var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:{ 'drawer-mini': !_vm.DRAWER_STATE },attrs:{"app":"","mini-variant":!_vm.DRAWER_STATE,"width":_vm.sidebarWidth,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"temporary":_vm.$vuetify.breakpoint.smAndDown,"mini-variant-width":_vm.sidebarMinWidth},model:{value:(_vm.DRAWER_STATE),callback:function ($$v) {_vm.DRAWER_STATE=$$v},expression:"DRAWER_STATE"}},[_c('v-list',[_c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[(_vm.DRAWER_STATE)?[_c('div',{staticClass:"text-left ml-1"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"line-height":"28px"}},[_vm._v("Menu")]),_c('v-icon',{staticClass:"float-right",staticStyle:{"font-size":"28px"},on:{"click":function($event){$event.stopPropagation();_vm.DRAWER_STATE = !_vm.DRAWER_STATE}}},[_vm._v("mdi-close")])],1)]:[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"28px"},attrs:{"center":""},on:{"click":function($event){$event.stopPropagation();_vm.DRAWER_STATE = !_vm.DRAWER_STATE}}},[_vm._v("mdi-menu")])],1)]],2)],1),_vm._l((_vm.items),function(item,i){return [(
          item.heading &&
          (typeof item.hasAccess == 'undefined' || item.hasAccess)
        )?_c('v-row',{key:item.heading,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"text-body-1 subheader",class:item.heading && _vm.DRAWER_STATE ? 'show ' : 'hide',staticStyle:{"padding-left":"32px"}},[_vm._v(" "+_vm._s(item.heading)+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}})],1):(item.divider)?_c('v-divider',{key:i,staticClass:"my-4",attrs:{"dark":""}}):(
          item.subMenus &&
          //DRAWER_STATE &&
          (typeof item.subMenus.hasAccess == 'undefined' ||
            item.subMenus.hasAccess) &&
          item.hasAccess
        )?_c('v-list-group',{key:item.title,attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap grey--text"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]},proxy:true}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},[_vm._l((item.subMenus),function(child,i){return [(typeof child.hasAccess == 'undefined' || child.hasAccess)?_c('v-list-item',{key:i,attrs:{"to":child.link === '#' ? null : child.link,"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap grey--text",attrs:{"link":""}},[_vm._v(" "+_vm._s(child.title)+" ")])],1)],1):_vm._e()]})],2):(typeof item.hasAccess == 'undefined' || item.hasAccess)?_c('v-list-item',{key:item.text,attrs:{"color":"primary","to":item.link === '#' ? null : item.link,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap grey--text",attrs:{"link":""}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):_vm._e()]}),(_vm.DRAWER_STATE)?_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Sign Out ")])]}}],null,false,1246054753),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"pt-5"},[_c('img',{attrs:{"src":require("@/assets/Warning.svg"),"alt":"logout"}})]),_c('v-card-title',{staticClass:"headline"},[_vm._v(" Yakin untuk Logout? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Tidak ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.logOut}},[_vm._v(" Ya ")])],1)],1)],1)],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }