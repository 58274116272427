<template>
  <v-app>
    <v-form ref="formPengguna" v-model="validFormPengaduan" lazy-validation>
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Pengguna</p>
          <span class="titlet"></span>
          <p v-if="isEdit" class="subTitle">Edit</p>
          <p v-else class="subTitle">Tambah Pengguna</p>
        </v-row>
        <v-col cols="12" md="18">
          <div class="top">
            <v-card cols="12">
              <v-card-text>
                <v-row>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">NIK</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-dialog v-model="dialogKaryawan" max-width="800px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="nik"
                          outlined
                          dense
                          :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                          hide-details="auto"
                          placeholder="Masukan Nomor Induk Karyawan"
                          :rules="[
                            (v) => !!v || 'Nomor Induk Karyawan harus di isi',
                          ]"
                          v-if="isEdit"
                          readonly
                        >
                        </v-text-field>

                        <v-text-field
                          v-model="nik"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          v-else
                          readonly
                          :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                          hide-details="auto"
                          placeholder="Masukan Nomor Induk Karyawan"
                          :rules="[
                            (v) => !!v || 'Nomor Induk Karyawan harus di isi',
                          ]"
                        >
                        </v-text-field>
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-icon
                            class="icon"
                            @click="closeDialog()"
                            style="float: right; margin-top: 12px"
                            >mdi-close</v-icon
                          >
                        </v-card-text>
                        <v-card-title class="black--text">
                          Karyawan
                        </v-card-title>
                        <v-card-text class="mt-5">
                          <v-row>
                            <v-text-field
                              label=""
                              class=""
                              hide-details="auto"
                              v-model="searchValueKaryawan"
                              dense
                              outlined
                            ></v-text-field>
                            <v-btn
                              @click="searchKaryawan"
                              class="elevation-0 text-capitalize ml-5"
                              color="primary"
                            >
                              <span class="white--text">Cari</span>
                            </v-btn>
                          </v-row>
                          <v-data-table
                            class="mt-5 mb-5"
                            :headers="headersKaryawan"
                            :items="allEmployee"
                            :items-per-page="5"
                            :header-props="{ sortIcon: null }"
                            disable-sort
                          >
                            <template v-slot:item="allEmploye">
                              <tr>
                                <td align="center">
                                  {{ allEmploye.item.nik }}
                                </td>
                                <td align="center">
                                  {{ allEmploye.item.name }}
                                </td>
                                <td align="center">
                                  {{ allEmploye.item.position }}
                                </td>
                                <td align="center">
                                  {{ allEmploye.item.organization }}
                                </td>
                                <td align="center">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    @click="pilihKaryawan(allEmploye.item)"
                                  >
                                    <span class="orange--text">Pilih</span>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Nama</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="inputFullName"
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nama lengkap"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Posisi</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="inputPosition"
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan position"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Organisasi</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="inputOrganization"
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nama organization"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Role</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-select
                      :items="inquiryRole"
                      item-text="roleName"
                      item-value="roleId"
                      label=""
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      v-model="roleId"
                      outlined
                      dense
                      clearable
                      placeholder="Pilih role"
                      :rules="[(v) => !!v || 'Role harus di isi']"
                      @click="loadRole"
                      multiple
                      chips
                    ></v-select>
                  </v-col>
                  <v-col md="12" v-if="isEdit">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Status</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-switch
                      v-model="status"
                      :label="status == true ? 'Aktif' : 'Tidak Aktif'"
                      inset
                      color="orange darken-3"
                      hide-details
                      
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <v-row>
            <v-col class="text-right">
              <v-btn
                v-if="isEdit"
                x-large
                depressed
                class="delegasi ml-8"
                color="primary"
                @click="editUser"
                ><span class="white--text">Save</span></v-btn
              >
              <v-btn
                v-else
                x-large
                depressed
                class="delegasi ml-8"
                color="primary"
                @click="addUser"
                ><span class="white--text">Save</span></v-btn
              >

              <v-btn :to="`/pengguna`" x-large depressed outlined class="back"
                ><span class="primary--text">Cancel</span></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      allEmployee: [],
      formPengguna: false,
      userId: "",
      nik: "",
      inputFullName: "",
      inputPosition: "",
      inputOrganization: "",
      roleId: "",
      roleName: "",
      department: "",
      status: "",
      dialogKaryawan: false,
      inquiryRole: [],
      headersKaryawan: [
        {
          text: "NIK",
          align: "center",
          width: "86px",
        },
        {
          text: "Nama",
          align: "center",
          width: "90px",
        },
        {
          text: "Posisi",
          align: "center",
          width: "95px",
        },
        {
          text: "Organisasi",
          align: "center",
          width: "95px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "62px",
        },
      ],
    };
  },

  async mounted() {
    await this.loadData();
    this.loadEmployee();
    this.loadRole();
  },

  created() {
    this.requiredFeature('PENGGUNA')
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "updateUser",
      "createUser",
      "getInquiryEmployee",
      "getParameterByParamType",
      "getEmployeeBySearchValue",
      "getInquiryRole",
      "getInquiryLovPengguna"
    ]),

    closeDialog() {
      this.dialogKaryawan = false
      this.searchValueKaryawan = ''
      this.loadEmployee();
    },

    async loadData() {
      if (this.isEdit) {
        await this.$store
          .dispatch("getUserById", { nik: this.idUser })
          .then((response) => {
            this.formData = response.data.data;
          });
        this.nik = this.formData.nik;
        this.userId = this.formData.userId;
        this.inputFullName = this.formData.fullName;
        this.inputPosition = this.formData.position;
        this.inputOrganization = this.formData.organization;
        this.email = this.formData.emailAddress;
        this.roleId = this.formData.roleId.map(item => {
          return Number(item)
        });
        this.department = this.formData.department;
        this.status = this.formData.status == "Aktif" ? true : false;
      }
    },

    loadEmployee() {
      this.getInquiryLovPengguna({
        pageNumber: 0,
        pageSize: 200,
        searchValue: "",
      })
        .then((resp) => {
          this.totalData = resp.data.countData;
          this.countData = resp.data.countData;

          this.allEmployee = resp.data.data
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryEmployee = [];
        });
    },

    pilihKaryawan(item) {
      this.dialogKaryawan = false;
      this.nik = `${item.nik}`;
      this.inputFullName = `${item.name}`;
      this.inputPosition = `${item.position}`;
      this.inputOrganization = `${item.organization}`;
      // console.log("ini adalah nik", item);

      this.nik = item.nik;
    },

    addUser() {
      this.createUser({
        nik: this.nik,
        fullName: this.inputFullName,
        emailAddress: this.email,
        roleId: this.roleId,
        department: this.department,
        status: "USER_ACTIVE",
        position: this.inputPosition,
        Organization: this.inputOrganization
      })
        .then((response) => {
          this.$router.push(`/pengguna`);
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },
    searchKaryawan() {
      this.getInquiryLovPengguna({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValueKaryawan,
      })
        .then((resp) => {
          // console.log("data resp", resp);
          this.totalData = resp.data.data.length;

          this.allEmployee = resp.data.data;
        })
        .catch((err) => {
          console.log("ini error", err);
          this.$store.state.inquiryEmployee = [];
        });
    },

    editUser() {
      this.updateUser({
        userId: this.userId,
        nik: this.nik,
        fullName: this.inputFullName,
        emailAddress: this.email,
        roleId: this.roleId,
        department: this.department,
        status: this.status == true ? "USER_ACTIVE" : "USER_NOT_ACTIVE",
      })
        .then((response) => {
          this.$router.push(`/pengguna`);
          this.$helpers.handleSuccess(response);
          this.loading = false;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadRole() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = "";
      this.$store
        .dispatch("getInquiryRole", { pageNumber, pageSize, searchValue })
        .then((res) => {
          this.inquiryRole = res.data.data;
          // console.log("allInquiryRole", this.inquiryRole);

          for (let index = 0; index < this.roleId.length; index++) {
            
            let indexs = this.inquiryRole.findIndex(
              (x) => x.roleId === this.roleId[index]
            );
  
            if (indexs == -1) {
              this.inquiryRole.push(this.roleId[index]);
  
              // this.roleId = this.formData.roleName;
            }
          }

        })
        .catch(() => {
          this.$store.state.inquiryRole = [];
        });
      this.page = 1;
    },
  },

  computed: {
    ...mapState(["userById"]),
    allInquiryEmployee() {
      return Object.values(this.$store.getters.allInquiryEmployee);
    },
    allInquiryRole() {
      return Object.values(this.$store.getters.allInquiryRole);
    },
    idUser: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
  },
};
</script>

<style src="./User.scss" lang="scss" />