<template>
  <v-app>
    <v-form ref="formKomiteFraud" v-model="validFormKomiteFraud" lazy-validation>
      <v-container fluid>
        <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
          <v-card>
            <v-col>
              <v-card-text>
                <v-icon class="icon" @click="expandPembiayaanFiktif = false">mdi-close</v-icon>
              </v-card-text>
              <v-card-title>{{ lossChosen.paramName }}</v-card-title>
              <v-row no-gutters justify="center">
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF' ||
                  lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
                  " :headers="headersPembayaranFiktif1Expand" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr>
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingPokok" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingMargin" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
                  :headers="headersPembayaranFiktif1ExpandSebagian" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.pembiayaanNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                          v-model="data.item.pembiayaanNasabahPercentage" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.pembiayaanKaryawan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                          v-model="data.item.pembiayaanKaryawanPercentage" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingTotal" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingKaryawan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
                  :headers="headersPembayaranFiktif2ExpandPelunasan" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td class="fixed_table_colum">
                        {{ data.index + 1 }}
                      </td>
                      <td class="fixed_table_colum2">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td class="fixed_table_colum3">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td class="fixed_table_colum4">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingPelunasan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
                  :headers="headersPembayaranFiktif2ExpandSetoran" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
                  :headers="headersPembayaranFiktif2ExpandTabungan" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.tabunganNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.transaksiType" :items="transaksiTypeList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.prsCollection" :items="prsCollectionList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'" :headers="headersPembayaranFiktif3ExpandBrw"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.mthdDetailDesc" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.brwCategory" :items="brwCategoryList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'"
                  :headers="headersPembayaranFiktif3ExpandKas" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.kasSolCategory" :items="kasSolCategoryList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                  :headers="headersPembayaranFiktif3ExpandLemari" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.mthdDetailDesc" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'" :headers="headersPembayaranFiktif3ExpandFpb"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.itemName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fpbTransactionDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'" :headers="headersPembayaranFiktif4Expand"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.itemName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.pelakuPencurian" :items="pelakuPencurianList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>

        <v-dialog width="800" v-model="pelakuFraudDialog">
          <v-card>
            <v-card-text>
              <v-icon class="icon" @click="pelakuFraudDialog = false"
                style="float: right; margin-top: 12px">mdi-close</v-icon>
            </v-card-text>
            <v-card-title>Pelaku Fraud</v-card-title>
            <v-card-text>Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text>
            <v-card-text>
              <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList" :items-per-page="5"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:[`item.netFraudDist`]="{ item }">
                  <vuetify-money v-model="item.netFraudDist" class="mb-0 mt-3" hide-details label="" outlined dense
                    v-bind:options="optionsMoney" readonly />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog width="800" v-model="documentDialog">
          <v-card>
            <v-card-text>
              <v-icon class="icon" @click="documentDialog = false"
                style="float: right; margin-top: 12px">mdi-close</v-icon>
            </v-card-text>
            <v-card-title>Download Images</v-card-title>
            <v-card-text>Format file</v-card-text>
            <v-card-text>
              <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
                <v-col>
                  {{ index + 1 }}. {{ document.documentTypeName }}
                </v-col>
                <v-col class="text-right pr-3">
                  <v-btn v-if="document.attachFilePath != null" color="primary" class="text-none pr-3" rounded depressed
                    @click="handleDownloadAttachment(document)">
                    <v-icon left> mdi-download </v-icon>
                    {{
                      document.attachFileName
                      ? document.attachFileName
                      : "Download"
                    }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-text-field hide-details="auto" dense outlined placeholder="Keterangan" v-model="document.notes"
                    readonly></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Komite Fraud</p>
          <span class="titlet"></span>
          <p v-if="isEdit" class="subTitle">Edit</p>
          <p v-else class="subTitle">Proses</p>
        </v-row>
        <v-col cols="12" md="18">
          <!-- <div class="top"> -->
          <v-card>
            <template>
              <v-tabs>
                <v-tab href="#tab-dataInvestigator" @click="submitBtn = false">Data Investigator</v-tab>
                <v-tab href="#tab-detailLaporan" @click="submitBtn = false">Detail Laporan</v-tab>
                <v-tab href="#tab-verifikasiFraud" @click="submitBtn = false">Verifikasi fraud</v-tab>
                <v-tab href="#tab-investigasiFraud" @click="submitBtn = false">Investigasi Fraud</v-tab>
                <v-tab href="#tab-laporanPelengkap" v-if="delegateBy == 'AFM Manager'" @click="submitBtn = false">Laporan
                  Pelengkap</v-tab>
                <v-tab href="#tab-reviewFraud" @click="submitBtn = false">Review Fraud</v-tab>
                <v-tab href="#tab-komiteFraud" @click="submitBtn = true">Komite Fraud Information</v-tab>

                <v-tab-item value="tab-dataInvestigator">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Nama Investigator</p>
                        <v-text-field v-model="investigatorName" class="dp mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="Masukan nama Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">NIK Investigator</p>
                        <v-text-field v-model="investigatorNik" class="dp mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="Masukan NIK Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">
                          Jabatan / Posisi Investigator
                        </p>
                        <v-text-field v-model="positionInvestigator" class="dp mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="Masukan Jabatan Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Email Investigator</p>
                        <v-text-field v-model="emailInvestigator" class="dp mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="Masukan Email Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">No Telepon Investigator</p>
                        <v-text-field v-model="phoneNumber" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan No Telepon Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-detailLaporan">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">No. Tiket JAGA</p>
                        <v-text-field v-model="jagaNo" class="dl mb-0" hide-details="auto" outlined color="#A8B5CB" dense
                          placeholder="Masukan No. Tiket JAGA Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Tanggal Pelaporan</p>
                        <v-menu ref="menuReportDate" v-model="menuReportDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar" v-model="reportDate"
                              class="dl mb-0" label="" hide-details="auto" outlined color="#A8B5CB" dense placeholder=""
                              readonly style="background-color: #f8f8f8">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="reportDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Jenis Fraud</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="fraudType" class="dl mb-0" disabled
                              style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ fraudType }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Sumber Informasi Awal Temuan Fraud
                        </p>
                        <v-select :items="infoFraud" item-text="paramName" label="" class="dl mb-0" hide-details="auto"
                          v-model="fraudInfo" outlined dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-select>
                      </v-col>
                      <v-col cols="12" v-if="fraudInfo == 'Sumber Lainnya : ... Sebutkan'">
                        <p class="label-text-field">Lainnya</p>
                        <v-text-field class="dl mb-0" v-model="fraudInfoOther" hide-details="auto" outlined dense
                          placeholder="Masukan sumber informasi awal temuan fraud" style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Yang Terlibat</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersSuspect" :items="fraudSuspect"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:[`item`]="{ item }">
                                <tr>
                                  <td align="center">{{ item.seqNo }}</td>
                                  <td align="center">{{ item.pelakuNik }}</td>
                                  <td align="center">
                                    <v-switch v-model="item.pelakuUtama" :label="item.pelakuUtama == 'Y' ? 'Ya' : 'Tidak'
                                      " true-value="Y" false-value="N" inset color="orange darken-3" hide-details
                                      readonly>
                                    </v-switch>
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuPosition }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    {{ item.lokasiKerja }}
                                  </td>
                                  <td align="center">
                                    {{ item.assignmentCategory }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuJointDate }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuEducation }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Lokasi Kerja Terjadinya Fraud
                        </p>
                        <v-text-field v-model="branchCd" class="dl mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan Kode Lokasi Kerja Terjadinya Fraud" readonly
                          style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Kerugian Awal</p>
                        <v-text-field v-model="initialLoss" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Modus Operandi</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersModus" :items="fraudMethod"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:[`item`]="{ item }">
                                <tr>
                                  <td align="center">{{ item.seqNo }}</td>
                                  <td style="text-align: left">
                                    {{ item.fraudMthdName }}
                                  </td>
                                  <td style="text-align: left">
                                    {{ item.incidentName }}
                                  </td>
                                  <td align="center">
                                    {{
                                      item.incidentDesc == ""
                                      ? "-"
                                      : item.incidentDesc
                                    }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Attachment</p>
                        <v-col cols="12" v-for="item in attachment" :key="item">
                          <a v-if="item.flowName == 'Pengaduan Fraud'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                      <!-- <v-col cols="12" v-if="reportType == 'REPORT_TYPE_USER'">
                        <p class="label-text-field">Link Sharepoint</p>
                        <v-text-field v-model="sharePoint" class="dl mb-0" hide-details="auto" outlined dense
                          placeholder="Masukan Link Sharepoint" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-verifikasiFraud">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Verification Status</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="verifStatus" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ verifStatus }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <!-- <v-col cols="12">
                          <p class="label-text-field">Return</p>
                          <v-textarea
                            v-model="returnNote"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            dense
                            placeholder=""
                            readonly
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <p class="label-text-field">Tanggal Return</p>
                          <v-menu
                            ref="menuReturnDate"
                            v-model="menuReturnDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-bind="attrs"
                                v-on="on"
                                prepend-inner-icon="mdi-calendar"
                                v-model="returnDate"
                                label=""
                                hide-details="auto"
                                outlined
                                color="#A8B5CB"
                                dense
                                placeholder=""
                                readonly
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="returnDateVal"
                              no-title
                              scrollable
                              disabled
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col> -->
                      <v-col cols="12">
                        <p class="label-text-field">Dokumen Awal Pelaporan</p>
                        <v-row class="ml-2">
                          <v-row v-for="item in reportDoc" :key="item">
                            <v-checkbox v-model="item.reportDocTypeName" class="px-2" :label="item.reportDocTypeName"
                              readonly>
                            </v-checkbox>
                          </v-row>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Nominal SP Pelaku</p>
                        <v-text-field v-model="spPelaku" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          SP Nasabah / Pihak Ketiga
                        </p>
                        <v-text-field v-model="spNasabah" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Manager Investigator</p>
                        <v-text-field v-model="investigatorManager" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">No Hp Investigator</p>
                        <v-text-field v-model="investigatorPhone" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Didelegasi Oleh</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="delegateBy" disabled style="
                                width: 180px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ delegateBy }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Keterangan
                        </p>
                        <v-textarea v-model="eksternalNote" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-investigasiFraud">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Investigation Status</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="investigationStatus" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ investigationStatus }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <!-- <v-col cols="12">
                        <p class="label-text-field">
                          Detail / Cara Fraud Dilakukan
                        </p>
                        <v-textarea
                          v-model="fraudDetail"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        ></v-textarea>
                      </v-col> -->
                      <v-col cols="12">
                        <p class="label-text-field">Tujuan Fraud</p>
                        <v-select :items="tujuanFraudList" item-text="paramName" label="" class="dl mb-0"
                          hide-details="auto" v-model="fraudFactor" outlined dense placeholder="" readonly
                          style="background-color: #f8f8f8">
                        </v-select>
                      </v-col>
                      <v-col cols="12" v-if="fraudFactor == 'Lainnya : ... Sebutkan'">
                        <p class="label-text-field">Lainnya</p>
                        <v-text-field v-model="fraudFactorOther" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <!-- <v-col cols="12">
                        <p class="label-text-field">
                          Alasan Pelaku Melakukan Fraud
                        </p>
                        <v-textarea
                          v-model="fraudReason"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Hasil Kecurangan Digunakan Untuk Apa?
                        </p>
                        <v-textarea
                          v-model="fraudPurpose"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Kontrol Yang Tidak Berjalan
                        </p>
                        <v-textarea
                          v-model="fraudControl"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        ></v-textarea>
                      </v-col> -->
                      <!-- <v-col cols="12">
                        <p class="label-text-field">Jenis Pelanggaran</p>
                        <v-select
                          :items="jenisPelanggaranList"
                          item-text="paramName"
                          label=""
                          class="dl mb-0"
                          hide-details="auto"
                          v-model="violationType"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          violationType == 'Kelalaian Lainnya : ... Sebutkan'
                        "
                      >
                        <p class="label-text-field">Lainnya</p>
                        <v-text-field
                          v-model="violationTypeOther"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        >
                        </v-text-field
                      ></v-col> -->

                      <v-col cols="12">
                        <!-- <h7 class="label-text-field" color="primary">
                    Jenis Pelanggaran
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headersJenisPelanggaran"
                        :items="jenisPelanggaranListToSave"
                        :items-per-page="5"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">{{ data.item.assignmentCategory }}</td>
                            <td align="center">{{ data.item.violationTypeName }}</td>
                            <td align="center">{{ data.item.violationDesc }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row> -->
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Root Cause</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:item="data">
                                <tr>
                                  <td align="center">{{ data.index + 1 }}</td>
                                  <td align="center">
                                    {{ data.item.rootCauseName }}
                                  </td>
                                  <td>
                                    <v-select :items="rootCauseStatus[data.index]" item-text="rootCauseName"
                                      item-value="rootCauseDesc" v-model="data.item.rootCauseList" return-object label=""
                                      class="mt-3 mb-3" hide-details="auto" outlined dense placeholder="" multiple chips
                                      readonly style="background-color: #f8f8f8">
                                    </v-select>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Tanggal Fraud Dilakukan</p>
                        <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                              style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="startDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Tanggal Fraud Diketahui</p>
                        <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                              style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="endDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Tanggal Terakhir Fraud
                        </p>
                        <v-menu v-model="recoveryDateMenu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fraudCommitedDateTo" label="" prepend-inner-icon="mdi-calendar"
                              v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                              placeholder="" style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="recoveryDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- <v-col cols="12">
                          <p class="label-text-field">Attachment</p>
                          <v-col
                            cols="12"
                            v-for="item in attachmentAfm"
                            :key="item"
                          >
                            <a
                              v-if="item.flowName == 'Investigasi AFM'"
                              @click="handleDownloadAttachment(item)"
                              >{{ item.attachFileName }}</a
                            >
                          </v-col>
                        </v-col> -->
                      <v-col cols="12">
                        <p class="label-text-field mb-5">
                          Rincian Daftar Kerugian
                        </p>
                        <v-row>
                          <!-- <v-expansion-panels v-model="panelIndex" accordion>
                            <v-expansion-panel
                              @click="onExpansionPanelClick(index, $event)"
                              v-for="(loss, index) in detailLoss"
                              :key="index"
                            >
                              <v-expansion-panel-header
                                v-if="lossList[index].length !== 0"
                                ><v-row no-gutters
                                  ><v-col>{{ loss.paramName }} </v-col
                                  ><v-col class="text-right"
                                    ><v-btn
                                      text
                                      @click.native.stop="
                                        openExpand(index, loss)
                                      "
                                    >
                                      <span class="orange--text"
                                        >Expand</span
                                      ></v-btn
                                    ></v-col
                                  ></v-row
                                ></v-expansion-panel-header
                              >
                              <v-expansion-panel-content
                                v-if="lossList[index].length !== 0"
                              >
                                <v-data-table
                                  v-if="
                                    loss.paramCd == 'LOSS_TYPE_FIKTIF' ||
                                    loss.paramCd ==
                                      'LOSS_TYPE_SELURUH_PENCAIRAN'
                                  "
                                  :headers="headersPembayaranFiktif1"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr>
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.nasabahName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.appIdNo"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum4">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.pencairanDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.plafond"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.outstandingPokok"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.outstandingMargin"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.tabunganBalance"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="
                                    loss.paramCd ==
                                    'LOSS_TYPE_SEBAGIAN_PENCAIRAN'
                                  "
                                  :headers="headersPembayaranFiktif1Sebagian"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.nasabahName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.appIdNo"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum4">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.pencairanDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.plafond"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.pembiayaanNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-text-field
                                          class="mt-3"
                                          suffix="%"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          v-model="
                                            data.item
                                              .pembiayaanNasabahPercentage
                                          "
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.pembiayaanKaryawan"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-text-field
                                          class="mt-3"
                                          suffix="%"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          v-model="
                                            data.item
                                              .pembiayaanKaryawanPercentage
                                          "
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.outstandingTotal"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.outstandingNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="
                                            data.item.outstandingKaryawan
                                          "
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.tabunganBalance"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_ANGSURAN'"
                                  :headers="headersPembayaranFiktif2Setoran"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.nasabahName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.appIdNo"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum4">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.angsuranStatus"
                                          :items="statusAngsuranList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.fraudRepayDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_PELUNASAN'"
                                  :headers="headersPembayaranFiktif2Pelunasan"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.nasabahName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.appIdNo"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum4">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="
                                            data.item.outstandingPelunasan
                                          "
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.tabunganBalance"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.fraudRepayDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_TABUNGAN'"
                                  :headers="headersPembayaranFiktif2Tabungan"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.nasabahName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.tabunganNo"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum4">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.transaksiType"
                                          :items="transaksiTypeList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.prsCollection"
                                          :items="prsCollectionList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.fraudRepayDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_BRW'"
                                  :headers="headersPembayaranFiktif3Brw"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.mthdDetailDesc"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.brwCategory"
                                          :items="brwCategoryList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_KAS_SOL'"
                                  :headers="headersPembayaranFiktif3Kas"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.sentraName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.kasSolCategory"
                                          :items="kasSolCategoryList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.fraudRepayDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                                  :headers="headersPembayaranFiktif3Lemari"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.mthdDetailDesc"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_FPB'"
                                  :headers="headersPembayaranFiktif3Fpb"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.itemName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field
                                          v-model="data.item.fpbTransactionDate"
                                          label=""
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined
                                          dense
                                          hide-details="auto"
                                          class="mt-3"
                                          placeholder=""
                                        ></v-text-field>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                                <v-data-table
                                  v-if="loss.paramCd == 'LOSS_TYPE_PENCURIAN'"
                                  :headers="headersPembayaranFiktif4"
                                  :items="lossList[index]"
                                  :header-props="{ sortIcon: null }"
                                  disable-sort
                                >
                                  <template v-slot:item="data">
                                    <tr class="text-center">
                                      <td class="fixed_table_colum">
                                        {{ data.index + 1 }}
                                      </td>
                                      <td class="fixed_table_colum2">
                                        <v-text-field
                                          class="mt-3"
                                          dense
                                          outlined
                                          hide-details="auto"
                                          persistent-placeholder
                                          v-model="data.item.itemName"
                                          readonly
                                        >
                                        </v-text-field>
                                      </td>
                                      <td class="fixed_table_colum3">
                                        <v-autocomplete
                                          class="mt-3"
                                          dense
                                          outlined
                                          persistent-placeholder
                                          hide-details="auto"
                                          v-model="data.item.pelakuPencurian"
                                          :items="pelakuPencurianList"
                                          item-text="paramName"
                                          item-value="paramCd"
                                          readonly
                                        >
                                        </v-autocomplete>
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.potentialLoss"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryNasabah"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.recoveryBranch"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <vuetify-money
                                          v-model="data.item.netFraud"
                                          class="mb-0 mt-3"
                                          hide-details
                                          label=""
                                          outlined
                                          dense
                                          v-bind:options="optionsMoney"
                                          readonly
                                        />
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openPelakuDialog(
                                              data.item.detailLossDistList,
                                              data.item.netFraud
                                            )
                                          "
                                          >Pelaku</v-btn
                                        >
                                      </td>
                                      <td>
                                        <v-dialog
                                          v-model="
                                            dialogKeterangan[data.item.netFraud]
                                          "
                                          :key="data.index"
                                          persistent
                                          width="600"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-btn
                                              text
                                              class="primary--text"
                                              v-bind="attrs"
                                              v-on="on"
                                              >Keterangan
                                            </v-btn>
                                          </template>
                                          <div class="text-center">
                                            <v-card>
                                              <v-card-text>
                                                <v-icon
                                                  class="icon"
                                                  @click="
                                                    $set(
                                                      dialogKeterangan,
                                                      data.item.netFraud,
                                                      false
                                                    )
                                                  "
                                                  style="
                                                    float: right;
                                                    margin-top: 12px;
                                                  "
                                                  >mdi-close</v-icon
                                                >
                                              </v-card-text>
                                              <v-card-title
                                                >Keterangan</v-card-title
                                              >
                                              <v-card-text>
                                                <v-textarea
                                                  class="mb-0"
                                                  hide-details="auto"
                                                  outlined
                                                  dense
                                                  placeholder=""
                                                  v-model="data.item.notes"
                                                  readonly
                                                ></v-textarea>
                                              </v-card-text>
                                            </v-card>
                                          </div>
                                        </v-dialog>
                                      </td>
                                      <td>
                                        <v-btn
                                          text
                                          class="primary--text"
                                          @click="
                                            openDocumentDialog(
                                              data.item.detailLossDocList
                                            )
                                          "
                                          >Document Image</v-btn
                                        >
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels> -->
                          
                          <!-- <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                            hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                            <template v-slot:item="data">
                              <tr>
                                <td>{{ data.index + 1 }}</td>
                                <td>{{ data.item.pelakuNik }}</td>
                                <td>{{ data.item.pelakuName }}</td>
                                <td>{{ data.item.modusName }}</td>
                                <td>{{ data.item.noa === 0 ? "" : data.item.noa }}</td>
                                <td>{{ data.item.plafond === 0 ? "" : data.item.plafond }}</td>
                                <td>{{ data.item.tabungan === 0 ? "" : data.item.tabungan }}</td>
                                <td>{{ data.item.potentialLoss === 0 ? "" : data.item.potentialLoss }}</td>
                                <td>{{ data.item.recovery }}</td>
                                <td>{{ data.item.netFraud }}</td>
                                <td>
                                  <v-btn v-if="data.item.pelakuUtama == 'N'" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                    @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                    <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                  </v-btn> 
                                  <div v-else>
                                    <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                      <span class="orange--text ml-2">Download List Nasabah</span>
                                    </v-btn> 
                                    <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                      <span class="orange--text ml-2">Download Bukti</span> 
                                    </v-btn> 
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template slot="body.append">
                              <tr>
                                <td colspan="4" align="center">Total</td>
                                <td>{{sumField('noa') === 0 ? "" : sumField('noa')}}</td>
                                <td>{{sumField('plafond') === 0 ? "" : sumField('plafond')}}</td>
                                <td>{{sumField('tabungan') === 0 ? "" : sumField('tabungan')}}</td>
                                <td>{{sumField('potentialLoss') === 0 ? "" : sumField('potentialLoss')}}</td>
                                <td>{{sumField('recovery') === 0 ? "" : sumField('recovery')}}</td>
                                <td>{{sumField('netFraud') === 0 ? "" : sumField('netFraud')}}</td>
                              </tr>
                            </template>
                          </v-data-table> -->
                          <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                            hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                            <template v-slot:item="data" item-key="pelakuId">
                              <tr v-if="!isMerged(data.item, data.index)">
                                <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                                  data.index) }}</td>
                                <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                                <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                                <td>{{ data.item.modusName }}</td>
                                <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                                <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                                <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                                <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                                <td>{{ formatNumber(data.item.recovery) }}</td>
                                <td>{{ formatNumber(data.item.netFraud) }}</td>
                                <td>
                                  <div v-if="data.item.pelakuUtama == 'N'">
                                    <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                      cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                      <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                    </v-btn>
                                  </div>
                                  <div v-else>
                                    <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                      <span class="orange--text ml-2">Download List Nasabah</span>
                                    </v-btn>
                                    <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                      <span class="orange--text ml-2">Download Bukti</span>
                                    </v-btn>
                                  </div>
                                </td>
                              </tr>
                              <tr v-else>
                                <td>{{ data.item.modusName }}</td>
                                <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                                <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                                <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                                <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                                <td>{{ formatNumber(data.item.recovery) }}</td>
                                <td>{{ formatNumber(data.item.netFraud) }}</td>
                                <td>
                                  <div v-if="data.item.pelakuUtama == 'N'">
                                    <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                      cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                      <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                    </v-btn>
                                  </div>
                                  <div v-else>
                                    <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                      <span class="orange--text ml-2">Download List Nasabah</span>
                                    </v-btn>
                                    <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                      @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                      <span class="orange--text ml-2">Download Bukti</span>
                                    </v-btn>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template slot="body.append">
                              <tr>
                                <td colspan="4" align="center">Total</td>
                                <td>{{sumField('noa') === 0 ? "" : formatNumber(sumField('noa'))}}</td>
                                <td>{{sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond'))}}</td>
                                <td>{{sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan'))}}</td>
                                <td>{{sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss'))}}</td>
                                <td>{{sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery'))}}</td>
                                <td>{{sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud'))}}</td>
                            </tr>
                            </template>
                          </v-data-table>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-laporanPelengkap">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Attachment</p>
                        <v-col cols="12" v-for="item in attachmentAfm" :key="item">
                          <a v-if="item.flowName == 'Investigasi AFM'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-reviewFraud">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Summary Status</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table :headers="headersSummaryStatus" :items="lossSummary" :items-per-page="5"
                              :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:item="itemSummary">
                                <tr>
                                  <td align="center">
                                    {{ itemSummary.index + 1 }}
                                  </td>
                                  <td align="center">
                                    {{ itemSummary.item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ itemSummary.item.pelakuPosition }}
                                  </td>
                                  <td align="center">
                                    {{ itemSummary.item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    {{ itemSummary.item.pelakuNik }}
                                  </td>
                                  <td align="center">
                                    {{ itemSummary.item.lossTypeName }}
                                  </td>
                                  <td align="center">
                                    Rp.
                                    {{
                                      humanFormat(
                                        itemSummary.item.potentialLoss
                                      )
                                    }}
                                  </td>
                                  <td align="center">
                                    Rp.
                                    {{
                                      humanFormat(
                                        itemSummary.item.recoveryNasabah
                                      )
                                    }}
                                  </td>
                                  <td align="center">
                                    Rp.
                                    {{
                                      humanFormat(
                                        itemSummary.item.recoveryBranch
                                      )
                                    }}
                                  </td>
                                  <td align="center">
                                    Rp.
                                    {{ humanFormat(itemSummary.item.netFraud) }}
                                  </td>
                                  <td align="center">
                                    <v-checkbox v-model="itemSummary.item.confirmStatus" class="px-2 mt-0" readonly>
                                    </v-checkbox>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Urain Kejadian</p>
                        <v-textarea v-model="chronology" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8"></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Penggunaan Uang</p>
                        <v-textarea v-model="moneyUsage" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8"></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Sanksi dan Pasal</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersSanksi" :items="fraudSuspect"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:[`item`]="{ item }">
                                <tr>
                                  <td align="center">{{ item.seqNo }}</td>
                                  <td align="center">{{ item.pelakuNik }}</td>
                                  <td align="center">
                                    {{ item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    {{ item.rekomendasiPasalName }}
                                  </td>
                                  <td align="center">
                                    {{ item.rekomendasiSanksiName }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- <v-col cols="12">
                        <p class="label-text-field">Keputusan</p>
                        <v-textarea
                          v-model="decision"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                          style="background-color: #f8f8f8"
                        ></v-textarea>
                      </v-col> -->
                      <v-col cols="12">
                        <p class="label-text-field">Catatan</p>
                        <v-textarea v-model="noteReview" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8"></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">SOP / Memo / Juknis</p>
                        <v-select :items="sopMemoList" item-text="paramName" label="" class="dl mb-0" hide-details="auto"
                          v-model="sopMemo" outlined dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Unsur Pelanggaran</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table :headers="headersViolation" :items="unsurList" :items-per-page="5"
                              :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:item="data">
                                <tr>
                                  <td align="center">{{ data.index + 1 }}</td>
                                  <td align="center">
                                    {{
                                      data.item.fraudLocName != null
                                      ? data.item.fraudLocName
                                      : "-"
                                    }}
                                  </td>
                                  <td align="center">
                                    {{
                                      data.item.fraudLoc != null
                                      ? data.item.fraudLoc
                                      : "-"
                                    }}
                                  </td>
                                  <td align="center">
                                    {{
                                      data.item.lossTypeName != null
                                      ? data.item.lossTypeName
                                      : "-"
                                    }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.pelakuNik }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.pelakuPosition }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.spPelaku != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.spPelaku,
                                              data.item.spPelakuPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.spPelaku != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.spPelaku,
                                              data.item.spPelakuPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.spPelaku == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.nasabahName }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.spNasabah != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.spNasabah,
                                              data.item.spNasabahPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.spNasabah != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.spNasabah,
                                              data.item.spNasabahPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.spNasabah == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    {{ data.item.amount }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.tabungan != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.tabungan,
                                              data.item.tabunganPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.tabungan != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.tabungan,
                                              data.item.tabunganPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.tabungan == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.prosperaT24 != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.prosperaT24,
                                              data.item.prosperaT24Path
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.prosperaT24 != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.prosperaT24,
                                              data.item.prosperaT24Path
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{
                                      data.item.prosperaT24 == null ? "-" : ""
                                    }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.penarikan != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.penarikan,
                                              data.item.penarikanPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.penarikan != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.penarikan,
                                              data.item.penarikanPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.penarikan == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.agendaku != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.agendaku,
                                              data.item.agendaKuPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.agendaku != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.agendaku,
                                              data.item.agendaKuPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.agendaku == null ? "-" : "" }}
                                  </td>
                                  <!-- <td align="center">
                                    {{
                                      data.item.rekapUangPulang != null
                                        ? data.item.rekapUangPulang
                                        : "-"
                                    }}
                                  </td> -->
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.bkl != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.bkl,
                                              data.item.bklPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.bkl != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.bkl,
                                              data.item.bklPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.bkl == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.bkc != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.bkc,
                                              data.item.bkcPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.bkc != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.bkc,
                                              data.item.bkcPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.bkc == null ? "-" : "" }}
                                  </td>
                                  <td align="center">
                                    <v-tooltip bottom slot="activator" v-if="data.item.sppuFphc != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            viewUnsur(
                                              data.item.sppuFphc,
                                              data.item.sppuFphcPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-eye-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Lihat</span>
                                    </v-tooltip>
                                    <v-tooltip bottom slot="activator" v-if="data.item.sppuFphc != null">
                                      <template v-slot:activator="{ on: tooltip }">
                                        <v-btn small text class="
                                            text-capitalize
                                            black--text
                                            px-0
                                          " v-on="tooltip" v-bind="attrs" @click="
                                            downloadUnsur(
                                              data.item.sppuFphc,
                                              data.item.sppuFphcPath
                                            )
                                            ">
                                          <v-icon aria-hidden="false" color="orange">
                                            mdi-file-download-outline
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Unduh</span>
                                    </v-tooltip>
                                    {{ data.item.sppuFphc == null ? "-" : "" }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Attachment</p>
                        <v-col cols="12" v-for="item in attachmentReview" :key="item">
                          <a v-if="item.flowName == 'Review Fraud'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Review Status</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="reviewStatus" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ reviewStatus }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-komiteFraud">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12" md="6" v-if="reportStatus == 'Reviewed' ||
                        reportStatus == 'Komite Fraud Outstanding'
                        ">
                        <p class="label-text-field">Tanggal Komite Fraud</p>
                        <v-menu ref="menuKomiteDate" v-model="menuKomiteDateSub" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="komiteDateSub" label="" prepend-inner-icon="mdi-calendar"
                              v-bind="attrs" v-on="on" outlined dense hide-details="auto" class="rp mb-0"
                              placeholder=""></v-text-field>
                          </template>
                          <v-date-picker v-model="komiteDateValSub" no-title scrollable @input="formatKomiteDate()">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" v-if="reportStatus == 'Komite Fraud Done' ||
                        reportStatus == 'In Sanction' ||
                        reportStatus == 'Closed'
                        ">
                        <p class="label-text-field">Tanggal Komite Fraud</p>
                        <v-menu ref="menuKomiteDate" v-model="menuKomiteDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="komiteDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on" outlined dense hide-details="auto" class="mb-0" placeholder=""
                              readonly></v-text-field>
                          </template>
                          <v-date-picker v-model="komiteDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" v-if="reportStatus == 'Reviewed' ||
                        reportStatus == 'Komite Fraud Outstanding'
                        ">
                        <p class="label-text-field">No. Komite Fraud</p>
                        <v-text-field v-model="komiteNoSub" class="rp mb-0" hide-details="auto" outlined dense
                          placeholder="">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" v-if="reportStatus == 'Komite Fraud Done' ||
                        reportStatus == 'In Sanction' ||
                        reportStatus == 'Closed'
                        ">
                        <p class="label-text-field">No. Komite Fraud</p>
                        <v-text-field v-model="komiteNo" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Reviewed' ||
                        reportStatus == 'Komite Fraud Outstanding'
                        ">
                        <p class="label-text-field">Velocity of Detection</p>
                        <v-text-field v-model="velocitySub" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Komite Fraud Done' ||
                        reportStatus == 'In Sanction' ||
                        reportStatus == 'Closed'
                        ">
                        <p class="label-text-field">Velocity of Detection</p>
                        <v-text-field v-model="velocity" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersKomite" :items="fraudSuspect"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:item="allKomite">
                                <tr>
                                  <td align="center">
                                    {{ allKomite.item.seqNo }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.pelakuNik }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.pelakuPosition }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.rekomendasiPasalName }}
                                  </td>
                                  <td align="center">
                                    {{ allKomite.item.rekomendasiSanksiName }}
                                  </td>
                                  <td align="center">
                                    <v-select :items="pasalKomite" item-text="paramName" item-value="paramCd"
                                      v-model="allKomite.item.kfPasal" label="" class="rp mt-3 mb-3" hide-details="auto"
                                      outlined dense clearable placeholder="" style="border-radius: 8px"></v-select>
                                  </td>
                                  <td align="center">
                                    <v-select :items="sanksiKomite" item-text="paramName" item-value="paramCd"
                                      v-model="allKomite.item.kfSanksi" label="" class="rp mt-3 mb-3" hide-details="auto"
                                      outlined dense clearable placeholder="" style="border-radius: 8px"></v-select>
                                  </td>
                                  <td align="center">
                                    <v-menu ref="menuRealisasiSanksiDate" v-model="menuRealisasiSanksiDate[allKomite.index]
                                      " :close-on-content-click="false" transition="scale-transition" offset-y
                                      min-width="290px">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="allKomite.item.realisasiSanksiDate
                                          " label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                          outlined dense hide-details="auto" class="rp mb-0" placeholder="" :readonly="reportStatus == 'Komite Fraud Done'
                                            "></v-text-field>
                                      </template>
                                      <v-date-picker v-model="realisasiSanksiDateVal" no-title scrollable @input="
                                        formatRealisasiSanksiDate(
                                          allKomite.index
                                        )
                                        ">
                                      </v-date-picker>
                                    </v-menu>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Reviewed' ||
                        reportStatus == 'Komite Fraud Outstanding'
                        ">
                        <p class="label-text-field">Keputusan</p>
                        <v-textarea v-model="decisionKomiteSub" class="rp mb-0" hide-details="auto" outlined dense
                          placeholder=""></v-textarea>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Komite Fraud Done' ||
                        reportStatus == 'In Sanction' ||
                        reportStatus == 'Closed'
                        ">
                        <p class="label-text-field">Keputusan</p>
                        <v-textarea v-model="decisionKomite" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly></v-textarea>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Komite Fraud Done' ||
                        reportStatus == 'In Sanction' ||
                        reportStatus == 'Closed'
                        ">
                        <p class="label-text-field">Upload Memo KF</p>
                        <v-col cols="12" v-for="item in attachmentKomite" :key="item">
                          <a v-if="item.flowName == 'Memo KF'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                      <v-col cols="12" v-if="reportStatus == 'Reviewed' ||
                        reportStatus == 'Komite Fraud Outstanding'
                        ">
                        <p class="label-text-field">Upload Memo KF</p>
                        <v-row>
                          <div v-for="(item, index) in fraudAttachmentKf" :key="index" class="dropbox ml-1">
                            <input :rules="uploadRules" type="file" multiple :name="uploadFieldName"
                              @change="uploadFile($event, index)" accept="image/*" class="input-file" ref="file" />

                            <div class="d-flex">
                              <v-icon v-if="!item.attachFilePath" aria-hidden="false" color="grey lighten-3"
                                class="mt-8 ml-13" size="65px">
                                mdi-image-plus
                              </v-icon>
                              <v-btn v-if="!item.attachFilePath" x-small outlined color="white" @click="removeFile(index)"
                                class="mt-1" style="margin-left: 20px">
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </div>

                            <div class="jpg d-flex">
                              <v-col v-if="item.attachFilePath && uploadPercentage < 100
                                " class="mt-12">
                                <p class="ml-14">{{ uploadPercentage }}%</p>
                                <progress max="100" :value.prop="uploadPercentage" class="ml-4"></progress>
                              </v-col>

                              <v-row v-if="(uploadPercentage = 100)">
                                <img v-if="ekstension[index] == '.jpg' ||
                                  ekstension[index] == '.JPG'
                                  " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />

                                <v-btn v-if="ekstension[index] == '.jpg' ||
                                  ekstension[index] == '.JPG'
                                  " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                                  <v-icon aria-hidden="false" color="orange">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </div>

                            <div class="png d-flex">
                              <v-row>
                                <img v-if="ekstension[index] == '.png' ||
                                  ekstension[index] == '.PNG'
                                  " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />
                                <v-btn v-if="ekstension[index] == '.png' ||
                                  ekstension[index] == '.PNG'
                                  " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                                  <v-icon aria-hidden="false" color="orange">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </div>

                            <div class="pdf d-flex">
                              <v-row>
                                <img v-if="ekstension[index] == '.pdf' ||
                                  ekstension[index] == '.PDF'
                                  " src="@/assets/Pdf.svg" alt="" class="preview mt-8 ml-4" />

                                <v-btn v-if="ekstension[index] == '.pdf' ||
                                  ekstension[index] == '.PDF'
                                  " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                                  <v-icon aria-hidden="false" color="orange">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </div>

                            <div class="docx d-flex">
                              <v-row>
                                <img v-if="ekstension[index] == '.docx' ||
                                  ekstension[index] == '.DOCX'
                                  " src="@/assets/docx.svg" alt="" class="preview mt-8 ml-4" />

                                <v-btn v-if="ekstension[index] == '.docx' ||
                                  ekstension[index] == '.DOCX'
                                  " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                                  <v-icon aria-hidden="false" color="orange">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </div>

                            <div class="imageData d-flex">
                              <v-row>
                                <img v-if="item.imageData && ekstension[index] == null
                                  " :src="item.imageData" alt="" class="preview mt-8 ml-4" />

                                <v-btn v-if="item.imageData && ekstension[index] == null
                                  " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                                  <v-icon aria-hidden="false" color="orange">
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-row>
                            </div>

                            <p v-if="!item.attachFileName" class="grey--text mt-4 ml-8">
                              Upload File {{ index + 1 }}
                              <v-progress-linear v-if="isLoadingPhoto[index]" indeterminate></v-progress-linear>
                            </p>
                            <p v-if="item.attachFileName" class="ml-6" style="text-align: initial">
                              {{ item.attachFileName }}
                            </p>
                            <p v-if="item.attachFileName && !item.imageData" class="size ml-6">
                              {{ attachFileSize[index] }} mb
                            </p>
                            <p v-if="item.imageSize" class="size ml-6">
                              {{ item.imageSize }} mb
                            </p>
                          </div>
                          <div class="dropbox">
                            <v-btn x-large outlined color="white" @click="addFraudAttachmentKf" class="mt-16">
                              <v-col>
                                <v-icon aria-hidden="false" color="orange" class="mt-5 mb-5 ml-4" size="35px">
                                  mdi-plus
                                </v-icon>
                                <v-row>
                                  <span class="orange--text ml-4">Tambah File</span>
                                </v-row>
                              </v-col>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </template>
          </v-card>
          <!-- </div> -->
          <v-row>
            <v-col class="text-right">
              <v-btn :to="`/komite-fraud`" color="primary" x-large depressed outlined
                class="elevation-0 text-capitalize mr-3 primary--text"><span class="primary--text">Back</span></v-btn>
              <v-btn v-if="submitBtn == true &&
                (reportStatus == 'Komite Fraud Done' ||
                  reportStatus == 'In Sanction' ||
                  reportStatus == 'Closed')
                " class="elevation-0 text-capitalize mr-3 primary--text" color="primary" x-large depressed
                @click="editKomite"><span class="white--text">Submit</span></v-btn>

              <v-btn v-if="submitBtn == true &&
                (reportStatus == 'Reviewed' ||
                  reportStatus == 'Komite Fraud Outstanding')
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="submitKomite"><span class="white--text">Submit</span></v-btn>
            </v-col>
          </v-row>

          <!-- <div class="bottom"> -->
          <v-card rounded="lg">
            <v-card-text>
              <v-row class="mx-1">
                <v-col>
                  <h7 class="label-text-field" color="primary"> History </h7>
                  <v-data-table :headers="headersLog" :items="fraudLog" :items-per-page="5"
                    :header-props="{ sortIcon: null }" disable-sort>
                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td align="center">{{ item.seqNo }}</td>
                        <td align="center">{{ item.processDate }}</td>
                        <td align="center">{{ item.processBy }}</td>
                        <td align="center">{{ item.processStatusName }}</td>
                        <td align="center">{{ item.processComment }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- </div> -->
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      rootCauseStatus: [],
      rootCauseList: [],
      rootCouse: [],
      rootCauseApi: [],
      isLoadingPhoto: [false, false],
      submitBtn: false,
      validFormKomiteFraud: false,
      investigatorName: "",
      investigatorNik: "",
      positionInvestigator: "",
      emailInvestigator: "",
      phoneNumber: "",

      reportType: "",
      jagaNo: "",
      menuReportDate: false,
      reportDate: "",
      reportDateVal: null,
      fraudType: [],
      infoFraud: [],
      fraudInfo: "",
      fraudInfoOther: "",
      branchCd: "",
      initialLoss: "",
      attachment: "",
      sharePoint: "",

      verifStatus: [],
      // returnNote: "",//?
      // menuReturnDate: false,//?
      // returnDate: "",//?
      // returnDateVal: null,//?
      reportDoc: "",
      spPelaku: "",
      spNasabah: "",
      investigatorManager: "",
      investigatorPhone: "",
      delegateBy: [],
      eksternalNote: "",

      investigationStatus: [],
      //fraudDetail: "",
      tujuanFraudList: [],
      fraudFactor: "",
      fraudFactorOther: "",
      // fraudReason: "",
      // fraudPurpose: "",
      // fraudControl: "",
      jenisPelanggaranList: [],
      violationType: "",
      violationTypeOther: "",
      menuStartDate: false,
      startDate: "",
      startDateVal: null,
      menuEndDate: false,
      endDate: "",
      endDateVal: null,
      recoveryDateMenu: false,
      fraudCommitedDateTo: "",
      recoveryDateVal: null,
      attachmentAfm: "",
      expandPembiayaanFiktif: false,
      indexExpand: 0,
      lossChosen: {
        paramValue: 5,
      },
      panelIndex: [],
      detailLoss: [],
      lossList: [],
      isiPelakuList: [],
      pelakuFraudDialog: false,
      dialogKeterangan: {},
      documentDialog: false,
      isiDocumentList: [],
      statusAngsuranList: [],
      transaksiTypeList: [],
      prsCollectionList: [],
      brwCategoryList: [],
      kasSolCategoryList: [],
      pelakuPencurianList: [],

      chronology: "",
      moneyUsage: "",
      decision: "",
      noteReview: "",
      sopMemoList: [],
      sopMemo: "",
      unsurList: [],
      attachmentReview: "",
      reviewStatus: [],

      menuKomiteDateSub: false,
      komiteDateSub: "",
      komiteDateValSub: null,
      komiteNoSub: "",
      velocitySub: "",
      //menuRealisasiSanksiDateSub: [],
      //realisasiSanksiDate: "",
      ///realisasiSanksiDateValSub: null,
      decisionKomiteSub: "",
      pasalKomite: [],
      sanksiKomite: [],
      fraudAttachmentKf: [
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Memo KF",
          attachFileImage: "",
          imageData: "",
          imageSize: "",
        },
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Memo KF",
          attachFileImage: "",
          imageData: "",
          imageSize: "",
        },
      ],

      menuKomiteDate: false,
      komiteDate: "",
      komiteDateVal: null,
      komiteNo: "",
      velocity: "",
      decisionKomite: "",
      attachmentKomite: "",
      ekstension: [],
      attachFileSize: [],
      uploadFraud: [],
      file: "",
      uploadPercentage: 0,
      menuRealisasiSanksiDate: [],
      realisasiSanksiDate: "",
      realisasiSanksiDateVal: null,

      reportStatus: "",

      // optionsPctg: {
      //   locale: "id-ID",
      //   prefix: "",
      //   suffix: "%",
      //   length: 12,
      //   precision: 0,
      // },
      // readMoney: true,

      fraudLog: [],
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp.",
        suffix: "",
        length: 12,
        precision: 0,
      },
      headersLog: [
        {
          text: "No.",
          align: "center",
        },
        {
          text: "Process Date",
          align: "center",
        },
        {
          text: "Process By",
          align: "center",
        },
        {
          text: "Process Status",
          align: "center",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "288px",
        },
      ],
      headersSuspect: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "130px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          width: "118px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Status Kepagawaian Pelaku",
          align: "center",
          width: "170px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          width: "170px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          width: "170px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          width: "126px",
        },
        {
          text: "Pendidikan",
          align: "center",
          width: "126px",
        },
      ],
      headersModus: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "280px",
        },
        {
          text: "Kronologis Kejadian",
          align: "center",
          width: "620px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "620px",
        },
      ],
      headersSummaryStatus: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "Nama Karyawan",
          align: "center",
          width: "200px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "120px",
        },
        {
          text: "Status",
          align: "center",
          width: "120px",
        },
        {
          text: "NIK",
          align: "center",
          width: "126px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "300px",
        },
        {
          text: "Potensi Kerugian Awal",
          align: "center",
          width: "200px",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
        },
        {
          text: "Confirm Status",
          align: "center",
          width: "126px",
        },
      ],
      headersSanksi: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "160px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Rekomendasi Pasal",
          align: "center",
          width: "224px",
        },
        {
          text: "Rekomendasi Sanksi",
          align: "center",
          width: "224px",
        },
      ],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      headersViolation: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "MMS",
          align: "center",
          width: "150px",
        },
        {
          text: "Kode MMS",
          align: "center",
          width: "150px",
        },
        {
          text: "Modus",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "150px",
        },
        {
          text: "Status",
          align: "center",
          width: "150px",
        },
        {
          text: "SP Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "200px",
        },
        {
          text: "Sp Nasabah / Pihak Ketiga",
          align: "center",
          width: "150px",
        },
        {
          text: "Amount",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan",
          align: "center",
          width: "150px",
        },
        {
          text: "Prospera / T24",
          align: "center",
          width: "150px",
        },
        {
          text: "Penarikan",
          align: "center",
          width: "150px",
        },
        {
          text: "Agendaku",
          align: "center",
          width: "150px",
        },
        // {
        //   text: "Rekap Ulang Pulang",
        //   align: "center",
        //   width: "150px",
        // },
        {
          text: "BKL",
          align: "center",
          width: "150px",
        },
        {
          text: "BKC",
          align: "center",
          width: "150px",
        },
        {
          text: "SPPU / FPHC",
          align: "center",
          width: "150px",
        },
      ],
      headersKomite: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "130px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "Status Kepagawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Rekomendasi Pasal",
          align: "center",
          width: "200px",
        },
        {
          text: "Rekomendasi Sanksi",
          align: "center",
          width: "200px",
        },
        {
          text: "Pasal Sesuai Komite Fraud",
          align: "center",
          width: "200px",
        },
        {
          text: "Sanksi Sesuai Komite Fraud",
          align: "center",
          width: "200px",
        },
        {
          text: "Monitoring Realisasi Sanksi",
          align: "center",
          width: "200px",
        },
      ],
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafon (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
        }
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "150px",
        },
      ],
    };
  },

  created() {
    this.requiredFeature('KOMITE_FRAUD')
    this.changeTitleApp("Komite Fraud");
    this.handleGetLossList();
  },

  async mounted() {
    this.loadDataKomiteFraud();
    this.loadFraudInfo();
    this.loadTujuanFraud();
    this.loadJenisPelanggaran();
    this.loadSopMemo();
    this.loadDataUnsurPelanggaran();
    this.loadPasalKomite();
    this.loadSanksiKomite();
    this.handleGetStatusAngsuran();
    this.handleGetTransaksiType();
    this.handleGetPrsCollection();
    this.handleGetBrwCategory();
    this.handleGetKasSolCategory();
    this.handleGetPelakuPencurian();
    this.handleSearchFraudPelaku();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getKomiteFraudByFraudId",
      "getParameterByParamType",
      "getUnsurPelanggaran",
      "submitKomiteFraud",
      "downloadAttachment",
      "uploadKomiteFraud",
      "searchFraudPelaku",
      "downloadFilePelaku"
    ]),

    handleSearchFraudPelaku() {
      this.searchFraudPelaku(this.idFraud)
        .then(response => {
          console.log('response', response)
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    handleDownloadFilePelaku(item, type) {
    this.downloadFilePelaku({
      pelakuDtlId: item.pelakuDetailId,
      type: type
    })
      .then((response) => {
        console.log(response, "response")
      })
      .catch((err) => {
        console.log("gagal download", err.response);
        this.$helpers.handleError(err);
        this.$store.commit("setShowSnackbar", {
                snackbarMsg: "Gagal mengunduh. File tidak ditemukan",
                snackbarColor: "red",
        })      
        return
      });  
    },
    isMerged(item, index) {
    if (index === 0) return false;
    return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    sumField(key) {
        return this.daftarKerugianList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    onExpansionPanelClick(index, event) {
      console.log("apa isi panelIndex", this.panelIndex);
      console.log("index", index);
      if (
        event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        console.log("Panel is closing/now closed.");
        //this.handleSaveAuto(index);
      } else {
        console.log("Panel is opening/now open.");
      }
    },

    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },

    handleGetLossList() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.detailLoss = response.data.data;
        console.log("isi detailLoss", this.detailLoss);
      });
    },

    openPelakuDialog(isi, fraud) {
      this.isiPelakuList = isi;
      this.netFraudText = fraud;
      this.pelakuFraudDialog = true;
    },

    openDocumentDialog(document) {
      this.isiDocumentList = document;
      this.documentDialog = true;
    },

    handleDownloadAttachment(item) {
      this.downloadAttachment({
        base64Data: "",
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((response) => {
          console.log("response oke", response);

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },

    viewUnsur(fileName, filePath) {
      this.downloadAttachment({
        base64Data: "",
        fileName: fileName,
        filePathName: filePath,
      })
        .then((response) => {
          if (fileName.substr(fileName.length - 3) == 'pdf' || fileName.substr(fileName.length - 3) == 'PDF') {
            var ieEDGE = navigator.userAgent.match(/Edge/g);
            var ie = navigator.userAgent.match(/.NET/g); // IE 11+
            var oldIE = navigator.userAgent.match(/MSIE/g);
            // var bytes = new Uint8Array(resp.data); //use this if data is raw bytes else directly pass resData
            var blob = new window.Blob([response.data], { type: 'application/pdf' });

            if (ie || oldIE || ieEDGE) {
              window.navigator.msSaveBlob(blob, fileName);
            }
            else {
              var fileURL = URL.createObjectURL(blob);
              var win = window.open();
              win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')

            }
          } else {
            var blobImage = response.data;

            var reader = new FileReader();
            reader.readAsDataURL(blobImage);
            reader.onload = function () {
              var base64Data = reader.result;
              var image = new Image();
              image.src = base64Data;

              var w = window.open("");
              w.document.write(image.outerHTML);
              return;
            };
          }
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },

    downloadUnsur(fileName, filePath) {
      this.downloadAttachment({
        base64Data: "",
        fileName: fileName,
        filePathName: filePath,
      })
        .then((response) => {
          console.log("response oke", response);

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.download = fileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },

    formatKomiteDate() {
      this.menuKomiteDateSub = false;
      this.komiteDateSub = moment(this.komiteDateValSub).format("DD-MMM-YYYY");
    },

    formatRealisasiSanksiDate(index) {
      console.log("index", index);
      this.menuRealisasiSanksiDate[index] = false;
      this.fraudSuspect[index].realisasiSanksiDate = moment(
        this.realisasiSanksiDateVal
      ).format("DD-MMM-YYYY");
    },

    addFraudAttachmentKf() {
      this.fraudAttachmentKf.push({
        attachFileName: "",
        attachFilePath: "",
        flowName: "Memo KF",
        attachFileImage: "",
        imageData: "",
        imageSize: "",
      });
      this.isLoadingPhoto.push(false);
    },

    uploadFile(event, index) {
      console.log("fraudAttachment", event);

      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);

      this.isLoadingPhoto.splice(index, 1, true);

      this.uploadKomiteFraud(formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          this.uploadFraud = response.data.data;
          console.log(this.uploadFraud, "upload");

          this.fraudAttachmentKf[index].attachFilePath =
            this.uploadFraud.filePathName;

          this.fraudAttachmentKf[index].attachFileName =
            this.uploadFraud.fileName;

          var ext = this.fraudAttachmentKf[index].attachFileName.substring(
            this.fraudAttachmentKf[index].attachFileName.lastIndexOf("."),
            this.fraudAttachmentKf[index].length
          );

          this.ekstension[index] = ext;

          this.attachFileSize[index] = (
            event.target.files[0].size /
            (1024 * 1024)
          ).toFixed(2);

          var reader = new FileReader();
          reader.onload = (e) => {
            this.fraudAttachmentKf[index].attachFileImage = e.target.result;
            //console.log("attachFileImage", this.attachFileImage[index]);
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch((err) => {
          this.isLoadingPhoto.splice(index, 1, false);
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    removeFile(index) {
      this.fraudAttachmentKf.splice(index, 1);
      this.ekstension.splice(index, 1);
      this.attachFileSize.splice(index, 1);
      this.isLoadingPhoto.splice(index, 1);
    },

    loadDataKomiteFraud() {
      this.getKomiteFraudByFraudId({
        fraudId: this.idFraud,
      }).then((res) => {
        this.dataKomiteFraud = res.data.data;

        //Log
        this.fraudLog = this.dataKomiteFraud.fraudLogList;

        this.jenisPelanggaranListToSave = this.dataKomiteFraud.jenisPelanggaranList;
        //Data Investigator
        this.investigatorName =
          this.dataKomiteFraud.investigationDelegatorName == null
            ? "-"
            : this.dataKomiteFraud.investigationDelegatorName;
        this.investigatorNik =
          this.dataKomiteFraud.investigationDelegatorNik == null
            ? "-"
            : this.dataKomiteFraud.investigationDelegatorNik;
        this.positionInvestigator =
          this.dataKomiteFraud.picInvestigatorPosition == ""
            ? "-"
            : this.dataKomiteFraud.picInvestigatorPosition;
        this.emailInvestigator =
          this.dataKomiteFraud.picInvestigatorEmail == ""
            ? "-"
            : this.dataKomiteFraud.picInvestigatorEmail;
        this.phoneNumber =
          this.dataKomiteFraud.picInvestigatorPhone == ""
            ? "-"
            : this.dataKomiteFraud.picInvestigatorPhone;

        //Detail Laporan
        this.reportType =
          this.dataKomiteFraud.reportType == null
            ? "-"
            : this.dataKomiteFraud.reportType;
        this.jagaNo =
          this.dataKomiteFraud.jagaNo == null
            ? "-"
            : this.dataKomiteFraud.jagaNo;
        this.reportDate = moment(this.dataKomiteFraud.reportDate).format(
          "DD-MMM-YYYY"
        );
        this.fraudType =
          this.dataKomiteFraud.fraudTypeName == null
            ? "-"
            : this.dataKomiteFraud.fraudTypeName;
        this.fraudInfo =
          this.dataKomiteFraud.fraudInfName == null
            ? "-"
            : this.dataKomiteFraud.fraudInfName;
        this.fraudInfoOther =
          this.dataKomiteFraud.fraudInfReason == null
            ? "-"
            : this.dataKomiteFraud.fraudInfReason;
        this.fraudSuspect = this.dataKomiteFraud.fraudPelakuList;
        this.fraudSuspect.map((item) => {
          item.realisasiSanksiDate == "" || item.realisasiSanksiDate == null
            ? (item.realisasiSanksiDate = "")
            : (item.realisasiSanksiDate = moment(
              item.realisasiSanksiDate
            ).format("DD-MMM-YYYY"));
        });
        this.locationCd =
          this.dataKomiteFraud.fraudLoc == null
            ? "-"
            : this.dataKomiteFraud.fraudLoc;
        this.locationName =
          this.dataKomiteFraud.fraudLocName == null
            ? "-"
            : this.dataKomiteFraud.fraudLocName;
        this.branchCd = `${this.locationCd} - ${this.locationName}`;

        function formatAsCurrency(value, dec) {
          dec = dec || 0;
          if (value === null) {
            return 0;
          }
          return (
            "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1. ")
          );
        }

        this.initialLoss = formatAsCurrency(this.dataKomiteFraud.initialLoss);
        this.fraudMethod = this.dataKomiteFraud.fraudMthdList;
        this.attachment = this.dataKomiteFraud.fraudAttList;
        this.sharePoint =
          this.dataKomiteFraud.sharepointLink == null
            ? "-"
            : this.dataKomiteFraud.sharepointLink;

        //Verifikasi Data
        this.verifStatus = this.dataKomiteFraud.verifStatusName;
        // this.returnNote =
        //   this.dataKomiteFraud.returnReason == null
        //     ? "-"
        //     : this.dataKomiteFraud.returnReason;
        // this.returnDate = moment(this.dataKomiteFraud.returnDate).format(
        //   "DD-MMM-YYYY"
        // );
        this.reportDoc = this.dataKomiteFraud.reportDocTypeList;
        this.spPelaku = formatAsCurrency(this.dataKomiteFraud.spPelaku);
        this.spNasabah = formatAsCurrency(this.dataKomiteFraud.spNasabah);
        this.investigatorManager =
          this.dataKomiteFraud.investigationDelegatorName;
        this.investigatorPhone =
          this.dataKomiteFraud.investigationDelegatorPhone;
        this.delegateBy =
          this.dataKomiteFraud.investigationDelegatorPositionName;
        this.eksternalNote = this.dataKomiteFraud.extFraudMthdDesc;

        //Investigasi Fraud
        //this.fraudRoot = this.dataKomiteFraud.rootCauseList;
        this.rootCauseApi = this.dataKomiteFraud.rootCauseList;
        this.loadStatusRoot();
        this.investigationStatus = this.dataKomiteFraud.investigationStatusName;
        //this.fraudDetail = this.dataKomiteFraud.fraudDetail;
        this.fraudFactor = this.dataKomiteFraud.fraudFactorName;
        this.fraudFactorOther =
          this.dataKomiteFraud.fraudFactorDesc == null
            ? "-"
            : this.dataKomiteFraud.fraudFactorDesc;
        // this.fraudReason = this.dataKomiteFraud.fraudReason;
        // this.fraudPurpose = this.dataKomiteFraud.fraudPurpose;
        // this.fraudControl = this.dataKomiteFraud.fraudControl;
        this.violationType = this.dataKomiteFraud.violationTypeName;
        this.violationTypeOther =
          this.dataKomiteFraud.violationTypeDesc == null
            ? "-"
            : this.dataKomiteFraud.violationTypeDesc;
        this.startDate = moment(this.dataKomiteFraud.fraudCommitedDate).format(
          "DD-MMM-YYYY"
        );
        this.endDate = moment(this.dataKomiteFraud.fraudKnownDate).format(
          "DD-MMM-YYYY"
        );
        this.fraudCommitedDateTo = moment(
          this.dataKomiteFraud.fraudCommitedDateTo
        ).format("DD-MMM-YYYY");
        this.attachmentAfm = this.dataKomiteFraud.fraudAttAFMList;

        let temp = this.dataKomiteFraud.detailLossList;

        for (var i = 0; i < this.detailLoss.length; i++) {
          this.lossList.push([]);
        }

        temp.map((item) => {
          //item.recordFlag = "E";
          console.log(item.lossType);
          let index = this.detailLoss.findIndex(
            (x) => x.paramCd === item.lossType
          );

          item.fraudRepayDate = moment(item.fraudRepayDate).format(
            "DD-MMM-YYYY"
          );

          item.pencairanDate = moment(item.pencairanDate).format("DD-MMM-YYYY");

          item.fpbTransactionDate = moment(item.fpbTransactionDate).format(
            "DD-MMM-YYYY"
          );

          this.lossList[index].push(item);
        });

        console.log("isi lossList", this.lossList);

        //Review Fraud
        this.lossSummary = this.dataKomiteFraud.lossSummaryList;
        this.chronology = this.dataKomiteFraud.rvwIncidentDesc;
        this.moneyUsage = this.dataKomiteFraud.rvwMoneyUsage;
        this.decision = this.dataKomiteFraud.rvwDecision;
        this.noteReview = this.dataKomiteFraud.rvwNotes;
        this.sopMemo = this.dataKomiteFraud.rvwSopMemoJuknisName;
        this.attachmentReview = this.dataKomiteFraud.fraudAttReviewList;
        this.reviewStatus = this.dataKomiteFraud.rvwStatusName;

        //Addition for Komite Request;
        this.reportStatus = this.dataKomiteFraud.reportStatusName;

        //Komite Fraud
        this.komiteDate = this.dataKomiteFraud.kfDate
          ? moment(this.dataKomiteFraud.kfDate).format("DD-MMM-YYYY")
          : "";
        this.komiteNo = this.dataKomiteFraud.kfNo;
        this.velocity = this.dataKomiteFraud.velocityDetection;
        this.velocitySub = this.dataKomiteFraud.velocityDetection;
        this.decisionKomite = this.dataKomiteFraud.kfResult;
        this.attachmentKomite = this.dataKomiteFraud.fraudAttMemoKfList;

        this.komiteDateSub = this.dataKomiteFraud.kfDate
          ? moment(this.dataKomiteFraud.kfDate).format("DD-MMM-YYYY")
          : "";
        this.komiteNoSub = this.dataKomiteFraud.kfNo;
        this.fraudAttachmentKf = this.dataKomiteFraud.fraudAttMemoKfList;

        for (let index = 0; index < this.fraudAttachmentKf.length; index++) {
          this.downloadAttachment({
            base64Data: "",
            fileName: this.fraudAttachmentKf[index].attachFileName,
            filePathName: this.fraudAttachmentKf[index].attachFilePath,
          }).then((response) => {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = (e) => {
              console.log("event", e);
              this.fraudAttachmentKf[index].imageData = e.target.result;

              this.fraudAttachmentKf[index].imageSize = (
                e.total /
                (1024 * 1024)
              ).toFixed(2);
            };
          });
        }
      });
    },

    loadFraudInfo() {
      this.getParameterByParamType({
        paramType: "FRAUD_INF",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.infoFraud = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadTujuanFraud() {
      this.getParameterByParamType({
        paramType: "FRAUD_FACTOR",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.tujuanFraudList = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadJenisPelanggaran() {
      this.getParameterByParamType({
        paramType: "VIOLATION_TYPE",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.jenisPelanggaranList = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadSopMemo() {
      this.getParameterByParamType({
        paramType: "SOP_MEMO_JUKNIS",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.sopMemoList = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadDataUnsurPelanggaran() {
      this.getUnsurPelanggaran({
        fraudId: this.idFraud,
        pageNumber: 0,
        pageSize: 1000,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.unsurList = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadPasalKomite() {
      this.getParameterByParamType({
        paramType: "PASAL",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.pasalKomite = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadSanksiKomite() {
      this.getParameterByParamType({
        paramType: "SANKSI",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.sanksiKomite = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    handleGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.statusAngsuranList = response.data.data;
      });
    },

    handleGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.transaksiTypeList = response.data.data;
      });
    },

    handleGetPrsCollection() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PRS_COLLECTION",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.prsCollectionList = response.data.data;
      });
    },

    handleGetBrwCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "BRW_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.brwCategoryList = response.data.data;
      });
    },

    handleGetKasSolCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "KAS_SOL_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.kasSolCategoryList = response.data.data;
      });
    },

    handleGetPelakuPencurian() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PELAKU_PENCURIAN",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.pelakuPencurianList = response.data.data;
      });
    },

    loadStatusRoot() {
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
        .then((resp) => {
          let rootCauseStatusa = resp.data.data;
          console.log("rootCauseStatusa", rootCauseStatusa);
          for (let index = 0; index < rootCauseStatusa.length; index++) {
            this.rootCouse.push({
              createBy: "",
              createDate: "",
              fraudId: this.fraudId,
              lossDocId: 0,
              recordFlag: "",
              rootCause: rootCauseStatusa[index].paramCd,
              rootCauseDesc: "",
              rootCauseList: [],
              rootCauseName: rootCauseStatusa[index].paramName,
              seqNo: 0,
              updateBy: "",
              updateDate: "",
            });

            this.rootCauseStatus.push([]);

            this.getParameterByParamType({
              pageNumber: 0,
              pageSize: 100,
              paramCd: "",
              paramName: "",
              paramType: rootCauseStatusa[index].paramCd,
              paramValue: "",
              searchValue: "",
            }).then((response) => {
              response.data.data.map((item) => {
                item.rootCause = item.paramType;
                delete item.paramType;
                item.rootCauseDesc = item.paramCd;
                delete item.paramCd;
                item.rootCauseName = item.paramName;
                delete item.paramName;
                item.rootCauseDescName = "";
                delete item.paramTypeName;
                delete item.paramValue;
              });
              this.rootCauseStatus.splice(index, 1, response.data.data);

              console.log("JJ", response.data.data);
            });
          }

          if (this.rootCauseApi.length != 0) {
            this.rootCauseApi.map((item) => {
              let indexs = this.rootCouse.findIndex(
                (x) => x.rootCause === item.rootCause
              );
              this.rootCouse.splice(indexs, 1, item);
              // console.log('apa isisisis', this.rootCouse[indexs])
              // .push(temp[index]);
            });
            console.log("rootCause awal", this.rootCouse);
          }

          console.log("isi rootCause", this.rootCauseStatus);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    submitKomite() {
      this.fraudAttachmentKf.map((item) => {
        item.attachFileImage = "";
      });
      this.fraudSuspect.map((item) => {
        item.realisasiSanksiDate = item.realisasiSanksiDate ? moment(
          item.realisasiSanksiDate,
          "DD-MMM-YYYY"
        ).format("YYYY-MM-DD") : null;
      });
      this.submitKomiteFraud({
        fraudId: this.idFraud,
        fraudAttList: this.fraudAttachmentKf,
        fraudPelakuList: this.fraudSuspect,
        kfDate: this.komiteDateSub
          ? moment(this.komiteDateSub, "DD-MMM-YYYY").format("YYYY-MM-DD")
          : null,
        kfNo:
          this.komiteNoSub != null ? this.komiteNoSub.trim() : this.komiteNoSub,
        kfResult:
          this.decisionKomiteSub != null
            ? this.decisionKomiteSub.trim()
            : this.decisionKomiteSub,
        velocityDetection: this.velocitySub,
      })
        .then((response) => {
          this.$router.push(`/komite-fraud`);
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    editKomite() {
      this.fraudSuspect.map((item) => {
        item.realisasiSanksiDate = item.realisasiSanksiDate ? moment(
          item.realisasiSanksiDate,
          "DD-MMM-YYYY"
        ).format("YYYY-MM-DD") : null;
      });
      this.submitKomiteFraud({
        fraudId: this.idFraud,
        fraudAttList: this.attachmentKomite,
        fraudPelakuList: this.fraudSuspect,
        kfDate: this.komiteDate
          ? moment(this.komiteDate, "DD-MMM-YYYY").format("YYYY-MM-DD")
          : null,
        kfNo: this.komiteNo,
        kfResult: this.decisionKomite,
        velocityDetection: this.velocity,
      })
        .then((response) => {
          this.$router.push(`/komite-fraud`);
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },
  },

  computed: {
    ...mapState(["token"]),
    idFraud: function () {
      return this.$route.params.fraudId;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
  },
};
</script>

<style src="./KomiteFraud.scss" lang="scss" />
