import store from './store/index'
// import router from './router/index'

export default { 
    handleError (error) { 
        // console.log('this is helper handleError')
        if (error.response) {
            console.log('apa', error.response)
            let response = error.response;
            // console.log(response)
            let data = response.data;
            // console.log(data.status)
            switch(response.status){
                case 403 :
                    store.commit('setShowSnackbar', { snackbarMsg: response.statusText, snackbarColor : 'red' })
                    break
                // case 500 :
                //     store.commit('setShowSnackbar', { snackbarMsg: response.statusText, snackbarColor : 'red' })
                //     break
                default: 
                    if (data.status == 0){
                        const snackbarMsg = data.message
                        const snackbarColor = 'red'
                        store.commit('setShowSnackbar', { snackbarMsg, snackbarColor })
                    }
                    break
            }
        }else if (error.request) {
            // let req = error.request;
            // console.log('errorHandle: error request', error.request.response);
            let errReqMsg = JSON.parse(error.request.response)
            // console.log(errReqMsg.message)
            store.commit('setShowSnackbar', { snackbarMsg: errReqMsg.message, snackbarColor : 'red' })
        } else {
            // let msg = error.message;
            console.log('Error', error.message);
        }
    },
    handleSuccess (response) { 
        // console.log('this is helper handleSuccess')
        if (response) {
            let data = response.data;
            // console.log('dari handle success', data)
            let snackbarMsg = data.message
            let snackbarColor = data.status == 0 ? 'red' : 'green'
            store.commit('setShowSnackbar', { snackbarMsg, snackbarColor })
            return data
        }
    }
};