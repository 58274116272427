<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Parameter</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Kode Parameter/Nama Parameter</p>
        <v-text-field
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="searchValue"
          dense
          outlined
          placeholder="Cari kode parameter/nama parameter"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">Tipe Parameter</p>
        <!-- <v-select
          item-text="paramType"
          :items="paramType"
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="paramType"
          item-value="paramTypeCode"
          dense
          outlined
          clearable
          placeholder="Tipe Parameter"
        ></v-select> -->

        <v-select
          v-model="paramType"
          :items="paramTypeList"
          item-text="parameterTypeName"
          item-value="parameterTypeCode"
          label=""
          class="rp mb-0"
          hide-details="auto"
          outlined
          dense
          clearable
          placeholder="Semua"
          @click="loadParameterType"
        ></v-select>
      </v-col>
      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn
            @click="search"
            class="elevation-0 text-capitalize ml-3 mt-2"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span>
          </v-btn>
          <v-btn
            class="elevation-0 text-capitalize ml-3 mt-2 primary--text"
            depressed
            outlined
            cols="12"
            color="white"
            @click="reset"
          >
            <span class="orange--text">Reset</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-col class="text-right">
        <p class="transparent--text" color="primary"></p>
        <!-- <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-15 mt-2"
          color="primary"
          :to="`/parameter/form/add`"
        >
          <v-icon aria-hidden="false" color="white"> mdi-plus </v-icon>
          <span class="white--text">Tambah</span>
        </v-btn> -->
        <v-btn class="elevation-0 mt-2" color="primary" :to="`/parameter/form/add`">
          <v-icon left>
            mdi-plus
          </v-icon>
          Tambah
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="18">
        <div class="pf">
          <v-card cols="12">
            <v-card-text>
              <v-row style="width: 100%">
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="allInquiryParameter"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                    ><template v-slot:item="allParameter">
                      <tr>
                        <td class="orange--text">
                          {{ allParameter.item.paramName }}
                        </td>
                        <td>{{ allParameter.item.paramCd }}</td>
                        <td>{{ allParameter.item.paramTypeName }}</td>
                        <td>{{ allParameter.item.paramValue }}</td>
                        <td align="center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/parameter/${allParameter.item.paramCd}/form/edit`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-dialog
                            v-model="dialogDel[allParameter.item.paramCd]"
                            persistent
                            :key="allParameter.item.paramCd"
                            width="unset"
                          >
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    v-on="{ ...dialog, ...tooltip }"
                                    v-bind="attrs"
                                  >
                                    <v-icon
                                      aria-hidden="false"
                                      style="font-size: 20px"
                                      color="#F5821F"
                                    >
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[allParameter.item.paramCd] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Parameter?
                                  <p>
                                    {{ allParameter.item.paramName }}
                                  </p>
                                </div>
                                <v-btn
                                  class="paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      allParameter.item.paramCd,
                                      false
                                    )
                                  "
                                >
                                  <span> Batalkan </span>
                                </v-btn>

                                <v-btn
                                  class="paramDialogDelBtn paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeParameter(allParameter.item.paramCd),
                                      $set(
                                        dialogDel,
                                        allParameter.item.paramCd,
                                        false
                                      )
                                  "
                                >
                                  <span> Hapus </span>
                                </v-btn>

                                <!-- <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      allParameter.item.paramCd,
                                      false
                                    )
                                  "
                                >
                                  Tidak
                                </v-btn> -->
                                <!-- <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeParameter(allParameter.item.paramCd),
                                      $set(
                                        dialogDel,
                                        allParameter.item.paramCd,
                                        false
                                      )
                                  "
                                >
                                  Ya
                                </v-btn> -->
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      items: [],
      headers: [
        {
          text: "Nama Parameter",
          align: "center",
          width: "230px",
        },
        {
          text: "Kode Parameter",
          align: "center",
          width: "230px",
        },
        {
          text: "Tipe Parameter",
          align: "center",
          width: "230px",
        },
        {
          text: "Nilai Parameter",
          align: "center",
          width: "230px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "135px",
        },
      ],
      statusSearch: false,
      searchValueSearch: "",
      searchValue: "",
      pageNoModel: "",
      pageSizeModel: "",
      paramType: "",
      paramTypeList: [],
    };
  },
  async mounted() {
    this.load();
    this.loadParameterType();
  },
  created() {
    this.requiredFeature('PARAMETER')
    this.changeTitleApp("Master Parameter");
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "getInquiryParameter",
      "deleteParameter",
      "getInquiryParameterType",
    ]),

    reset: function () {
      this.searchValue = "";
      this.paramType = "";
      this.load();
    },

    load() {
      this.getInquiryParameter({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValue,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryParameter = [];
        });
    },

    loadParameterType() {
      this.getInquiryParameterType({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: "",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.paramTypeList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryParameterType = [];
        });
    },

    handlePageChange(value) {
      this.$store.dispatch("getInquiryParameter", {
        pageNumber: value - 1,
        pageSize: this.perPage,
        searchValue: this.searchValueSearch
          ? this.searchValue
          : this.searchValue,
      });
    },

    search() {
      let pageNumber = 0;
      let pageSize = this.countData;
      let searchValue = this.searchValue;
      let paramType = this.paramType;
      this.$store
        .dispatch("getInquiryParameter", {
          pageNumber,
          pageSize,
          searchValue,
          paramType
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (this.searchValue == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryParameter = [];
        });
      this.page = 1;
    },

    removeParameter(id) {
      this.deleteParameter(id)
        .then((response) => {
          this.load();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },

  computed: {
    ...mapState(["paramType"]),
    allInquiryParameter() {
      return Object.values(this.$store.getters.allInquiryParameter);
    },
  },
};
</script>

<style src="./Parameter.scss" lang="scss" />
