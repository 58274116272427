<template> 
  <v-container fluid>
    <v-dialog v-model="reDelegateDialog" transition="dialog-bottom-transition" max-width="600">
      <template>
        <v-card>
          <v-card>
            <v-card-title>
              Re-Delegate
            </v-card-title>
            <v-card-text class="mt-5">
              <span class="black--text">No JAGA</span>
              <v-row>
                <v-text-field label="" v-model="dataPerRow.jagaNo" class="ma-2" hide-details="auto" dense outlined placeholder="" disabled>
                </v-text-field>
              </v-row>
              <span class="black--text">PIC Investigator (Before)</span>
              <v-row>
                <v-text-field label="" v-model="dataPerRow.picInvestigatorName" class="ma-2" hide-details="auto" dense outlined placeholder="" disabled>
                </v-text-field>
              </v-row>
              <span class="black--text">PIC Investigator (To be)</span>
              <v-row>
                <v-text-field @click="picInvestigatorDialog = !picInvestigatorDialog" label="" class="ma-2"
                  hide-details="auto" v-model="newPicInvestigatorName" dense outlined placeholder="">
                </v-text-field>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card-actions class="justify-end">
            <v-btn depressed color="primary" @click="handleReDelegate">Re-Delegate</v-btn>
            <v-btn text @click="reDelegateDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="picInvestigatorDialog" max-width="700px">
      <v-card>
        <v-card-title class="black--text">
          Manager Investigator
        </v-card-title>
        <v-card-text class="mt-5">
          <span class="black--text">NIK Atau nama</span>
          <v-row>
            <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValueActor" dense outlined
              placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify">
            </v-text-field>
            <v-btn @click="loadDataKary(dataPerRow.investigationDelegatorNik, dataPerRow.investigationDelegatorPosition)" class="elevation-0 text-capitalize ml-5" color="primary">
              <span class="white--text">Cari</span>
            </v-btn>
          </v-row>
          <v-data-table :loading="isLoading" :headers="headersPelaku" :items="inquiryInvestigatorData" :items-per-page="5" disable-sort
            class="mt-5">
            <template v-slot:[`item.pelakuId`]="{ item }">
              <v-btn text class="primary--text" @click="pilihPelakuFraud(item)" icon>
                Pilih</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Investigasi Controller</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> No. Tiket JAGA </h7>
        <v-text-field v-model="jagaNo" class="mb-0" hide-details="auto" outlined dense placeholder="Cari No. Tiket JAGA"
          label="" style="background-color: #ffffff"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> No. Referensi </h7>
        <v-text-field v-model="refNo" class="mb-0" hide-details="auto" outlined dense placeholder="Cari No. Referensi"
          label="" style="background-color: #ffffff"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Lokasi Kejadian </h7>
        <v-text-field v-model="fraudLoc" class="mb-0" hide-details="auto" outlined dense
          placeholder="Cari lokasi kejadian" label="" style="background-color: #ffffff"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <h7 class="label-text-field" color="primary"> Tanggal Verifikasi </h7>
        <v-menu ref="reportDateFromMenu" v-model="reportDateFromMenu" :close-on-content-click="false"
          transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="reportDateFrom" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
              readonly outlined dense hide-details="auto" class="mb-0" placeholder="01-Jan-2022"
              style="background-color: #ffffff"></v-text-field>
          </template>
          <v-date-picker v-model="reportDateFromVal" no-title scrollable @input="pickReportDateFrom">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <h7 class="label-text-field transparent--text"> "" </h7>
        <v-menu ref="reportDateToMenu" v-model="reportDateToMenu" :close-on-content-click="false"
          transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="reportDateTo" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
              readonly outlined dense hide-details="auto" class="mb-0" placeholder="01-Jan-2022"
              style="background-color: #ffffff"></v-text-field>
          </template>
          <v-date-picker v-model="reportDateToVal" no-title scrollable @input="pickReportDateTo">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select v-model="reportStatus" :items="infoStatus" item-text="paramName" item-value="paramCd" class="mb-0"
          hide-details="auto" outlined dense placeholder="Semua" label="" style="background-color: #ffffff"></v-select>
      </v-col>
      <v-col>
        <h7 class="label-text-field transparent--text" color="primary"> "" </h7>
        <v-row cols="12" md="4" class="text--right">
          <v-row>
            <div class="searchBtn">
              <v-btn cols="12" @click="handleGetInquiryController">
                <p>Cari</p>
              </v-btn>
            </div>
            <div class="resetBtns">
              <v-btn cols="12" class="ml-0" @click="reset">
                <p>Reset</p>
              </v-btn>
            </div>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pi">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="headersController" :items="inquiryInvestigasiProcess" :items-per-page="5"
                    :header-props="{ sortIcon: null }" disable-sort mobile-breakpoint="0">
                    <template v-slot:item="allController">
                      <tr>
                        <td class="orange--text">{{ allController.item.jagaNo }}</td>
                        <td align="center">
                          {{ allController.item.refNo }}
                        </td>
                        <td align="center">
                          {{ allController.item.verifStartDateStr }}
                        </td>
                        <td align="center">
                          {{ allController.item.fraudLocName }}
                        </td>
                        <td align="center">
                          {{ allController.item.fraudTypeName }}
                        </td>
                        <td align="center">
                          <v-chip class="ma-2" color="orange lighten-4" text-color="orange">
                            {{ allController.item.reportStatusName }}
                          </v-chip>
                        </td>
                        <td align="center">
                          <span
                            :class="`${allController.item.slaColor ? allController.item.slaColor.toLowerCase() : 'black'}--text`">{{
                                allController.item.sla
                            }}
                          </span>
                        </td>
                        <td align="center">
                          <v-tooltip v-if="!(allController.item.reportStatus === 'REPORT_STATUS_DELEGATE_INVESTIGATOR' && isButtonReDelegate)" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs">
                                <v-icon @click="changeStatus('Delegate', allController.item.fraudId)"
                                  aria-hidden="false" style="font-size: 20px" color="#F5821F">
                                  mdi-account-plus-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Delegasi</span>
                          </v-tooltip>

                          <v-tooltip v-if="allController.item.reportStatus === 'REPORT_STATUS_VERIFIED' && isButtonReturn" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs">
                                <v-icon @click="changeStatus('Return', allController.item.fraudId)" aria-hidden="false"
                                  style="font-size: 20px" color="#F5821F">
                                  mdi-arrow-u-left-top
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Return</span>
                          </v-tooltip>

                          <v-tooltip v-if="allController.item.reportStatus === 'REPORT_STATUS_DELEGATE_INVESTIGATOR' && isButtonReDelegate" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn @click="openRedelegateDialog(allController.item), newPicInvestigatorName = '', dataPerRow = allController.item" small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs">
                                <v-icon aria-hidden="false" style="font-size: 20px" color="#F5821F">
                                  mdi-autorenew
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Re-Delegate</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      inquiryInvestigatorData: [],
      dataPerRow: {},
      reDelegateDialog: false,
      headersPelaku: [
        {
          text: "NIK",
          width: "10px",
          value: "nik",
          align: "center",
        },

        {
          text: "Nama",
          width: "76px",
          value: "name",
          align: "center",
        },
        {
          text: "Jabatan",
          width: "96px",
          value: "position",
          align: "center",
        },
        {
          text: "Organisasi",
          width: "96px",
          value: "organization",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "96px",
          value: "pelakuId",
          align: "center",
        },
      ],
      picInvestigatorDialog: false,
      reportDateFromMenu: false,
      reportDateToMenu: false,
      reportDate: "",
      reportDateFrom: "",
      reportDateTo: "",
      reportDateFromVal: null,
      reportDateToVal: null,
      jagaNo: "",
      refNo: "",
      fraudLoc: "",
      reportStatus: "",
      infoStatus: [],
      inquiryController: [],
      headersController: [
        {
          text: "No. Tiket JAGA",
          align: "center",
          // width: "180px",
        },
        {
          text: "No. Referensi",
          align: "center",
          // width: "180px",
        },
        {
          text: "Tanggal Verifikasi",
          // width: "140px",
          align: "center",
        },
        {
          text: "Lokasi Kejadian",
          // width: "200px",
          align: "center",
        },
        {
          text: "Jenis Fraud",
          // width: "110px",
          align: "center",
        },
        {
          text: "Status",
          // width: "120px",
          align: "center",
        },
        {
          text: "Running SLA",
          // width: "120px",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "200px",
          align: "center",
        },
      ],
      newPicInvestigatorNik: '',
      newPicInvestigatorName: ''
    };
  },

  created() {
    this.requiredFeature('INVESTIGASI_CONTROLLER')
    this.changeTitleApp("Investigasi Controller");
  },

  async mounted() {
    this.load();
    this.loadReportStatus();
    // this.loadDataKary();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getParameterByParamType",
      "searchDataInvestigasiProcess",
      "getManagerInvestigatorSearchValue",
      "reDelegateInvestigasi",
      "getInquiryLovInvestigator"
    ]),

    openRedelegateDialog(item){
      this.searchValueActor = ""
      this.reDelegateDialog = true;
      this.loadDataKary(item.investigationDelegatorNik, item.investigationDelegatorPosition);
    },

    loadDataKary(nik, position) {
      this.getInquiryLovInvestigator({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValueActor != null ? this.searchValueActor.trim() : this.searchValueActor,
        role: position,
        nikManagerInvestigator: nik,
      })
        .then((res) => {
          this.inquiryInvestigatorData = res.data.data;
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch(() => {
          this.$store.state.inquiryInvestigator = [];
        });
    },

    handleReDelegate(){
      this.reDelegateInvestigasi({
        fraudId: this.dataPerRow.fraudId,
        picInvestigator: this.newPicInvestigatorNik
      })
      .then(response => {
        this.$helpers.handleSuccess(response);
        this.reDelegateDialog = false;
        this.load();
      })
      .catch(error => {
        this.$helpers.handleError(error)
      })
    },

    pilihPelakuFraud(item) {
      this.picInvestigatorDialog = false;
      this.newPicInvestigatorName = item.name;
      this.newPicInvestigatorNik = item.nik;
    },

    loadDataKaryEdited() {
      this.getManagerInvestigatorSearchValue({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValueActor != null ? this.searchValueActor.trim() : this.searchValueActor,
      })
        .then((resp) => {
          this.totalData = resp.data.data.length;
          this.tables = resp.data.data.filter(
            (x) =>
              x.roleCd == "AFM_MANAGER" ||
              x.roleCd == "QA_MANAGER" ||
              x.roleCd == "QA_OPERATION_HEAD"
          );
          // const result = this.tables.filter(x => x ==  44 || x ==  45 || x == 46 );

          // console.log(result);
          console.log("ini tables karyawan role", this.tables);
        })
        .catch(() => {
          this.$store.state.inquiryEmployee = [];
        });
      this.page = 1;
    },

    reset: function () {
      this.jagaNo = "";
      this.refNo = "";
      this.reportDateFrom = "";
      this.reportDateTo = "";
      this.reportDateFromVal = "";
      this.reportDateToVal = "";
      this.fraudLoc = "";
      this.reportStatus = "";
      this.load();
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format("DD-MMM-YYYY");
    },

    pickReportDateTo() {
      this.reportDateToMenu = false;
      this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) =>
              // item.paramCd === "REPORT_STATUS_APPROVE_AFM_MANAGER" ||
              // item.paramCd === "REPORT_STATUS_APPROVE_BC" ||
              // item.paramCd === "REPORT_STATUS_APPROVE_DH" ||
              // item.paramCd === "REPORT_STATUS_APPROVE_QA_MANAGER" ||
              // item.paramCd === "REPORT_STATUS_APPROVE_QA_OPERATION" ||
              item.paramCd === "REPORT_STATUS_RETURN_AFM_MANAGER" ||
              item.paramCd === "REPORT_STATUS_RETURN_AFM_REVIEWER" ||
              item.paramCd === "REPORT_STATUS_RETURN_BC" ||
              item.paramCd === "REPORT_STATUS_RETURN_DH" ||
              item.paramCd === "REPORT_STATUS_RETURN_QA_MANAGER" ||
              item.paramCd === "REPORT_STATUS_RETURN_QA_OPERATION" ||
              item.paramCd === "REPORT_STATUS_INVESTIGATED" ||
              item.paramCd === "REPORT_STATUS_INVESTIGATION_REVISED" ||
              item.paramCd === "REPORT_STATUS_VERIFIED" ||
              item.paramCd === "REPORT_STATUS_DELEGATE_INVESTIGATOR"
          );
          console.log('infoStatus', this.infoStatus)
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    load() {
      let pageNumber = 0;
      let pageSize = 1000;
      this.$store
        .dispatch("searchDataInvestigasiProcess", { pageNumber, pageSize })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    handleGetInquiryController() {
      this.searchDataInvestigasiProcess({
        pageNumber: 0,
        pageSize: 1000,
        //reportDate: "",
        refNo: this.refNo.trim(),
        jagaNo: this.jagaNo.trim(),
        reportDateFrom: this.reportDateFromVal,
        reportDateTo: this.reportDateToVal,
        fraudLoc: this.fraudLoc.trim(),
        reportStatus: this.reportStatus,
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;

        this.inquiryController = res.data.data;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    changeStatus(status, id) {
      this.$store.commit('changeStatusInvestigastiController', status)
      this.$router.push("/investigasi-controller/" + id + "/form");
      // this.$store.state.statusInvestigasiController = status
    }

    // runningSla(dateReport) {
    //   const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    //   const firstDate = new Date();
    //   const secondDate = new Date(dateReport);

    //   const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    //   return diffDays;
    // },
  },
  computed: {
    ...mapState(["inquiryInvestigator"]),
    inquiryInvestigasiProcess() {
      return Object.values(this.$store.getters.inquiryInvestigasiProcess);
    },
    //enhance
    roleCode() {
      return this.$store.state.roleCodeFromLogin;
    },
    isButtonReturn() {
      return this.roleCode.includes('ADMIN') || this.roleCode.includes('QA_MANAGER') || this.roleCode.includes('AFM_MANAGER')
    },
    isButtonReDelegate() {
      return this.roleCode.includes('ADMIN') || this.roleCode.includes('QA_MANAGER') || this.roleCode.includes('AFM_MANAGER')
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style src="./InvestigasiController.scss" lang="scss" />