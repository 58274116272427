<template>
  <v-app>
    <v-container>
      <v-row class="pt-10 px-2 pb-5">
        <h4 v-if="isEdit" class="elevation-0 text-capitalize black--text" color="primary">
          Edit Tugas
        </h4>
        <h4 v-else class="elevation-0 text-capitalize black--text" color="primary">
          Tambah Tugas
        </h4>
      </v-row>
      <v-card class="pa-10">
        <v-row>
          <v-col>
            <p class="label-text-field ml-0">Role Code</p>
            <v-text-field v-model="inputRoleCode" dense hide-details="auto" outlined :disabled="isEdit"
              @keypress="noChar" @paste="pasteChar"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="label-text-field ml-0">Role Name</p>
            <v-text-field @keypress="noChar" dense @paste="pasteChar" :disabled="isEdit" v-model="inputRoleName"
              hide-details="auto" outlined>
            </v-text-field>

            <v-row>
              <v-checkbox v-model="checkMenu" @change="checkAllData" label="Pilih Semua" class="px-2"></v-checkbox>
            </v-row>

            <v-row no-gutters v-for="(item, index) in listMenu" :key="index" class="px-5">
              <v-col>
                <v-row>
                  <v-checkbox :label="item.menuName" v-model="checkDataParent"
                    :value="item.menuId"></v-checkbox>
                </v-row>
                <v-row v-for="(items, indexs) in item.children" :key="indexs" class="px-5">
                  <v-checkbox :label="items.menuDesc" @change="checkParent" v-model="checkDataChildren"
                    :value="items.menuId"></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 text-right">
                <v-btn class="ma-1" outlined color="primary" :to="`/role`">Batal</v-btn>
                <v-btn v-if="isEdit" class="ma-1 white--text" color="primary" @click="editMapping">
                  <span style="color: white">Simpan</span>
                </v-btn>
                <v-btn v-else class="ma-1 white--text" color="primary" @click="addMapping">
                  <span style="color: white">Simpan</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      roleId: "",
      roleName: "",
      listMenu: [],
      checkMenu: false,
      checkData: [],
      checkDataParent: [],
      checkDataChildren: [],
      searchValue: null,
      menuChoosed: {},
      roleChoosed: {},
      inputRoleCode: "",
      inputRoleName: "",
      listMenuRaw: [],
    };
  },

  created() {
    this.requiredFeature('TUGAS')
    if (this.isEdit) {
      this.changeTitleApp("Edit Tugas");
    } else {
      this.changeTitleApp("Tambah Tugas");
    }
  },

  async mounted() {
    await this.loadMenu();
    await this.loadData();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "updateRole",
      "getMenuId",
      "addRoleMenuMapping",
      "updateRoleMenuMapping",
      "searchMenu",
    ]),


    async loadData() {
      if (this.isEdit) {
        await this.$store
          .dispatch("getRoleId", { roleId: this.idRole })
          .then((response) => {
            this.formData = response.data.data;
          });
        this.inputRoleName = this.formData.roleName;
        this.inputRoleCode = this.formData.roleCode;

        this.formData.rmmVoList.map((item) => {
          let index = this.listMenu.findIndex((x) => x.menuId === item.menuId);
          if(index != -1) {
            this.checkDataParent.push(item.menuId);
          } else {
            this.checkDataChildren.push(item.menuId);
          }
        });

        if(this.formData.rmmVoList.length == this.listMenuRaw.length) {
          this.checkMenu = true
        }
      }
    },

    checkParent() {
      let chosen = []
      this.checkDataChildren.map((item) => {
        let index = this.listMenuRaw.findIndex((x) => x.menuId === item);
        chosen.push(this.listMenuRaw[index]);
      });

      this.checkDataParent = []

      chosen.map((item) => {
        let index = this.listMenu.findIndex((x) => x.menuId === item.parentId);
        this.checkDataParent.push(this.listMenu[index].menuId);
      })

      this.checkDataParent = [...new Set(this.checkDataParent)];
    },

    checkAllChildren() {
      if (this.checkDataParent.length > 0) {
        let chosen = [];
        this.checkDataParent.map((item) => {
          let index = this.listMenu.findIndex((x) => x.menuId === item);
          chosen.push(this.listMenu[index]);
        });
        this.checkDataChildren = [];
        chosen.map((item) => {
          if (item.children.length != 0) {
            item.children.map((items) => {
              this.checkDataChildren.push(items.menuId);
            });
          }
        })
      } else {
        this.checkDataChildren = [];
      }
    },

    checkAllData() {
      if (this.checkMenu) {
        this.listMenu.map((item) => {
          this.checkDataParent.push(item.menuId);
          if (item.children.length != 0) {
            item.children.map((items) => {
              this.checkDataChildren.push(items.menuId);
            });
          }
        });
      } else {
        this.checkDataParent = [];
        this.checkDataChildren = [];
      }
    },

    async loadMenu() {
      await this.$store
        .dispatch("getInquiryMenu", {
          searchValue: this.searchValue,
          pageSize: 100,
          pageNumber: 0,
          menuDesc: "",
          menuId: null,
          menuLevel: "",
          menuName: "",
          menuSeq: null,
          menuType: "",
          menuUrl: "",
          parentId: null,
        })
        .then((response) => {
          this.listMenuRaw = response.data.data;

          // this.listMenu = this.list_to_tree(response.data.data);

          function list_to_tree(list) {
            var map = {},
              node,
              roots = [],
              i;
            for (i = 0; i < list.length; i += 1) {
              map[list[i].menuId] = i;
              list[i].children = [];
            }

            for (i = 0; i < list.length; i += 1) {
              node = list[i];
              if (node.parentId !== null) {
                list[map[node.parentId]].children.push(node);
              } else {
                roots.push(node);
              }
            }
            return roots;
          }

          this.listMenu = list_to_tree(response.data.data);

          //   this.listMenu = this.list_to_tree(response.data.data);

          // this.listMenu.map((item, index) => {

          //     this.checkData.push(false);
          //     this.checkDataChild.push([]);

          //     if(item.children.length != 0){

          //         this.checkDataChild[index].push(false);

          //     }
          // })
        });
    },

    list_to_tree(list) {
      var map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].menuId] = i;
        list[i].children = [];
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null) {
          list[map[node.parentId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },

    async addRole() {
      let apa = [];
      this.checkDataParent.map((item) => {
        let index = this.listMenuRaw.findIndex((x) => x.menuId === item);
        apa.push(this.listMenuRaw[index]);
      });

      this.checkDataChildren.map((item) => {
        let index = this.listMenuRaw.findIndex((x) => x.menuId === item);
        apa.push(this.listMenuRaw[index]);
      });

      await this.$store
        .dispatch("addRole", {
          roleId: this.idRole,
          roleCd: this.inputRoleCode,
          roleName: this.inputRoleName,
          roleMenuMappList: apa,
        })
        .then(async (response) => {
          this.$helpers.handleSuccess(response);
          this.roleChoosed = response.data.data;
          this.$router.push("/role");
          // await this.handleAddRoleMenuMapping(this.roleChoosed);
        })
        .catch((err) => {
          //   if (!err.response) {
          //     this.loading = false;
          //   } else {
          //     switch (err.response.status) {
          //       default:
          //         this.$store.commit("setShowSnackbar", {
          //           snackbarMsg: err.response.data.message,
          //           snackbarColor: "red",
          //         });
          //         this.errorMessages = err.response.data.message;
          //         break;
          //     }
          //   }
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: err.response.data.message,
            snackbarColor: "red",
          });
        });
    },

    // handleAddRoleMenuMapping(menuChoose) {
    //   this.addRoleMenuMapping({
    //     menuId: this.checkData[0],
    //     roleId: menuChoose.roleId,
    //     roleMenuId: this.menuChoosed.menuId

    //   })
    //     .then((response) => {
    //       this.$router.push("/role");
    //     });
    // },

    async addMapping() {
      await this.addRole();
    },

    editRole() {

      let apa = [];
      this.checkDataParent.map((item) => {
        let index = this.listMenuRaw.findIndex((x) => x.menuId === item);
        apa.push(this.listMenuRaw[index]);
      });

      this.checkDataChildren.map((item) => {
        let index = this.listMenuRaw.findIndex((x) => x.menuId === item);
        apa.push(this.listMenuRaw[index]);
      });

      this.updateRole({
        roleId: this.idRole,
        roleName: this.inputRoleName,
        roleCd: this.inputRoleCode,
        roleMenuMappList: apa,
      })
        .then((resp) => {
          this.$helpers.handleSuccess(resp);
          this.roleChoosed = resp.data.data;
          // this.handleEditRoleMenuMapping(this.roleChoosed);
          this.$router.push("/role");
        })
        .catch((err) => {

          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    handleEditRoleMenuMapping(menuChoose) {
      this.updateRoleMenuMapping({
        menuId: this.checkData[0],
        roleId: menuChoose.roleId,
        roleMenuId: this.idRole,
      })
        .then(() => {
          this.$router.push("/role");
        });
    },

    async editMapping() {
      await this.editRole();
      //  this.handleAddRoleMenuMapping();
    },
  },
  computed: {
    ...mapState(["roleId"]),
    idRole: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    idMenu: function () {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped>
body tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>