var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mt-5 mb-1",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Tugas")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',{staticClass:"label-text-field",attrs:{"color":"primary"}},[_vm._v("Role Name")]),_c('v-text-field',{staticClass:"rp mb-0",attrs:{"outlined":"","dense":"","placeholder":"role name","prepend-inner-icon":"mdi-magnify","hide-details":"auto"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-col',[_c('v-row',[_c('v-btn',{staticClass:"elevation-0 text-capitalize ml-5 mt-6",attrs:{"color":"primary"},on:{"click":_vm.search}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Cari")])]),_c('v-btn',{staticClass:"elevation-0 text-capitalize ml-3 mt-6",attrs:{"color":"primary","outlined":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"elevation-0 mt-6",attrs:{"color":"primary","to":"/role/form/add"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Tambah ")],1)],1)],1),_c('v-card',{staticClass:"pa-10"},[_c('v-row',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.allInquiryRole,"items-per-page":5,"header-props":{ sortIcon: null },"disable-sort":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(allRole){return [_c('tr',[_c('td',[_vm._v(_vm._s(allRole.item.roleName))]),_c('td',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(allRole.item.Action)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize black--text px-0",attrs:{"small":"","text":"","to":("/role/" + (allRole.item.roleId) + "/form/edit")}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"aria-hidden":"false","outlined":"","color":"primary"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-dialog',{key:allRole.item.roleId,attrs:{"persistent":"","width":"unset"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"slot":"activator","bottom":""},slot:"activator",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize black--text px-0",attrs:{"small":"","text":""}},'v-btn',attrs,false),Object.assign({}, dialog, {tooltip: tooltip})),[_c('v-icon',{attrs:{"aria-hidden":"false","outlined":"","color":"primary"}},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true),model:{value:(_vm.dialogDel[allRole.item.roleId]),callback:function ($$v) {_vm.$set(_vm.dialogDel, allRole.item.roleId, $$v)},expression:"dialogDel[allRole.item.roleId]"}},[_c('div',{staticClass:"dialogDelete text-center"},[_c('v-sheet',[_c('v-icon',{staticClass:"icon",staticStyle:{"float":"right"},on:{"click":function($event){_vm.dialogDel[
                                    allRole.item.roleId
                                  ] = false}}},[_vm._v("mdi-close")]),_c('img',{attrs:{"src":require("@/assets/Warning.svg"),"alt":""}}),_c('div',{staticClass:"pDialog"},[_vm._v(" Hapus Tugas? "),_c('p',[_vm._v(" "+_vm._s(allRole.item.roleName)+" ")])]),_c('v-btn',{staticClass:"paramDialogBtn ma-1",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$set(_vm.dialogDel, allRole.item.roleId, false)}}},[_vm._v("Batalkan")]),_c('v-btn',{staticClass:"paramDialogDelBtn ma-1",attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.removeRole(allRole.item.roleId),
                                    _vm.$set(_vm.dialogDel, allRole.item.roleId, false)}}},[_vm._v("Hapus")])],1)],1)])],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }