<template>
  <v-app>
    <v-form ref="formParameter" v-model="validFormParameter" lazy-validation>
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Parameter</p>
          <span class="titlet"></span>
          <p v-if="isEdit" class="subTitle">Edit</p>
          <p v-else class="subTitle">Tambah Parameter</p>
        </v-row>
        <v-col cols="12" md="18">
          <div class="top">
            <v-card cols="12">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Tipe Parameter</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-select
                      :items="allInquiryParameterType"
                      item-text="parameterTypeName"
                      item-value="parameterTypeCode"
                      label=""
                      class="rp mb-0"
                      hide-details="auto"
                      v-model="parameterTypeCode"
                      outlined
                      dense
                      clearable
                      placeholder="Masukan tipe parameter"
                      :rules="[
                        (v) =>
                          !!v || 'tipe parameter harus diisi',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Kode Parameter</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="parameterCode"
                      @keypress="noChar"
                      @paste="pasteChar"
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan kode parameter"
                      :readonly="isEdit"
                      :rules="[
                        (v) =>
                          !!v || 'kode parameter harus diisi',
                        (v) => (v && v.length <= 64) || 'kode parameter must be less than 64 characters'
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Nama Parameter</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="parameterName"
                      @keypress="noChar"
                      @paste="pasteChar"
                      class="rp mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nama parameter"
                      :rules="[
                        (v) =>
                          !!v || 'nama parameter harus diisi',
                        (v) => (v && v.length <= 512) || 'nama parameter must be less than 512 characters'
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Nilai Parameter</p>
                      <!-- <span class="ml-2 mt-1"> Wajib </span> -->
                    </v-row>
                    <v-text-field
                      v-model="parameterValue"
                      class="rp mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nilai parameter"
                      @keypress="noCharSpecial"
                      @paste="pasteCharSpecial"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="elevation-0 text-capitalize mr-3 primary--text"
                      depressed
                      outlined
                      color="white"
                      :to="`/parameter`"
                    >
                      <span class="orange--text">Batal</span></v-btn
                    >
                    <v-btn
                      v-if="isEdit"
                      class="elevation-0 text-capitalize"
                      color="primary"
                      @click="editParameter"
                      ><span class="white--text">Simpan</span></v-btn
                    >
                    <v-btn
                      v-else
                      class="elevation-0 text-capitalize"
                      color="primary"
                      @click="addParameter"
                    >
                      <span class="white--text">Simpan</span></v-btn
                    >
                  </v-col>
                </v-row> -->
              </v-card-text>
            </v-card>
          </div>
          <v-row>
            <v-col class="text-right">
              <v-btn
                :to="`/parameter`"
                x-large
                depressed
                outlined
                class="elevation-0 text-capitalize mr-3 primary--text"
                ><span class="primary--text">Cancel</span></v-btn
              >

              <v-btn v-if="isEdit" x-large depressed color="primary" @click="editParameter"
                ><span class="white--text">Save</span></v-btn
              >

              <v-btn v-else x-large depressed color="primary" @click="addParameter"
                ><span class="white--text">Save</span></v-btn
              >

              <!-- <v-btn
                v-if="isEdit"
                x-large
                depressed
                class="paramBtn ml-8"
                @click="editParameter"
                ><span>Save</span></v-btn
              >

              <v-btn
                v-else
                x-large
                depressed
                class="paramBtn ml-8"
                @click="addParameter"
                ><span>Save</span></v-btn
              >

              <v-btn :to="`/parameter`" x-large depressed outlined class="back"
                ><span class="primary--text">Cancel</span></v-btn
              > -->
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      formParameter: false,
      parameterTypeCode: "",
      parameterCode: "",
      parameterName: "",
      parameterValue: "",
      paramType: "",
    };
  },

  async mounted() {
    await this.loadData();
  },
  created() {
    this.requiredFeature('PARAMETER')
  },
  methods: {
    ...mapActions(["changeTitleApp", "updateParameter"]),

    async loadData() {
      if (this.isEdit) {
        await this.$store
          .dispatch("getParameterByCd", { paramCd: this.idParameterCode })
          .then((response) => {
            this.formData = response.data.data;
          });
        this.parameterTypeCode = this.formData.paramType.parameterTypeCode;
        this.parameterCode = this.formData.paramCd;
        this.parameterName = this.formData.paramName;
        this.parameterValue = this.formData.paramValue;
      }
    },

    addParameter() {
      if (this.$refs.formParameter.validate()) {
        let parameterTypeCode = this.parameterTypeCode;
        let parameterCode = this.parameterCode;
        let parameterName = this.parameterName;
        let parameterValue = this.parameterValue;
  
        this.$store
          .dispatch("addParameter", {
            parameterTypeCode,
            parameterCode,
            parameterName,
            parameterValue,
          })
          .then((response) => {
            this.$router.push(`/parameter`);
            this.$helpers.handleSuccess(response);
          })
          .catch((err) => {
              if (!err.response) {
                this.loading = false;
              } else {
                switch (err.response.status) {
                  default:
                    this.$store.commit("setShowSnackbar", {
                      snackbarMsg: err.response.data.message,
                      snackbarColor: "red",
                    });
                    this.errorMessages = err.response.data.message;
                    break;
                }
              }
            });
      }
    },

    editParameter() {
      if (this.$refs.formParameter.validate()) {
        this.updateParameter({
          paramCd: this.parameterCode,
          paramName: this.parameterName,
          paramValue: this.parameterValue,
          paramType: this.parameterTypeCode,
        })
          .then((response) => {
            this.$router.push(`/parameter`);
            this.$helpers.handleSuccess(response);
            this.loading = false;
          })
          .catch((err) => {
              if (!err.response) {
                this.loading = false;
              } else {
                switch (err.response.status) {
                  default:
                    this.$store.commit("setShowSnackbar", {
                      snackbarMsg: err.response.data.message,
                      snackbarColor: "red",
                    });
                    this.errorMessages = err.response.data.message;
                    break;
                }
              }
            });
      }
    },
  },

  computed: {
    ...mapState(["parameterByCd"]),
    idParameterCode: function () {
      return this.$route.params.id;
    },
    allInquiryParameterType() {
      return Object.values(this.$store.getters.allInquiryParameterType);
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
  },
};
</script>

<style src="./Parameter.scss" lang="scss" />
