<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h4 class="text-capitalize font-weight-black">Verifikasi Fraud</h4>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <h7> No. Referensi </h7>
        <v-text-field v-model="refNoModel" class="mb-0 white " hide-details="auto" outlined dense
          placeholder="Cari no.referensi" label=""></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="elevation-0 text-capitalize" color="primary">
          Lokasi Kejadian
        </h7>
        <v-text-field v-model="lokasi" class="mb-0 white" hide-details="auto" outlined dense
          placeholder="Masukkan lokasi kejadian" label=""></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="elevation-0 text-capitalize" color="primary">
          Tanggal Laporan
        </h7>
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="inputStartDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
              readonly outlined dense hide-details="auto" class="mb-0 white" placeholder=""></v-text-field>
          </template>
          <v-date-picker v-model="inputStartDateVal" no-title scrollable @input="formatStartDate()">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="elevation-0 text-capitalize white--text" color="primary">
          ""
        </h7>
        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="inputEndDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on"
              readonly outlined dense hide-details="auto" class="mb-0 white" placeholder=""></v-text-field>
          </template>
          <v-date-picker v-model="inputEndDateVal" no-title scrollable @input="formatEndDate()">
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="elevation-0 text-capitalize" color="primary">
          Status
        </h7>

        <v-select :items="infoStatusNew" item-text="paramName" item-value="paramCd" label="" class="mb-0 white"
          hide-details="auto" v-model="statusInfo" outlined dense clearable placeholder="Semua">
        </v-select>

      </v-col>
      <v-col cols="12" md="2">
        <h7 class="elevation-0 text-capitalize white--text font-weight-black" color="primary">
          ""
        </h7>
        <v-row cols="12" md="4" class="text--right">
          <v-btn @click="search" class="elevation-0 text-capitalize ml-3" cols="12" color="primary">
            <span class="white--text">Cari</span></v-btn>
          <v-btn class="elevation-0 text-capitalize ml-2 primary--text" depressed outlined color="primary" cols="12"
            @click="reset">
            <span class="orange--text">Reset</span></v-btn>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="elevation-0 text-capitalize ml-2 primary--text" style="margin-right: 12px;" depressed outlined
              color="primary" cols="12" @click="downloadData()">
              <v-icon left>mdi-download</v-icon>
              <span class="orange--text">Download</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="18">
        <v-card rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headers" :items="getInquiryVerifikasi" :items-per-page="5"
                  :header-props="{ sortIcon: null }" disable-sort mobile-breakpoint="0"><template
                    v-slot:item="allVerifikasi">
                    <tr>
                      <td class="orange--text">{{ allVerifikasi.item.refNo }}</td>
                      <td>{{ allVerifikasi.item.reportDateStr }}</td>
                      <td>{{ allVerifikasi.item.fraudLocName }}</td>
                      <td>{{ allVerifikasi.item.fraudTypeName }}</td>

                      <td>
                        <v-chip class="ma-2" color="orange lighten-4" text-color="orange">
                          {{ allVerifikasi.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td class="tableData">
                        <span v-if="allVerifikasi.item.reportStatus !== 'REPORT_STATUS_CLOSED' && allVerifikasi.item.reportStatus !== 'REPORT_STATUS_KILLED'"
                          :class="`${allVerifikasi.item.slaColor.toLowerCase()}--text`">
                          {{ allVerifikasi.item.sla }}
                        </span>
                      </td>

                      <td align="center">
                        <v-row no-gutters justify="center" v-for="item in allVerifikasi" :key="item.id + item.reportStatus">
                          <v-col cols="6"
                            v-if="infoStatusNew.find(status => status.paramName === 'Open' && status.paramCd.split(',').includes(item.reportStatus)) ||
                              infoStatusNew.find(status => status.paramName === 'On Process' && status.paramCd.split(',').includes(item.reportStatus))">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon aria-hidden="false" color="#F5821F" v-on="on" v-bind="attrs"
                                  @click="openKillDialog(item)">
                                  mdi-window-close
                                </v-icon>
                              </template>
                              <span>Kill</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="6"
                            v-if="infoStatusNew.find(status => status.paramName === 'Open' && status.paramCd.split(',').includes(item.reportStatus))">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <router-link :to="`/verifikasi-data/${item.fraudId}/form/proses`">
                                  <v-icon aria-hidden="false" color="#F5821F" v-on="on" v-bind="attrs">
                                    mdi-share-outline
                                  </v-icon>
                                </router-link>
                              </template>
                              <span>Process</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="6"
                            v-if="infoStatusNew.find(status => status.paramName === 'On Process' && status.paramCd.split(',').includes(item.reportStatus) &&
                              !['REPORT_STATUS_INVESTIGATION_SUBMIT', 'REPORT_STATUS_RETURN_AFM_REVIEWER', 'REPORT_STATUS_INVESTIGATION_RE_SUBMIT', 'REPORT_STATUS_REVIEWED',
                                'REPORT_STATUS_KF_DONE', 'REPORT_STATUS_KF_OUTSTANDING', 'REPORT_STATUS_IN_SANCTION'].includes(item.reportStatus))">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon aria-hidden="false" color="#F5821F" v-on="on" v-bind="attrs"
                                  @click="openDialogReDelegate(item)">
                                  mdi-autorenew
                                </v-icon>
                              </template>
                              <span>Re-Delegate</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="killDialog" persistent width="unset">
      <v-card>
        <div class="text-center">
          <v-sheet style="
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 32px;
                  position: relative;
                  width: 349px;
                  background: #ffffff;
                  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                  border-radius: 12px;
                ">
            <img src="@/assets/alert.jpg" alt="" style="
                    position: static;
                    left: 0%;
                    right: 0%;
                    top: 0%;
                    bottom: 0%;
                  " />
            <div style="
                    position: static;
                    left: calc(50% - 285px / 2);
                    top: 164px;
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: #2e3a59;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 1;
                    margin: 32px 0px;
                  ">
              No. Referensi : {{ selectedItem ? selectedItem.refNo : '' }}<br>
              No. Jaga : {{ selectedItem ? selectedItem.jagaNo : '' }}
            </div>
            <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
              @click="killDialog = false">
              <span class="orange--text">Batalkan</span>
            </v-btn>
            <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleKill()">
              <span class="white--text">Non Aktif</span>
            </v-btn>
          </v-sheet>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReDelegate" max-width="900px">
      <v-card>
        <v-card-title class="black--text">
          Re-Delegate
        </v-card-title>

        <v-row class="mx-1" justify="end">
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">No JAGA</p>
            </v-row>
            <v-text-field v-model="jagaNo" class="mb-0" hide-details="auto" outlined dense placeholder="Masukan No JAGA"
              disabled>
            </v-text-field>
          </v-col>

          <!-- Manager Investigator -->
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">Manager Investigator</p>
            </v-row>

            <v-dialog v-model="dialogManagerInvestigator" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="investigationDelegatorName" v-bind="attrs" v-on="on" class="mb-0"
                  hide-details="auto" outlined dense placeholder="Masukan Manager Investigator"
                  @click="loadManagerInvestigator">
                </v-text-field>
              </template>

              <v-card>
                <v-card-text>
                  <v-icon class="icon" @click="dialogManagerInvestigator = false"
                    style="float: right; margin-top: 12px">mdi-close</v-icon>
                </v-card-text>
                <v-card-title class="black--text">
                  Manager Investigator
                </v-card-title>
                <v-card-text class="mt-5">
                  <span class="black--text">NIK Atau nama</span>
                  <v-row>
                    <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchManagerInvestigator" dense
                      outlined placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify"
                      clearable>
                    </v-text-field>
                    <v-btn @click="loadManagerInvestigator" class="elevation-0 text-capitalize ml-5" color="primary">
                      <span class="white--text">Cari</span>
                    </v-btn>
                  </v-row>
                  <v-data-table :headers="headersManagerInvestigator" :items="inquiryManagerInvestigator"
                    :items-per-page="5" disable-sort class="mt-5">
                    <template v-slot:[`item.pelakuId`]="{ item }">
                      <v-btn text class="primary--text" @click="pilihManagerInvestigator(item)" icon>
                        Pilih</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <!-- PIC Investigator -->
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">PIC Investigator</p>
            </v-row>
            <v-dialog v-model="dialogPicInvestigator" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="picInvestigatorName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                  outlined dense placeholder="Masukan PIC Investigator" :disabled="!picInvestigatorName"
                  @click="loadPicInvestigator">
                </v-text-field>
              </template>

              <v-card>
                <v-card-text>
                  <v-icon class="icon" @click="dialogPicInvestigator = false"
                    style="float: right; margin-top: 12px">mdi-close</v-icon>
                </v-card-text>
                <v-card-title class="black--text">
                  PIC Investigator
                </v-card-title>
                <v-card-text class="mt-5">
                  <span class="black--text">NIK Atau nama</span>
                  <v-row>
                    <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchPicInvestigator" dense
                      outlined placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify"
                      clearable>
                    </v-text-field>
                    <v-btn @click="loadPicInvestigator" class="elevation-0 text-capitalize ml-5" color="primary">
                      <span class="white--text">Cari</span>
                    </v-btn>
                  </v-row>
                  <v-data-table :headers="headersManagerInvestigator" :items="inquiryInvestigator" :items-per-page="5"
                    disable-sort class="mt-5">
                    <template v-slot:[`item.pelakuId`]="{ item }">
                      <v-btn text class="primary--text" @click="pilihPicInvestigator(item)" icon>
                        Pilih</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <!-- PIC Approver -->
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">PIC Approver</p>
            </v-row>
            <v-dialog v-model="dialogPicApprover" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="picApprName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto" outlined
                  dense :disabled="!picApprName" @click="loadPicApprover">
                </v-text-field>
              </template>

              <v-card>
                <v-card-text>
                  <v-icon class="icon" @click="dialogPicApprover = false"
                    style="float: right; margin-top: 12px">mdi-close</v-icon>
                </v-card-text>
                <v-card-title class="black--text">
                  PIC Approver
                </v-card-title>
                <v-card-text class="mt-5">
                  <span class="black--text">NIK Atau nama</span>
                  <v-row>
                    <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchPicApprover" dense
                      outlined placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify"
                      clearable>
                    </v-text-field>
                    <v-btn @click="loadPicApprover" class="elevation-0 text-capitalize ml-5" color="primary">
                      <span class="white--text">Cari</span>
                    </v-btn>
                  </v-row>
                  <v-data-table :headers="headersManagerInvestigator" :items="inquiryApprover" :items-per-page="5"
                    disable-sort class="mt-5">
                    <template v-slot:[`item.pelakuId`]="{ item }">
                      <v-btn text class="primary--text" @click="pilihPicApprover(item)" icon>
                        Pilih</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <!-- Pejabat 1 -->
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">Pejabat 1</p>
            </v-row>
            <v-dialog v-model="dialogPejabat1" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="picBcName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto" outlined dense
                  :disabled="!picBcName" @click="loadPejabat1">
                </v-text-field>
              </template>

              <v-card>
                <v-card-text>
                  <v-icon class="icon" @click="dialogPejabat1 = false"
                    style="float: right; margin-top: 12px">mdi-close</v-icon>
                </v-card-text>
                <v-card-title class="black--text">
                  Pejabat 1
                </v-card-title>
                <v-card-text class="mt-5">
                  <span class="black--text">NIK Atau nama</span>
                  <v-row>
                    <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchPejabat1" dense outlined
                      placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify" clearable>
                    </v-text-field>
                    <v-btn @click="loadPejabat1" class="elevation-0 text-capitalize ml-5" color="primary">
                      <span class="white--text">Cari</span>
                    </v-btn>
                  </v-row>
                  <v-data-table :headers="headersManagerInvestigator" :items="allInquiryPicBh" :items-per-page="5"
                    disable-sort class="mt-5">
                    <template v-slot:[`item.pelakuId`]="{ item }">
                      <v-btn text class="primary--text" @click="pilihPejabat1(item)" icon>
                        Pilih</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <!-- Pejabat 2 -->
          <v-col cols="12">
            <v-row class="verifStat ml-0">
              <p class="label-text-field">Pejabat 2</p>
            </v-row>
            <v-dialog v-model="dialogPejabat2" max-width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="picDhName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto" outlined dense
                  :disabled="!picDhName" @click="loadPejabat2">
                </v-text-field>
              </template>

              <v-card>
                <v-card-text>
                  <v-icon class="icon" @click="dialogPejabat2 = false"
                    style="float: right; margin-top: 12px">mdi-close</v-icon>
                </v-card-text>
                <v-card-title class="black--text">
                  Pejabat 2
                </v-card-title>
                <v-card-text class="mt-5">
                  <span class="black--text">NIK Atau nama</span>
                  <v-row>
                    <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchPejabat2" dense outlined
                      placeholder="Contoh NIK: 123415 atau Contoh Nama: Test " prepend-inner-icon="mdi-magnify" clearable>
                    </v-text-field>
                    <v-btn @click="loadPejabat2" class="elevation-0 text-capitalize ml-5" color="primary">
                      <span class="white--text">Cari</span>
                    </v-btn>
                  </v-row>
                  <v-data-table :headers="headersManagerInvestigator" :items="allInquiryPicDh" :items-per-page="5"
                    disable-sort class="mt-5">
                    <template v-slot:[`item.pelakuId`]="{ item }">
                      <v-btn text class="primary--text" @click="pilihPejabat2(item)" icon>
                        Pilih</v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <v-btn x-large depressed class="elevation-0 text-capitalize mr-3 mb-3 primary--text" color="primary"
            @click="handleReDelagate">
            <span class="white--text">Re-Delegate</span>
          </v-btn>

          <v-btn class="mr-3 elevation-0" height="50px" @click="dialogReDelegate = false">
            <span>Cancel</span>
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      refNoModel: "",
      fraudIdKill: 0,
      fraudIdReDelegate: 0,
      refNoKill: "",
      refNoReDelegate: "",
      fraudId: "",
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      noReferences: "",
      lokasi: "",
      // reportStatus: "",
      searchManagerInvestigator: "",
      statusInfo: "",
      infoStatus: [],
      infoStatusNew: [],
      reportStatusNameModel: "",
      killDialog: false,
      dialogReDelegate: false,
      dialogManagerInvestigator: false,
      dialogPicInvestigator: false,
      dialogPicApprover: false,
      dialogPejabat1: false,
      dialogPejabat2: false,
      menu1: false,
      menu2: false,
      inputStartDate: "",
      inputStartDateVal: null,
      inputEndDate: "",
      inputEndDateVal: null,
      reportDateFrom: null,
      reportDateTo: null,
      selectedItem: null,
      getInquiryVerifikasi: [],
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "dd-mmm-yyyy"
      ),
      currentDateMinusOneDay: new Date(Date.now() - 86400000)
        .toISOString()
        .substring(0, 10),
      location: "",
      items: [],
      headers: [
        {
          text: "No. Referensi",
          align: "center",
          width: "64px",
        },
        {
          text: "Tanggal Laporan",
          width: "100px",
          align: "center",
        },
        {
          text: "Lokasi Kejadian",
          width: "100px",
          align: "center",
        },
        {
          text: "Jenis Fraud",
          width: "110px",
          align: "center",
        },
        {
          text: "Status",
          width: "30px",
          align: "center",
        },
        {
          text: "Running SLA",
          width: "44px",
          align: "center",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "100px",
        },
      ],
      headersManagerInvestigator: [
        {
          text: "NIK",
          width: "10px",
          value: "nik",
          align: "center",
        },
        {
          text: "Nama",
          width: "76px",
          value: "name",
          align: "center",
        },
        {
          text: "Jabatan",
          width: "96px",
          value: "position",
          align: "center",
        },
        {
          text: "Organisasi",
          width: "96px",
          value: "organization",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "96px",
          value: "pelakuId",
          align: "center",
        },
      ],
      statusSearch: false,
      searchValueModel: "",
    };
  },
  async mounted() {
    this.load();
    this.loadStatusInfo();
    console.log("username", this.username)
  },
  created() {
    this.requiredFeature('VERIFIKASI_FRAUD')
    this.changeTitleApp("Verifikasi Fraud");
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "getInquiryVerifikasiFraud",
      "getParameterByParamType",
      "killVerifikasiFraud",
      "downloadVerifikasiFraud",
      "getManagerInvestigator",
      "getInquiryLovPicBc",
      "getInquiryLovPicDh",
      "getPicInvestigator",
      "reDelegateVerifikasiFraud"
    ]),

    formatStartDate() {
      this.menu1 = false;
      this.inputStartDate = moment(this.inputStartDateVal).format("DD-MMM-YYYY");
    },

    formatEndDate() {
      this.menu2 = false;
      this.inputEndDate = moment(this.inputEndDateVal).format("DD-MMM-YYYY");
    },

    reset: function () {
      this.refNoModel = "";
      this.statusInfo = "";
      this.lokasi = "";
      this.inputStartDate = "";
      this.inputEndDate = "";
      this.getInquiryVerifikasi = [];
      //this.load();

    },

    loadStatusInfo() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          let openStatuses = ['REPORT_STATUS_OPEN', 'REPORT_STATUS_VERIFICATION_OUTSTANDING'];
          let closedStatuses = ['REPORT_STATUS_CLOSED'];
          let killedStatuses = ['REPORT_STATUS_KILLED'];
          let openItems = resp.data.data.filter(item => openStatuses.includes(item.paramCd));
          let closedItems = resp.data.data.filter(item => closedStatuses.includes(item.paramCd));
          let killedItems = resp.data.data.filter(item => killedStatuses.includes(item.paramCd));
          let onProcessItems = resp.data.data.filter(item => !openStatuses.includes(item.paramCd) && !closedStatuses.includes(item.paramCd) && !killedStatuses.includes(item.paramCd));

          this.infoStatusNew = [
            { paramName: 'Open', paramCd: openItems.map(item => item.paramCd).join(',') },
            { paramName: 'On Process', paramCd: onProcessItems.map(item => item.paramCd).join(',') },
            { paramName: 'Closed', paramCd: closedItems.map(item => item.paramCd).join(',') },
            { paramName: 'Killed', paramCd: killedItems.map(item => item.paramCd).join(',') }
          ];

          console.log("status sukses ", resp.data.data);
          console.log("status closed", closedItems);
          console.log(this.infoStatusNew, "status");

        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("status error", err);
        });
    },

    openKillDialog(item) {
      this.selectedItem = item;
      this.fraudIdKill = item.fraudId;
      this.refNoKill = item.refNo;
      this.killDialog = true;
    },

    openDialogReDelegate(item) {
      this.selectedItem = item;
      this.fraudIdReDelegate = item.fraudId;
      this.refNoReDelegate = item.refNo;
      this.dialogReDelegate = true;
      this.jagaNo = this.getItemVerifikasiTable('jagaNo');
      this.investigationDelegatorNik = this.getItemVerifikasiTable('investigationDelegatorNik');
      this.investigationDelegatorName = this.getItemVerifikasiTable('investigationDelegatorName');
      this.investigationDelegatorPosition = this.getItemVerifikasiTable('investigationDelegatorPosition');
      this.picInvestigator = this.getItemVerifikasiTable('picInvestigator');
      this.picInvestigatorName = this.getItemVerifikasiTable('picInvestigatorName');
      this.picInvestigatorPosition = this.getItemVerifikasiTable('picInvestigatorPosition');
      this.picAppr = this.getItemVerifikasiTable('picAppr');
      this.picApprName = this.getItemVerifikasiTable('picApprName');
      this.picBc = this.getItemVerifikasiTable('picBc');
      this.picBcName = this.getItemVerifikasiTable('picBcName');
      this.picDh = this.getItemVerifikasiTable('picDh');
      this.picDhName = this.getItemVerifikasiTable('picDhName');
    },

    handleKill() {
      this.killDialog = false;
      this.killVerifikasiFraud({
        fraudId: this.fraudIdKill,
        reportStatus: "REPORT_STATUS_KILLED",
        reportResolution: "REPORT_RESOLUTION_CANCELLED"
      }).then((resp) => {
        this.search();
        console.log(resp, "ResponKill")
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `Fraud dengan No. Referensi ${this.refNoKill} berhasil di-Kill`,
          snackbarColor: 'green',
        });
        return;
      }).catch((err) => {
        console.log * (err, "errorKill")
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `Fraud dengan No. Referensi ${this.refNoKill} gagak di-Kill`,
          snackbarColor: 'red',
        });
        return;
      })
    },

    handleReDelagate() {
      this.dialogReDelegate = false;
      this.reDelegateVerifikasiFraud({
        fraudId: this.fraudIdReDelegate,
        managerInvestigator: this.investigationDelegatorNik,
        picInvestigator: this.picInvestigator,
        picInvestigatorName: this.picInvestigatorName,
        picInvestigatorPosition: this.picInvestigatorPosition,
        picApprover: this.picAppr,
        pejabat1: this.picBc,
        pejabat2: this.picDh
      })
        .then((resp) => {
          this.search();
          console.log(resp, "Response ReDelegate")
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: `Fraud dengan No. Referensi ${this.refNoReDelegate} berhasil di-ReDelegate`,
            snackbarColor: 'green',
          });
          return;
        })
        .catch((err) => {
          console.log(err, "Error ReDelagate")
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: `Fraud dengan No. Referensi ${this.refNoReDelegate} gagal di-ReDelegate`,
            snackbarColor: 'red',
          });
          return;
        });
    },

    loadManagerInvestigator() {
      let roleCd;
      if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        roleCd = 'SBM';
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_OPR_HEAD') {
        roleCd = 'QA_OPR_HEAD';
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER') {
        roleCd = 'QA_MANAGER';
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER') {
        roleCd = 'AFM_MANAGER';
      }

      this.$store.state.inquiryManagerInvestigator = [];
      this.getManagerInvestigator({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchManagerInvestigator != null ? this.searchManagerInvestigator.trim() : this.searchManagerInvestigator,
        roleCd: roleCd
      })
        .then((resp) => {
          this.totalData = resp.data.data.length;
        })
        .catch(() => {
          this.$store.state.inquiryManagerInvestigator = [];
        });
      this.page = 1;
    },

    loadPicInvestigator() {
      let roleCd;
      if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        roleCd = 'SBM';
        this.loadInvestigatorData(roleCd, this.getManagerInvestigator);
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_OPR_HEAD' || this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER') {
        roleCd = 'QAO';
        this.loadInvestigatorData(roleCd, this.getPicInvestigator);
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER') {
        roleCd = 'AFM_INVESTIGATOR';
        this.loadInvestigatorData(roleCd, this.getPicInvestigator);
      }
      this.page = 1;
    },

    loadInvestigatorData(roleCd, investigatorFunction) {
      this.$store.state.inquiryInvestigator = [];
      investigatorFunction({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchPicInvestigator != null ? this.searchPicInvestigator.trim() : this.searchPicInvestigator,
        roleCd: roleCd
      })
        .then((resp) => {
          this.totalData = resp.data.data.length;
        })
        .catch(() => {
          this.$store.state.inquiryInvestigator = [];
        });
    },

    loadPicApprover() {
      let roleCd;
      if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_SBM' || this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER') {
        roleCd = 'QA_MANAGER';
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_OPR_HEAD') {
        roleCd = 'QA_OPR_HEAD';
      } else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER') {
        roleCd = 'AFM_MANAGER';
      }

      this.$store.state.inquiryApprover = [];
      this.getManagerInvestigator({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchPicApprover != null ? this.searchPicApprover.trim() : this.searchPicApprover,
        roleCd: roleCd
      })
        .then((resp) => {
          this.totalData = resp.data.data.length;
        })
        .catch(() => {
          this.$store.state.inquiryApprover = [];
        });
      this.page = 1;
    },

    loadPejabat1() {
      this.getInquiryLovPicBc({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchPejabat1
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.allInquiryPicBh = [];
      });
    },

    loadPejabat2() {
      this.getInquiryLovPicDh({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchPejabat2
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.allInquiryPicDh = [];
      });
    },

    pilihManagerInvestigator(item) {
      this.dialogManagerInvestigator = false;
      this.investigationDelegatorName = item.name;
      this.investigationDelegatorNik = item.nik;

      if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        this.picInvestigatorName = item.name;
        this.picInvestigator = item.nik;
      } 
      // else if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_OPR_HEAD' ||
      //   this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' ||
      //   this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER') {
      //   this.picApprName = item.name;
      //   this.picAppr = item.nik;
      // }
    },

    pilihPicInvestigator(item) {
      this.dialogPicInvestigator = false;
      this.picInvestigatorName = item.name;
      this.picInvestigator = item.nik;
      this.picInvestigatorPosition = item.position;
      if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        this.investigationDelegatorName = item.name;
        this.investigationDelegatorNik = item.nik;
      }
    },

    pilihPicApprover(item) {
      this.dialogPicApprover = false;
      this.picApprName = item.name;
      this.picAppr = item.nik;
      // if (this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_OPR_HEAD' ||
      //   this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' ||
      //   this.investigationDelegatorPosition === 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER') {
      //   this.investigationDelegatorName = item.name;
      //   this.investigationDelegatorNik = item.nik;
      // }
    },

    pilihPejabat1(item) {
      this.dialogPejabat1 = false;
      this.picBcName = item.name;
      this.picBc = item.nik;
    },

    pilihPejabat2(item) {
      this.dialogPejabat2 = false;
      this.picDhName = item.name;
      this.picDh = item.nik;
    },

    async downloadData() {
      if (this.statusInfo == '' || this.infoStatus == null) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `Minimal masukkan Status terlebih dahulu`,
          snackbarColor: 'red',
        });
        return;
      }
      this.downloadVerifikasiFraud({
        refNo: this.refNoModel,
        reportStatus: this.statusInfo,
        fraudLoc: this.lokasi,
        reportDateFrom: this.inputStartDate != "" ? moment(this.inputStartDate, 'DD-MMM-YYYY').format("YYYY-MM-DD") : "",
        reportDateTo: this.inputEndDate != "" ? moment(this.inputEndDate, 'DD-MMM-YYYY').format("YYYY-MM-DD") : "",
      })
    },

    load() {
      this.getInquiryVerifikasiFraud({
        pageNumber: 0,
        pageSize: 1000,
        refNo: this.refNoModel,
        reportStatus: this.statusInfo,
        fraudLoc: this.lokasi,
        // reportDateFrom: this.inputStartDate,
        reportDateTo: this.inputEndDate,
      })
        .then((resp) => {
          this.totalData = resp.data.countData;
          this.countData = resp.data.countData;
          console.log("ini data search", resp);
        })
        .catch((err) => {
          console.log("error", err);
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.reportStatusName) {
              default:
                this.$helpers.handleError(err);
            }
          }
        });
    },

    loadVerifikasiId() {
      this.getDataFraudId({
        fraudId: this.idFraud,
      })
        .then((resp) => {
          console.log("suskses mendapatkan verifikas id", resp);
        })
        .catch((err) => {
          console.log("error mendapatkan verifikasi id", err);
        });
    },

    search() {
      if (this.statusInfo == '' || this.infoStatus == null) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `Status wajib dipilih`,
          snackbarColor: 'red',
        });
        return;
      }
      this.getInquiryVerifikasiFraud({
        pageNumber: 0,
        pageSize: 1000,
        refNo: this.refNoModel,
        reportStatus: this.statusInfo,
        fraudLoc: this.lokasi,
        reportDateFrom: this.inputStartDate != "" ? moment(this.inputStartDate, 'DD-MMM-YYYY').format("YYYY-MM-DD") : "",
        reportDateTo: this.inputEndDate != "" ? moment(this.inputEndDate, 'DD-MMM-YYYY').format("YYYY-MM-DD") : "",
      })
        .then((resp) => {
          this.totalData = resp.data.data;
          this.getInquiryVerifikasi = resp.data.data;
          if (this.noReferences == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.inquiryVerifikasi = [];
        });
      this.page = 1;
    },

    runningSla(dateReport) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date();
      const secondDate = new Date(dateReport);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      return diffDays;
    },
  },

  computed: {
    ...mapState(["reportStatusName", "reportById", "username", "inquiryManagerInvestigator", "inquiryInvestigator", "inquiryApprover"]),
    allInquiryVerifikasiData() {
      return Object.values(this.$store.getters.allInquiryVerifikasiData);
    },
    parameterByType() {
      return Object.values(this.$store.getters.parameterByType);
    },
    idFraud: function () {
      return this.$route.params.id;
    },
    getItemVerifikasiTable() {
      return function (itemName) {
        return this.selectedItem ? this.selectedItem[itemName] : '';
      }
    },
    allInquiryPicBh() {
      return Object.values(this.$store.getters.allInquiryPicBh);
    },
    allInquiryPicDh() {
      return Object.values(this.$store.getters.allInquiryPicDh);
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
  },
};
</script>

