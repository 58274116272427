<template>
  <router-view />
</template>

<script>
const appName = process.env.VUE_APP_NAME;

export default {
  name: "App",
  created() {
    if (appName == "") {
      document.title = "Jaga-Online";
    } else {
      document.title = appName;
    }
  },
};
</script>

<style src="./styles/app.scss" lang="scss"/>


