<template>
  <v-container>
    <!-- {{faqById}} -->
    <v-row>
      <v-col>
        <v-textarea
          dense
          outlined
          hide-details="auto"
          label="Question"
          v-model="question"
          :disabled="isView || isOnGoing()"
          rows="4"
        ></v-textarea>
        <v-autocomplete
          clearable
          :items="listFaqCategory"
          item-text="systemValue"
          item-value="systemCd"
          v-model="category"
          outlined
          label="Category"
          class="mb-0"
          hide-details
          :disabled="isView || isOnGoing()"
          dense
        />
      </v-col>
      <v-col>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Effective Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              placeholder=" "
              clearable
              dense
              :disabled="isView || isOnGoing()"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="currentDate"
            v-model="startDateVal"
            no-title
            scrollable
            @input="formatStartDate()"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="Effective End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              class="mb-7"
              placeholder=" "
              clearable
              dense
              :disabled="isView"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="isEdit ? currentDateMinusOneDay : currentDate"
            v-model="endDateVal"
            no-title
            scrollable
            @input="formatEndDate()"
          >
          </v-date-picker>
        </v-menu>
        <v-text-field
          @keypress="numOnly"
          @paste="pasteNumOnly"
          :disabled="isView || isOnGoing()"
          outlined
          dense
          hide-details="auto"
          class="mb-0"
          label="Sequence"
          v-model="seq"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div id="app">
          <ckeditor
            class="ck-editor"
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
            :disabled="isView || isOnGoing()"
          ></ckeditor>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          disabled
          dense
          placeholder=" "
          outlined
          hide-details="auto"
          label="Create By"
          v-model="createBy"
        ></v-text-field>
        <v-text-field
          disabled
          dense
          placeholder=" "
          outlined
          hide-details="auto"
          label="Create Date"
          class="mb-0"
          v-model="createDate"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          disabled
          dense
          placeholder=" "
          outlined
          hide-details="auto"
          label="Update By"
          v-model="updateBy"
        ></v-text-field>
        <v-text-field
          disabled
          dense
          placeholder=" "
          outlined
          hide-details="auto"
          label="Update Date"
          class="mb-0"
          v-model="updateDate"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-col>
      <v-row justify="space-between">
        <v-btn
          v-if="isEdit"
          @click="handleEditFaq"
          class="text-capitalize"
          width="15%"
          color="primary"
          elevation="0"
          >Save</v-btn
        >
        <v-btn
          v-else-if="!isView"
          @click="handleAddFaq"
          class="text-capitalize"
          width="15%"
          color="primary"
          elevation="0"
          >Add</v-btn
        >
        <v-btn
          class="text-capitalize"
          width="15%"
          color="primary"
          elevation="0"
          to="/pusat-bantuan-master"
          >Cancel</v-btn
        >
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";

import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";

export default {
  data() {
    return {
      menu: "",
      menu2: "",
      startDate: null,
      endDate: null,
      startDateVal: null,
      endDateVal: null,
      question: "",
      seq: "",
      category: "",
      createBy: "",
      createDate: "",
      updateBy: "",
      updateDate: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        image: {
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "resizeImage",
            "|",
            "imageTextAlternative",
          ],

          // The default value.
          styles: ["alignLeft", "alignCenter", "alignRight"],
        },
        mediaEmbed: {
          previewsInData: true,
        },
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Base64UploadAdapter,
          Alignment,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          ImageResize,
          MediaEmbed,
          Heading,
          ListStyle,
          Indent,
          IndentBlock,
        ],

        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "link",
            "undo",
            "redo",
            "alignment",
            "uploadImage",
            "mediaEmbed",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
          ],
        },
      },
      currentDateMinusOneDay: new Date(Date.now() - 86400000)
        .toISOString()
        .substring(0, 10),
      currentDate: new Date().toISOString().substr(0, 10),
      today: new Date(),
      faqById: [],
    };
  },
  async mounted() {
    await this.loadData();
    this.loadListFaqCategory();
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "getFaq",
      "getListFaqCategory",
      "addFaq",
      "editFaq",
    ]),
    loadListFaqCategory() {
      this.getListFaqCategory();
    },
    async loadData() {
      if (this.isView || this.isEdit || this.isCopy) {
        await this.getFaq({ internalId: this.$route.params.id }).then((res) => {
          this.faqById = res.data.data;
        });
        this.startDate = this.isCopy ? "" : this.faqById.effStartDate;
        this.endDate = this.isCopy ? "" : this.faqById.effEndDate;
        this.question = this.faqById.question;
        this.seq = this.faqById.seq;
        this.category = this.faqById.categoryCode;
        this.editorData = this.faqById.answer.replaceAll(
          '<img style="width:100%"',
          "<img"
        );
        this.createBy = this.faqById.createBy;
        this.createDate = this.faqById.createDate;
        this.updateBy = this.faqById.updateBy;
        this.updateDate = this.faqById.updateDate;
      }
    },
    handleEditFaq() {
      this.editFaq({
        answer: this.editorData.replaceAll("<img", '<img style="width:100%"'),
        categoryCode: this.category,
        internalId: this.$route.params.id,
        effEndDate: moment(this.endDate, "DD-MMM-YYYY").format("DD-MM-YYYY"),
        effStartDate: moment(this.startDate, "DD-MMM-YYYY").format(
          "DD-MM-YYYY"
        ),
        question: this.question,
        seq: this.seq ? this.seq : 0,
      }).then((response) => {
        this.$helpers.handleSuccess(response);
        this.$router.push("/pusat-bantuan-master");
      });
    },
    handleAddFaq() {
      this.addFaq({
        answer: this.editorData.replaceAll("<img", '<img style="width:100%"'),
        categoryCode: this.category,
        effEndDate: moment(this.endDate, "DD-MMM-YYYY").format("DD-MM-YYYY"),
        effStartDate: moment(this.startDate, "DD-MMM-YYYY").format(
          "DD-MM-YYYY"
        ),
        question: this.question,
        seq: this.seq ? this.seq : 0,
      }).then((response) => {
        this.$helpers.handleSuccess(response);
        this.$router.push("/pusat-bantuan-master");
      });
      // .then(res => {
      //   console.log('halo', res)
      //   this.$router.push("/pusat-bantuan-master");
      // })
    },
    isOnGoing() {
      if (this.isView || this.isEdit) {
        let startDate = moment(this.faqById.effStartDate, "DD-MMM-YYYY");
        let endDate = moment(this.faqById.effEndDate, "DD-MMM-YYYY");
        // console.log("onGoingasdas", moment(this.today).isAfter(startDate), moment(this.today).isBefore(endDate))
        return (
          moment(this.today.setHours(0, 0, 0, 0)).isAfter(startDate) &&
          moment(this.today.setHours(0, 0, 0, 0)).isSameOrBefore(endDate)
        );
      } else {
        return false;
      }
    },
    formatStartDate() {
      this.menu = false;
      this.startDate = moment(this.startDateVal).format("DD-MMM-YYYY");
    },
    formatEndDate() {
      this.menu2 = false;
      this.endDate = moment(this.endDateVal).format("DD-MMM-YYYY");
    },
  },
  created() {
    this.requiredFeature("MNT_FAQ")
    // this.loadData()
    if (this.isView) {
      this.changeTitleApp("View Bantuan Form");
    } else if (this.isEdit) {
      this.changeTitleApp("Edit Bantuan Form");
    } else if (this.isCopy) {
      this.changeTitleApp("Copy Bantuan Form");
    } else {
      this.changeTitleApp("Add Bantuan Form");
    }
  },
  computed: {
    ...mapState(["listFaqCategory"]),
    mode: function () {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    isView: function () {
      return this.mode === "view";
    },
    isCopy: function () {
      return this.mode === "copy";
    },
  },
};
</script>

<style>
</style>