<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="d-flex justify-center">
        <v-col cols="8" xl=12 lg=12>
          <div class="card">
            <span class="error-logo">403</span>
            <p class="error-text">Oops. Sepertinya Anda tidak memiliki akses untuk halaman <span class="font-weight-bold">{{ this.$store.state.latest403Page ? this.$store.state.latest403Page : 'ini' }}</span></p>
            <!-- <p class="error-subtext">Silahkan kembali ke Dashboard</p> -->
            <!-- <v-btn
                class="text-capitalize"
                dark
                x-large
                :color="config.light.primary"
                to="/"
            >
              Kembali ke Dashboard
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
  </v-container>
  </v-app>
</template>

<script>
import config from '@/config';

export default {
  name: 'Forbidden',
  data(){
    return{
      config
    }
  }
};
</script>

<style src="./Forbidden.scss" lang="scss"></style>
