<template>
  <v-contianer fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-5">
      <h1 class="page-title">Cabang</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">Kode atau Nama Cabang</p>
        <v-text-field
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="searchValue"
          dense
          outlined
          placeholder="Cari Kode atau Nama Cabang"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn
            @click="handleSearch"
            class="elevation-0 text-capitalize ml-3 mt-2"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span>
          </v-btn>

          <v-btn
            class="elevation-0 text-capitalize ml-3 mt-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="handleResetBranch"
          >
            <span class="orange--text">Reset</span></v-btn
          >

          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize text-center"
              cols="12"
              @click="handleDownloadTemplate"
            >
              <v-icon aria-hidden="false" color="orange">
                mdi-file-download-outline </v-icon
              ><span class="orange--text ml-2">Download Template</span>
            </v-btn>
            <v-btn
              class="elevation-0 text-capitalize text-center"
              cols="12"
              @click="handleUploadClickTemplate"
            >
              <v-icon aria-hidden="false" color="orange">
                mdi-file-upload-outline </v-icon
              ><span class="orange--text ml-2">Upload</span>
            </v-btn>
            <input
              :ref="`uploaderTemplate`"
              class="d-none"
              type="file"
              @change="onFileChangedTemplate($event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- TABLE -->
    <v-row>
      <v-col cols="12" md="18">
        <div>
          <v-card cols="12">
            <v-card-text>
              <v-row style="widh: 100%">
                <v-col cols="12">
                  <v-data-table
                    :headers="headersCabang"
                    :items="allInquiryBranch"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item="data">
                      <tr>
                        <td>{{ data.item.branchCode }}</td>
                        <td>{{ data.item.branchName }}</td>
                        <td>{{ data.item.bmArea }}</td>
                        <td>{{ data.item.bmName }}</td>
                        <td>{{ data.item.bcArea }}</td>
                        <td>{{ data.item.bcName }}</td>
                        <td>{{ data.item.dhSbc }}</td>
                        <td align="center">
                          <v-dialog
                            v-model="dialogDel[data.item.branchCode]"
                            persistent
                            :key="data.item.branchCode"
                            width="unset"
                          >
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    v-on="{ ...dialog, ...tooltip }"
                                    v-bind="attrs"
                                  >
                                    <v-icon
                                      aria-hidden="false"
                                      style="font-size: 20px"
                                      color="#F5821F"
                                    >
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[data.item.branchCode] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Cabang?
                                  <p>
                                    {{ data.item.branchName }}
                                  </p>
                                </div>
                                <v-btn
                                  class="paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      data.item.branchCode,
                                      false
                                    )
                                  "
                                >
                                  <span> Batalkan </span>
                                </v-btn>

                                <v-btn
                                  class="paramDialogDelBtn paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeCabang(data.item.branchCode),
                                      $set(
                                        dialogDel,
                                        data.item.branchCode,
                                        false
                                      )
                                  "
                                >
                                  <span> Hapus </span>
                                </v-btn>
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-contianer>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialogDel: {},
      headersCabang: [
        {
          text: "Kode Cabang",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Cabang",
          align: "center",
          width: "150px",
        },
        {
          text: "Area BM",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama BM",
          align: "center",
          width: "150px",
        },
        {
          text: "Area BC",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama BC",
          align: "center",
          width: "150px",
        },
        {
          text: "DH",
          align: "center",
          width: "150px",
        },
        {
          text: "Tindakan",
          align: "center",
        },
      ],

      searchValue: "",
    };
  },

  created() {
    this.requiredFeature('CABANG')
    this.changeTitleApp("Master Cabang");
  },

  mounted() {
    this.handleSearch();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "saveUploadBranch",
      "searchBranch",
      "downloadTemplateBranch",
      "deleteBranch"
    ]),

    // HANDLE
    handleDownloadTemplate() {
      this.downloadTemplateBranch()
        .then((resp) => {
          console.log("response template cabang oke", resp);
        })
        .catch((err) => {
          console.log("gagal download template", err.response);
          this.$$helpers.handleError(err);
        });
    },
    removeCabang(code) {
      this.deleteBranch(code)
        .then((response) => {
          this.handleSearch();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleSearch() {
      this.searchBranch({
        pageSize: 1000,
        pageNumber: 0,
        searchValue: this.searchValue,
      })
        .then((resp) => {
          this.countData = resp.data.data;
          this.totalData = resp.data.data;
          this.inquiryBranch = resp.data.data;
          // console.log(
          //   "berhasil di tampilkan seluruh data cabang",
          //   resp.data.data
          // );
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("terjadi error saat search", err.response);
        });
    },

    handleResetBranch() {
      this.searchValue = ''
      this.handleSearch()
    },

    handleUploadClickTemplate() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // const button = this.$refs['uploaderTemplate'].find( el => el.$attrs['key'] === index);

      // button.click();
      this.$refs["uploaderTemplate"].click();
    },

    onFileChangedTemplate(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.saveUploadBranch({
        formData: formData,
      })
        .then((resp) => {
          console.log("upload template cabang oke", resp);
          this.handleSearch();
        })
        .catch((err) => {
          console.log("gagal upload cabang", err.response);
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/pctet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.$helpers.handleError(err);
        });
      console.log("ini tombol upload");
    },
  },

  computed: {
    allInquiryBranch() {
      return Object.values(this.$store.getters.allInquiryBranch);
    },
  },
};
</script>


<style src="./Cabang.scss" lang="scss" />