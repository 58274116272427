<template>
  <v-app>
    <v-container class="grey lighten-5">
      <v-row>
        <v-col md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="isReadOnly()"
                v-model="effectiveStartDate"
                label="Stop Selling Start Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="mb-0"
                placeholder=" "
              ></v-text-field>
            </template>
            <v-date-picker
              :min="currentDate"
              v-model="effStartDateVal"
              no-title
              scrollable
              @input="formatStartDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="6">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="effectiveEndDate"
                label="Stop Selling End Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="mb-0"
                placeholder=" "
              ></v-text-field>
            </template>
            <v-date-picker
              :min="isEdit ? currentDateMinusOneDay : currentDate"
              v-model="effEndDateVal"
              no-title
              scrollable
              @input="formatEndDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="isReadOnly()"
                label="User Name"
                append-icon="mdi-magnify"
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="mb-0"
                v-model="userName"
                placeholder=" "
                readonly
              ></v-text-field>
            </template>
          </v-dialog>
        </v-col>
        <v-col md="6">
          <v-text-field
            v-model="agentType"
            label="Agent Type"
            class="mb-0"
            hide-details="auto"
            outlined
            placeholder=" "
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="NIK/ID"
            outlined
            class="mb-0"
            placeholder=" "
            v-model="oid"
            disabled
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Nama"
            outlined
            class="mb-0"
            placeholder=" "
            v-model="personName"
            disabled
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="isEdit"
            class="elevation-0 text-capitalize"
            color="primary"
            @click="editStopSellingUser"
            >Save</v-btn
          >
          <v-btn
            v-else
            class="elevation-0 text-capitalize"
            width="15%"
            color="primary"
            @click="addStopSellingUser"
            >Add</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn
            class="elevation-0 text-capitalize"
            color="primary"
            :to="`${link}/stop-selling`"
            >Cancel</v-btn
          >
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="1200px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">Lookup Username</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeBox">
                <v-icon>mdi-close-box</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      item-text="userTypeName"
                      :items="allUserType"
                      label="Agent Type"
                      item-value="userTypeCd"
                      outlined
                      hide-details="auto"
                      class="mb-0"
                      v-model="agentTypeRegistered"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="name"
                      class="mb-0"
                      label="Person Name"
                      outlined
                      hide-details="auto"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn @click="search" color="elevation-0 primary"
                      >Search</v-btn
                    >
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="allRegisteredUser"
                  :items-per-page="5"
                  :header-props="{ sortIcon: null }"
                >
                  <template v-slot:item="allUsers">
                    <tr>
                      <td>{{ allUsers.item.userName }}</td>
                      <td>{{ allUsers.item.oid }}</td>
                      <td>{{ allUsers.item.userTypeCode }}</td>
                      <td>{{ allUsers.item.personName }}</td>
                      <td align="center">
                        <v-btn
                          @click="choose(allUsers.item)"
                          small
                          text
                          class="text-capitalize"
                          color="secondary"
                          >Choose</v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-pagination
                  :total-visible="7"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  v-model="page"
                  :length="Math.ceil(totalData / perPage)"
                  @input="handlePageChange"
                >
                </v-pagination>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      link: "",
      dialog: false,
      loading: false,

      userName: "",
      name: "",
      agentType: "",
      agentTypeRegistered: "",

      effectiveStartDate: null,
      effectiveEndDate: null,
      effStartDateVal: null,
      effEndDateVal: null,

      oid: "",
      personName: "",
      userId: "",
      endNowDate: "",
      endNowBy: "",
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),

      menu: false,
      menu2: false,
      date: "",
      date2: "",
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary black--text",
          width: "250px",
        },
        { text: "NIK/ID", class: "primary black--text", width: "200px" },
        { text: "Agent Type", class: "primary black--text", width: "200px" },
        { text: "Person Name", class: "primary black--text", width: "250px" },
        {
          text: "Action",
          align: "center",
          class: "primary black--text",
        },
      ],
      statusSearch: false,
      today: new Date(),
      currentDateMinusOneDay: new Date(Date.now()-86400000).toISOString().substring(0,10),
    };
  },
  async mounted() {
    await this.loadData()
    this.loadRegisteredUser();
  },
  created(){
    this.requiredFeature('MNT_STOP_SELL')
    if(this.isEdit){
      this.changeTitleApp("Edit Stop Selling")
    } else {
      this.changeTitleApp("Add Stop Selling")
    }
  },
  methods: {
    ...mapActions(["getRegisteredUser", "changeTitleApp"]),
    choose(data){
      this.dialog = false,
      this.userName = data.userName,
      this.oid = data.oid,
      this.personName = data.personName,
      this.userId = data.internalId,
      this.agentType = data.userTypeCode         
      
      this.agentTypeRegistered = "";
      this.name = "";

      let agentType = "";
      let name = "";
      let pageNumber = 0;
      let pageSize = 100;
      this.$store
        .dispatch("getRegisteredUser", {
          agentType,
          name,
          pageNumber,
          pageSize,
        }).then(res => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
      this.page = 1
    },
    closeBox() {
      this.dialog = false;
      this.agentTypeRegistered = "";
      this.name = "";

      let agentType = "";
      let name = "";
      let pageNumber = 0;
      let pageSize = 100;
      this.$store
        .dispatch("getRegisteredUser", {
          agentType,
          name,
          pageNumber,
          pageSize,
        })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        });
      this.page = 1
    },
    formatStartDate() {
      this.menu = false;
      this.effectiveStartDate = moment(this.effStartDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    formatEndDate() {
      this.menu2 = false;
      this.effectiveEndDate = moment(this.effEndDateVal).format("DD-MMM-YYYY");
    },
    async loadData(){
      if(this.isEdit){
        await this.$store.dispatch("getStopSellingById", { internalId : this.idStopSelling })
        .then((response) => {
          this.formData = response.data.data
        })
        this.effectiveStartDate = this.formData.effStartDate
        this.effectiveEndDate = this.formData.effEndDate
        this.userName = this.formData.userName
        this.agentType = this.formData.agentType
        this.oid = this.formData.nik
        this.personName = this.formData.fullName
      }      
    },
    isReadOnly() {
      let startDate = moment(this.stopSellingById.effStartDate, "DD-MMM-YYYY");
      return moment(this.today.setHours(0,0,0,0)).isAfter(startDate);
    },
    loadRegisteredUser() {
        let pageNumber = 0;
        let pageSize = 100;
        this.$store
          .dispatch("getRegisteredUser", { pageSize, pageNumber })
          .then((res) => {
            this.totalData = res.data.countData;
            this.countData = res.data.countData;
          })
          .catch((err) => console.log(err));
      this.$store.dispatch("getUserType");
    },
    handlePageChange(value) {
      if (!this.statusSearch && (this.agentTypeRegistered !== '' || this.agentTypeRegistered !== null || this.name !== '')) {
        this.getRegisteredUser({
          pageNumber: value - 1,
          pageSize: this.perPage,
          name: '',
          agentType: ''
        });
      } else {
        this.getRegisteredUser({
          pageNumber: value - 1,
          pageSize: this.perPage,
          name: this.name,
          agentType: this.agentTypeRegistered
        });
      }
    },
    search() {
      let agentType = this.agentTypeRegistered;
      let name = this.name;
      let pageNumber = 0;
      let pageSize = this.countData;
      this.$store.dispatch("getRegisteredUser", {
        agentType,
        name,
        pageNumber,
        pageSize,
      }).then(res => {
          this.totalData = res.data.data.length;
          if((this.agentTypeRegistered == '' || this.agentTypeRegistered == null ) && this.name == ''){
            this.statusSearch = false
          } else {
            this.statusSearch = true;
          }
      })
      this.page = 1;
    },
    addStopSellingUser() {
      let effStartDate = moment(this.effectiveStartDate, "DD-MMM-YYYY").format("DD-MM-YYYY");
      let effEndDate = moment(this.effectiveEndDate, "DD-MMM-YYYY").format("DD-MM-YYYY");


      let effStartDateWithoutFormat = moment(this.effStartDateVal);
      let effEndDateWithoutFormat = moment(this.effEndDateVal);

      let endNowDate = this.endNowDate;
      let endNowBy = this.endNowBy;
      let userId = this.userId;
      if (moment(effStartDateWithoutFormat).isAfter(effEndDateWithoutFormat)) {
        this.loading = false;
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Stop Selling End Date tidak boleh lebih kecil dari Stop Selling Start Date",
          snackbarColor: "red",
        });
      } else {
        this.$store
          .dispatch("addStopSellingUser", {
            effStartDate,
            effEndDate,
            endNowDate,
            endNowBy,
            userId,
          })
          .then((response) => {
            this.$router.push(`/stop-selling`);
            this.$helpers.handleSuccess(response);
          });
      }
    },
    editStopSellingUser() {
      let effStartDate = moment(this.effectiveStartDate, "DD-MMM-YYYY").format("DD-MM-YYYY")
      let effEndDate = moment(this.effectiveEndDate, "DD-MMM-YYYY").format("DD-MM-YYYY")

      let effStartDateWithoutFormat = moment(this.stopSellingById.effStartDate);
      let effEndDateWithoutFormat = moment(this.stopSellingById.effEndDate);

      let endNowBy = this.stopSellingById.endNowBy;
      let endNowDate = this.stopSellingById.endNowDate;
      let internalId = this.$route.params.id;
      let userId = this.stopSellingById.userId;
      if (moment(effStartDateWithoutFormat).isAfter(effEndDateWithoutFormat)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Effective End Date tidak boleh lebih kecil dari Effective Start Date",
          snackbarColor: "red",
        });
        this.loading = false;
      } else {
        this.$store
          .dispatch("updateUserStopSelling", {
            effStartDate,
            effEndDate,
            endNowBy,
            endNowDate,
            internalId,
            userId,
          })
          .then((response) => {
            this.$helpers.handleSuccess(response);
            this.$router.push(`/stop-selling`);
            this.loading = false;
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },   
  },
  computed: {
    ...mapGetters(["allRegisteredUser", "allUserType"]),
    ...mapState(["stopSellingById"]),
    idStopSelling: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    isView: function () {
      return this.mode === "view";
    },
  }  
};
</script>

