<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col md="4" class="main-part d-none d-md-block">
          <!-- <div class="d-flex"> -->
          <!-- <v-img class="image-part"
                    src="@/assets/login-logo.svg"
                  ></v-img> -->
          <!-- </div> -->
        </v-col>
        <v-col md="8" class="login-part align-center justify-center">
          <v-row no-gutters>
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper dark">
                <!-- <div class="brand-wrapper d-flex justify-center align-center">
                  <v-img
                    src="@/assets/ad1seller-logo.png"
                    aspect-ratio
                    max-width="230"
                  ></v-img>
                </div> -->
                <v-form ref="formLogin" v-model="validFormLogin" lazy-validation>
                  <v-container>
                    <h1>Sign In</h1>
                    <p class="body-1">
                      Welcome back, please login to your account
                    </p>
                    <v-row class="flex-column text-right">
                      <v-col>
                        <v-text-field v-model="email" :label="isExternalAgentType ? 'ID / Email' : 'NIK'" required
                          outlined hide-details="auto" :rules="emailRules"></v-text-field>
                        <v-text-field v-model="password" :rules="passRules" :type="value ? 'password' : 'text'"
                          label="Password" required outlined hide-details="auto" class="mb-0"
                          :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append="() => (value = !value)"></v-text-field>
                        <captcha mode="num" resolve="text" :callSuccess="captchaOk" :callFailed="captchaNotOk">
                        </captcha>
                        <!-- <v-btn to="/forgot-password" small text class="text-capitalize primary--text px-0 mt-1" v-if="isExternalAgentType">Forgot Password?</v-btn> -->
                      </v-col>
                      <v-col class="d-flex justify-space-between">
                        <v-btn class="text-capitalize white--text" large block :disabled="password.length === 0 ||
                          email.length === 0 ||
                          !isCaptchaOk
                          " color="secondary" @click="handleLogin">
                          Sign In</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <!-- <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2020 Adira Multi Finance. All rights reserved.</div>
              </v-footer>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor">
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn text small v-bind="attrs" @click="showSnackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :absolute="absolute" :value="isLoading">
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Captcha from "@/components/Captcha/Captcha";
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  components: {
    captcha: Captcha,
  },
  data() {
    return {
      value: String,
      validFormLogin: true,
      // vuetify: this.$vuetify,
      absolute: true,
      email: "",
      emailRules: [
        (v) =>
          !!v ||
          `${this.isExternalAgentType ? `ID / Email` : `NIK`
          } tidak boleh Kosong`,
        // (v) => /.+@.+/.test(v) || "Format E-mail harus sesuai",
      ],
      // emailRules: [
      //   (v) => !!v || "E-mail is required",
      //   (v) => /.+@.+/.test(v) || "E-mail must be valid",
      // ],
      createFullName: "John Smith",
      createEmail: "",
      createPassword: "",
      password: "",
      passRules: [(v) => !!v || "Password harus diisi"],
      userType: "INTERN_SLS",
      // userType: localStorage.getItem("login__selectedUserType")
      //   ? localStorage.getItem("login__selectedUserType")
      //   : "INTERN_SLS",
      // items: [
      //   { state: "AXI", abbr: "AXI" },
      //   { state: "Internal Sales", abbr: "INTERN_SLS" },
      //   { state: "Keday", abbr: "KEDAY" },
      // ],
      isCaptchaOk: false,
    };
  },
  methods: {
    ...mapActions([
      "login",
      "getFeatures",
      "getUserTypeLogin",
      "generateCaptcha",
      // "validateToken",
    ]),
    // testx: function() {
    //   console.log(this.$vuetify.breakpoint.name)
    // },
    // loginOld(){
    //   window.localStorage.setItem('authenticated', true);
    //   this.$router.push('/dashboard');
    // },
    handleLogin() {
      if (this.$refs.formLogin.validate() && this.isCaptchaOk) {
        let username = this.email;
        let password = this.password;
        let captcha = btoa(this.$root.$refs.A.captcha);
        let uuid = this.$root.$refs.A.uuid;
        this.login({ username, password, captcha, uuid })
          .then((response) => {
            if (response.data.status == 1) {
              let menuList = [];
              let userAccessList = response.data.data.userAccessList;
              // let token = response.data.token;

              userAccessList.map((menu) => {
                if (menu.menuCode != null) {
                  menuList.push(menu.menuCode);
                }
                if (menu.userAccessList) {
                  menu.userAccessList.map((menuSub) => {
                    if (menuSub.menuCode != null) {
                      menuList.push(menuSub.menuCode);
                    }
                  });
                }
              });

              // console.log(menuList);
              // let menuListUniq = menuList.filter(function (item, pos) {
              //   return menuList.indexOf(item) == pos;
              // });
              // let obj = {
              //   menuList: menuListUniq,
              //   nik: this.email,
              //   token: token,
              // }
              // let encoded = btoa(JSON.stringify(obj))
              // let fullEncode = this.makeid(76) + encoded
              if (!this.$store.state.nextPath) {
                if (this.hasFeature("DASHBOARD")) {
                  let routeName = "Dashboard"; //default
                  this.$router.push({ name: routeName });
                } else {
                  this.$router.push({ name: "PengaduanFraud" });
                }
              } else {
                this.$router.push(this.$store.state.nextPath);
              }


              // this.validateToken({
              //   id: fullEncode
              // })
              //   .then((response) => {
              //     let info = response.data.data

              //     let sliceInfo = info.slice(76)
              //     let actual = JSON.parse(atob(sliceInfo))

              //     if (actual == true) {
              //       if (this.hasFeature("DASHBOARD")) {
              //         let routeName = "Dashboard"; //default
              //         this.$router.push({ name: routeName });
              //       } else {
              //         this.$router.push({ name: "PengaduanFraud" });
              //       }
              //     } else {
              //       this.$store.state.token = '';
              //       this.$root.$refs.A.getCaptcha();
              //       this.$store.commit("setShowSnackbar", {
              //         snackbarMsg: "NIK atau Password salah",
              //         snackbarColor: "red",
              //       });
              //     }
              //   })
              //   .catch((err) => {
              //     this.$helpers.handleError(err);
              //     this.$root.$refs.A.getCaptcha();
              //     this.$store.commit("setShowSnackbar", {
              //       snackbarMsg: response.data.message,
              //       snackbarColor: "red",
              //     });
              //   });
            } else {
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: response.data.message,
                snackbarColor: "red",
              });
              this.$root.$refs.A.getCaptcha();
            }
          })
          .catch((err) => {
            this.$helpers.handleError(err);
            this.$root.$refs.A.getCaptcha();
            this.$store.commit("setShowSnackbar", {
              snackbarMsg: "NIK atau Password salah",
              snackbarColor: "red",
            });
          });
      }
    },


    // login: function(e) {
    //   e.preventDefault();
    //   let username = this.email;
    //   let password = this.password;
    //   let userType = this.userType;
    //   this.$store
    //     .dispatch("login", { username, password, userType })
    //     .then((response) => {
    //       console.log(response.data.status);
    //       if (response.data.status == 1) {
    //         this.$router.push(`/dashboard`);
    //         this.$store.commit("setShowSnackbar", {
    //           snackbarMsg: response.data.message,
    //           snackbarColor: "green",
    //         });
    //       } else {
    //         this.$store.commit("setShowSnackbar", {
    //           snackbarMsg: response.data.message,
    //           snackbarColor: "red",
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       if (!err.response) {
    //         console.log("Network Error");
    //       } else {
    //         switch (err.response.status) {
    //           default:
    //             //console.log('errornya', err.response.data.message)
    //             this.$store.commit("setShowSnackbar", {
    //               snackbarMsg: err.response.data.message,
    //               snackbarColor: "red",
    //             });
    //             this.errorMessages =
    //               err.response.data.message[0].messages[0].message;
    //             break;
    //         }
    //       }
    //       // this.snackbar = true
    //     });
    // },
    handleUserType: function (val) {
      localStorage.setItem("login__selectedUserType", val);
    },
    captchaOk() {
      this.isCaptchaOk = true;
    },
    captchaNotOk() {
      this.isCaptchaOk = false;
    },
    getCaptcha() {
      this.generateCaptcha();
    },
  },
  // mounted(){
  //   this.getCaptcha()
  // },
  computed: {
    ...mapState(["userTypeListLogin", "dataUser"]),
    isExternalAgentType() {
      return this.userType != "INTERN_SLS";
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },

    //   loginWrapperStyles () { console.log(this.$vuetify.breakpoint.name);
    //     // const styles = {};
    //     switch (this.$vuetify.breakpoint.name) {
    //       case 'xs': return {
    //           width: '100%'
    //       }
    //       // case 'sm':
    //       // case 'md':
    //       // case 'xl': return {
    //       //     width: '360px'
    //       // }
    //     }
    //     return {
    //           width: '360px'
    //       };
    //   }
  },
  // created() {
  //   console.log('isi', )
  // },
};
</script>





<style src="./Login.scss" lang="scss" />
 