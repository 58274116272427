<template>
  <v-container>
    <!-- {{inquiryFaq[0]}} -->
    <v-row>
      <v-col>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Effective Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              class="mb-0"
              placeholder=" "
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDateVal"
            no-title
            scrollable
            @input="formatStartDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="Effective End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              class="mb-0"
              placeholder=" "
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDateVal"
            no-title
            scrollable
            @input="formatEndDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="question"
          outlined
          label="Question"
          class="mb-0"
          hide-details
        />
      </v-col>
      <v-col>
        <v-autocomplete
          clearable
          :items="listFaqCategory"
          item-text="systemValue"
          item-value="systemCd"
          v-model="category"
          outlined
          label="Category"
          class="mb-0"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="searchFaq"
          class="elevation-0 text-capitalize"
          color="primary"
          >Search</v-btn
        >
        <v-btn
          class="elevation-0 text-capitalize ml-1"
          color="primary"
          @click="downloadFaqData"
        >
          Download
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-col>
      <v-row justify="end">
        <v-btn
          :to="`pusat-bantuan-master/form/add`"
          class="elevation-0 text-capitalize mt-3"
          color="primary"
        >
          Add
        </v-btn>
      </v-row>
    </v-col>
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="inquiryFaq"
          :items-per-page="perPage"
          :header-props="{ sortIcon: null }"
          disable-sort
        >
          <template v-slot:item="dataFaq">
            <tr>
              <td>{{ dataFaq.item.question }}</td>
              <td>{{ dataFaq.item.effStartDate }}</td>
              <td>{{ dataFaq.item.effEndDate }}</td>
              <td>{{ dataFaq.item.categoryCode }}</td>
              <td>{{ dataFaq.item.createBy }}</td>
              <td>{{ dataFaq.item.createDate }}</td>
              <td>{{ dataFaq.item.updateBy }}</td>
              <td>{{ dataFaq.item.updateDate }}</td>
              <td align="center">
                <v-btn
                  small
                  text
                  class="text-capitalize black--text px-0"
                  :to="`pusat-bantuan-master/${dataFaq.item.internalId}/form/view`"
                >
                  View
                </v-btn>
                <v-btn
                  v-show="beforeToday(dataFaq.item.effEndDate)"
                  small
                  text
                  class="text-capitalize black--text px-0"
                  :to="`pusat-bantuan-master/${dataFaq.item.internalId}/form/edit`"
                >
                  Edit
                </v-btn>
                <v-btn
                  small
                  text
                  class="text-capitalize black--text px-0"
                  :to="`pusat-bantuan-master/${dataFaq.item.internalId}/form/copy`"
                >
                  Copy
                </v-btn>

                <v-dialog
                  v-model="dialogDel[dataFaq.item.internalId]"
                  persistent
                  :key="dataFaq.item.internalId"
                  width="unset"
                  v-if="isHidden(dataFaq.item.effStartDate)"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      text
                      class="text-capitalize black--text px-0"
                      v-on="on"
                      v-bind="attrs"
                      >Delete
                    </v-btn>
                  </template>
                  <div class="text-center">
                    <v-sheet
                      class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
                      color="blue-grey darken-3"
                    >
                      <div class="grey--text text--lighten-1 text-body-2 mb-4">
                        Yakin untuk menghapus data ini ?
                      </div>

                      <v-btn
                        class="ma-1"
                        color="primary"
                        text
                        @click="
                          $set(dialogDel, dataFaq.item.internalId, false)
                        "
                      >
                        Tidak
                      </v-btn>

                      <v-btn
                        class="ma-1"
                        color="primary"
                        text
                        @click="
                          removeDataFaq(dataFaq.item.internalId),
                            $set(dialogDel, dataFaq.item.internalId, false)
                        "
                      >
                        Ya
                      </v-btn>
                    </v-sheet>
                  </div>
                </v-dialog>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination
          :total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          v-model="page"
          :length="Math.ceil(totalData / perPage)"
          @input="handlePageChange"
        >
        </v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      text: "",
      menu: "",
      menu2: "",
      startDate: null,
      endDate: null,
      startDateVal: null,
      endDateVal: null,
      dialogDel: {},
      question: "",
      category: "",
      headers: [
        {
          text: "Question",
          align: "start",
          value: "name",
          width: "400px",
          class: "primary black--text",
        },
        {
          text: "Effective Start Date",
          width: "200px",
          class: "primary black--text",
        },
        {
          text: "Effective End Date",
          width: "200px",
          class: "primary black--text",
        },
        {
          text: "Category",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Create by",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Create date",
          width: "200px",
          class: "primary black--text",
        },
        {
          text: "Update by",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Update date",
          width: "200px",
          class: "primary black--text",
        },
        {
          text: "Action",
          align: "center",
          width: "300px",
          class: "primary black--text",
        },
      ],
      items: [
        {
          name: "asd",
        },
      ],
      statusSearch: false,
      currentDate: new Date(),
      
      effectiveStartDateSearch: '',
      effectiveEndDateSearch: '',
      questionSearch: '',
      categorySearch: '',
    };
  },
  mounted() {
    this.loadInquiryFaq();
    this.loadListFaqCategory();
  },
  beforeDestroy() {
    this.$store.state.inquiryFaq = [];
  },
  methods: {
    ...mapActions(["changeTitleApp", "getInquiryFaq", "getListFaqCategory", "deleteFaq", "downloadFaq"]),
    loadListFaqCategory() {
      this.getListFaqCategory();
    },
    loadInquiryFaq() {
      this.getInquiryFaq({
        pageNumber: 0,
        pageSize: 5,
        categoryCode: "",
        startDate: "",
        endDate: "",
        question: ""
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      });
    },
    handlePageChange(value) {
      // if ((!this.statusSearch && this.category !== "") || this.category !== null || this.startDate !== null || this.endDate !== null) {
      //   this.getInquiryFaq({
      //     pageNumber: value - 1,
      //     pageSize: this.perPage,
      //     startDate: "",
      //     endDate: "",
      //     categoryCode: "",
      //   });
      // } else {
        this.getInquiryFaq({
          pageNumber: value - 1,
          pageSize: this.perPage,
          startDate: this.effectiveStartDateSearch,
          endDate: this.effectiveEndDateSearch,
          categoryCode: this.categorySearch,
          question: this.questionSearch
        });
      // }
    },
    searchFaq() {
      this.effectiveStartDateSearch = this.startDate !== null ? moment(this.startDateVal).format("DD-MM-YYYY") : "";
      this.effectiveEndDateSearch = this.endDate !== null ? moment(this.endDateVal).format("DD-MM-YYYY") : "";
      this.categorySearch = this.category;
      this.questionSearch = this.question

      this.getInquiryFaq({
        pageNumber: 0,
        pageSize: 10,
        startDate: this.startDate !== null ? moment(this.startDateVal).format("DD-MM-YYYY") : "",
        endDate: this.endDate !== null ? moment(this.endDateVal).format("DD-MM-YYYY") : "",
        categoryCode: this.category,
        question: this.question
      }).then((res) => {
        this.totalData = res.data.countData;
        if ((this.category == "" || this.category == null) && this.startDate == null && this.endDate == null && this.question == null) {
          this.statusSearch = false;
        } else {
          this.statusSearch = true;
        }
      });
      this.page = 1;
    },
    downloadFaqData(){
      this.downloadFaq({
        startDate: this.startDate !== null ? moment(this.startDateVal).format("DD-MM-YYYY") : "",
        endDate: this.endDate !== null ? moment(this.endDateVal).format("DD-MM-YYYY") : "",
        categoryCode: this.category,
        question: this.question
      })
    },
    async removeDataFaq(id){
      // console.log("delete", id)
      await this.deleteFaq({ internalId : id})
        .then(() => {
          this.getInquiryFaq({
            pageNumber: 0,
            pageSize: 10,
            startDate: this.startDate !== null ? moment(this.startDateVal).format("DD-MM-YYYY") : "",
            endDate: this.endDate !== null ? moment(this.endDateVal).format("DD-MM-YYYY") : "",
            categoryCode: this.category,
          }).then((res) => {
            this.totalData = res.data.countData;
            this.page = 1
          });
        })
    },
    beforeToday(data) {
      let endDate = moment(data, "DD-MMM-YYYY");
      return moment(endDate).isSameOrAfter(this.currentDate.setHours(0, 0, 0, 0));
    },
    isHidden(b) {
      let startDate = moment(b, "DD-MMM-YYYY");
      return moment(startDate).isAfter(this.currentDate);
    },
    formatStartDate() {
      this.menu = false;
      this.startDate = moment(this.startDateVal).format("DD-MMM-YYYY");
    },
    formatEndDate() {
      this.menu2 = false;
      this.endDate = moment(this.endDateVal).format("DD-MMM-YYYY");
    },
  },
  created() {
    this.requiredFeature("MNT_FAQ")
    this.changeTitleApp("Pusat Bantuan");
  },
  computed: {
    ...mapState(["inquiryFaq", "listFaqCategory"]),
  },
};
</script>

<style>
</style>