<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-bold mb-0 mt-2">Dashboard</h3>
      </v-col>
      <v-col cols="12">
        <v-card class="rounded-lg">
          <!-- <v-toolbar light flat>
            <v-checkbox
              class="check_top"
              color="success"
            ></v-checkbox>

            <v-toolbar-title class="title_detail">Dokumen</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="blue--text mr-2"
              icon
              ><v-icon>mdi-swap-horizontal</v-icon></v-btn
            >
          </v-toolbar> -->

          <!-- <v-divider :color="lineColor"></v-divider> -->

          <v-row>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">ALL CASE - ALL PERIOD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFrom"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromVal"
                          no-title
                          scrollable
                          @input="pickReportDateFrom"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateTo"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToVal"
                          no-title
                          scrollable
                          @input="pickReportDateTo"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetAllCaseAll()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <PieChart :chartData="allCase" />
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">OPEN CASE - ALL PERIOD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenuOpen"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFromOpen"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromValOpen"
                          no-title
                          scrollable
                          @input="pickReportDateFromOpen"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenuOpen"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateToOpen"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToValOpen"
                          no-title
                          scrollable
                          @input="pickReportDateToOpen"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetAllCaseOpen()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <PieChart :chartData="openCase" />
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">MODUS</td>
                      <td class="text-left">ALL CASE - ALL PERIOD</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in allCaseTable" :key="index">
                      <td class="text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.value }}
                      </td>
                    </tr>
                    <tr v-if="allCaseTable.length == 0">
                      <td colspan="2">NO DATA</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">MODUS</td>
                      <td class="text-left">OPEN CASE - ALL PERIOD</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in openCaseTable" :key="index">
                      <td class="text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.value }}
                      </td>
                    </tr>
                    <tr v-if="openCaseTable.length == 0">
                      <td colspan="2">NO DATA</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">CASE IN PROGRESS - ALL PERIOD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenuProg"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFromProg"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromValProg"
                          no-title
                          scrollable
                          @input="pickReportDateFromProg"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenuProg"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateToProg"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToValProg"
                          no-title
                          scrollable
                          @input="pickReportDateToProg"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetAllCaseInprog()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <PieChart :chartData="inprogCase" />
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">CLOSED CASE - ALL PERIOD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenuClose"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFromClose"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromValClose"
                          no-title
                          scrollable
                          @input="pickReportDateFromClose"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenuClose"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateToClose"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToValClose"
                          no-title
                          scrollable
                          @input="pickReportDateToClose"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetAllCaseClose()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <PieChart :chartData="closeCase" />
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">MODUS</td>
                      <td class="text-left">CASE IN PROGRESS - ALL PERIOD</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in inprogCaseTable" :key="index">
                      <td class="text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.value }}
                      </td>
                    </tr>
                    <tr v-if="inprogCaseTable.length == 0">
                      <td colspan="2">NO DATA</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">MODUS</td>
                      <td class="text-left">CLOSED CASE - ALL PERIOD</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in closeCaseTable" :key="index">
                      <td class="text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.value }}
                      </td>
                    </tr>
                    <tr v-if="closeCaseTable.length == 0">
                      <td colspan="2">NO DATA</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">DUGAAN FRAUD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenuDugaan"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFromDugaan"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromValDugaan"
                          no-title
                          scrollable
                          @input="pickReportDateFromDugaan"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenuDugaan"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateToDugaan"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToValDugaan"
                          no-title
                          scrollable
                          @input="pickReportDateToDugaan"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetDugaanFraud()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <v-row>
                    <v-col cols="6">
                      <p class="text-md-center">OPEN</p>
                      <h3 class="text-md-center yellow--text">
                        {{ dugaanFraudTable.open }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">VERIFIKASI</p>
                      <h3 class="text-md-center orange--text">
                        {{ dugaanFraudTable.verifikasi }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">DIPROSES</p>
                      <h3 class="text-md-center blue--text">
                        {{ dugaanFraudTable.diproses }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">DITOLAK</p>
                      <h3 class="text-md-center green--text">
                        {{ dugaanFraudTable.ditolak }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">TOTAL</p>
                      <h3 class="text-md-center red--text">
                        {{ dugaanFraudTable.total }}
                      </h3>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="ma-5" outlined>
                <p class="text-md-center mt-2">INVESTIGASI FRAUD</p>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateFromMenuInvest"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateFromInvest"
                            label="From"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateFromValInvest"
                          no-title
                          scrollable
                          @input="pickReportDateFromInvest"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu1"
                        v-model="reportDateToMenuInvest"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reportDateToInvest"
                            label="To"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0 white mr-1"
                            placeholder=""
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reportDateToValInvest"
                          no-title
                          scrollable
                          @input="pickReportDateToInvest"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <div class="ml-2 mr-2">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 mr-3"
                        depressed
                        @click="handleGetInvestigasiFraud()"
                        outlined
                      >
                        <span>Filter</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div class="ma-2">
                  <v-row>
                    <v-col cols="6">
                      <p class="text-md-center">SUBMIT TO INVESTIGATOR</p>
                      <h3 class="text-md-center yellow--text">
                        {{ investigasiFraudTable.submitToInvestigator }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">INVESTIGASI</p>
                      <h3 class="text-md-center orange--text">
                        {{ investigasiFraudTable.investigasi }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">APPROVAL</p>
                      <h3 class="text-md-center blue--text">
                        {{ investigasiFraudTable.approval }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">REVIEWER</p>
                      <h3 class="text-md-center green--text">
                        {{ investigasiFraudTable.reviewer }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">KF</p>
                      <h3 class="text-md-center red--text">
                        {{ investigasiFraudTable.kf }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">RECOVERY</p>
                      <h3 class="text-md-center pink--text">
                        {{ investigasiFraudTable.recovery }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">CLOSED</p>
                      <h3 class="text-md-center lime--text">
                        {{ investigasiFraudTable.closed }}
                      </h3>
                    </v-col>
                    <v-col cols="6">
                      <p class="text-md-center">TOTAL</p>
                      <h3 class="text-md-center olive--text">
                        {{ investigasiFraudTable.total }}
                      </h3>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">STATUS</td>
                      <td class="text-left">LAPORAN DUGAAN</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in Object.keys(dugaanFraudTable)"
                      :key="index"
                    >
                      <td class="text-left">
                        {{ Object.keys(dugaanFraudTable)[index] }}
                      </td>
                      <td class="text-right">
                        {{ Object.values(dugaanFraudTable)[index] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" lg="6">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td class="text-left">STATUS</td>
                      <td class="text-left">LAPORAN FRAUD</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">Submit to Investigator</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.submitToInvestigator }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Investigasi</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.investigasi }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Approval</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.approval }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Reviewer</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.reviewer }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">KF</td>
                      <td class="text-right">{{ investigasiFraudTable.kf }}</td>
                    </tr>
                    <tr>
                      <td class="text-left">Recovery</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.recovery }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Closed</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.closed }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">Total</td>
                      <td class="text-right">
                        {{ investigasiFraudTable.total }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-5">
              <v-simple-table dense class="mx-5">
                <template v-slot:default>
                  <thead class="fixed_table_row_dashboard">
                    <tr>
                      <td colspan="1" rowspan="2" class="text-center fixed_table_colum_dashboard">
                        Bulan Pelaporan
                      </td>
                      <td colspan="1" rowspan="2" class="text-center">Case</td>
                      <td
                        colspan="14"
                        class="text-center"
                      >
                        Bulan Komite
                      </td>
                    </tr>
                    <tr>
                      <td
                        v-for="(item, index) in months"
                        :key="index"
                        style="text-align: center"
                        colspan="1"
                      >
                        <span>{{ item }}</span>
                      </td>
                      <td
                        style="text-align: center"
                        colspan="1"
                      >
                        <span>On Process</span>
                      </td>
                      <td
                        style="text-align: center"
                        colspan="1"
                      >
                        <span>KF%</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in prosesCaseTable" :key="index">
                      <td class="text-left fixed_table_colum_dashboard">{{ item.bulan }}</td>
                      <td class="text-right">
                        {{ item.jmlCase == 0 ? '' : item.jmlCase }}
                      </td>
                      <td class="text-right">
                        {{ item.jan == 0 ? '' : item.jan }}
                      </td>
                      <td class="text-right">
                        {{ item.feb == 0 ? '' : item.feb }}
                      </td>
                      <td class="text-right">
                        {{ item.mar == 0 ? '' : item.mar }}
                      </td>
                      <td class="text-right">
                        {{ item.apr == 0 ? '' : item.apr }}
                      </td>
                      <td class="text-right">
                        {{ item.may == 0 ? '' : item.may }}
                      </td>
                      <td class="text-right">
                        {{ item.jun == 0 ? '' : item.jun }}
                      </td>
                      <td class="text-right">
                        {{ item.jul == 0 ? '' : item.jul }}
                      </td>
                      <td class="text-right">
                        {{ item.aug == 0 ? '' : item.aug }}
                      </td>
                      <td class="text-right">
                        {{ item.sep == 0 ? '' : item.sep }}
                      </td>
                      <td class="text-right">
                        {{ item.oct == 0 ? '' : item.oct }}
                      </td>
                      <td class="text-right">
                        {{ item.nov == 0 ? '' : item.nov }}
                      </td>
                      <td class="text-right">
                        {{ item.dec == 0 ? '' : item.dec }}
                      </td>
                      <td class="text-right">
                        {{ item.onProses == 0 ? '' : item.onProses }}
                      </td>
                      <td class="text-right">
                        {{ item.kfPercent == null ? '0 %' : item.kfPercent+' %' }}
                      </td>
                      <!-- <td
                        v-for="(items, indexs) in item.bulanKomite"
                        :key="indexs"
                      >
                        {{ items.name.includes('KF%') ? items.value*100+' %' : item.value == 0 ? '' : item.value }}
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- <v-data-table
                :headers="headers"
                :items="prosesCaseTable"
                :items-per-page="14"
                class="elevation-0"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:header="header">
                  <thead>
                    <tr>
                      <th colspan="1" rowspan="2" style="width: 110px">
                        {{ header.props.headers[0].text }}
                      </th>
                      <th colspan="1" rowspan="2" style="width: 110px">
                        {{ header.props.headers[1].text }}
                      </th>
                      <th colspan="12" style="width: 400px; text-align: center">
                        Bulan Komite
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-for="(item, index) in prosesCaseTable[0].bulanKomite"
                        :key="index"
                        style="background-color: orange; text-align: center"
                        colspan="1"
                      >
                        <span style="color: #fff">{{ item.name }}</span>
                      </td>
                    </tr>
                  </thead>
                </template>
              </v-data-table> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="8">
              <BarChart :chartData="chartDataBar" />
            </v-col>
          </v-row>

          <!-- <v-card-text class="black--text"> asdfsadfdasfadsfsd </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import BarChart from "@/components/Chart/BarChart";
import PieChart from "@/components/Chart/Pie";
import moment from "moment";
// import mock from './mock';
// import Trend from "vuetrend"
// import ApexChart from 'vue-apexcharts'

export default {
  name: "Dashboard",
  data() {
    return {
      reportDateFromMenuInvest: false,
      reportDateToMenuInvest: false,
      reportDateFromMenu: false,
      reportDateToMenu: false,
      reportDateFrom: "",
      reportDateTo: "",
      reportDateFromVal: null,
      reportDateToVal: null,
      reportDateFromMenuOpen: false,
      reportDateToMenuOpen: false,
      reportDateFromOpen: "",
      reportDateToOpen: "",
      reportDateFromValOpen: null,
      reportDateToValOpen: null,
      reportDateFromMenuProg: false,
      reportDateToMenuProg: false,
      reportDateFromProg: "",
      reportDateToProg: "",
      reportDateFromValProg: null,
      reportDateToValProg: null,
      reportDateFromMenuClose: false,
      reportDateToMenuClose: false,
      reportDateFromClose: "",
      reportDateToClose: "",
      reportDateFromValClose: null,
      reportDateToValClose: null,
      reportDateFromMenuDugaan: false,
      reportDateToMenuDugaan: false,
      reportDateFromDugaan: "",
      reportDateToDugaan: "",
      reportDateFromValDugaan: null,
      reportDateToValDugaan: null,
      chartData: {
        labels: ["Pencairan", "Tabungan", "Lembes", "BRW"],
        datasets: [
          {
            backgroundColor: ["#5b9bd5", "#ed7d31", "#a5a5a5", "#ffc000"],
            data: [50, 10, 20, 30],
          },
        ],
      },
      headers: [
        {
          text: "Bulan Pelaporan",
          sortable: false,
          value: "reportMonth",
        },
        { text: "Case", sortable: false, value: "value" },
        // { text: "Bulan Komite", sortable: false, value: "bulanKomite" },
      ],
      prosesCaseTable: [
        {
          bulanKomite: "",
        },
      ],
      moduses: [
        {
          name: "Pencairan",
          calories: 50,
        },
        {
          name: "Tabungan",
          calories: 10,
        },
        {
          name: "Lembes",
          calories: 20,
        },
        {
          name: "BRW",
          calories: 30,
        },
      ],
      dugaanFraudList: [
        {
          name: "Open",
          calories: 10,
        },
        {
          name: "Verifikasi",
          calories: 20,
        },
        {
          name: "Diproses",
          calories: 30,
        },
        {
          name: "Ditolak",
          calories: 50,
        },
        {
          name: "Total",
          calories: 110,
        },
      ],
      investigasiFraudList: [
        {
          name: "Submit to Investigator",
          calories: 10,
        },
        {
          name: "Investigasi",
          calories: 20,
        },
        {
          name: "Approval",
          calories: 30,
        },
        {
          name: "Reviewer",
          calories: 5,
        },
        {
          name: "KF",
          calories: 10,
        },
        {
          name: "Recovery",
          calories: 35,
        },
        {
          name: "Closed",
          calories: 100,
        },
        {
          name: "Total",
          calories: 210,
        },
      ],
      allCase: {},
      openCase: {},
      closeCase: {},
      inprogCase: {},
      allCaseTable: "",
      openCaseTable: "",
      closeCaseTable: "",
      inprogCaseTable: "",
      dugaanFraudTable: "",
      investigasiFraudTable: "",
      bgColor: [
        "#5b9bd5",
        "#ed7d31",
        "#a5a5a5",
        "#ffc000",
        "#ffd966",
        "#d55b5b",
        "#d5b45b",
        "#acd55b",
        "#5bd571",
        "#5bd5d5",
        "#5b8cd5",
      ],
      chartDataBar: {
        labels: [
          "Carry Over 21",
          "Jan 22",
          "Feb 22",
          "Mar 22",
          "Apr 22",
          "May 22",
          "Jun 22",
          "Jul 22",
          "Aug 22",
          "Sep 22",
          "Oct 22",
          "Nov 22",
          "Dec 22",
        ],
        datasets: [
          {
            label: "Case",
            backgroundColor: "#ed7d31",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
          },
          {
            label: "KF",
            backgroundColor: "#ffc000",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11, 10],
          },
        ],
      },
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
  },
  components: {
    BarChart,
    PieChart,
  },
  mounted() {
    this.$store.dispatch("changeTitleApp", "Jaga-Online");
  },
  created() {
    this.requiredFeature('DASHBOARD')
    this.handleGetAllCaseAll();
    this.handleGetAllCaseOpen();
    this.handleGetAllCaseClose();
    this.handleGetAllCaseInprog();
    this.handleGetDugaanFraud();
    this.handleGetInvestigasiFraud();
    this.handleGetProsesCase();
    this.handleGetThisYear();
  },
  methods: {
    ...mapActions([
      "getAllCase",
      "getDugaanFraud",
      "getInvestigasiFraud",
      "getProsesCase",
    ]),

    handleGetThisYear() {
      let thisYear = new Date().getFullYear().toString().slice(-2);

      this.months = this.months.map(item => 
        item+'-'+thisYear
      ) 
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateTo() {
      this.reportDateToMenu = false;
      this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    },

    pickReportDateFromOpen() {
      this.reportDateFromMenuOpen = false;
      this.reportDateFromOpen = moment(this.reportDateFromValOpen).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateToOpen() {
      this.reportDateToMenuOpen = false;
      this.reportDateToOpen = moment(this.reportDateToValOpen).format(
        "DD-MMM-YYYY"
      );
    },

    pickReportDateFromProg() {
      this.reportDateFromMenuProg = false;
      this.reportDateFromProg = moment(this.reportDateFromValProg).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateToProg() {
      this.reportDateToMenuProg = false;
      this.reportDateToProg = moment(this.reportDateToValProg).format(
        "DD-MMM-YYYY"
      );
    },

    pickReportDateFromClose() {
      this.reportDateFromMenuClose = false;
      this.reportDateFromClose = moment(this.reportDateFromValClose).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateToClose() {
      this.reportDateToMenuClose = false;
      this.reportDateToClose = moment(this.reportDateToValClose).format(
        "DD-MMM-YYYY"
      );
    },

    pickReportDateFromDugaan() {
      this.reportDateFromMenuDugaan = false;
      this.reportDateFromDugaan = moment(this.reportDateFromValDugaan).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateToDugaan() {
      this.reportDateToMenuDugaan = false;
      this.reportDateToDugaan = moment(this.reportDateToValDugaan).format(
        "DD-MMM-YYYY"
      );
    },

    pickReportDateFromInvest() {
      this.reportDateFromMenuInvest = false;
      this.reportDateFromInvest = moment(this.reportDateFromValInvest).format(
        "DD-MMM-YYYY"
      );
    },
    pickReportDateToInvest() {
      this.reportDateToMenuInvest = false;
      this.reportDateToInvest = moment(this.reportDateToValInvest).format(
        "DD-MMM-YYYY"
      );
    },

    handleGetProsesCase() {
      this.getProsesCase({}).then((response) => {
        this.prosesCaseTable = response.data.data;
        let labels = [];
        let data = [];
        let dataCase = [];
        response.data.data.map((item) => {
          // if(item.bulanKomite !== null){
            labels.push(item.bulan);
            dataCase.push(item.jmlCase != 0 ? 100 - (Number(item.kfPercent)) : 0);
            data.push(item.kfPercent == null ? 0 : item.kfPercent);
          // }
        });
        this.chartDataBar = {
          labels: labels,
          datasets: [
            {
              label: "Case (%)",
              backgroundColor: "#ed7d31",
              data: dataCase,
            },
            {
              label: "KF (%)",
              backgroundColor: "#ffc000",
              data: data,
            },
          ],
        };
      });
    },

    handleGetInvestigasiFraud() {
      this.getInvestigasiFraud({
        reportDateFrom: this.reportDateFromInvest
          ? moment(this.reportDateFromInvest, "DD-MMM-YYYY").format(
              "yyyy-MM-DD"
            )
          : null,
        reportDateTo: this.reportDateToInvest
          ? moment(this.reportDateToInvest, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
      }).then((response) => {
        this.investigasiFraudTable = response.data.data;
      });
    },

    handleGetDugaanFraud() {
      this.getDugaanFraud({
        reportDateFrom: this.reportDateFromDugaan
          ? moment(this.reportDateFromDugaan, "DD-MMM-YYYY").format(
              "yyyy-MM-DD"
            )
          : null,
        reportDateTo: this.reportDateToDugaan
          ? moment(this.reportDateToDugaan, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
      }).then((response) => {
        this.dugaanFraudTable = response.data.data;
      });
    },

    handleGetAllCaseAll() {
      this.getAllCase({
        reportDateFrom: this.reportDateFrom
          ? moment(this.reportDateFrom, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        reportDateTo: this.reportDateTo
          ? moment(this.reportDateTo, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        chartCase: "All",
      }).then((response) => {
        let labels = [];
        let data = [];
        response.data.data.map((item) => {
          labels.push(item.name);
          data.push(item.value);
        });
        this.allCase = {
          labels: labels,
          datasets: [
            {
              backgroundColor: this.bgColor,
              data: data,
            },
          ],
        };
        this.allCaseTable = response.data.data;
      });
    },

    handleGetAllCaseOpen() {
      this.getAllCase({
        reportDateFrom: this.reportDateFromOpen
          ? moment(this.reportDateFromOpen, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        reportDateTo: this.reportDateToOpen
          ? moment(this.reportDateToOpen, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        chartCase: "OPEN",
      }).then((response) => {
        let labels = [];
        let data = [];
        response.data.data.map((item) => {
          labels.push(item.name);
          data.push(item.value);
        });
        this.openCase = {
          labels: labels,
          datasets: [
            {
              backgroundColor: this.bgColor,
              data: data,
            },
          ],
        };
        this.openCaseTable = response.data.data;
      });
    },

    handleGetAllCaseClose() {
      this.getAllCase({
        chartCase: "CLOSE",
      }).then((response) => {
        let labels = [];
        let data = [];
        response.data.data.map((item) => {
          labels.push(item.name);
          data.push(item.value);
        });
        this.closeCase = {
          labels: labels,
          datasets: [
            {
              backgroundColor: this.bgColor,
              data: data,
            },
          ],
        };
        this.closeCaseTable = response.data.data;
      });
    },

    handleGetAllCaseInprog() {
      this.getAllCase({
        reportDateFrom: this.reportDateFromProg
          ? moment(this.reportDateFromProg, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        reportDateTo: this.reportDateToProg
          ? moment(this.reportDateToProg, "DD-MMM-YYYY").format("yyyy-MM-DD")
          : null,
        chartCase: "INPROG",
      }).then((response) => {
        let labels = [];
        let data = [];
        response.data.data.map((item) => {
          labels.push(item.name);
          data.push(item.value);
        });
        this.inprogCase = {
          labels: labels,
          datasets: [
            {
              backgroundColor: this.bgColor,
              data: data,
            },
          ],
        };
        this.inprogCaseTable = response.data.data;
      });
    },
  },
};
</script>

// <style src="./Dashboard.scss" lang="scss" scoped />
