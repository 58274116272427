// https://vuex.vuejs.org/en/state.html

export default {
  haveDashboard: false,
  dataUser: [],
  userAccessList: [],
  inquiryManagerInvestigator: [],
  profile: {},
  authStatus: '',
  token: '',
  username: '',
  refreshTokenAdmin: '',
  tokenExpDate: '',
  refreshTokenExpDate: '',
  user: {},
  tableList: [],
  userList: [],
  userListCount: null,
  headerLinks: [],
  drawer: true,
  showSnackbar: false,
  snackbarMsg: '',
  snackbarColor: '',
  justLoggedIn: false,

  paramTypeDataList: [],
  inquiryParameterType: [],
  paramTypeCd: [],
  parameterTypeCode: '',

  reviewFraudList: [],

  investigasiCheckList: [],
  inquiryInvestigasiCheck: [],
  inquiryReviewFraud: [],

  parameterDataList: [],
  roleMenuMappDataList: [],
  inquiryParameter: [],
  parameterByCd: {},
  reportById: {},
  paramCd: '',
  inquiryUser: [],
  userDataList: [],
  userById: {},
  verifikasiId: {},
  inquiryHolidays: [],
  holidayById: {},
  holidayDataList: [],
  inquiryRole: [],
  roleDataList: [],
  roleId: {},
  inquiryMenu: [],
  inquiryRoleMenuMapp: [],
  menuId: {},
  
  inquiryVerifikasi: [],
  inquiryFraud: [],
  inquirySuspects: [],
  inquiryLocation: [],
  inquiryModus: [],
  inquiryEmployee: [],
  inquiryBranch: [],
  inquiryKaryawanValue: [],
  inquiryInvestigasiProcess: [],
  processId: {},
  
  employeeNik: {},
  branchCode: {},
  fraudId: {},
  inquiryReports: [],
  parameterByType: [],
  parameterByCode: [],
  fraudDataList:[],
  karyawanByNik: {},

  inquiryAfm: [],

  inquiryInvestigator: [],
  inquiryApprover: [],
  inquiryBh: [],
  inquiryDh: [],
  approvalDataList: [],
  bcDataList:[],
  dhDataList:[],
  delegateDataList:[],

  inquiryRecoveryPayment: [],
  recoveryPaymentData: {},

  inquiryKomiteFraud: [],
  komiteFraudData: {},

  stopSellingUserList: [],
  agentTypes: [],
  featureAccessList: [],
  featureList: [],
  jobList: [],
  featureAccess: {},
  registeredUser: [],
  inquiryStopSelling: [],
  userType: '', //sebelumnya []
  userTypeListLogin: [],
  stopSellingUser: {},
  endNowUser: {},
  stopSellingById: {},
  paramData: {},
  roleData: {},
  menuData: '',


  listJobList: [],
  inquiryFeatureAccess: [],
  registeredUserList: [],
  userTypeList: [],
  userProfileList: [],
  listApprovalStatus: [],
  listInquiryApproval: [],
  listBranch: [],
  kodeCabang: '',
  paramType: ["Nama Parameter", "Kode Parameter", "Nilai Parameter"],
  status: ["Aktif", "Tidak Aktif"],
  reportStatusName: ["Open"],
  searchValue: '',

  listProductPartner: [],
  inquiryProduct: [],
  listProductType: [],
  listSyariahType: [],
  listPremiumPartner: [],

  reportSalesByBranchList: [],
  titleApp: '',
  productById: {},

  cashoutAccApprById: {},

  inquiryCommission: [],
  commissionById: {},
  listPartyType: [],
  listUserTypePrefix: [],
  listCustomerType: [],
  listPaymentPeriodType: [],

  inquiryProductSetting: [],
  productSettingById: {},

  isLoading: false,

  bankAccInfo: {
    data: {}
  },
  inquiryAdminFee: [],
  initAdminFeeData: {},
  inquiryProductAccess: [],
  productAccessData: {},
  initProductAccessData: {},
  inquiryFeatureGroup: [],
  featuresWebAdmin: [],
  featureData: {},
  initFeatureData: {},
  listDataType: [],
  listExternSystem: [],
  inquiryInterfaceDataMapData: [],
  interfaceDataMapData: {},
  initInterfaceDataMapData: {},
  fullName: '',

  inquiryProductPartner: [],
  productPartnerById: {},

  inquiryBank: [],
  bankById: {},

  inquirySystem: [],
  systemById: {},
  listDatabase: [],
  listSystemType: [],

  inquiryRegVAFormMode: [],
  listFieldMode: [],
  regVAFormModeData: {},
  initRegVAFormModeData: {},

  inquiryActivity: [],
  listActivityType: [],
  activityById: {},
  inquirySchedLog: [],
  listSchedLogStatus: [],
  listSchedLog: [],
  schedLogById: {},

  inquirySalesKit: [],
  salesKitById: {},

  inquiryProgram: [],
  programData: {},
  initProgramData: {},

  inquiryPopupNoti: [],
  popupNotiData: {},
  initPopupNotiData: {},
  listPopupNotiType: [],

  inquiryFaq: [],
  faqById: {},
  listFaqCategory: [],

  listPaymentType: [],
  listPromoCountBaseOn: [],
  inquiryFeatureAccessList: {},
  listVehicleType: [],

  //enhance
  roleCodeFromLogin: [],
  statusInvestigasiController: ''
}
