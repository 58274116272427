<template> 
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Investigasi Checker</h1>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <h7>No. Tiket JAGA </h7>
        <v-text-field
          v-model="noJaga"
          class="mb-0 white mr-1"
          hide-details="auto"
          dense
          placeholder="Cari no. referensi"
          label=""
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <h7>Kode Lokasi Kejadian</h7>
        <v-text-field
          v-model="codeLocM"
          class="mb-0 white mr-1"
          hide-details="auto"
          dense
          placeholder="Cari Kode Lokasi kejadian"
          label=""
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <h7>Lokasi Kejadian</h7>
        <v-text-field
          v-model="locM"
          class="mb-0 white mr-1"
          hide-details="auto"
          dense
          placeholder="Cari Lokasi kejadian"
          label=""
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <h7>Tanggal Verifikasi</h7>
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateFrom"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0 white mr-1"
              placeholder=""
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateFromValPicker"
            no-title
            scrollable
            @input="pickDateForm"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="label-text-field white--text">""</h7>
        <v-menu
          ref="menuTo"
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateTo"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0 white mr-1"
              placeholder=""
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateToValPicker"
            no-title
            scrollable
            @input="pickDateTo"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <h7>Status</h7>
        <v-select
          v-model="reportStatusM"
          class="mb-0 white mr-1"
          hide-details="auto"
          dense
          outlined
          placeholder="Semua"
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
        ></v-select>
      </v-col>
      <v-col>
        <h7 class="label-text-field white--text" color="primary">""</h7>
        <v-row>
          <v-btn
            @click="handleGetCheck"
            class="evelation-0 text-capitalize ml-3"
            cols="12"
            color="primary"
            depressed
          >
            <span class="white--text">Cari</span>
          </v-btn>
          <v-btn
            @click="reset"
            depressed
            outlined
            color="primary"
            cols="12"
            class="elevation-0 text-capitalize ml-2 primary--text"
          >
            <span class="orange--text">Reset</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  class="tableData"
                  :headers="headersInvestigasiFraud"
                  :items="allInquiryInvestigasiCheck"
                  :items-per-page="5"
                  :header-props="{ sortIcon: null }"
                  disable-sort
                  mobile-breakpoint="0"
                >
                  <template v-slot:item="data">
                    <tr>
                      <td class="tableData orange--text">{{ data.item.jagaNo }}</td>
                      <td class="tableData">{{ data.item.verifStartDateStr }}</td>
                      <td class="tableData">{{ data.item.fraudLocCd }}</td>
                      <td class="tableData">{{ data.item.fraudLocName }}</td>
                      <td class="tableData">{{ data.item.modus }}</td>
                      <td class="tableData">
                        <v-chip
                          class="ma-2"
                          color="orange lighten-4"
                          text-color="orange"
                        >
                          {{ data.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td class="tableData">
                        <span :class="`${data.item.slaColor.toLowerCase()}--text`">
                          {{ data.item.sla }}
                        </span>
                      </td>
                      <td align="center" class="tableData">
                        <!-- Proses -->
                        <v-tooltip v-if="
                          ((data.item.reportStatus === 'REPORT_STATUS_REQUEST_QA_MANAGER' && role.includes('QA_MANAGER') && data.item.picAppr === username)||
                          (data.item.reportStatus === 'REPORT_STATUS_REQUEST_QA_OPERATION' && role.includes('QA_OPERATION') && data.item.picAppr === username)||
                          (data.item.reportStatus === 'REPORT_STATUS_REQUEST_AFM_MANAGER' && role.includes('AFM_MANAGER') && data.item.picAppr === username)||
                          (data.item.reportStatus === 'REPORT_STATUS_REQUEST_BC' && role.includes('PIC_BC') && data.item.picBc === username)||
                          (data.item.reportStatus === 'REPORT_STATUS_REQUEST_DH' && role.includes('PIC_DH') && data.item.picDh === username))
                          " bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs"
                              :to="`/investigasi-checker/${data.item.fraudId}/form`">
                              <v-icon aria-hidden="false" style="font-size: 20px" color="#F5821F">
                                mdi-share-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Proses</span>
                        </v-tooltip>

                        <!-- View -->
                        <v-tooltip v-else bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              text
                              class="text-capitalize black--text px-0"
                              v-on="on"
                              v-bind="attrs"
                              :to="`/investigasi-checker/${data.item.fraudId}/form`"
                            >
                              <v-icon
                                aria-hidden="false"
                                style="font-size: 20px"
                                color="#F5821F"
                              >
                                mdi-magnify
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>View</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      headersInvestigasiFraud: [
        {
          text: "No. Tiket JAGA",
          align: "center",
          width: "150px",
        },
        {
          text: "Tanggal Verifikasi",
          align: "center",
          width: "180px",
        },
        {
          text: "Kode Lokasi Kejadian",
          align: "center",
          width: "150px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "158px",
        },
        {
          text: "Modus Fraud",
          align: "center",
          width: "134px",
        },
        {
          text: " Status",
          align: "center",
          width: "170px",
        },
        {
          text: "Running SLA",
          align: "center",
          width: "100px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "100px",
        },
      ],
      role: [],
      currendDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      menuFrom: false,
      menuTo: false,
      reportDateFrom: "",
      reportDateTo: "",
      noJaga: "",
      codeLocM: "",
      locM: "",
      reportStatusM: "",
      infoStatus: [],
      inquiryChecker: [],
    };
  },

  async mounted() {
    this.loadInvestigasiFraud();
    this.loadStatus();
    this.handleLoginRole();

    console.log("Nilai nik:", this.username); // Cetak nilai nik
    if (this.username) {
      console.log("Nik memiliki nilai."); // Jika nik memiliki nilai
    } else {
      console.log("Nik tidak memiliki nilai."); // Jika nik tidak memiliki nilai
    }
  },

  created() {
    this.requiredFeature('INVESTIGASI_CHECKER')
    this.changeTitleApp("Investigasi Checker");
  },

  methods: {
    ...mapActions([
      "searchInvestigasiCheck",
      "changeTitleApp",
      "getParameterByParamType",
      "getLoginRole",
    ]),

    reset: function (){
      this.noJaga = "";
      this.codeLocM = "";
      this.locM = "";
      this.reportDateFrom = "";
      this.reportDateTo = "";
      this.reportStatusM = "";
      this.loadInvestigasiFraud();
    },

    loadStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) =>
              item.paramCd == "REPORT_STATUS_DELEGATE_INVESTIGATOR" ||
              item.paramCd == "REPORT_STATUS_INVESTIGATED" ||
              item.paramCd == "REPORT_STATUS_INVESTIGATION_OUTSTANDING" ||
              item.paramCd == "REPORT_STATUS_INVESTIGATION_REVISED" ||
              item.paramCd == "REPORT_STATUS_RE_INVESTIGATE" ||
              item.paramCd == "REPORT_STATUS_REQUEST_QA_MANAGER" ||
              item.paramCd == "REPORT_STATUS_REQUEST_QA_OPERATION" ||
              item.paramCd == "REPORT_STATUS_REQUEST_AFM_MANAGER" ||
              item.paramCd == "REPORT_STATUS_REQUEST_BC" ||
              item.paramCd == "REPORT_STATUS_REQUEST_DH" ||
              item.paramCd == "REPORT_STATUS_APPROVE_QA_MANAGER" ||
              item.paramCd == "REPORT_STATUS_APPROVE_QA_OPERATION" ||
              item.paramCd == "REPORT_STATUS_APPROVE_AFM_MANAGER" ||
              item.paramCd == "REPORT_STATUS_APPROVE_BC" ||
              item.paramCd == "REPORT_STATUS_APPROVE_DH" ||
              item.paramCd == "REPORT_STATUS_RETURN_QA_MANAGER" ||
              item.paramCd == "REPORT_STATUS_RETURN_QA_OPERATION" ||
              item.paramCd == "REPORT_STATUS_RETURN_AFM_MANAGER" ||
              item.paramCd == "REPORT_STATUS_RETURN_BC" ||
              item.paramCd == "REPORT_STATUS_RETURN_DH" ||
              item.paramCd == "REPORT_STATUS_DELEGATE_REVIEWER" ||
              item.paramCd == "REPORT_STATUS_REVIEWED" ||
              item.paramCd == "REPORT_STATUS_KF_DONE" ||
              item.paramCd == "REPORT_STATUS_INVESTIGATION_SUBMIT"
          );
          console.log("ini infoStatus", this.infoStatus);

          this.userRole = this.$store.state.roleCodeFromLogin
          console.log("Cek userRole: ", this.userRole)
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },   

    loadInvestigasiFraud() {
      this.searchInvestigasiCheck({
        pageNumber: 0,
        pageSize: 1000,
        refNo: "",
        jagaNo: this.noJaga,
        reportDateFrom: this.reportDateFrom,
        fraudLoc: this.locM,
        fraudLocCd: this.codeLocM,
        reportStatus: this.reportStatusM,
      }).then((resp) => {
        console.log(" ini untuk memunculkan data load ", resp);
      }).catch((err) => {
        // this.$helpers.handleError(err);

        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    pickDateForm() {
      this.menuFrom = false;
      this.reportDateFrom = moment(this.reportDateFromValPicker).format(
        "DD-MMM-YYYY"
      );
    },

    pickDateTo() {
      this.menuTo = false;
      this.reportDateTo = moment(this.reportDateToValPicker).format(
        "DD-MMM-YYYY"
      );
    },

    runningSla(dateReport) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date();
      const secondDate = new Date(dateReport);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      return diffDays;
    },

    handleLoginRole() {
      this.getLoginRole(this.username)
      .then((resp) => {
        this.role = resp.data.data;
        console.log(resp.data.data);
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },

    handleGetCheck() {
      this.searchInvestigasiCheck({
        fraudId: null,
        fraudLoc: this.locM,
        fraudLocCd: this.codeLocM,
        pageNumber: 0,
        pageSize: 1000,
        jagaNo: this.noJaga,
        refNo: "",
        reportStatus: this.reportStatusM,
        reportDate: "",
        reportDateFrom: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
        reportDateTo: this.reportDateTo ? moment(this.reportDateTo, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
      })
        .then((resp) => {
          this.countData = resp.data.data;
          this.totalData = resp.data.data;

          this.inquiryChecker = resp.data.data;
          console.log("berhasil di tampilkan", resp.data.data);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("error saat search", err.response);
        });
    },
  },

  computed: {
    ...mapState(['username', 'roleCodeFromLogin']),
    allInquiryInvestigasiCheck() {
      return Object.values(this.$store.getters.allInquiryInvestigasiCheck);
    },
  },
};
</script>
<style src="./InvestigasiChecker.scss" lang="scss">
