<template>
  <v-app>
    <v-form
      ref="formParameterType"
      v-model="validFormParameterType"
      lazy-validation
    >
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Parameter Type</p>
          <span class="titlet"></span>
          <p v-if="isEdit" class="subTitle">Edit Parameter Type</p>
          <p v-else class="subTitle">Tambah Parameter Type</p>
        </v-row>
        <v-col cols="12" md="18">
          <div class="top">
            <v-card cols="12">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Kode Parameter Type</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="parameterTypeCode"
                      :class="isEdit ? 'dl mb-0' : 'rp mb-0'"
                      hide-details="auto"
                      :readonly="isEdit"
                      :rules="[
                        (v) => !!v || 'kode parameter type harus diisi',
                        (v) =>
                          (v && v.length <= 64) ||
                          'kode parameter type must be less than 64 characters',
                      ]"
                      outlined
                      dense
                      placeholder="Masukan kode parameter type"
                      @keypress="noChar"
                      @paste="pasteChar"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Nama Parameter Type</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="parameterTypeName"
                      class="rp mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nama parameter type"
                      :rules="[
                        (v) => !!v || 'nama parameter type harus diisi',
                        (v) =>
                          (v && v.length <= 64) ||
                          'nama parameter type must be less than 64 characters',
                      ]"
                       @keypress="noChar"
                      @paste="pasteChar"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <v-row>
            <v-col class="text-right">
              <v-btn
                :to="`/parameter-type`"
                x-large
                depressed
                outlined
                class="elevation-0 text-capitalize mr-3 primary--text"
                ><span class="primary--text">Cancel</span></v-btn
              >

              <v-btn v-if="isEdit" x-large depressed color="primary" @click="editParamtype"
                ><span class="white--text">Save</span></v-btn
              >

              <v-btn v-else x-large depressed color="primary" @click="addParamType"
                ><span class="white--text">Save</span></v-btn
              >
              <!-- <v-btn
                v-if="isEdit"
                x-large
                depressed
                class="paramBtn ml-8"
                @click="editParamtype"
                ><span>Save</span></v-btn
              >

              <v-btn
                v-else
                x-large
                depressed
                class="paramBtn ml-8"
                @click="addParamType"
                ><span>Save</span></v-btn
              >

              <v-btn
                :to="`/parameter-type`"
                x-large
                depressed
                outlined
                class="back"
                ><span class="primary--text">Cancel</span></v-btn
              > -->
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      formParameterType: false,
      parameterTypeCode: "",
      parameterTypeName: "",
      // parameterType: {
      // paramTCode: "",
      // paramTName: "",
      // }
    };
  },

  created() {
    this.requiredFeature('PARAMETER_TYPE')
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "addParameterType",
      "getParameterTypeCd",
      "updateParamtype",
      "getInquiryParameterType",
    ]),

    async loadData() {
      if (this.isEdit) {
        await this.getParameterTypeCd({
          parameterTypeCode: this.idParamtypeCd,
        }).then((resp) => {
          this.formData = resp.data.data;
        });
        this.parameterTypeCode = this.formData.parameterTypeCode;
        this.parameterTypeName = this.formData.parameterTypeName;
      }
      // else {
      //   this.getInquiryParameterType({
      //     pageNumber: 0,
      //     pageSize: 1000,
      //     searchValue: "",
      //   })
      //     .then((resp) => {
      //       this.totalData = resp.data.countData;
      //       this.countData = resp.data.countData;
      //       this.paramTCode = resp.data.data.map((x) => x.parameterTypeCode);
      //       this.paramTName = resp.data.data.map((x) => x.parameterTypeName);
      //       console.log("ini memunculkan data", this.paramTCode);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.$store.state.inquiryParameterType = [];
      //     });
      // }
    },

    addParamType() {
      if (this.$refs.formParameterType.validate()) {
        this.addParameterType({
          parameterTypeCode: this.parameterTypeCode.trim(),
          parameterTypeName: this.parameterTypeName.trim(),
        })
          .then((resp) => {
            console.log("berhasil di add", resp);
            this.$router.push(`/parameter-type`);
            this.$helpers.handleSuccess(resp);
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },

    editParamtype() {
      if (this.$refs.formParameterType.validate()) {
        this.updateParamtype({
          parameterTypeCode: this.parameterTypeCode.trim(),
          parameterTypeName: this.parameterTypeName.trim(),
        })
          .then((resp) => {
            this.$router.push(`/parameter-type`);
            this.$helpers.handleSuccess(resp);
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },
  },

  computed: {
    ...mapState(["paramTypeCd", "inquiryParameterType"]),
    idParamtypeCd: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
  },
};
</script>