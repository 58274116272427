<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-5">
      <h1 class="page-title">Tambahan Modus & Kerugian</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field"> No. LHI </h7>
        <v-text-field
          v-model="jagaNo"
          class="mb-0 mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari no. LHI"
          label=""
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Lokasi Kejadian </h7>
        <v-text-field
          v-model="fraudLoc"
          class="mb-0 mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari lokasi kejadian"
          label=""
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary">
          Tanggal Selesai Investigasi
        </h7>
        <v-row no-gutters>
          <v-col cols="12">
            <v-menu
              ref="menu1"
              v-model="reportDateFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reportDateFrom"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-0 mr-1"
                  placeholder=""
                  clearable
                  background-color="white"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reportDateFromVal"
                no-title
                scrollable
                @input="pickReportDateFrom"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col cols="6">
            <v-menu
              ref="menu2"
              v-model="reportDateToMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reportDateTo"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-0 mr-1"
                  placeholder=""
                  clearable
                  background-color="white"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reportDateToVal"
                no-title
                scrollable
                @input="pickReportDateTo"
              >
              </v-date-picker>
            </v-menu>
          </v-col> -->
        </v-row>
      </v-col>
      <!-- <v-col cols="12" md="1">
        <h7 class="label-text-field white--text" color="primary"> "" </h7>
        
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Jenis Fraud </h7>
        <v-select
          v-model="fraudType"
          class="mb-0 mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          :items="jenisFraudList"
          item-text="paramName"
          item-value="paramCd"
          background-color="white"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          v-model="reportStatus"
          class="mb-0 mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
          background-color="white"
          clearable
        ></v-select>
      </v-col>
      <v-col md="4">
        <h7 class="label-text-field white--text" color="primary"> "" </h7>
        <v-row cols="12" md="4" class="text--right">
          <v-btn
            @click="handleGetInquiryReview"
            class="elevation-0 text-capitalize ml-3"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="elevation-0 text-capitalize ml-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
          >
            <span class="orange--text">Reset</span></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersReview"
                  :items="inquiryReview"
                  :items-per-page="5"
                  :header-props="{ sortIcon: null }"
                  disable-sort
                  mobile-breakpoint="0"
                  ><template v-slot:item="data">
                    <tr>
                      <td class="orange--text">{{ data.item.jagaNo }}</td>
                      <td>{{ data.item.investigationEndDateStr }}</td>
                      <td>{{ data.item.fraudLocName }}</td>
                      <td>{{ data.item.fraudTypeName }}</td>
                      <td>
                        <v-chip
                          class="ma-2"
                          color="orange lighten-4"
                          text-color="orange"
                        >
                          {{ data.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td align="center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              text
                              class="text-capitalize black--text px-0"
                              v-on="on"
                              v-bind="attrs"
                              :to="`/tambah-modus/${data.item.fraudId}/form`"
                            >
                              <v-icon
                                aria-hidden="false"
                                style="font-size: 20px"
                                color="#F5821F"
                              >
                                mdi-share-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Proses</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      fraudType: "",
      jenisFraudList: [],
      reportDateFromMenu: false,
      reportDateToMenu: false,
      infoStatus: [],
      fraudLoc: "",
      jagaNo: "",
      reportDate: "",
      reportDateFrom: "",
      reportDateTo: "",
      reportStatus: "",
      reportDateFromVal: null,
      reportDateToVal: null,
      headersReview: [
        {
          text: "No. Tiket JAGA",
          align: "center",
          width: "50px",
        },
        {
          text: "Tanggal Selesai Investigasi",
          align: "center",
          width: "100px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "100px",
        },
        {
          text: "Jenis Fraud",
          align: "center",
          width: "110px",
        },
        {
          text: "Status",
          align: "center",
          width: "44px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "63px",
        },
      ],
      inquiryReview: [],
    };
  },
  created() {
    this.requiredFeature('TAMBAH_MODUS_KERUGIAN')
    this.changeTitleApp("Tambahan Modus & Kerugian");
  },
  async mounted() {
    this.handleGetInquiryReview();
    this.loadReportStatus();
    this.loadJenisFraud();
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "searchDataTambahModus",
      "getParameterByParamType",
    ]),

    reset(){
      this.jagaNo = ''
      this.fraudLoc = ''
      this.reportDateFrom = ''
      this.reportDateTo = ''
      this.fraudType = ''
      this.reportStatus = ''

      this.handleGetInquiryReview();
    },

    handleGetInquiryReview() {
      this.searchDataTambahModus({
        fraudId: 0,
        fraudLoc: this.fraudLoc,
        fraudType: this.fraudType,
        jagaNo: this.jagaNo,
        pageNumber: 0,
        pageSize: 10,
        refNo: "",
        reportDate: "",
        investigationEndDt: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
        // reportDateFrom: this.reportDateFrom,
        reportDateTo: this.reportDateTo,
        reportStatus: this.reportStatus,
      })
        .then((response) => {
          this.inquiryReview = response.data.data;
          console.log("response", response);
        })
        .catch(() => {
          this.inquiryReview = [];
        });
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format("DD-MMM-YYYY");
    },

    pickReportDateTo() {
      this.reportDateToMenu = false;
      this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) =>
              item.paramCd === "REPORT_STATUS_KF_DONE" ||
              item.paramCd === "REPORT_STATUS_IN_SANCTION" ||
              item.paramCd === "REPORT_STATUS_CLOSED"
          );
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    loadJenisFraud() {
      this.getParameterByParamType({
        paramType: "FRAUD_TYPE",
      })
        .then((resp) => {
          this.jenisFraudList = resp.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style src="./TambahModus.scss" lang="scss" />