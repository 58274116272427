<template>
    <v-container fluid>
        <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
            <h1 class="page-title">Monitoring Report</h1>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" md="2">
                <h7 class="label-text-field"> No. Referensi </h7>
                <v-text-field v-model="refNo" class="mb-0 white mr-1" hide-details="auto" outlined dense
                    placeholder="Cari no. referensi" label=""></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <h7 class="label-text-field" color="primary"> No Jaga </h7>
                <v-text-field v-model="jagaNo" class="mb-0 white mr-1" hide-details="auto" outlined dense
                    placeholder="Cari lokasi kejadian" label=""></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <h7 class="label-text-field" color="primary"> Lokasi Kejadian </h7>
                <v-text-field v-model="jagaNo" class="mb-0 white mr-1" hide-details="auto" outlined dense
                    placeholder="Cari lokasi kejadian" label=""></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <h7 class="label-text-field" color="primary"> Tanggal Laporan </h7>
                <v-menu ref="menu1" v-model="reportDateFromMenu" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="reportDateFrom" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                            v-on="on" readonly outlined dense hide-details="auto" class="mb-0 white mr-1" placeholder=""
                            clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="reportDateFromVal" no-title scrollable @input="pickReportDateFrom">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <h7 class="label-text-field white--text" color="primary"> "" </h7>
                <v-menu ref="menu2" v-model="reportDateToMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="reportDateTo" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                            v-on="on" readonly outlined dense hide-details="auto" class="mb-0 white mr-1" placeholder=""
                            clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="reportDateToVal" no-title scrollable @input="pickReportDateTo">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2">
                <h7 class="label-text-field" color="primary"> Status </h7>
                <v-select v-model="reportStatus" class="mb-0 white mr-1" hide-details="auto" outlined dense
                    placeholder="Semua" label="" :items="infoStatus" item-text="paramName" item-value="paramCd"></v-select>
            </v-col>
            <v-col>
                <h7 class="label-text-field white--text" color="primary"> "" </h7>
                <v-row cols="12" md="4" class="text--right">
                    <v-btn @click="handleExportExcel" class="elevation-0 text-capitalize ml-3" cols="12" color="primary">
                        <span class="white--text">Export</span></v-btn>
                    <v-btn class="elevation-0 text-capitalize ml-2 primary--text" depressed outlined color="primary"
                        cols="12" @click="reset">
                        <span class="orange--text">Reset</span></v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import moment from "moment";

export default {
    data() {
        return {
            refNo: "",
            jagaNo: "",
            reportDateFrom: "",
            reportDateTo: "",
            reportStatus: "",
            infoStatus: [],
            reportDateToMenu: false,
            reportDateFromMenu: false,
            reportDateFromVal: null,
            reportDateToVal: null,
        }
    },
    mounted() {
        this.loadReportStatus();
    },
    methods: {
        ...mapActions(["getParameterByParamType", "exportExcelMonitoringReport"]),
        loadReportStatus() {
            this.getParameterByParamType({
                paramType: "REPORT_STATUS",
            })
                .then(response => {
                    this.infoStatus = response.data.data
                })
        },
        handleExportExcel() {
            this.exportExcelMonitoringReport({
                fraudLoc: this.fraudLoc,
                jagaNo: this.jagaNo,
                refNo: this.refNo,
                reportDateFrom: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
                reportDateTo: this.reportDateTo ? moment(this.reportDateTo, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
                reportStatus: this.reportStatus
            })
        },
        pickReportDateFrom() {
            this.reportDateFromMenu = false;
            this.reportDateFrom = moment(this.reportDateFromVal).format("DD-MMM-YYYY");
        },

        pickReportDateTo() {
            this.reportDateToMenu = false;
            this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
        },
    }
}
</script>