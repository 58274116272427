<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Menu</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Nama menu</p>
        <v-text-field
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="menuName"
          outlined
          placeholder="Cari..."
          dense
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">Type</p>
        <v-select
          :items="listMenuType"
          item-text="paramName"
          item-value="paramCd"
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="menuType"
          outlined
          dense
          placeholder="Semua"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">Level</p>
        <v-text-field
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="menuLevel"
          outlined
          placeholder="Cari..."
          dense
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn
            @click="load"
            class="elevation-0 text-capitalize ml-3 mt-2"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="elevation-0 text-capitalize ml-3 mt-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
          >
            <span class="orange--text">Reset</span></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pf">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="inquiryMenu"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                    ><template v-slot:item="allUser">
                      <tr>
                        <td>{{ allUser.item.menuName }}</td>
                        <td>{{ allUser.item.menuDesc }}</td>
                        <td>{{ allUser.item.menuType }}</td>
                        <td>{{ allUser.item.menuLevel }}</td>
                        <td>{{ allUser.item.parentId }}</td>
                        <td>{{ allUser.item.menuSeq }}</td>
                        <td>{{ allUser.item.menuUrl }}</td>
                        <td align="center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/menu/${allUser.item.menuId}/form`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-dialog
                          v-model="dialogDel[allUser.item.menuId]"
                          persistent
                          :key="allUser.item.menuId"
                          width="unset"
                        >
                          <template v-slot:activator="{ on: dialog, attrs }">
                            <v-tooltip bottom slot="activator">
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  text
                                  class="text-capitalize black--text px-0"
                                  v-on="{ ...dialog, ...tooltip }"
                                  v-bind="attrs"
                                >
                                  <v-icon aria-hidden="false" style="font-size: 18px" color="#F5821F">
                                    mdi-delete-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </template>
                          <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[
                                      allUser.item.menuId
                                    ] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Menu?
                                  <p>
                                   {{ allUser.item.menuName }}
                                  </p>
                                </div>
                                <v-btn
                                  class="paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(dialogDel, allUser.item.menuId, false)
                                  "
                                  >Batalkan</v-btn
                                >
                                <v-btn
                                  class="paramDialogDelBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeMenu(allUser.item.menuId),
                                      $set(dialogDel, allUser.item.menuId, false)
                                  "
                                  >Hapus</v-btn
                                >
                              </v-sheet>
                            </div>
                          <!-- <div class="text-center">
                            <v-sheet
                              class="
                                px-7
                                pt-7
                                pb-4
                                mx-auto
                                text-center
                                d-inline-block
                              "
                              color="blue-grey darken-3"
                            >
                              <div
                                class="
                                  grey--text
                                  text--lighten-1 text-body-2
                                  mb-4
                                "
                              >
                                Yakin untuk menghapus
                                {{ allUser.item.menuName }} ?
                              </div>
                              <v-btn
                                class="ma-1"
                                color="primary"
                                text
                                @click="
                                  $set(dialogDel, allUser.item.menuId, false)
                                "
                              >
                                Tidak
                              </v-btn>
                              <v-btn
                                class="ma-1"
                                color="primary"
                                text
                                @click="
                                  removeMenu(allUser.item.menuId),
                                    $set(dialogDel, allUser.item.menuId, false)
                                "
                              >
                                Ya
                              </v-btn>
                            </v-sheet>
                          </div> -->
                        </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      inquiryMenu: [],
      menuName: "",
      listMenuType: [],
      menuType: "",
      menuLevel: null,

      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      items: [],
      headers: [
        {
          text: "Menu Name",
          align: "center",
        },
        {
          text: "Menu Description",
          align: "center",
        },
        {
          text: "Type",
          align: "center",
        },
        {
          text: "Level",
          align: "center",
        },
        {
          text: "Menu Parent",
          align: "center",
        },
        {
          text: "Sequence",
          align: "center",
        },
        {
          text: "URL",
          align: "center",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
        },
      ],
      statusSearch: false,
      searchValueSearch: "",
      searchValue: "",
    };
  },
  async mounted() {
    this.load();
    this.loadMenuType();
  },
  created() {
    this.requiredFeature('MENU')
    this.changeTitleApp("Menu");
  },
  methods: {
    ...mapActions(["changeTitleApp", "searchMenu", "getParameterByParamType", "deleteMenu"]),

    reset: function () {
      this.menuName = "";
      this.menuType = "";
      this.menuLevel = null;

      this.load();
    },

    load() {
      this.searchMenu({
        menuDesc: "",
        menuId: null,
        menuLevel: this.menuLevel,
        menuName: this.menuName.trim(),
        menuSeq: null,
        menuType: this.menuType,
        menuUrl: "",
        pageNumber: 0,
        pageSize: 100,
        parentId: null,
        searchValue: "",
      })
        .then((res) => {
          this.inquiryMenu = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.inquiryMenu = [];
        });
    },

    loadMenuType() {
        this.getParameterByParamType({
            paramType: "MENU_TYPE"
        })
        .then((res) => {
            this.listMenuType = res.data.data;
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    search() {
      let pageNumber = 0;
      let pageSize = this.countData;
      let searchValue = this.searchValue;
      this.searchValueSearch = this.searchValue;
      this.$store
        .dispatch("getInquiryUser", {
          pageNumber,
          pageSize,
          searchValue,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (this.searchValue == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryUser = [];
        });
      this.page = 1;
    },

      removeMenu(id) {
          this.deleteMenu(id)
          .then((response) => {
            this.load();
            this.page = 1;
            this.$helpers.handleSuccess(response);
          })
          .catch((err) => {
            this.$helpers.handleError(err);
          });
      },
  },
  computed: {
    allInquiryUser() {
      return Object.values(this.$store.getters.allInquiryUser);
    },
    allInquiryParameter() {
      return Object.values(this.$store.getters.allInquiryParameter);
    },
    parameterByType() {
      return Object.values(this.$store.getters.parameterByType);
    },
  },
};
</script>
