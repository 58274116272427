// import Vue from 'vue';
// import Router from 'vue-router';
import store from '../store'

import Layout from '@/components/Layout/Layout';

// Pages
// import Dashboard from '@/pages/Dashboard/Dashboard';
import DashboardX from '@/pages/Dashboard/DashboardX2';
// import Typography from '@/pages/Typography/Typography'
// import Tables from '@/pages/Tables/Basic'
import Notifications from '@/pages/Notifications/Notifications'
// import Icons from '@/pages/Icons/Icons'
// import Charts from '@/pages/Charts/Charts'
// import Maps from '@/pages/Maps/Google'
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";

import Role from "@/pages/Role/Role";
import RoleFormAdd from "@/pages/Role/RoleForm";
import RoleFormEdit from "@/pages/Role/RoleForm";
import ParameterType from "@/pages/ParameterType/ParameterType";
import ParameterTypeFormAdd from "@/pages/ParameterType/ParameterTypeForm";
import ParameterTypeFormEdit from "@/pages/ParameterType/ParameterTypeForm";
// import RoleFormAdd from "@/pages/Role/RoleForm";
import Parameter from "@/pages/Parameter/Parameter";
import ParameterFormAdd from "@/pages/Parameter/ParameterForm";
import ParameterForm from "@/pages/Parameter/ParameterForm";

import User from "@/pages/User/User";
import UserFormAdd from "@/pages/User/UserForm";
import UserFormEdit from "@/pages/User/UserForm";

import Holidays from "@/pages/Holidays/Holidays";
import HolidaysFormAdd from "@/pages/Holidays/HolidaysForm";
import HolidaysFormEdit from "@/pages/Holidays/HolidaysForm";

import PengaduanFraud from "@/pages/PengaduanFraud/PengaduanFraud";
import PengaduanFraudAdd from "@/pages/PengaduanFraud/PengaduanFraudForm";
import PengaduanFraudEdit from "@/pages/PengaduanFraud/PengaduanFraudForm";
// import PengaduanFraudMaintenance from "@/pages/PengaduanFraud/PengaduanFraudMaintenance";

import VerifikasiData from "@/pages/VerifikasiData/VerifikasiData";
import VerifikasiDataAdd from "@/pages/VerifikasiData/VerifikasiDataForm";
import VerifikasiDataEdit from "@/pages/VerifikasiData/VerifikasiDataForm";
import ListOfViewVerifikasi from "@/pages/VerifikasiData/VerifikasiDataForm";

import Informasi from "@/pages/Informasi/Informasi";
import InformasiFormAdd from "@/pages/Informasi/InformasiForm";

import StopSelling from "@/pages/StopSelling/StopSelling";
import StopSellingForm from "@/pages/StopSelling/StopSellingForm";
import PusatBantuan from "@/pages/PusatBantuan/PusatBantuan";
import PusatBantuanForm from "@/pages/PusatBantuan/PusatBantuanForm";

import InvestigasiFraud from "@/pages/InvestigasiFraud/InvestigasiFraud";
import InvestigasiFraudForm from "@/pages/InvestigasiFraud/InvestigasiFraudForm";

import InvestigasiController from "@/pages/InvestigasiController/InvestigasiController";
import InvestigasiControllerForm from "@/pages/InvestigasiController/InvestigasiControllerForm";

import ReviewController from "@/pages/ReviewController/ReviewController";
import ReviewControllerForm from "@/pages/ReviewController/ReviewControllerForm";

import InvestigasiChecker from "@/pages/InvestigasiChecker/InvestigasiChecker";
import InvestigasiCheckerForm from "@/pages/InvestigasiChecker/InvestigasiCheckerForm";

import ReviewFraud from "@/pages/ReviewFraud/ReviewFraud";
import ReviewFraudForm from "@/pages/ReviewFraud/ReviewFraudForm";

import RecoveryAndPaymentNotes from "@/pages/RecoveryAndPaymentNotes/RecoveryAndPaymentNotes";
import RecoveryAndPaymentNotesForm from "@/pages/RecoveryAndPaymentNotes/RecoveryAndPaymentNotesForm";

import TambahModus from "@/pages/TambahModus/TambahModus";
// import TambahModusForm from "@/pages/TambahModus/TambahModusForm";
import TambahModusForm from "@/pages/TambahModus/TambahModusFormNew";
import KomiteFraud from "@/pages/KomiteFraud/KomiteFraud";
import KomiteFraudForm from "@/pages/KomiteFraud/KomiteFraudForm";

import Menu from "@/pages/Menu/Menu";
import MenuForm from "@/pages/Menu/MenuForm";
import Karyawan from "@/pages/Karyawan/Karyawan";
import Cabang from "@/pages/Cabang/Cabang";

import Report from "@/pages/MonitoringReport/MonitoringReport"

import Forbidden from "@/pages/Forbidden/Forbidden"


const prefixPath = ``//process.env.VUE_APP_PREFIX_ROUTE_PATH

// Vue.use(Router);

export default [
  // {
  //   path: '*',
  //   meta: {
  //     name: '',
  //     requiresAuth: true
  //   },
  //   redirect: {
  //     path: '/dashboard'
  //   }
  // },
  {
    path: `${prefixPath}/`,
    name: 'home',
    meta: {
      name: '',
      requiresAuth: false
    },
    component: Login,
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        if(store.state.haveDashboard) {
          next(`${prefixPath}/dashboard`)
        } else {
          next(`${prefixPath}/pengaduan-fraud`)
        }
      } else {
        next()
      }
    }
    // children: [
    //   {
    //     path: '',
    //     component: Login,
    //   }
    // ]
  },
  {
    path: `${prefixPath}/login`,
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        if(store.state.haveDashboard) {
          next(`${prefixPath}/dashboard`)
        } else {
          next(`${prefixPath}/pengaduan-fraud`)
        }
      } else {
        next()
      }
    }
  },
  {
    path: `${prefixPath}/`,
    meta: {
      name: 'baseLayout',
      requiresAuth: true
    },
    redirect: `${prefixPath}/login`,
    name: 'layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: DashboardX,
      },
      {
        path: `forbidden`,
        name: 'forbidden',
        component: Forbidden,
      },
      {
        path: 'role',
        name: 'Role',
        component: Role,
      },
      {
        path: 'role/form/add',
        name: 'RoleFormAdd',
        component: RoleFormAdd,
      },
      {
        path: 'role/:id/form/:mode',
        name: 'RoleFormMode',
        component: RoleFormEdit,
      },
      {
        path: 'parameter-type',
        name: 'ParameterType',
        component: ParameterType,
      },
      {
        path: 'parameter-type/form/add',
        name: 'ParameterTypeFormAdd',
        component: ParameterTypeFormAdd,
      },
      {
        path: 'parameter-type/:id/form/:mode',
        name: 'ParameterTypeFormEdit',
        component: ParameterTypeFormEdit,
      },
      {
        path: 'parameter',
        name: 'Parameter',
        component: Parameter,
      },
      {
        path: 'parameter/form/add',
        name: 'ParameterFormAdd',
        component: ParameterFormAdd,
      },
      {
        path: 'parameter/:id/form/:mode',
        name: 'ParameterFormByMode',
        component: ParameterForm
      },
      {
        path: 'pengguna',
        name: 'User',
        component: User,
      },
      {
        path: 'pengguna/form/add',
        name: 'UserFormAdd',
        component: UserFormAdd,
      },
      {
        path: 'pengguna/:id/form/:mode',
        name: 'UserFormEdit',
        component: UserFormEdit,
      },
      {
        path: 'master-hari-libur',
        name: 'Holidays',
        component: Holidays,
      },
      {
        path: 'master-hari-libur/form/add',
        name: 'HolidaysFormAdd',
        component: HolidaysFormAdd,
      },
      {
        path: 'master-hari-libur/:id/form/:mode',
        name: 'HolidaysFormEdit',
        component: HolidaysFormEdit,
      },
      {
        path: 'pengaduan-fraud',
        name: 'PengaduanFraud',
        component: PengaduanFraud,
        // component: PengaduanFraudMaintenance,
      },
      {
        path: 'pengaduan-fraud/form/add',
        name: 'PengaduanFraudAdd',
        component: PengaduanFraudAdd,
        // component: PengaduanFraudMaintenance,
      },
      {
        path: 'pengaduan-fraud/:id/form/:mode',
        name: 'PengaduanFraudEdit',
        component: PengaduanFraudEdit,
        // component: PengaduanFraudMaintenance,
      },
      {
        path: 'verifikasi-data',
        name: 'VerifikasiData',
        component: VerifikasiData,
      },
      {
        path: 'verifikasi-data/form/add',
        name: 'VerifikasiDataAdd',
        component: VerifikasiDataAdd,
      },
      {
        path: 'verifikasi-data/:id/form/:mode',
        name: 'VerifikasiDataEdit',
        component: VerifikasiDataEdit,
      },
      {
        path: 'verifikasi-data/:id/form/:mode',
        name: 'ListOfViewVerifikasiData',
        component: ListOfViewVerifikasi,
      },
      {
        path: 'informasi',
        name: 'Informasi',
        component: Informasi,
      },
      {
        path: 'informasi/form/add',
        name: 'InformasiFormAdd',
        component: InformasiFormAdd,
      },
      {
        path: 'stop-selling',
        name: 'StopSelling',
        component: StopSelling,
      },
      {
        path: 'stop-selling/form/add',
        name: 'StopSellingFormAdd',
        component: StopSellingForm,
      },
      {
        path: 'stop-selling/:id/form/:mode',
        name: 'StopSellingFormByMode',
        component: StopSellingForm
      },
      {
        path: 'pusat-bantuan-master',
        name: 'PusatBantuan',
        component: PusatBantuan
      },
      {
        path: 'pusat-bantuan-master/form/add',
        name: 'PusatBantuanForm',
        component: PusatBantuanForm
      },
      {
        path: 'pusat-bantuan-master/:id/form/:mode',
        name: 'PusatBantuanFormByMode',
        component: PusatBantuanForm
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications
      },
      {
        path: 'investigasi-fraud',
        name: 'InvestigasiFraud',
        component: InvestigasiFraud,
      },
      {
        path: 'investigasi-fraud/:fraudId/form',
        name: 'InvestigasiFraudForm',
        component: InvestigasiFraudForm,
      },
      {
        path: 'investigasi-controller',
        name: 'InvestigasiController',
        component: InvestigasiController,
      },
      {
        path: 'investigasi-controller/:fraudId/form',
        name: 'InvestigasiControllerForm',
        component: InvestigasiControllerForm,
      },
      {
        path: 'review-controller',
        name: 'ReviewController',
        component: ReviewController,
      },
      {
        path: 'review-controller/:fraudId/form',
        name: 'ReviewControllerForm',
        component: ReviewControllerForm,
      },
      {
        path: 'investigasi-checker',
        name: 'InvestigasiChecker',
        component: InvestigasiChecker,
      },
      {
        path: 'investigasi-checker/:fraudId/form',
        name: 'InvestigasiCheckerForm',
        component: InvestigasiCheckerForm,
      },
      {
        path: 'review-fraud',
        name: 'ReviewFraud',
        component: ReviewFraud,
      },
      {
        path: 'review-fraud/:fraudId/form',
        name: 'ReviewFraudForm',
        component: ReviewFraudForm,
      },
      {
        path: 'recovery-and-payment-notes',
        name: 'RecoveryAndPaymentNotes',
        component: RecoveryAndPaymentNotes,
      },
      {
        path: 'recovery-and-payment-notes/:fraudId/form',
        name: 'RecoveryAndPaymentNotesForm',
        component: RecoveryAndPaymentNotesForm,
      },
      {
        path: 'tambah-modus',
        name: 'TambahModus',
        component: TambahModus,
      },
      {
        path: 'tambah-modus/:fraudId/form',
        name: 'TambahModusForm',
        component: TambahModusForm,
      },  
      {
        path: 'komite-fraud',
        name: 'KomiteFraud',
        component: KomiteFraud,
      },
      {
        path: 'komite-fraud/:fraudId/form/:mode',
        name: 'KomiteFraudForm',
        component: KomiteFraudForm,
      },
      {
        path: 'menu',
        name: 'menu',
        component: Menu,
      },
      {
        path: 'menu/:menuId/form',
        name: 'menuEdit',
        component: MenuForm,
      },
      {
        path: 'master-karyawan',
        name: 'karyawan',
        component: Karyawan,
      },
      {
        path: 'master-cabang',
        name: 'cabang',
        component: Cabang,
      },
      {
        path: 'report',
        name: 'report',
        component: Report,
      }
    ],
  },
  {
    path: '*',
    name: 'Error',
    component: Error
  }
];
