<template>
  <v-container fluid>
    <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
      <v-card>
        <v-col>
          <v-card-text class="text-right">
            <v-icon
              class="icon"
              @click="
                (expandPembiayaanFiktif = false), handleSaveAuto(indexExpand)
              "
              >mdi-close</v-icon
            >
          </v-card-text>
          <v-card-title>{{ lossChosen.paramName }} </v-card-title>
          <v-row>
            <v-col class="text-left">
              <v-btn
                class="elevation-0 text-capitalize text-center"
                cols="12"
                color="white"
                @click="handleDownloadTemplate(lossChosen.paramCd)"
              >
                <v-icon aria-hidden="false" color="orange">
                  mdi-download </v-icon
                ><span class="orange--text ml-2">Download Template</span>
              </v-btn>
              <v-btn
                class="elevation-0 text-capitalize text-center"
                cols="12"
                color="white"
                @click="handleAddDetailLoss(indexExpand, lossChosen)"
              >
                <v-icon aria-hidden="false" color="orange">
                  mdi-plus-box </v-icon
                ><span class="orange--text ml-2">Tambah</span>
              </v-btn>
              <v-btn
                class="elevation-0 text-capitalize text-center"
                cols="12"
                color="white"
                @click="onButtonClickTemplateExpand(indexExpand)"
              >
                <v-icon aria-hidden="false" color="orange"> mdi-upload </v-icon
                ><span class="orange--text ml-2">Upload</span>
              </v-btn>
              <input
                :ref="`uploaderTemplate` + indexExpand"
                class="d-none"
                type="file"
                @change="
                  onFileChangedTemplate(
                    $event,
                    lossChosen.paramValue,
                    lossChosen.paramCd
                  )
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF'"
              :headers="headersPembayaranFiktif1Expand"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.appIdNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-menu
                      v-model="pencairanDateMenuExpand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.pencairanDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="pencairanDateVal[data.index]"
                        no-title
                        scrollable
                        @input="
                          pencairanDatePickerExpand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.plafond"
                      prefix="Rp"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingPokok"
                      prefix="Rp"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingMargin"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsPctg"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.tabunganBalance"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialogEx[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialogEx, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialogEx, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      v-if="data.item.version != 'Original'"
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'"
              :headers="headersPembayaranFiktif1Expand"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.appIdNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-menu
                      v-model="pencairanDateMenu3Expand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.pencairanDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="pencairanDateVal3[data.index]"
                        no-title
                        scrollable
                        @input="
                          pencairanDatePicker3Expand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.plafond"
                      prefix="Rp"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingPokok"
                      prefix="Rp"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingMargin"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsPctg"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.tabunganBalance"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog2Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog2Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog2Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      v-if="data.item.version != 'Original'"
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
              :headers="headersPembayaranFiktif1ExpandSebagian"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.appIdNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-menu
                      v-model="pencairanDateMenu2Expand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.pencairanDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="pencairanDateVal2[data.index]"
                        no-title
                        scrollable
                        @input="
                          pencairanDatePicker2Expand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.plafond"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), pembiayaanPercentage(data.item), karyawan(data.item), karyawanPercentage(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.pembiayaanNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), pembiayaanPercentage(data.item), karyawan(data.item), karyawanPercentage(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.pembiayaanNasabahPercentage"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsPctg"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.pembiayaanKaryawan"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.pembiayaanKaryawanPercentage"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsPctg"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingTotal"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), outstandingNasabahNominal(data.item),  outstandingKaryawanNominal(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingKaryawan"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.tabunganBalance"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), potensiKerugian(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog3Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog3Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog3Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      v-if="data.item.version != 'Original'"
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
              :headers="headersPembayaranFiktif2ExpandSetoran"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.appIdNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.angsuranStatus"
                      :items="statusAngsuranList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-menu
                      v-model="fraudRepayDateMenuExpand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.fraudRepayDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fraudRepayDateVal[data.index]"
                        no-title
                        scrollable
                        @input="
                          fraudRepayDatePickerExpand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog4Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog4Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog4Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
              :headers="headersPembayaranFiktif2ExpandPelunasan"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.appIdNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.outstandingPelunasan"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), potensiKerugianPelunasan(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.tabunganBalance"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), potensiKerugianPelunasan(data.item), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-menu
                      v-model="fraudRepayDateMenu2Expand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.fraudRepayDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fraudRepayDateVal2[data.index]"
                        no-title
                        scrollable
                        @input="
                          fraudRepayDatePicker2Expand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog5Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog5Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog5Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
              :headers="headersPembayaranFiktif2ExpandTabungan"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.nasabahName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.tabunganNo"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.transaksiType"
                      :items="transaksiTypeList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.prsCollection"
                      :items="prsCollectionList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-menu
                      v-model="fraudRepayDateMenu3Expand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.fraudRepayDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fraudRepayDateVal3[data.index]"
                        no-title
                        scrollable
                        @input="
                          fraudRepayDatePicker3Expand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog6Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog6Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog6Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'"
              :headers="headersPembayaranFiktif3ExpandBrw"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.mthdDetailDesc"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.brwCategory"
                      :items="brwCategoryList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog7Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog7Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog7Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'"
              :headers="headersPembayaranFiktif3ExpandKas"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.sentraName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.kasSolCategory"
                      :items="kasSolCategoryList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-menu
                      v-model="fraudRepayDateMenu4Expand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.fraudRepayDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fraudRepayDateVal4[data.index]"
                        no-title
                        scrollable
                        @input="
                          fraudRepayDatePicker4Expand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog8Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog8Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog8Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
              :headers="headersPembayaranFiktif3ExpandLemari"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.mthdDetailDesc"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog9Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog9Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog9Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'"
              :headers="headersPembayaranFiktif3ExpandFpb"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.itemName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-menu
                      v-model="fpbTransactionDateMenuExpand[data.index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.item.fpbTransactionDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mt-3"
                          placeholder=""
                          :disabled="data.item.version == 'Original'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fpbTransactionDateVal[data.index]"
                        no-title
                        scrollable
                        @input="
                          fpbTransactionDatePickerExpand(data),
                            diedit(data.item, data.index)
                        "
                      >
                      </v-date-picker>
                    </v-menu>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog10Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog10Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog10Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'"
              :headers="headersPembayaranFiktif4Expand"
              :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }"
              disable-sort
            >
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field
                      class="mt-3"
                      dense
                      outlined
                      hide-details="auto"
                      persistent-placeholder
                      v-model="data.item.itemName"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                      @keypress="noChar"
                      @paste="pasteChar"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                      class="mt-3"
                      dense
                      outlined
                      persistent-placeholder
                      hide-details="auto"
                      v-model="data.item.pelakuPencurian"
                      :items="pelakuPencurianList"
                      item-text="paramName"
                      item-value="paramCd"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    >
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.potentialLoss"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryNasabah"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.recoveryBranch"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <vuetify-money
                      v-model="data.item.netFraud"
                      class="mb-0 mt-3"
                      hide-details
                      label=""
                      outlined
                      dense
                      readonly
                      v-bind:options="optionsMoney"
                      @input="diedit(data.item, data.index)"
                      :disabled="data.item.version == 'Original'"
                    />
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openPelakuDialog(
                          data.item.detailLossId,
                          data.item.detailLossTempId,
                          data.item.netFraud,
                          data.index,
                          data.item.version
                        ),
                          diedit(data.item, data.index)
                      "
                      >Pelaku</v-btn
                    >
                  </td>
                  <td>
                    <v-dialog
                      v-model="dialog11Ex[data.index]"
                      :key="data.index"
                      persistent
                      width="600"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          class="primary--text"
                          v-bind="attrs"
                          v-on="on"
                          >Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea
                              class="mb-0"
                              hide-details="auto"
                              outlined
                              dense
                              placeholder=""
                              v-model="data.item.notes"
                              @input="diedit(data.item, data.index)"
                              :disabled="data.item.version == 'Original'"
                              @keypress="noChar"
                      @paste="pasteChar"
                            ></v-textarea>
                          </v-card-text>
                          <v-card-actions>
                            <v-col class="text-right">
                              <v-btn
                                class="
                                  elevation-0
                                  text-capitalize
                                  mr-3
                                  primary--text
                                "
                                depressed
                                outlined
                                color="primary"
                                @click="$set(dialog11Ex, data.index, false)"
                              >
                                <span class="orange--text">Cancel</span></v-btn
                              >
                              <v-btn
                                class="elevation-0 text-capitalize mr-3"
                                depressed
                                color="primary"
                                @click="$set(dialog11Ex, data.index, false)"
                              >
                                <span class="white--text">Save</span></v-btn
                              >
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn
                      text
                      class="primary--text"
                      @click="
                        openDocumentDialog(data.item, data.index),
                          diedit(data.item, data.index)
                      "
                      >Document Image</v-btn
                    >
                  </td>
                  <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                      v-if="data.item.version != 'Original'"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog width="1000" v-model="pelakuFraudDialog">
      <v-card>
        <v-card-title>Pelaku Fraud</v-card-title>
        <v-card-text
          >Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text
        >
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="headersPelakuFraud"
            :items="isiPelakuList"
            :items-per-page="5"
            :header-props="{ sortIcon: null }"
            disable-sort
          >
            <template v-slot:item="data">
              <tr>
                <td>
                  {{ data.item.pelakuName }}
                </td>
                <td>
                  {{ data.item.pelakuNik }}
                </td>
                <td>
                  {{ data.item.pelakuPosition }}
                </td>
                <td>
                  {{ data.item.pelakuStatus }}
                </td>
                <td>
                  <vuetify-money
                    v-model="data.item.netFraudDist"
                    prefix="Rp"
                    class="mb-0 mt-3"
                    hide-details
                    label=""
                    outlined
                    dense
                    v-bind:options="optionsMoney"
                    @input="calculate()"
                    :disabled="statusBaris == 'Original'"
                  />
                </td>
              </tr>
            </template>
            <!-- <template v-slot:[`item.netFraudDist`]="{ item }"> -->
            <!-- <v-text-field
                class="mt-3"
                dense
                outlined
                hide-details="auto"
                persistent-placeholder
                v-model="item.netFraudDist"
              >
              </v-text-field> -->
            <!-- <vuetify-money
                v-model="item.netFraudDist"
                prefix="Rp"
                class="mb-0 mt-3"
                hide-details
                label=""
                outlined
                dense
                v-bind:options="optionsMoney"
                @input="calculate(), dieditPelaku(item)"
              />
            </template> -->
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize mr-3 primary--text"
              depressed
              outlined
              color="primary"
              @click="(pelakuFraudDialog = false), clear()"
            >
              <span class="orange--text">Cancel</span></v-btn
            >
            <v-btn
              :disabled="totalFraud != netFraudText"
              class="elevation-0 text-capitalize mr-3"
              depressed
              color="primary"
              @click="(pelakuFraudDialog = false), dieditPelaku()"
            >
              <span class="white--text">Save</span></v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="documentDialog">
      <v-card>
        <v-card-title>Upload Images</v-card-title>
        <v-card-text>Format file</v-card-text>
        <v-card-text>
          <v-row
            no-gutters
            v-for="(document, index) in isiDocumentList"
            :key="index"
          >
            <v-col> {{ index + 1 }}. {{ document.documentTypeName }} </v-col>
            <v-col class="text-right pr-3">
              <v-btn
                color="primary"
                class="text-none pr-3"
                rounded
                depressed
                :loading="isSelecting"
                @click="onButtonClick(index, document)"
              >
                <v-icon left> mdi-upload </v-icon>
                {{
                  document.attachFileName ? document.attachFileName : "Upload"
                }}
              </v-btn>
              <input
                :ref="`uploader` + index"
                class="d-none"
                type="file"
                accept="image/*, .doc, .docx, .pdf, .xls, .xlsx"
                @change="onFileChanged($event, index)"
              />
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
                v-model="document.notes"
                :disabled="statusBaris == 'Original'"
                @keypress="noChar"
                      @paste="pasteChar"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters>
            <v-col> 1. AP3R/AKAD </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col> 2. KTP </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col> 3. Prospera(Pencairan) </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col> 4. BKC(Buku Kas Cadangan) </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col> 5. FSTRP (manual) </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col> 6. SP Karyawan </v-col>
            <v-col class="text-right">
              <v-btn text class="primary--text">Upload</v-btn>
            </v-col>
            <v-col>
              <v-text-field
                hide-details="auto"
                dense
                outlined
                placeholder="Keterangan"
              ></v-text-field>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize mr-3 primary--text"
              depressed
              outlined
              color="primary"
              @click="documentDialog = false"
            >
              <span class="orange--text">Cancel</span></v-btn
            >
            <v-btn
              class="elevation-0 text-capitalize mr-3"
              depressed
              color="primary"
              @click="(documentDialog = false), dieditDocument()"
            >
              <span class="white--text">Save</span></v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
      <v-overlay :absolute="true" :value="isLoading">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog width="800" v-model="keteranganDialog">
      <v-card>
        <v-card-title>Keterangan</v-card-title>
        <v-card-text>
          <v-textarea v-model="catatan"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize mr-3 primary--text"
              depressed
              outlined
              color="primary"
              @click="keteranganDialog = false"
            >
              <span class="orange--text">Cancel</span></v-btn
            >
            <v-btn
              class="elevation-0 text-capitalize mr-3"
              depressed
              color="primary"
              @click="keteranganDialog = false"
            >
              <span class="white--text">Save</span></v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="header d-flex mt-4 mb-1">
      <p class="titlet">Tambahan Modus & Kerugian</p>
      <span class="titlet"></span>
      <p class="subTitle">Proses</p>
    </v-row>
    <v-col>
      <v-card rounded="lg">
        <v-tabs v-model="tab" center-active>
          <v-tab href="#data-investigator">Data Investigator</v-tab>
          <v-tab href="#detail-laporan">Detail Laporan</v-tab>
          <v-tab href="#verifikasi-fraud">Verifikasi Fraud</v-tab>
          <v-tab
            href="#tab-laporanPelengkap"
            v-if="
              investigationDelegatorPosition ==
              'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
            "
            >Laporan Pelengkap</v-tab
          >
          <v-tab href="#investigasi-fraud">Investigasi Fraud</v-tab>

          <v-tab-item value="data-investigator">
            <v-card flat>
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="6">
                    <h7 class="label-text-field">Nama Investigator</h7>
                    <v-text-field
                      hide-details="auto"
                      dense
                      outlined
                      v-model="namaInvestigator"
                      disabled
                    ></v-text-field>
                    <h7 class="label-text-field"
                      >Jabatan/Posisi Investigator</h7
                    >
                    <v-text-field
                      hide-details="auto"
                      dense
                      outlined
                      v-model="jabatanInvestigator"
                      disabled
                    ></v-text-field>
                    <h7 class="label-text-field">No. Telepon Investigator</h7>
                    <v-text-field
                      hide-details="auto"
                      dense
                      outlined
                      v-model="picInvestigatorPhone"
                      @keypress="numOnly"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h7 class="label-text-field">NIK Investigator</h7>
                    <v-text-field
                      hide-details="auto"
                      dense
                      outlined
                      v-model="nikInvestigator"
                      disabled
                    ></v-text-field>
                    <h7 class="label-text-field">Email Investigator</h7>
                    <v-text-field
                      hide-details="auto"
                      dense
                      outlined
                      v-model="emailInvestigator"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="elevation-0 text-capitalize mr-3 primary--text"
                      depressed
                      outlined
                      color="primary"
                      :to="`/tambah-modus`"
                      x-large
                    >
                      <span class="orange--text">Back</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="detail-laporan">
            <v-card cols="12">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="12">
                    <h7 class="label-text-field">
                      No. Tiket JAGA
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field
                      v-model="jagaNo"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Pelaporan
                    </h7>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="reportDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mb-0"
                          placeholder=""
                          disabled
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="currentDate"
                        v-model="inputStartDateVal"
                        no-title
                        scrollable
                        @input="formatStartDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Jenis Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn
                        x-large
                        class="elevation-0 text-capitalize ml-5 primary--text"
                        depressed
                        outlined
                        color="primary"
                        cols="12"
                      >
                        <span class="primary--text">{{
                          fraudTypeName
                        }}</span></v-btn
                      >
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Sumber Informasi Awal Temuan Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-select
                      item-text="paramName"
                      :items="sumberInformasiList"
                      label=""
                      class="mb-0"
                      hide-details="auto"
                      v-model="fraudInf"
                      item-value="paramCd"
                      outlined
                      dense
                      clearable
                      placeholder="Pilih sumber informasi awal temuan fraud"
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="fraudInf == 'FRAUD_INF_OTHER'">
                    <h7 class="label-text-field" color="primary">
                      Lainnya
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field
                      v-model="fraudInfReason"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan kode lokasi kerja terjadinya fraud"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="18">
                    <h7 class="label-text-field" color="primary">
                      Yang Terlibat
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          hide-default-footer
                          :headers="headersYangTerlibat"
                          :items="fraudPelakuList"
                          :items-per-page="5"
                          :header-props="{ sortIcon: null }"
                          disable-sort
                        >
                          <template v-slot:[`item.pelakuJointDate`]="{ item }">
                            {{ item.pelakuJointDate }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Lokasi Kerja Terjadinya Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field
                      v-model="fraudLocName"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Kerugian Awal
                      <!-- <span class="grey--text ml-2">Wajib</span> -->
                    </h7>
                    <v-text-field
                      v-model="initialLoss"
                      prefix="Rp"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Modus Operandi
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          hide-default-footer
                          :headers="headersModus"
                          :items="modusOperandiList"
                          :items-per-page="5"
                          :header-props="{ sortIcon: null }"
                          disable-sort
                        >
                          <template v-slot:item="data">
                            <tr>
                              <td>
                                {{ data.index + 1 }}
                              </td>
                              <td>
                                <!-- <v-autocomplete
                                  class="mt-3"
                                  dense
                                  outlined
                                  persistent-placeholder
                                  hide-details="auto"
                                  v-model="data.item.fraudMthd"
                                  :items="kodeModusList"
                                  item-text="paramName"
                                  item-value="paramCd"
                                  @change="
                                    handleGetKronologiList(
                                      data.item.fraudMthd,
                                      data.index
                                    )
                                  "
                                >
                                </v-autocomplete> -->
                                {{ data.item.fraudMthdName }}
                              </td>
                              <td>
                                <!-- <v-autocomplete
                                  class="mt-3"
                                  dense
                                  outlined
                                  persistent-placeholder
                                  hide-details="auto"
                                  v-model="data.item.incident"
                                  :items="kronologiList[data.index]"
                                  item-text="paramName"
                                  item-value="paramCd"
                                >
                                </v-autocomplete> -->
                                {{ data.item.incidentName }}
                              </td>
                              <td>
                                <!-- <v-text-field
                                  class="mt-3"
                                  dense
                                  outlined
                                  hide-details="auto"
                                  persistent-placeholder
                                  v-model="data.item.incidentDesc"
                                >
                                </v-text-field> -->
                                {{ data.item.incidentDesc }}
                              </td>
                              <!-- <td style="text-align: center">
                                <v-icon
                                  small
                                  color="red"
                                  @click="deleteRowOperandi(data.item)"
                                >
                                  mdi-delete
                                </v-icon>
                              </td> -->
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col>
                    <div
                      slot="activator"
                      style="
                        border-style: dashed;
                        border-color: lightgrey;
                        width: 130px;
                        height: auto;
                      "
                    >
                      <v-card
                        class="elevation-0 text-capitalize text-center"
                        cols="12"
                        color="white"
                        @click="handleAdd"
                      >
                        <v-icon aria-hidden="false" color="orange">
                          mdi-plus-box </v-icon
                        ><span class="orange--text ml-2">Tambah</span>
                      </v-card>
                    </div>
                  </v-col> -->
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Attachment
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col cols="12" v-for="item in attachment" :key="item">
                      <a @click="handleDownloadAttch(item)">
                        {{ item.attachFileName }}
                      </a>
                    </v-col>
                  </v-col>
                  <!-- <v-col cols="12" v-if="reportType !== 'REPORT_TYPE_ANONIM'">
                    <h7 class="label-text-field" color="primary">
                      Link Sharepoint
                      <span class="red--text"> *</span>
                    </h7>
                    <span class="grey--text ml-2">Wajib</span>
                    <v-text-field
                      v-model="sharepointLink"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan link share point"
                      disabled
                    ></v-text-field>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="elevation-0 text-capitalize mr-3 primary--text"
                      depressed
                      outlined
                      color="primary"
                      :to="`/tambah-modus`"
                      x-large
                    >
                      <span class="orange--text">Back</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="verifikasi-fraud">
            <v-card cols="12" md="18">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Verification Status
                      <span class="grey--text ml-2">Wajib</span>
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn
                        x-large
                        class="elevation-0 text-capitalize ml-5 primary--text"
                        depressed
                        outlined
                        color="primary"
                        cols="12"
                      >
                        <span class="primary--text">{{
                          verifStatusName
                        }}</span></v-btn
                      >
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Dokumen Awal Pelaporan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col>
                      <v-row>
                        <div
                          v-for="(dokumen, index) in reportDocTypeList"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="dokumen.reportDocType"
                            :label="dokumen.reportDocTypeName"
                            class="px-2"
                            outline
                            readonly
                          ></v-checkbox>
                        </div>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Nominal SP Pelaku
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field
                      v-model="spPelaku"
                      class="mb-0"
                      prefix="Rp"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="0"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      SP Nasabah / Pihak Ketiga
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field
                      v-model="spNasabah"
                      class="mb-0"
                      prefix="Rp"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder=""
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Manager Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field
                      v-model="investigationDelegatorName"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan Investigator Manager"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      No Hp Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field
                      v-model="investigationDelegatorPhone"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="contoh: 0812xxxxxx"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Didelegasi Oleh
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn
                        x-large
                        class="elevation-0 text-capitalize ml-5 grey--text"
                        depressed
                        outlined
                        color="grey"
                        cols="12"
                      >
                        <span class="grey--text">{{
                          investigationDelegatorPositionName
                        }}</span></v-btn
                      >
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Keterangan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-textarea
                      v-model="extFraudMthdDesc"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder=""
                      disabled
                    ></v-textarea>
                  </v-col>
                  <!-- <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Return
                    </h7>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="returnDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="mb-0"
                          placeholder=""
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="currentDate"
                        v-model="inputEndDateVal"
                        no-title
                        scrollable
                        @input="formatEndDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="elevation-0 text-capitalize mr-3 primary--text"
                      depressed
                      outlined
                      color="primary"
                      :to="`/tambah-modus`"
                      x-large
                    >
                      <span class="orange--text">Back</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-laporanPelengkap">
            <v-card-text>
              <v-row class="mx-1">
                <v-col
                  cols="12"
                  v-if="
                    investigationDelegatorPosition ==
                    'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
                  "
                >
                  <h7 class="label-text-field" color="primary"> Attachment </h7>
                  <v-col cols="12" v-for="item in attachmentAfm" :key="item">
                    <a @click="handleDownloadAttch(item)">
                      {{ item.attachFileName }}
                    </a>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="investigasi-fraud">
            <v-form ref="investigasiForm">
              <v-card cols="12">
                <v-card-text>
                  <v-row class="mx-1">
                    <v-col>
                      <h7 class="label-text-field" color="primary">
                        Investigation Status
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-col align="left" style="margin-left: -12px">
                        <v-col align="left" style="margin-left: -12px">
                          <v-btn
                            x-large
                            class="
                              elevation-0
                              text-capitalize
                              ml-5
                              primary--text
                            "
                            depressed
                            outlined
                            color="primary"
                            cols="12"
                          >
                            <span class="primary--text">{{
                              investigationStatusName
                            }}</span></v-btn
                          >
                        </v-col>
                        <!-- <v-btn-toggle v-model="investigationStatus">
                          <v-btn
                            x-large
                            :class="
                              investigationStatus ==
                              'INVESTIGATION_STATUS_NOT_PROVEN'
                                ? 'elevation-0 text-capitalize primary--text'
                                : 'elevation-0 text-capitalize grey--text'
                            "
                            style="
                              width: 112px;
                              height: 48px;
                              border: 1px solid #f5821f;
                              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                              border-radius: 8px;
                            "
                            depressed
                            outlined
                            :color="
                              investigationStatus ==
                              'INVESTIGATION_STATUS_NOT_PROVEN'
                                ? 'primary'
                                : 'grey'
                            "
                            cols="12"
                            value="INVESTIGATION_STATUS_NOT_PROVEN"
                          >
                            Not Proven</v-btn
                          >
                          <v-btn
                            x-large
                            :class="
                              investigationStatus ==
                              ('INVESTIGATION_STATUS_PROVEN' || 'Proven')
                                ? 'elevation-0 text-capitalize ml-3 primary--text'
                                : 'elevation-0 text-capitalize ml-3 grey--text'
                            "
                            style="
                              width: 112px;
                              height: 48px;
                              border: 1px solid #f5821f;
                              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                              border-radius: 8px;
                            "
                            depressed
                            outlined
                            :color="
                              investigationStatus ==
                              'INVESTIGATION_STATUS_PROVEN'
                                ? 'primary'
                                : 'grey'
                            "
                            cols="12"
                            value="INVESTIGATION_STATUS_PROVEN"
                          >
                            Proven</v-btn
                          >
                        </v-btn-toggle> -->
                      </v-col>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_NOT_PROVEN'
                      "
                    >
                      <h7
                        class="label-text-field"
                        color="primary"
                        v-if="
                          investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                        "
                      >
                        Notes
                      </h7>
                      <span
                        class="grey--text ml-2"
                        v-if="
                          investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                        "
                        >Wajib</span
                      >
                      <v-textarea
                        v-model="note"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder=""
                        v-if="
                          investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                        "
                        :rules="
                          investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                            ? notesRules
                            : notesNoRules
                        "
                        disabled
                      ></v-textarea>
                      <!-- <h7
                        class="label-text-field"
                        color="primary"
                        v-if="
                          investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                        "
                      >
                        Detail/Cara Fraud Dilakukan
                      </h7>
                      <v-textarea
                        v-model="fraudDetail"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder=""
                        v-if="
                          investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                        "
                        disabled
                      ></v-textarea> -->
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Tujuan Fraud
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-select
                        item-text="paramName"
                        :items="tujuanFraudList"
                        label=""
                        class="mb-0"
                        hide-details="auto"
                        v-model="fraudFactor"
                        item-value="paramCd"
                        outlined
                        dense
                        clearable
                        disabled
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN' &&
                        fraudFactor == 'FRAUD_FACTOR_OTHER'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Lainnya
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-text-field
                        v-model="fraudFactorDesc"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="Jelaskan secara rinci"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Alasan Pelaku Melakukan Fraud
                      </h7>
                      <v-textarea
                        v-model="fraudReason"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="jelaskan secara rinci"
                        disabled
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Hasil Kecurangan Digunakan Untuk Apa ?
                      </h7>
                      <v-textarea
                        v-model="fraudPurpose"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="jelaskan secara rinci"
                        disabled
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Kontrol Yang Tidak Berjalan
                      </h7>
                      <v-textarea
                        v-model="fraudControl"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="jelaskan secara rinci"
                        disabled
                      ></v-textarea>
                    </v-col> -->
                    <!-- <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Lokasi Kerja Terjadinya Fraud
                      </h7>
                      <v-text-field
                        v-model="fraudDetail"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="jelaskan secara rinci"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Jenis Pelanggaran
                      </h7>
                      <v-select
                        item-text="paramName"
                        :items="jenisPelanggaranList"
                        label=""
                        class="mb-0"
                        hide-details="auto"
                        v-model="violationType"
                        item-value="paramCd"
                        outlined
                        dense
                        clearable
                        disabled
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN' &&
                        violationType == 'VIOLATION_TYPE_OTHER'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Lainnya
                      </h7>
                      <v-text-field
                        v-model="violationTypeDesc"
                        class="mb-0"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="Jelaskan secara rinci"
                        disabled
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                  <h7 class="label-text-field" color="primary">
                    Jenis Pelanggaran
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headersJenisPelanggaran"
                        :items="jenisPelanggaranListToSave"
                        :items-per-page="5"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">{{ data.item.assignmentCategory }}</td>
                            <td align="center">{{ data.item.violationTypeName }}</td>
                            <td align="center">{{ data.item.violationDesc }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Root Cause
                        <span class="ml-2 grey--text">(Optional)</span>
                      </h7>
                      <v-row style="width: 100%">
                        <v-col cols="12">
                          <v-data-table
                            hide-default-footer
                            :headers="headersRoot"
                            :items="rootCouse"
                            :items-per-page="10"
                            :header-props="{ sortIcon: null }"
                            disable-sort
                          >
                            <template v-slot:item="data">
                              <tr>
                                <td align="center">{{ data.index + 1 }}</td>
                                <td align="center">
                                  {{ data.item.rootCauseName }}
                                </td>
                                <td>
                                  <v-select
                                    ref="keteranganRootCause"
                                    :items="rootCauseStatus[data.index]"
                                    item-text="rootCauseName"
                                    item-value="rootCauseDesc"
                                    v-model="data.item.rootCauseList"
                                    return-object
                                    label=""
                                    class="mt-3 mb-3"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    placeholder=""
                                    multiple
                                    chips
                                    :rules="
                                      mandatoryList[data.index] ==
                                        'Mandatory' &&
                                      investigationStatus ==
                                        'INVESTIGATION_STATUS_PROVEN'
                                        ? rootCauseRules
                                        : noRootCauseRules
                                    "
                                    disabled
                                  >
                                  </v-select>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Tanggal Fraud Dilakukan
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu
                        v-model="fraudCommitedDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fraudCommitedDate"
                            label=""
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0"
                            placeholder=""
                            disabled
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fraudCommitedDateVal"
                          :max="reportDateFormat"
                          no-title
                          scrollable
                          @input="fraudCommitedDatePicker()"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Tanggal Fraud Diketahui
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu
                        v-model="fraudKnownDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fraudKnownDate"
                            label=""
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0"
                            placeholder=""
                            disabled
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :max="reportDateFormat"
                          v-model="fraudKnownDateVal"
                          no-title
                          scrollable
                          @input="fraudKnownDatePicker()"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <h7 class="label-text-field" color="primary">
                        Tanggal Komitmen Recovery
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu
                        v-model="recoveryDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="recoveryCommitDate"
                            label=""
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-0"
                            placeholder=""
                            disabled
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="recoveryDateVal"
                          no-title
                          scrollable
                          @input="recoveryDatePicker()"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      v-if="
                        investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      "
                    >
                      <v-row>
                        <v-col>
                          <h3>Rincian Daftar Kerugian</h3>
                        </v-col>
                        <!-- <v-col class="text-right">
                          <v-btn
                            class="elevation-0 text-capitalize text-center"
                            cols="12"
                            color="white"
                            @click="handleDownloadDataRincian"
                          >
                            <v-icon aria-hidden="false" color="orange">
                              mdi-download </v-icon
                            ><span class="orange--text ml-2"
                              >Download Rincian Kerugian</span
                            >
                          </v-btn>
                          <v-btn
                            class="elevation-0 text-capitalize text-center"
                            cols="12"
                            color="white"
                            @click="handleDownloadDataRekap"
                          >
                            <v-icon aria-hidden="false" color="orange">
                              mdi-download </v-icon
                            ><span class="orange--text ml-2"
                              >Download Rekap</span
                            >
                          </v-btn>
                        </v-col> -->
                      </v-row>
                      <v-row>
                        <v-expansion-panels v-model="panelIndex" accordion>
                          <v-expansion-panel
                            @click="onExpansionPanelClick(index, $event)"
                            v-for="(loss, index) in lossList"
                            :key="index"
                          >
                            <v-expansion-panel-header
                              ><v-row no-gutters
                                ><v-col :class="pembiayaanFiktifList[index].length > 0 ? 'font-weight-bold' : 'font-weight-regular'"><p>{{ loss.paramName }}</p> </v-col
                                ><v-col class="text-right"
                                  ><v-btn
                                    text
                                    @click.native.stop="openExpand(index, loss)"
                                  >
                                    <span class="orange--text"
                                      >Expand</span
                                    ></v-btn
                                  ></v-col
                                ></v-row
                              ></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                              <v-row>
                                <v-col class="text-left">
                                  <v-btn
                                    class="
                                      elevation-0
                                      text-capitalize text-center
                                    "
                                    cols="12"
                                    color="white"
                                    @click="
                                      handleDownloadTemplate(loss.paramCd)
                                    "
                                  >
                                    <v-icon aria-hidden="false" color="orange">
                                      mdi-download </v-icon
                                    ><span class="orange--text ml-2"
                                      >Download Template</span
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="
                                      elevation-0
                                      text-capitalize text-center
                                    "
                                    cols="12"
                                    color="white"
                                    @click="handleAddDetailLoss(index, loss)"
                                  >
                                    <v-icon aria-hidden="false" color="orange">
                                      mdi-plus-box </v-icon
                                    ><span class="orange--text ml-2"
                                      >Tambah</span
                                    >
                                  </v-btn>
                                  <v-btn
                                    class="
                                      elevation-0
                                      text-capitalize text-center
                                    "
                                    cols="12"
                                    color="white"
                                    @click="onButtonClickTemplate(index)"
                                  >
                                    <v-icon aria-hidden="false" color="orange">
                                      mdi-upload </v-icon
                                    ><span class="orange--text ml-2"
                                      >Upload</span
                                    >
                                  </v-btn>
                                  <input
                                    :ref="`uploaderTemplate` + index"
                                    class="d-none"
                                    type="file"
                                    @change="
                                      onFileChangedTemplate(
                                        $event,
                                        loss.paramValue,
                                        loss.paramCd,
                                        index
                                      )
                                    "
                                  />
                                </v-col>
                              </v-row>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_FIKTIF'"
                                :headers="headersPembayaranFiktif1"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.appIdNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="pencairanDateMenu[data.index]"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.pencairanDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="pencairanDateVal[data.index]"
                                          no-title
                                          scrollable
                                          @input="
                                            pencairanDatePicker(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.plafond"
                                        prefix="Rp"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingPokok"
                                        prefix="Rp"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingMargin"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsPctg"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.tabunganBalance"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="
                                  loss.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
                                "
                                :headers="headersPembayaranFiktif1"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.appIdNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="pencairanDateMenu3[data.index]"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.pencairanDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            pencairanDateVal3[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            pencairanDatePicker3(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.plafond"
                                        prefix="Rp"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingPokok"
                                        prefix="Rp"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingMargin"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsPctg"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.tabunganBalance"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog2[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog2,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog2,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="
                                  loss.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'
                                "
                                :headers="headersPembayaranFiktif1Sebagian"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.appIdNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="pencairanDateMenu2[data.index]"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.pencairanDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            pencairanDateVal2[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            pencairanDatePicker2(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.plafond"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), pembiayaanPercentage(data.item), karyawan(data.item), karyawanPercentage(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.pembiayaanNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), pembiayaanPercentage(data.item), karyawan(data.item), karyawanPercentage(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="
                                          data.item.pembiayaanNasabahPercentage
                                        "
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsPctg"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.pembiayaanKaryawan"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="
                                          data.item.pembiayaanKaryawanPercentage
                                        "
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsPctg"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingTotal"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), outstandingNasabahNominal(data.item),  outstandingKaryawanNominal(data.item), fiktifEdit2(data.item), potensiKerugian(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingKaryawan"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.tabunganBalance"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), potensiKerugian(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog3[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog3,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog3,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_ANGSURAN'"
                                :headers="headersPembayaranFiktif2Setoran"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.appIdNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.angsuranStatus"
                                        :items="statusAngsuranList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="fraudRepayDateMenu[data.index]"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.fraudRepayDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            fraudRepayDateVal[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            fraudRepayDatePicker(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog4[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog4,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog4,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_PELUNASAN'"
                                :headers="headersPembayaranFiktif2Pelunasan"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.appIdNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.outstandingPelunasan"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), potensiKerugianPelunasan(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.tabunganBalance"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), potensiKerugianPelunasan(data.item), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="
                                          fraudRepayDateMenu2[data.index]
                                        "
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.fraudRepayDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            fraudRepayDateVal2[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            fraudRepayDatePicker2(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog5[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog5,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog5,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_TABUNGAN'"
                                :headers="headersPembayaranFiktif2Tabungan"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.nasabahName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.tabunganNo"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum4">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.transaksiType"
                                        :items="transaksiTypeList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.prsCollection"
                                        :items="prsCollectionList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="
                                          fraudRepayDateMenu3[data.index]
                                        "
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.fraudRepayDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            fraudRepayDateVal3[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            fraudRepayDatePicker3(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog6[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog6,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog6,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_BRW'"
                                :headers="headersPembayaranFiktif3Brw"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.mthdDetailDesc"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.brwCategory"
                                        :items="brwCategoryList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog7[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog7,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog7,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_KAS_SOL'"
                                :headers="headersPembayaranFiktif3Kas"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.sentraName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.kasSolCategory"
                                        :items="kasSolCategoryList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="
                                          fraudRepayDateMenu4[data.index]
                                        "
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="data.item.fraudRepayDate"
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            fraudRepayDateVal4[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            fraudRepayDatePicker4(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog8[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog8,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog8,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                                :headers="headersPembayaranFiktif3Lemari"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.mthdDetailDesc"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog9[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog9,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog9,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_FPB'"
                                :headers="headersPembayaranFiktif3Fpb"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.itemName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td>
                                      <v-menu
                                        v-model="
                                          fpbTransactionDateMenu[data.index]
                                        "
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="
                                              data.item.fpbTransactionDate
                                            "
                                            label=""
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            hide-details="auto"
                                            class="mt-3"
                                            placeholder=""
                                            :disabled="
                                              data.item.version == 'Original'
                                            "
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="
                                            fpbTransactionDateVal[data.index]
                                          "
                                          no-title
                                          scrollable
                                          @input="
                                            fpbTransactionDatePicker(data),
                                              diedit(data.item, data.index)
                                          "
                                        >
                                        </v-date-picker>
                                      </v-menu>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog10[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog10,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog10,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-if="loss.paramCd == 'LOSS_TYPE_PENCURIAN'"
                                :headers="headersPembayaranFiktif4"
                                :items="pembiayaanFiktifList[index]"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                              >
                                <template v-slot:item="data">
                                  <tr class="text-center">
                                    <td class="fixed_table_colum">
                                      {{ data.index + 1 }}
                                    </td>
                                    <td class="fixed_table_colum2">
                                      <v-text-field
                                        class="mt-3"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        persistent-placeholder
                                        v-model="data.item.itemName"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                        @keypress="noChar"
                      @paste="pasteChar"
                                      >
                                      </v-text-field>
                                    </td>
                                    <td class="fixed_table_colum3">
                                      <v-autocomplete
                                        class="mt-3"
                                        dense
                                        outlined
                                        persistent-placeholder
                                        hide-details="auto"
                                        v-model="data.item.pelakuPencurian"
                                        :items="pelakuPencurianList"
                                        item-text="paramName"
                                        item-value="paramCd"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      >
                                      </v-autocomplete>
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.potentialLoss"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryNasabah"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.recoveryBranch"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index), fiktifEdit2(data.item)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <vuetify-money
                                        v-model="data.item.netFraud"
                                        class="mb-0 mt-3"
                                        hide-details
                                        label=""
                                        outlined
                                        dense
                                        readonly
                                        v-bind:options="optionsMoney"
                                        @input="diedit(data.item, data.index)"
                                        :disabled="data.item.version == 'Original'"
                                      />
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openPelakuDialog(
                                            data.item.detailLossId,
                                            data.item.detailLossTempId,
                                            data.item.netFraud,
                                            data.index,
                                            data.item.version
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Pelaku</v-btn
                                      >
                                    </td>
                                    <td>
                                      <v-dialog
                                        v-model="dialog11[data.index]"
                                        :key="data.index"
                                        persistent
                                        width="600"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            small
                                            text
                                            class="primary--text"
                                            v-bind="attrs"
                                            v-on="on"
                                            >Keterangan
                                          </v-btn>
                                        </template>
                                        <div class="text-center">
                                          <v-card>
                                            <v-card-title
                                              >Keterangan</v-card-title
                                            >
                                            <v-card-text>
                                              <v-textarea
                                                class="mb-0"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                placeholder=""
                                                v-model="data.item.notes"
                                                :disabled="
                                                  data.item.version == 'Original'
                                                "
                                                @input="
                                                  diedit(data.item, data.index)
                                                "
                                                @keypress="noChar"
                      @paste="pasteChar"
                                              ></v-textarea>
                                            </v-card-text>
                                            <v-card-actions>
                                              <v-col class="text-right">
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                    primary--text
                                                  "
                                                  depressed
                                                  outlined
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog11,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="orange--text"
                                                    >Cancel</span
                                                  ></v-btn
                                                >
                                                <v-btn
                                                  class="
                                                    elevation-0
                                                    text-capitalize
                                                    mr-3
                                                  "
                                                  depressed
                                                  color="primary"
                                                  @click="
                                                    $set(
                                                      dialog11,
                                                      data.index,
                                                      false
                                                    )
                                                  "
                                                >
                                                  <span class="white--text"
                                                    >Save</span
                                                  ></v-btn
                                                >
                                              </v-col>
                                            </v-card-actions>
                                          </v-card>
                                        </div>
                                      </v-dialog>
                                    </td>
                                    <td>
                                      <v-btn
                                        text
                                        class="primary--text"
                                        @click="
                                          openDocumentDialog(
                                            data.item,
                                            data.index
                                          ),
                                            diedit(data.item, data.index)
                                        "
                                        >Document Image</v-btn
                                      >
                                    </td>
                                    <td style="text-align: center">
                                      <v-icon
                                        small
                                        color="red"
                                        @click="deleteRow(data.item, index)"
                                        v-if="data.item.version != 'Original'"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="elevation-0 text-capitalize mr-3 primary--text"
                        depressed
                        outlined
                        color="primary"
                        :to="`/tambah-modus`"
                        x-large
                      >
                        <span class="orange--text">Back</span></v-btn
                      >
                      <!-- <v-btn
                        class="elevation-0 text-capitalize mr-3 primary--text"
                        depressed
                        outlined
                        color="primary"
                        @click="handleSaveAsDraft"
                        x-large
                      >
                        <span class="orange--text">Save as Draft</span></v-btn
                      > -->
                      <v-btn
                        class="elevation-0 text-capitalize mr-3"
                        depressed
                        color="primary"
                        @click="handleSubmitInvestigasi"
                        x-large
                      >
                        <span class="white--text">Submit</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-col>
      <v-card rounded="lg">
        <v-card-text>
          <v-row class="mx-1">
            <v-col>
              <h7 class="label-text-field"> History </h7>
              <v-data-table
                :headers="headersHistory"
                :items="fraudLogList"
                :header-props="{ sortIcon: null }"
                disable-sort
              >
                <template v-slot:[`item.processDate`]="{ item }">
                  {{ item.processDate }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      statusBaris: "",
      investigationStatusName: "",
      investigationDelegatorPosition: "",
      indexForSave: 0,
      detailLossToSaveAuto: [],
      detailLossToDeleteAuto: [],
      rootCauseApi: [],
      reportDateFormat: null,
      fraudCommitedDateFormat: null,
      totalFraud: 0,
      notesNoRules: [],
      notesRules: [(v) => !!v || "Notes Tidak Boleh Kosong"],
      namaInvestigator: "",
      nikInvestigator: "",
      jabatanInvestigator: "",
      emailInvestigator: "",

      picInvestigatorPhone: "",
      isFromSave: false,
      reportType: "",
      attachment: [],
      attachmentAfm: [],
      rootCauseRules: [(v) => v.length > 0 || "Keterangan harus diisi"],
      noRootCauseRules: [],
      mandatoryList: [],
      rootCauseStatus: [],
      rootCouse: [],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      fraudIdCopy: "",
      panelIndex: null,
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp",
        suffix: "",
        length: 12,
        precision: 0,
      },
      optionsPctg: {
        locale: "id-ID",
        prefix: "",
        suffix: "%",
        length: 12,
        precision: 0,
      },
      fraudAttList: [],
      fraudCommitedDateMenu: false,
      fraudCommitedDateVal: null,
      fraudKnownDateMenu: false,
      fraudKnownDateVal: null,
      recoveryDateMenu: false,
      recoveryCommitDate: null,
      recoveryDateVal: null,
      dialog: {},
      dialog2: {},
      dialog3: {},
      dialog4: {},
      dialog5: {},
      dialog6: {},
      dialog7: {},
      dialog8: {},
      dialog9: {},
      dialog10: {},
      dialog11: {},
      dialogEx: {},
      dialog2Ex: {},
      dialog3Ex: {},
      dialog4Ex: {},
      dialog5Ex: {},
      dialog6Ex: {},
      dialog7Ex: {},
      dialog8Ex: {},
      dialog9Ex: {},
      dialog10Ex: {},
      dialog11Ex: {},
      pelakuFraudDialog: false,
      documentDialog: false,
      picInvestigator: "",
      picInvestigatorPosition: "",
      picInvestigatorEmail: "",
      picInvestagorPhone: "",
      fraudLogList: [],
      jagaNo: "",
      reportDate: "",
      fraudTypeName: "",
      fraudInf: "",
      fraudInfReason: "",
      fraudPelakuList: [],
      fraudPelakuListPelakuFraud: [],
      fraudLocName: "",
      initialLoss: "",
      sharepointLink: "",
      verifStatusName: "",
      reportDocTypeList: [],
      spPelaku: "",
      spNasabah: "",
      investigationDelegatorName: "",
      investigationDelegatorPhone: "",
      investigationDelegatorPositionName: "",
      extFraudMthdDesc: "",
      returnDate: "",
      fraudCommitedDate: null,
      fraudControl: "",
      fraudDetail: "",
      fraudFactor: "",
      fraudFactorDesc: "",
      fraudKnownDate: null,
      fraudPurpose: "",
      fraudReason: "",
      investigationStatus: "",
      violationType: "",
      violationTypeDesc: "",
      note: "",
      expandPembiayaanFiktif: false,
      pembiayaanFiktifList: [],
      headersModus: [
        {
          text: "No",
          align: "center",
          width: "30px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "250px",
        },
        {
          text: "Kronologi Kejadian",
          align: "center",
          width: "400px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "150px",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        // },
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "200px",
        },
      ],
      headersYangTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "assignmentCategory",
          width: "150px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
        },
      ],
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Janis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersHistory: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Date",
          align: "center",
          value: "processDate",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Processed By",
          align: "center",
          value: "processBy",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "250px",
          value: "processComment",
          class: "tableHeader ",
        },
      ],
      dataInvestigasiById: null,
      jenisPelanggaranList: [],
      modusOperandiList: [],
      kodeModusList: [],
      kronologiList: [],
      deleteIndex: null,
      defaultListModus: {
        fraudId: "",
        fraudMthd: "",
        fraundMthdName: "",
        incident: "",
        incidentDesc: "",
        incidentName: "",
        seqNo: 0,
      },
      defaultDetailLossList: {
        angsuranStatus: "", //NEW
        appIdNo: "",
        brwCategory: "", //NEW
        createBy: "",
        createDate: "",
        detailLossDistList: [],
        detailLossDocList: [],
        detailLossId: 0,
        detailLossTempId: 0,
        fpbTransactionDate: "", //NEW DATE
        fraudId: 0,
        fraudRepayDate: "", //NEW DATE
        itemName: "",
        kasSolCategory: "", //NEW
        lossType: "",
        lossTypeName: "", //NOT ANYMORE
        mthdDetailDesc: "",
        nasabahName: "",
        netFraud: null,
        notes: "",
        outstandingKaryawan: null, //NEW
        outstandingMargin: null,
        outstandingNasabah: null, //NEW
        outstandingPelunasan: null, //NEW
        outstandingPokok: null,
        outstandingTotal: null, //NEW
        ownershipStatus: "",
        ownershipStatusName: "", //NOT ANYMORE
        pelakuPencurian: "", //NEW
        pembiayaanKaryawan: null, //NEW
        pembiayaanKaryawanPercentage: null, //NEW
        pembiayaanNasabah: null, //NEW
        pembiayaanNasabahPercentage: null, //NEW
        pencairanDate: "",
        plafond: null,
        potentialLoss: null,
        prsCollection: "", //NEW
        recordFlag: "N",
        recoveryBranch: null,
        recoveryNasabah: null,
        sentraName: "",
        tabunganBalance: null,
        tabunganNo: "",
        transaksiType: "", //NEW
        updateBy: "",
        updateDate: "",
        version: "",
      },
      lossList: [],
      indexExpand: 0,
      indexTable: 0,
      pelakuFraudList: [],
      isiPelakuList: [],
      catatan: "",
      keteranganDialog: false,
      isiDocumentList: [],
      sumberInformasiList: [],
      tujuanFraudList: [],
      lossChosen: {
        paramValue: 5,
      },
      statusAngsuranList: [],
      transaksiTypeList: [],
      prsCollectionList: [],
      brwCategoryList: [],
      kasSolCategoryList: [],
      pelakuPencurianList: [],
      pencairanDateMenu: [],
      pencairanDateVal: [],
      pencairanDateMenu2: [],
      pencairanDateVal2: [],
      pencairanDateMenu3: [],
      pencairanDateVal3: [],
      fraudRepayDateMenu: [],
      fraudRepayDateVal: [],
      fraudRepayDateMenu2: [],
      fraudRepayDateVal2: [],
      fraudRepayDateMenu3: [],
      fraudRepayDateVal3: [],
      fraudRepayDateMenu4: [],
      fraudRepayDateVal4: [],
      fpbTransactionDateMenu: [],
      fpbTransactionDateVal: [],

      pencairanDateMenuExpand: [],
      pencairanDateMenu2Expand: [],
      pencairanDateMenu3Expand: [],
      fraudRepayDateMenuExpand: [],
      fraudRepayDateMenu2Expand: [],
      fraudRepayDateMenu3Expand: [],
      fraudRepayDateMenu4Expand: [],
      fpbTransactionDateMenuExpand: [],
    };
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    momentDateHistory: function (date) {
      return moment(date).format("DD-MMM-YYYY HH:mm:ss");
    },
  },
  async created() {
    this.requiredFeature('TAMBAH_MODUS_KERUGIAN')
    // this.changeTitleApp("Investigasi Fraud");
    this.fraudIdCopy = this.fraudId;
    await this.loadData();
    this.handleGetJenisPelanggaran();
    this.handleGetKodeModus();
    this.handleGetLossList();
    this.handleGetSumberInformasi();
    this.handleGetTujuanFraud();
    this.hanldeGetStatusAngsuran();
    this.hanldeGetTransaksiType();
    this.hanldeGetPrsCollection();
    this.hanldeGetBrwCategory();
    this.hanldeGetKasSolCategory();
    this.hanldeGetPelakuPencurian();
    // this.loadStatusRoot();
    // this.loadKaryawan();
  },
  beforeDestroy() {
    // this.handleSaveAuto();
    if (!this.isFromSave) {
      this.handleSaveAuto(this.panelIndex); //Jika tidak dari Save atau Submit
    }
  },
  // watch: {
  //   panelIndex: function () {
  //     console.log("watch panelIndex id: " + this.panelIndex);
  //     //if this panelIndex matches this component's index.. do stuff since we're selected
  //   },
  // },
  mounted() {},
  methods: {
    ...mapActions([
      "changeTitleApp",

      "getDataTambahModusByFraudId",

      "saveInvestigasi",

      "submitTambahModus",

      "getParameterByParamType",

      "uploadTambahanModus",

      "saveTambahanModusAuto",

      "searchDataTambahanModusKerugianByFraudId",

      "downloadTemplateTambahanModus",

      "saveUploadTambahModusKerugian",

      "getDataKerugianDocumentByFraudId",
      "getRincianDafKerugianPelakuByFraudId",
      "downloadDataRekapKerugian",
      "downloadDataRincianKerugian",
      "downloadAttachment",
      "getEmployeeByNik",
    ]),

    fiktifEdit(item) {
      item.potentialLoss = Number(item.outstandingPokok) - Number(item.tabunganBalance)
    },
    fiktifEdit2(item) {
      item.netFraud = Number(item.potentialLoss) - (Number(item.recoveryNasabah) + Number(item.recoveryBranch))
    },
    pembiayaanPercentage(item) {
      item.pembiayaanNasabahPercentage = (Number(item.pembiayaanNasabah) / Number(item.plafond)) * 100
    },
    karyawan(item) {
      item.pembiayaanKaryawan = Number(item.plafond) - Number(item.pembiayaanNasabah)
    },
    karyawanPercentage(item) {
      item.pembiayaanKaryawanPercentage = (Number(item.pembiayaanKaryawan) / Number(item.plafond)) * 100
    },
    outstandingNasabahNominal(item) {
      item.outstandingNasabah = Math.floor((Number(item.pembiayaanNasabahPercentage) / 100) * item.outstandingTotal)
    },
    outstandingKaryawanNominal(item) {
      item.outstandingKaryawan = Math.floor((Number(item.pembiayaanKaryawanPercentage) / 100) * item.outstandingTotal)
    },
    potensiKerugian(item){
      item.potentialLoss = Number(item.outstandingKaryawan) - Number(item.tabunganBalance)
    },
    potensiKerugianPelunasan(item) {
      item.potentialLoss = Number(item.outstandingPelunasan) - Number(item.tabunganBalance)
    },

    dieditPelaku() {
      this.detailLossToSaveAuto[this.indexForSave].detailLossDistList =
        this.isiPelakuList;
    
      this.handleSaveAuto(this.indexForSave);
    },

    dieditDocument() {
      this.detailLossToSaveAuto[this.indexForSave].detailLossDocList =
        this.isiDocumentList;

      this.handleSaveAuto(this.indexForSave);
    },

    diedit(apa, index) {
      if (this.detailLossToSaveAuto.length == 0) {
        for (let indexa = 0; indexa <= index; indexa++) {
          this.detailLossToSaveAuto.push("");
        }
      }
      this.detailLossToSaveAuto.splice(index, 1, apa);
    },

    clear() {
      this.isiPelakuList.map((item) => {
        item.netFraudDist = "";
      });
    },

    calculate() {
      this.totalFraud = 0;
      this.isiPelakuList.map((item) => {
        this.totalFraud = Number(this.totalFraud) + Number(item.netFraudDist);
      });
    },

    loadKaryawan() {
      this.getEmployeeByNik({
        nik: this.username,
      })
        .then((response) => {
          let respData = response.data.data;

          this.namaInvestigator = respData.name;
          this.nikInvestigator = respData.nik;
          this.jabatanInvestigator = respData.position;
          this.emailInvestigator = respData.email;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleDownloadAttch(item) {
      this.downloadAttachment({
        base64Data: null,
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
    },

    loadStatusRoot() {
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
        .then((resp) => {
          this.mandatoryList = [];
          let rootCauseStatusa = resp.data.data;
          for (let index = 0; index < rootCauseStatusa.length; index++) {
            this.rootCouse.push({
              createBy: "",
              createDate: "",
              fraudId: this.fraudId,
              lossDocId: 0,
              recordFlag: "",
              rootCause: rootCauseStatusa[index].paramCd,
              rootCauseDesc: "",
              rootCauseList: [],
              rootCauseName: rootCauseStatusa[index].paramName,
              seqNo: 0,
              updateBy: "",
              updateDate: "",
            });

            this.mandatoryList.push(rootCauseStatusa[index].paramValue);

            this.rootCauseStatus.push([]);

            this.getParameterByParamType({
              pageNumber: 0,
              pageSize: 100,
              paramCd: "",
              paramName: "",
              paramType: rootCauseStatusa[index].paramCd,
              paramValue: "",
              searchValue: "",
            }).then((response) => {
              response.data.data.map((item) => {
                item.rootCause = item.paramType;
                delete item.paramType;
                item.rootCauseDesc = item.paramCd;
                delete item.paramCd;
                item.rootCauseName = item.paramName;
                delete item.paramName;
                item.rootCauseDescName = "";
                delete item.paramTypeName;
                delete item.paramValue;

                // item.lossDocId = null; //dihapus pas load
                // item.fraudId = this.fraudId; //dihapus pas load
                //         "rootCauseId": 272, // dihapus aja pas load
                //         "seqNo": 1, //dihapus pas load

                //         "rootCause": "ROOT_CAUSE_1", //munculkan
                //         "rootCauseName": null, //munculkan
                //         "rootCauseDesc": "ROOT_CAUSE_PRESSURE_1", //munculkan
                //         "rootCauseDescName": null, //munculkan

                //         "rootCauseList": [] //hapus pas load
              });
              this.rootCauseStatus.splice(index, 1, response.data.data);
            });
          }

          if (this.rootCauseApi.length != 0) {
            this.rootCauseApi.map((item) => {
              let indexs = this.rootCouse.findIndex(
                (x) => x.rootCause === item.rootCause
              );
              this.rootCouse.splice(indexs, 1, item);
              // console.log('apa isisisis', this.rootCouse[indexs])
              // .push(temp[index]);
            });
            console.log("rootCause awal", this.rootCouse);
          }

          console.log("isi rootCause", this.rootCauseStatus);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleDownloadDataRincian() {
      this.downloadDataRincianKerugian(this.fraudId)
        .then((response) => {
          console.log("response oke", response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
    },
    handleDownloadDataRekap() {
      this.downloadDataRekapKerugian(this.fraudId)
        .then((response) => {
          console.log("response oke", response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
    },
    handleDownloadTemplate(tipe) {
      this.downloadTemplateTambahanModus(tipe)
        .then((response) => {
          console.log("response oke", response);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
          this.$helpers.handleError(err);
        });
    },
    onButtonClickTemplate(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // const button = this.$refs['uploaderTemplate'].find( el => el.$attrs['key'] === index);

      // button.click();
      this.$refs["uploaderTemplate" + index][0].click();
    },
    onButtonClickTemplateExpand(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // const button = this.$refs['uploaderTemplate'].find( el => el.$attrs['key'] === index);

      // button.click();
      this.$refs["uploaderTemplate" + index].click();
    },
    onFileChangedTemplate(e, tipe, lossType, index) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      console.log('tipe', tipe)

      // this.handleSaveAuto(index);

      var hasil = this.detailLossToSaveAuto.filter((item) => {
        return item != "";
      });
      console.log(index);
      // let detailLossList = this.pembiayaanFiktifList[index];
      let detailLossList = hasil.concat(this.detailLossToDeleteAuto);
      detailLossList.map((item) => {
        item.detailLossDistTempList = item.detailLossDistList;
        item.detailLossDocTempList = item.detailLossDocList;
        delete item.detailLossDistList;
        delete item.detailLossDocList;
      });
      console.log("isi detail Loss list", detailLossList);
      // let detailLossList = [].concat(...this.pembiayaanFiktifList);
      this.saveTambahanModusAuto({
        detailLossList: detailLossList,
        fraudId: this.fraudIdCopy,
      })
        .then(() => {
          this.detailLossToSaveAuto = [];
          this.detailLossToDeleteAuto = [];
        })
        .catch((err) => {
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/octet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.detailLossToSaveAuto = [];
          this.detailLossToDeleteAuto = [];
          this.$helpers.handleError(err);
        });

      this.saveUploadTambahModusKerugian({
        formData: formData,
        fraudId: this.fraudId,
        // typeTemplate: tipe,
        lossType: lossType,
      })
        .then((response) => {
          console.log("upload oke", response);
          // this.loadData();
          this.handleGetLossList();
          this.panelIndex = index;
        })
        .catch((err) => {
          console.log("gagal upload", err.response);
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/octet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.$helpers.handleError(err);
        });
    },
    handleSaveAuto(index) {
      var hasil = this.detailLossToSaveAuto.filter((item) => {
        return item != "";
      });
      console.log(index);
      // let detailLossList = this.pembiayaanFiktifList[index];
      let detailLossList = hasil.concat(this.detailLossToDeleteAuto);
      detailLossList.map((item) => {
        item.detailLossDistTempList = item.detailLossDistList;
        item.detailLossDocTempList = item.detailLossDocList;
        delete item.detailLossDistList;
        delete item.detailLossDocList;
      });
      console.log("isi detail Loss list", detailLossList);
      // let detailLossList = [].concat(...this.pembiayaanFiktifList);
      this.saveTambahanModusAuto({
        detailLossList: detailLossList,
        fraudId: this.fraudIdCopy,
      })
        .then((response) => {
          console.log("response auto", response);
          this.handleGetLossList();
          this.detailLossToSaveAuto = [];
          this.detailLossToDeleteAuto = [];
        })
        .catch((err) => {
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/octet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.detailLossToSaveAuto = [];
          this.detailLossToDeleteAuto = [];
          this.$helpers.handleError(err);
        });
    },
    onButtonClick(index, document) {
      console.log("file yang dipilih", document);
      if (this.statusBaris != "Original") {
        this.isSelecting = true;
        window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
        );

        // this.$refs.uploader[index].click();
        this.$refs["uploader" + index][0].click();
      } else {
        if (document.attachFileName != null) this.handleDownloadAttch(document);
      }
    },
    onFileChanged(e, index) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);

      this.uploadTambahanModus(formData)
        .then((response) => {
          console.log("response", response);
          this.isiDocumentList[index].attachFileName =
            response.data.data.fileName;
          this.isiDocumentList[index].attachFilePath =
            response.data.data.filePathName;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    uploadFile(event, index) {
      console.log("event", event);
      console.log("index", index);

      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      this.uploadTambahanModus(formData)
        .then((response) => {
          console.log("response", response);
          this.fraudAttList[index].attachFileName = response.data.data.fileName;
          this.fraudAttList[index].attachFilePath =
            response.data.data.filePathName;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    addFraudAttachment() {
      this.fraudAttList.push({
        attachFileName: "",
        attachFilePath: "",
        flowName: "Investigasi Fraud",
        fraudId: this.fraudId,
        seqNo: 0,
      });
    },
    onExpansionPanelClick(index) {
      console.log("apa isi panelIndex", this.panelIndex);
      console.log("index", index);
      // if (
      //   event.currentTarget.classList.contains(
      //     "v-expansion-panel-header--active"
      //   )
      // ) {
      //   console.log("Panel is closing/now closed.");
      //   this.handleSaveAuto(index);
      // } else {
      //   console.log("Panel is opening/now open.");
      // }
      if (this.panelIndex != null) {
        this.handleSaveAuto(this.panelIndex);
      }
    },
    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },
    fraudCommitedDatePicker() {
      this.fraudCommitedDateMenu = false;
      this.fraudCommitedDateFormat = moment(this.fraudCommitedDateVal).format(
        "yyyy-MM-DD"
      );
      this.fraudCommitedDate = moment(this.fraudCommitedDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    pencairanDatePicker(data) {
      this.pencairanDateMenu[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker2(data) {
      this.pencairanDateMenu2[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker3(data) {
      this.pencairanDateMenu3[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker(data) {
      this.fraudRepayDateMenu[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker2(data) {
      this.fraudRepayDateMenu2[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker3(data) {
      this.fraudRepayDateMenu3[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker4(data) {
      this.fraudRepayDateMenu4[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal4[data.index]
      ).format("yyyy-MM-DD");
    },
    fpbTransactionDatePicker(data) {
      this.fpbTransactionDateMenu[data.index] = false;
      data.item.fpbTransactionDate = moment(
        this.fpbTransactionDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePickerExpand(data) {
      this.pencairanDateMenuExpand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker2Expand(data) {
      this.pencairanDateMenu2Expand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker3Expand(data) {
      this.pencairanDateMenu3Expand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePickerExpand(data) {
      this.fraudRepayDateMenuExpand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker2Expand(data) {
      this.fraudRepayDateMenu2Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker3Expand(data) {
      this.fraudRepayDateMenu3Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker4Expand(data) {
      this.fraudRepayDateMenu4Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal4[data.index]
      ).format("yyyy-MM-DD");
    },
    fpbTransactionDatePickerExpand(data) {
      this.fpbTransactionDateMenuExpand[data.index] = false;
      data.item.fpbTransactionDate = moment(
        this.fpbTransactionDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudKnownDatePicker() {
      this.fraudKnownDateMenu = false;
      this.fraudKnownDate = moment(this.fraudKnownDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    recoveryDatePicker() {
      this.recoveryDateMenu = false;
      this.recoveryCommitDate = moment(this.recoveryDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    openPelakuDialog(lossId, tempId, fraud, index, record) {
      this.getRincianDafKerugianPelakuByFraudId({
        detailLossId: lossId,
        detailLossTempId: tempId,
        fraudId: this.fraudId,
      }).then((responsePelaku) => {
        let cloneArray = responsePelaku.data.data;
        this.isiPelakuList = cloneArray;
        this.netFraudText = fraud;
        this.pelakuFraudDialog = true;
        this.indexForSave = index;

        this.statusBaris = record;
      });
    },
    openDocumentDialog(document, index) {
      this.getDataKerugianDocumentByFraudId({
        attachFileName: "",
        attachFilePath: "",
        createBy: "",
        createDate: "",
        detailLossId: document.detailLossId,
        detailLossTempId: document.detailLossTempId,
        documentType: document.lossType,
        lossDocId: 0,
        lossDocTempId: 0,
        notes: "",
        recordFlag: "",
        updateBy: "",
        updateDate: "",
      }).then((response) => {
        let respData = response.data.data;
        this.isiDocumentList = respData;
        this.documentDialog = true;
        this.indexForSave = index;

        this.statusBaris = document.version;
      });
    },
    openKeteranganDialog(catatan) {
      console.log("apa isi catatan", catatan);
      this.catatan = catatan;
      this.keteranganDialog = true;
    },
    deleteRowOperandi(data) {
      this.deleteIndex = this.modusOperandiList.indexOf(data);
      if (this.deleteIndex !== -1) {
        this.modusOperandiList.splice(this.deleteIndex, 1);
        this.kronologiList.splice(this.deleteIndex, 1);
      }
    },
    deleteRow(data, index) {
      console.log("apa isi", data);
      this.deleteIndex = this.pembiayaanFiktifList[index].indexOf(data);
      if (this.deleteIndex !== -1) {
        this.pembiayaanFiktifList[index].splice(this.deleteIndex, 1);

        data.recordFlag = "D";
        this.detailLossToDeleteAuto.push(data);
      }
    },
    handleAdd() {
      const addObj = Object.assign(
        {},
        {
          fraudId: this.fraudId,
          fraudMthd: "",
          fraundMthdName: "",
          incident: "",
          incidentDesc: "",
          incidentName: "",
          seqNo: 0,
        }
      );
      this.modusOperandiList.push(addObj);
      this.kronologiList.push([]);
    },
    handleAddDetailLoss(index, loss) {
      // this.getDataKerugianDocumentByFraudId({
      //   attachFileName: "",
      //   attachFilePath: "",
      //   createBy: "",
      //   createDate: "",
      //   detailLossId: 0,
      //   detailLossTempId: 0,
      //   documentType: loss.paramCd,
      //   lossDocId: 0,
      //   lossDocTempId: 0,
      //   notes: "",
      //   recordFlag: "",
      //   updateBy: "",
      //   updateDate: "",
      // })
      //   .then((response) => {
      //     let respData = response.data.data;
      //     respData.map((item) => {
      //       item.recordFlag = "N";
      //     });

      // this.getRincianDafKerugianPelakuByFraudId({
      //   fraudId: this.fraudId,
      //   fraudLoc: "",
      //   fraudType: "",
      //   jagaNo: "",
      //   pageNumber: 0,
      //   pageSize: 0,
      //   refNo: "",
      //   reportDate: "",
      //   reportDateFrom: "",
      //   reportDateTo: "",
      //   reportStatus: "",
      // })
      // .then((responsePelaku) => {
      //   let cloneArray = responsePelaku.data.data;

      //   //   var cloneArray = JSON.parse(JSON.stringify(this.fraudPelakuList));
      //   // cloneArray.map((item) => {
      //   //   item.netFraudDist = "";
      //   //   item.fraudPelakuId = item.pelakuId;
      //   //   item.detailLossId = 0;
      //   //   item.lossDistId = 0;
      //   //   item.lossDistTempId = 0;
      //   // });

      //   // this.defaultDetailLossList.detailLossDistList = cloneArray;
      //   // this.defaultDetailLossList.detailLossDocList = respData;
      // })
      // .catch((err) => {
      //   this.$helpers.handleError(err);
      // });
      // })
      // .catch((err) => {
      //   this.$helpers.handleError(err);
      // });
      this.defaultDetailLossList.lossType = loss.paramCd;
      this.defaultDetailLossList.lossTypeName = loss.paramName;
      this.defaultDetailLossList.fraudId = this.fraudId;
      const addObj = Object.assign({}, this.defaultDetailLossList);
      this.pembiayaanFiktifList[index].push(addObj);
      this.pelakuFraudList[index].push([]);
      console.log("PEMBIAYAAN", this.pembiayaanFiktifList[index]);

      // this.getParameterByParamType({
      //   pageNumber: 0,
      //   pageSize: 100,
      //   paramCd: "",
      //   paramName: "",
      //   paramType: loss.paramCd,
      //   paramValue: "",
      //   searchValue: "",
      // }).then((response) => {
      //   let respData = response.data.data;
      //   let docArray = [];
      //   console.log("isi", respData);
      //   for (let index = 0; index < respData.length; index++) {
      //     docArray.push({
      //       attachFileName: null,
      //       attachFilePath: null,
      //       documentType: respData[index].paramCd,
      //       documentTypeName: respData[index].paramName,
      //       fileUpload: null,
      //       lossDocTempId: null,
      //       notes: null,
      //       detailLossId: null,
      //       lossDocId: null,
      //     });
      //   }

      // });
    },
    hanldeGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.transaksiTypeList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetPrsCollection() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PRS_COLLECTION",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.prsCollectionList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetBrwCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "BRW_CATEGORY",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.brwCategoryList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetKasSolCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "KAS_SOL_CATEGORY",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kasSolCategoryList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetPelakuPencurian() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PELAKU_PENCURIAN",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.pelakuPencurianList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetJenisPelanggaran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "VIOLATION_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.jenisPelanggaranList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.statusAngsuranList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetTujuanFraud() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_FACTOR",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.tujuanFraudList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetSumberInformasi() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_INF",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.sumberInformasiList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetLossList() {
      this.pembiayaanFiktifList = [];
      this.pelakuFraudList = [];
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.lossList = response.data.data;
          console.log("isi lossList", this.lossList);
          for (var i = 0; i < this.lossList.length; i++) {
            this.pembiayaanFiktifList.push([]);
            this.pelakuFraudList.push([]);
          }
          this.searchDataTambahanModusKerugianByFraudId({
            fraudId: this.fraudId,
            fraudLoc: "",
            pageNumber: 0,
            pageSize: 100,
            refNo: "",
            reportDate: "",
            reportDateFrom: "",
            reportDateTo: "",
            reportStatus: "",
          })
            .then((resp) => {
              let temp = resp.data.data;
              console.log("tempp", temp);

              for (let index = 0; index < temp.length; index++) {
                // temp[index].recordFlag = "NE";

                //   this.getDataKerugianDocumentByFraudId({
                //     attachFileName: "",
                //     attachFilePath: "",
                //     createBy: "",
                //     createDate: "",
                //     detailLossId: temp[index].detailLossId,
                //     detailLossTempId: 0,
                //     documentType: temp[index].lossType,
                //     lossDocId: 0,
                //     lossDocTempId: 0,
                //     notes: "",
                //     recordFlag: "",
                //     updateBy: "",
                //     updateDate: "",
                //   })
                //     .then((response) => {
                //       let respData = response.data.data;
                //       respData.map((item) => {
                //         item.recordFlag = "N";
                //       });

                //       this.getRincianDafKerugianPelakuByFraudId({
                //         detailLossId: temp[index].detailLossId,
                //         detailLossTempId: 0,
                //         fraudId: this.fraudId,
                //       }).then((responsePelaku) => {
                //         let cloneArray = responsePelaku.data.data;

                //         temp[index].detailLossDistList = cloneArray;
                //         temp[index].detailLossDocList = respData;

                let indexs = this.lossList.findIndex(
                  (x) => x.paramCd === temp[index].lossType
                );
                this.pembiayaanFiktifList[indexs].push(temp[index]);
                //       });
                //     })
                //     .catch((err) => {
                //       this.$helpers.handleError(err);
                //     });
              }

              // temp.map((item) => {
              //   item.recordFlag = "E";
              //   console.log('DI moto', item);
              //   let index = this.lossList.findIndex(
              //     (x) => x.paramCd === item.lossType
              //   );
              //   this.pembiayaanFiktifList[index].push(item);
              // });

              console.log(
                "isi pembiayaanFiktifList",
                this.pembiayaanFiktifList
              );

              // this.lossList.map(item => {
              //   let index = temp.findIndex(x => x.lossType === item.paramCd);

              //   console.log('isi index', index)
              // })
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });

          // this.pembiayaanFiktifList[index].push();
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetKodeModus() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_MTHD",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kodeModusList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetKronologiList(item, index) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: item,
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kronologiList.splice(index, 1, response.data.data);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    loadData() {
      this.getDataTambahModusByFraudId({
        fraudId: this.fraudId,
      })
        .then((response) => {
          console.log("byId", response.data.data);
          this.dataInvestigasiById = response.data.data;

          this.reportType = this.dataInvestigasiById.reportType;

          this.jenisPelanggaranListToSave = this.dataInvestigasiById.jenisPelanggaranList;

          //HISTORY
          this.fraudLogList = this.dataInvestigasiById.fraudLogList;

          //DATA INVESTIGATOR
          this.picInvestigator = this.dataInvestigasiById.picInvestigator;
          this.picInvestigatorPosition =
            this.dataInvestigasiById.picInvestigatorPosition;
          this.picInvestigatorEmail =
            this.dataInvestigasiById.picInvestigatorEmail;
          this.picInvestigatorPhone =
            this.dataInvestigasiById.picInvestigatorPhone;
          this.investigationDelegatorPhone =
            this.dataInvestigasiById.investigationDelegatorPhone;

          this.namaInvestigator = this.dataInvestigasiById.picInvestigatorName;
          this.nikInvestigator = this.dataInvestigasiById.picInvestigator;
          this.jabatanInvestigator = this.dataInvestigasiById.picInvestigatorPosition;
          this.emailInvestigator = this.dataInvestigasiById.picInvestigatorEmail;

          //DETAIL LAPORAN
          this.jagaNo = this.dataInvestigasiById.jagaNo;
          this.reportDate = moment(this.dataInvestigasiById.reportDate).format(
            "DD-MMM-YYYY"
          );
          this.reportDateFormat = moment(
            this.dataInvestigasiById.reportDate
          ).format("YYYY-MM-DD");
          this.fraudTypeName = this.dataInvestigasiById.fraudTypeName;
          this.fraudInf = this.dataInvestigasiById.fraudInf;
          this.fraudInfReason = this.dataInvestigasiById.fraudInfReason;
          this.fraudPelakuList = this.dataInvestigasiById.fraudPelakuList;
          this.fraudLocName = this.dataInvestigasiById.fraudLocName;
          this.initialLoss = this.humanFormat(
            this.dataInvestigasiById.initialLoss
          );
          this.sharepointLink = this.dataInvestigasiById.sharepointLink;
          this.modusOperandiList = this.dataInvestigasiById.fraudMthdList;
          for (var i = 0; i < this.modusOperandiList.length; i++) {
            this.kronologiList.push([]);
            if (this.modusOperandiList[i].fraudMthd != null) {
              this.handleGetKronologiList(
                this.modusOperandiList[i].fraudMthd,
                i
              );
            }
          }
          this.fraudPelakuListPelakuFraud =
            this.dataInvestigasiById.fraudPelakuList;
          this.attachment = this.dataInvestigasiById.fraudAttList;
          this.attachmentAfm = this.dataInvestigasiById.fraudAttAFMList;

          //VERIFIKASI FRAUD
          this.verifStatusName = this.dataInvestigasiById.verifStatusName;
          this.reportDocTypeList = this.dataInvestigasiById.reportDocTypeList;
          this.spPelaku = this.humanFormat(this.dataInvestigasiById.spPelaku);
          this.spNasabah = this.humanFormat(this.dataInvestigasiById.spNasabah);
          this.investigationDelegatorName =
            this.dataInvestigasiById.investigationDelegatorName;
          this.investigationDelegatorPhone =
            this.dataInvestigasiById.investigationDelegatorPhone;
          this.investigationDelegatorPositionName =
            this.dataInvestigasiById.investigationDelegatorPositionName;
          this.investigationDelegatorPosition =
            this.dataInvestigasiById.investigationDelegatorPosition;
          this.extFraudMthdDesc = this.dataInvestigasiById.extFraudMthdDesc;
          this.returnDate = this.dataInvestigasiById.returnDate
            ? moment(this.dataInvestigasiById.returnDate).format("DD/MM/yyyy")
            : null;

          //INVESTIGASI FRAUD
          this.investigationStatus =
            this.dataInvestigasiById.investigationStatus;
          this.investigationStatusName =
            this.dataInvestigasiById.investigationStatusName;
          this.note = this.dataInvestigasiById.note;
          this.fraudFactor = this.dataInvestigasiById.fraudFactor;
          this.fraudFactorDesc = this.dataInvestigasiById.fraudFactorDesc;
          this.fraudReason = this.dataInvestigasiById.fraudReason;
          this.fraudPurpose = this.dataInvestigasiById.fraudPurpose;
          this.fraudControl = this.dataInvestigasiById.fraudControl;
          this.fraudDetail = this.dataInvestigasiById.fraudDetail;
          this.violationType = this.dataInvestigasiById.violationType;
          this.violationTypeDesc = this.dataInvestigasiById.violationTypeDesc;
          this.fraudCommitedDate = this.dataInvestigasiById.fraudCommitedDate
            ? moment(this.dataInvestigasiById.fraudCommitedDate).format(
                "DD-MMM-YYYY"
              )
            : null;
          this.fraudKnownDate = this.dataInvestigasiById.fraudKnownDate
            ? moment(this.dataInvestigasiById.fraudKnownDate).format(
                "DD-MMM-YYYY"
              )
            : null;
          this.recoveryCommitDate = this.dataInvestigasiById.recoveryCommitDate
            ? moment(
                this.dataInvestigasiById.recoveryCommitDate,
                "DD-MMM-YYYY"
              ).format("DD-MMM-YYYY")
            : null;
          this.fraudAttList = this.dataInvestigasiById.fraudAttList;

          this.rootCauseApi = this.dataInvestigasiById.rootCauseList;
          this.loadStatusRoot();

          // this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleSaveAsDraft() {
      let fraudCommited = moment(this.fraudCommitedDate, "DD-MMM-YYYY");
      let fraudKnown = moment(this.fraudKnownDate, "DD-MMM-YYYY");

      if (moment(fraudCommited).isAfter(fraudKnown)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
          snackbarColor: "red",
        });
      } else {
        let array = [];
        this.rootCouse.map((item) => {
          item.rootCauseList.map((items) => {
            array.push(items);
          });
        });
        console.log("di save", array);
        if (this.$refs.investigasiForm.validate()) {
          var hasil = this.detailLossToSaveAuto.filter((item) => {
            return item != "";
          });
          let detailLossList = hasil.concat(this.detailLossToDeleteAuto);
          // let detailLossList = [].concat(...this.pembiayaanFiktifList);
          detailLossList.map((item) => {
            item.angsuranStatus =
              item.angsuranStatus != null
                ? item.angsuranStatus.trim()
                : item.angsuranStatus;
            item.appIdNo =
              item.appIdNo != null ? item.appIdNo.trim() : item.appIdNo;
            item.brwCategory =
              item.brwCategory != null
                ? item.brwCategory.trim()
                : item.brwCategory;
            item.createBy =
              item.createBy != null ? item.createBy.trim() : item.createBy;
            item.itemName =
              item.itemName != null ? item.itemName.trim() : item.itemName;
            item.kasSolCategory =
              item.kasSolCategory != null
                ? item.kasSolCategory.trim()
                : item.kasSolCategory;
            item.lossType =
              item.lossType != null ? item.lossType.trim() : item.lossType;
            item.mthdDetailDesc =
              item.mthdDetailDesc != null
                ? item.mthdDetailDesc.trim()
                : item.mthdDetailDesc;
            item.nasabahName =
              item.nasabahName != null
                ? item.nasabahName.trim()
                : item.nasabahName;
            item.notes = item.notes != null ? item.notes.trim() : item.notes;
            item.ownershipStatus =
              item.ownershipStatus != null
                ? item.ownershipStatus.trim()
                : item.ownershipStatus;
            item.pelakuPencurian =
              item.pelakuPencurian != null
                ? item.pelakuPencurian.trim()
                : item.pelakuPencurian;
            item.prsCollection =
              item.prsCollection != null
                ? item.prsCollection.trim()
                : item.prsCollection;
            item.recordFlag =
              item.recordFlag != null
                ? item.recordFlag.trim()
                : item.recordFlag;
            item.sentraName =
              item.sentraName != null
                ? item.sentraName.trim()
                : item.sentraName;
            item.tabunganNo =
              item.tabunganNo != null
                ? item.tabunganNo.trim()
                : item.tabunganNo;
            item.transaksiType =
              item.transaksiType != null
                ? item.transaksiType.trim()
                : item.transaksiType;
            item.updateBy =
              item.updateBy != null ? item.updateBy.trim() : item.updateBy;
            item.version =
              item.version != null ? item.version.trim() : item.version;
          });
          this.saveInvestigasi({
            detailLossList: detailLossList,
            fraudAttList: this.fraudAttList,
            fraudCommitedDate: this.fraudCommitedDate
              ? moment(this.fraudCommitedDate, "DD-MMM-YYYY").format(
                  "yyyy-MM-DD"
                )
              : "", //OK tanggal fraud dilakukan
            fraudControl:
              this.fraudControl != null
                ? this.fraudControl.trim()
                : this.fraudControl, //OK kontrol yang tidak berjalan
            fraudDetail:
              this.fraudDetail != null
                ? this.fraudDetail.trim()
                : this.fraudDetail, // Detail / Cara Fraud Dilakukan (lokasi kerja terjadinya fraud ??)
            fraudFactor:
              this.fraudFactor != null
                ? this.fraudFactor.trim()
                : this.fraudFactor, //OK Tujuan Fraud
            fraudFactorDesc:
              this.fraudFactorDesc != null
                ? this.fraudFactorDesc.trim()
                : this.fraudFactorDesc, //OK Jika Tujuan Fraud Lainnya
            fraudId: this.fraudId,
            fraudKnownDate: this.fraudKnownDate
              ? moment(this.fraudKnownDate, "DD-MMM-YYYY").format("yyyy-MM-DD")
              : "", //OK tanggal fraud diketahui
            fraudMthdList: this.modusOperandiList,
            fraudPurpose:
              this.fraudPurpose != null
                ? this.fraudPurpose.trim()
                : this.fraudPurpose, //OK Hari Kecurangan digunakan digunakan untuk apa
            fraudReason:
              this.fraudReason != null
                ? this.fraudReason.trim()
                : this.fraudReason, //OK Alasan Pelaku melakukan fraud
            investigationDelegatorPhone:
              this.investigationDelegatorPhone != null
                ? this.investigationDelegatorPhone.trim()
                : this.investigationDelegatorPhone,
            investigationStatus:
              this.investigationStatus != null
                ? this.investigationStatus.trim()
                : this.investigationStatus, //OK Investigation Status / Status Investigasi
            picInvestigatorEmail: "", //??
            picInvestigatorPhone:
              this.picInvestigatorPhone != null
                ? this.picInvestigatorPhone.trim()
                : this.picInvestigatorPhone, //??
            note: this.note != null ? this.note.trim() : this.note, //OK
            recoveryCommitDate: this.recoveryCommitDate
              ? moment(this.recoveryCommitDate, "DD-MMM-YYYY").format(
                  "yyyy-MM-DD"
                )
              : "",
            rootCauseList: array, //??
            violationType: this.violationType, //OK jenis pelanggaran
            violationTypeDesc: this.violationTypeDesc, //OK jika jenis pelanggaran lainnya
          })
            .then((response) => {
              this.isFromSave = true;
              console.log("response", response);
              this.$router.push("/tambah-modus");
              this.$helpers.handleSuccess(response);
            })
            .catch((err) => {
              console.log("response", err.response);
              this.$helpers.handleError(err);
            });
        } else {
          this.$refs.keteranganRootCause.focus();
        }
      }
    },
    handleSubmitInvestigasi() {
      //   let fraudCommited = moment(this.fraudCommitedDate, "DD-MMM-YYYY");
      //   let fraudKnown = moment(this.fraudKnownDate, "DD-MMM-YYYY");

      //   if (moment(fraudCommited).isAfter(fraudKnown)) {
      //     this.$store.commit("setShowSnackbar", {
      //       snackbarMsg:
      //         "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
      //       snackbarColor: "red",
      //     });
      //   } else {
      let arraysv = [];
      this.rootCouse.map((item) => {
        item.rootCauseList.map((items) => {
          arraysv.push(items);
        });
      });
      var hasil = this.detailLossToSaveAuto.filter((item) => {
        return item != "";
      });
      let detailLossList = hasil.concat(this.detailLossToDeleteAuto);
      // let detailLossList = [].concat(...this.pembiayaanFiktifList);
      if (this.$refs.investigasiForm.validate()) {
        detailLossList.map((item) => {
          item.angsuranStatus =
            item.angsuranStatus != null
              ? item.angsuranStatus.trim()
              : item.angsuranStatus;
          item.appIdNo =
            item.appIdNo != null ? item.appIdNo.trim() : item.appIdNo;
          item.brwCategory =
            item.brwCategory != null
              ? item.brwCategory.trim()
              : item.brwCategory;
          item.createBy =
            item.createBy != null ? item.createBy.trim() : item.createBy;
          item.itemName =
            item.itemName != null ? item.itemName.trim() : item.itemName;
          item.kasSolCategory =
            item.kasSolCategory != null
              ? item.kasSolCategory.trim()
              : item.kasSolCategory;
          item.lossType =
            item.lossType != null ? item.lossType.trim() : item.lossType;
          item.mthdDetailDesc =
            item.mthdDetailDesc != null
              ? item.mthdDetailDesc.trim()
              : item.mthdDetailDesc;
          item.nasabahName =
            item.nasabahName != null
              ? item.nasabahName.trim()
              : item.nasabahName;
          item.notes = item.notes != null ? item.notes.trim() : item.notes;
          item.ownershipStatus =
            item.ownershipStatus != null
              ? item.ownershipStatus.trim()
              : item.ownershipStatus;
          item.pelakuPencurian =
            item.pelakuPencurian != null
              ? item.pelakuPencurian.trim()
              : item.pelakuPencurian;
          item.prsCollection =
            item.prsCollection != null
              ? item.prsCollection.trim()
              : item.prsCollection;
          item.recordFlag =
            item.recordFlag != null ? item.recordFlag.trim() : item.recordFlag;
          item.sentraName =
            item.sentraName != null ? item.sentraName.trim() : item.sentraName;
          item.tabunganNo =
            item.tabunganNo != null ? item.tabunganNo.trim() : item.tabunganNo;
          item.transaksiType =
            item.transaksiType != null
              ? item.transaksiType.trim()
              : item.transaksiType;
          item.updateBy =
            item.updateBy != null ? item.updateBy.trim() : item.updateBy;
          item.version =
            item.version != null ? item.version.trim() : item.version;
        });
        this.submitTambahModus({
          detailLossList: detailLossList,
          // fraudAttList: this.fraudAttList,
          // fraudCommitedDate: this.fraudCommitedDate
          //   ? moment(this.fraudCommitedDate, "DD-MMM-YYYY").format(
          //       "yyyy-MM-DD"
          //     )
          //   : "", //OK tanggal fraud dilakukan
          // fraudControl:
          //   this.fraudControl != null
          //     ? this.fraudControl.trim()
          //     : this.fraudControl, //OK kontrol yang tidak berjalan
          // fraudDetail:
          //   this.fraudDetail != null
          //     ? this.fraudDetail.trim()
          //     : this.fraudDetail, // Detail / Cara Fraud Dilakukan (lokasi kerja terjadinya fraud ??)
          // fraudFactor:
          //   this.fraudFactor != null
          //     ? this.fraudFactor.trim()
          //     : this.fraudFactor, //OK Tujuan Fraud
          // fraudFactorDesc:
          //   this.fraudFactorDesc != null
          //     ? this.fraudFactorDesc.trim()
          //     : this.fraudFactorDesc, //OK Jika Tujuan Fraud Lainnya
          fraudId: this.fraudId,
          // fraudKnownDate: this.fraudKnownDate
          //   ? moment(this.fraudKnownDate, "DD-MMM-YYYY").format("yyyy-MM-DD")
          //   : "", //OK tanggal fraud diketahui
          // fraudMthdList: this.modusOperandiList,
          // fraudPurpose:
          //   this.fraudPurpose != null
          //     ? this.fraudPurpose.trim()
          //     : this.fraudPurpose, //OK Hari Kecurangan digunakan digunakan untuk apa
          // fraudReason:
          //   this.fraudReason != null
          //     ? this.fraudReason.trim()
          //     : this.fraudReason, //OK Alasan Pelaku melakukan fraud
          // investigationDelegatorPhone:
          //   this.investigationDelegatorPhone != null
          //     ? this.investigationDelegatorPhone.trim()
          //     : this.investigationDelegatorPhone,
          // investigationStatus:
          //   this.investigationStatus != null
          //     ? this.investigationStatus.trim()
          //     : this.investigationStatus, //OK Investigation Status / Status Investigasi
          // picInvestigatorEmail: "", //??
          // picInvestigatorPhone:
          //   this.picInvestigatorPhone != null
          //     ? this.picInvestigatorPhone.trim()
          //     : this.picInvestigatorPhone, //??
          // note: this.note != null ? this.note.trim() : this.note, //OK
          // recoveryCommitDate: this.recoveryCommitDate
          //   ? moment(this.recoveryCommitDate, "DD-MMM-YYYY").format(
          //       "yyyy-MM-DD"
          //     )
          //   : "",
          // rootCauseList: arraysv, //??
          // violationType: this.violationType, //OK jenis pelanggaran
          // violationTypeDesc: this.violationTypeDesc, //OK jika jenis pelanggaran lainnya
        })
          .then((response) => {
            this.isFromSave = true;
            console.log("response", response);
            this.$router.push("/tambah-modus");
            this.$helpers.handleSuccess(response);
          })
          .catch((err) => {
            console.log("response", err.response);
            this.$helpers.handleError(err);
          });
      }
      //   }
    },
  },

  computed: {
    ...mapState(["username"]),
    fraudId: function () {
      return this.$route.params.fraudId;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style>
</style>