<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Investigasi Fraud</h1>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="2">
        <h7 class="label-text-field"> No. Tiket JAGA </h7>
        <v-text-field
          v-model="refNo"
          class="mb-0 white mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari no. referensi"
          label=""
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="label-text-field" color="primary"> Lokasi Kejadian </h7>
        <v-text-field
          v-model="fraudLoc"
          class="mb-0 white mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari lokasi kejadian"
          label=""
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="label-text-field" color="primary"> Tanggal Verifikasi </h7>
        <v-menu
          ref="menu1"
          v-model="reportDateFromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateFrom"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0 white mr-1"
              placeholder=""
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateFromVal"
            no-title
            scrollable
            @input="pickReportDateFrom"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="label-text-field white--text" color="primary"> "" </h7>
        <v-menu
          ref="menu2"
          v-model="reportDateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateTo"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0 white mr-1"
              placeholder=""
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateToVal"
            no-title
            scrollable
            @input="pickReportDateTo"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          v-model="reportStatus"
          class="mb-0 white mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
        ></v-select>
      </v-col>
      <v-col>
        <h7 class="label-text-field white--text" color="primary"> "" </h7>
        <v-row cols="12" md="4" class="text--right">
          <v-btn
            @click="handleGetInquiryInvestigasi"
            class="elevation-0 text-capitalize ml-3"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="elevation-0 text-capitalize ml-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
          >
            <span class="orange--text">Reset</span></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <v-card rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersInvestigasi"
                  :items="inquiryInvestigasi"
                  :items-per-page="5"
                  :header-props="{ sortIcon: null }"
                  disable-sort
                  mobile-breakpoint="0"
                  ><template v-slot:item="data">
                    <tr>
                      <td class="orange--text">{{ data.item.jagaNo }}</td>
                      <td>{{ data.item.verifStartDateStr }}</td>
                      <td>{{ data.item.fraudLocName }}</td>
                      <td>{{ data.item.fraudTypeName }}</td>
                      <td>
                        <v-chip
                          class="ma-2"
                          color="orange lighten-4"
                          text-color="orange"
                        >
                          {{ data.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td><span :class="`${data.item.slaColor ? data.item.slaColor.toLowerCase() : 'black'}--text`">
                          {{ data.item.sla }}
                        </span></td>
                      <td align="center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              text
                              class="text-capitalize black--text px-0"
                              v-on="on"
                              v-bind="attrs"
                              :to="`/investigasi-fraud/${data.item.fraudId}/form`"
                            >
                              <v-icon
                                aria-hidden="false"
                                style="font-size: 20px"
                                color="#F5821F"
                              >
                                mdi-share-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Proses</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      reportDateFromMenu: false,
      reportDateToMenu: false,
      infoStatus: [],
      fraudLoc: "",
      refNo: "",
      reportDate: "",
      reportDateFrom: "",
      reportDateTo: "",
      reportStatus: "",
      reportDateFromVal: null,
      reportDateToVal: null,
      headersInvestigasi: [
        {
          text: "No. Tiket JAGA",
          align: "center",
          width: "50px",
        },
        {
          text: "Tanggal Verifikasi",
          align: "center",
          width: "100px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "100px",
        },
        {
          text: "Jenis Fraud",
          align: "center",
          width: "110px",
        },
        {
          text: "Status",
          align: "center",
          width: "130px",
        },
        {
          text: "Running SLA",
          align: "center",
          width: "44px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "63px",
        },
      ],
      inquiryInvestigasi: [
        {
          jaga: "a",
        },
      ],
    };
  },
  created() {
    this.requiredFeature('INVESTIGASI_FRAUD')
    this.changeTitleApp("Investigasi Fraud");
  },
  async mounted() {
    this.handleGetInquiryInvestigasi();
    this.loadReportStatus();
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "searchDataInvestigasi",
      "getParameterByParamType",
    ]),

    reset() {
      this.refNo = ""
      this.fraudLoc = ""
      this.reportDateFrom = null
      this.reportDateTo = null
      this.reportStatus = ""

      this.handleGetInquiryInvestigasi()
    },

    handleGetInquiryInvestigasi() {
      this.searchDataInvestigasi({
        fraudId: null,
        fraudLoc: this.fraudLoc, //Lokasi Kejadian
        pageNumber: 0,
        pageSize: 10000,
        jagaNo: this.refNo, //No. Tiket JAGA
        reportDate: "",
        reportDateFrom: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "", //Tanggal Verifikasi
        reportDateTo: this.reportDateTo ? moment(this.reportDateTo, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "", //Tanggal Verifikasi
        reportStatus: this.reportStatus, //Status
      }).then((response) => {
        this.inquiryInvestigasi = response.data.data;
      })
      .catch(() => {
        this.inquiryInvestigasi = []
      })
      ;
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format("DD-MMM-YYYY");
    },

    pickReportDateTo() {
      this.reportDateToMenu = false;
      this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(item => item.paramCd === 'REPORT_STATUS_INVESTIGATION_OUTSTANDING'
          || item.paramCd === 'REPORT_STATUS_DELEGATE_INVESTIGATOR'
          || item.paramCd === 'REPORT_STATUS_RE_INVESTIGATE');
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style src="./InvestigasiFraud.scss" lang="scss" />