<template> 
  <v-app>
    <v-form ref="formController" v-model="validFormConttroller" lazy-validation>
      <v-container fluid>

        <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
          <v-card>
            <v-col>
              <v-card-text>
                <v-icon class="icon" @click="expandPembiayaanFiktif = false">mdi-close</v-icon>
              </v-card-text>
              <v-card-title>{{ lossChosen.paramName }}</v-card-title>
              <v-row no-gutters justify="center">
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF' ||
                  lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
                  " :headers="headersPembayaranFiktif1Expand" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr>
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingPokok" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingMargin" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
                  :headers="headersPembayaranFiktif1ExpandSebagian" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.pembiayaanNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                          v-model="data.item.pembiayaanNasabahPercentage" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.pembiayaanKaryawan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                          v-model="data.item.pembiayaanKaryawanPercentage" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingTotal" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingKaryawan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
                  :headers="headersPembayaranFiktif2ExpandPelunasan" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td class="fixed_table_colum">
                        {{ data.index + 1 }}
                      </td>
                      <td class="fixed_table_colum2">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td class="fixed_table_colum3">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td class="fixed_table_colum4">
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.outstandingPelunasan" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
                  :headers="headersPembayaranFiktif2ExpandSetoran" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.appIdNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
                  :headers="headersPembayaranFiktif2ExpandTabungan" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.nasabahName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.tabunganNo" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.transaksiType" :items="transaksiTypeList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.prsCollection" :items="prsCollectionList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'" :headers="headersPembayaranFiktif3ExpandBrw"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.mthdDetailDesc" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.brwCategory" :items="brwCategoryList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'"
                  :headers="headersPembayaranFiktif3ExpandKas" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.sentraName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.kasSolCategory" :items="kasSolCategoryList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                          v-model="data.item.netFraud" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                  :headers="headersPembayaranFiktif3ExpandLemari" :items="lossList[indexExpand]"
                  :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.mthdDetailDesc" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'" :headers="headersPembayaranFiktif3ExpandFpb"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.itemName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="data.item.fpbTransactionDate" label="" prepend-inner-icon="mdi-calendar"
                          readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'" :headers="headersPembayaranFiktif4Expand"
                  :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr class="text-center">
                      <td>
                        {{ data.index + 1 }}
                      </td>
                      <td>
                        <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                          v-model="data.item.itemName" readonly>
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                          v-model="data.item.pelakuPencurian" :items="pelakuPencurianList" item-text="paramName"
                          item-value="paramCd" readonly>
                        </v-autocomplete>
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                          outlined dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                          dense v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                          v-bind:options="optionsMoney" readonly />
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openPelakuDialog(
                            data.item.detailLossDistList,
                            data.item.netFraud
                          )
                          ">Pelaku</v-btn>
                      </td>
                      <td>
                        <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                            </v-btn>
                          </template>
                          <div class="text-center">
                            <v-card>
                              <v-card-text>
                                <v-icon class="icon" @click="
                                  $set(
                                    dialogKeterangan,
                                    data.item.netFraud,
                                    false
                                  )
                                  " style="float: right; margin-top: 12px">mdi-close</v-icon>
                              </v-card-text>
                              <v-card-title>Keterangan</v-card-title>
                              <v-card-text>
                                <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                  v-model="data.item.notes" readonly></v-textarea>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-dialog>
                      </td>
                      <td>
                        <v-btn text class="primary--text" @click="
                          openDocumentDialog(data.item.detailLossDocList)
                          ">Document Image</v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>

        <v-dialog width="800" v-model="pelakuFraudDialog">
          <v-card>
            <v-card-text>
              <v-icon class="icon" @click="pelakuFraudDialog = false"
                style="float: right; margin-top: 12px">mdi-close</v-icon>
            </v-card-text>
            <v-card-title>Pelaku Fraud</v-card-title>
            <v-card-text>Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text>
            <v-card-text>
              <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList" :items-per-page="5"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:[`item.netFraudDist`]="{ item }">
                  <vuetify-money v-model="item.netFraudDist" class="mb-0 mt-3" hide-details label="" outlined dense
                    v-bind:options="optionsMoney" readonly />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog width="800" v-model="documentDialog">
          <v-card>
            <v-card-text>
              <v-icon class="icon" @click="documentDialog = false"
                style="float: right; margin-top: 12px">mdi-close</v-icon>
            </v-card-text>
            <v-card-title>Download Images</v-card-title>
            <v-card-text>Format file</v-card-text>
            <v-card-text>
              <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
                <v-col>
                  {{ index + 1 }}. {{ document.documentTypeName }}
                </v-col>
                <v-col class="text-right pr-3">
                  <v-btn v-if="document.attachFilePath != null" color="primary" class="text-none pr-3" rounded depressed
                    @click="handleDownloadAttachment(document)">
                    <v-icon left> mdi-download </v-icon>
                    {{
                      document.attachFileName
                      ? document.attachFileName
                      : "Download"
                    }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-text-field hide-details="auto" dense outlined placeholder="Keterangan" v-model="document.notes"
                    readonly></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Investigasi Controller</p>
          <span class="titlet"></span>
          <p class="subTitle">Delegasi</p>
        </v-row>
        <v-col cols="12" md="18">
          <!-- <div class="top"> -->
          <v-card rounded="lg">
            <template>
              <v-tabs>
                <v-tab href="#tab-dataPelapor" v-if="reportType != 'REPORT_TYPE_ANONIM'">Data Pelapor</v-tab>
                <v-tab href="#tab-detailLaporan" @click="handleDetailLaporan">Detail Laporan</v-tab>
                <v-tab href="#tab-verifikasiFraud" @click="handleVerifikasiFraud">Verifikasi fraud</v-tab>
                <v-tab href="#tab-investigasiFraud" v-if="reportStatusCode != 'REPORT_STATUS_VERIFIED'" @click="handleInvestigasiFraud">Investigasi
                  Fraud</v-tab>
                <v-tab href="#tab-delegasi" @click="handleDelegasiInvestigator">Delegasi</v-tab>

                <v-tab-item v-if="reportType != 'REPORT_TYPE_ANONIM'" value="tab-dataPelapor">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Nama Pelapor</p>
                        <v-text-field v-model="reportBy" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan nama Anda" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">NIK Pelapor</p>
                        <v-text-field v-model="nik" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB" dense
                          placeholder="Masukan NIK Anda" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Jabatan Pelapor</p>
                        <v-text-field v-model="position" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan Jabatan Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Email Pelapor</p>
                        <v-text-field v-model="email" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB" dense
                          placeholder="Masukan Email Anda" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Office Pelapor</p>
                        <v-text-field v-model="office" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB" dense
                          placeholder="" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col><v-col cols="12" md="6">
                        <p class="label-text-field">Departement Pelapor</p>
                        <v-text-field v-model="department" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Nama Atasan 1</p>
                        <v-text-field v-model="superior1" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col><v-col cols="12" md="6">
                        <p class="label-text-field">Nama Atasan 2</p>
                        <v-text-field v-model="superior2" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="" readonly style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">No Telepon Pelapor</p>
                        <v-text-field v-model="phoneNumber" class="dp mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan No Telepon Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-detailLaporan">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">No. Tiket JAGA</p>
                        <v-text-field v-model="jagaNo" class="dl mb-0" hide-details="auto" outlined color="#A8B5CB" dense
                          placeholder="Masukan No. Tiket JAGA Anda" readonly
                          style="background-color: #f8f8f8"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Tanggal Pelaporan</p>
                        <v-menu ref="menuReportDate" v-model="menuReportDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar" v-model="reportDate"
                              class="dl mb-0" label="" hide-details="auto" outlined color="#A8B5CB" dense placeholder=""
                              readonly style="background-color: #f8f8f8">
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="reportDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Jenis Fraud</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ fraudType }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Sumber Informasi Awal Temuan Fraud
                        </p>
                        <v-text-field v-model="fraudInfo" class="mb-0" hide-details="auto" outlined filled readonly dense></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="fraudInfo == 'Sumber Lainnya : ... Sebutkan'">
                        <p class="label-text-field">Lainnya</p>
                        <v-text-field v-model="fraudInfoOther" class="dl mb-0" hide-details="auto" outlined dense readonly
                          placeholder="Masukan sumber informasi awal temuan fraud" style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Yang Terlibat</p>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersSuspect" :items="fraudSuspect"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:[`item`]="{ item }">
                                <tr>
                                  <td align="center">{{ item.seqNo }}</td>
                                  <td align="center">{{ item.pelakuNik }}</td>
                                  <td align="center">
                                    <v-switch v-model="item.pelakuUtama" :label="item.pelakuUtama == 'Y' ? 'Ya' : 'Tidak'
                                      " true-value="Y" false-value="N" inset color="orange darken-3" hide-details
                                      readonly>
                                    </v-switch>
                                  </td>
                                  <!-- <td align="center">
                                      {{
                                        item.pelakuUtama == "Y" ? "Ya" : "Tidak"
                                      }}
                                    </td> -->
                                  <td align="center">
                                    {{ item.pelakuName }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuPosition }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuStatus }}
                                  </td>
                                  <td align="center">
                                    {{ item.lokasiKerja }}
                                  </td>
                                  <td align="center">
                                    {{ item.assignmentCategory }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuJointDate }}
                                  </td>
                                  <td align="center">
                                    {{ item.pelakuEducation }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Lokasi Kerja Terjadinya Fraud
                        </p>
                        <v-text-field v-model="branchCd" class="dl mb-0" hide-details="auto" outlined color="#A8B5CB"
                          dense placeholder="Masukan Kode Lokasi Kerja Terjadinya Fraud" readonly
                          style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Kerugian Awal</p>
                        <v-text-field v-model="initialLoss" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Modus Operandi</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersModus" :items="fraudMethod"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:[`item`]="{ item }">
                                <tr>
                                  <td align="center">{{ item.seqNo }}</td>
                                  <td style="text-align: left">
                                    {{ item.fraudMthdName }}
                                  </td>
                                  <td style="text-align: left">
                                    {{ item.incidentName }}
                                  </td>
                                  <td align="center">
                                    {{
                                      item.incidentDesc == ""
                                      ? "-"
                                      : item.incidentDesc
                                    }}
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Attachment</p>
                        <v-col cols="12" v-for="item in attachment" :key="item">
                          <a v-if="item.flowName == 'Pengaduan Fraud'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-verifikasiFraud"><v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Verification Status</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ verifStatus }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <!-- <v-col cols="12">
                        <p class="label-text-field">Return</p>
                        <v-textarea
                          v-model="returnNote"
                          class="mb-0"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder=""
                          readonly
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Tanggal Return</p>
                        <v-menu
                          ref="menuReturnDate"
                          v-model="menuReturnDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              prepend-inner-icon="mdi-calendar"
                              v-model="returnDate"
                              label=""
                              hide-details="auto"
                              outlined
                              color="#A8B5CB"
                              dense
                              placeholder=""
                              readonly
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="returnDateVal"
                            no-title
                            scrollable
                            disabled
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col> -->
                      <v-col cols="12">
                        <p class="label-text-field">Dokumen Awal Pelaporan</p>
                        <v-row class="ml-2" style="margin-right: 150px">
                          <v-row v-for="item in reportDoc" :key="item">
                            <v-checkbox v-model="item.reportDocTypeName" class="px-2" :label="item.reportDocTypeName"
                              readonly>
                            </v-checkbox>
                          </v-row>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Nominal SP Pelaku</p>
                        <v-text-field v-model="spPelaku" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          SP Nasabah / Pihak Ketiga
                        </p>
                        <v-text-field v-model="spNasabah" prefix="Rp" class="dl mb-0" hide-details="auto" outlined
                          color="#A8B5CB" dense placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Manager Investigator</p>
                        <v-text-field v-model="investigatorManager" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">No Hp Investigator</p>
                        <v-text-field v-model="investigatorPhone" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Didelegasi Oleh</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" disabled style="
                                    width: 170px;
                                    height: 56px;
                                    border: 1px solid #f5821f;
                                    box-shadow: 0px 10px 20px
                                      rgba(235, 127, 0, 0.1);
                                    border-radius: 8px;
                                  "><span class="primary--text">
                                {{ delegateBy }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Keterangan
                        </p>
                        <v-textarea v-model="eksternalNote" class="mb-0" hide-details="auto" outlined dense placeholder=""
                          readonly style="background-color: #f8f8f8"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-investigasiFraud">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <p class="label-text-field">Investigation Status</p>
                        <v-col align="left" style="margin-left: -16px">
                          <div class="vs mt-2">
                            <v-btn x-large depressed outlined color="grey" v-model="investigationStatus" disabled style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "><span class="primary--text">
                                {{ investigationStatus }}</span></v-btn>
                          </div>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Tujuan Fraud</p>
                        <v-text-field hide-details="auto" dense outlined v-model="fraudFactor" disabled></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="fraudFactor == 'Lainnya : ... Sebutkan'">
                        <p class="label-text-field">Lainnya</p>
                        <v-text-field v-model="fraudFactorOther" class="mb-0" hide-details="auto" outlined dense
                          placeholder="" readonly style="background-color: #f8f8f8">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <h7 class="label-text-field" color="primary">
                          Root Cause
                        </h7>
                        <v-row style="width: 100%">
                          <v-col cols="12">
                            <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse"
                              :items-per-page="10" :header-props="{ sortIcon: null }" disable-sort>
                              <template v-slot:item="data">
                                <tr>
                                  <td align="center">{{ data.index + 1 }}</td>
                                  <td align="center">
                                    {{ data.item.rootCauseName }}
                                  </td>
                                  <td>
                                    <v-select :items="rootCauseStatus[data.index]" item-text="rootCauseName"
                                      item-value="rootCauseDesc" v-model="data.item.rootCauseList" return-object label=""
                                      class="mt-3 mb-3" hide-details="auto" outlined dense placeholder="" multiple chips
                                      readonly style="background-color: #f8f8f8">
                                    </v-select>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Tanggal Fraud Dilakukan</p>
                        <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                              style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="startDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6">
                        <p class="label-text-field">Tanggal Fraud Diketahui</p>
                        <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                              style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="endDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">
                          Tanggal Terakhir Fraud
                        </p>
                        <v-menu v-model="recoveryDateMenu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="fraudCommitedDateTo" label="" prepend-inner-icon="mdi-calendar"
                              v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                              placeholder="" style="background-color: #f8f8f8"></v-text-field>
                          </template>
                          <v-date-picker v-model="recoveryDateVal" no-title scrollable disabled>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field">Attachment</p>
                        <v-col cols="12" v-for="item in attachmentAfm" :key="item">
                          <a v-if="item.flowName == 'Investigasi AFM'" @click="handleDownloadAttachment(item)">{{
                            item.attachFileName }}</a>
                        </v-col>
                      </v-col>
                      <v-col cols="12">
                        <p class="label-text-field mb-5">
                          Rincian Daftar Kerugian
                        </p>

                        <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                          hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                          <template v-slot:item="data" item-key="pelakuId">
                            <tr v-if="!isMerged(data.item, data.index)">
                              <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                                data.index) }}</td>
                              <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                              <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                              <td>{{ data.item.modusName }}</td>
                              <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                              <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                              <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                              <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                              <td>{{ formatNumber(data.item.recovery) }}</td>
                              <td>{{ formatNumber(data.item.netFraud) }}</td>
                              <td>
                                <div v-if="data.item.pelakuUtama == 'N'">
                                  <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                    cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                    <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                  </v-btn>
                                </div>
                                <div v-else>
                                  <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                    @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                    <span class="orange--text ml-2">Download List Nasabah</span>
                                  </v-btn>
                                  <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                    @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                    <span class="orange--text ml-2">Download Bukti</span>
                                  </v-btn>
                                </div>
                              </td>
                            </tr>
                            <tr v-else>
                              <td>{{ data.item.modusName }}</td>
                              <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                              <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                              <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                              <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                              <td>{{ formatNumber(data.item.recovery) }}</td>
                              <td>{{ formatNumber(data.item.netFraud) }}</td>
                              <td>
                                <div v-if="data.item.pelakuUtama == 'N'">
                                  <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                    cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                    <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                  </v-btn>
                                </div>
                                <div v-else>
                                  <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                    @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                    <span class="orange--text ml-2">Download List Nasabah</span>
                                  </v-btn>
                                  <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                    @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                    <span class="orange--text ml-2">Download Bukti</span>
                                  </v-btn>
                                </div>
                              </td>
                            </tr>
                          </template>
                          <template slot="body.append">
                            <tr>
                              <td colspan="4" align="center">Total</td>
                              <td>{{sumField('noa') === 0 ? "" : formatNumber(sumField('noa'))}}</td>
                              <td>{{sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond'))}}</td>
                              <td>{{sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan'))}}</td>
                              <td>{{sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss'))}}</td>
                              <td>{{sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery'))}}</td>
                              <td>{{sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud'))}}</td>
                          </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>

                <v-tab-item value="tab-delegasi">
                  <v-card-text>
                    <v-row class="mx-1">
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">PIC Investigator</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>

                        <v-dialog v-model="dialogPicInves" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="picInvestigator" v-bind="attrs" v-on="on" class="mb-0"
                              hide-details="auto" outlined dense placeholder="Masukan nama PIC Investigator"
                              @click="loadPicInves" :disabled="reportStatusCode != 'REPORT_STATUS_VERIFIED' || $store.state.statusInvestigasiController == 'Return'">
                            </v-text-field>
                          </template>
                          
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="dialogPicInves = false"
                                style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title class="black--text">
                              PIC Investigator
                            </v-card-title>
                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicInves"
                                  dense outlined placeholder="Masukan nama PIC investigator"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicInves" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>
                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicInvestigator"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicInves">
                                  <tr>
                                    <td align="center">
                                      {{ allPicInves.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicInves.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicInves.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicInves.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicInves(allPicInves.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" v-if="reportStatusCode != 'REPORT_STATUS_VERIFIED'">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">PIC Approver</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>

                        <v-dialog v-model="dialogPicAppr" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="picApprName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                                outlined 
                                dense 
                                placeholder="Masukan nama PIC Approover" 
                                @click="loadPicAppr"
                                :disabled="
                                reportStatusCode !== 'REPORT_STATUS_INVESTIGATED' ? picApprReturn !== null ? true : false : true ||
                                reportStatusCode === 'REPORT_STATUS_INVESTIGATED' ? picApprReturn === null ? true : false : true"
                                >
                            </v-text-field>
                          </template>
                          
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="dialogPicAppr = false"
                                style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title class="black--text">
                              PIC Approver
                            </v-card-title>
                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicAppr"
                                  dense outlined placeholder="Masukan nama PIC investigator"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicAppr" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>
                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicApprover"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicAppr">
                                  <tr>
                                    <td align="center">
                                      {{ allPicAppr.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicAppr(allPicAppr.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" v-if="(reportStatusCode == 'REPORT_STATUS_INVESTIGATED' &&
                        picBcReturn != null
                        ? true
                        : false) ||
                        reportStatusCode ==
                        'REPORT_STATUS_APPROVE_QA_MANAGER' ||
                        (reportStatusCode ==
                          'REPORT_STATUS_RETURN_QA_MANAGER' &&
                          picBcReturn != null
                          ? true
                          : false) ||
                        reportStatusCode == 'REPORT_STATUS_APPROVE_BC' ||
                        reportStatusCode == 'REPORT_STATUS_RETURN_BC' ||
                        reportStatusCode == 'REPORT_STATUS_APPROVE_DH' ||
                        reportStatusCode == 'REPORT_STATUS_RETURN_DH' ||
                        (reportStatusCode ==
                          'REPORT_STATUS_RETURN_AFM_REVIEWER' &&
                          picBcReturn != null
                          ? true
                          : false) ||
                        (reportStatusCode ==
                          'REPORT_STATUS_INVESTIGATION_REVISED' &&
                          picBcReturn != null
                          ? true
                          : false)
                        ">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Pejabat 1</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog v-model="dialogPicBh" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="picBcName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                              outlined dense placeholder="Masukan nama Pejabat 1" @click="loadPicBh" :disabled="reportStatusCode ==
                                'REPORT_STATUS_APPROVE_QA_MANAGER'
                                ? picBcReturn == null
                                  ? false
                                  : true
                                : true
                                ">
                            </v-text-field>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="dialogPicBh = false"
                                style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title class="black--text">
                              Pejabat 1
                            </v-card-title>
                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicBh"
                                  dense outlined placeholder="Masukan nama PIC investigator"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicBh" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>
                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicBh"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicBh">
                                  <tr>
                                    <td align="center">
                                      {{ allPicBh.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicBh.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicBh.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicBh.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicBh(allPicBh.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                      
                      <v-col cols="12" v-if="(reportStatusCode == 'REPORT_STATUS_INVESTIGATED' &&
                        picDhReturn != null
                        ? true
                        : false) ||
                        (reportStatusCode ==
                          'REPORT_STATUS_APPROVE_QA_MANAGER' &&
                          picDhReturn != null
                          ? true
                          : false) ||
                        (reportStatusCode ==
                          'REPORT_STATUS_RETURN_QA_MANAGER' &&
                          picDhReturn != null
                          ? true
                          : false) ||
                        reportStatusCode == 'REPORT_STATUS_APPROVE_BC' ||
                        (reportStatusCode == 'REPORT_STATUS_RETURN_BC' &&
                          picDhReturn != null
                          ? true
                          : false) ||
                        reportStatusCode == 'REPORT_STATUS_APPROVE_DH' ||
                        reportStatusCode == 'REPORT_STATUS_RETURN_DH' ||
                        (reportStatusCode ==
                          'REPORT_STATUS_RETURN_AFM_REVIEWER' &&
                          picDhReturn != null
                          ? true
                          : false) ||
                        (reportStatusCode ==
                          'REPORT_STATUS_INVESTIGATION_REVISED' &&
                          picDhReturn != null
                          ? true
                          : false)
                        ">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Pejabat 2</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog v-model="dialogPicDh" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="picDhName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                              outlined dense placeholder="Masukan nama Pejabat 2" @click="loadPicDh" :disabled="reportStatusCode == 'REPORT_STATUS_APPROVE_BC'
                                ? picDhReturn == null
                                  ? false
                                  : true
                                : true
                                ">
                            </v-text-field>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="dialogPicDh = false"
                                style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title class="black--text">
                              Pejabat 2
                            </v-card-title>
                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicDh"
                                  dense outlined placeholder="Masukan nama PIC investigator"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicDh" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>
                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicDh"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicDh">
                                  <tr>
                                    <td align="center">
                                      {{ allPicDh.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicDh.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicDh.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicDh.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicDh(allPicDh.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </template>
          </v-card>
          <!-- </div> -->

          <v-row>
            <v-col class="text-right">
              <v-btn :to="`/investigasi-controller`" x-large depressed outlined color="primary"
                class="elevation-0 text-capitalize mr-3 primary--text"><span class="primary--text">Back</span></v-btn>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-large depressed color="primary" class="elevation-0 text-capitalize mr-3 primary--text"
                    v-bind="attrs" v-on="on" v-if="$store.state.statusInvestigasiController == 'Return'"><span
                      class="white--text">Return</span></v-btn>
                </template>
                <template>
                  <v-card>
                    <v-card-title>
                      Return
                    </v-card-title>
                    <v-card-text>
                      <span class="black--text">Notes</span>
                      <v-row>
                        <v-textarea class="ma-2" hide-details="auto" outlined dense placeholder=""
                          v-model="notesReturn"></v-textarea>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn depressed color="primary" @click="handleReturnInvestigasi" class="white--text">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
              <v-btn v-if="delegateBoolean == false && //??
                (reportStatusCode == 'REPORT_STATUS_VERIFIED' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_AFM_MANAGER' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_QA_OPERATION' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_QA_MANAGER' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_BC' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_DH' || //OK
                  reportStatusCode == 'REPORT_STATUS_RETURN_AFM_REVIEWER') //??
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed @click="delegate" class="elevation-0 text-capitalize mr-3 primary--text"
                color="primary"><span class="white--text">Delegasi</span></v-btn>

              <v-btn v-if="approvalBoolean == false && //??
                picApprover != '' && //??
                reportStatusCode == 'REPORT_STATUS_INVESTIGATED' //OK
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="requestPicApproval"><span class="white--text">Request for Approval</span></v-btn>

              <v-btn v-if="delegateBy != 'QA Manager' &&
                (reportStatusCode == 'REPORT_STATUS_APPROVE_DH' ||
                  reportStatusCode == 'REPORT_STATUS_APPROVE_AFM_MANAGER' || //OK
                  reportStatusCode == 'REPORT_STATUS_APPROVE_QA_OPERATION' || //OK
                  reportStatusCode == 'REPORT_STATUS_INVESTIGATION_REVISED')
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="submitInvestigasiProses"><span class="white--text">Submit</span></v-btn>

              <!-- approval for BC -->
              <v-btn v-if="approveBCBoolean == false &&
                reportStatusCode == 'REPORT_STATUS_APPROVE_QA_MANAGER'
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="requestBC"><span class="white--text">Request for Approval</span></v-btn>

              <!-- approval for BC -->
              <v-btn v-if="approveDHBoolean == false && //??
                reportStatusCode == 'REPORT_STATUS_APPROVE_BC' //OK
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="requestDH"><span class="white--text">Request for Approval</span></v-btn>

              <v-btn v-if="picInvestigatorReturn != '' &&
                picApproverReturn != '' &&
                picBcReturn != '' &&
                picDhReturn != '' &&
                (reportStatusCode == 'REPORT_STATUS_APPROVE_DH' || //OK
                  reportStatusCode ==
                  'REPORT_STATUS_INVESTIGATION_REVISED') &&
                delegateBy == 'QA Manager'
                && $store.state.statusInvestigasiController == 'Delegate'
                " x-large depressed class="elevation-0 text-capitalize mr-3 primary--text" color="primary"
                @click="submitInvestigasiProses"><span class="white--text">Submit</span></v-btn>
            </v-col>
          </v-row>

          <v-card>
            <v-card-text>
              <v-row class="mx-1">
                <v-col>
                  <h7 class="label-text-field" color="primary"> History </h7>
                  <v-data-table :headers="headersLog" :items="fraudLog" :items-per-page="10"
                    :header-props="{ sortIcon: null }" disable-sort>
                    <template v-slot:[`item`]="{ item }">
                      <tr>
                        <td align="center">{{ item.seqNo }}</td>
                        <td align="center">{{ item.processDate }}</td>
                        <td align="center">{{ item.processBy }}</td>
                        <td align="center">{{ item.processStatusName }}</td>
                        <td align="center" style="text-align: left">
                          {{ item.processComment }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- </div> -->
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      delegatorPosition: "",
      rootCauseStatus: [],
      rootCauseList: [],
      rootCouse: [],
      rootCauseApi: [],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      validFormPengaduan: false,
      checkAnonym: false,
      menuReportDate: false,
      //menuReturnDate: false,
      menuStartDate: false,
      menuEndDate: false,

      reportDateVal: null,
      //returnDateVal: null,
      startDateVal: null,
      endDateVal: null,
      recoveryDateMenu: false,
      fraudCommitedDateTo: "",
      recoveryDateVal: null,

      anonimFlag: "",
      reportBy: "",
      nik: "",
      position: "",
      email: "",
      office: "",
      department: "",
      superior1: "",
      superior2: "",
      phoneNumber: "",

      jagaNo: "",
      reportDate: "",
      fraudType: [],
      fraudInfo: "",
      fraudInfoOther: "",
      infoFraud: [],
      employee: "",
      locationCd: "",
      locationName: "",
      branchCd: "",
      initialLoss: "",
      attachment: "",
      sharePoint: "",
      reportType: "",

      verifStatus: [],
      //returnNote: "",
      //returnDate: "",
      reportDoc: "",
      spPelaku: "",
      spNasabah: "",
      investigatorManager: "",
      investigatorPhone: "",
      delegateBy: [],
      eksternalNote: "",

      investigationStatus: [],
      // fraudDetail: "",
      tujuanFraudList: [],
      fraudFactor: "",
      fraudFactorOther: "",
      // fraudReason: "",
      // fraudPurpose: "",
      // fraudControl: "",
      violationType: "",
      jenisPelanggaranList: [],
      violationTypeOther: "",
      startDate: "",
      endDate: "",
      attachmentAfm: "",
      detailLoss: [],
      lossList: [],
      pembiayaanFiktifList: [],
      expandPembiayaanFiktif: false,
      indexExpand: 0,
      lossChosen: {
        paramValue: 5,
      },
      panelIndex: [],
      dialogKeterangan: {},
      statusAngsuranList: [],
      transaksiTypeList: [],
      prsCollectionList: [],
      brwCategoryList: [],
      kasSolCategoryList: [],
      pelakuPencurianList: [],

      isiPelakuList: [],
      isiDocumentList: [],
      isiKeteranganList: [],

      pelakuFraudDialog: false,
      keteranganDialog: false,
      documentDialog: false,

      picInvestigator: "",
      picApprover: "",
      picBc: null,
      picDh: null,

      dialogPicInves: false,
      searchValuePicInves: "",
      dialogPicAppr: false,
      searchValuePicAppr: "",
      dialogPicBh: false,
      searchValuePicBh: "",
      dialogPicDh: false,
      searchValuePicDh: "",

      picApprAppproveDate: "",
      picBcApproveDate: "",
      picDhApproveDate: "",
      investigationStartDate: "",
      investigationEndDate: "",
      verifStartDate: "",
      verifEndDate: "",

      delegateBoolean: false,
      approvalBoolean: false,
      approveBCBoolean: false,
      approveDHBoolean: false,

      picInvestigatorNik: "",
      picApproverNik: "",
      picBcNik: "",
      picDhNik: "",

      picAppDefaultNik: "",

      reportStatus: "",
      picInvestigatorReturn: "",
      picApproverReturn: "",
      picBcReturn: "",
      picDhReturn: "",
      // baru ji 
      picBcReturnNik: "",
      picDhReturnNik: "",
      // 
      picInvestigatorNikReturn: "",
      picApproverNikReturn: "",
      picBcNikReturn: "",
      picDhNikReturn: "",

      attachFilePath: "",
      attachFileName: "",
      base64: "",

      page: 1,
      perPage: 5,
      statusSearch: false,
      totalData: null,
      countData: null,

      fraudLog: [],
      fraudSuspect: [],
      fraudMethod: [],
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp.",
        suffix: "",
        length: 12,
        precision: 0,
      },
      headersLog: [
        {
          text: "No.",
          align: "center",
        },
        {
          text: "Process Date",
          align: "center",
        },
        {
          text: "Process By",
          align: "center",
        },
        {
          text: "Process Status",
          align: "center",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "180px",
        },
      ],
      headersPic: [
        {
          text: "NIK",
          align: "center",
          width: "130px",
        },
        {
          text: "Nama",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "180px",
        },
        {
          text: "Organisasi",
          align: "center",
          width: "170px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "116px",
        },
      ],
      headersSuspect: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "130px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          width: "118px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Status Kepagawaian Pelaku",
          align: "center",
          width: "170px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          width: "170px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          width: "170px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          width: "126px",
        },
        {
          text: "Pendidikan",
          align: "center",
          width: "126px",
        },
      ],
      headersModus: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "280px",
        },
        {
          text: "Kronologis Kejadian",
          align: "center",
          width: "620px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "620px",
        },
      ],
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "180px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "150px",
        },
      ],
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafon (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
        }
      ],
      returnDialog: false,
      notesReturn: '',
      flagLoadLaporan: false,
      flagLoadVerifikasi: false,
      flagLoadInvestigasi:false,
      flagLoadDelegasi: false,
    };
  },

  async mounted() {
    this.handleInitialData();

    // this.loadFraudInfo();
    // this.loadTujuanFraud();
    // this.loadJenisPelanggaran();
    // this.loadDataController();
    // this.handleGetLossList();
    // this.handleGetStatusAngsuran();
    // this.handleGetTransaksiType();
    // this.handleGetPrsCollection();
    // this.handleGetBrwCategory();
    // this.handleGetKasSolCategory();
    // this.handleGetPelakuPencurian();
    // this.handleSearchFraudPelaku();
  },

  created() {
    this.requiredFeature('INVESTIGASI_CONTROLLER')
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getParameterByParamType",
      "getFraudInvProcessDataByFraudId",
      "getInquiryPic",
      "getInquiryLovInvestigator",
      "getInquiryLovPicBc",
      "getInquiryLovPicDh",
      "getEmployeeBySearchValue",
      "requestPICApproval",
      "requestPICBC",
      "requestPICDH",
      "delegateInvestigator",
      "submitInvestigation",
      "downloadAttachment",
      "returnInvestigasi",
      "searchFraudPelaku",
      "downloadFilePelaku",
      "createInvHistory",
      "getInitialData",
      "getDataPelaporByFraudId",
      "getFraudHistoryByFraudId",
      "getDetailLaporanByFraudId",
      "getYangTerlibatByFraudId",
      "getModusOperandiByFraudId",
      "getAttachmentByFraudId",
      "getVerifikasiFraudByFraudId",
      "getDokumenAwalByFraudId",
      "getDataInvestigasiFraudByFraudId",
      "getRootCauseByFraudId",
      "getAttachmentAFMByFraudId",
      "getDelegasiInvestigatorByFraudId",
    ]),

    handleInitialData() {
      this.getInitialData({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.reportType = resp.data.data.reportType;
        this.delegatorPosition = resp.data.data.delegatorPosition;
        this.reportStatusCode = resp.data.data.reportStatus;

        if (this.reportType !== 'REPORT_TYPE_ANONIM') {
          this.handleDataPelapor();
        }
        else {
          this.handleDetailLaporan();
        }
        
        this.handleFraudHistory();
      })
      .catch((err) => {
        console.log("handleInitialData ERR", err);
      });
    },

    handleDataPelapor() {
      this.getDataPelaporByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.reportBy = resp.data.data.namaPelapor == null ? "-" : resp.data.data.namaPelapor;
        this.nik = resp.data.data.nikPelapor == null ? "-" : resp.data.data.nikPelapor;
        this.position = resp.data.data.jabatanPelapor == null ? "-" : resp.data.data.jabatanPelapor;
        this.email = resp.data.data.emailPelapor == null ? "-" : resp.data.data.emailPelapor;
        this.office = resp.data.data.officePelapor == null ? "-" : resp.data.data.officePelapor;
        this.department = resp.data.data.departmentPelapor == null ? "-" : resp.data.data.departmentPelapor;
        this.superior1 = resp.data.data.namaAtasan1 == null ? "-" : resp.data.data.namaAtasan1;
        this.superior2 = resp.data.data.namaAtasan2 == null ? "-" : resp.data.data.namaAtasan2;
        this.phoneNumber = resp.data.data.teleponPelapor == null ? "-" : resp.data.data.teleponPelapor;
      })
      .catch((err) => {
        console.log("handleDataPelapor ERR", err);
      });
    },

    handleFraudHistory() {
      this.getFraudHistoryByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.fraudLog = resp.data.data;
      })
      .catch((err) => {
        console.log("handleFraudHistory ERR", err);
      });
    },

    handleDetailLaporan() {
      if (!this.flagLoadLaporan) {
        this.flagLoadLaporan = true;

        this.getDetailLaporan();
        this.getYangTerlibat();
        this.getModusOperandi();
        this.getAttachment();
      }
    },

    getDetailLaporan() {
      this.getDetailLaporanByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.jagaNo = resp.data.data.jagaNo;
        this.reportDate = moment(resp.data.data.tanggalLaporan).format("DD-MMM-YYYY");
        this.fraudType = resp.data.data.jenisFraudStr;
        this.fraudInfo = resp.data.data.sumberInformasiStr;
        this.fraudInfoOther = resp.data.data.sumberInformasiDesc;
        this.branchCd = resp.data.data.lokasiFraudNm;
        this.initialLoss = this.humanFormat(resp.data.data.kerugianAwal);
      })
      .catch((err) => {
        console.log("getDetailLaporan ERR", err);
      });
    },

    getYangTerlibat() {
      this.getYangTerlibatByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.fraudSuspect = resp.data.data;
      })
      .catch((err) => {
        console.log("getYangTerlibat ERR", err);
      });
    },

    getModusOperandi() {
      this.getModusOperandiByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.fraudMethod = resp.data.data;
      })
      .catch((err) => {
        console.log("getModusOperandi ERR", err);
      });
    },

    getAttachment() {
      this.getAttachmentByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.attachment = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachment ERR", err);
      });
    },

    handleVerifikasiFraud() {
      if (!this.flagLoadVerifikasi) {
        this.flagLoadVerifikasi = true;

        this.getVerifikasiFraud();
        this.getDokumenAwal();
      }
    },

    getVerifikasiFraud() {
      this.getVerifikasiFraudByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.verifStatus = resp.data.data.verifikasiStatusNm; 
        this.spPelaku = resp.data.data.spPelaku;
        this.spNasabah = resp.data.data.spNasabah;
        this.investigatorManager = resp.data.data.delegatorName;
        this.investigatorPhone = resp.data.data.delegatorPhone;
        this.delegateBy = resp.data.data.delegatorPositionNm;
        this.eksternalNote = resp.data.data.keterangan;
      })
      .catch((err) => {
        console.log("getVerifikasiFraud ERR", err);
      });
    },

    getDokumenAwal() {
      this.getDokumenAwalByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.reportDoc = resp.data.data;
      })
      .catch((err) => {
        console.log("getDokumenAwal ERR", err);
      });
    },

    handleInvestigasiFraud() {
      if (!this.flagLoadInvestigasi) {
        this.flagLoadInvestigasi = true;

        this.loadStatusRoot();
        this.getInvestigasiFraud();
        this.getRootCause();
        this.getSummaryStatus();
        this.getAttachmentAFM();
      }
    },

    getInvestigasiFraud() {
      this.getDataInvestigasiFraudByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.investigationStatusCode = resp.data.data.investigationStatus;
        this.investigationStatus = resp.data.data.investigationStatusNm;
        this.fraudFactor = resp.data.data.fraudFactorNm;
        this.fraudFactorOther = resp.data.data.fraudFactorDesc;
        this.startDate = resp.data.data.fraudCommitedDt !== null ? moment(resp.data.data.fraudCommitedDt).format("DD-MMM-YYYY") : null;
        this.endDate = resp.data.data.fraudKnownDt !== null ? moment(resp.data.data.fraudKnownDt).format("DD-MMM-YYYY") : null;
        this.fraudCommitedDateTo = resp.data.data.fraudCommitedDtTo !== null ? moment(resp.data.data.fraudCommitedDtTo).format("DD-MMM-YYYY") : null;
      })
      .catch((err) => {
        console.log("getInvestigasiFraud ERR", err);
      });
    },

    getRootCause() {
      this.getRootCauseByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        // console.log("getRootCause", resp.data.data);
        this.rootCauseApi = resp.data.data;
        
        if (this.rootCauseApi.length != 0) {
          this.rootCauseApi.map((item) => {
            let indexs = this.rootCouse.findIndex(
              (x) => x.rootCause === item.rootCause
            );
            this.rootCouse.splice(indexs, 1, item);
          });
        }
      })
      .catch((err) => {
        console.log("getRootCause ERR", err);
      });
    },

    getSummaryStatus() {
      this.searchFraudPelaku(this.idFraud)
        .then(response => {
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('getSummaryStatus ERR', error)
        })
    },

    getAttachmentAFM() {
      this.getAttachmentAFMByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.attattachmentAfmachment = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachmentAFM ERR", err);
      });
    },

    handleDelegasiInvestigator() {
      if (!this.flagLoadDelegasi) {
        this.flagLoadDelegasi = true;

        this.getDelegasiInvestigator();
      }
    },

    getDelegasiInvestigator() {
      this.getDelegasiInvestigatorByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.picAppDefaultNik = resp.data.data.delegatorNik;
        this.delegateBy = resp.data.data.delegatorPosition;

        this.picInvestigatorNik = resp.data.data.investigatorNik;
        this.picInvestigator = resp.data.data.investigatorNm;
        this.picInvestigatorNikReturn = resp.data.data.investigatorNik;
        this.picInvestigatorReturn = resp.data.data.investigatorNm;

        this.picAppr = resp.data.data.approverNik;
        this.picApproverNik = resp.data.data.approverNik == null ? this.picAppDefaultNik : resp.data.data.approverNik;
        this.picApprover = resp.data.data.delegatorNm;
        this.picApproverReturn = resp.data.data.approverNm;
        this.picApprNameReturn = resp.data.data.approverNm;

        this.picBcNik = resp.data.data.bcNik == null ? "-" : resp.data.data.bcNik;
        this.picBcName = resp.data.data.bcNm;
        this.picBcReturnNik = resp.data.data.bcNik;
        this.picBcReturn = resp.data.data.bcNm;
        this.picBcNameReturn = resp.data.data.bcNm;

        this.picDhNik = resp.data.data.dhNik == null ? "-" : resp.data.data.dhNik;
        this.picDhReturnNik = resp.data.data.dhNik;
        this.picDhReturn = resp.data.data.dhNm;
        this.picDh = resp.data.data.dhNik;
        this.picDhName = resp.data.data.dhNm;

        // PIC Approver Jika Delegasi Oleh SBM Maka Nilai di text-field jadi kosong
        if (this.picAppr !== null) {
          this.picApprReturn = resp.data.data.approverNik == null ? resp.data.data.delegatorNik : resp.data.data.approverNik;
          this.picApprName = resp.data.data.approverNm == null ? resp.data.data.delegatorNm : resp.data.data.approverNm;
        }
        else if (this.picAppr === null) {
          if (this.delegateBy == 'INVESTIGATOR_DELEGATOR_POS_SBM') {
            this.picApprReturn = ""
            this.picApprName = ""
          }
          else {
            this.picApprReturn = resp.data.data.approverNik == null ? resp.data.data.delegatorNik : resp.data.data.approverNik;
            this.picApprName = resp.data.data.approverNm == null ? resp.data.data.delegatorNm : resp.data.data.approverNm;
          }
        }
      })
      .catch((err) => {
        console.log("getAttachmentAFM ERR", err);
      });
    },

    handleSearchFraudPelaku() {
      this.searchFraudPelaku(this.idFraud)
        .then(response => {
          console.log('response', response)
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    handleDownloadFilePelaku(item, type) {
    this.downloadFilePelaku({
      pelakuDtlId: item.pelakuDetailId,
      type: type
    })
      .then((response) => {
        console.log(response, "response")
      })
      .catch((err) => {
        console.log("gagal download", err.response);
        this.$helpers.handleError(err);
        this.$store.commit("setShowSnackbar", {
                snackbarMsg: "Gagal mengunduh. File tidak ditemukan",
                snackbarColor: "red",
        })      
        return
      });  
    },
    isMerged(item, index) {
    if (index === 0) return false;
    return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    sumField(key) {
      return this.daftarKerugianList?.reduce((a, b) => a + (b[key] || 0), 0)
    },

    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    handleDownloadAttachment(item) {
      this.downloadAttachment({
        base64Data: null,
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    loadDataController() {
      this.getFraudInvProcessDataByFraudId({
        fraudId: this.idFraud,
      }).then((res) => {
        this.dataProsesById = res.data.data;

        //Log
        this.fraudLog = this.dataProsesById.fraudLogList;

        this.jenisPelanggaranListToSave = this.dataProsesById.jenisPelanggaranList;

        //Data Pelapor
        this.anonimFlag = this.dataProsesById.anonimFlag;
        this.reportBy =
          this.dataProsesById.reporterName == null
            ? "-"
            : this.dataProsesById.reporterName;
        this.nik =
          this.dataProsesById.reporterNik == null
            ? "-"
            : this.dataProsesById.reporterNik;
        this.position =
          this.dataProsesById.reporterPosition == null
            ? "-"
            : this.dataProsesById.reporterPosition;
        this.email =
          this.dataProsesById.reporterEmail == null
            ? "-"
            : this.dataProsesById.reporterEmail;
        this.office =
          this.dataProsesById.reporterOffice == null
            ? "-"
            : this.dataProsesById.reporterOffice;
        this.department =
          this.dataProsesById.reporterDepartment == null
            ? "-"
            : this.dataProsesById.reporterDepartment;
        this.superior1 =
          this.dataProsesById.reporterAtasan1Name == null
            ? "-"
            : this.dataProsesById.reporterAtasan1Name;
        this.superior2 =
          this.dataProsesById.reporterAtasan2Name == null
            ? "-"
            : this.dataProsesById.reporterAtasan2Name;
        this.phoneNumber =
          this.dataProsesById.reporterPhone == null
            ? "-"
            : this.dataProsesById.reporterPhone;

        //Detail Laporan
        this.reportType =
          this.dataProsesById.reportType == null
            ? "-"
            : this.dataProsesById.reportType;
        this.jagaNo =
          this.dataProsesById.jagaNo == null ? "-" : this.dataProsesById.jagaNo;
        this.reportDate = moment(this.dataProsesById.reportDate).format(
          "DD-MMM-YYYY"
        );
        this.fraudType =
          this.dataProsesById.fraudTypeName == null
            ? "-"
            : this.dataProsesById.fraudTypeName;
        // this.infoFraud.paramName =  this.dataProsesById.fraudInfName;
        this.fraudInfo =
          this.dataProsesById.fraudInfName == null
            ? "-"
            : this.dataProsesById.fraudInfName;
        this.fraudInfoOther =
          this.dataProsesById.fraudInfReason == null
            ? "-"
            : this.dataProsesById.fraudInfReason;
        this.fraudSuspect = this.dataProsesById.fraudPelakuList;
        this.locationCd =
          this.dataProsesById.fraudLoc == null
            ? "-"
            : this.dataProsesById.fraudLoc;
        this.locationName =
          this.dataProsesById.fraudLocName == null
            ? "-"
            : this.dataProsesById.fraudLocName;
        this.branchCd = `${this.locationCd} - ${this.locationName}`;

        function formatAsCurrency(value, dec) {
          dec = dec || 0;
          if (value === null) {
            return 0;
          }
          return (
            "" + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1. ")
          );
        }

        this.initialLoss = formatAsCurrency(this.dataProsesById.initialLoss);
        this.fraudMethod = this.dataProsesById.fraudMthdList;
        this.attachment = this.dataProsesById.fraudAttList;
        this.sharePoint =
          this.dataProsesById.sharepointLink == null
            ? "-"
            : this.dataProsesById.sharepointLink;

        //Verifikasi Data
        this.verifStatus = this.dataProsesById.verifStatusName;
        console.log("Cek verifStatus: ", this.verifStatus)
        
        this.reportDoc = this.dataProsesById.reportDocTypeList;
        this.spPelaku = formatAsCurrency(this.dataProsesById.spPelaku);
        this.spNasabah = formatAsCurrency(this.dataProsesById.spNasabah);
        this.investigatorManager =
          this.dataProsesById.investigationDelegatorName;
        this.investigatorPhone =
          this.dataProsesById.investigationDelegatorPhone;
        
        // Delegasi Oleh
        this.delegateBy =
          this.dataProsesById.investigationDelegatorPositionName;
        console.log("Cek Delegate Name: ", this.delegateBy)

        this.delegatorPosition = 
          this.dataProsesById.investigationDelegatorPosition;
        console.log("Cek Delegate Position: ", this.delegatorPosition)

        this.eksternalNote = this.dataProsesById.extFraudMthdDesc;
        //Investigasi Fraud
        this.rootCauseApi = this.dataProsesById.rootCauseList;
        this.loadStatusRoot();
        this.investigationStatus = this.dataProsesById.investigationStatusName;
        //this.fraudDetail = this.dataProsesById.fraudDetail;
        this.fraudFactor = this.dataProsesById.fraudFactorName;
        this.fraudFactorOther =
          this.dataProsesById.fraudFactorDesc == null
            ? "-"
            : this.dataProsesById.fraudFactorDesc;
        this.violationType = this.dataProsesById.violationTypeName;
        this.violationTypeOther =
          this.dataProsesById.violationTypeDesc == null
            ? "-"
            : this.dataProsesById.violationTypeDesc;
        this.startDate = moment(this.dataProsesById.fraudCommitedDate).format(
          "DD-MMM-YYYY"
        );
        this.endDate = moment(this.dataProsesById.fraudKnownDate).format(
          "DD-MMM-YYYY"
        );
        this.fraudCommitedDateTo = moment(
          this.dataProsesById.fraudCommitedDateTo
        ).format("DD-MMM-YYYY");
        this.attachmentAfm = this.dataProsesById.fraudAttAFMList;

        let temp = this.dataProsesById.detailLossList;

        for (var i = 0; i < this.detailLoss.length; i++) {
          this.lossList.push([]);
        }

        temp.map((item) => {
          //item.recordFlag = "E";
          let index = this.detailLoss.findIndex(
            (x) => x.paramCd === item.lossType
          );

          item.fraudRepayDate = moment(item.fraudRepayDate).format(
            "DD-MMM-YYYY"
          );

          item.pencairanDate = moment(item.pencairanDate).format("DD-MMM-YYYY");

          item.fpbTransactionDate = moment(item.fpbTransactionDate).format(
            "DD-MMM-YYYY"
          );

          this.lossList[index].push(item);
        });


        //Addition for PIC Request;
        this.reportStatus = this.dataProsesById.reportStatusName;
        this.reportStatusCode = this.dataProsesById.reportStatus;
        //this.picInvestigator = this.dataProsesById.picInvestigatorName;
        this.picApprover = this.dataProsesById.investigationDelegatorName;

        this.picAppDefaultNik = this.dataProsesById.investigationDelegatorNik;

        this.picInvestigatorReturn = this.dataProsesById.picInvestigatorName;
        this.picApproverReturn = this.dataProsesById.picApprName;
        this.picBcReturn = this.dataProsesById.picBcName;
        this.picDhReturn = this.dataProsesById.picDhName;

        this.picInvestigatorNikReturn = this.dataProsesById.picInvestigator;

        this.picApproverNik =
          this.dataProsesById.picAppr == null
            ? this.picAppDefaultNik
            : this.dataProsesById.picAppr;
        this.picBcNik =
          this.dataProsesById.picBc == null ? "-" : this.dataProsesById.picBc;
        this.picDhNik =
          this.dataProsesById.picDh == null ? "-" : this.dataProsesById.picDh;

        this.picAppr = this.dataProsesById.picAppr
        console.log("Cek picAppr: ", this.picAppr)

        this.picInvestigator = this.dataProsesById.picInvestigatorName;
        this.picInvestigatorNik = this.dataProsesById.picInvestigator;

        this.picApprNameReturn = this.dataProsesById.picApprName;

        // PIC Approver Jika Delegasi Oleh SBM Maka Nilai di text-field jadi kosong
        if (this.picAppr !== null) {
          this.picApprReturn = this.dataProsesById.picAppr == null
          ? this.dataProsesById.investigationDelegatorNik
          : this.dataProsesById.picAppr;
          this.picApprName = this.dataProsesById.picApprName == null
          ? this.dataProsesById.investigationDelegatorName
          : this.dataProsesById.picApprName;

          console.log("Cek picAppr Kondisi 1: ", this.picAppr)
          console.log("Cek picApprReturn Kondisi 1: ", this.picApprReturn)
          console.log("Cek picApprName Kondisi 1: ", this.picApprName)
        } else if (this.picAppr === null) {
          if (this.delegateBy == 'SBM') {
            this.picApprReturn = ""
            this.picApprName = ""

            console.log("Cek picApprReturn: ", this.picApprReturn)
            console.log("Cek picApprName: ", this.picApprName)
          } else {
            this.picApprReturn = this.dataProsesById.picAppr == null
            ? this.dataProsesById.investigationDelegatorNik
            : this.dataProsesById.picAppr;
            this.picApprName = this.dataProsesById.picApprName == null
            ? this.dataProsesById.investigationDelegatorName
            : this.dataProsesById.picApprName;

            console.log("Cek picApprReturn: ", this.picApprReturn)
            console.log("Cek picApprName: ", this.picApprName)
          }
          console.log("Cek picAppr Kondisi 2: ", this.picAppr)
        }

        this.picBcNameReturn = this.dataProsesById.picBcName;
        this.picBcReturnNik = this.dataProsesById.picBc;
        this.picDhReturnNik = this.dataProsesById.picDh;

        this.picBcName = this.dataProsesById.picBcName;

        this.picDhName = this.dataProsesById.picDhName;
        this.picDh = this.dataProsesById.picDh;

      });
    },

    loadFraudInfo() {
      this.getParameterByParamType({
        paramType: "FRAUD_INF",
      }).then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.infoFraud = res.data.data;
        }).catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadTujuanFraud() {
      this.getParameterByParamType({
        paramType: "FRAUD_FACTOR",
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
        this.tujuanFraudList = res.data.data;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    loadJenisPelanggaran() {
      this.getParameterByParamType({
        paramType: "VIOLATION_TYPE",
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
        this.jenisPelanggaranList = res.data.data;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },

    handleGetLossList() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.detailLoss = response.data.data;
      });
    },

    openPelakuDialog(isi, fraud) {
      this.isiPelakuList = isi;
      this.netFraudText = fraud;
      this.pelakuFraudDialog = true;
    },
    openDocumentDialog(document) {
      this.isiDocumentList = document;
      this.documentDialog = true;
    },

    handleGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.statusAngsuranList = response.data.data;
      });
    },

    handleGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.transaksiTypeList = response.data.data;
      });
    },

    handleGetPrsCollection() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PRS_COLLECTION",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.prsCollectionList = response.data.data;
      });
    },

    handleGetBrwCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "BRW_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.brwCategoryList = response.data.data;
      });
    },

    handleGetKasSolCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "KAS_SOL_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.kasSolCategoryList = response.data.data;
      });
    },

    handleGetPelakuPencurian() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PELAKU_PENCURIAN",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.pelakuPencurianList = response.data.data;
      });
    },

    loadStatusRoot() {
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      }).then((resp) => {
        let rootCauseStatusa = resp.data.data;
        for (let index = 0; index < rootCauseStatusa.length; index++) {
          this.rootCouse.push({
            createBy: "",
            createDate: "",
            fraudId: this.fraudId,
            lossDocId: 0,
            recordFlag: "",
            rootCause: rootCauseStatusa[index].paramCd,
            rootCauseDesc: "",
            rootCauseList: [],
            rootCauseName: rootCauseStatusa[index].paramName,
            seqNo: 0,
            updateBy: "",
            updateDate: "",
          });

          this.rootCauseStatus.push([]);

          this.getParameterByParamType({
            pageNumber: 0,
            pageSize: 100,
            paramCd: "",
            paramName: "",
            paramType: rootCauseStatusa[index].paramCd,
            paramValue: "",
            searchValue: "",
          }).then((response) => {
            response.data.data.map((item) => {
              item.rootCause = item.paramType;
              delete item.paramType;
              item.rootCauseDesc = item.paramCd;
              delete item.paramCd;
              item.rootCauseName = item.paramName;
              delete item.paramName;
              item.rootCauseDescName = "";
              delete item.paramTypeName;
              delete item.paramValue;
            });
            this.rootCauseStatus.splice(index, 1, response.data.data);

          });
        }

        if (this.rootCauseApi.length != 0) {
          this.rootCauseApi.map((item) => {
            let indexs = this.rootCouse.findIndex(
              (x) => x.rootCause === item.rootCause
            );
            this.rootCouse.splice(indexs, 1, item);
            // console.log('apa isisisis', this.rootCouse[indexs])
            // .push(temp[index]);
          });
        }

      }).catch((err) => {
        this.$helpers.handleError(err);
      });
    },

    loadPicInves() {
      this.getInquiryLovInvestigator({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValuePicInves,
        role: this.delegatorPosition,
        nikManagerInvestigator: this.picAppDefaultNik,
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.inquiryInvestigator = [];
      });
    },

    searchPicInves() {
      this.getEmployeeBySearchValue({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValuePicInves,
      }).then((res) => {
        this.totalData = res.data.data.length;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    pilihPicInves(item) {
      this.dialogPicInves = false;

      this.picInvestigator = item.name;
      this.picInvestigatorNik = item.nik;
    },

    loadPicAppr() {
      this.getInquiryPic({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValuePicAppr,
        role: this.delegatorPosition,
        nikManagerInvestigator: this.picAppDefaultNik,
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.inquiryApprover = [];
      });
    },

    searchPicAppr() {
      this.getEmployeeBySearchValue({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValuePicAppr,
      }).then((res) => {
        this.totalData = res.data.data.length;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    pilihPicAppr(item) {
      this.dialogPicAppr = false;

      this.picApprover = item.name;
      this.picApproverNik = item.nik;

      this.picApprName = item.name;
      this.picAppr = item.nik;
    },

    loadPicBh() {
      this.getInquiryLovPicBc({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValuePicBh,
        nikManagerInvestigator: this.picAppDefaultNik,
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.inquiryBh = [];
      });
    },

    searchPicBh() {
      this.getEmployeeBySearchValue({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValuePicBh,
      }).then((res) => {
        this.totalData = res.data.data.length;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    pilihPicBh(item) {
      this.dialogPicBh = false;

      this.picBcName = item.name;
      this.picBc = item.nik;
      this.picBcNik = item.nik;
    },

    loadPicDh() {
      this.getInquiryLovPicDh({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValuePicDh,
        nikManagerInvestigator: this.picAppDefaultNik,
      }).then((res) => {
        this.totalData = res.data.countData;
        this.countData = res.data.countData;
      }).catch(() => {
        this.$store.state.inquiryDh = [];
      });
    },

    searchPicDh() {
      this.getEmployeeBySearchValue({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValuePicDh,
      }).then((res) => {
        this.totalData = res.data.data.length;
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    pilihPicDh(item) {
      this.dialogPicDh = false;

      this.picDh = item.nik;
      this.picDhNik = item.nik;

      this.picDhName = item.name;
    },

    delegate() {
      this.delegateInvestigator({
        fraudId: this.idFraud,
        picAppr: this.picAppr,
        picBc: this.picBc != null ? this.picBc : this.picBcReturnNik,
        picDh: this.picDh != null ? this.picDh : this.picDhReturnNik,
        picInvestigator:
          this.reportStatusCode == "REPORT_STATUS_RETURN_AFM_MANAGER" ||
            this.reportStatusCode == "REPORT_STATUS_RETURN_QA_OPERATION" ||
            this.reportStatusCode == "REPORT_STATUS_RETURN_QA_MANAGER" ||
            this.reportStatusCode == "REPORT_STATUS_RETURN_BC" ||
            this.reportStatusCode == "REPORT_STATUS_RETURN_DH" ||
            this.reportStatusCode == "REPORT_STATUS_RETURN_AFM_REVIEWER"
            ? this.picInvestigatorNikReturn
            : this.picInvestigatorNik,
      }).then(() => {
        if (this.verifStatus === 'Fraud') {
          return this.createInvHistory({
            fraudId: this.idFraud,
            investigationDelegatorPosition: this.delegatorPosition,
            investigationDelegatorNik: this.picInvestigatorNik,
            picInvestigator: this.picInvestigatorNik
          });
        }
      }).then((response) => {
        this.$helpers.handleSuccess(response);
        this.$router.push(`/investigasi-controller`);
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    requestPicApproval() {
      let delegatorPosition = this.delegatorPosition
      console.log("Cek delegatorPosition: ", delegatorPosition)

      if (delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        this.reportStatusCode = 'REPORT_STATUS_REQUEST_QA_MANAGER'
        console.log("Report Status New: ", this.reportStatusCode)

        this.requestPICApproval({
          fraudId: this.idFraud,
          picAppr: this.picAppr != null ? this.picAppr : this.picApprReturn, //: this.picAppDefaultNik,
          picBc: this.picBc,
          picDh: this.picDh,
          picInvestigator: this.picInvestigatorNik,
          reportStatus: this.reportStatusCode
        }).then((response) => {
            this.$helpers.handleSuccess(response);
            this.$router.push(`/investigasi-controller`);
        }).catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
      } else {
        this.requestPICApproval({
          fraudId: this.idFraud,
          picAppr: this.picAppr != null ? this.picAppr : this.picApprReturn, //: this.picAppDefaultNik,
          picBc: this.picBc,
          picDh: this.picDh,
          picInvestigator: this.picInvestigatorNik,
        }).then((response) => {
            //this.$router.push(`/investigasi-controller/${this.idFraud}/form`);
            this.$helpers.handleSuccess(response);
            // this.approvalBoolean = true;
            this.$router.push(`/investigasi-controller`);
        }).catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
      }
    },

    submitInvestigasiProses() {
      this.submitInvestigation({
        fraudId: this.idFraud,
        picAppr: this.picAppr != null ? this.picAppr : this.picApprReturn,
        picBc: this.picBc,
        picDh: this.picDh,
        picInvestigator: this.picInvestigatorNik,
      }).then((response) => {
        this.$router.push(`/investigasi-controller`);
        this.$helpers.handleSuccess(response);
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    requestBC() {
      if (
        this.picBcNik === "-" ||
        this.picBcNik === "" ||
        this.picBcNik === null
      ) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Pejabat 1 harus diisi",
          snackbarColor: "red",
        });
      } else {
        this.requestPICBC({
          fraudId: this.idFraud,
          picAppr: this.picAppr,
          picBc: this.picBc != null ? this.picBc : this.picBcReturnNik,
          picDh: this.picDh,
          picInvestigator: this.picInvestigatorNik,
        }).then((response) => {
          //this.$router.push(`/investigasi-controller/${this.idFraud}/form`);
          this.$helpers.handleSuccess(response);
          // this.approveBCBoolean = true;
          this.$router.push(`/investigasi-controller`);
        }).catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
      }
    },

    requestDH() {
      this.requestPICDH({
        fraudId: this.idFraud,
        picAppr: this.picAppr,
        picBc: this.picBc != null ? this.picBc : this.picBcReturnNik,
        picDh: this.picDh != null ? this.picDh : this.picDhReturnNik,
        picInvestigator: this.picInvestigatorNik,
      }).then((response) => {
        //this.$router.push(`/investigasi-controller/${this.idFraud}/form`);
        this.$helpers.handleSuccess(response);
        // this.approveDHBoolean = true;
        this.$router.push(`/investigasi-controller`);
      }).catch((err) => {
        if (!err.response) {
          this.loading = false;
        } else {
          switch (err.response.status) {
            default:
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: err.response.data.message,
                snackbarColor: "red",
              });
              this.errorMessages = err.response.data.message;
              break;
          }
        }
      });
    },

    openDialogReturn() {
      this.returnDialog = true
    },

    handleReturnInvestigasi() {
      this.returnInvestigasi({
        fraudId: this.idFraud,
        note: this.notesReturn
      })
        .then(response => {
          this.$helpers.handleSuccess(response);
          this.$router.push(`/investigasi-controller`);
        })
        .catch(error => {
          this.$helpers.handleError(error)
        })
    }
  },

  computed: {
    idFraud: function () {
      return this.$route.params.fraudId;
    },
    allInquiryPicInvestigator() {
      return Object.values(this.$store.getters.allInquiryPicInvestigator);
    },
    allInquiryPicApprover() {
      return Object.values(this.$store.getters.allInquiryPicApprover);
    },
    allInquiryPicBh() {
      return Object.values(this.$store.getters.allInquiryPicBh);
    },
    allInquiryPicDh() {
      return Object.values(this.$store.getters.allInquiryPicDh);
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
  },
};
</script>

<style src="./InvestigasiController.scss" lang="scss" />
