<template>
  <v-navigation-drawer
    app
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <v-list>
      <v-row>
        <v-col cols="12" class="mb-2">
          <!-- <v-btn icon class="mx-1" @click.stop="DRAWER_STATE = !DRAWER_STATE"> -->
          <template v-if="DRAWER_STATE">
            <div class="text-left ml-1">
              <span style="line-height: 28px" class="font-weight-bold"
                >Menu</span
              >
              <v-icon
                style="font-size: 28px"
                @click.stop="DRAWER_STATE = !DRAWER_STATE"
                class="float-right"
                >mdi-close</v-icon
              >
            </div>
          </template>
          <!-- <template  v-if="DRAWER_STATE">
                <v-list-item link>
                  <v-list-item-title class="grey--text">
                    Menu
                  </v-list-item-title>
                  <v-list-item-icon @click.stop="DRAWER_STATE = !DRAWER_STATE">
                    <v-icon style="font-size: 28px">mdi-close</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template> -->
          <template v-else>
            <!-- <v-list-item link>
                  <v-list-item-icon @click.stop="DRAWER_STATE = !DRAWER_STATE">
                    <v-icon style="font-size: 28px">mdi-menu</v-icon>
                  </v-list-item-icon>
                </v-list-item> -->
            <div class="text-center">
              <v-icon
                style="font-size: 28px"
                @click.stop="DRAWER_STATE = !DRAWER_STATE"
                center
                >mdi-menu</v-icon
              >
            </div>
          </template>
          <!-- </v-btn> -->
          <!-- <v-layout justify-center>
              <v-btn
                color="pink"
                dark
                @click.stop="DRAWER_STATE = !DRAWER_STATE"
              >
                Toggle
              </v-btn>
            </v-layout> -->
        </v-col>
        <!-- <v-col cols="12" v-if="DRAWER_STATE">
          <span
            style="padding-left: 32px"
            class="text-body-1 subheader d-flex flex-column"
          > -->
        <!-- <div class="mb-1">ID Anda</div> -->
        <!-- <div class="black--text font-weight-bold">
              {{ this.$store.state.profile.oid }}
            </div> -->
        <!-- </span>
        </v-col> -->
      </v-row>
      <template v-for="(item, i) in items">
        <v-row
          v-if="
            item.heading &&
            (typeof item.hasAccess == 'undefined' || item.hasAccess)
          "
          :key="item.heading"
          align="center"
        >
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          color="primary"
          v-else-if="
            item.subMenus &&
            //DRAWER_STATE &&
            (typeof item.subMenus.hasAccess == 'undefined' ||
              item.subMenus.hasAccess) &&
            item.hasAccess
          "
          :key="item.title"
          v-model="item.model"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap grey--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child, i) in item.subMenus">
            <v-list-item
              v-if="typeof child.hasAccess == 'undefined' || child.hasAccess"
              :key="i"
              :to="child.link === '#' ? null : child.link"
              link
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap grey--text" link>
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else-if="typeof item.hasAccess == 'undefined' || item.hasAccess"
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <!-- <v-list-item-action>
            <v-icon
              size="28"
              :color="item.color ? item.color : ''"
            >{{ item.icon }}</v-icon>
          </v-list-item-action> -->
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap grey--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-row class="mt-10" v-if="DRAWER_STATE">
        <v-col cols="12">
          <v-dialog v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-btn block text color="primary" v-bind="attrs" v-on="on"
                >Sign Out
              </v-btn>
            </template>
            <v-card>
              <div class="pt-5">
              <img
                src="@/assets/Warning.svg"
                alt="logout"
              />
              </div>
              <v-card-title class="headline">
                Yakin untuk Logout?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">
                  Tidak
                </v-btn>
                <v-btn color="green darken-1" text @click="logOut"> Ya </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import config from "../../config";
import { mapState } from "vuex";

export default {
  props: {
    source: String,
  },
  data() {
    return {
      DRAWER_STATE: true,
      dialog: false,
      config,
      items: [
        {
          title: "Dashboard",
          link: "/dashboard",
          icon: "mdi-view-dashboard",
          hasAccess: this.hasFeature("DASHBOARD"),
        },
        {
          title: "Manajemen Pengguna",
          hasAccess: this.hasFeature("MANAGEMENT_PENGGUNA"),
          icon: "mdi-account",
          subMenus: [
            {
              title: "Pengguna",
              link: "/pengguna",
              hasAccess: this.hasFeature("PENGGUNA"),
            },
            {
              title: "Menu",
              link: "/menu",
              hasAccess: this.hasFeature("MENU"),
            },
            {
              title: "Tugas",
              link: "/role",
              hasAccess: this.hasFeature("TUGAS"),
            },
          ],
        },
        {
          title: "Master",
          icon: "mdi-folder",
          hasAccess: this.hasFeature("MASTER"),
          subMenus: [
            {
              title: "Parameter Type",
              link: "/parameter-type",
              hasAccess: this.hasFeature("PARAMETER_TYPE"),
            },
            {
              title: "Parameter",
              link: "/parameter",
              hasAccess: this.hasFeature("PARAMETER"),
            },
            // {
            //   title: "Informasi",
            //   link: "/informasi",
            //   hasAccess: this.hasFeature("Informasi"),
            // },
            // {
            //   title: "Informasi Checker",
            //   link: "/informasi-checker",
            //   hasAccess: this.hasFeature("Informasi Checker"),
            // },
            {
              title: "Hari Libur",
              link: "/master-hari-libur",
              hasAccess: this.hasFeature("HOLIDAY"),
            },
            {
              title: "Karyawan",
              link: "/master-karyawan",
              hasAccess: this.hasFeature("KARYAWAN"),
            },
            {
              title: "Cabang",
              link: "/master-cabang",
              hasAccess: this.hasFeature("CABANG"),
            },
          ],
        },
        {
          title: "JAGA Core",
          icon: "mdi-text-box",
          hasAccess: true,
          subMenus: [
            {
              title: "Pengaduan Fraud",
              link: "/pengaduan-fraud",
            },
            {
              title: "Verifikasi Fraud",
              link: "/verifikasi-data",
              hasAccess: this.hasFeature("VERIFIKASI_FRAUD"),
            },
            {
              title: "Investigasi Controller",
              link: "/investigasi-controller",
              hasAccess: this.hasFeature("INVESTIGASI_CONTROLLER"),
            },
            {
              title: "Investigasi Fraud",
              link: "/investigasi-fraud",
              hasAccess: this.hasFeature("INVESTIGASI_FRAUD"),
            },
            {
              title: "Investigasi Checker",
              link: "/investigasi-checker",
              hasAccess: this.hasFeature("INVESTIGASI_CHECKER"),
            },
            {
              title: "Review Controller",
              link: "/review-controller",
              hasAccess: this.hasFeature("REVIEW_CONTROLLER"),
            },
            {
              title: "Review Fraud",
              link: "/review-fraud",
              hasAccess: this.hasFeature("REVIEW_FRAUD"),
            },
            {
              title: "Komite Fraud",
              link: "/komite-fraud",
              hasAccess: this.hasFeature("KOMITE_FRAUD"),
            },
          ],
        },
        {
          title: "JAGA Complement",
          hasAccess: this.hasFeature("JAGA_COMPLEMENT"),
          icon: "mdi-account",
          subMenus: [
            {
              title: "Recovery & Payment Notes",
              link: "/recovery-and-payment-notes",
              hasAccess: this.hasFeature("RECOVERY"),
            },
            {
              title: "Tambahan Modus & Kerugian",
              link: "/tambah-modus",
              hasAccess: this.hasFeature("TAMBAH_MODUS_KERUGIAN"),
            },
            {
              title: "Dana Talangan",
              link: "/investigasi-frauda",
              hasAccess: this.hasFeature("Dana Talangan"),
            },
            {
              title: "Dana Talangan AFM",
              link: "/investigasi-controllera",
              hasAccess: false,
            },
            {
              title: "Dana Talangan SBOS",
              link: "/investigasi-checkera",
              hasAccess: false,
            },
          ],
        },
        {
          title: "Report",
          hasAccess: this.hasFeature("REPORT"),
          icon: "mdi-file-document",
          subMenus: [
            {
              title: "Monitoring Report",
              link: "/report",
              hasAccess: this.hasFeature("MONITORING_REPORT"),
            }
          ]
        }

        // { title: 'Tables', icon: 'mdi-grid-large', link: '/tables' },
        // { title: 'Notifications', icon: 'mdi-bell-outline', link: '/notifications' },
        // {
        //   title: 'UI Elements',
        //   icon: 'mdi-image-filter-none',
        //   link: '/icons',
        //   model: false,
        //   children: [
        //     { title: 'Icons', icon: 'mdi-circle-small', link: '/icons'},
        //     { title: 'Charts', icon: 'mdi-circle-small', link: '/charts'},
        //     { title: 'Maps', icon: 'mdi-circle-small', link: '/maps'},
        //   ],
        // }
      ],
      sidebarWidth: 270,
      sidebarMinWidth: 80,
    };
  },
  computed: {
    ...mapState(["drawer", "userMenu"]),
    // DRAWER_STATE: {
    //   get() {
    //     return this.drawer;
    //   },
    //   set(newValue) {
    //     // console.log('sampai sini', newValue);
    //     // if (newValue === this.drawer) return;
    //     return newValue;
    //     // this.TOGGLE_DRAWER();
    //   },
    // },
  },
  methods: {
    // ...mapActions(["TOGGLE_DRAWER"]),
    logOut: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
      // .catch((error) => {
      //   if (error.response) {
      //     let response = error.response;
      //     // let data = response.data;
      //     let hasToken = !!response.config.headers.Authorization;
      //     // console.log(response)
      //     switch (response.status) {
      //       case 403:
      //         if (hasToken) {
      //           this.$store.commit("logout");
      //           this.$store.commit("resetCart");
      //           this.$router.push({ name: "home" });
      //         }
      //     }
      //   }
      //   // console.log(err.response);
      //   // let hasToken = !!err.response.config.headers.Authorization;
      //   // switch(err.response.status){
      //   //   case 403 : if (hasToken){
      //   //     this.$store.commit('logout')
      //   //     return this.$router.push('/')
      //   //   }
      //   // }
      // });
    },
  },
};
</script>

<style src="./Sidebar.scss" lang="scss" />
