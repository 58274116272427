var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-form',{ref:"formParameter",attrs:{"lazy-validation":""},model:{value:(_vm.validFormParameter),callback:function ($$v) {_vm.validFormParameter=$$v},expression:"validFormParameter"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"header d-flex mt-4 mb-1",attrs:{"no-gutters":""}},[_c('p',{staticClass:"titlet"},[_vm._v("Parameter")]),_c('span',{staticClass:"titlet"}),(_vm.isEdit)?_c('p',{staticClass:"subTitle"},[_vm._v("Edit")]):_c('p',{staticClass:"subTitle"},[_vm._v("Tambah Parameter")])]),_c('v-col',{attrs:{"cols":"12","md":"18"}},[_c('div',{staticClass:"top"},[_c('v-card',{attrs:{"cols":"12"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"verifStat ml-0"},[_c('p',{staticClass:"label-text-field ml-0"},[_vm._v("Tipe Parameter")]),_c('span',{staticClass:"ml-2 mt-1"},[_vm._v(" Wajib ")])]),_c('v-select',{staticClass:"rp mb-0",attrs:{"items":_vm.allInquiryParameterType,"item-text":"parameterTypeName","item-value":"parameterTypeCode","label":"","hide-details":"auto","outlined":"","dense":"","clearable":"","placeholder":"Masukan tipe parameter","rules":[
                      function (v) { return !!v || 'tipe parameter harus diisi'; } ]},model:{value:(_vm.parameterTypeCode),callback:function ($$v) {_vm.parameterTypeCode=$$v},expression:"parameterTypeCode"}})],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-row',{staticClass:"verifStat ml-0"},[_c('p',{staticClass:"label-text-field ml-0"},[_vm._v("Kode Parameter")]),_c('span',{staticClass:"ml-2 mt-1"},[_vm._v(" Wajib ")])]),_c('v-text-field',{class:_vm.isEdit ? 'dl mb-0' : 'rp mb-0',attrs:{"hide-details":"auto","outlined":"","dense":"","placeholder":"Masukan kode parameter","readonly":_vm.isEdit,"rules":[
                      function (v) { return !!v || 'kode parameter harus diisi'; },
                      function (v) { return (v && v.length <= 64) || 'kode parameter must be less than 64 characters'; }
                    ]},on:{"keypress":_vm.noChar,"paste":_vm.pasteChar},model:{value:(_vm.parameterCode),callback:function ($$v) {_vm.parameterCode=$$v},expression:"parameterCode"}})],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-row',{staticClass:"verifStat ml-0"},[_c('p',{staticClass:"label-text-field ml-0"},[_vm._v("Nama Parameter")]),_c('span',{staticClass:"ml-2 mt-1"},[_vm._v(" Wajib ")])]),_c('v-text-field',{staticClass:"rp mb-0",attrs:{"hide-details":"auto","outlined":"","dense":"","placeholder":"Masukan nama parameter","rules":[
                      function (v) { return !!v || 'nama parameter harus diisi'; },
                      function (v) { return (v && v.length <= 512) || 'nama parameter must be less than 512 characters'; }
                    ]},on:{"keypress":_vm.noChar,"paste":_vm.pasteChar},model:{value:(_vm.parameterName),callback:function ($$v) {_vm.parameterName=$$v},expression:"parameterName"}})],1),_c('v-col',{attrs:{"md":"12","cols":"12"}},[_c('v-row',{staticClass:"verifStat ml-0"},[_c('p',{staticClass:"label-text-field ml-0"},[_vm._v("Nilai Parameter")])]),_c('v-text-field',{staticClass:"rp mb-0",attrs:{"hide-details":"auto","outlined":"","dense":"","placeholder":"Masukan nilai parameter"},on:{"keypress":_vm.noCharSpecial,"paste":_vm.pasteCharSpecial},model:{value:(_vm.parameterValue),callback:function ($$v) {_vm.parameterValue=$$v},expression:"parameterValue"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"elevation-0 text-capitalize mr-3 primary--text",attrs:{"to":"/parameter","x-large":"","depressed":"","outlined":""}},[_c('span',{staticClass:"primary--text"},[_vm._v("Cancel")])]),(_vm.isEdit)?_c('v-btn',{attrs:{"x-large":"","depressed":"","color":"primary"},on:{"click":_vm.editParameter}},[_c('span',{staticClass:"white--text"},[_vm._v("Save")])]):_c('v-btn',{attrs:{"x-large":"","depressed":"","color":"primary"},on:{"click":_vm.addParameter}},[_c('span',{staticClass:"white--text"},[_vm._v("Save")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }