<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Tugas</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Role Name</p>
        <v-text-field
          outlined
          class="rp mb-0"
          dense
          placeholder="role name"
          prepend-inner-icon="mdi-magnify"
          v-model="searchValue"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-btn
            @click="search"
            class="elevation-0 text-capitalize ml-5 mt-6"
            color="primary"
          >
            <span style="color: white">Cari</span>
          </v-btn>
          <v-btn
            @click="reset"
            class="elevation-0 text-capitalize ml-3 mt-6"
            color="primary"
            outlined
          >
            Reset
          </v-btn>
        </v-row>
      </v-col>

      <v-col class="text-right">
        <!-- <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-15 mt-6"
          color="primary"
          :to="`/role/form/add`"
        >
          <v-icon aria-hidden="false" color="white"> mdi-plus </v-icon>
          <span style="color: white">Tambah</span>
        </v-btn> -->
        <v-btn class="elevation-0 mt-6" color="primary" :to="`/role/form/add`">
          <v-icon left>
            mdi-plus
          </v-icon>
          Tambah
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-10">
      <v-row>
        <v-data-table
          :headers="headers"
          :items="allInquiryRole"
          :items-per-page="5"
          :header-props="{ sortIcon: null }"
          style="width: 100%"
          disable-sort
          mobile-breakpoint="0"
        >
          <template v-slot:item="allRole">
            <tr>
              <td>{{ allRole.item.roleName }}</td>
              <td align="center">
                {{ allRole.item.Action }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      text
                      class="text-capitalize black--text px-0"
                      v-on="on"
                      v-bind="attrs"
                      :to="`/role/${allRole.item.roleId}/form/edit`"
                    >
                      <v-icon aria-hidden="false" outlined color="primary">
                        mdi-square-edit-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-dialog
                  v-model="dialogDel[allRole.item.roleId]"
                  persistent
                  :key="allRole.item.roleId"
                  width="unset"
                >
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom slot="activator">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          small
                          text
                          class="text-capitalize black--text px-0"
                          v-on="{ ...dialog, tooltip }"
                          v-bind="attrs"
                        >
                          <v-icon aria-hidden="false" outlined color="primary">
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                  <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[
                                      allRole.item.roleId
                                    ] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Tugas?
                                  <p>
                                    {{ allRole.item.roleName }}
                                  </p>
                                </div>
                                <v-btn
                                  class="paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(dialogDel, allRole.item.roleId, false)
                                  "
                                  >Batalkan</v-btn
                                >
                                <v-btn
                                  class="paramDialogDelBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeRole(allRole.item.roleId),
                                      $set(dialogDel, allRole.item.roleId, false)
                                  "
                                  >Hapus</v-btn
                                >
                              </v-sheet>
                            </div>
                  <!-- <div class="text-center">
                    <v-sheet
                      class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
                      color="blue-grey daren-3"
                    >
                      <div class="grey--text text--lighten-1 text-body-2 mb-4">
                        Yakin untuk menghapus
                        {{ allRole.item.name }} ?
                      </div>

                      <v-btn
                        class="ma-1"
                        color="primary"
                        text
                        @click="$set(dialogDel, allRole.item.roleId, false)"
                      >
                        Tidak
                      </v-btn>

                      <v-btn
                        class="ma-1"
                        color="primary"
                        text
                        @click="
                          removeRole(allRole.item.roleId),
                            $set(dialogDel, allRole.item.roleId, false)
                        "
                      >
                        Yes
                      </v-btn>
                    </v-sheet>
                  </div> -->
                </v-dialog>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      link: "",
      deletedFlag: false,
      // roleName: "",

      items: [],
      headers: [
        {
          text: "Reponsibility",
          align: "center",
          value: "roleName",
          width: "150px",
          class: "font-weight-bold subtitle-1 font-family-roboto",
          // class: "primary black--text",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "100px",
          class: "font-weight-bold subtitle-1 font-family-roboto",

          // class: "primary black--text",
        },
      ],

      statusSearch: false,
      // allInquiryRole: [],
      // roleNameSearch: "",
      searchValueSearch: "",
      searchValue: "",
    };
  },
  async mounted() {
    this.load();
    // console.log("this.items" + this.items);
  },
  created() {
    this.requiredFeature('TUGAS')
    this.changeTitleApp("Master Role");
  },
  methods: {
    ...mapActions(["changeTitleApp", "getInquiryRole"]),
    load() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = this.searchValue;
      this.$store
        .dispatch("getInquiryRole", { pageNumber, pageSize, searchValue })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryRole = [];
          //   let pageNumber = 0;
          //   let pageSize = this.perPage;
          //   let roleName = this.roleName;
          //   this.$store.dispatch("getInquiryRole", { pageNumber, pageSize, roleName})
          //   .then((res) => {
          //       this.totalData = res.data.countData;
          //       this.countData = res.data.countData;
          //   })
          //   .catch((err) => {
          //       console.log(err);
          //       this.$store.state.allInquiryRole = [];
          //   });
          //   this.getInquiryRole({
          //       pageNumber: 0,
          //       pageSize: 166,
          //       searchValue: null,
          //   })
          //     .then((res) => {
          //         this.allInquiryRole = res.data.data;
          //         console.log(this.allInquiryRole);
          //         this.totalData = res.data.countData;
          //         this.countData = res.data.countData;

          //        })
          //     .catch((err) => {
          //         console.log("error " + err);
          //         this.$store.state.inquiryRole = [];

          //   //     });
        });
    },

    handlePageChange(value) {
      this.$store.dispatch("getInquiryRole", {
        pageNumber: value - 1,
        pageSize: this.perPage,
        // roleName: this.roleNameSearch ? this.roleNameSearch : this.roleName,
        searchValue: this.searchValueSearch
          ? this.searchValue
          : this.searchValue,
      });
    },
    search() {
      // let searchValue = this.roleName;
      // this.roleNameSearch = this.roleName;
      let pageNumber = 0;
      let pageSize = this.countData;
      let searchValue = this.searchValue;
      this.searchValueSearch = this.searchValue.trim();
      this.$store
        .dispatch("getInquiryRole", {
          pageNumber,
          pageSize,
          searchValue,
        })
        .then((res) => {
          // this.allInquiryRole = res.data.data;
          this.totalData = res.data.data.length;
          if (this.searchValue == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryRole = [];
        });
      this.page = 1;
    },

    reset: function () {
      this.searchValue = "";
      this.load();
    },

    removeRole(id) {
      let roleId = id;
      this.$store
        .dispatch("deleteRole", { roleId })
        .then((response) => {
          this.load();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
  computed: {
    ...mapState(["roleName"]),
    allInquiryRole() {
      return Object.values(this.$store.getters.allInquiryRole);
    },
  },
};
</script>

<style src="./Role.scss" lang="scss"/>
