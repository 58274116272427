<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Recovery & Payment Notes</h1>
    </v-row>
    <v-row class="d-flex justify-space-between mt-4 mb-1">
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">No. Tiket Jaga</p>
        <v-text-field
          v-model="jagaNo"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari No. Tiket JAGA"
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Nama MMS</p>
        <v-text-field
          v-model="fraudLocName"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">
          NIK
        </p>
        <!-- <v-menu
          ref="reportDateFromMenu"
          v-model="reportDateFromMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateFrom"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder="01/01/2022"
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateFromVal"
            no-title
            scrollable
            @input="pickReportDateFrom"
          >
          </v-date-picker>
        </v-menu> -->
         <v-text-field
          v-model="nik"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" md="3">
        <h7 class="label-text-field transparent--text"> "" </h7>
        <v-menu
          ref="reportDateToMenu"
          v-model="reportDateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateTo"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder="01/01/2022"
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateToVal"
            no-title
            scrollable
            @input="pickReportDateTo"
          >
          </v-date-picker> </v-menu
      ></v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Kode MMS </h7>
        <v-text-field
          v-model="fraudLoc"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
        <!-- <v-select
          v-model="fraudType"
          :items="fraudTypeItems"
          item-text="paramName"
          item-value="paramCd"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          style="background-color: #ffffff"
        ></v-select> -->
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          v-model="reportStatus"
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
          label=""
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          clearable
          placeholder="Semua"
          @click="loadReportStatus"
          style="background-color: #ffffff"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <p
          class="elevation-0 text-capitalize white--text font-weight-black"
          color="primary"
        ></p>
        <v-row>
          <v-btn
            class="search mt-1"
            @click="searchRecoveryPayment"
            cols="12"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #f5821f;
              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
              border-radius: 8px;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="search">Cari</span></v-btn
          >
          <v-btn
            class="resetBtn ml-0 mt-1"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 8px;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="resetBtn">Reset</span></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pi">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersRecoveryPayment"
                    :items="inquiryRecoveryPayment"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item="allRecoveryPayment">
                      <tr>
                        <td align="center" class="orange--text">
                          {{ allRecoveryPayment.item.jagaNo }}
                        </td>
                        <td align="center">
                          {{ allRecoveryPayment.item.fraudLoc }}
                        </td>
                        <td align="center">
                          {{ allRecoveryPayment.item.fraudLocName }}
                        </td>
                        <td align="center">
                          <div v-for="items in allRecoveryPayment.item.fraudPelakuList" :key="items">
                          {{ items.pelakuNik }}
                          </div>
                        </td>
                        <td align="center">
                          <div v-for="items in allRecoveryPayment.item.fraudPelakuList" :key="items">
                          {{ items.pelakuName }}
                          </div>
                        </td>
                        <td align="center">
                          <v-chip
                            class="ma-2"
                            color="orange lighten-4"
                            text-color="orange"
                          >
                            {{ allRecoveryPayment.item.reportStatusName }}
                          </v-chip>
                        </td>
                        <td align="center">
                          Rp. {{ humanFormat(allRecoveryPayment.item.totalKerugianAwal) }}
                        </td>
                        <td align="center">
                          Rp. {{ humanFormat(allRecoveryPayment.item.totalRecovery) }}
                        </td>
                        <td align="center">
                          Rp. {{ humanFormat(allRecoveryPayment.item.totalNetFraud) }}
                        </td>
                        <td align="center" v-if="reportStatus != 'REPORT_STATUS_CLOSED'">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/recovery-and-payment-notes/${allRecoveryPayment.item.fraudId}/form`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-share-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Proses</span>
                          </v-tooltip>
                          <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/recovery-and-payment-notes/${allRecoveryPayment.item.fraudId}/form`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip> -->
                        </td>
                        <td v-else>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      nik: '',
      fraudLocName: '',
      kodeMms: '',
      reportDateFromMenu: false,
      //reportDateToMenu: false,
      reportDate: "",
      reportDateFrom: "",
      //reportDateTo: "",
      reportDateFromVal: null,
      //reportDateToVal: null,
      jagaNo: "",
      fraudLoc: "", // NamaMMS 
      fraudType: "",
      reportStatus: "",
      fraudTypeItems: [],
      infoStatus: [],
      inquiryRecovery: [],
      headersRecoveryPayment: [
        {
          text: "No. Tiket Jaga",
          align: "center",
          width: "200px",
          class: "black--label-text-field",
        },
        {
          text: "Kode MMS",
          width: "210px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Nama MMS",
          width: "200px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "NIK Pelaku",
          width: "120px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Nama Pelaku",
          width: "200px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Status",
          width: "160px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Total Kerugian Awal",
          width: "160px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Total Recovery",
          width: "160px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Net Fraud",
          width: "160px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "150px",
          class: "black--label-text-field",
          align: "center",
        },
      ],
    };
  },

  created() {
    this.requiredFeature('RECOVERY')
    this.changeTitleApp("Recovery & Payment Notes");
  },

  async mounted() {
    this.load();
    this.loadFraudType();
    this.loadReportStatus();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getParameterByParamType",
      "searchDataRecoveryPayment",
      // "searchDataInvestigasiProcess",
    ]),

    reset: function () {
      this.jagaNo = "";
      this.fraudLoc = ""; //Nama MMS
      this.fraudLocName = "";
      this.nik = "";
      this.reportDateFrom = "";
      this.reportDateTo = "";
      this.reportDateFromVal = "";
      this.reportDateToVal = "";
      this.fraudType = "";
      this.reportStatus = "";
      this.load();
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format(
        "DD-MMM-YYYY"
      );
    },

    // pickReportDateTo() {
    //   this.reportDateToMenu = false;
    //   this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    // },

    loadFraudType() {
      this.getParameterByParamType({
        paramType: "FRAUD_TYPE",
      })
        .then((resp) => {
          this.fraudTypeItems = resp.data.data;
        })
        .catch((err) => {
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) =>
              item.paramCd === "REPORT_STATUS_KF_DONE" ||
              item.paramCd === "REPORT_STATUS_IN_SANCTION" ||
              item.paramCd === "REPORT_STATUS_CLOSED"
          );
        })
        .catch((err) => {
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    load() {
      this.searchDataRecoveryPayment({
        pageNumber: 0,
        pageSize: 1000,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.inquiryRecoveryPayment.map((item) => {
            item.investigationEndDate == "" || item.investigationEndDate == null
              ? item.investigationEndDate = "" 
              : (item.investigationEndDate = moment(
                  item.investigationEndDate, "YYYY-MM-DD"
                ).format("DD-MMM-YYYY"));
          });
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    searchRecoveryPayment() {
      this.searchDataRecoveryPayment({
        pageNumber: 0,
        pageSize: 1000,
        jagaNo: this.jagaNo.trim(),
        investigationEndDt: this.reportDateFromVal,
        //reportDateFrom: this.reportDateFromVal,
        //reportDateTo: this.reportDateToVal,
        fraudType: this.fraudType,
        fraudLoc: this.fraudLoc.trim(), //Nama MMS
        reportStatus: this.reportStatus,
        pelakuNik: this.nik,
        fraudLocName: this.fraudLocName
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.inquiryRecovery = res.data.data;

          this.inquiryRecoveryPayment.map((item) => {
            item.investigationEndDate == "" || item.investigationEndDate == null
              ? item.investigationEndDate = "" 
              : (item.investigationEndDate = moment(
                  item.investigationEndDate, "YYYY-MM-DD"
                ).format("DD-MMM-YYYY"));
          });
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },
  },

  computed: {
    inquiryRecoveryPayment() {
      return Object.values(this.$store.getters.inquiryRecoveryPayment);
    },
  },
};
</script>

<style src="./RecoveryAndPaymentNotes.scss" lang="scss" />
