<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Pengaduan Fraud</h1>
    </v-row>
    <v-row class="d-flex mt-4">
      <v-col cols="12" md="3">
        <h7 class="label-text-field" color="primary"> No. Referensi </h7>
        <v-text-field
          v-model="noRef"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari no. referensi"
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <h7 class="label-text-field" color="primary"> Lokasi </h7>
        <v-text-field
          v-model="location"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari lokasi kejadian"
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <h7 class="label-text-field" color="primary"> Tanggal Laporan </h7>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inputStartDate"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder=""
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="inputStartDateVal"
            no-title
            scrollable
            @input="formatStartDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <h7 class="label-text-field transparent--text" color="primary"> "" </h7>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inputEndDate"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder=""
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="inputEndDateVal"
            no-title
            scrollable
            @input="formatEndDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          :items="parameterByType"
          item-text="paramName"
          item-value="paramCd"
          label=""
          class="mb-0"
          hide-details="auto"
          v-model="status"
          outlined
          dense
          clearable
          placeholder="Semua"
          @click="loadReportStatus"
          style="background-color: #ffffff"
        ></v-select>
      </v-col>
      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn
            @click="searchFraud"
            class="search mt-1"
            cols="12"
            color="primary"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #f5821f;
              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
              border-radius: 8px;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="resetBtn ml-0 mt-1"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 8px;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="orange--text">Reset</span></v-btn
          >
        </v-row>
      </v-col>
      <v-col class="text-right">
        <p class="transparent--text" color="primary"></p>
        <v-btn
          class="elevation-0 text-capitalize mr-3 mt-1"
          color="primary"
          :to="`/pengaduan-fraud/form/add`"
          depressed
          ><v-icon aria-hidden="false" color="white"> mdi-plus </v-icon
          ><span class="white--text">Buat Pengaduan</span></v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="18">
        <div class="pf">
          <v-card cols="12">
            <v-card-text>
              <v-row>
                <!-- <v-col cols="12" v-if="allInquiryFraud.length == 0">
                  <img
                    src="@/assets/Group.svg"
                    alt=""
                    style="
                      width: 497.53px;
                      height: 326px;
                      left: 1.63px;
                      top: 52.8px;
                      margin-left: 220px;
                    "
                  />
                  <p
                    style="
                      position: static;
                      justify-content: center;
                      left: 10.9%;
                      right: 10.9%;
                      top: 93.07%;
                      bottom: 0%;
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 24px;
                      line-height: 32px;
                      display: flex;
                      align-items: center;
                      color: #a8b5cb;
                      flex: none;
                      order: 1;
                      flex-grow: 0;
                      margin: 0px 0px;
                    "
                  >
                    Opps... Belum ada pengaduan nih!
                  </p>
                  <v-btn
                    cols="12"
                    :to="`/pengaduan-fraud/form/add`"
                    class="add"
                    style="
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      padding: 12px;
                      width: 158px;
                      height: 48px;
                      background: #f5821f;
                      box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                      border-radius: 8px;
                      flex: none;
                      order: 1;
                      flex-grow: 0;
                      margin: 32px 0px;
                      margin-left: 420px;
                    "
                    ><v-icon class="add"> mdi-plus </v-icon
                    ><span class="add">Buat Pengaduan</span></v-btn
                  >
                </v-col> -->
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="allInquiryFraud"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                    ><template v-slot:item="allFraud">
                      <tr>
                        <td class="orange--text">
                          {{ allFraud.item.refNo }}
                        </td>
                        <td>{{ allFraud.item.reportDateStr }}</td>
                        <td>{{ allFraud.item.fraudLocName }}</td>
                        <td>{{ allFraud.item.fraudTypeName }}</td>
                        <td>
                          <v-chip
                            class="ma-2"
                            color="orange lighten-4"
                            text-color="orange"
                          >
                            {{ allFraud.item.reportStatusName }}
                          </v-chip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      noRef: "",
      menu1: false,
      menu2: false,
      inputStartDate: "",
      inputStartDateVal: null,
      inputEndDate: "",
      inputEndDateVal: null,
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      currentDateMinusOneDay: new Date(Date.now() - 86400000)
        .toISOString()
        .substring(0, 10),
      location: "",
      status:"",
      items: [],
      headers: [
        {
          text: "No. Referensi",
          align: "center",
          width: "170px",
        },
        {
          text: "Tanggal Laporan",
          align: "center",
          width: "130px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "180px",
        },
        {
          text: "Jenis Fraud",
          align: "center",
          width: "120px",
        },
        {
          text: "Status",
          align: "center",
          width: "180px",
        },
      ],
      statusSearch: false,
      searchValueSearch: "",
      searchValue: "",
    };
  },
  async mounted() {
    this.load();
    this.loadReportStatus();
  },
  created() {
    this.changeTitleApp("Pengaduan Fraud");
  },
  methods: {
    ...mapActions(["changeTitleApp"]),

    reset: function () {
      this.noRef = "";
      this.inputStartDate = "";
      this.inputEndDate = "";
      this.inputStartDateVal = "";
      this.inputEndDateVal = "";
      this.location = "";
      this.status = "";
      this.load();
    },

    formatStartDate() {
      this.menu1 = false;
      this.inputStartDate = moment(this.inputStartDateVal).format("DD-MMM-YYYY");
    },

    formatEndDate() {
      this.menu2 = false;
      this.inputEndDate = moment(this.inputEndDateVal).format("DD-MMM-YYYY");
    },

    load() {
      let pageNumber = 0;
      let pageSize = 1000;
      this.$store
        .dispatch("getInquiryFraud", { pageNumber, pageSize })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadReportStatus() {
      let paramType = "REPORT_STATUS";
      this.$store
        .dispatch("getParameterByParamType", { paramType })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    searchFraud() {
      let pageNumber = 0;
      let pageSize = 1000;
      let refNo = this.noRef;
      let reportDateFrom = this.inputStartDateVal;
      let reportDateTo = this.inputEndDateVal;
      let fraudLoc = this.location;
      let reportStatus = this.status;
      this.$store
        .dispatch("getInquiryFraud", {
          refNo,
          pageNumber,
          pageSize,
          reportDateFrom,
          reportDateTo,
          fraudLoc,
          reportStatus,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (
            this.refNo == "" ||
            this.inputStartDateVal == "" ||
            this.inputEndDateVal == "" ||
            this.location == "" ||
            this.status == ""
          ) {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryFraud = [];
        });
      this.page = 1;
    },
  },

  computed: {
    allInquiryFraud() {
      return Object.values(this.$store.getters.allInquiryFraud);
    },
    parameterByType() {
      return Object.values(this.$store.getters.parameterByType);
    },
  },
};
</script>

<style src="./PengaduanFraud.scss" lang="scss" />