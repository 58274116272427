<template>
  <v-container fluid>
    <v-row>
      <v-col md="4">
        <v-text-field
          label="Search"
          class="mb-0"
          hide-details="auto"
          v-model="searchParamTxt"
          outlined
          placeholder=" "
        ></v-text-field>        
      </v-col>      
      <v-col>
        <v-btn
          @click="search"
          class="elevation-0 text-capitalize"
          color="primary"
          >Search</v-btn>        
        <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-1"
          color="primary"
          @click="reset"
        >Reset</v-btn>       
      </v-col>
      <v-col class="text-right">
         <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-15"
          color="primary"
          :to="`/informasi/form/add`"
        >Tambah</v-btn>        
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>
import {mapActions } from "vuex";
//import { mapActions, mapGetters, mapState } from "vuex";
//import moment from "moment";
//var dateFormat = require("dateformat");

export default { 
   async mounted() {
    // console.log("ASDAS", this.branchCode);
    // this.load();
  },
  created() {
    //this.requiredFeature('MST_PARAMETER')
    this.changeTitleApp("Informasi");
  }, 
  methods: {
      ...mapActions(["changeTitleApp"]),
  },  
  handlePageChange(value) {
      this.$store.dispatch("getInquiryInformasi", {
        pageNumber: value - 1,
        pageSize: this.perPage,
        searchText: this.searchText,
      })
    // }
  },
  load() {
  }, 
};
</script>

<style src="./Informasi.scss" lang="scss" />
