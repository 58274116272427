export default {
  light: {
    // primary: "#536DFE",
    // primary: "#FFDD00",
    // secondary: "#190775",
    // error: "#FF4081",
    // warning: "#FFC260",
    // success: "#3CD4A0",
    // info: "#9013FE",
    // textColor: "#B9B9B9",
    // iconColor: "#FFFFFF",
    // grayBold: '#4a4a4a'
    primary: "#F5821F",
    gray: "#d4d4d3",
    white: "#ffffff",
    secondary: "#F5821F",
    error: "#FF4081",
    warning: "#FFC260",
    success: "#4caf50",
    info: "#9013FE",
    textColor: "#B9B9B9",
    iconColor: "#FFFFFF",
    grayBold: '#4a4a4a'
  }
};

