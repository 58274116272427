<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Komite Fraud</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">No. LHI</p>
        <v-text-field
          v-model="jagaNo"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari No. LHI"
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Lokasi Kejadian</p>
        <v-text-field
          v-model="fraudLoc"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari lokasi kejadian"
          label=""
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">
          Tanggal Selesai Investigasi
        </p>
        <v-menu
          ref="reportEndDateMenu"
          v-model="reportEndDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportEndDate"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder="01-Jan-2022"
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportEndDateVal"
            no-title
            scrollable
            @input="pickReportEndDate"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-col cols="12" md="3">
        <h7 class="label-text-field transparent--text"> "" </h7>
        <v-menu
          ref="reportDateToMenu"
          v-model="reportDateToMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="reportDateTo"
              label=""
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              readonly
              outlined
              dense
              hide-details="auto"
              class="mb-0"
              placeholder="01/01/2022"
              style="background-color: #ffffff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportDateToVal"
            no-title
            scrollable
            @input="pickReportDateTo"
          >
          </v-date-picker> </v-menu
      ></v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Jenis Fraud </h7>
        <v-select
          v-model="fraudType"
          :items="fraudTypeItems"
          item-text="paramName"
          item-value="paramCd"
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          style="background-color: #ffffff"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          v-model="reportStatus"
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
          label=""
          class="mb-0"
          hide-details="auto"
          outlined
          dense
          clearable
          placeholder="Semua"
          @click="loadReportStatus"
          style="background-color: #ffffff"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <p
          class="elevation-0 text-capitalize white--text font-weight-black"
          color="primary"
        ></p>
        <v-row>
          <v-btn
            class="search mt-1"
            @click="searchKomiteFraud"
            cols="12"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #f5821f;
              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
              border-radius: 8px;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="search">Cari</span></v-btn
          >
          <v-btn
            class="resetBtn ml-0 mt-1"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
            style="
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 12px;
              width: 70px;
              height: 40px;
              background: #ffffff;
              box-sizing: border-box;
              border-radius: 8px;
              flex: none;
              flex-grow: 0;
              margin: 0px 12px;
            "
          >
            <span class="resetBtn">Reset</span></v-btn
          >
          <div>
            <v-tooltip bottom slot="activator">
              <template v-slot:activator="{ on: tooltip }">
                <v-row>
                  <v-btn
                    small
                    text
                    class="text-capitalize black--text px-0 mt-2 ml-8 mr-8"
                    v-on="{ ...dialog, ...tooltip }"
                    v-bind="attrs"
                    :loading="isSelecting"
                    @click="downloadFile"
                  >
                    <v-icon
                      aria-hidden="false"
                      style="font-size: 24px"
                      color="#F5821F"
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                    <p class="label-text-field mt-1 ml-2 primary--text">
                      Download
                    </p>
                  </v-btn>
                </v-row>
              </template>
              <span>Download</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pi">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersKomiteFraud"
                    :items="inquiryKomiteFraud"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item="allKomiteFraud">
                      <tr>
                        <td align="center" class="orange--text">
                          {{ allKomiteFraud.item.jagaNo }}
                        </td>
                        <td align="center">
                          {{ allKomiteFraud.item.investigationEndDate }}
                        </td>
                        <td align="center">
                          {{ allKomiteFraud.item.fraudTypeName }}
                        </td>
                        <td align="center">
                          {{ allKomiteFraud.item.fraudLocName }}
                        </td>
                        <td align="center">
                          <v-chip
                            class="ma-2"
                            color="orange lighten-4"
                            text-color="orange"
                          >
                            {{ allKomiteFraud.item.reportStatusName }}
                          </v-chip>
                        </td>
                        <td align="center">
                          <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/komite-fraud/${allKomiteFraud.item.fraudId}/form/edit`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip> -->
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/komite-fraud/${allKomiteFraud.item.fraudId}/form/proses`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-share-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Proses</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      jagaNo: "",
      fraudLoc: "",
      reportDate: "",
      reportEndDateMenu: false,
      reportEndDate: "",
      reportEndDateVal: null,
      //reportDateToMenu: false,
      // reportDateTo: "",
      //reportDateToVal: null,
      fraudType: "",
      reportStatus: "",

      fraudTypeItems: [],
      infoStatus: [],
      headersKomiteFraud: [
        {
          text: "No. LHI",
          align: "center",
          width: "180px",
          class: "black--label-text-field",
        },
        {
          text: "Tanggal Selesai Investigasi",
          width: "210px",
          class: "black--label-text-field",
          align: "center",
        },

        {
          text: "Jenis Fraud",
          width: "120px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Lokasi Kejadian",
          width: "200px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Status",
          width: "160px",
          class: "black--label-text-field",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "120px",
          class: "black--label-text-field",
          align: "center",
        },
      ],
    };
  },

  created() {
    this.requiredFeature('KOMITE_FRAUD')
    this.changeTitleApp("Komite Fraud");
  },

  async mounted() {
    this.load();
    this.loadFraudType();
    this.loadReportStatus();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getParameterByParamType",
      "searchDataKomiteFraud",
      "downloadKomiteFraud",
    ]),

    reset: function () {
      this.jagaNo = "";
      this.fraudLoc = "";
      this.reportEndDate = "";
      this.reportEndDateVal = "";
      this.fraudType = "";
      this.reportStatus = "";
      this.load();
    },

    pickReportEndDate() {
      this.reportEndDateMenu = false;
      this.reportEndDate = moment(this.reportEndDateVal).format("DD-MMM-YYYY");
    },

    loadFraudType() {
      this.getParameterByParamType({
        paramType: "FRAUD_TYPE",
      })
        .then((resp) => {
          this.fraudTypeItems = resp.data.data;
        })
        .catch((err) => {
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) =>
              item.paramCd === "REPORT_STATUS_KF_DONE" ||
              item.paramCd === "REPORT_STATUS_IN_SANCTION" ||
              item.paramCd === "REPORT_STATUS_CLOSED" ||
              item.paramCd === "REPORT_STATUS_KF_OUTSTANDING" ||
              item.paramCd === "REPORT_STATUS_REVIEWED"
          );
        })
        .catch((err) => {
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    load() {
      this.searchDataKomiteFraud({
        pageNumber: 0,
        pageSize: 1000,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.inquiryKomiteFraud.map((item) => {
            item.investigationEndDate == "" || item.investigationEndDate == null
              ? item.investigationEndDate = "" 
              : (item.investigationEndDate = moment(
                  item.investigationEndDate, "YYYY-MM-DD"
                ).format("DD-MMM-YYYY"));
          });
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    searchKomiteFraud() {
      this.searchDataKomiteFraud({
        pageNumber: 0,
        pageSize: 1000,
        jagaNo: this.jagaNo.trim(),
        investigationEndDt: this.reportEndDateVal,
        fraudType: this.fraudType,
        fraudLoc: this.fraudLoc.trim(),
        reportStatus: this.reportStatus,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
          this.inquiryKomiteFraud.map((item) => {
            item.investigationEndDate == "" || item.investigationEndDate == null
              ? item.investigationEndDate = "" 
              : (item.investigationEndDate = moment(
                  item.investigationEndDate, "YYYY-MM-DD"
                ).format("DD-MMM-YYYY"));
          });
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    downloadFile() {
      if(this.reportStatus) {
        this.downloadKomiteFraud({
          status: this.reportStatus,
        })
          .then((response) => {
            console.log("response oke", response);
          })
          .catch((err) => {
            this.$helpers.handleError(err);
            console.log("gagal download", err.response);
          });
      } else {
        this.$store.commit("setShowSnackbar", {
                      snackbarMsg: "Status harus diisi",
                      snackbarColor: "red",
                    });
      }
    },
  },

  computed: {
    inquiryKomiteFraud() {
      return Object.values(this.$store.getters.inquiryKomiteFraud);
    },
  },
};
</script>

<style src="./KomiteFraud.scss" lang="scss" />
