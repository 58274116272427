// https://vuex.vuejs.org/en/getters.html


// authorized lets you know if the token is true or not
export default {
  authorized: state => !!state.token,
  authstatus: state => state.authStatus,
  drawer_state: state => state.drawer,

  allInquiryParamTypeDatas: state => state.paramTypeDataList,
  allInquiryParameterType: state => state.inquiryParameterType,
  allParamType: state => state.paramTypeDataList,

  allParameterDatas: state => state.parameterDataList,
  allInquiryParameter: state => state.inquiryParameter,
  allParameter: state => state.parameterDataList,

  allRoleDatas: state => state.roleDataList,
  allInquiryUser: state => state.inquiryUser,

  allInquiryHolidays: state => state.inquiryHolidays,
  allInquiryVerifikasiData: state => state.inquiryVerifikasi,
  allInquiryFraud: state => state.inquiryFraud,
  allInquirySuspect: state => state.inquirySuspects,
  allInquiryBranch: state => state.inquiryBranch,
  allInquiryKaryawanValue: state => state.inquiryKaryawanValue,
  allInquiryEmployee: state => state.inquiryEmployee,
  allInquiryEmployeeVerifikasiFraud: state => state.inquiryEmployee,
  allInquiryModus: state => state.inquiryModus,
  allInquiryReports: state => state.inquiryReports,
  parameterByType: state => state.parameterByType,
  inquiryInvestigasiProcess: state => state.inquiryInvestigasiProcess,

  allInquiryRole: state => state.inquiryRole,
  allRole: state => state.roleDataList,
  allInquiryMenu: state => state.inquiryMenu,
  
  allInquiryEmployeeAfm: state => state.inquiryAfm,

  allInquiryPicInvestigator: state => state.inquiryInvestigator,
  allInquiryPicApprover: state => state.inquiryApprover,
  allInquiryPicBh: state => state.inquiryBh,
  allInquiryPicDh: state => state.inquiryDh,

  inquiryRecoveryPayment: state => state.inquiryRecoveryPayment,
  inquiryKomiteFraud: state => state.inquiryKomiteFraud,
  

  allInquiryInvestigasiCheck: state => state.inquiryInvestigasiCheck,

  

  allInquiryReviewFraud: state => state.inquiryReviewFraud,

  allRoleMenuMappData: state => state.roleMenuMappDataList,
  allInquiryRoleMenuMapp: state => state.inquiryRoleMenuMapp,

  allStopSellingUsers: state => state.stopSellingUserList,
  allAgentTypes: state => state.agentTypes,
  allFeatureAccess: state => state.featureAccessList,
  //allFeature: state => state.featureList,
  //allJob: state => state.jobList,

  allRegisteredUser: state => state.registeredUser,
  allInquiryStopSelling: state => state.inquiryStopSelling,
  //allUserType: state => state.userType,
  allEndNowUser: state => state.endNowUser,

  allListJob: state => state.listJobList,
  allInquiryFeatureAccess: state => state.inquiryFeatureAccess,
  allFeature: state => state.featureList,
  allUserType: state => state.userTypeList,
  allJob: state => state.jobList,
  allUserProfile: state => state.userProfileList,

  allInquiryAdminFee: state => state.inquiryAdminFee,

  allReportSalesByBranch: state => state.reportSalesByBranch,
  allInquiryApproval: state => state.listInquiryApproval,
  allListBranch: state => state.listBranch,
  kodeCabangSelected: state => state.kodeCabang,

  // allListProductPartner: state => state.listProductPartner,
  // allInquiryProduct: state => state.inquiryProduct,

  showSnackbar: state => state.showSnackbar,
  snackbarMsg: state => state.snackbarMsg,
  snackbarColor: state => state.snackbarColor,
  allApprovalStatus: state => state.listApprovalStatus,

  isLoading: state => state.isLoading
}
