<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Hari Libur</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Nama Hari Libur</p>
        <v-text-field
          label=""
          class="mb-0"
          hide-details="auto"
          v-model="searchValue"
          outlined
          placeholder="Masukkan nama hari libur"
          dense
          prepend-inner-icon="mdi-magnify"
          style="background-color: #ffffff"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="">
        <p
          class="elevation-0 text-capitalize white--text font-weight-black"
          color="primary"
        ></p>
        <v-row>
          <v-btn
            @click="search"
            class="elevation-0 text-capitalize ml-3 mt-2"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="elevation-0 text-capitalize ml-3 mt-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
          >
            <span class="orange--text">Reset</span></v-btn
          >
          <v-col class="text-right">
            <v-btn
              width="100"
              class="elevation-0 text-capitalize float-right"
              color="primary"
              :to="`/master-hari-libur/form/add`"
              ><v-icon aria-hidden="false" color="white"> mdi-plus </v-icon
              ><span class="white--text">Tambah</span></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col class="text-right">
        <p
          class="elevation-0 text-capitalize white--text font-weight-black"
          color="primary"
        ></p>
        <v-btn
          width="100"
          class="elevation-0 text-capitalize mt-2"
          color="primary"
          :to="`/master-hari-libur/form/add`"
          ><v-icon aria-hidden="false" color="white"> mdi-plus </v-icon
          ><span class="white--text">Tambah</span></v-btn
        >
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pi">
          <v-card>
            <v-card-text>
              <v-row style="width: 100%">
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="allInquiryHolidays"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                    ><template v-slot:item="allHolidays">
                      <tr>
                        <td class="orange--text">
                          {{ allHolidays.item.holidayName }}
                        </td>
                        <td align="center">
                          {{ allHolidays.item | momentDateStart }}
                          -
                          {{ allHolidays.item | momentDateEnd }}
                        </td>
                        <td align="center">
                          <v-layout justify-center>
                            <v-switch
                              v-model="allHolidays.item.holidayActiveFlag"
                              :label="
                                allHolidays.item.holidayActiveFlag == 'Y'
                                  ? 'Ya'
                                  : 'Tidak'
                              "
                              true-value="Y"
                              false-value="N"
                              inset
                              color="orange darken-3"
                              hide-details
                              readonly
                            >
                            </v-switch>
                          </v-layout>
                        </td>
                        <td align="center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                class="text-capitalize black--text px-0"
                                v-on="on"
                                v-bind="attrs"
                                :to="`/master-hari-libur/${allHolidays.item.holidayId}/form/edit`"
                              >
                                <v-icon
                                  aria-hidden="false"
                                  style="font-size: 20px"
                                  color="#F5821F"
                                >
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-dialog
                            v-model="dialogDel[allHolidays.item.holidayId]"
                            persistent
                            :key="allHolidays.item.holidayId"
                            width="unset"
                          >
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    v-on="{ ...dialog, ...tooltip }"
                                    v-bind="attrs"
                                  >
                                    <v-icon
                                      aria-hidden="false"
                                      style="font-size: 18px"
                                      color="#F5821F"
                                    >
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="text-center">
                              <v-sheet
                                style="
                                  flex-direction: column;
                                  justify-content: center;
                                  align-items: center;
                                  padding: 32px;
                                  position: relative;
                                  width: 349px;
                                  height: 354px;
                                  background: #ffffff;
                                  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                                  border-radius: 12px;
                                "
                              >
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[
                                      allHolidays.item.holidayId
                                    ] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img
                                  src="@/assets/Warning.svg"
                                  alt=""
                                  style="
                                    position: static;
                                    left: 0%;
                                    right: 0%;
                                    top: 0%;
                                    bottom: 0%;
                                  "
                                />
                                <div
                                  style="
                                    position: static;
                                    width: 285px;
                                    height: 78px;
                                    left: calc(50% - 285px / 2);
                                    top: 164px;
                                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 26px;
                                    text-align: center;
                                    color: #2e3a59;
                                    flex: none;
                                    order: 1;
                                    align-self: stretch;
                                    flex-grow: 1;
                                    margin: 32px 0px;
                                  "
                                >
                                  Hapus Hari Libur?
                                  <p
                                    style="
                                      position: static;
                                      width: 285px;
                                      height: 26px;
                                      left: calc(50% - 285px / 2);
                                      top: 48px;
                                      font-family: 'Inter';
                                      font-style: normal;
                                      font-weight: 500;
                                      font-size: 16px;
                                      line-height: 26px;
                                      text-align: center;
                                      color: #a8b5cb;
                                      flex: none;
                                      order: 1;
                                      flex-grow: 0;
                                      margin: 16px 0px;
                                    "
                                  >
                                    {{ allHolidays.item.holidayName }}
                                  </p>
                                </div>
                                <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      allHolidays.item.holidayId,
                                      false
                                    )
                                  "
                                  style="
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0px;
                                    width: 134px;
                                    height: 48px;
                                    background: #ffffff;
                                    border: 1px solid #f5821f;
                                    border-radius: 8px;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;
                                    margin: 0px 16px;
                                  "
                                >
                                  <span
                                    style="
                                      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                      font-style: normal;
                                      font-weight: 600;
                                      font-size: 14px;
                                      line-height: 24px;
                                      color: #f5821f;
                                      flex: none;
                                      order: 0;
                                      flex-grow: 0;
                                      margin: 10px 0px;
                                    "
                                  >
                                    Batalkan
                                  </span>
                                </v-btn>
                                <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeHolidays(allHolidays.item.holidayId),
                                      $set(
                                        dialogDel,
                                        allHolidays.item.holidayId,
                                        false
                                      )
                                  "
                                  style="
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0px;
                                    width: 134px;
                                    height: 48px;
                                    background: #f5821f;
                                    border-radius: 8px;
                                    flex: none;
                                    order: 1;
                                    flex-grow: 0;
                                    margin: 0px 16px;
                                  "
                                >
                                  <span
                                    style="
                                      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                      font-style: normal;
                                      font-weight: 600;
                                      font-size: 14px;
                                      line-height: 22px;
                                      display: flex;
                                      align-items: center;
                                      text-align: center;
                                      color: #ffffff;
                                      flex: none;
                                      order: 0;
                                      flex-grow: 0;
                                      margin: 10px 0px;
                                    "
                                  >
                                    Hapus
                                  </span>
                                </v-btn>

                                <!-- <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      allHolidays.item.holidayId,
                                      false
                                    )
                                  "
                                >
                                  Tidak
                                </v-btn> -->

                                <!-- <v-btn
                                  class="ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeHolidays(allHolidays.item.holidayId),
                                      $set(
                                        dialogDel,
                                        allHolidays.item.holidayId,
                                        false
                                      )
                                  "
                                >
                                  Ya
                                </v-btn> -->
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      items: [],
      headers: [
        {
          text: "Nama Hari Libur",
          align: "center",
          width: "124px",
        },
        {
          text: "Tanggal Hari Libur",
          align: "center",
          width: "124px",
        },
        {
          text: "Status",
          align: "center",
          width: "124px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "124px",
        },
      ],
      statusSearch: false,
      searchValueSearch: "",
      searchValue: "",
    };
  },

  filters: {
    momentDateStart: function (item) {
      if(item.holidayRepeatFlag == 'Y') {
        return moment(item.holidayStartDate).format("DD-MMM");
      } else {
        return moment(item.holidayStartDate).format("DD-MMM-YYYY");
      }
    },
    momentDateEnd: function (item) {
      if(item.holidayRepeatFlag == 'Y') {
        return moment(item.holidayEndDate).format("DD-MMM");
      } else {
        return moment(item.holidayEndDate).format("DD-MMM-YYYY");
      }
    },
  },

  async mounted() {
    this.load();
  },
  created() {
    this.requiredFeature('HOLIDAY')
    this.changeTitleApp("Hari Libur");
  },
  methods: {
    ...mapActions(["changeTitleApp", "getInquiryHolidays"]),

    reset: function () {
      this.searchValue = "";

      this.load();
    },

    load() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = this.searchValue;
      this.$store
        .dispatch("getInquiryHolidays", { pageNumber, pageSize, searchValue })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryHolidays = [];
        });
    },

    search() {
      let pageNumber = 0;
      let pageSize = this.countData;
      let searchValue = this.searchValue.trim();
      this.searchValueSearch = this.searchValue;
      this.$store
        .dispatch("getInquiryHolidays", {
          pageNumber,
          pageSize,
          searchValue,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (this.searchValue == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryHolidays = [];
        });
      this.page = 1;
    },

    removeHolidays(id) {
      let holidayId = id;
      this.$store
        .dispatch("deleteHolidays", { holidayId })
        .then((response) => {
          this.load();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
  computed: {
    allInquiryHolidays() {
      return Object.values(this.$store.getters.allInquiryHolidays);
    },
  },
};
</script>

<style src="./Holidays.scss" lang="scss" />
