<template>
  <v-container fluid>
    <v-row no-gutters class="header d-flex mt-4 mb-1">
      <v-dialog width="800" v-model="pelakuFraudDialog">
        <v-card>
          <v-card-text>
            <v-icon class="icon" @click="pelakuFraudDialog = false"
              style="float: right; margin-top: 12px">mdi-close</v-icon>
          </v-card-text>
          <v-card-title>Pelaku Fraud</v-card-title>
          <v-card-text>Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text>
          <v-card-text>
            <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList" :items-per-page="5"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:[`item.netFraudDist`]="{ item }">
                <vuetify-money v-model="item.netFraudDist" class="mb-0 mt-3" hide-details label="" outlined dense
                  v-bind:options="optionsMoney" readonly />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
        <v-card>
          <v-col>
            <v-card-text>
              <v-icon class="icon" @click="expandPembiayaanFiktif = false">mdi-close</v-icon>
            </v-card-text>
            <v-card-title>{{ lossChosen.paramName }}</v-card-title>
            <v-row no-gutters justify="center">
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF' ||
                lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
              " :headers="headersPembayaranFiktif1Expand" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr>
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.nasabahName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.appIdNo" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingPokok" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingMargin" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" @input="diedit(data.item, data.index)"></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                              <v-col class="text-right">
                                <v-btn class="
                                    elevation-0
                                    text-capitalize
                                    mr-3
                                    primary--text
                                  " depressed outlined color="primary" @click="$set(dialog5, data.index, false)">
                                  <span class="orange--text">Cancel</span></v-btn>
                                <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary"
                                  @click="$set(dialog5, data.index, false)">
                                  <span class="white--text">Save</span></v-btn>
                              </v-col>
                            </v-card-actions>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
                :headers="headersPembayaranFiktif1ExpandSebagian" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.nasabahName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.appIdNo" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.pembiayaanNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                        v-model="data.item.pembiayaanNasabahPercentage" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.pembiayaanKaryawan" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                        v-model="data.item.pembiayaanKaryawanPercentage" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingTotal" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingKaryawan" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
                :headers="headersPembayaranFiktif2ExpandPelunasan" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td class="fixed_table_colum">
                      {{ data.index + 1 }}
                    </td>
                    <td class="fixed_table_colum2">
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.nasabahName" readonly>
                      </v-text-field>
                    </td>
                    <td class="fixed_table_colum3">
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.appIdNo" readonly>
                      </v-text-field>
                    </td>
                    <td class="fixed_table_colum4">
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.outstandingPelunasan" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
                :headers="headersPembayaranFiktif2ExpandSetoran" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.nasabahName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.appIdNo" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" prefix="Rp" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                        item-value="paramCd" readonly>
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>

                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
                :headers="headersPembayaranFiktif2ExpandTabungan" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.nasabahName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.tabunganNo" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.transaksiType" :items="transaksiTypeList" item-text="paramName"
                        item-value="paramCd" readonly>
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.prsCollection" :items="prsCollectionList" item-text="paramName"
                        item-value="paramCd" readonly>
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'" :headers="headersPembayaranFiktif3ExpandBrw"
                :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.mthdDetailDesc" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.brwCategory" :items="brwCategoryList" item-text="paramName"
                        item-value="paramCd" readonly>
                      </v-autocomplete>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'"
                :headers="headersPembayaranFiktif3ExpandKas" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.sentraName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.kasSolCategory" :items="kasSolCategoryList" item-text="paramName"
                        item-value="paramCd">
                      </v-autocomplete>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                :headers="headersPembayaranFiktif3ExpandLemari" :items="lossList[indexExpand]"
                :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.mthdDetailDesc" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'" :headers="headersPembayaranFiktif3ExpandFpb"
                :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.itemName" readonly>
                      </v-text-field>
                    </td>
                    <td>
                      <v-text-field v-model="data.item.fpbTransactionDate" label="" prepend-inner-icon="mdi-calendar"
                        readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'" :headers="headersPembayaranFiktif4Expand"
                :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
                <template v-slot:item="data">
                  <tr class="text-center">
                    <td>
                      {{ data.index + 1 }}
                    </td>
                    <td>
                      <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                        v-model="data.item.itemName" readonly>
                      </v-text-field>
                    </td>
                    <td class="fixed_table_colum3">
                      <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                        v-model="data.item.pelakuPencurian" :items="pelakuPencurianList" item-text="paramName"
                        item-value="paramCd" readonly>
                      </v-autocomplete>
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label=""
                        outlined dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                        dense v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                        v-bind:options="optionsMoney" readonly />
                    </td>
                    <td>
                      <v-btn text class="primary--text" @click="
                        openPelakuDialog(
                          data.item.detailLossDistList,
                          data.item.netFraud
                        )
                        ">Pelaku</v-btn>
                    </td>
                    <td>
                      <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                          </v-btn>
                        </template>
                        <div class="text-center">
                          <v-card>
                            <v-card-text>
                              <v-icon class="icon" @click="
                                $set(
                                  dialogKeterangan,
                                  data.item.netFraud,
                                  false
                                )
                                " style="float: right; margin-top: 12px">mdi-close</v-icon>
                            </v-card-text>
                            <v-card-title>Keterangan</v-card-title>
                            <v-card-text>
                              <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                                v-model="data.item.notes" readonly></v-textarea>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-dialog>
                    </td>
                    <td>
                      <v-btn text class="primary--text"
                        @click="openDocumentDialog(data.item.detailLossDocList)">Document
                        Image</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>

      <v-dialog width="800" v-model="documentDialog">
        <v-card>
          <v-card-text>
            <v-icon class="icon" @click="documentDialog = false"
              style="float: right; margin-top: 12px">mdi-close</v-icon>
          </v-card-text>
          <v-card-title>Download Images</v-card-title>
          <v-card-text>Format file</v-card-text>
          <v-card-text>
            <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
              <v-col> {{ index + 1 }}. {{ document.documentTypeName }} </v-col>
              <v-col class="text-right pr-3">
                <v-btn v-if="document.attachFileName != null" color="primary" class="text-none pr-3" rounded depressed
                  @click="handleDownloadAttachment(document)">
                  <v-icon left> mdi-download </v-icon>
                  {{
                    document.attachFileName ? document.attachFileName : "Upload"
                  }}
                </v-btn>
                <input :ref="`uploader` + index" class="d-none" type="file" accept="image/*"
                  @change="onFileChanged($event, index)" />
              </v-col>
              <v-col>
                <v-text-field v-if="document.notes != null" hide-details="auto" dense outlined placeholder="Keterangan"
                  v-model="document.notes" disabled></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-card-actions>
            <v-col class="text-right">
              <v-btn
                class="elevation-0 text-capitalize mr-3 primary--text"
                depressed
                outlined
                color="primary"
                @click="documentDialog = false"
              >
                <span class="orange--text">Cancel</span></v-btn
              >
            </v-col>
          </v-card-actions> -->
        </v-card>
        <v-overlay :absolute="true" :value="isLoading">
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-overlay>
      </v-dialog>
      <v-dialog width="800" v-model="reviewDialog">
        <v-card>
          <v-card-title>Download Images</v-card-title>
          <v-card-text>Format file</v-card-text>
          <v-card-text>
            <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
              <v-col> {{ index + 1 }}. {{ document.documentTypeName }} </v-col>
              <v-col class="text-right pr-3">
                <v-btn color="primary" class="text-none pr-3" rounded depressed @click="onButtonClick(index)">
                  <v-icon left> mdi-upload </v-icon>
                  {{
                    document.attachFileName ? document.attachFileName : "Upload"
                  }}
                </v-btn>
                <input :ref="`uploader` + index" class="d-none" type="file" accept="image/*"
                  @change="onFileChanged($event, index)" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                @click="documentDialog = false">
                <span class="orange--text">Cancel</span></v-btn>
              <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="documentDialog = false">
                <span class="white--text">Save</span></v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
        <v-overlay :absolute="true" :value="isLoading">
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-overlay>
      </v-dialog>
      <p class="titlet">Review Fraud</p>
      <span class="titlet"></span>
      <p class="subTitle">Proses</p>
    </v-row>
    <v-col>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab href="#data-investigator" @click="handleTab">Data Investigator</v-tab>
          <v-tab href="#detail-laporan" @click="handleTab">
            Detail Laporan</v-tab>
          <v-tab href="#verifikasi-fraud" @click="handleTab">Verifikasi fraud</v-tab>
          <v-tab href="#investigasi-fraud" @click="handleTab">Investigasi Fraud</v-tab>
          <v-tab href="#laporan-pelengkap" @click="handleTab" v-if="verifikasiDelegasi == 'AFM Manager'">Laporan
            Pelengkap</v-tab>
          <v-tab href="#review-fraud" @click="handleTab"> Review Fraud</v-tab>

          <v-tab-item value="data-investigator">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="6">
                  <h7 class="label-text-field">Nama Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined disabled v-model="picNamaInvest"></v-text-field>
                  <h7 class="label-text-field">Jabatan/Posisi Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined disabled v-model="picPosisiInvest"></v-text-field>
                  <h7 class="label-text-field">No. Telepon Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined disabled v-model="picNomorInvest"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h7 class="label-text-field">NIK Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined disabled v-model="picNikInvest"></v-text-field>
                  <h7 class="label-text-field">Email Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined disabled v-model="picEmailInvest"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="detail-laporan">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <h7 class="label-text-field">No. Tiket JAGA</h7>
                  <v-text-field class="mb-0" hide-details="auto" outlined dense disabled
                    v-model="jagaNo"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">Tanggal Laporan</h7>
                  <v-text-field class="mb-0" hide-details="auto" prepend-inner-icon="mdi-calendar" outlined dense
                    disabled v-model="reportDate"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field">Jenis Fraud</h7>
                  <v-col>
                    <v-btn x-large class="elevation-0 text-capitalize primary--text" outlined disabled color="primary"
                      style="
                        width: 112px;
                        height: 48px;
                        border: 1px solid #f5821f;
                        box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                        border-radius: 8px;
                      " v-model="reportJenisFraud">
                      <span class="primary--text">{{
                        reportJenisFraud
                      }}</span></v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field">Sumber Informasi Awal Temuan Fraud</h7>
                  <v-text-field hide-details="auto" dense outlined disabled
                    v-model="reportSumberInformasi"></v-text-field>
                </v-col>
                <v-col>
                  <h7 class="label-text-field" color="primary">
                    Yang Terlibat
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="headersTerlibat" :items="fraudPelakuList" :items-per-page="5"
                        :header-props="{ sortIcon: null }" disable-sort></v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Lokasi Kerja Terjadinya Fraud</h7>
                  <v-text-field hide-details="auto" dense outlined v-model="reportFraudLoc" disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Kerugian Awal</h7>
                  <vuetify-money v-model="reportLoss" prefix="Rp" class="mb-0" color="grey" outlined dense
                    v-bind:options="options" v-bind:readonly="readMoney" />
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Modus Operandi</h7>
                  <v-data-table :headers="headersModus" :items="modusOperandiList" :items-per-page="5"
                    :header-props="{ sortIcon: null }" disable-sort>
                    <template v-slot:item="data">
                      <tr>
                        <td>{{ data.index + 1 }}</td>
                        <td>
                          {{ data.item.fraudMthdName }}
                        </td>
                        <td>
                          {{ data.item.incidentName }}
                        </td>
                        <td>
                          {{ data.item.incidentDesc }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col>
                  <h7 class="label-text-field" color="primary"> Attachment</h7>
                  <v-col cols="12" v-for="item in reportAttach" :key="item">
                    <a v-if="item.flowName == 'Pengaduan Fraud'" @click="handleDownloadAttachment(item)">{{
                      item.attachFileName }}</a>
                  </v-col>
                </v-col>
                <!-- <v-col cols="12">
                  <h7 class="label-text-field" color="primary" v-if="reporterType != 'REPORT_TYPE_ANONIM'">
                    Link Sharepoint
                    <span class="red--text"> *</span>
                  </h7>
                  <span class="grey--text ml-2" v-if="reporterType != 'REPORT_TYPE_ANONIM'">Wajib</span>
                  <v-text-field v-if="reporterType != 'REPORT_TYPE_ANONIM'" v-model="reportLinkShare" class="mb-0"
                    hide-details="auto" outlined dense disabled placeholder="Masukan link share point"></v-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="verifikasi-fraud">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="6">
                  <h7 class="label-text-field">Verifikasi Fraud</h7>
                  <v-col align="left" style="margin-left: -12px">
                    <v-btn x-large class="elevation-0 text-capitalize primary--text" outlined disabled color="primary"
                      cols="12" v-model="verifikasiStatus" style="
                        width: 112px;
                        height: 48px;
                        border: 1px solid #f5821f;
                        box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                        border-radius: 8px;
                      ">
                      <span class="primary--text">{{
                        verifikasiStatus
                      }}</span></v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Dokumen Awal Pelaporan </h7>
                  <v-col>
                    <v-row>
                      <div v-for="(dokumen, index) in verifikasiDoc" :key="index">
                        <v-checkbox v-model="dokumen.reportDocType" :label="dokumen.reportDocTypeName" readonly value
                          class="px-2"></v-checkbox>
                      </div>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Nominal SP Pelaku</h7>
                  <vuetify-money v-model="verifikasiSpPelaku" prefix="Rp" class="mb-0" color="grey" outlined dense
                    v-bind:options="options" v-bind:readonly="readMoney" />
                </v-col>
                <v-col>
                  <h7 class="label-text-field" color="primary">
                    SP Nasabah / Pihak Ketiga</h7>
                  <vuetify-money v-model="verifikasiSpNasabah" prefix="Rp" class="mt-0" color="grey" outlined dense
                    v-bind:options="options" v-bind:readonly="readMoney" />
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Manager Investigator</h7>
                  <v-text-field v-model="verifikasiManagerInves" class="mb-0" hide-details="auto" outlined dense
                    disabled placeholder="0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> No HP Investigator</h7>
                  <v-text-field v-model="verifikasiHandphoneInves" class="mb-0" hide-details="auto" outlined dense
                    disabled placeholder="0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Didelegasi Oleh</h7>
                  <v-col>
                    <v-row class="ml-2px">
                      <v-btn x-large class="elevation-0 text-capitalize primary--text" outlined disabled color="primary"
                        cols="12" v-model="verifikasiDelegasi" style="
                                    width: 170px;
                                    height: 56px;
                                    border: 1px solid #f5821f;
                                    box-shadow: 0px 10px 20px
                                      rgba(235, 127, 0, 0.1);
                                    border-radius: 8px;
                                  ">
                        <span class="primary--text">{{
                          verifikasiDelegasi
                        }}</span></v-btn>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Keterangan
                    <!-- <span class="red--text"> *</span> -->
                  </h7>
                  <v-textarea v-model="verifikasiExtFraudMthdDesc" class="mb-0" hide-details="auto" outlined disabled
                    dense placeholder=""></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="investigasi-fraud">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <h7 class="label-text-field">Investigasi Fraud</h7>
                  <v-col align="left" style="margin-left: -12px">
                    <v-btn x-large class="elevation-0 text-capitalize primary--text" outlined disabled color="primary"
                      cols="12" v-model="investigationStatus" style="
                        width: 112px;
                        height: 48px;
                        border: 1px solid #f5821f;
                        box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                        border-radius: 8px;
                      ">
                      <span class="primary--text">{{
                        investigationStatus
                      }}</span></v-btn>
                  </v-col>
                </v-col>

                <v-col>
                  <h7 class="label-text-field"> Tujuan Fraud</h7>
                  <v-select item-text="paramName" :items="tujuanFraudList" label="" class="mb-0" hide-details="auto"
                    v-model="investigasiFraudFactor" item-value="paramCd" outlined dense clearable disabled></v-select>
                </v-col>
                <v-col cols="12" v-if="investigasiFraudFactor == 'FRAUD_FACTOR_OTHER'
                ">
                  <h7 class="label-text-field" color="primary">
                    Lainnya

                  </h7>
                  <v-text-field v-model="investigasiFraudFactorDesc" class="mb-0" hide-details="auto" outlined dense
                    placeholder="Jelaskan secara rinci" disabled></v-text-field>
                </v-col>

                <!-- <v-col cols="12">
                  <h7 class="label-text-field"> Jenis Pelanggaran</h7>
                  <v-select
                      item-text="paramName"
                      :items="jenisPelanggaranList"
                      label=""
                      class="mb-0"
                      hide-details="auto"
                      v-model="investigasiViolationType"
                      item-value="paramCd"
                      outlined
                      dense
                      clearable
                      disabled
                    ></v-select>
                  
                </v-col>
                 <v-col
                    cols="12"
                   v-if="
                   
                      investigasiViolationType == 'VIOLATION_TYPE_OTHER'
                  "
                  >
                    <h7 class="label-text-field" color="primary">
                      Lainnya
                     
                    </h7>
                    <v-text-field
                      v-model="investigasiViolationTypeDesc"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Jelaskan secara rinci"
                      disabled
                    ></v-text-field>
                  </v-col> -->

                <v-col cols="12">
                  <!-- <h7 class="label-text-field" color="primary">
                    Jenis Pelanggaran
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headersJenisPelanggaran"
                        :items="jenisPelanggaranListToSave"
                        :items-per-page="5"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">{{ data.item.assignmentCategory }}</td>
                            <td align="center">{{ data.item.violationTypeName }}</td>
                            <td align="center">{{ data.item.violationDesc }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row> -->
                </v-col>
                <!-- ROOT CAUSE -->
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary"> Root Cause </h7>
                  <v-row style="width: 100%">
                    <v-col cols="12">
                      <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse" :items-per-page="10"
                        :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">
                              {{ data.item.rootCauseName }}
                            </td>
                            <td>
                              <v-select ref="keteranganRootCause" :items="rootCauseStatus[data.index]"
                                item-text="rootCauseName" item-value="rootCauseDesc" v-model="data.item.rootCauseList"
                                return-object label="" class="mt-3 mb-3" hide-details="auto" outlined dense
                                placeholder="" multiple readonly chips :rules="mandatoryList[data.index] == 'Mandatory' &&
                                  investigationStatus ==
                                  'INVESTIGATION_STATUS_PROVEN'
                                  ? rootCauseRules
                                  : noRootCauseRules
                                  ">
                              </v-select>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="6">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Fraud Dilakukan</h7>
                  <v-text-field v-model="investigasiFraudCommitDate" label="" prepend-inner-icon="mdi-calendar" readonly
                    outlined filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Fraud Diketahui</h7>
                  <v-text-field v-model="investigasiFraudKnowDate" label="" prepend-inner-icon="mdi-calendar" readonly
                    outlined filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h7> Tanggal Terakhir Fraud </h7>
                  <v-text-field v-model="investigasiFraudRecovery" label="" prepend-inner-icon="mdi-calendar" readonly
                    outlined filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <p class="label-text-field mb-5">
                  Rincian Daftar Kerugian
                </p>
                <!-- <v-expansion-panels accordion>
                  <v-expansion-panel
                    @click="onExpansionPanelClick(index, $event)"
                    v-for="(loss, index) in detailLoss"
                    :key="index"
                  >
                    <v-expansion-panel-header
                      v-if="lossList[index].length !== 0"
                    >
                      <v-row no-gutters>
                        <v-col>{{ loss.paramName }}</v-col>
                        <v-col class="text-right"
                          ><v-btn
                            text
                            @click.native.stop="openExpand(index, loss)"
                            ><span class="orange--text">Expand</span></v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="lossList[index].length !== 0"
                    >
                      <v-data-table
                        v-if="
                          loss.paramCd == 'LOSS_TYPE_FIKTIF' ||
                          loss.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
                        "
                        :headers="headersPembayaranFiktif1"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr>
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.nasabahName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum3">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.appIdNo"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum4">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.pencairanDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.plafond"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingPokok"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingMargin"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.tabunganBalance"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
                        :headers="headersPembayaranFiktif1Sebagian"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.nasabahName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum3">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.appIdNo"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum4">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.pencairanDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.plafond"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.pembiayaanNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-text-field
                                class="mt-3"
                                suffix="%"
                                dense
                                outlined
                                hide-details="auto"
                                v-model="data.item.pembiayaanNasabahPercentage"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.pembiayaanKaryawan"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-text-field
                                class="mt-3"
                                suffix="%"
                                dense
                                outlined
                                hide-details="auto"
                                v-model="data.item.pembiayaanKaryawanPercentage"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingTotal"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingKaryawan"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.tabunganBalance"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_ANGSURAN'"
                        :headers="headersPembayaranFiktif2Setoran"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.nasabahName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum3">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.appIdNo"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum4">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                prefix="Rp"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.angsuranStatus"
                                :items="statusAngsuranList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.fraudRepayDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_PELUNASAN'"
                        :headers="headersPembayaranFiktif2Pelunasan"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.nasabahName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum3">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.appIdNo"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum4">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.outstandingPelunasan"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.tabunganBalance"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.fraudRepayDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_TABUNGAN'"
                        :headers="headersPembayaranFiktif2Tabungan"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.nasabahName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum3">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.tabunganNo"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td class="fixed_table_colum4">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.transaksiType"
                                :items="transaksiTypeList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.prsCollection"
                                :items="prsCollectionList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.fraudRepayDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_BRW'"
                        :headers="headersPembayaranFiktif3Brw"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.mthdDetailDesc"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.brwCategory"
                                :items="brwCategoryList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_KAS_SOL'"
                        :headers="headersPembayaranFiktif3Kas"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.sentraName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.kasSolCategory"
                                :items="kasSolCategoryList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.fraudRepayDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                        :headers="headersPembayaranFiktif3Lemari"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.mthdDetailDesc"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_FPB'"
                        :headers="headersPembayaranFiktif3Fpb"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum2">
                              <v-text-field
                                class="mt-3"
                                dense
                                outlined
                                hide-details="auto"
                                persistent-placeholder
                                v-model="data.item.itemName"
                                readonly
                              >
                              </v-text-field>
                            </td>
                            <td>
                              <v-text-field
                                v-model="data.item.fpbTransactionDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mt-3"
                                placeholder=""
                              ></v-text-field>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-data-table
                        v-if="loss.paramCd == 'LOSS_TYPE_PENCURIAN'"
                        :headers="headersPembayaranFiktif4"
                        :items="lossList[index]"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr class="text-center">
                            <td class="fixed_table_colum">
                              {{ data.index + 1 }}
                            </td>
                            <td class="fixed_table_colum3">
                              <v-autocomplete
                                class="mt-3"
                                dense
                                outlined
                                persistent-placeholder
                                hide-details="auto"
                                v-model="data.item.pelakuPencurian"
                                :items="pelakuPencurianList"
                                item-text="paramName"
                                item-value="paramCd"
                                readonly
                              >
                              </v-autocomplete>
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.potentialLoss"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryNasabah"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.recoveryBranch"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <vuetify-money
                                v-model="data.item.netFraud"
                                class="mb-0 mt-3"
                                hide-details
                                label=""
                                outlined
                                dense
                                v-bind:options="optionsMoney"
                                readonly
                              />
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openPelakuDialog(
                                    data.item.detailLossDistList,
                                    data.item.netFraud
                                  )
                                "
                                >Pelaku</v-btn
                              >
                            </td>
                            <td>
                              <v-dialog
                                v-model="dialogKeterangan[data.item.netFraud]"
                                :key="data.index"
                                persistent
                                width="600"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    class="primary--text"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Keterangan
                                  </v-btn>
                                </template>
                                <div class="text-center">
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          $set(
                                            dialogKeterangan,
                                            data.item.netFraud,
                                            false
                                          )
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title>Keterangan</v-card-title>
                                    <v-card-text>
                                      <v-textarea
                                        class="mb-0"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        placeholder=""
                                        v-model="data.item.notes"
                                        readonly
                                      ></v-textarea>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-dialog>
                            </td>
                            <td>
                              <v-btn
                                text
                                class="primary--text"
                                @click="
                                  openDocumentDialog(
                                    data.item.detailLossDocList
                                  )
                                "
                                >Document Image</v-btn
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->

                <!-- <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                  hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data">
                    <tr>
                      <td>{{ data.index + 1 }}</td>
                      <td>{{ data.item.pelakuNik }}</td>
                      <td>{{ data.item.pelakuName }}</td>
                      <td>{{ data.item.modusName }}</td>
                      <td>{{ data.item.noa === 0 ? "" : data.item.noa }}</td>
                      <td>{{ data.item.plafond === 0 ? "" : data.item.plafond }}</td>
                      <td>{{ data.item.tabungan === 0 ? "" : data.item.tabungan }}</td>
                      <td>{{ data.item.potentialLoss === 0 ? "" : data.item.potentialLoss }}</td>
                      <td>{{ data.item.recovery }}</td>
                      <td>{{ data.item.netFraud }}</td>
                      <td>
                        <v-btn v-if="data.item.pelakuUtama == 'N'" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                          @click="handleDownloadFilePelaku(data.item, 'bukti')">
                          <span class="orange--text ml-2">Download Surat Pernyataan</span>
                        </v-btn> 
                        <div v-else>
                          <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                            <span class="orange--text ml-2">Download List Nasabah</span>
                          </v-btn> 
                          <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'bukti')">
                            <span class="orange--text ml-2">Download Bukti</span> 
                          </v-btn> 
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td colspan="4" align="center">Total</td>
                      <td>{{sumField('noa') === 0 ? "" : sumField('noa')}}</td>
                      <td>{{sumField('plafond') === 0 ? "" : sumField('plafond')}}</td>
                      <td>{{sumField('tabungan') === 0 ? "" : sumField('tabungan')}}</td>
                      <td>{{sumField('potentialLoss') === 0 ? "" : sumField('potentialLoss')}}</td>
                      <td>{{sumField('recovery') === 0 ? "" : sumField('recovery')}}</td>
                      <td>{{sumField('netFraud') === 0 ? "" : sumField('netFraud')}}</td>
                    </tr>
                  </template>
                </v-data-table> -->
                <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                  hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                  <template v-slot:item="data" item-key="pelakuId">
                    <tr v-if="!isMerged(data.item, data.index)">
                      <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                        data.index) }}</td>
                      <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                      <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                      <td>{{ data.item.modusName }}</td>
                      <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                      <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                      <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                      <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                      <td>{{ formatNumber(data.item.recovery) }}</td>
                      <td>{{ formatNumber(data.item.netFraud) }}</td>
                      <td>
                        <div v-if="data.item.pelakuUtama == 'N'">
                          <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'bukti')">
                            <span class="orange--text ml-2">Download Surat Pernyataan</span>
                          </v-btn>
                        </div>
                        <div v-else>
                          <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                            <span class="orange--text ml-2">Download List Nasabah</span>
                          </v-btn>
                          <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'bukti')">
                            <span class="orange--text ml-2">Download Bukti</span>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    <tr v-else>
                      <td>{{ data.item.modusName }}</td>
                      <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                      <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                      <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                      <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                      <td>{{ formatNumber(data.item.recovery) }}</td>
                      <td>{{ formatNumber(data.item.netFraud) }}</td>
                      <td>
                        <div v-if="data.item.pelakuUtama == 'N'">
                          <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'bukti')">
                            <span class="orange--text ml-2">Download Surat Pernyataan</span>
                          </v-btn>
                        </div>
                        <div v-else>
                          <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                            <span class="orange--text ml-2">Download List Nasabah</span>
                          </v-btn>
                          <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                            class="elevation-0 text-capitalize text-center" cols="12" color="white"
                            @click="handleDownloadFilePelaku(data.item, 'bukti')">
                            <span class="orange--text ml-2">Download Bukti</span>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <td colspan="4" align="center">Total</td>
                      <td>{{ sumField('noa') === 0 ? "" : formatNumber(sumField('noa')) }}</td>
                      <td>{{ sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond')) }}</td>
                      <td>{{ sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan')) }}</td>
                      <td>{{ sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss')) }}</td>
                      <td>{{ sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery')) }}</td>
                      <td>{{ sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud')) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="laporan-pelengkap">
            <v-card-text>
              <v-row class="mx-1">
                <v-col>
                  <h7 class="label-text-field" color="primary"> Attachment</h7>
                  <v-col cols="12" v-for="item in lapAttach" :key="item">
                    <a @click="handleDownloadAttachment(item)">{{
                      item.attachFileName
                    }}</a>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="review-fraud">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Summary Status
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="headersSummary" :items="lossSummary" :items-per-page="5"
                        :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">
                              {{ data.item.pelakuPosition }}
                            </td>
                            <td align="center">{{ data.item.pelakuStatus }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.lossTypeName }}</td>
                            <td align="center">
                              Rp. {{ humanFormat(data.item.potentialLoss) }}
                            </td>
                            <td align="center">
                              Rp. {{ humanFormat(data.item.recoveryNasabah) }}
                            </td>
                            <td align="center">
                              Rp. {{ humanFormat(data.item.recoveryBranch) }}
                            </td>
                            <td align="center">Rp. {{ humanFormat(data.item.netFraud) }}</td>
                            <td>
                              <v-checkbox v-model="data.item.confirmStatus" true-value="Y" false-value=""
                                class="ml-15"></v-checkbox>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field">Uraian Kejadian

                    <span class="textWajib">Wajib</span>
                  </h7>
                  <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder="" v-model="reviewUraian"
                    :rules="uraianRules" @keypress="noChar" @paste="pasteChar"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field">Penggunaan Uang

                    <span class="textWajib">Wajib</span>
                  </h7>
                  <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder="" v-model="rviewPengguna"
                    :rules="penggunaRules" @keypress="noChar" @paste="pasteChar"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Sanksi dan Pasal
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table hide-default-footer :headers="headersSanksiPelaku" :items="fraudPelaku"
                        :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">
                              {{ data.item.pelakuStatus }}
                            </td>
                            <td>
                              <v-select :items="rekomendPasalStatus" item-text="paramName" item-value="paramCd"
                                v-model="data.item.rekomendasiPasal" label="" class="mt-3 mb-3" hide-details="auto"
                                outlined dense clearable placeholder="" style="border-radius: 8px"></v-select>
                            </td>
                            <td>
                              <v-select :items="rekomendSanksiStatus" item-text="paramName" item-value="paramCd"
                                v-model="data.item.rekomendasiSanksi" label="" class="mt-3 mb-3" hide-details="auto"
                                outlined dense clearable placeholder="" style="border-radius: 8px"></v-select>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- <v-col cols="12">
                  <h7 class="label-text-field"
                    >Keputusan

                    <span class="textWajib">Wajib</span>
                  </h7>
                  <v-textarea
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    placeholder=""
                    v-model="rviewKeputusan"
                    :rules="keputusanRules"
                  ></v-textarea>
                </v-col> -->
                <v-col cols="12">
                  <h7 class="label-text-field">Catatan

                    <span class="textWajib">Wajib</span>
                  </h7>
                  <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder="" v-model="rviewCatatan"
                    :rules="catatanRules" @keypress="noChar" @paste="pasteChar"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <h7 class="label-text-field">SOP / Memo / Juknis

                    <span class="textWajib">Wajib</span>
                  </h7>
                  <v-select v-model="rviewJenisSop" class="mb-0" hide-details="auto" solo dense outlined
                    placeholder="Semua" :items="rviewJenisSopList" item-text="paramName"
                    item-value="paramCd"></v-select>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Unsur Pelanggaran
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="headersUnsur" :items="unsurList" :items-per-page="5"
                        :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">
                              {{ data.index + 1 }}
                            </td>
                            <td align="center">
                              {{
                                data.item.fraudLocName != null
                                  ? data.item.fraudLocName
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.fraudLoc != null
                                  ? data.item.fraudLoc
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.lossTypeName != null
                                  ? data.item.lossTypeName
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.pelakuName != null
                                  ? data.item.pelakuName
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.pelakuNik != null
                                  ? data.item.pelakuNik
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.pelakuPosition != null
                                  ? data.item.pelakuPosition
                                  : "-"
                              }}
                            </td>
                            <td align="center">
                              {{
                                data.item.pelakuStatus != null
                                  ? data.item.pelakuStatus
                                  : "-"
                              }}
                            </td>
                            <!-- SP Pelaku -->
                            <td align="center">
                              <v-tooltip bottom v-if="data.item.spPelaku != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.spPelaku,
                                        data.item.spPelakuPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.spPelaku != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.spPelaku,
                                        data.item.spPelakuPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <td>
                              {{
                                data.item.nasabahName != ""
                                  ? data.item.nasabahName
                                  : "-"
                              }}
                            </td>

                            <!-- SP Nasabah/Pihak Ketiga -->
                            <td>
                              <v-tooltip bottom v-if="data.item.spNasabah != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.spNasabah,
                                        data.item.spNasabahPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.spNasabah != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.spNasabah,
                                        data.item.spNasabahPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- AMOUNT -->
                            <td>Rp. {{ data.item.amount }}</td>

                            <!-- Tabungan -->
                            <td>
                              <v-tooltip bottom v-if="data.item.tabungan != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.tabungan,
                                        data.item.tabunganPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.tabungan != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.tabungan,
                                        data.item.tabunganPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>


                            <!-- PROSPERA / T24 -->
                            <td>
                              <v-tooltip bottom v-if="data.item.prosperaT24 != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.prosperaT24,
                                        data.item.prosperaT24Path
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.prosperaT24 != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.prosperaT24,
                                        data.item.prosperaT24Path
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- PENARIKAN -->
                            <td>
                              <v-tooltip bottom v-if="data.item.penarikan != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.penarikan,
                                        data.item.penarikanPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.penarikan != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.penarikan,
                                        data.item.penarikanPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- AGENDAKU -->
                            <td>
                              <v-tooltip bottom v-if="data.item.agendaku != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.agendaku,
                                        data.item.agendaKuPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.agendaku != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.agendaku,
                                        data.item.agendaKuPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- BKL -->
                            <td>
                              <v-tooltip bottom v-if="data.item.bkl != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.bkl,
                                        data.item.bklPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.bkl != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.bkl,
                                        data.item.bklPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- BKC -->
                            <td>
                              <v-tooltip bottom v-if="data.item.bkc != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.bkc,
                                        data.item.bkcPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.bkc != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.bkc,
                                        data.item.bkcPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                            <!-- SPPU / FPHC -->
                            <td>
                              <v-tooltip bottom v-if="data.item.sppuFphc != null">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                    @click="
                                      viewUnsur(
                                        data.item.sppuFphc,
                                        data.item.sppuFphcPath
                                      )
                                      ">
                                    <v-icon color="orange">
                                      mdi-eye-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Lihat</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="data.item.sppuFphc != null">
                                <template v-slot:activator="{ on: tooltip, attrs }">
                                  <v-btn small text class="text-capitalize black--text px-0" v-on="{ ...tooltip }"
                                    v-bind="attrs" @click="
                                      handleDownloadAttch(
                                        data.item.sppuFphc,
                                        data.item.sppuFphcPath
                                      )
                                      ">
                                    <v-icon color="orange" aria-hidden="false">
                                      mdi-file-download-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Download</span>
                              </v-tooltip>

                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Attachment</p>
                    <span class="textWajib mt-1"> Wajib </span>
                  </v-row>
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0" style="color: #a8b5cb">
                      Format file JPG/PNG/PDF/DOCX (Bukti minimal 2 file dengan
                      masing-masing maksimum 2MB per file)
                    </p>
                  </v-row>
                  <v-row>
                    <v-div v-for="(item, index) in attachList" :key="index">
                      <div class="dropbox ml-1">
                        <input :rules="uploadRules" type="file" :name="uploadFieldName"
                          @change="handleUploadFile($event, index)"
                          accept="image/*, application/pdf, application/msword" class="input-file" ref="file" />
                        <v-div class="d-flex">
                          <v-icon v-if="!item.attachFilePath" aria-hidden="false" color="grey lighten-3"
                            class="mt-8 ml-13" size="65px">
                            mdi-image-plus
                          </v-icon>
                          <v-btn v-if="!item.attachFilePath" x-small outlined color="white"
                            @click="handleRemoveFile(index)" class="mt-1" style="margin-left: 20px">
                            <v-icon aria-hidden="false" color="orange">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-div>

                        <v-div class="jpg d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.jpg' ||
                              ekstension[index] == '.JPG'
                            " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.jpg' ||
                              ekstension[index] == '.JPG'
                            " x-small outlined color="white" @click="handleRemoveFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="png d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.png' ||
                              ekstension[index] == '.PNG'
                            " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />
                            <v-btn v-if="ekstension[index] == '.png' ||
                              ekstension[index] == '.PNG'
                            " x-small outlined color="white" @click="handleRemoveFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="pdf d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.pdf' ||
                              ekstension[index] == '.PDF'
                            " src="@/assets/Pdf.svg" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.pdf' ||
                              ekstension[index] == '.PDF'
                            " x-small outlined color="white" @click="handleRemoveFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="docx d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.docx' ||
                              ekstension[index] == '.DOCX'
                            " src="@/assets/docx.svg" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.docx' ||
                              ekstension[index] == '.DOCX'
                            " x-small outlined color="white" @click="handleRemoveFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <p v-if="!item.attachFileName" class="grey--text mt-4 ml-8">
                          Upload File {{ index + 1 }}
                          <v-progress-linear v-if="isLoadingPhoto[index]" indeterminate></v-progress-linear>
                        </p>
                        <p v-if="item.attachFileName" class="ml-6" style="text-align: initial">
                          {{ item.attachFileName }}
                        </p>
                        <p v-if="item.attachFileName" class="size ml-6">
                          {{ attachFileSize[index] }} mb
                        </p>
                      </div>
                    </v-div>

                    <div class="dropbox">
                      <v-btn x-large outlined color="white" @click="handleAddFraudAttachment" class="mt-16">
                        <v-col>
                          <v-icon aria-hidden="false" color="orange" class="mt-5 mb-5 ml-4" size="35px">
                            mdi-plus
                          </v-icon>
                          <v-row>
                            <span class="orange--text ml-4">Tambah File</span>
                          </v-row>
                        </v-col>
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Review Status</p>
                    <span class="textWajib">Wajib</span>
                  </v-row>
                  <div class="jf mt-2">
                    <v-btn-toggle id="btnGroup" active-class="active" v-model="rviewJenis">
                      <v-btn x-large :class="rviewJenis == 'REVIEW_STATUS_NOT_OK'
                        ? 'elevation-0 text-capitalize primary--text'
                        : 'elevation-0 text-capitalize grey--text'
                        " style="
                          width: 112px;
                          height: 48px;
                          border: 1px solid #f5821f;
                          box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                          border-radius: 8px;
                        " depressed outlined :color="investigationStatus == 'REVIEW_STATUS_NOT_OK'
                          ? 'primary'
                          : 'grey'
                          " cols="12" value="REVIEW_STATUS_NOT_OK">
                        Not Ok</v-btn>
                      <v-btn x-large :class="rviewJenis == 'REVIEW_STATUS_OK'
                        ? 'elevation-0 text-capitalize primary--text'
                        : 'elevation-0 text-capitalize grey--text'
                        " style="
                          width: 112px;
                          height: 48px;
                          border: 1px solid #f5821f;
                          box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                          border-radius: 8px;
                        " depressed outlined :color="investigationStatus == 'REVIEW_STATUS_OK'
                          ? 'primary'
                          : 'grey'
                          " cols="12" value="REVIEW_STATUS_OK">
                        OK</v-btn>
                    </v-btn-toggle>
                  </div>
                </v-col>
                <v-col cols="12" v-if="rviewJenis == 'REVIEW_STATUS_NOT_OK'">
                  <h7> Notes </h7>
                  <span class="textWajib">Wajib</span>
                  <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder="" v-model="reviewNotes"
                    :rules="notesRules"></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-col>
      <v-row>
        <v-col class="text-right">
          <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
            :to="`/review-fraud`" x-large>
            <span class="orange--text">Back</span></v-btn>
          <v-btn v-if="submitBtn" class="elevation-0 text-capitalize mr-3 primary--text" depressed color="primary"
            @click="handleSubmit" x-large>
            <span class="white--text">Submit</span></v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-card rounded="lg">
        <v-card-text>
          <v-row class="mx-1">
            <v-col>
              <h7 class="label-text-field"> History </h7>
              <v-data-table :headers="headersHistory" :items="fraduLogList" :header-props="{ sortIcon: null }"
                disable-sort></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      empty: false,
      linkPath: "",
      rootCauseList: [],
      tab: null,
      dataId: null,
      isLoadingPhoto: [false, false],
      brwCategoryList: [],
      kasSolCategoryList: [],
      transaksiTypeList: [],
      prsCollectionList: [],
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp.",
        suffix: "",
        length: 12,
        precision: 0,
      },
      headersTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "",
          width: "150px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
        },
      ],
      headersModus: [
        {
          text: "No",
          align: "center",
          //   width: "50px",
        },
        {
          text: "Kode Modus",
          align: "center",
          //   width: "350",
        },
        {
          text: "Kronologi Kejadian",
          align: "center",
          //   width: "400px",
        },
        {
          text: "Deskripsi",
          align: "center",
          //   width: "100px",
        },
      ],
      headersSanksiPelaku: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Rekomendasi Pasal",
          align: "center",
          width: "250px",
        },
        {
          text: "Rekomendasi Sanksi",
          align: "center",
          width: "220px",
        },
      ],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "108px",
        //   class: "black--text",
        // },
      ],
      headersSummary: [
        {
          text: "No",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Karyawan",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "150px",
        },
        {
          text: "Status",
          align: "center",
          width: "150px",
        },
        {
          text: "NIK",
          align: "center",

          width: "150px",
        },
        {
          text: "Modus Operandi",
          align: "center",

          width: "150px",
        },
        {
          text: "Poteni Kerugian Awal",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
        },
        {
          text: "Confirm Status",
          align: "center",
          width: "150px",
        },
      ],

      headersUnsur: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "MMS",
          align: "center",
          width: "150px",
        },
        {
          text: "Kode MMS",
          align: "center",
          width: "150px",
        },
        {
          text: "Modus",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "150px",
        },
        {
          text: "Status",
          align: "center",
          width: "150px",
        },
        {
          text: "SP Pelaku",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
        },
        {
          text: "SP Nasabah / Pihak Ketiga",
          align: "center",
          width: "150px",
        },
        {
          text: "Amount",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan",
          align: "center",
          width: "150px",
        },
        {
          text: "Prospera / T24",
          align: "center",
          width: "150px",
        },
        {
          text: "Penarikan",
          align: "center",
          width: "150px",
        },
        {
          text: "Agendaku",
          align: "center",
          width: "150px",
        },
        {
          text: "BKL",
          align: "center",
          width: "150px",
        },
        {
          text: "BKC",
          align: "center",
          width: "150px",
        },
        {
          text: "SPPU / FPHC",
          align: "center",
          width: "150px",
        },
      ],

      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],

      headersHistory: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Date",
          align: "center",
          value: "processDate",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Processed By",
          align: "center",
          value: "processBy",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "250px",
          value: "processComment",
          class: "tableHeader",
        },
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "150px",
        },
      ],

      options: {
        locale: "pt-BR",
        prefix: "Rp",
        suffix: "",
        length: 14,
        precision: 0,
      },

      readMoney: true,

      //DATA INVESTIGATOR
      picNamaInvest: "",
      picPosisiInvest: "",
      picNomorInvest: "",
      picNikInvest: "",
      picEmailInvest: "",

      //DETAIL LAPORAN
      jagaNo: "",
      reportDate: "",
      reportDetail: "",
      reportJenisFraud: "",
      reportFraudLoc: "",
      reportLoss: "",
      reportAttach: "",
      reportLinkShare: "",
      reportSumberInformasi: "",
      fraudPelakuList: [],
      modusOperandiList: [],

      //VERIFIKASI FRAUD
      verifikasiStatus: "",
      verifikasiDoc: "",
      verifikasiSpPelaku: "",
      verifikasiSpNasabah: "",
      verifikasiManagerInves: "",
      verifikasiHandphoneInves: "",
      verifikasiDelegasi: "",
      verifikasiExtFraudMthdDesc: "",
      verifiksaiReportDateReturn: "",

      //INVESTIGASI FRAUD
      investigationStatus: "",
      investigasiNote: "",
      investigationFraudDetail: "",
      investigasiFraudFactor: "",
      investigasiFraudFactorDesc: "",
      investigasiFraudReason: "",
      investigasiFraudPurpose: "",
      investigasiFraudControl: "",
      investigasiViolationType: "",
      investigasiViolationTypeDesc: "",
      investigasiFraudCommitDate: "",
      investigasiFraudKnowDate: "",
      investigasiFraudRecovery: "",

      //LAPORAN PELENGKAP
      lapAttach: "",

      // SUMMARY
      summaryList: [],

      // UNSUR
      unsurList: [],

      // SANKSI PELAKU
      sanksiPasalList: [],

      // REVIEW FRAUD
      rviewPengguna: "",
      reviewUraian: "",
      rviewKeputusan: "",
      rviewCatatan: "",
      rviewJenisStatus: [],
      rviewJenis: "",
      rviewJenisSop: "",
      rviewJenisSopList: [],

      fraudPelaku: [
        {
          fraudId: 0,
          kfPasal: "",
          kfSanksi: "",
          pelakuId: 0,
          pelakuJointDate: "",
          pelakuLastEdu: "",
          pelakuLocName: "",
          pelakuName: "",
          pelakuNik: "",
          pelakuPosition: "",
          pelakuStatus: "",
          pelakuStatusName: "",
          pelakuUtama: "",
          realisasiSanksiDate: "",
          rekomendasiPasal: "",
          rekomendasiSanksi: "",
          seqNo: 0,
        },
      ],
      fraudAttch: [
        {
          fraudId: 0,
          attachFileName: "",
          attachFilePath: "",
          flowName: "Review Fraud",
        },
      ],
      lossSummary: [
        {
          fraudId: 0,
          confirmStatus: "",
          lossType: "",
          lossTypeName: "",
          netFraud: "",
          potentialLoss: "",
          recoveryBranch: "",
          recoveryNasabah: "",
        },
      ],
      rootCouse: [],

      attachList: [
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Review Fraud",
          attachFileImage: "",
        },
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Review Fraud",
          attachFileImage: "",
        },
      ],

      attachFileName: "",
      attachFilePath: "",
      rootJenis: "",
      rootCauseStatus: [],
      rekomendPasalStatus: [],
      rekomendSanksiStatus: [],
      uploadFruad: [],

      // FILE
      file: "",

      //UPLOAD
      uploadPercentage: 0,
      uploadReview: [],
      ekstension: [],

      //ATTACH FILE
      attachFileSize: [],

      tipeReview: [
        {
          id: "1",
          title: "Not Ok",
          value: "REVIEW_STATUS_NOT_OK",
        },
        {
          id: "2",
          title: "Ok",
          value: "REVIEW_STATUS_OK",
        },
      ],

      rootOther: [],

      reviewFraud: "",

      selectedConfirm: [],
      submitBtn: false,

      //HISTORY
      fraduLogList: [],

      reporterType: "",

      //ACCORDION
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Janis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersYangTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",

          width: "150px",
          class: "black--text",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
          class: "black--text",
        },
      ],
      headersPembayaran1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaran2Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafon (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
        }
      ],
      headersPembayaranFiktif3Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      // headersPembayaranFiktif4Expand: [
      //   {
      //     text: "No",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Jenis Barang/Asset",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Pelaku",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Potensi Kerugian",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Recovery Nasabah",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Recovery Cabang",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Net Fraud",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Pelaku Fraud",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Keterangan",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      //   {
      //     text: "Document Image",
      //     align: "center",
      //     width: "150px",
      //     class: "tableHeader ",
      //   },
      // ],
      expandPembiayaanFiktif: false,
      lossChosen: {
        paramValue: 5,
      },
      detailPembiayaan: [],
      lossList: [],
      uraianRules: [(v) => !!v || "Uraian Wajib diisi"],
      penggunaRules: [(v) => !!v || "Penggunaan Uang Wajib diisi"],
      keputusanRules: [(v) => !!v || "Keputusan Wajib diisi"],
      catatanRules: [(v) => !!v || "Catatan Wajib diisi"],
      notesRules: [(v) => !!v || "Notes Wajib diisi"],
      uploadRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      uploadFraud: [],
      rootCauseApi: [],
      documentDialog: false,
      indexForSave: 0,
      reviewDialog: false,
      detailLoss: [],
      dialogKeterangan: {},
      baseImage: "",
      isiPelakuList: [],
      pelakuFraudDialog: false,
      reviewNotes: "",
      pelakuPencurianList: [],
      statusAngsuranList: [],
      jenisPelanggaranList: [],
      tujuanFraudList: [],
    };
  },

  async created() {
    this.requiredFeature('REVIEW_FRAUD')
    await this.loadGetLossList();
    this.loadDataReviewFrud();
    this.loadStatusSOP();
    // this.loadStatusRoot();
    this.loadStatusPasal();
    this.loadStatusSanksi();
    this.loadDataUnsurPelanggaran();
    this.loadStatusReview();
    this.hanldeGetStatusAngsuran();
    this.handleGetBrwCategory();
    this.handleGetKasSolCategory();
    this.handleGetPelakuPencurian();
    this.handleGetTransaksiType();
    this.handleGetPrsCollection();
    this.handleGetTujuanFraud();
    this.handleGetJenisPelanggaran();
    this.handleSearchFraudPelaku();
  },

  // async mounted(){
  //   this.loadGetLossList();
  // },

  methods: {
    ...mapActions([
      "getDataReviewFraudId",
      "submitReviewFraud",
      "getParameterByParamType",
      "getUnsurPelanggaran",
      "downloadAttachment",
      "uploadReviewFraud",
      "getDataKerugianDocumentByFraudId",
      "searchFraudPelaku",
      "downloadFilePelaku"
    ]),

    handleSearchFraudPelaku() {
      this.searchFraudPelaku(this.getIdReview)
        .then(response => {
          console.log('response', response)
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    handleDownloadFilePelaku(item, type) {
      this.downloadFilePelaku({
        pelakuDtlId: item.pelakuDetailId,
        type: type
      })
        .then((response) => {
          console.log(response, "response")
        })
        .catch((err) => {
          console.log("gagal download", err.response);
          this.$helpers.handleError(err);
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Gagal mengunduh. File tidak ditemukan",
            snackbarColor: "red",
          })
          return
        });
    },
    isMerged(item, index) {
      if (index === 0) return false;
      return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    sumField(key) {
      return this.daftarKerugianList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    // LOAD
    loadDataReviewFrud() {
      this.getDataReviewFraudId({
        fraudId: this.getIdReview,
      }).then((resp) => {
        this.dataId = resp.data.data;
        this.jenisPelanggaranListToSave = this.dataId.jenisPelanggaranList;
        this.reporterType = this.dataId.reportType;
        // DATA INVESTIGATOR
        this.picNamaInvest =
          this.dataId.picInvestigatorName != null
            ? this.dataId.picInvestigatorName
            : "-";
        this.picPosisiInvest =
          this.dataId.picInvestigatorPosition != null
            ? this.dataId.picInvestigatorPosition
            : "-";
        this.picNomorInvest =
          this.dataId.picInvestigatorPhone != null
            ? this.dataId.picInvestigatorPhone
            : "-";
        this.picNikInvest =
          this.dataId.picInvestigator != null
            ? this.dataId.picInvestigator
            : "-";
        this.picEmailInvest =
          this.dataId.picInvestigatorEmail != null
            ? this.dataId.picInvestigatorEmail
            : "-";
        //DETAIL LAPORAN
        this.jagaNo = this.dataId.jagaNo != "" ? this.dataId.jagaNo : "-";
        this.reportDate =
          this.dataId.reportDate != null
            ? moment(this.dataId.reportDate).format("DD/MM/YYYY")
            : "-";
        this.reportJenisFraud =
          this.dataId.fraudTypeName != null ? this.dataId.fraudTypeName : "-";
        this.reportSumberInformasi =
          this.dataId.fraudInfName != null ? this.dataId.fraudInfName : "-";
        this.fraudPelakuList =
          this.dataId.fraudPelakuList != null
            ? this.dataId.fraudPelakuList
            : "-";
        this.reportFraudLoc =
          this.dataId.fraudLocName != null ? this.dataId.fraudLocName : "-";
        this.reportLoss =
          this.dataId.initialLoss != null ? this.humanFormat(this.dataId.initialLoss) : "-";
        this.modusOperandiList =
          this.dataId.fraudMthdList != null ? this.dataId.fraudMthdList : "-";
        this.reportAttach =
          this.dataId.fraudAttList != null ? this.dataId.fraudAttList : "-";
        this.reportLinkShare =
          this.dataId.sharepointLink != "" ? this.dataId.sharepointLink : "-";
        //VERIFIKASI FRAUD
        this.verifikasiStatus =
          this.dataId.verifStatusName != "" ? this.dataId.verifStatusName : "-";
        this.verifikasiDoc =
          this.dataId.reportDocTypeList != null
            ? this.dataId.reportDocTypeList
            : "-";
        this.verifikasiSpPelaku =
          this.dataId.spPelaku != null ? this.dataId.spPelaku : "-";
        this.verifikasiSpNasabah =
          this.dataId.spNasabah != null ? this.dataId.spNasabah : "-";
        this.verifikasiManagerInves =
          this.dataId.investigationDelegatorName != ""
            ? this.dataId.investigationDelegatorName
            : "-";
        this.verifikasiHandphoneInves =
          this.dataId.investigationDelegatorPhone != ""
            ? this.dataId.investigationDelegatorPhone
            : "-";
        this.verifikasiDelegasi =
          this.dataId.investigationDelegatorPositionName != null
            ? this.dataId.investigationDelegatorPositionName
            : "-";
        this.verifikasiExtFraudMthdDesc =
          this.dataId.extFraudMthdDesc != ""
            ? this.dataId.extFraudMthdDesc
            : "-";
        this.verifiksaiReportDateReturn =
          this.dataId.returnDate != null
            ? moment(this.dataId.returnDate).format("DD/MM/YYYY")
            : null;
        //INVESTIGASI FRAUD
        this.rootCauseList = this.dataId.rootCauseList;
        this.investigationStatus =
          this.dataId.investigationStatusName != null
            ? this.dataId.investigationStatusName
            : null;

        this.investigationFraudDetail =
          this.dataId.fraudDetail != null ? this.dataId.fraudDetail : "-";
        this.investigasiFraudFactor =
          this.dataId.fraudFactorName != null
            ? this.dataId.fraudFactor
            : "-";
        this.investigasiFraudFactorDesc = this.dataId.fraudFactorDesc != null ? this.dataId.fraudFactorDesc : "-";
        this.investigasiFraudReason =
          this.dataId.fraudReason != null ? this.dataId.fraudReason : "-";
        this.investigasiFraudPurpose =
          this.dataId.fraudPurpose != null ? this.dataId.fraudPurpose : "-";
        this.investigasiFraudControl =
          this.dataId.fraudControl != null ? this.dataId.fraudControl : "-";
        this.investigasiViolationType =
          this.dataId.violationType != null
            ? this.dataId.violationType
            : "-";
        this.investigasiViolationTypeDesc = this.dataId.violationTypeDesc != null ? this.dataId.violationTypeDesc : "-";
        this.investigasiFraudCommitDate =
          this.dataId.fraudCommitedDate != null
            ? moment(this.dataId.fraudCommitedDate).format("DD-MMM-YYYY")
            : "-";
        this.investigasiFraudKnowDate =
          this.dataId.fraudKnownDate != null
            ? moment(this.dataId.fraudKnownDate).format("DD-MMM-YYYY")
            : "-";
        this.investigasiFraudRecovery =
          this.dataId.fraudCommitedDateTo!= null
            ? moment(this.dataId.fraudCommitedDateTo).format("DD-MMM-YYYY")
            : "-";
        this.rootCauseApi = this.dataId.rootCauseList;
        this.loadStatusRoot();

        let temp = this.dataId.detailLossList;
        console.log("data detailLoss", this.detailLoss);

        for (var i = 0; i < this.detailLoss.length; i++) {
          this.lossList.push([]);
        }
        console.log("data dari lossList", this.lossList);
        temp.map((item) => {
          console.log(item.lossType);
          let index = this.detailLoss.findIndex(
            (x) => x.paramCd === item.lossType
          );

          item.fraudRepayDate = moment(item.fraudRepayDate).format(
            "DD-MMM-YYYY"
          );

          item.pencairanDate = moment(item.pencairanDate).format("DD-MMM-YYYY");

          item.fpbTransactionDate = moment(item.fpbTransactionDate).format(
            "DD-MMM-YYYY"
          );

          this.lossList[index].push(item);
        });

        console.log("isi lossList", this.lossList);

        //LAPORAN PELENGKAP
        this.lapAttach = this.dataId.fraudAttList;

        // SUMMARY
        this.lossSummary = this.dataId.lossSummaryList;
        // REVIEW FRAUD
        this.fraudPelaku = this.dataId.fraudPelakuList;
        this.fraduLogList = this.dataId.fraudLogList;
      });
    },

    loadStatusSOP() {
      this.getParameterByParamType({
        paramType: "SOP_MEMO_JUKNIS",
      }).then((resp) => {
        this.rviewJenisSopList = resp.data.data.filter(
          (item) =>
            item.paramCd == "SOP_MEMO_JUKNIS_1" ||
            item.paramCd == "SOP_MEMO_JUKNIS_2" ||
            item.paramCd == "SOP_MEMO_JUKNIS_3"
        );
        console.log("status SOP", resp.data.data);
      });
    },

    handleGetBrwCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "BRW_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.brwCategoryList = response.data.data;
      });
    },

    handleGetKasSolCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "KAS_SOL_CATEGORY",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.kasSolCategoryList = response.data.data;
      });
    },

    handleGetPelakuPencurian() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PELAKU_PENCURIAN",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.pelakuPencurianList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.transaksiTypeList = response.data.data;
      });
    },

    handleGetPrsCollection() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PRS_COLLECTION",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.prsCollectionList = response.data.data;
      });
    },

    loadStatusReview() {
      this.getParameterByParamType({
        paramType: "REVIEW_STATUS",
      }).then((resp) => {
        this.rviewJenisStatus = resp.data.data.filter(
          (item) =>
            item.paramCd == "REVIEW_STATUS_NOT_OK" ||
            item.paramCd == "REVIEW_STATUS_NOT_OK"
        );
        console.log("ini param review status ", resp.data.data);
      });
    },

    loadStatusRoot() {
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
        .then((resp) => {
          this.mandatoryList = [];
          let rootCauseStatusa = resp.data.data;
          for (let index = 0; index < rootCauseStatusa.length; index++) {
            this.rootCouse.push({
              createBy: "",
              createDate: "",
              fraudId: this.fraudId,
              lossDocId: 0,
              recordFlag: "",
              rootCause: rootCauseStatusa[index].paramCd,
              rootCauseDesc: "",
              rootCauseList: [],
              rootCauseName: rootCauseStatusa[index].paramName,
              seqNo: 0,
              updateBy: "",
              updateDate: "",
            });

            this.mandatoryList.push(rootCauseStatusa[index].paramValue);

            this.rootCauseStatus.push([]);

            this.getParameterByParamType({
              pageNumber: 0,
              pageSize: 100,
              paramCd: "",
              paramName: "",
              paramType: rootCauseStatusa[index].paramCd,
              paramValue: "",
              searchValue: "",
            }).then((response) => {
              response.data.data.map((item) => {
                item.rootCause = item.paramType;
                delete item.paramType;
                item.rootCauseDesc = item.paramCd;
                delete item.paramCd;
                item.rootCauseName = item.paramName;
                delete item.paramName;
                item.rootCauseDescName = "";
                delete item.paramTypeName;
                delete item.paramValue;
              });
              this.rootCauseStatus.splice(index, 1, response.data.data);

              console.log("JJ", response.data.data);
            });
          }

          if (this.rootCauseApi.length != 0) {
            this.rootCauseApi.map((item) => {
              let indexs = this.rootCouse.findIndex(
                (x) => x.rootCause === item.rootCause
              );
              this.rootCouse.splice(indexs, 1, item);
            });
            console.log("rootCause awal", this.rootCouse);
          }

          console.log("isi rootCause", this.rootCauseStatus);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    openDocumentDialog(document) {
      this.isiDocumentList = document;
      this.documentDialog = true;
      console.log("isi document list", this.isiDocumentList);
    },
    loadStatusPasal() {
      this.getParameterByParamType({
        paramType: "PASAL",
      }).then((resp) => {
        this.rekomendPasalStatus = resp.data.data;
        console.log("status pasal", resp.data.data);
      });
    },

    loadStatusSanksi() {
      this.getParameterByParamType({
        paramType: "SANKSI",
      }).then((resp) => {
        this.rekomendSanksiStatus = resp.data.data;
        console.log("status sanksi", resp.data.data);
      });
    },

    loadDataUnsurPelanggaran() {
      this.getUnsurPelanggaran({
        fraudId: this.getIdReview,
        pageNumber: 0,
        pageSize: 1000,
      }).then((resp) => {
        this.totalData = resp.data.countData;
        this.countData = resp.data.countData;
        this.unsurList = resp.data.data;
        console.log("ini Unsur Pelanggaran", this.unsurList);
      });
    },

    loadGetLossList() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((resp) => {
        this.detailLoss = resp.data.data;
        console.log(" ini detail Loss", this.detailLoss);
      });
    },

    downloadUnsur(item) {
      this.downloadAttachment({
        base64Data: null,
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
      console.log("ini download unsur", item);
    },
    // HANDLE
    handleGetJenisPelanggaran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "VIOLATION_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.jenisPelanggaranList = response.data.data;
      });
    },
    handleSubmit() {
      this.empty = false;
      this.lossSummary.map((item) => {
        if (item.confirmStatus == null || item.confirmStatus == "") {
          this.empty = true;
        }
      });
      if (this.empty && this.rviewJenis != 'REVIEW_STATUS_NOT_OK') {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Confirm Status di Summary Status harus diisi semua",
          snackbarColor: "red",
        });
      } else {
        this.attachList.map((item) => {
          item.attachFileImage = "";
        });
        console.log("APAKAH ADA YANG KOSONG", this.empty);
        this.submitReviewFraud({
          fraudId: this.getIdReview,
          rvwMoneyUsage: this.rviewPengguna,
          rvwIncidentDesc: this.reviewUraian != null ? this.reviewUraian : null,
          rvwNotes: this.rviewCatatan != null ? this.reviewUraian : null,
          rvwDecision: this.rviewKeputusan != null ? this.reviewUraian : null,
          rvwSopMemoJuknis: this.rviewJenisSop,
          rvwStatus: this.rviewJenis,
          notes: this.reviewNotes != null ? this.reviewNotes : null,

          rootCouseList: this.rootCouse,
          lossSummaryList: this.lossSummary,
          fraudPelakuList: this.fraudPelaku,
          fraudAttList: this.attachList,
        })
          .then((resp) => {
            this.$router.push("/review-fraud");
            this.$helpers.handleSuccess(resp);
            console.log("data berhasil di submit", resp.data.data);
          })
          .catch((err) => {
            console.log("data error ", err.response);
            this.$helpers.handleError(err);
          });
      }
    },

    handleAddRootCause() {
      this.rootCouse.push({
        fraudId: this.getIdReview,
        recordFlag: "",
        rootCause: "",
        rootCauseDesc: "",
      });
    },

    handleRemoveRootCause(index) {
      this.rootCouse.splice(index, 1);
    },

    handleGetTujuanFraud() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_FACTOR",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.tujuanFraudList = response.data.data;
      });
    },

    handleDownloadAttachment(item) {
      this.downloadAttachment({
        base64Data: null,
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
    },

    handleUploadFile(event, index) {
      console.log("fraudAttachment", event);

      //this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      this.isLoadingPhoto.splice(index, 1, true);

      this.uploadReviewFraud(formData)
        .then((response) => {
          console.log("SUCCESS!!", response);
          this.uploadFraud = response.data.data;
          console.log(this.uploadFraud, "upload");

          this.attachList[index].attachFilePath = this.uploadFraud.filePathName;

          this.attachList[index].attachFileName = this.uploadFraud.fileName;

          var ext = this.attachList[index].attachFileName.substring(
            this.attachList[index].attachFileName.lastIndexOf("."),
            this.attachList[index].length
          );

          this.ekstension[index] = ext;

          this.attachFileSize[index] = (
            event.target.files[0].size /
            (1024 * 1024)
          ).toFixed(2);

          var reader = new FileReader();
          reader.onload = (e) => {
            console.log("event reader", e);
            this.attachList[index].attachFileImage = e.target.result;
            // console.log(
            //   "imageData",
            //   this.fraudAttachment[index].attachFileImage
            // );
          };
          reader.readAsDataURL(event.target.files[0]);
        })
        .catch((err) => {
          this.isLoadingPhoto.splice(index, 1, false);
          console.log("error", err.response);
          this.$helpers.handleError(err);
        });
    },

    handleDownloadAttch(fileName, filePath) {
      this.downloadAttachment({
        base64Data: "",
        fileName: fileName,
        filePathName: filePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = fileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },

    handleRemoveFile(index) {
      this.attachList.splice(index, 1);
      this.ekstension.splice(index, 1);
      this.attachFileSize.splice(index, 1);
      this.isLoadingPhoto.splice(index, 1);
    },

    handleAddFraudAttachment() {
      this.attachList.push({
        // fraudId: this.getIdReview,
        attachFileName: "",
        attachFilePath: "",
        flowName: "Review Fraud",
        attachFileImage: "",
      });
      console.log("UPLOAD FILE", this.attachList);
      this.isLoadingPhoto.push(false);
    },

    hanldeGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.statusAngsuranList = response.data.data;
          console.log("ini ANGSURAN STATUS", this.statusAngsuranList);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleClickTipe(item) {
      console.log("ini item  tipe", item);
      item == "Ok"
        ? this.reviewFraud == "REVIEW_STATUS_OK"
        : this.reviewFraud == "REVIEW_STATUS_NOT_OK";
    },

    viewUnsur(fileName, filePath) {
      this.downloadAttachment({
        base64Data: null,
        fileName: fileName,
        filePathName: filePath,
      })
        .then((resp) => {
          if (fileName.substr(fileName.length - 3) == 'pdf' || fileName.substr(fileName.length - 3) == 'PDF') {
            var ieEDGE = navigator.userAgent.match(/Edge/g);
            var ie = navigator.userAgent.match(/.NET/g); // IE 11+
            var oldIE = navigator.userAgent.match(/MSIE/g);
            // var bytes = new Uint8Array(resp.data); //use this if data is raw bytes else directly pass resData
            var blob = new window.Blob([resp.data], { type: 'application/pdf' });

            if (ie || oldIE || ieEDGE) {
              window.navigator.msSaveBlob(blob, fileName);
            }
            else {
              var fileURL = URL.createObjectURL(blob);
              var win = window.open();
              win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')

            }
          } else {
            var blobImage = resp.data;
            var reader = new FileReader();

            reader.readAsDataURL(blobImage);
            reader.onloadend = function () {
              var base64data = reader.result;
              var image = new Image();
              image.src = base64data;

              var w = window.open("");
              w.document.write(image.outerHTML);
              return;
            };
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    openPelakuDialog(isi, fraud) {
      this.isiPelakuList = isi;
      this.netFraudText = fraud;
      this.pelakuFraudDialog = true;
      console.log("apa isi isiPelakuList", this.isiPelakuList);
    },
    handleOk() {
      this.reviewFraud = "REVIEW_STATUS_OK";
    },

    handleNotOk() {
      this.reviewFraud = "REVIEW_NOT_OK";
    },

    onExpansionPanelClick(index, event) {
      console.log("ini index expanel", index);
      if (
        event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        console.log("Panel is closing/now closed.");
        //this.handleSaveAuto(index);
      } else {
        console.log("Panel is opening/now open.");
      }
    },

    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },
  },

  watch: {
    tab() {
      console.log("ini tab dari watch", this.tab);
      if (this.tab == "review-fraud") {
        this.submitBtn = true;
      } else {
        this.submitBtn = false;
      }
    },
  },

  computed: {
    ...mapState(["token", "username"]),
    getIdReview: function () {
      return this.$route.params.fraudId;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
  },
};
</script>

<style src="./ReviewFraud.scss" lang="scss" />
