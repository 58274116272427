<template>
  <v-app>
     <v-container fluid>
      <v-row>
        <v-col md="6">
          <v-text-field
            v-model="informasiTitle"
            label="Judul *"
            class="mb-0"
            hide-details="auto"
            outlined
            placeholder=" "
          ></v-text-field>
        </v-col>
      </v-row> 
      <v-row>
        <v-col md="6">
          <v-textarea
            v-model="informasiDeskripsi"
            label="Deskripsi *"
            class="mb-0"
            hide-details="auto"
            outlined
            placeholder=" "
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value="date"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="informasiStartDate"
                label="Start Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="mb-0"
                placeholder=" "
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              :min="currentDate"
              v-model="inforStartDateVal"
              no-title
              scrollable
              @input="formatStartDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="informasiEndDate"
                label="End Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details="auto"
                class="mb-0"
                placeholder=" "
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              :min="isEdit ? currentDateMinusOneDay : currentDate"
              v-model="inforEndDateVal"
              no-title
              scrollable
              @input="formatEndDate()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            class="elevation-0 text-capitalize"
            color="primary"
            @click="editStopSellingUser"
            >Simpan</v-btn
          >
           <v-btn
            class="elevation-0 text-capitalize ml-1"
            color="primary"
            :to="`/informasi`"
            >Batal</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
//import { mapActions, mapGetters, mapState } from "vuex";
import { mapActions } from "vuex";
import moment from "moment";
//var dateFormat = require("dateformat");

export default {
  data() {
    return {
        informasiTitle: '',
        informasiDeskripsi: '',
        link: "",
        menu: false,
        menu2: false,
        informasiStartDate: null,
        inforStartDateVal: null,
        inforDateVal: null,
        informasiEndDate: null,

    };
  },
  async mounted() {
   // await this.loadData()
    //this.loadRegisteredUser();
  },
  created(){
   // this.requiredFeature('MST_PARAMETER')
    if(this.isEdit){
      this.changeTitleApp("Edit Parameter")
    } else {
      this.changeTitleApp("Add Parameter")
    }
  }, 
  methods: {
    ...mapActions(["changeTitleApp"]),
    formatStartDate() {
      this.menu = false;
      this.informasiStartDate = moment(this.inforStartDateVal).format("DD-MMM-YYYY");
    },
    formatEndDate() {
      this.menu2 = false;
      this.informasiEndDate = moment(this.inforEndDateVal).format("DD-MMM-YYYY");
    },
    isEndNow(start, end, endNowDate) {
      // console.log("END NOW", start, end, endNowDate)
      let startDate = moment(start, "DD-MMM-YYYY");
      let endDate = moment(end, "DD-MMM-YYYY");
      // console.log("start: ", startDate)
      // console.log("end: ", endDate)
      // console.log("sama dengan startDate atau setelah ?", moment(this.today).isSameOrAfter(startDate))
      // console.log("sama dengan endDate atau sebelum ?", moment(this.today.setHours(0, 0, 0, 0)).isSameOrBefore(endDate))
      return (
        endNowDate == null &&
        (moment(this.today.setHours(0, 0, 0, 0)).isSameOrAfter(startDate) &&
        moment(this.today.setHours(0, 0, 0, 0)).isSameOrBefore(endDate))
      );
    },
    compareDate(e, f) {
      let endDate = moment(e, "DD-MMM-YYYY");
      // console.log(endDate, "vs", this.currentDate ,"=", moment(endDate).isSameOrAfter(this.currentDate));
      return (
        f == null &&
        moment(endDate).isSameOrAfter(this.currentDate.setHours(0, 0, 0, 0))
      );
    },
    isHidden(b) {
      let startDate = moment(b, "DD-MMM-YYYY");
      return moment(startDate).isAfter(this.currentDate);
    },
    beforeToday(data) {
      let endDate = moment(data, "DD-MMM-YYYY");
      return moment(endDate).isSameOrAfter(this.currentDate.setHours(0, 0, 0, 0));
    },
  },  
  async loadData(){
    if(this.isEdit){
      await this.$store.dispatch("getParameterById", { parameterCodeId : this.parameterCode })
      .then((response) => {
        this.formData = response.data.data
      })
    }      
  },
   /* isReadOnly() {
      let startDate = moment(this.stopSellingById.effStartDate, "DD-MMM-YYYY");
      return moment(this.today.setHours(0,0,0,0)).isAfter(startDate);
    },
    loadRegisteredUser() {
        let pageNumber = 0;
        let pageSize = 100;
        this.$store
          .dispatch("getRegisteredUser", { pageSize, pageNumber })
          .then((res) => {
            this.totalData = res.data.countData;
            this.countData = res.data.countData;
          })
          .catch((err) => console.log(err));
      this.$store.dispatch("getUserType");
    },
    handlePageChange(value) {
      if (!this.statusSearch && (this.agentTypeRegistered !== '' || this.agentTypeRegistered !== null || this.name !== '')) {
        this.getRegisteredUser({
          pageNumber: value - 1,
          pageSize: this.perPage,
          name: '',
          agentType: ''
        });
      } else {
        this.getRegisteredUser({
          pageNumber: value - 1,
          pageSize: this.perPage,
          name: this.name,
          agentType: this.agentTypeRegistered
        });
      }
    }, */
    
  computed: {
   // ...mapGetters(["allRegisteredUser", "allUserType"]),
    //...mapState(["stopSellingById"]),
    /*idStopSelling: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    isView: function () {
      return this.mode === "view";
    },*/
  }  
};
</script>

