<template>
  <v-contianer fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-5">
      <h1 class="page-title">Karyawan</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">NIK atau Nama</p>
        <v-text-field
          label=""
          class="rp mb-0"
          hide-details="auto"
          v-model="searchValue"
          dense
          outlined
          placeholder="NIK atau Nama"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn
            @click="handleSearchKaryawan"
            class="elevation-0 text-capitalize ml-3 mt-2"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span>
          </v-btn>

          <v-btn
            class="elevation-0 text-capitalize ml-3 mt-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="handleResetKaryawan"
          >
            <span class="orange--text">Reset</span></v-btn
          >

          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize text-center"
              cols="12"
              @click="handleDownloadTemplate"
            >
              <v-icon aria-hidden="false" color="orange">
                mdi-file-download-outline </v-icon
              ><span class="orange--text ml-2">Download Template</span>
            </v-btn>
            <v-btn
              class="elevation-0 text-capitalize text-center"
              cols="12"
              @click="handleUploadClickTemplate"
            >
              <v-icon aria-hidden="false" color="orange">
                mdi-file-upload-outline </v-icon
              ><span class="orange--text ml-2">Upload</span>
            </v-btn>
            <input
              :ref="`uploaderTemplate`"
              class="d-none"
              type="file"
              @change="
                onFileChangedTemplate(
                  $event
                )
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- TABLE -->
    <v-row>
      <v-col cols="12" md="18">
        <div>
          <v-card cols="12">
            <v-card-text>
              <v-row style="widh: 100%">
                <v-col cols="12">
                  <v-data-table
                    :headers="headersKaryawan"
                    :items="allInquiryKaryawanValue"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null}"
                    disable-sort
                    mobile-breakpoint="0"
                  >
                    <template v-slot:item="data"> 
                      <tr>
                        <td>{{data.item.nik}}</td>
                        <td>{{data.item.name}}</td>
                        <td>{{data.item.dob}}</td>
                        <td>{{data.item.gender}}</td>
                        <td>{{data.item.maritalStatus}}</td>
                        <td>{{data.item.education}}</td>
                        <td>{{data.item.freshGrad}}</td>
                        <td>{{data.item.jointDate}}</td>
                        <td>{{data.item.status}}</td>
                        <td>{{data.item.terminationDate}}</td>
                        <td>{{data.item.assignmentCategory}}</td>
                        <td>{{data.item.permanentDate}}</td>
                        <td>{{data.item.position}}</td>
                        <td>{{data.item.organization}}</td>
                        <td>{{data.item.department}}</td>
                        <td>{{data.item.division}}</td>
                        <td>{{data.item.superior1Name}}</td>
                        <td align="center">
                          <v-dialog
                            v-model="dialogDel[data.item.nik]"
                            persistent
                            :key="data.item.nik"
                            width="unset"
                          >
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    v-on="{ ...dialog, ...tooltip }"
                                    v-bind="attrs"
                                  >
                                    <v-icon
                                      aria-hidden="false"
                                      style="font-size: 20px"
                                      color="#F5821F"
                                    >
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon
                                  class="icon"
                                  @click="
                                    dialogDel[data.item.nik] = false
                                  "
                                  style="float: right"
                                  >mdi-close</v-icon
                                >
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Karyawan?
                                  <p>
                                    {{ data.item.name }}
                                  </p>
                                </div>
                                <v-btn
                                  class="paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    $set(
                                      dialogDel,
                                      data.item.nik,
                                      false
                                    )
                                  "
                                >
                                  <span> Batalkan </span>
                                </v-btn>

                                <v-btn
                                  class="paramDialogDelBtn paramDialogBtn ma-1"
                                  color="primary"
                                  text
                                  @click="
                                    removeKaryawan(data.item.nik),
                                      $set(
                                        dialogDel,
                                        data.item.nik,
                                        false
                                      )
                                  "
                                >
                                  <span> Hapus </span>
                                </v-btn>
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-contianer>
</template>

<script>
import {mapActions, mapState} from "vuex";
export default {
  data() {
    return {
      dialogDel: {},
      headersKaryawan: [
        {
          text: "NIK",
          align: "center",
          width: "150px",
         
        },
        {
          text: "Nama Karyawan",
          align: "center",
          width: "150px",
        },
        {
          text: "Tanggal Lahir",
          align: "center",
          width: "150px",
         
        },
        {
          text: "Jenis Kelamin",
          align: "center",
          width: "150px",
        },
        {
          text: "Status Pernikahan",
          align: "center",
          width: "120px",
         
        },
        {
          text: "Pendidikan Terakhir",
          align: "center",
          width: "120px",
        
        },
        {
          text: "Fresh Graduate",
          align: "center",
          width: "120px",
         
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          width: "120px",
         
        },
        {
          text: "Status Karyawan",
          align: "center",
        },
        {
          text: "Tanggal Berhenti",
          align: "center",
        },
        {
          text: "Status Penugasan",
          align: "center",
        },
        {
          text: "Tanggal Permanen",
          align: "center",
        },
        {
          text: "Jabatan/Posisi",
          align: "center",
        },
        {
          text: "Organisasi",
          align: "center",
        },
        {
          text: "Department",
          align: "center",
        },
        {
          text: "Divisi",
          align: "center",
        },
        {
          text: "Atasan Langsung",
          align: "center",
        },
        {
          text: "Tindakan",
          align: "center",
        },
      ],

      searchValue: "",
      countData: null,
      totalData: null,
    };
  },

  created() {
    this.requiredFeature('KARYAWAN')
    this.changeTitleApp("Master Cabang");
  },

  mounted(){
    this.load();
  },

  methods: {
    ...mapActions(["changeTitleApp", "saveUploadKaryawan", "searchKaryawanValue","downloadTemplateKaryawan", "deleteKaryawan"]),

    //LOAD 
    load(){
      this.searchKaryawanValue({
        pageSize: 1000,
        pageNumber: 0,
        searchValue: this.searchValue
      }).then((resp) => {
         this.countData = resp.data.data;
         this.totalData = resp.data.data;
        
      }).catch((err) => {
        console.log("data karyawan tidak muncul", err);
        this.$store.state.inquiryKaryawanValue = [];
      })
    },  

    removeKaryawan(nik) {
      this.deleteKaryawan(nik)
        .then((response) => {
          this.load();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    // HANDLE
     handleDownloadTemplate(){
      this.downloadTemplateKaryawan().then((resp) => {
        console.log("response template karyawan oke", resp);
      }).catch((err) => {
        console.log("gagal download template", err.response);
        this.$$helpers.handleError(err);
      });
    },
    handleSearchKaryawan(){
      this.searchKaryawanValue({
        pageSize: 1000,
        pageNumber: 0,
        searchValue: this.searchValue
      }).then((resp) => {
         this.countData = resp.data.data;
        this.totalData = resp.data.data;
        this.inquiryKaryawanValue = resp.data.data;
        // console.log("berhasil di tampilkan seluruh data karyawan", this.inquiryKaryawanValue);
        
      })
    },

    handleResetKaryawan() {
      this.searchValue = ''
      this.handleSearchKaryawan()
    },

    handleUploadClickTemplate() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      
      // button click
      this.$refs["uploaderTemplate"].click();
    },

    onFileChangedTemplate(e) {
      let formData = new FormData();
      console.log("ini event", e);
      formData.append("file", e.target.files[0]);
      this.saveUploadKaryawan({
        formData: formData,
      })
        .then((resp) => {
          console.log("upload template karyawan oke", resp);
          this.handleSearchKaryawan();
        })
        .catch((err) => {
          console.log("gagal upload karyawan", err.response);
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/pctet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.$helpers.handleError(err);
        });
      console.log("ini tombol upload");
    },
  },

  computed: {
    ...mapState([""]),
    allInquiryKaryawanValue(){
      return Object.values(this.$store.getters.allInquiryKaryawanValue);
    }
  }
}
</script>


<style src="./Karyawan.scss" lang="scss" />