import Vue from "vue";
import App from "./App.vue";
// import router from './Routes'
import router from "./router/index";
import store from "./store/index";
import { sync } from "vuex-router-sync"; // Sync router with store
import vuetify from "./plugins/vuetify";
// import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import helpers from "./helpers";
import "./plugins/vuetify-money.js";
import interceptorsSetup from "./interceptors2";
// import interceptorTestSetup from './interceptorTest'
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";

// interceptorTestSetup()

Vue.use(CKEditor);

interceptorsSetup();

Vue.component("v-text-field", VTextField);

Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

const globalHelpers = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(globalHelpers);

Vue.use(Toast);
sync(store, router); // Sync store with router

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
//   },
// });

Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.mixin({
  data() {
    return {
      vMoneyDefaultOpts: {
        locale: "id-ID",
        prefix: "Rp",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },
  methods: {
    hasFeature(featureCd) {
      if (store.state.dataUser.length != 0) {
        const allowedFeature = store.state.dataUser.filter((feature) => {
          return feature.menuName == featureCd;
        });
        return allowedFeature.length > 0 ? true : false;
      } else {
        return true;
      }
    },
    requiredFeature(featureCd) {
      if (!this.hasFeature(featureCd)) {
        router.push({ name: "forbidden" });
      }
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    noChar($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        keyCode == 96 || // is `
        keyCode == 34 || // is "
        keyCode == 47 || // is /
        keyCode == 42 || // is *
        keyCode == 124 || // is |
        keyCode == 40 || // is (
        keyCode == 41 || // is )
        keyCode == 60 || // is <
        keyCode == 62 // is >
      ) {
        $event.preventDefault();
      }
    },
    pasteChar($event) {
      if ($event.clipboardData.getData("text").match(/[`"/*|()<>]/)) {
        $event.preventDefault();
      }
    },
    noCharSpecial($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode == 96 || keyCode == 34 || keyCode == 42 || keyCode == 124) {
        $event.preventDefault();
      }
    },
    pasteCharSpecial($event) {
      if ($event.clipboardData.getData("text").match(/[`"*|]/)) {
        $event.preventDefault();
      }
    },
    noCharLink($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        keyCode == 96 ||
        keyCode == 34 ||
        keyCode == 42 ||
        keyCode == 124 ||
        keyCode == 40 ||
        keyCode == 41 ||
        keyCode == 60 ||
        keyCode == 62
      ) {
        $event.preventDefault();
      }
    },
    pasteCharLink($event) {
      if ($event.clipboardData.getData("text").match(/[`"*|()<>]/)) {
        $event.preventDefault();
      }
    },
    numOnly($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    numOnlyAndMinus($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 45) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    humanFormat(number, options) {
      if (options === undefined) {
        options = {
          locale: "id-ID",
          prefix: "",
          suffix: "",
          length: 11,
          precision: 0,
        };
      }

      if (isNaN(number)) {
        number = 0;
      } else {
        // number = Number(number).toLocaleString(this.options.locale, {maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'BRL'});
        number = Math.floor(Number(number)).toLocaleString(options.locale, {
          maximumFractionDigits: options.precision,
          minimumFractionDigits: options.precision,
        });
      }
      return number;
    },
    decodeCaptchaNew(teks) {
      // Membersihkan teks dari karakter khusus
      teks = teks.replace(/[^0-9]/g, "");

      // Mengambil hanya 6 angka pertama
      var enamAngkaPertama = teks.slice(0, 6);
      return enamAngkaPertama;
    },
  },
}); 

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
