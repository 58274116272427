<template>
  <v-container fluid>
    <v-dialog width="1000" v-model="pelakuFraudDialog">
      <v-card>
        <v-card-title>Pelaku Fraud</v-card-title>
        <v-card-text>Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text>
        <v-card-text>
          <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList"
            :items-per-page="isiPelakuList.length" :header-props="{ sortIcon: null }" disable-sort>
            <template v-slot:item="data">
              <tr>
                <td>
                  {{ data.item.pelakuName }}
                </td>
                <td>
                  {{ data.item.pelakuNik }}
                </td>
                <td>
                  {{ data.item.pelakuPosition }}
                </td>
                <td>
                  {{ data.item.pelakuStatus }}
                </td>
                <td>
                  <vuetify-money v-model="data.item.netFraudDist" prefix="Rp" class="mb-0 mt-3" hide-details label=""
                    outlined dense v-bind:options="optionsMoney" @input="calculate()" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
              @click="(pelakuFraudDialog = false), clear()">
              <span class="orange--text">Cancel</span>
            </v-btn>
            <v-btn :disabled="totalFraud != netFraudText" class="elevation-0 text-capitalize mr-3" depressed
              color="primary" @click="(pelakuFraudDialog = false), dieditPelaku()">
              <span class="white--text">Save</span>
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="documentDialog">
      <v-card>
        <v-card-title>Upload Images</v-card-title>
        <v-card-text>Format file</v-card-text>
        <v-card-text>
          <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
            <v-col> {{ index + 1 }}. {{ document.documentTypeName }} </v-col>
            <v-col class="text-right pr-3">
              <v-btn color="primary" class="text-none pr-3" rounded depressed :loading="isSelecting"
                @click="onButtonClick(index)">
                <v-icon left> mdi-upload </v-icon>
                {{
                  document.attachFileName ? document.attachFileName : "Upload"
                }}
              </v-btn>
              <input :ref="`uploader` + index" class="d-none" type="file" accept="image/*, .doc, .docx, .pdf, .xls, .xlsx"
                @change="onFileChanged($event, index)" />
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" dense outlined placeholder="Keterangan" v-model="document.notes"
                @keypress="noChar" @paste="pasteChar"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
              @click="documentDialog = false">
              <span class="orange--text">Cancel</span>
            </v-btn>
            <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary"
              @click="(documentDialog = false), dieditDocument()">
              <span class="white--text">Save</span>
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-overlay :absolute="true" :value="isLoading">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog width="800" v-model="keteranganDialog">
      <v-card>
        <v-card-title>Keterangan</v-card-title>
        <v-card-text>
          <v-textarea v-model="catatan"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
              @click="keteranganDialog = false">
              <span class="orange--text">Cancel</span>
            </v-btn>
            <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="keteranganDialog = false">
              <span class="white--text">Save</span>
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="header d-flex mt-4 mb-1">
      <p class="titlet">Investigasi Fraud</p>
      <span class="titlet"></span>
      <p class="subTitle">Proses</p>
    </v-row>
    <v-col>
      <v-card rounded="lg">
        <v-tabs v-model="tab" center-active>
          <v-tab href="#data-investigator">Data Investigator</v-tab>
          <v-tab href="#detail-laporan" @click="handleDetailLaporan">Detail Laporan</v-tab>
          <v-tab href="#verifikasi-fraud" @click="handleVerifikasiFraud">Verifikasi Fraud</v-tab>
          <v-tab href="#tab-laporanPelengkap" @click="handleLaporanPelengkap" v-if="investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'">
            Laporan Pelengkap
          </v-tab>
          <v-tab href="#investigasi-fraud" @click="handleInvestigasiFraud">Investigasi Fraud</v-tab>

          <v-tab-item value="data-investigator">
            <v-card flat>
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="6">
                    <h7 class="label-text-field">Nama Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="namaInvestigator" disabled></v-text-field>
                    <h7 class="label-text-field">Jabatan/Posisi Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="jabatanInvestigator" disabled>
                    </v-text-field>
                    <h7 class="label-text-field">No. Telepon Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="picInvestigatorPhone" @keypress="numOnly"
                      :rules="[
                        (v) =>
                          v.length <= 24 ||
                          'No Telepon Investigator tidak boleh lebih dari 24 karakter',
                      ]"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h7 class="label-text-field">NIK Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="nikInvestigator" disabled></v-text-field>
                    <h7 class="label-text-field">Email Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="emailInvestigator" disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-fraud`" x-large>
                      <span class="orange--text">Back</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="detail-laporan">
            <v-card cols="12">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="12">
                    <h7 class="label-text-field">
                      No. Tiket JAGA
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="jagaNo" class="mb-0" hide-details="auto" outlined dense disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Pelaporan
                    </h7>
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="reportDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                          v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder="" disabled>
                        </v-text-field>
                      </template>
                      <v-date-picker :min="currentDate" v-model="inputStartDateVal" no-title scrollable
                        @input="formatStartDate()">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Jenis Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12">
                        <span class="primary--text">{{
                          fraudTypeName
                        }}</span>
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Sumber Informasi Awal Temuan Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field
                      v-model="fraudInf"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      filled
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="fraudInf == 'FRAUD_INF_OTHER'">
                    <h7 class="label-text-field" color="primary">
                      Lainnya
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="fraudInfReason" class="mb-0" hide-details="auto" outlined dense
                      placeholder="Masukan kode lokasi kerja terjadinya fraud" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="18">
                    <h7 class="label-text-field" color="primary">
                      Yang Terlibat
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table :headers="headersYangTerlibat" :items="fraudPelakuList" :items-per-page="5"
                          :header-props="{ sortIcon: null }" disable-sort>
                          <template v-slot:item="data">
                            <tr>
                              <td>{{ data.index + 1 }}</td>
                              <td>{{ data.item.pelakuNik }}</td>
                              <td>{{ data.item.pelakuUtama }}</td>
                              <td>{{ data.item.pelakuName }}</td>
                              <td>{{ data.item.pelakuPosition }}</td>
                              <td>{{ data.item.pelakuStatus }}</td>
                              <td>{{ data.item.pelakuPosition }}</td>
                              <td>{{ data.item.assignmentCategory }}</td>
                              <td>{{ data.item.pelakuJointDate }}</td>
                              <td>{{ data.item.pelakuEducation }}</td>
                            </tr>
                          </template>
                          <!-- <template v-slot:[`item.pelakuJointDate`]="{ item }">
                            {{ item.pelakuJointDate | momentDate }}
                          </template> -->
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Lokasi Kerja Terjadinya Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="fraudLocName" class="mb-0" hide-details="auto" outlined dense disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Kerugian Awal
                      <!-- <span class="grey--text ml-2">Wajib</span> -->
                    </h7>
                    <v-text-field v-model="initialLoss" prefix="Rp" class="mb-0" hide-details="auto" outlined dense
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Modus Operandi
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table hide-default-footer :headers="headersModus" :items="modusOperandiList"
                          :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                          <template v-slot:item="data">
                            <tr>
                              <td>
                                {{ data.index + 1 }}
                              </td>
                              <td>
                                <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                                  v-model="data.item.fraudMthd" :items="kodeModusList" item-text="paramName"
                                  item-value="paramCd" @change="
                                    handleGetKronologiList(
                                      data.item.fraudMthd,
                                      data.index
                                    )
                                    ">
                                </v-autocomplete>
                                <!-- {{ data.item.fraudMthdName }} -->
                              </td>
                              <td>
                                <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                                  v-model="data.item.incident" :items="kronologiList[data.index]" item-text="paramName"
                                  item-value="paramCd">
                                </v-autocomplete>
                                <!-- {{ data.item.incidentName }} -->
                              </td>
                              <td>
                                <v-text-field v-if="data.item.fraudMthd ==
                                  'FRAUD_MTHD_OTHER' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_ANGSURAN_2' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_BRW_2' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_FIKTIF_8' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_FPB_4' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_KAS_SOL_2' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_LEMARI_BESI_5' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_PELUNASAN_2' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_PENCURIAN_EXT_1' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_PENCURIAN_INT_1' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_SEBAGIAN_PENCAIRAN_4' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_SELURUH_PENCAIRAN_4' ||
                                  data.item.incident ==
                                  'FRAUD_MTHD_TABUNGAN_4'
                                  " class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                                  v-model="data.item.incidentDesc">
                                </v-text-field>
                                <!-- {{ data.item.incidentDesc }} -->
                                <v-text-field v-else class="mt-3" hide-details="auto" outlined dense disabled>
                                </v-text-field>
                              </td>
                              <td style="text-align: center">
                                <v-icon small color="red" @click="deleteRowOperandi(data.item)">
                                  mdi-delete
                                </v-icon>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <div class="tambah">
                      <v-btn v-model="modusOperandi" class="mb-0" hide-details="auto" dense placeholder="Tambah"
                        prepend-inner-icon="mdi-plus-box" @click="addFraudMethod">
                        <v-icon aria-hidden="false" color="orange">
                          mdi-plus-box-outline </v-icon><span class="orange--text ml-2">Tambah</span>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Attachment
                    </h7>
                    <v-col cols="12" v-for="item in attachment" :key="item">
                      <a @click="handleDownloadAttch(item)">
                        {{ item.attachFileName }}
                      </a>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-fraud`" x-large>
                      <span class="orange--text">Back</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="verifikasi-fraud">
            <v-card cols="12" md="18">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Verification Status
                      <span class="grey--text ml-2">Wajib</span>
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12">
                        <span class="primary--text">{{
                          verifStatusName
                        }}</span>
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Dokumen Awal Pelaporan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col>
                      <v-row>
                        <div v-for="(dokumen, index) in reportDocTypeList" :key="index">
                          <v-checkbox v-model="dokumen.reportDocType" :label="dokumen.reportDocTypeName" class="px-2"
                            outline readonly></v-checkbox>
                        </div>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Nominal SP Pelaku
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="spPelaku" class="mb-0" prefix="Rp" hide-details="auto" outlined dense
                      placeholder="0" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      SP Nasabah / Pihak Ketiga
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="spNasabah" class="mb-0" prefix="Rp" hide-details="auto" outlined dense
                      placeholder="" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Manager Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="investigationDelegatorName" class="mb-0" hide-details="auto" outlined dense
                      placeholder="Masukan Investigator Manager" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      No Hp Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="investigationDelegatorPhone" class="mb-0" hide-details="auto" outlined dense
                      placeholder="contoh: 0812xxxxxx" disabled></v-text-field>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Didelegasi Oleh
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large depressed outlined color="grey" disabled
                        style="
                          width: 170px;
                          height: 56px;
                          border: 1px solid #f5821f;
                          box-shadow: 0px 10px 20px
                            rgba(235, 127, 0, 0.1);
                          border-radius: 8px;
                        ">
                        <span class="primary--text">{{
                          investigationDelegatorPositionName
                        }}</span>
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Keterangan
                    </h7>
                    <v-textarea v-model="extFraudMthdDesc" class="mb-0" hide-details="auto" outlined dense placeholder=""
                      disabled></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-fraud`" x-large>
                      <span class="orange--text">Back</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-laporanPelengkap">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12" v-if="investigationDelegatorPosition ==
                  'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
                  ">
                  <h7 class="label-text-field" color="primary">
                    Attachment
                  </h7>
                  <v-col style="margin-left: -10px">
                  </v-col>
                  <v-row>
                    <v-div v-for="(item, index) in fraudAttAFMList" :key="index">
                      <div class="dropbox ml-1">
                        <input :rules="uploadRules" type="file" :name="uploadFieldName"
                          @change="uploadFile($event, index)" accept="image/*, application/pdf, application/msword"
                          class="input-file" ref="file" />
                        <v-div class="d-flex">
                          <v-icon v-if="!item.attachFilePath" aria-hidden="false" color="grey lighten-3"
                            class="mt-8 ml-13" size="65px">
                            mdi-image-plus
                          </v-icon>
                          <v-btn v-if="!item.attachFilePath" x-small outlined color="white" @click="removeFile(index)"
                            class="mt-1" style="margin-left: 20px">
                            <v-icon aria-hidden="false" color="orange">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </v-div>

                        <v-div class="jpg d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.jpg' ||
                              ekstension[index] == '.JPG'
                              " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.jpg' ||
                              ekstension[index] == '.JPG'
                              " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="png d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.png' ||
                              ekstension[index] == '.PNG'
                              " :src="item.attachFileImage" alt="" class="preview mt-8 ml-4" />
                            <v-btn v-if="ekstension[index] == '.png' ||
                              ekstension[index] == '.PNG'
                              " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="pdf d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.pdf' ||
                              ekstension[index] == '.PDF'
                              " src="@/assets/Pdf.svg" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.pdf' ||
                              ekstension[index] == '.PDF'
                              " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <v-div class="docx d-flex">
                          <v-row>
                            <img v-if="ekstension[index] == '.docx' ||
                              ekstension[index] == '.DOCX'
                              " src="@/assets/docx.svg" alt="" class="preview mt-8 ml-4" />

                            <v-btn v-if="ekstension[index] == '.docx' ||
                              ekstension[index] == '.DOCX'
                              " x-small outlined color="white" @click="removeFile(index)" class="mt-1 mr-1">
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </v-div>

                        <p v-if="!item.attachFileName" class="grey--text mt-4 ml-8">
                          Upload File {{ index + 1 }}
                          <v-progress-linear v-if="isLoadingPhoto[index]" indeterminate></v-progress-linear>
                        </p>
                        <p v-if="item.attachFileName" class="ml-6" style="text-align: initial">
                          {{ item.attachFileName }}
                        </p>
                        <p v-if="item.attachFileName" class="size ml-6">
                          {{ attachFileSize[index] }} mb
                        </p>
                      </div>
                    </v-div>

                    <div class="dropbox">
                      <v-btn x-large outlined color="white" @click="addFraudAttachment" class="mt-16">
                        <v-col>
                          <v-icon aria-hidden="false" color="orange" class="mt-5 mb-5 ml-4" size="35px">
                            mdi-plus
                          </v-icon>
                          <v-row>
                            <span class="orange--text ml-4">Tambah File</span>
                          </v-row>
                        </v-col>
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="investigasi-fraud">
            <v-form ref="investigasiForm">
              <v-card cols="12">
                <v-card-text>
                  <v-row class="mx-1">
                    <v-col>
                      <h7 class="label-text-field" color="primary">
                        Investigation Status
                      </h7>
                      <v-col align="left" style="margin-left: -12px">
                        <v-btn-toggle v-model="investigationStatus">
                          <v-btn v-for="(item, index) in investigationStatusList" :key="index" x-large :class="investigationStatus ==
                            item.paramCd
                            ? 'elevation-0 text-capitalize ml-3 primary--text'
                            : 'elevation-0 text-capitalize ml-3 grey--text'
                            " style="
                              width: 112px;
                              height: 48px;
                              border: 1px solid #f5821f;
                              box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                              border-radius: 8px;
                            " depressed outlined :color="investigationStatus ==
                              item.paramCd
                              ? 'primary'
                              : 'grey'
                              " cols="12" :value="item.paramCd">
                            {{ item.paramName }}</v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-col>
                    <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_NOT_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary" v-if="investigationStatus ==
                        'INVESTIGATION_STATUS_NOT_PROVEN'
                        ">
                        Notes
                      </h7>
                      <span class="grey--text ml-2" v-if="investigationStatus ==
                        'INVESTIGATION_STATUS_NOT_PROVEN'
                        ">Wajib</span>
                      <v-textarea v-model="note" @keypress="noChar" @paste="pasteChar" class="mb-0" hide-details="auto"
                        outlined dense placeholder="" v-if="investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                          " :rules="investigationStatus ==
                          'INVESTIGATION_STATUS_NOT_PROVEN'
                          ? notesRules
                          : notesNoRules
                          "></v-textarea>
                    </v-col>
                    <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Tujuan Fraud
                      </h7>
                      <span class="grey--text ml-2">Wajib</span>
                      <v-select item-text="paramName" :items="tujuanFraudList" label="" class="mb-0" hide-details="auto"
                        v-model="fraudFactor" item-value="paramCd" outlined dense clearable></v-select>
                    </v-col>
                    <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN' &&
                      fraudFactor == 'FRAUD_FACTOR_OTHER'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Lainnya
                      </h7>
                      <v-text-field v-model="fraudFactorDesc" class="mb-0" hide-details="auto" outlined dense
                        placeholder="Jelaskan secara rinci" @keypress="noChar" @paste="pasteChar"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Root Cause
                        <span class="ml-2 grey--text">(Optional)</span>
                      </h7>
                      <v-row style="width: 100%">
                        <v-col cols="12">
                          <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse" :items-per-page="10"
                            :header-props="{ sortIcon: null }" disable-sort>
                            <template v-slot:item="data">
                              <tr>
                                <td align="center">{{ data.index + 1 }}</td>
                                <td align="center">
                                  {{ data.item.rootCauseName }}
                                </td>
                                <td>
                                  <v-select ref="keteranganRootCause" :items="rootCauseStatus[data.index]"
                                    item-text="rootCauseName" item-value="rootCauseDesc" v-model="data.item.rootCauseList"
                                    return-object label="" class="mt-3 mb-3" hide-details="auto" outlined dense
                                    placeholder="" multiple chips deletable-chips :rules="mandatoryList[data.index] ==
                                      'Mandatory' &&
                                      investigationStatus ==
                                      'INVESTIGATION_STATUS_PROVEN'
                                      ? rootCauseRules
                                      : noRootCauseRules
                                      ">
                                  </v-select>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Tanggal Fraud Dilakukan
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu v-model="fraudCommitedDateMenu" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="fraudCommitedDate" label="" prepend-inner-icon="mdi-calendar"
                            v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                            placeholder="" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="fraudCommitedDateVal" :max="reportDateFormat" no-title scrollable
                          @input="fraudCommitedDatePicker()">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Tanggal Fraud Diketahui
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu v-model="fraudKnownDateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="fraudKnownDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                            v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                            clearable></v-text-field>
                        </template>
                        <v-date-picker :max="reportDateFormat" v-model="fraudKnownDateVal" no-title scrollable
                          @input="fraudKnownDatePicker()">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                      ">
                      <h7 class="label-text-field" color="primary">
                        Tanggal Terakhir Fraud
                        <!-- <span class="red--text"> *</span> -->
                      </h7>
                      <v-menu v-model="recoveryDateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="fraudCommitedDateTo" label="" prepend-inner-icon="mdi-calendar"
                            v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                            placeholder="" clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="recoveryDateVal" no-title scrollable @input="recoveryDatePicker()">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    
                    <v-col v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                      <p class="label-text-field mb-5">
                        Rincian Daftar Kerugian
                      </p>
                      <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                        hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data" item-key="pelakuId">
                          <tr v-if="!isMerged(data.item, data.index)">
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                              data.index) }}</td>
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                            <td>{{ data.item.modusName }}</td>
                            <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                            <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                            <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                            <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                            <td>{{ formatNumber(data.item.recovery) }}</td>
                            <td>{{ formatNumber(data.item.netFraud) }}</td>
                            <td>
                              <div v-if="data.item.pelakuUtama == 'N'">
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                  cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                  <span class="orange--text ml-2">Download List Nasabah</span>
                                </v-btn>
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Bukti</span>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                          <tr v-else>
                            <td>{{ data.item.modusName }}</td>
                            <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                            <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                            <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                            <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                            <td>{{ formatNumber(data.item.recovery) }}</td>
                            <td>{{ formatNumber(data.item.netFraud) }}</td>
                            <td>
                              <div v-if="data.item.pelakuUtama == 'N'">
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                  cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                  <span class="orange--text ml-2">Download List Nasabah</span>
                                </v-btn>
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Bukti</span>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template slot="body.append">
                          <tr>
                            <td colspan="4" align="center">Total</td>
                            <td>{{sumField('noa') === 0 ? "" : formatNumber(sumField('noa'))}}</td>
                            <td>{{sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond'))}}</td>
                            <td>{{sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan'))}}</td>
                            <td>{{sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss'))}}</td>
                            <td>{{sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery'))}}</td>
                            <td>{{sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud'))}}</td>
                        </tr>
                        </template>
                      </v-data-table>

                      <v-row>
                        <v-col cols="12">
                          <v-data-table :headers="headersJenisPelanggaran" :items="jenisPelanggaranListToSave"
                            :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                            <template v-slot:item="data">
                              <tr>
                                <td align="center">{{ data.index + 1 }}</td>
                                <td align="center">
                                  <v-dialog v-model="dialogEmployee[data.index]" :key="data.Index" max-width="780">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-bind="attrs" v-on="on" v-model="data.item.pelakuNik" class="mb-0"
                                        hide-details="auto" outlined dense></v-text-field>
                                    </template>
                                    <v-card>
                                      <v-card-text>
                                        <v-icon class="icon" @click="dialogEmployee[data.index] = false"
                                          style="float: right; margin-top: 12px">mdi-close
                                        </v-icon>
                                      </v-card-text>
                                      <v-card-title class="black--text">
                                        Yang Terlibat
                                      </v-card-title>
                                      <v-card-text class="mt-5">
                                        <span class="black--text">NIK atau Nama</span>
                                        <v-row>
                                          <v-text-field label="" class="mb-0 ml-3" hide-details="auto"
                                            v-model="searchValueEmployee" dense outlined placeholder="12345678"
                                            prepend-inner-icon="mdi-magnify" @click="searchEmployee"></v-text-field>
                                          <v-btn @click="searchEmployee" class="
                                                      elevation-0
                                                      text-capitalize
                                                      ml-5
                                                    " color="primary">
                                            <span class="white--text">Cari</span>
                                          </v-btn>
                                        </v-row>
                                        <v-data-table class="mt-5 mb-5" :headers="headersEmployee" :items="allEmployee"
                                          :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                          <template v-slot:item="dataf">
                                            <tr>
                                              <td align="center">
                                                {{ dataf.item.nik }}
                                              </td>
                                              <td align="center">
                                                {{ dataf.item.name }}
                                              </td>
                                              <td align="center">
                                                {{ dataf.item.position }}
                                              </td>
                                              <td align="center">
                                                {{ dataf.item.status }}
                                              </td>
                                              <td align="center">
                                                <v-btn small text class="
                                                  text-capitalize
                                                  black--text
                                                  px-0
                                                  " @click="
                                                    pilihFraudPelaku(
                                                      dataf.item,
                                                      data.item,
                                                      data.index
                                                    )
                                                    ">
                                                  <span class="orange--text">Pilih</span>
                                                </v-btn>
                                              </td>
                                            </tr>
                                          </template>
                                        </v-data-table>
                                      </v-card-text>
                                      <v-divider></v-divider>
                                    </v-card>
                                  </v-dialog>
                                </td>
                                <td align="center">
                                  {{ data.item.pelakuName }}
                                </td>
                                <td align="center">
                                  <v-switch @change="clearJenis(data.item)" :readonly="data.item.pelakuName == ''"
                                    v-model="data.item.pelakuUtama" :label="data.item.pelakuUtama == 'Y'
                                      ? 'Ya'
                                      : 'Tidak'
                                      " true-value="Y" false-value="N" inset color="orange darken-3" hide-details>
                                  </v-switch>
                                </td>
                                <td align="center">
                                  <v-text-field disabled v-model="data.item.assignmentCategory" class="mb-0" hide-details="auto" outlined
                                    dense></v-text-field>
                                </td>
                                <td style="text-align: center">
                                  <v-icon small color="red" @click="updateDetailPelakuList(data.item)">
                                    mdi-share-outline
                                  </v-icon>
                                  <v-icon small color="red" @click="deleteRowJenisPelanggaran(data.item)">
                                    mdi-delete
                                  </v-icon>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>

                          <v-dialog v-model="dialogDetailPelakuUtama" max-width="auto">
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Detail Pelaku</span>
                              </v-card-title>

                              <v-card-text>
                                <v-data-table :headers="headersDetailPelakuUtama" :items="detailPelakuUtamaList" class="elevation-1"
                                  disable-pagination hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                                  <template v-slot:item="data" item-key="pelakuDetailId">
                                    <tr>
                                      <td>{{ data.index + 1 }}</td>
                                      <td>{{ data.item.pelakuNik }}</td>
                                      <td>{{ data.item.pelakuName }}</td>
                                      <td>
                                        <v-select :items="lossList" item-text="paramName" item-value="paramCd"
                                          v-model="data.item.modus" label="" class="mt-3 mb-3" hide-details="auto" outlined dense
                                          clearable placeholder="" style="border-radius: 8px">
                                        </v-select>
                                      </td>
                                      <td>
                                        <v-text-field v-model.number="data.item.noa" type="number" class="mb-0" hide-details="auto"
                                          outlined dense>
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field v-model="data.item.plafond" class="mb-0" hide-details="auto" outlined dense 
                                          @input="updateValue(data.item, 'plafond')" @keypress="onlyNumber"
                                          :disabled="!(data.item.modus === 'LOSS_TYPE_SEBAGIAN_PENCAIRAN' || data.item.modus === 'LOSS_TYPE_SELURUH_PENCAIRAN' || data.item.modus === 'LOSS_TYPE_FIKTIF')">
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field v-model="data.item.tabungan" class="mb-0" hide-details="auto" outlined dense 
                                          @input="updateValue(data.item, 'tabungan')" @keypress="onlyNumber"
                                          :disabled="!(data.item.modus === 'LOSS_TYPE_SEBAGIAN_PENCAIRAN' || data.item.modus === 'LOSS_TYPE_SELURUH_PENCAIRAN' || data.item.modus === 'LOSS_TYPE_FIKTIF')">
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field v-model="data.item.potentialLoss" class="mb-0" hide-details="auto" outlined dense 
                                          @input="updateValue(data.item, 'potentialLoss')" @keypress="onlyNumber">
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field v-model="data.item.recovery" class="mb-0" hide-details="auto" outlined dense 
                                          @input="updateValue(data.item, 'recovery')" @keypress="onlyNumber">
                                        </v-text-field>
                                      </td>
                                      <td>
                                        {{ getActualLoss(data.item.potentialLoss, data.item.recovery, "string") }}
                                      </td>
                                      <td>
                                        <v-select :items="weakControlList" item-text="paramName" item-value="paramCd"
                                          v-model="data.item.weakControl" label="" class="mt-3 mb-3" hide-details="auto" outlined
                                          dense clearable placeholder="" style="border-radius: 8px">
                                        </v-select>
                                      </td>
                                      <td>
                                        <v-select :items="fixingList" item-text="paramName" item-value="paramCd"
                                          v-model="data.item.fixing" label="" class="mt-3 mb-3" hide-details="auto" outlined
                                          dense clearable placeholder="" style="border-radius: 8px">
                                        </v-select>
                                      </td>
                                      <td>
                                        <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                          @click="handleDownloadTemplate(data.item.modus)">
                                          <span class="orange--text ml-2">Download Template</span>
                                        </v-btn>
                                        <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                          @click="$refs['fileInputNasabah' + data.index].click()">
                                          <span class="orange--text ml-2">Upload List Nasabah</span>
                                        </v-btn>
                                        <input type="file" :ref="'fileInputNasabah' + data.index"
                                          @change="e => uploadInvestigasiFraud(e, ['nasabah'], [data.item.modus], [data.item.pelakuNik], data.index)"
                                          style="display: none" />
                                        <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                          @click="$refs['fileInputBukti' + data.index].click()">
                                          <span class="orange--text ml-2">Upload Bukti</span>
                                        </v-btn>
                                        <input type="file" :ref="'fileInputBukti' + data.index"
                                          @change="e => uploadInvestigasiFraud(e, ['bukti'], [data.item.modus], [data.item.pelakuNik], data.index)"
                                          style="display: none" />
                                      </td>
                                      <td>
                                        <v-icon small color="red" @click="deleteRowDetailPelakuUtama(data.item)">
                                          mdi-delete
                                        </v-icon>
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                                  @click="handleCancelDetailPelakuUtama">
                                  <span class="orange--text">Cancel</span>
                                </v-btn>
                                <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleSaveDetailPelakuUtama">
                                  <span class="white--text">Save</span>
                                </v-btn>
                                <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary"
                                  @click="handleAddDetailPelaku">
                                  <span class="white--text">Tambah</span>
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <v-dialog v-model="dialogDetailPelaku" max-width="auto">
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Detail Pelaku</span>
                              </v-card-title>

                              <v-card-text>
                                <v-data-table :headers="headersDetailPelaku" :items="detailPelakuList" class="elevation-1"
                                  disable-pagination hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                                  <template v-slot:item="data">
                                    <tr>
                                      <td>{{ data.index + 1 }}</td>
                                      <td>{{ data.item.pelakuNik }}</td>
                                      <td>{{ data.item.pelakuName }}</td>
                                      <td>{{ data.item.assignmentCategory }}</td>
                                      <td>
                                        <v-select :items="kodeModusList" item-text="paramName" item-value="paramCd"
                                          v-model="data.item.violationType" label="" class="mt-3 mb-3" hide-details="auto" outlined
                                          dense clearable placeholder="" style="border-radius: 8px">
                                        </v-select>
                                      </td>
                                      <td>
                                        <v-text-field v-model="data.item.modusDesc" class="mb-0" hide-details="auto" outlined dense>
                                        </v-text-field>
                                      </td>
                                      <td>
                                        <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                          @click="$refs['fileInputBukti' + data.index].click()">
                                          <span class="orange--text ml-2">Upload Surat Pernyataan</span>
                                        </v-btn>
                                        <input type="file" :ref="'fileInputBukti' + data.index"
                                          @change="e => uploadInvestigasiFraud(e, ['suratPernyataan'], [data.item.violationType], [data.item.pelakuNik], data.index)"
                                          style="display: none" />
                                      </td>
                                      <td>
                                        <v-icon small color="red" @click="deleteRowDetailPelaku(data.item)">
                                          mdi-delete
                                        </v-icon>
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                                  @click="handleCancelDetailPelaku">
                                  <span class="orange--text">Cancel</span>
                                </v-btn>
                                <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleSaveDetailPelaku">
                                  <span class="white--text">Save</span>
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <v-dialog v-model="validationDialog" persistent width="unset">
                            <v-card>
                              <div class="text-center">
                                <v-sheet style="
                                  flex-direction: column;
                                  justify-content: center;
                                  align-items: center;
                                  padding: 32px;
                                  position: relative;
                                  width: 349px;
                                  background: #ffffff;
                                  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                                  border-radius: 12px;
                                ">
                                  <img src="@/assets/Checklist.svg" alt="" style="
                                    position: static;
                                    left: 0%;
                                    right: 0%;
                                    top: 0%;
                                    bottom: 0%;
                                  " />
                                  <div style="
                                    position: static;
                                    left: calc(50% - 285px / 2);
                                    top: 164px;
                                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 26px;
                                    text-align: center;
                                    color: #2e3a59;
                                    flex: none;
                                    order: 1;
                                    align-self: stretch;
                                    flex-grow: 1;
                                    margin: 32px 0px;
                                  ">
                                    {{ validationText }}
                                  </div>
                                  <v-btn class="ma-1" text @click="validationDialog = false" style="
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    padding: 0px;
                                    width: 285px;
                                    height: 48px;
                                    background: #f5821f;
                                    border-radius: 8px;
                                    flex: none;
                                    order: 2;
                                    flex-grow: 0;
                                  ">
                                    <span style="
                                      position: static;
                                      width: 21px;
                                      height: 22px;
                                      left: 132px;
                                      top: 13px;
                                      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                      font-style: normal;
                                      font-weight: 600;
                                      font-size: 14px;
                                      line-height: 22px;
                                      display: flex;
                                      align-items: center;
                                      text-align: center;
                                      color: #ffffff;
                                      flex: none;
                                      order: 0;
                                      flex-grow: 0;
                                      margin: 10px 0px;
                                    ">
                                      OK
                                    </span>
                                  </v-btn>
                                </v-sheet>
                              </div>
                            </v-card>
                          </v-dialog>

                          <v-snackbar v-model="showSnackbar" :color="snackbarColor">
                            {{ snackbarMsg }}
                            <template v-slot:action="{ attrs }">
                              <v-btn text small v-bind="attrs" @click="showSnackbar = false">
                                X
                              </v-btn>
                            </template>
                          </v-snackbar>

                        </v-col>
                        <v-col class="text-right">
                          <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleSaveInvestigasiFraud">
                            <span class="white--text">Save</span>
                          </v-btn>
                          <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleAddJenisPelanggaran">
                            <span class="white--text">Tambah</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text-right">
                      <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                        :to="`/investigasi-fraud`" x-large>
                        <span class="orange--text">Back</span>
                      </v-btn>

                      <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                        @click="handleSaveAsDraft" x-large>
                        <span class="orange--text">Save as Draft</span>
                      </v-btn>

                      <!-- Menampilkan Popup 'Pergantian Manager' -->
                      <!-- Lebih dari 25 juta -->
                      <v-dialog
                        v-if="sumField('potentialLoss') > valueSBM && investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM'"
                        v-model="dialogPicAppr" max-width="900px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="elevation-0 text-capitalize mr-3" depressed color="primary" x-large>
                            <span class="white--text">Submit</span>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="black--text">
                            Pergantian Manager
                          </v-card-title>

                          <v-row class="mx-1" justify="end">
                            <!-- No Jaga -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">No JAGA</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="jagaNo" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto" outlined
                                    dense placeholder="Masukan No JAGA"
                                    :disabled="reportStatus == 'REPORT_STATUS_DELEGATE_INVESTIGATOR'">
                                  </v-text-field>
                                </template>
                              </v-dialog>
                            </v-col>

                            <!-- SBM -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">SBM</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="namaInvestigator" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                                    outlined dense placeholder="Masukan SBM"
                                    :disabled="reportStatus == 'REPORT_STATUS_DELEGATE_INVESTIGATOR'">
                                  </v-text-field>
                                </template>
                              </v-dialog>
                            </v-col>

                            <!-- QA Manager -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">QA Manager</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog v-model="dialogPicApprBC" max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="picApproverName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                                    outlined dense placeholder="Masukan QA Manager" @click="loadPicAppr">
                                  </v-text-field>
                                </template>

                                <v-card>
                                  <v-card-text>
                                    <v-icon class="icon" @click="dialogPicApprBC = false"
                                      style="float: right; margin-top: 12px">mdi-close</v-icon>
                                  </v-card-text>
                                  <v-card-title class="black--text">
                                    QA Manager
                                  </v-card-title>
                                  <v-card-text class="mt-5">
                                    <span class="black--text">NIK Atau Nama</span>
                                    <v-row>
                                      <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicAppr" dense
                                        outlined placeholder="Masukan nama QA Manager" prepend-inner-icon="mdi-magnify"></v-text-field>
                                      <v-btn @click="loadPicAppr" class="elevation-0 text-capitalize ml-5" color="primary">
                                        <span class="white--text">Cari</span>
                                      </v-btn>
                                    </v-row>
                                    <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicApprover"
                                      :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                      <template v-slot:item="allPicAppr">
                                        <tr>
                                          <td align="center">
                                            {{ allPicAppr.item.nik }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.name }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.position }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.organization }}
                                          </td>
                                          <td align="center">
                                            <v-btn small text class="text-capitalize black--text px-0"
                                              @click="pilihPicAppr(allPicAppr.item)">
                                              <span class="orange--text">Pilih</span>
                                            </v-btn>
                                          </td>
                                        </tr>
                                      </template>
                                    </v-data-table>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>

                            <!-- Delegasi -->
                            <v-btn x-large depressed class="elevation-0 text-capitalize mr-3 mb-3 primary--text" color="primary"
                              @click="handleSubmitInvestigasiFraud">
                              <span class="white--text">Delegasi</span>
                            </v-btn>

                            <!-- Cancle -->
                            <v-btn class="mr-3 elevation-0" height="50px" @click="dialogPicAppr = false">
                              <span>Cancle</span>
                            </v-btn>
                          </v-row>
                        </v-card>
                      </v-dialog>

                      <!-- Lebih dari 100 juta -->
                      <v-dialog
                        v-else-if="sumField('potentialLoss') > valueQAM && investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER'"
                        v-model="dialogPicAppr" max-width="900px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="elevation-0 text-capitalize mr-3" depressed color="primary" x-large>
                            <span class="white--text">Submit</span>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="black--text">
                            Pergantian Manager
                          </v-card-title>

                          <v-row class="mx-1" justify="end">
                            <!-- No Jaga -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">No JAGA</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="jagaNo" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto" outlined
                                    dense placeholder="Masukan No JAGA"
                                    :disabled="reportStatus == 'REPORT_STATUS_DELEGATE_INVESTIGATOR'">
                                  </v-text-field>
                                </template>
                              </v-dialog>
                            </v-col>

                            <!-- QA Manager -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">QA Manager</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="namaInvestigator" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                                    outlined dense placeholder="Masukan QA Manager"
                                    :disabled="reportStatus == 'REPORT_STATUS_DELEGATE_INVESTIGATOR'">
                                  </v-text-field>
                                </template>
                              </v-dialog>
                            </v-col>

                            <!-- AFM Head -->
                            <v-col cols="12">
                              <v-row class="verifStat ml-0">
                                <p class="label-text-field">AFM Head</p>
                                <span class="ml-2 mt-1">Wajib</span>
                              </v-row>

                              <v-dialog v-model="dialogPicApprBC" max-width="900px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="picApproverName" v-bind="attrs" v-on="on" class="mb-0" hide-details="auto"
                                    outlined dense placeholder="Masukan AFM Head" @click="loadPicAppr">
                                  </v-text-field>
                                </template>

                                <v-card>
                                  <v-card-text>
                                    <v-icon class="icon" @click="dialogPicApprBC = false"
                                      style="float: right; margin-top: 12px">mdi-close</v-icon>
                                  </v-card-text>
                                  <v-card-title class="black--text">
                                    AFM Head
                                  </v-card-title>
                                  <v-card-text class="mt-5">
                                    <span class="black--text">NIK Atau Nama</span>
                                    <v-row>
                                      <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicAppr" dense
                                        outlined placeholder="Masukan nama AFM Head" prepend-inner-icon="mdi-magnify"></v-text-field>
                                      <v-btn @click="loadPicAppr" class="elevation-0 text-capitalize ml-5" color="primary">
                                        <span class="white--text">Cari</span>
                                      </v-btn>
                                    </v-row>
                                    <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicApprover"
                                      :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                      <template v-slot:item="allPicAppr">
                                        <tr>
                                          <td align="center">
                                            {{ allPicAppr.item.nik }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.name }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.position }}
                                          </td>
                                          <td align="center">
                                            {{ allPicAppr.item.organization }}
                                          </td>
                                          <td align="center">
                                            <v-btn small text class="text-capitalize black--text px-0"
                                              @click="pilihPicAppr(allPicAppr.item)">
                                              <span class="orange--text">Pilih</span>
                                            </v-btn>
                                          </td>
                                        </tr>
                                      </template>
                                    </v-data-table>
                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                            </v-col>

                            <!-- Delegasi -->
                            <v-btn x-large depressed class="elevation-0 text-capitalize mr-3 mb-3 primary--text" color="primary"
                              @click="handleSubmitInvestigasiFraud">
                              <span class="white--text">Delegasi</span>
                            </v-btn>

                            <!-- Cancle -->
                            <v-btn class="mr-3 elevation-0" height="50px" @click="dialogPicAppr = false">
                              <span>Cancle</span>
                            </v-btn>
                          </v-row>
                        </v-card>
                      </v-dialog>

                      <!-- Kurang dari 25jt & 100jt -->
                      <v-btn
                        v-else-if="sumField('potentialLoss') < valueSBM && investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' ||
                          sumField('potentialLoss') < valueQAM && investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER'"
                        class="elevation-0 text-capitalize mr-3" depressed color="primary" x-large @click="handleSubmitInvestigasi">
                        <span class="white--text">Submit</span>
                      </v-btn>

                      <!-- Code Lama -->
                      <v-btn v-else class="elevation-0 text-capitalize mr-3" depressed color="primary" x-large
                        @click="handleSubmitInvestigasi">
                        <span class="white--text">Submit</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-col>
      <v-card rounded="lg">
        <v-card-text>
          <v-row class="mx-1">
            <v-col>
              <h7 class="label-text-field"> History </h7>
              <v-data-table :headers="headersHistory" :items="fraudLogList" :header-props="{ sortIcon: null }"
                disable-sort>
                <template v-slot:[`item.processDate`]="{ item }">
                  {{ item.processDate }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      headersEmployee: [
        {
          text: "NIK",
          align: "center",
          width: "91px",
        },
        {
          text: "Nama",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "150px",
        },
        {
          text: "Status",
          align: "center",
          width: "86px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "86px",
        },
      ],
      dialogEmployee: {},
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          width: "100px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        // {
        //   text: "Jenis Pelanggaran",
        //   align: "center",
        //   width: "250px",
        // },
        // {
        //   text: "Deskripsi",
        //   align: "center",
        //   width: "220px",
        // },
        {
          text: "Action",
          align: "center",
          width: "100px",
        },
      ],
      selectedData: null,
      handleSavePelakuCalled: false,
      handleSaveDetailPelakuUtamaCalled: false,
      handleSaveDetailPelakuCalled: false,
      deleteRowCalled: false,
      originalDetailPelakuUtamaList: [],
      originalDetailPelakuList: [],
      daftarKerugianList: [],
      pelakuListFinal: [],
      savePelakuList: [],
      savePelakuUtamaList: [],
      fraudPelakuListToSave: [],
      loadPelakuUtama: [],
      loadPelaku: [],
      selectedFile: null,
      files: {},
      originalFiles: {},
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafond (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
          colspan: "2",
        }
      ],
      detailPelakuUtamaList: [],
      detailPelakuList: [],
      savedDetailPelakuUtamaList: [],
      savedDetailPelakuList: [],
      dialogDetailPelakuUtama: false,
      dialogDetailPelaku: false,
      validationDialog: false,
      headersDetailPelakuUtama: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "150px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafond (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        // {
        //  text: "Root Cause",
        //  align: "center",
        //  width: "150px",
        // },
        {
          text: "Kelemahan Kontrol",
          align: "center",
          width: "150px",
        },
        {
          text: "Rekomendasi Perbaikan",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "525px",
        },
        {
          text: "Action",
          align: "center",
          width: "100px",
        }
      ],
      headersDetailPelaku: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian",
          align: "center",
          width: "150px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "300px",
        },
        {
          text: "Action",
          align: "center",
          width: "100px",
        }
      ],
      investigationStatusList: [],
      ekstension: [],
      isLoadingPhoto: [],
      attachFileSize: [],
      indexForSave: 0,
      detailLossToSaveAuto: [],
      detailLossToDeleteAuto: [],
      rootCauseApi: [],
      reportDateFormat: null,
      fraudCommitedDateFormat: null,
      totalFraud: 0,
      notesNoRules: [],
      notesRules: [(v) => !!v || "Notes Tidak Boleh Kosong"],
      namaInvestigator: "",
      nikInvestigator: "",
      jabatanInvestigator: "",
      emailInvestigator: "",
      picInvestigatorPhone: "",
      isFromSave: false,
      reportType: "",
      attachment: [],
      rootCauseRules: [(v) => v.length > 0 || "Keterangan harus diisi"],
      noRootCauseRules: [],
      mandatoryList: [],
      rootCauseStatus: [],
      rootCouse: [],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      fraudIdCopy: "",
      panelIndex: null,
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp",
        suffix: "",
        length: 12,
        precision: 0,
      },
      optionsPctg: {
        locale: "id-ID",
        prefix: "",
        suffix: "%",
        length: 12,
        precision: 2,
      },
      fraudAttList: [],
      fraudAttAFMList: [],
      fraudCommitedDateMenu: false,
      fraudCommitedDateVal: null,
      fraudKnownDateMenu: false,
      fraudKnownDateVal: null,
      recoveryDateMenu: false,
      fraudCommitedDateTo: null,
      recoveryDateVal: null,
      dialog: {},
      dialog2: {},
      dialog3: {},
      dialog4: {},
      dialog5: {},
      dialog6: {},
      dialog7: {},
      dialog8: {},
      dialog9: {},
      dialog10: {},
      dialog11: {},
      dialogEx: {},
      dialog2Ex: {},
      dialog3Ex: {},
      dialog4Ex: {},
      dialog5Ex: {},
      dialog6Ex: {},
      dialog7Ex: {},
      dialog8Ex: {},
      dialog9Ex: {},
      dialog10Ex: {},
      dialog11Ex: {},
      pelakuFraudDialog: false,
      documentDialog: false,
      picInvestigator: "",
      picInvestigatorPosition: "",
      picInvestigatorEmail: "",
      picInvestagorPhone: "",
      fraudLogList: [],
      jagaNo: "",
      reportDate: "",
      fraudTypeName: "",
      fraudInf: "",
      fraudInfReason: "",
      fraudPelakuList: [],
      fraudPelakuListPelakuFraud: [],
      fraudLocName: "",
      initialLoss: "",
      sharepointLink: "",
      verifStatusName: "",
      reportDocTypeList: [],
      spPelaku: "",
      spNasabah: "",
      investigationDelegatorName: "",
      investigationDelegatorPhone: "",
      investigationDelegatorPositionName: "",
      investigationDelegatorPosition: "",
      extFraudMthdDesc: "",
      returnDate: "",
      fraudCommitedDate: null,
      fraudControl: "",
      fraudDetail: "",
      fraudFactor: "",
      fraudFactorDesc: "",
      fraudKnownDate: null,
      fraudPurpose: "",
      fraudReason: "",
      investigationStatus: "",
      violationType: "",
      violationTypeDesc: "",
      note: "",
      expandPembiayaanFiktif: false,
      pembiayaanFiktifList: [],
      headersModus: [
        {
          text: "No",
          align: "center",
          width: "30px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "250px",
        },
        {
          text: "Kronologi Kejadian",
          align: "center",
          width: "400px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "150px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
        },
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "200px",
        },
      ],
      headersPic: [
        {
          text: "NIK",
          align: "center",
          width: "130px",
        },
        {
          text: "Nama",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "180px",
        },
        {
          text: "Organisasi",
          align: "center",
          width: "170px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "116px",
        },
      ],
      headersYangTerlibat: [
        {
          text: "No",
          align: "center",
          value: "no",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "assignmentCategory",
          width: "150px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
        },
      ],
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Janis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "170px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "200px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersHistory: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Date",
          align: "center",
          value: "processDate",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Processed By",
          align: "center",
          value: "processBy",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "250px",
          value: "processComment",
          class: "tableHeader ",
        },
      ],
      dataInvestigasiById: null,
      jenisPelanggaranList: [],
      modusOperandiList: [],
      kodeModusList: [],
      weakControlList: [],
      fixingList: [],
      kronologiList: [],
      deleteJenisPelanggaran: [],
      deleteIndex: null,
      deleteIndexJenisPelanggaran: null,
      deleteIndexLoadDataPelaku: null,
      deleteIndexDetailPelakuUtama: null,
      deleteIndexDetailPelaku: null,
      defaultListModus: {
        fraudId: "",
        fraudMthd: "",
        fraundMthdName: "",
        incident: "",
        incidentDesc: "",
        incidentName: "",
        seqNo: 0,
      },
      defaultDetailLossList: {
        angsuranStatus: "", //NEW
        appIdNo: "",
        brwCategory: "", //NEW
        createBy: "",
        createDate: "",
        detailLossDistList: [],
        detailLossDocList: [],
        detailLossId: 0,
        detailLossTempId: 0,
        fpbTransactionDate: "", //NEW DATE
        fraudId: 0,
        fraudRepayDate: "", //NEW DATE
        itemName: "",
        kasSolCategory: "", //NEW
        lossType: "",
        lossTypeName: "", //NOT ANYMORE
        mthdDetailDesc: "",
        nasabahName: "",
        netFraud: 0,
        notes: "",
        outstandingKaryawan: 0, //NEW
        outstandingMargin: 0,
        outstandingNasabah: 0, //NEW
        outstandingPelunasan: 0, //NEW
        outstandingPokok: 0,
        outstandingTotal: 0, //NEW
        ownershipStatus: "",
        ownershipStatusName: "", //NOT ANYMORE
        pelakuPencurian: "", //NEW
        pembiayaanKaryawan: 0, //NEW
        pembiayaanKaryawanPercentage: 0, //NEW
        pembiayaanNasabah: 0, //NEW
        pembiayaanNasabahPercentage: 0, //NEW
        pencairanDate: "",
        plafond: 0,
        potentialLoss: 0,
        prsCollection: "", //NEW
        recordFlag: "N",
        recoveryBranch: 0,
        recoveryNasabah: 0,
        sentraName: "",
        tabunganBalance: 0,
        tabunganNo: "",
        transaksiType: "", //NEW
        updateBy: "",
        updateDate: "",
        version: "",
      },
      lossList: [],
      indexExpand: 0,
      indexTable: 0,
      pelakuFraudList: [],
      isiPelakuList: [],
      catatan: "",
      keteranganDialog: false,
      isiDocumentList: [],
      sumberInformasiList: [],
      tujuanFraudList: [],
      lossChosen: {
        paramValue: 5,
      },
      statusAngsuranList: [],
      transaksiTypeList: [],
      prsCollectionList: [],
      brwCategoryList: [],
      kasSolCategoryList: [],
      pelakuPencurianList: [],
      pencairanDateMenu: [],
      pencairanDateVal: [],
      pencairanDateMenu2: [],
      pencairanDateVal2: [],
      pencairanDateMenu3: [],
      pencairanDateVal3: [],
      fraudRepayDateMenu: [],
      fraudRepayDateVal: [],
      fraudRepayDateMenu2: [],
      fraudRepayDateVal2: [],
      fraudRepayDateMenu3: [],
      fraudRepayDateVal3: [],
      fraudRepayDateMenu4: [],
      fraudRepayDateVal4: [],
      fpbTransactionDateMenu: [],
      fpbTransactionDateVal: [],

      pencairanDateMenuExpand: [],
      pencairanDateMenu2Expand: [],
      pencairanDateMenu3Expand: [],
      fraudRepayDateMenuExpand: [],
      fraudRepayDateMenu2Expand: [],
      fraudRepayDateMenu3Expand: [],
      fraudRepayDateMenu4Expand: [],
      fpbTransactionDateMenuExpand: [],

      allEmployee: [],
      searchValueEmployee: '',
      searchValuePicAppr: '',
      investigatorManager: '',
      dialogPicAppr: false,
      dialogPicApprBC: false,
      flagLoadLaporan: false,
      flagLoadVerifikasi:false,
      flagLoadPelengkap: false,
      flagLoadInvestigasi:false,
    };
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    momentDateHistory: function (date) {
      return moment(date).format("DD-MMM-YYYY HH:mm:ss");
    },
  },
  async created() {
    this.requiredFeature('INVESTIGASI_FRAUD')
    this.changeTitleApp("Investigasi Fraud");
    this.fraudIdCopy = this.fraudId;

    this.handleInitialData();
  },
  watch: {
    'data.item.netFraud': function () {
      return this.data.item.potentialLoss - this.data.item.recovery;
    },
    detailPelakuUtamaList: {
      handler: function(after) {
        for (let i = 0; i < after.length; i++) {
          if (after[i].modus !== 'LOSS_TYPE_SEBAGIAN_PENCAIRAN' && after[i].modus !== 'LOSS_TYPE_SELURUH_PENCAIRAN' && after[i].modus !== 'LOSS_TYPE_FIKTIF') {
            this.$set(this.detailPelakuUtamaList[i], 'plafond', 0);
            this.$set(this.detailPelakuUtamaList[i], 'tabungan', 0);
          }
        }
      },
      deep: true
    }
  },

  mounted() {
    this.searchEmployee();
    this.handleSearchFraudPelaku();
    this.handleFetchDaftarKerugian();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getDataInvestigasiByFraudId",
      "saveInvestigasi",
      "submitInvestigasi",
      "getParameterByParamType",
      "uploadInvestigasi",
      "searchDataRinDafKerugianByFraudId",
      "downloadTemplateDaftarKerugian",
      "saveUploadRincianDaftarKerugian",
      "getDataKerugianDocumentByFraudId",
      "getRincianDafKerugianPelakuByFraudId",
      "downloadDataRekapKerugian",
      "downloadDataRincianKerugian",
      "downloadAttachment",
      "getEmployeeByNik",
      "getInquiryLovTerlibat",
      "saveInvestigasiFraud",
      "searchFraudPelaku",
      "uploadFilePelaku",
      "downloadFilePelaku",
      "getInquiryPic",
      "submitVerifikasiData",
      "getInitialData",
      "getDataInvestigatorByFraudId",
      "getFraudHistoryByFraudId",
      "getDetailLaporanByFraudId",
      "getYangTerlibatByFraudId",
      "getModusOperandiByFraudId",
      "getAttachmentByFraudId",
      "getVerifikasiFraudByFraudId",
      "getDokumenAwalByFraudId",
      "getDataInvestigasiFraudByFraudId",
      "getRootCauseByFraudId",
      "getAttachmentAFMByFraudId",
    ]),

    handleInitialData() {
      this.getInitialData({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.reportType = resp.data.data.reportType;
        this.investigationDelegatorPosition = resp.data.data.delegatorPosition;
        this.reportStatus = resp.data.data.reportStatus;

        this.handleDataInvestigator();        
        this.handleFraudHistory();
      })
      .catch((err) => {
        console.log("handleInitialData ERR", err);
      });
    },

    handleDataInvestigator() {
      this.getDataInvestigatorByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.namaInvestigator = resp.data.data.investigatorName;
        this.nikInvestigator = resp.data.data.investigatorNik;
        this.jabatanInvestigator = resp.data.data.investigatorPosition;
        this.emailInvestigator = resp.data.data.investigatorEmail;
        this.picInvestigatorPhone = resp.data.data.investigatorPhone;
      })
      .catch((err) => {
        console.log("handleDataInvestigator ERR", err);
      });
    },

    handleFraudHistory() {
      this.getFraudHistoryByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.fraudLogList = resp.data.data;
      })
      .catch((err) => {
        console.log("handleFraudHistory ERR", err);
      });
    },

    handleDetailLaporan() {
      if (!this.flagLoadLaporan) {
        this.flagLoadLaporan = true;
        
        this.handleGetKodeModus();
        this.getDetailLaporan();
        this.getYangTerlibat();
        this.getModusOperandi();
        this.getAttachment();
      }
    },

    getDetailLaporan() {
      this.getDetailLaporanByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.jagaNo = resp.data.data.jagaNo;
        this.reportDate = moment(resp.data.data.tanggalLaporan).format("DD-MMM-YYYY");
        this.fraudTypeName = resp.data.data.jenisFraudStr;
        this.fraudInf = resp.data.data.sumberInformasiStr;
        this.fraudInfReason = resp.data.data.sumberInformasiDesc;
        this.fraudLocName = resp.data.data.lokasiFraudNm;
        this.initialLoss = this.humanFormat(resp.data.data.kerugianAwal);
      })
      .catch((err) => {
        console.log("getDetailLaporan ERR", err);
      });
    },

    getYangTerlibat() {
      this.getYangTerlibatByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.fraudPelakuList = resp.data.data;
      })
      .catch((err) => {
        console.log("getYangTerlibat ERR", err);
      });
    },

    getModusOperandi() {
      this.getModusOperandiByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.modusOperandiList = resp.data.data;
        for (var i = 0; i < this.modusOperandiList.length; i++) {
            this.kronologiList?.push([]);
            
            if (this.modusOperandiList[i].fraudMthd != null) {
              this.handleGetKronologiList(
                this.modusOperandiList[i].fraudMthd,
                i
              );
            }
          }
      })
      .catch((err) => {
        console.log("getModusOperandi ERR", err);
      });
    },

    getAttachment() {
      this.getAttachmentByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.attachment = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachment ERR", err);
      });
    },

    handleVerifikasiFraud() {
      if (!this.flagLoadVerifikasi) {
        this.flagLoadVerifikasi = true;

        this.getVerifikasiFraud();
        this.getDokumenAwal();
      }
    },

    getVerifikasiFraud() {
      this.getVerifikasiFraudByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.verifStatus = resp.data.data.verifikasiStatus;
        this.verifStatusName = resp.data.data.verifikasiStatusNm; 
        this.spPelaku = resp.data.data.spPelaku;
        this.spNasabah = resp.data.data.spNasabah;
        this.investigationDelegatorName = resp.data.data.delegatorName;
        this.investigationDelegatorPhone = resp.data.data.delegatorPhone;
        this.investigationDelegatorPositionName = resp.data.data.delegatorPositionNm;
        this.extFraudMthdDesc = resp.data.data.keterangan;
      })
      .catch((err) => {
        console.log("getVerifikasiFraud ERR", err);
      });
    },

    getDokumenAwal() {
      this.getDokumenAwalByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.reportDocTypeList = resp.data.data;
      })
      .catch((err) => {
        console.log("getDokumenAwal ERR", err);
      });
    },

    handleLaporanPelengkap() {
      if (!this.flagLoadPelengkap) {
        this.flagLoadPelengkap = true;

        this.getAttachmentAFM();
      }
    },

    handleInvestigasiFraud() {
      if (!this.flagLoadLaporan) {
        this.getDetailLaporan();
      }

      if (!this.flagLoadInvestigasi) {
        this.flagLoadInvestigasi = true;

        this.handleVerifikasiFraud()
        this.loadInvestigationStatus();
        this.handleGetTujuanFraud();
        this.loadStatusRoot();
        this.loadModusOperandi();
        this.loadKelemahanKontrol();
        this.loadRekomendasiPerbaikan();
        this.getInvestigasiFraud();
        this.getRootCause();
      }
    },

    loadModusOperandi() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramType: "LOSS_TYPE",
      })
      .then((resp) => {
        this.lossList = resp.data.data;
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },

    loadKelemahanKontrol() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramType: "WEAK_CONTROL",
      })
      .then((resp) => {
        this.weakControlList = resp.data.data;
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },

    loadRekomendasiPerbaikan() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramType: "FIXING",
      })
      .then((resp) => {
        this.fixingList = resp.data.data;
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },

    getInvestigasiFraud() {
      this.getDataInvestigasiFraudByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.investigationStatus = resp.data.data.investigationStatus;
        // this.note = this.dataInvestigasiById.note;
        this.fraudFactor = resp.data.data.fraudFactor;
        this.fraudFactorDesc = resp.data.data.fraudFactorDesc;
        this.fraudCommitedDate = resp.data.data.fraudCommitedDt !== null ? moment(resp.data.data.fraudCommitedDt).format("DD-MMM-YYYY") : null;
        this.fraudKnownDate = resp.data.data.fraudKnownDt !== null ? moment(resp.data.data.fraudKnownDt).format("DD-MMM-YYYY") : null;
        this.fraudCommitedDateTo = resp.data.data.fraudCommitedDtTo !== null ? moment(resp.data.data.fraudCommitedDtTo).format("DD-MMM-YYYY") : null;
      })
      .catch((err) => {
        console.log("getInvestigasiFraud ERR", err);
      });
    },

    getRootCause() {
      this.getRootCauseByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.rootCauseApi = resp.data.data;
        
        if (this.rootCauseApi.length != 0) {
          this.rootCauseApi.map((item) => {
            let indexs = this.rootCouse.findIndex(
              (x) => x.rootCause === item.rootCause
            );
            this.rootCouse.splice(indexs, 1, item);
          });
        }
      })
      .catch((err) => {
        console.log("getRootCause ERR", err);
      });
    },

    getAttachmentAFM() {
      this.getAttachmentAFMByFraudId({
        fraudId: this.fraudId,
      })
      .then((resp) => {
        this.fraudAttAFMList = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachmentAFM ERR", err);
      });
    },

    handleSaveInvestigasiFraud() {
      if (!this.handleSaveDetailPelakuUtamaCalled && !this.handleSaveDetailPelakuCalled) {
        if(!this.deleteRowCalled) {
          this.jenisPelanggaranListToSave = this.loadDataPelakuList = [...this.jenisPelanggaranListToSave];
        } 
        this.handleSaveDetailPelakuUtama()
        this.handleSaveDetailPelaku()
      } 

      const hasEmptyNik = this.jenisPelanggaranListToSave.some(item => item && !item.pelakuNik);
      if (hasEmptyNik) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "NIK Pelaku tidak boleh kosong",
          snackbarColor: "red",
        });
        return;
      }

      let formData = new FormData();
      for (let key in this.files) {
        formData.append(`files`, this.files[key].file);
        formData.append(`type`, this.files[key].type);
        formData.append(`modus`, this.files[key].modus);
      }

      if (formData.getAll('files').length !== 0) {
        this.uploadFilePelaku(formData)
          .then((response) => {
            console.log("response", response);

            if (response.data.status === "1") {
              const { bukti, nasabah } = response.data.data;
              this.jenisPelanggaranListToSave = this.jenisPelanggaranListToSave.map(item => {
                const newDetails = item.pelakuDetails ? item.pelakuDetails.map(detail => {
                  const nasabahIndex = nasabah.findIndex(n => n.modus === detail.modus);
                  const buktiIndex = bukti.findIndex(b => b.modus === detail.modus);

                  return {
                    ...detail,
                    nasabahAttName: nasabahIndex !== -1 && nasabah[nasabahIndex].fileName ? nasabah[nasabahIndex].fileName : detail.nasabahAttName,
                    nasabahAttPath: nasabahIndex !== -1 && nasabah[nasabahIndex].filePathName ? nasabah[nasabahIndex].filePathName : detail.nasabahAttPath,
                    evidenceAttName: buktiIndex !== -1 && bukti[buktiIndex].fileName ? bukti[buktiIndex].fileName : detail.evidenceAttName,
                    evidenceAttPath: buktiIndex !== -1 && bukti[buktiIndex].filePathName ? bukti[buktiIndex].filePathName : detail.evidenceAttPath,
                  };
                }) : [];

                return {
                  ...item,
                  pelakuDetails: newDetails,
                };
              });

              this.files = {};
              formData = new FormData();
            }

            console.log(this.jenisPelanggaranListToSave, "finalSaveData")
            this.saveInvestigasiFraud({
              fraudId: this.fraudId,
              fraudPelakuList: this.jenisPelanggaranListToSave
            }).then(response => {
              this.handleClearList();
              this.handleFetchDaftarKerugian();
              this.handleSaveDetailPelakuUtamaCalled = false
              this.handleSaveDetailPelakuCalled = false
              this.deleteRowCalled = false
              console.log('response', response)
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: "Data berhasil disimpan",
                snackbarColor: 'green',
              });
            }).catch((err) => {
              console.log(err)
              this.$store.commit("setShowSnackbar", {
                snackbarMsg: "Terjadi kesalahan saat menyimpan data",
                snackbarColor: "red",
              });
              return;
            });
          })
          .catch((err) => {
            this.$helpers.handleError(err);
            this.$store.commit("setShowSnackbar", {
              snackbarMsg: "FIles gagal disimpan",
              snackbarColor: "red",
            });
            return;
          });
      } else {
        // Jika formData kosong, langsung panggil saveInvestigasiFraud
        this.saveInvestigasiFraud({
          fraudId: this.fraudId,
          fraudPelakuList: this.jenisPelanggaranListToSave
        }).then(response => {
          this.handleClearList();
          this.handleFetchDaftarKerugian();
          this.handleSaveDetailPelakuUtamaCalled = false
          this.handleSaveDetailPelakuCalled = false
          this.deleteRowCalled = false
          console.log('response', response)
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Data berhasil disimpan",
            snackbarColor: 'green',
          });
          return;
        }).catch((err) => {
          console.log(err)
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Terjadi kesalahan saat menyimpan data",
            snackbarColor: "red",
          });
          return;
        });
      }
    },
    handleClearList() {
      this.detailPelakuUtamaList = []
      this.detailPelakuList = []
      this.savedDetailPelakuUtamaList = []
      this.savedDetailPelakuList = []
      this.originalDetailPelakuUtamaList = []
      this.originalDetailPelakuList = []
    },
    handleFetchDaftarKerugian() {
      this.searchFraudPelaku(this.fraudId)
        .then(response => {
          this.daftarKerugianList = response.data.data
          this.loadDataPelaku(response);
          console.log(this.savedDetailPelakuUtamaList, "Load Pelaku Utama From handleFetch")
          console.log(this.savedDetailPelakuList, "Load Pelaku Bukan Utama From handleFetch")
          console.log(this.daftarKerugianList, "Load Tabel Daftar Kerugian From handleFetch")
        })
    },
    loadDataPelaku(response) {
      let potentialLossValue = 0;
      this.savedDetailPelakuUtamaList = response.data.data
        .filter(item => item.pelakuUtama === 'Y')
        .map(item => {

          potentialLossValue += item.potentialLoss
          console.log("Cek potentialLossValue: ", potentialLossValue)

          return {
            pelakuId: item.pelakuId,
            pelakuDetailId: item.pelakuDetailId,
            pelakuName: item.pelakuName,
            pelakuUtama: item.pelakuUtama,
            pelakuNik: item.pelakuNik,
            fixing: item.fixing,
            modus: item.modus,
            modusName: item.modusName,
            violationType: item.modus,
            modusDesc: item.modusDesc,
            netFraud: item.netFraud,
            noa: item.noa,
            plafond: item.plafond,
            potentialLoss: item.potentialLoss,
            recovery: item.recovery,
            rootCause: item.rootCause,
            tabungan: item.tabungan,
            weakControl: item.weakControl,
            assignmentCategory: item.pelakuStatus,
            nasabahAttName: item.nasabahAttName,
            nasabahAttPath: item.nasabahAttPath,
            evidenceAttName: item.evidenceAttName,
            evidenceAttPath: item.evidenceAttPath,
          };
        });

      this.originalDetailPelakuUtamaList = JSON.parse(JSON.stringify(this.savedDetailPelakuUtamaList));

      this.savedDetailPelakuList = response.data.data
        .filter(item => item.pelakuUtama === 'N')
        .map(item => {
          return {
            pelakuId: item.pelakuId,
            pelakuDetailId: item.pelakuDetailId,
            pelakuName: item.pelakuName,
            pelakuNik: item.pelakuNik,
            pelakuUtama: item.pelakuUtama,
            violationType: item.modus,
            modusName: item.modusName,
            modusDesc: item.modusDesc,
            assignmentCategory: item.pelakuStatus,
            nasabahAttName: item.nasabahAttName,
            nasabahAttPath: item.nasabahAttPath,
            evidenceAttName: item.evidenceAttName,
            evidenceAttPath: item.evidenceAttPath,
          };
        });

      this.originalDetailPelakuList = JSON.parse(JSON.stringify(this.savedDetailPelakuList));

      this.jenisPelanggaranListToSave = response.data.data.reduce((unique, item) => {
        let existingItem = unique.find(uniqueItem => uniqueItem.pelakuNik === item.pelakuNik);

        let pelakuDetails = item.pelakuUtama === 'Y' ? this.savedDetailPelakuUtamaList : this.savedDetailPelakuList;
        if (item.pelakuUtama === 'N') {
          pelakuDetails = pelakuDetails.map(detail => {
            return {
              ...detail,
              modus: detail.violationType,
            };
          });
        }

        if (existingItem) {
          existingItem.pelakuDetails = [...new Set([...existingItem.pelakuDetails, ...pelakuDetails.filter(detail => detail.pelakuNik === item.pelakuNik)])];
        } else {
          unique.push({
            ...item,
            pelakuDetails: pelakuDetails.filter(detail => detail.pelakuNik === item.pelakuNik),
          });
        }

        return unique;
      }, []);

      this.jenisPelanggaranListToSave = this.jenisPelanggaranListToSave.map(item => {
        item.pelakuDetails = item.pelakuDetails.filter(detail => detail.modus !== null);
        return item;
      });

      this.loadDataPelakuList = JSON.parse(JSON.stringify(this.jenisPelanggaranListToSave));
      // this.loadDataPelakuList = [...this.jenisPelanggaranListToSave];
      console.log(this.loadDataPelakuList, "load Data Pelaku List")
    },
    isMerged(item, index) {
      if (index === 0) return false;
      return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    handleSearchFraudPelaku() {
      this.searchFraudPelaku(this.fraudId)
        .then(response => {
          console.log('response', response)
          this.fraudPelakuList = response.data.data
          this.loadDataPelaku(response);
          console.log(this.savedDetailPelakuUtamaList, "Load Pelaku Utama From handleSearch")
          console.log(this.savedDetailPelakuList, "Load Pelaku Bukan Utama From handleSearch")
          console.log(this.daftarKerugianList, "Load Tabel Daftar Kerugian From handleSearch")
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    pilihFraudPelaku(item, indexTable, index) {

      this.dialogEmployee[index] = false;

      indexTable.pelakuNik = item.nik;
      indexTable.pelakuName = item.name;
      indexTable.assignmentCategory = item.status;
    },
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    formatNumberString(value) {
      return value.toLocaleString('id-ID');
    },
    onlyNumber(event) {
      let charCode = (event.which) ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    updateValue(item, key) {
      const formattedNumber = item[key].toString().replace(/[^0-9]/g, '');
      if (formattedNumber.length > 0) {
        item[key] = this.formatNumber(formattedNumber);
      }
      console.log("item[key]", item[key]);
    },
    searchEmployee() {
      this.getInquiryLovTerlibat({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValueEmployee,
      })
        .then((res) => {
          this.totalData = res.data.data.length;
          this.allEmployee = res.data.data;
          if (this.searchValueEmployee == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.page = 1;
    },
    handleAddJenisPelanggaran() {
      this.jenisPelanggaranListToSave.push({
        pelakuId: null,
        pelakuNik: "",
        pelakuName: "",
        pelakuUtama: "N",
      })
    },
    clearJenis(item) {
      if (item.pelakuUtama == 'Y') {
        item.violationType = ''
      }
    },
    clearDesc(item) {
      if (item.violationType != 'VIOLATION_TYPE_OTHER') {
        item.violationDesc = ''
      }
    },
    fiktifEdit(item) {
      item.potentialLoss = Number(item.outstandingPokok) - Number(item.tabunganBalance)
    },
    fiktifEdit2(item) {
      item.netFraud = Number(item.potentialLoss) - (Number(item.recoveryNasabah) + Number(item.recoveryBranch))
    },
    pembiayaanPercentage(item) {
      item.pembiayaanNasabahPercentage = (Number(item.pembiayaanNasabah) / Number(item.plafond)) * 100
    },
    karyawan(item) {
      item.pembiayaanKaryawan = Number(item.plafond) - Number(item.pembiayaanNasabah)
    },
    karyawanPercentage(item) {
      item.pembiayaanKaryawanPercentage = (Number(item.pembiayaanKaryawan) / Number(item.plafond)) * 100
    },
    outstandingNasabahNominal(item) {
      item.outstandingNasabah = Math.floor((Number(item.pembiayaanNasabahPercentage) / 100) * item.outstandingTotal)
    },
    outstandingKaryawanNominal(item) {
      item.outstandingKaryawan = Math.floor((Number(item.pembiayaanKaryawanPercentage) / 100) * item.outstandingTotal)
    },
    potensiKerugian(item) {
      item.potentialLoss = Number(item.outstandingKaryawan) - Number(item.tabunganBalance)
    },
    potensiKerugianPelunasan(item) {
      item.potentialLoss = Number(item.outstandingPelunasan) - Number(item.tabunganBalance)
    },
    addFraudMethod() {
      this.modusOperandiList.push({
        fraudMthd: "",
        fraudMthdName: "",
        incident: "",
        incidentDesc: "",
        incidentName: "",
      });
    },
    dieditPelaku() {
      this.detailLossToSaveAuto[this.indexForSave].detailLossDistList =
        this.isiPelakuList;
    },
    dieditDocument() {
      this.detailLossToSaveAuto[this.indexForSave].detailLossDocList =
        this.isiDocumentList;
    },
    diedit(apa, index) {
      if (this.detailLossToSaveAuto.length == 0) {
        for (let indexa = 0; indexa <= index; indexa++) {
          this.detailLossToSaveAuto.push("");
        }
      }
      this.detailLossToSaveAuto.splice(index, 1, apa);
    },
    clear() {
      this.isiPelakuList.map((item) => {
        item.netFraudDist = "";
      });
    },
    calculate() {
      this.totalFraud = 0;
      this.isiPelakuList.map((item) => {
        this.totalFraud = Number(this.totalFraud) + Number(item.netFraudDist);
      });
    },
    loadKaryawan() {
      this.getEmployeeByNik({
        nik: this.username,
      })
        .then((response) => {
          let respData = response.data.data;

          this.namaInvestigator = respData.name;
          this.nikInvestigator = respData.nik;
          this.jabatanInvestigator = respData.position;
          this.emailInvestigator = respData.email;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleDownloadAttch(item) {
      this.downloadAttachment({
        base64Data: null,
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
        });
    },
    loadInvestigationStatus() {
      this.getParameterByParamType({
        paramType: "INVESTIGATION_STATUS"
      })
        .then((resp) => {
          this.investigationStatusList = resp.data.data;
        })
    },
    loadStatusRoot() {
      this.rootCouse = [];
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
      .then((resp) => {
        this.mandatoryList = [];
        let rootCauseStatusa = resp.data.data;
        for (let index = 0; index < rootCauseStatusa.length; index++) {
          this.rootCouse.push({
            createBy: "",
            createDate: "",
            fraudId: this.fraudId,
            lossDocId: 0,
            recordFlag: "",
            rootCause: rootCauseStatusa[index].paramCd,
            rootCauseDesc: "",
            rootCauseList: [],
            rootCauseName: rootCauseStatusa[index].paramName,
            seqNo: 0,
            updateBy: "",
            updateDate: "",
          });

          this.mandatoryList.push(rootCauseStatusa[index].paramValue);

          this.rootCauseStatus.push([]);

          this.getParameterByParamType({
            pageNumber: 0,
            pageSize: 100,
            paramCd: "",
            paramName: "",
            paramType: rootCauseStatusa[index].paramCd,
            paramValue: "",
            searchValue: "",
          }).then((response) => {
            response.data.data.map((item) => {
              item.rootCause = item.paramType;
              delete item.paramType;
              item.rootCauseDesc = item.paramCd;
              delete item.paramCd;
              item.rootCauseName = item.paramName;
              delete item.paramName;
              item.rootCauseDescName = "";
              delete item.paramTypeName;
              delete item.paramValue;
            });
            this.rootCauseStatus.splice(index, 1, response.data.data);
          });
        }
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },
    handleDownloadDataRincian() {
      this.downloadDataRincianKerugian(this.fraudId)
        .then((response) => {
          console.log("handleDownloadDataRincian OK", response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("handleDownloadDataRincian ERROR", err.response);
        });
    },
    handleDownloadDataRekap() {
      this.downloadDataRekapKerugian(this.fraudId)
        .then((response) => {
          console.log("handleDownloadDataRekap OK", response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("handleDownloadDataRekap ERROR", err.response);
        });
    },
    handleDownloadTemplate(tipe) {
      if (tipe === "") {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Modus Operandi wajib diisi terlebih dahulu",
          snackbarColor: "red",
        });

        return;
      }

      this.downloadTemplateDaftarKerugian(tipe)
        .then((response) => {
          console.log("handleDownloadTemplate OK", response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("handleDownloadTemplate ERROR", err.response);
        });
    },
    onButtonClickTemplate(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs["uploaderTemplate" + index][0].click();
    },
    onButtonClickTemplateExpand(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs["uploaderTemplate" + index].click();
    },
    onFileChangedTemplate(e, tipe, lossType, index) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);

      this.saveUploadRincianDaftarKerugian({
        formData: formData,
        fraudId: this.fraudId,
        typeTemplate: tipe,
        lossType: lossType,
      })
        .then((response) => {
          console.log("onFileChangedTemplate OK", response);
          this.handleGetLossList();
          this.panelIndex = index;
        })
        .catch((err) => {
          console.log("onFileChangedTemplate ERROR", err.response);
          if (err.response.data.message == "Save Auto Error") {
            let fileSource = `data:application/octet-stream;base64,${err.response.data.data}`;
            const link = document.createElement("a");
            link.href = fileSource;
            link.setAttribute("download", `Error.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.$helpers.handleError(err);
        });
    },
    onButtonClick(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // this.$refs.uploader[index].click();
      this.$refs["uploader" + index][0].click();
    },
    onFileChanged(e, index) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);

      this.uploadInvestigasi(formData)
        .then((response) => {
          console.log("response", response);
          this.isiDocumentList[index].attachFileName =
            response.data.data.fileName;
          this.isiDocumentList[index].attachFilePath =
            response.data.data.filePathName;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    uploadFile(event, index) {

      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      this.isLoadingPhoto.splice(index, 1, true);

      this.uploadInvestigasi(formData)
        .then((response) => {
          console.log("response", response);
          this.fraudAttAFMList[index].attachFileName = response.data.data.fileName;
          this.fraudAttAFMList[index].attachFilePath =
            response.data.data.filePathName;

          var ext = this.fraudAttAFMList[index].attachFileName.substring(
            this.fraudAttAFMList[index].attachFileName.lastIndexOf("."),
            this.fraudAttAFMList[index].length
          );

          this.ekstension[index] = ext;

          this.attachFileSize[index] = (
            event.target.files[0].size /
            (1024 * 1024)
          ).toFixed(2);
        })
        .catch((err) => {
          this.isLoadingPhoto.splice(index, 1, false);
          this.$helpers.handleError(err);
        });
    },
    removeFile(index) {
      this.fraudAttAFMList.splice(index, 1);
      this.ekstension.splice(index, 1);
      this.attachFileSize.splice(index, 1);
      this.isLoadingPhoto.splice(index, 1);
    },
    addFraudAttachment() {
      this.fraudAttAFMList.push({
        attachFileName: "",
        attachFilePath: "",
        flowName: "Investigasi AFM",
        fraudId: this.fraudId,
        seqNo: 0,
      });

      this.isLoadingPhoto.push(false);
    },
    fraudCommitedDatePicker() {
      this.fraudCommitedDateMenu = false;
      this.fraudCommitedDateFormat = moment(this.fraudCommitedDateVal).format(
        "yyyy-MM-DD"
      );
      this.fraudCommitedDate = moment(this.fraudCommitedDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    pencairanDatePicker(data) {
      this.pencairanDateMenu[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker2(data) {
      this.pencairanDateMenu2[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker3(data) {
      this.pencairanDateMenu3[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker(data) {
      this.fraudRepayDateMenu[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker2(data) {
      this.fraudRepayDateMenu2[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker3(data) {
      this.fraudRepayDateMenu3[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker4(data) {
      this.fraudRepayDateMenu4[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal4[data.index]
      ).format("yyyy-MM-DD");
    },
    fpbTransactionDatePicker(data) {
      this.fpbTransactionDateMenu[data.index] = false;
      data.item.fpbTransactionDate = moment(
        this.fpbTransactionDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePickerExpand(data) {
      this.pencairanDateMenuExpand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker2Expand(data) {
      this.pencairanDateMenu2Expand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    pencairanDatePicker3Expand(data) {
      this.pencairanDateMenu3Expand[data.index] = false;
      data.item.pencairanDate = moment(
        this.pencairanDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePickerExpand(data) {
      this.fraudRepayDateMenuExpand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker2Expand(data) {
      this.fraudRepayDateMenu2Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal2[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker3Expand(data) {
      this.fraudRepayDateMenu3Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal3[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudRepayDatePicker4Expand(data) {
      this.fraudRepayDateMenu4Expand[data.index] = false;
      data.item.fraudRepayDate = moment(
        this.fraudRepayDateVal4[data.index]
      ).format("yyyy-MM-DD");
    },
    fpbTransactionDatePickerExpand(data) {
      this.fpbTransactionDateMenuExpand[data.index] = false;
      data.item.fpbTransactionDate = moment(
        this.fpbTransactionDateVal[data.index]
      ).format("yyyy-MM-DD");
    },
    fraudKnownDatePicker() {
      this.fraudKnownDateMenu = false;
      this.fraudKnownDate = moment(this.fraudKnownDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    recoveryDatePicker() {
      this.recoveryDateMenu = false;
      this.fraudCommitedDateTo = moment(this.recoveryDateVal).format(
        "DD-MMM-YYYY"
      );
    },
    openPelakuDialog(lossId, tempId, fraud, index) {
      this.getRincianDafKerugianPelakuByFraudId({
        detailLossId: lossId,
        detailLossTempId: tempId,
        fraudId: this.fraudId,
      }).then((responsePelaku) => {
        let cloneArray = responsePelaku.data.data;
        this.isiPelakuList = cloneArray;
        this.netFraudText = fraud;
        this.pelakuFraudDialog = true;
        this.indexForSave = index;
      });
    },
    openDocumentDialog(document, index) {
      this.getDataKerugianDocumentByFraudId({
        attachFileName: "",
        attachFilePath: "",
        createBy: "",
        createDate: "",
        detailLossId: document.detailLossId,
        detailLossTempId: document.detailLossTempId,
        documentType: document.lossType,
        lossDocId: 0,
        lossDocTempId: 0,
        notes: "",
        recordFlag: "",
        updateBy: "",
        updateDate: "",
      }).then((response) => {
        let respData = response.data.data;
        this.isiDocumentList = respData;
        this.documentDialog = true;
        this.indexForSave = index;
      });
    },
    openKeteranganDialog(catatan) {
      console.log("apa isi catatan", catatan);
      this.catatan = catatan;
      this.keteranganDialog = true;
    },
    deleteRowOperandi(data) {
      this.deleteIndex = this.modusOperandiList.indexOf(data);
      if (this.deleteIndex !== -1) {
        this.modusOperandiList.splice(this.deleteIndex, 1);
        this.kronologiList.splice(this.deleteIndex, 1);
      }
    },
    deleteRowJenisPelanggaran(data) {
      this.deleteRowCalled = true;
      this.deleteIndexJenisPelanggaran = this.jenisPelanggaranListToSave.indexOf(data);
      if (this.deleteIndexJenisPelanggaran !== -1) {
        this.jenisPelanggaranListToSave.splice(this.deleteIndexJenisPelanggaran, 1);
      }
    },
    deleteRowDetailPelakuUtama(data) {
      this.deleteIndexDetailPelakuUtama = this.detailPelakuUtamaList.indexOf(data);
      if (this.deleteIndexDetailPelakuUtama !== -1) {
        this.detailPelakuUtamaList.splice(this.deleteIndexDetailPelakuUtama, 1);
      }

      let deleteIndexSaved = this.savedDetailPelakuUtamaList.indexOf(data);
      if (deleteIndexSaved !== -1) {
        this.savedDetailPelakuUtamaList.splice(deleteIndexSaved, 1);
      }
    },
    deleteRowDetailPelaku(data) {
      this.deleteIndexDetailPelaku = this.detailPelakuList.indexOf(data);
      if (this.deleteIndexDetailPelaku !== -1) {
        this.detailPelakuList.splice(this.deleteIndexDetailPelaku, 1);
      }

      let deleteIndexSavedPelaku = this.savedDetailPelakuList.indexOf(data);
      if (deleteIndexSavedPelaku !== -1) {
        this.savedDetailPelakuList.splice(deleteIndexSavedPelaku, 1);
      }
    },
    deleteRow(data, index) {
      console.log("apa isi", data);
      this.deleteIndex = this.pembiayaanFiktifList[index].indexOf(data);
      if (this.deleteIndex !== -1) {
        this.pembiayaanFiktifList[index].splice(this.deleteIndex, 1);

        data.recordFlag = "D";
        this.detailLossToDeleteAuto.push(data);
      }
    },
    updateDetailPelakuList(data) {
      this.originalFiles = Object.assign({}, this.files);
      if (data.pelakuUtama == 'Y') {
        let foundPelaku = null;

        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.map(item => ({
          ...item,
          plafond: this.formatNumberString(item.plafond),
          potentialLoss: this.formatNumberString(item.potentialLoss),
          tabungan: this.formatNumberString(item.tabungan),
          recovery: this.formatNumberString(item.recovery)
        }));

        if (this.savedDetailPelakuUtamaList) {
          foundPelaku = this.savedDetailPelakuUtamaList.filter(pelaku => pelaku.pelakuNik === data.pelakuNik);
        }

        if (foundPelaku.length > 0) {
          this.detailPelakuUtamaList = foundPelaku;
        } else {
          this.selectedData = {
            pelakuId: data.pelakuId,
            pelakuNik: data.pelakuNik,
            pelakuName: data.pelakuName,
            assignmentCategory: data.assignmentCategory,
            modus: "",
            noa: '',
            plafond: '',
            tabungan: '',
            potentialLoss: '',
            recovery: '',
            netFraud: '',
            rootCause: "",
            weakControl: "",
            fixing: "",
          };
          this.detailPelakuUtamaList = [this.selectedData];
        }
        this.dialogDetailPelakuUtama = true;
      } else if (data.pelakuUtama == 'N') {

        let foundPelaku = null;

        if (this.savedDetailPelakuList) {
          foundPelaku = this.savedDetailPelakuList.filter(pelaku => pelaku.pelakuNik === data.pelakuNik);
        }

        if (foundPelaku.length > 0) {
          this.detailPelakuList = foundPelaku;
        } else {
          this.selectedData = {
            pelakuId: data.pelakuId,
            pelakuNik: data.pelakuNik,
            pelakuName: data.pelakuName,
            assignmentCategory: data.assignmentCategory,
            violationType: "",
            modusDesc: "",
          };
          this.detailPelakuList = [this.selectedData];
        }

        this.dialogDetailPelaku = true;
      }
    },
    handleSaveDetailPelakuUtama() {
      this.handleSaveDetailPelakuUtamaCalled = true
      let result = {};

      let modusYangSama;
      const hasSameModus = this.detailPelakuUtamaList.some((item, index, self) => {
        const duplicateIndex = self.findIndex((t) => t.modus === item.modus);
        if (index !== duplicateIndex) {
          modusYangSama = item.modus;
          return true;
        }
        return false;
      });

      if (hasSameModus) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Modus Operandi tidak boleh ada yang sama",
          snackbarColor: "red",
        });
        
        // Hapus file dengan modus yang sama, jika sebelumnya sudah melakukan upload
        for (let key in this.files) {
          if (this.files[key].modus === modusYangSama) {
            delete this.files[key];
          }
        }

        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter((item, index, self) =>
          index === self.findIndex((t) => t.modus === item.modus)
        ); 
        return;
      }

      this.savedDetailPelakuUtamaList.push(...this.detailPelakuUtamaList)
      console.log(this.savedDetailPelakuUtamaList, "saved")

      this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.reverse().filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.pelakuNik === item.pelakuNik && t.pelakuName === item.pelakuName && t.modus === item.modus
        ))
      ).reverse();

      this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.map(item => ({
        ...item,
        netFraud: this.getActualLoss(item.potentialLoss, item.recovery, "number")
      }));

      console.log(this.savedDetailPelakuUtamaList, "test saved filter")
      this.originalDetailPelakuUtamaList = JSON.parse(JSON.stringify(this.savedDetailPelakuUtamaList));

      // Iterasi melalui setiap item di jenisPelanggaranListToSave
      this.jenisPelanggaranListToSave.forEach(item => {
        if (item.pelakuUtama === "Y") {
          // Jika pelakuNik ini belum ada di result, tambahkan
          if (!result[item.pelakuNik]) {
            result[item.pelakuNik] = {
              pelakuId: item.pelakuId,
              pelakuName: item.pelakuName,
              pelakuNik: item.pelakuNik,
              pelakuUtama: item.pelakuUtama,
              violationType: item.violationType,
              assignmentCategory: item.assignmentCategory,
              pelakuDetails: []
            };
          }
        }
      });

      this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.map(item => ({
        ...item,
        plafond: this.removeDot(item.plafond),
        potentialLoss: this.removeDot(item.potentialLoss),
        tabungan: this.removeDot(item.tabungan),
        recovery: this.removeDot(item.recovery)
      }));

      console.log(this.savedDetailPelakuUtamaList, "test123")

      //Iterasi melalui setiap item di detailPelakuUtamaList
      this.savedDetailPelakuUtamaList.forEach(item => {
        // Jika pelakuNik ini ada di result, tambahkan detailnya
        if (result[item.pelakuNik]) {
          console.log(result[item.pelakuNik]);
          result[item.pelakuNik].pelakuDetails.push({
            pelakuId: item.pelakuId,
            pelakuDetailId: item.pelakuDetailId,
            pelakuName: item.pelakuName,
            pelakuNik: item.pelakuNik,
            fixing: item.fixing,
            modus: item.modus,
            modusName: item.modusName,
            modusDesc: item.modusDesc,
            netFraud: item.netFraud,
            noa: item.noa,
            plafond: item.plafond,
            potentialLoss: item.potentialLoss,
            recovery: item.recovery,
            rootCause: item.rootCause,
            tabungan: item.tabungan,
            weakControl: item.weakControl,
            nasabahAttName: item.nasabahAttName,
            nasabahAttPath: item.nasabahAttPath,
            evidenceAttName: item.evidenceAttName,
            evidenceAttPath: item.evidenceAttPath
          });
        }
      });

      // Mengubah objek menjadi array
      result = Object.values(result);

      const hasEmptyModus = this.detailPelakuUtamaList.some(detail => !detail.modus);
      if (hasEmptyModus) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Modus Operandi tidak boleh kosong",
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.modus);
        return;
      }

      const hasEmptyNoa = this.detailPelakuUtamaList.some(detail => !detail.noa);
      if (hasEmptyNoa) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "NOA minimal diisi dengan nilai 1",
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.noa);
        return;
      }

      const hasEmptyPotentialLoss = this.detailPelakuUtamaList.some(detail => !detail.potentialLoss);
      if (hasEmptyPotentialLoss) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `Potensi Kerugian tidak boleh kosong`,
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.potentialLoss);
        return;
      }

      const hasEmptyRecovery = this.detailPelakuUtamaList.some(detail => detail.recovery === null ||
        detail.recovery === undefined || detail.recovery === '');
      if (hasEmptyRecovery) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Recovery tidak boleh kosong",
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.recovery !== null &&
          detail.recovery !== undefined &&
          detail.recovery !== '');
        return;
      }

      const hasRecoveryGreaterThanPotentialLoss = this.detailPelakuUtamaList.some(detail => this.removeDot(detail.recovery) > this.removeDot(detail.potentialLoss));
      if (hasRecoveryGreaterThanPotentialLoss) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Recovery tidak boleh lebih besar dari Potensi Kerugian",
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => this.removeDot(detail.recovery) <= this.removeDot(detail.potentialLoss));
        return;
      }

      result = result.map(item => {
        item.pelakuDetails = item.pelakuDetails.filter(detail => detail.modus !== null && detail.modus);
        return item;
      });

      let hasNasabahFile = false;
      let hasBuktiFile = false;

      for (let key in this.files) {
        if (this.files[key].type === 'nasabah') {
          hasNasabahFile = true;
        } else if (this.files[key].type === 'bukti') {
          hasBuktiFile = true;
        }
      }

      const nasabahFileMissing = result.findIndex(item => item.pelakuDetails.some(detail => !detail.nasabahAttName || !detail.nasabahAttPath));
      if (!hasNasabahFile && nasabahFileMissing !== -1) {
        const missingModus = result[nasabahFileMissing].pelakuDetails.find(detail => !detail.nasabahAttName || !detail.nasabahAttPath).modus;
        const missingModusItem = this.lossList.find(item => item.paramCd === missingModus);
        const missingModusName = missingModusItem ? missingModusItem.paramName : 'Unknown';
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `File List Nasabah untuk modus '${missingModusName}' belum diunggah`,
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.nasabahAttName && detail.nasabahAttPath);
        return;
      }

      const buktiFileMissing = result.findIndex(item => item.pelakuDetails.some(detail => !detail.evidenceAttName || !detail.evidenceAttPath));
      if (!hasBuktiFile && buktiFileMissing !== -1) {
        const missingModus = result[buktiFileMissing].pelakuDetails.find(detail => !detail.evidenceAttName || !detail.evidenceAttPath).modus;
        const missingModusItem = this.lossList.find(item => item.paramCd === missingModus);
        const missingModusName = missingModusItem ? missingModusItem.paramName : 'Unknown';
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `File Bukti untuk modus '${missingModusName}' belum diunggah`,
          snackbarColor: "red",
        });
        this.savedDetailPelakuUtamaList = this.savedDetailPelakuUtamaList.filter(detail => detail.evidenceAttName && detail.evidenceAttPath);
        return;
      }

      this.savePelakuUtamaList = result
      console.log(this.savePelakuUtamaList, "savePelakuUtamaList")
      this.jenisPelanggaranListToSave.push(...this.savePelakuUtamaList);
      this.jenisPelanggaranListToSave.sort((a, b) => (a.pelakuUtama === b.pelakuUtama ? 0 : a.pelakuUtama > b.pelakuUtama ? -1 : 1));
      this.jenisPelanggaranListToSave = this.jenisPelanggaranListToSave.reverse().filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.pelakuNik === item.pelakuNik && t.pelakuName === item.pelakuName
        ))
      ).reverse();
      console.log(this.jenisPelanggaranListToSave, "final Save");
      this.dialogDetailPelakuUtama = false;
      this.originalFiles = Object.assign({}, this.files);
      console.log(this.files, "saveOriginalFiles")
    },
    handleSaveDetailPelaku() {
      this.handleSaveDetailPelakuCalled = true
      let result = {};
      this.savedDetailPelakuList.push(...this.detailPelakuList);

      this.savedDetailPelakuList = this.savedDetailPelakuList.reverse().filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.pelakuNik === item.pelakuNik && t.pelakuName === item.pelakuName && t.violationType === item.violationType
        ))
      ).reverse();

      this.originalDetailPelakuList = JSON.parse(JSON.stringify(this.savedDetailPelakuList));

      this.jenisPelanggaranListToSave.forEach(item => {
        if (item.pelakuUtama === "N") {
          if (!result[item.pelakuNik]) {
            result[item.pelakuNik] = {
              pelakuId: item.pelakuId,
              pelakuName: item.pelakuName,
              pelakuNik: item.pelakuNik,
              pelakuUtama: item.pelakuUtama,
              assignmentCategory: item.assignmentCategory,
              pelakuDetails: []
            };
          }
        }
      });

      this.savedDetailPelakuList.forEach(item => {
        if (result[item.pelakuNik]) {
          result[item.pelakuNik].pelakuDetails.push({
            pelakuId: item.pelakuId,
            pelakuDetailId: item.pelakuDetailId,
            pelakuName: item.pelakuName,
            pelakuNik: item.pelakuNik,
            fixing: item.fixing,
            modus: item.violationType ? item.violationType : item.modus,
            modusDesc: item.modusDesc,
            nasabahAttName: item.nasabahAttName,
            nasabahAttPath: item.nasabahAttPath,
            evidenceAttName: item.evidenceAttName,
            evidenceAttPath: item.evidenceAttPath
          });
        }
      });

      result = Object.values(result);

      // Cek apakah ada jenis pelanggaran yang kosong hanya pada detailPelakuList
      const hasEmptyViolationType = this.detailPelakuList.some(detail => !detail.modus && !detail.violationType);
      if (hasEmptyViolationType) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Jenis Pelanggaran tidak boleh kosong",
          snackbarColor: "red",
        });
        this.savedDetailPelakuList = this.savedDetailPelakuList.filter(detail => detail.violationType);
        return;
      }

      this.savePelakuList = result
      console.log(this.savePelakuList, "savePelakuListBukanUtama");
      this.savePelakuList = this.savePelakuList.map(item => {
        item.pelakuDetails = item.pelakuDetails.filter(detail => detail.modus !== null && detail.modus);
        return item;
      });
      this.jenisPelanggaranListToSave.push(...this.savePelakuList);
      this.jenisPelanggaranListToSave = this.jenisPelanggaranListToSave.reverse().filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.pelakuNik === item.pelakuNik && t.pelakuName === item.pelakuName
        ))
      ).reverse();
      console.log(this.jenisPelanggaranListToSave, "final Save");
      this.dialogDetailPelaku = false
      this.originalFiles = Object.assign({}, this.files);
      console.log(this.files, "saveOriginalFiles")
    },
    handleAddDetailPelaku() {
      // Mendapatkan detail pelaku terakhir
      let lastPelaku = this.detailPelakuUtamaList[this.detailPelakuUtamaList.length - 1];

      // Menambahkan baris baru dengan pelakuNik dan pelakuName yang sama
      this.detailPelakuUtamaList.push({
        pelakuId: lastPelaku.pelakuId,
        pelakuNik: lastPelaku.pelakuNik,
        pelakuName: lastPelaku.pelakuName,
        modus: "",
        noa: "",
        plafond: "",
        tabungan: "",
        potentialLoss: "",
        recovery: "",
        netFraud: "",
        rootCause: "",
        weakControl: "",
        fixing: ""
      });
    },
    handleCancelDetailPelakuUtama() {
      console.log(this.originalDetailPelakuUtamaList, "originalListCancel")
      this.savedDetailPelakuUtamaList = JSON.parse(JSON.stringify(this.originalDetailPelakuUtamaList));
      this.dialogDetailPelakuUtama = false;
      this.files = Object.assign({}, this.originalFiles);
      console.log(this.files, "fileUploadCancel")
    },
    handleCancelDetailPelaku() {
      console.log(this.originalDetailPelakuList, "CancelBukanUtama")
      this.savedDetailPelakuList = JSON.parse(JSON.stringify(this.originalDetailPelakuList));
      this.dialogDetailPelaku = false;
      this.files = Object.assign({}, this.originalFiles);
      console.log(this.files, "fileUploadCancel")
    },
    handleGetWeakControl() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "WEAK_CONTROL",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.weakControlList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetFixing() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FIXING",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.fixingList = response.data.data;
          console.log("isi fixingList", this.fixingList);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    getParamName(modus, violationType) {
      const selectedModus = this.kodeModusList.find(modusOperandi => modusOperandi.paramCd === modus);
      const selectedViolation = this.jenisPelanggaranList.find(jenis => jenis.paramCd === violationType);
      return selectedModus ? selectedModus.paramName : (selectedViolation ? selectedViolation.paramName : '');
    },
    sumField(key) {
      return this.daftarKerugianList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    getActualLoss (potentialLoss, recovery, type) {
      let result = this.removeDot(potentialLoss) - this.removeDot(recovery);
      result = result < 0 ? 0 : result;
      if (type === "string") {
        return this.formatNumber(result);
      } 
      else {
        return this.removeDot(result);
      }
    },
    removeDot(value) {
      if (typeof value === 'string') {
        if (value === '0') {
          return parseFloat(value);
        } else if (value.includes('.')) {
          let number = parseFloat(value.replace(/\./g, ''));
          return Number(number.toFixed(2));
        }
      }
      return value;
    },
    uploadInvestigasiFraud(e, types, moduses, pelakuNiks, index) {
      console.log('Parameters:', { e, types, moduses, pelakuNiks });
      const files = e.target.files;

      let allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'application/msword', 'application/pdf', 'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      const maxFilenameLength = 128;

      this.originalFiles = Object.assign({}, this.files);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Gunakan kombinasi type dan indeks file sebagai kode unik
        this.files[`${types[i]}_${i}_${index}_${pelakuNiks[i]}`] = {
          file: file,
          type: types[i],
          modus: moduses[i],
          pelakuNik: pelakuNiks[i]
        };

        console.log('File details:', file);
        console.log(this.files, "FilesUpload");

        if (!allowedTypes.includes(file.type)) {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Format file salah",
            snackbarColor: "red",
          })
          return
        }

        if (file.size > 2000000) {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Ukuran File terlalu besar (Maks. 2MB)",
            snackbarColor: "red",
          })
          return
        }

        if (file.name.length > maxFilenameLength) {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Nama File terlalu panjang (Maks. 128 Karakter)",
            snackbarColor: "red",
          })
          return
        }

        const regex = /^[a-zA-Z0-9 _.-]+$/;
        if (!file.name.match(regex)) {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Nama File tidak boleh mengandung karakter khusus selain alfanumerik, garis bawah (_), dan tanda hubung (-).",
            snackbarColor: "red",
          });
          return;
        }

        this.$store.commit("setShowSnackbar", {
          snackbarMsg: `File dengan tipe "${types[i]}" dengan nama "${file.name}" telah berhasil diunggah.`,
          snackbarColor: "green",
        });
      }
    },
    handleDownloadFilePelaku(item, type) {
      this.downloadFilePelaku({
        pelakuDtlId: item.pelakuDetailId,
        type: type
      })
        .then((response) => {
          console.log(response, "response")
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("gagal download", err.response);
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Gagal download File",
            snackbarColor: "red",
          })
          return
        });
    },
    handleAdd() {
      const addObj = Object.assign(
        {},
        {
          fraudId: this.fraudId,
          fraudMthd: "",
          fraundMthdName: "",
          incident: "",
          incidentDesc: "",
          incidentName: "",
          seqNo: 0,
        }
      );
      this.modusOperandiList.push(addObj);
      this.kronologiList.push([]);
    },
    handleAddDetailLoss(index, loss) {
      this.defaultDetailLossList.lossType = loss.paramCd;
      this.defaultDetailLossList.lossTypeName = loss.paramName;
      this.defaultDetailLossList.fraudId = this.fraudId;
      const addObj = Object.assign({}, this.defaultDetailLossList);
      this.pembiayaanFiktifList[index].push(addObj);
      this.pelakuFraudList[index].push([]);
      console.log("PEMBIAYAAN", this.pembiayaanFiktifList[index]);
    },
    hanldeGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.transaksiTypeList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetJenisPelanggaran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "VIOLATION_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.jenisPelanggaranList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.statusAngsuranList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetTujuanFraud() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_FACTOR",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.tujuanFraudList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetSumberInformasi() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_INF",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.sumberInformasiList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetLossList() {
      this.pembiayaanFiktifList = [];
      this.pelakuFraudList = [];
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      })
      .then((response) => {
        this.lossList = response.data.data;
        for (var i = 0; i < this.lossList.length; i++) {
          this.pembiayaanFiktifList.push([]);
          this.pelakuFraudList.push([]);
        }
        this.searchDataRinDafKerugianByFraudId({
          fraudId: this.fraudId,
          fraudLoc: "",
          pageNumber: 0,
          pageSize: 99999,
          refNo: "",
          reportDate: "",
          reportDateFrom: "",
          reportDateTo: "",
          reportStatus: "",
        })
          .then((resp) => {
            let temp = resp.data.data;
            console.log("tempp", temp);

            for (let index = 0; index < temp.length; index++) {

              let indexs = this.lossList.findIndex(
                (x) => x.paramCd === temp[index].lossType
              );
              this.pembiayaanFiktifList[indexs].push(temp[index]);
            }
          })
          .catch((err) => {
            this.$helpers.handleError(err);
          });
      })
      .catch((err) => {
        this.$helpers.handleError(err);
      });
    },
    handleGetKodeModus() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_MTHD",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kodeModusList = response.data.data;
          console.log("isi kodeModusList", this.kodeModusList);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleGetKronologiList(item, index) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: item,
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kronologiList.splice(index, 1, response.data.data);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    loadData() {
      this.getDataInvestigasiByFraudId({
        fraudId: this.fraudId,
      })
        .then((response) => {
          console.log("byId", response.data.data);
          this.dataInvestigasiById = response.data.data;

          this.reportType = this.dataInvestigasiById.reportType;

          this.namaInvestigator = this.dataInvestigasiById.picInvestigatorName;
          this.nikInvestigator = this.dataInvestigasiById.picInvestigator;
          this.jabatanInvestigator = this.dataInvestigasiById.picInvestigatorPosition;
          this.emailInvestigator = this.dataInvestigasiById.picInvestigatorEmail;

          //HISTORY
          this.fraudLogList = this.dataInvestigasiById.fraudLogList;

          //DATA INVESTIGATOR
          this.picInvestigator = this.dataInvestigasiById.picInvestigator;
          this.picInvestigatorPosition =
            this.dataInvestigasiById.picInvestigatorPosition;
          this.picInvestigatorEmail =
            this.dataInvestigasiById.picInvestigatorEmail;
          this.picInvestigatorPhone =
            this.dataInvestigasiById.picInvestigatorPhone;
          this.investigationDelegatorPhone =
            this.dataInvestigasiById.investigationDelegatorPhone;

          //DETAIL LAPORAN
          this.jagaNo = this.dataInvestigasiById.jagaNo;
          this.reportDate = moment(this.dataInvestigasiById.reportDate).format(
            "DD-MMM-YYYY"
          );
          this.reportDateFormat = moment(
            this.dataInvestigasiById.reportDate
          ).format("YYYY-MM-DD");
          this.fraudTypeName = this.dataInvestigasiById.fraudTypeName;
          this.fraudInf = this.dataInvestigasiById.fraudInf;
          this.fraudInfReason = this.dataInvestigasiById.fraudInfReason;
          this.fraudPelakuList = this.dataInvestigasiById.fraudPelakuList;
          this.fraudLocName = this.dataInvestigasiById.fraudLocName;
          this.initialLoss = this.humanFormat(
            this.dataInvestigasiById.initialLoss
          );
          this.sharepointLink = this.dataInvestigasiById.sharepointLink;
          this.modusOperandiList = this.dataInvestigasiById.fraudMthdList;
          for (var i = 0; i < this.modusOperandiList.length; i++) {
            this.kronologiList.push([]);
            if (this.modusOperandiList[i].fraudMthd != null) {
              this.handleGetKronologiList(
                this.modusOperandiList[i].fraudMthd,
                i
              );
            }
          }
          this.fraudPelakuListPelakuFraud =
            this.dataInvestigasiById.fraudPelakuList;
          this.attachment = this.dataInvestigasiById.fraudAttList;

          this.fraudAttAFMList = this.dataInvestigasiById.fraudAttAFMList;

          //VERIFIKASI FRAUD
          this.verifStatus = this.dataInvestigasiById.verifStatus;
          this.verifStatusName = this.dataInvestigasiById.verifStatusName;
          this.reportDocTypeList = this.dataInvestigasiById.reportDocTypeList;
          this.spPelaku = this.dataInvestigasiById.spPelaku;
          this.spNasabah = this.dataInvestigasiById.spNasabah;
          this.investigationDelegatorNik =
            this.dataInvestigasiById.investigationDelegatorNik;
          this.investigationDelegatorName =
            this.dataInvestigasiById.investigationDelegatorName;
          this.investigationDelegatorPhone =
            this.dataInvestigasiById.investigationDelegatorPhone;
          this.investigationDelegatorPositionName =
            this.dataInvestigasiById.investigationDelegatorPositionName;
          this.investigationDelegatorPosition =
            this.dataInvestigasiById.investigationDelegatorPosition;
          this.extFraudMthdDesc = this.dataInvestigasiById.extFraudMthdDesc;
          this.returnDate = this.dataInvestigasiById.returnDate
            ? moment(this.dataInvestigasiById.returnDate).format("DD/MM/yyyy")
            : null;
          this.reportStatus = this.dataInvestigasiById.reportStatus;
          this.returnKeterangan = this.dataInvestigasiById.extFraudMthdDesc;

          //INVESTIGASI FRAUD
          this.jenisPelanggaranListToSave = this.dataInvestigasiById.jenisPelanggaranList;
          this.investigationStatus =
            this.dataInvestigasiById.investigationStatus;
          this.note = this.dataInvestigasiById.note;
          this.fraudFactor = this.dataInvestigasiById.fraudFactor;
          this.fraudFactorDesc = this.dataInvestigasiById.fraudFactorDesc;
          this.fraudReason = this.dataInvestigasiById.fraudReason;
          this.fraudPurpose = this.dataInvestigasiById.fraudPurpose;
          this.fraudControl = this.dataInvestigasiById.fraudControl;
          this.fraudDetail = this.dataInvestigasiById.fraudDetail;
          this.violationType = this.dataInvestigasiById.violationType;
          this.violationTypeDesc = this.dataInvestigasiById.violationTypeDesc;
          this.fraudCommitedDate = this.dataInvestigasiById.fraudCommitedDate
            ? moment(this.dataInvestigasiById.fraudCommitedDate).format(
              "DD-MMM-YYYY"
            )
            : null;
          this.fraudKnownDate = this.dataInvestigasiById.fraudKnownDate
            ? moment(this.dataInvestigasiById.fraudKnownDate).format(
              "DD-MMM-YYYY"
            )
            : null;
          this.fraudCommitedDateTo = this.dataInvestigasiById.fraudCommitedDateTo
            ? moment(
              this.dataInvestigasiById.fraudCommitedDateTo,
              "DD-MMM-YYYY"
            ).format("DD-MMM-YYYY")
            : null;
          this.fraudAttList = this.dataInvestigasiById.fraudAttList;

          this.rootCauseApi = this.dataInvestigasiById.rootCauseList;
          this.loadStatusRoot();

          // this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleSaveAsDraft() {
      let fraudCommited = moment(this.fraudCommitedDate, "DD-MMM-YYYY");
      let fraudKnown = moment(this.fraudKnownDate, "DD-MMM-YYYY");
      let fraudLast = moment(this.fraudCommitedDateTo, "DD-MMM-YYYY");

      if (moment(fraudCommited).isAfter(fraudKnown)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
          snackbarColor: "red",
        });
      } 
      else if (moment(fraudCommited).isAfter(fraudLast)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: 
            "Tanggal Terakhir Fraud tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
          snackbarColor: "red",
        });
      }
      else if (moment(fraudLast).isAfter(fraudKnown)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: 
            "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Terakhir Fraud",
          snackbarColor: "red",
        });
      } else {
        let array = [];
        this.rootCouse.map((item) => {
          item.rootCauseList.map((items) => {
            array.push(items);
          });
        });
        console.log("di save", array);
        // if (this.$refs.investigasiForm.validate()) {
        var hasil = this.detailLossToSaveAuto.filter((item) => {
          return item != "";
        });
        // let detailLossList = hasil.concat(this.detailLossToDeleteAuto);
        // let detailLossList = [].concat(...this.pembiayaanFiktifList);

        let detailLossLista = hasil.concat(this.detailLossToDeleteAuto);
        let detailLossListb = detailLossLista.concat(...this.pembiayaanFiktifList);

        const detailLossList = detailLossListb.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return index === detailLossListb.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        });

        detailLossList.map((item) => {
          item.angsuranStatus =
            item.angsuranStatus != null
              ? item.angsuranStatus.trim()
              : item.angsuranStatus;
          item.appIdNo =
            item.appIdNo != null ? item.appIdNo.trim() : item.appIdNo;
          item.brwCategory =
            item.brwCategory != null
              ? item.brwCategory.trim()
              : item.brwCategory;
          item.createBy =
            item.createBy != null ? item.createBy.trim() : item.createBy;
          item.itemName =
            item.itemName != null ? item.itemName.trim() : item.itemName;
          item.kasSolCategory =
            item.kasSolCategory != null
              ? item.kasSolCategory.trim()
              : item.kasSolCategory;
          item.lossType =
            item.lossType != null ? item.lossType.trim() : item.lossType;
          item.mthdDetailDesc =
            item.mthdDetailDesc != null
              ? item.mthdDetailDesc.trim()
              : item.mthdDetailDesc;
          item.nasabahName =
            item.nasabahName != null
              ? item.nasabahName.trim()
              : item.nasabahName;
          item.notes = item.notes != null ? item.notes.trim() : item.notes;
          item.ownershipStatus =
            item.ownershipStatus != null
              ? item.ownershipStatus.trim()
              : item.ownershipStatus;
          item.pelakuPencurian =
            item.pelakuPencurian != null
              ? item.pelakuPencurian.trim()
              : item.pelakuPencurian;
          item.prsCollection =
            item.prsCollection != null
              ? item.prsCollection.trim()
              : item.prsCollection;
          item.recordFlag =
            item.recordFlag != null
              ? item.recordFlag.trim()
              : item.recordFlag;
          item.sentraName =
            item.sentraName != null
              ? item.sentraName.trim()
              : item.sentraName;
          item.tabunganNo =
            item.tabunganNo != null
              ? item.tabunganNo.trim()
              : item.tabunganNo;
          item.transaksiType =
            item.transaksiType != null
              ? item.transaksiType.trim()
              : item.transaksiType;
          item.updateBy =
            item.updateBy != null ? item.updateBy.trim() : item.updateBy;
          item.version =
            item.version != null ? item.version.trim() : item.version;
        });
        this.saveInvestigasi({
          jenisPelanggaranList: this.jenisPelanggaranListToSave,
          detailLossList: detailLossList,
          fraudAttList: this.fraudAttAFMList,
          fraudCommitedDate: this.fraudCommitedDate
            ? moment(this.fraudCommitedDate, "DD-MMM-YYYY").format(
              "yyyy-MM-DD"
            )
            : "", //OK tanggal fraud dilakukan
          fraudControl:
            this.fraudControl != null
              ? this.fraudControl.trim()
              : this.fraudControl, //OK kontrol yang tidak berjalan
          fraudDetail:
            this.fraudDetail != null
              ? this.fraudDetail.trim()
              : this.fraudDetail, // Detail / Cara Fraud Dilakukan (lokasi kerja terjadinya fraud ??)
          fraudFactor:
            this.fraudFactor != null
              ? this.fraudFactor.trim()
              : this.fraudFactor, //OK Tujuan Fraud
          fraudFactorDesc:
            this.fraudFactorDesc != null
              ? this.fraudFactorDesc.trim()
              : this.fraudFactorDesc, //OK Jika Tujuan Fraud Lainnya
          fraudId: this.fraudId,
          fraudKnownDate: this.fraudKnownDate
            ? moment(this.fraudKnownDate, "DD-MMM-YYYY").format("yyyy-MM-DD")
            : "", //OK tanggal fraud diketahui
          fraudMthdList: this.modusOperandiList,
          fraudPurpose:
            this.fraudPurpose != null
              ? this.fraudPurpose.trim()
              : this.fraudPurpose, //OK Hari Kecurangan digunakan digunakan untuk apa
          fraudReason:
            this.fraudReason != null
              ? this.fraudReason.trim()
              : this.fraudReason, //OK Alasan Pelaku melakukan fraud
          investigationDelegatorPhone:
            this.investigationDelegatorPhone != null
              ? this.investigationDelegatorPhone.trim()
              : this.investigationDelegatorPhone,
          investigationStatus:
            this.investigationStatus != null
              ? this.investigationStatus.trim()
              : this.investigationStatus, //OK Investigation Status / Status Investigasi
          picInvestigatorEmail: this.emailInvestigator, //??
          picInvestigatorPhone:
            this.picInvestigatorPhone != null
              ? this.picInvestigatorPhone.trim()
              : this.picInvestigatorPhone, //??
          note: this.note != null ? this.note.trim() : this.note, //OK
          fraudCommitedDateTo: this.fraudCommitedDateTo
            ? moment(this.fraudCommitedDateTo, "DD-MMM-YYYY").format(
              "yyyy-MM-DD"
            )
            : "",
          rootCauseList: array,
        })
        .then((response) => {
          this.isFromSave = true;
          console.log("response", response);
          this.$router.push("/investigasi-fraud");
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          console.log("response", err.response);
          this.$helpers.handleError(err);
        });
      }
    },
    handleSubmitInvestigasi() {
      let fraudCommited = moment(this.fraudCommitedDate, "DD-MMM-YYYY");
      let fraudKnown = moment(this.fraudKnownDate, "DD-MMM-YYYY");
      let fraudLast = moment(this.fraudCommitedDateTo, "DD-MMM-YYYY");

      if (!this.handleSaveDetailPelakuUtamaCalled && !this.handleSaveDetailPelakuCalled) {
        if(!this.deleteRowCalled) {
          this.jenisPelanggaranListToSave = this.loadDataPelakuList = [...this.jenisPelanggaranListToSave];
        } 
        this.handleSaveDetailPelakuUtama()
        this.handleSaveDetailPelaku()
      } 
      
      console.log(this.jenisPelanggaranListToSave, "Submit Test JenisPelanggaran")

      const hasEmptyPelaku = !this.jenisPelanggaranListToSave || this.jenisPelanggaranListToSave.length === 0;
      if (hasEmptyPelaku) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Minimal terdapat 1 Pelaku",
          snackbarColor: "red",
        });
        return;
      }

      const hasEmptyDetails = this.jenisPelanggaranListToSave.some(item => !item.pelakuDetails || item.pelakuDetails.length === 0);
      if (hasEmptyDetails) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Pelaku Minimal memiliki 1 Pelaku Detail",
          snackbarColor: "red",
        });
        return;
      }

      if (!this.fraudCommitedDate) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Tanggal Fraud Dilakukan harus diisi",
          snackbarColor: "red",
        });
      }
      else if (!this.fraudKnownDate) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Tanggal Fraud Diketahui harus diisi",
          snackbarColor: "red",
        });
      } 
      else if (!this.fraudCommitedDateTo) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Tanggal Terakhir Fraud harus diisi",
          snackbarColor: "red",
        });
      } 
      else if (moment(fraudCommited).isAfter(fraudKnown)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
          snackbarColor: "red",
        });
      }
      else if (moment(fraudCommited).isAfter(fraudLast)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: 
            "Tanggal Terakhir Fraud tidak boleh lebih kecil dari Tanggal Fraud Dilakukan",
          snackbarColor: "red",
        });
      }
      else if (moment(fraudLast).isAfter(fraudKnown)) {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: 
            "Tanggal Fraud Diketahui tidak boleh lebih kecil dari Tanggal Terakhir Fraud",
          snackbarColor: "red",
        });
      } else {
        let arraysv = [];
        this.rootCouse.map((item) => {
          item.rootCauseList.map((items) => {
            arraysv.push(items);
          });
        });
        var hasil = this.detailLossToSaveAuto.filter((item) => {
          return item != "";
        });
        let detailLossLista = hasil.concat(this.detailLossToDeleteAuto);
        let detailLossListb = detailLossLista.concat(...this.pembiayaanFiktifList);

        const detailLossList = detailLossListb.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return index === detailLossListb.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        });

        if (this.$refs.investigasiForm.validate()) {
          detailLossList.map((item) => {
            item.angsuranStatus =
              item.angsuranStatus != null
                ? item.angsuranStatus.trim()
                : item.angsuranStatus;
            item.appIdNo =
              item.appIdNo != null ? item.appIdNo.trim() : item.appIdNo;
            item.brwCategory =
              item.brwCategory != null
                ? item.brwCategory.trim()
                : item.brwCategory;
            item.createBy =
              item.createBy != null ? item.createBy.trim() : item.createBy;
            item.itemName =
              item.itemName != null ? item.itemName.trim() : item.itemName;
            item.kasSolCategory =
              item.kasSolCategory != null
                ? item.kasSolCategory.trim()
                : item.kasSolCategory;
            item.lossType =
              item.lossType != null ? item.lossType.trim() : item.lossType;
            item.mthdDetailDesc =
              item.mthdDetailDesc != null
                ? item.mthdDetailDesc.trim()
                : item.mthdDetailDesc;
            item.nasabahName =
              item.nasabahName != null
                ? item.nasabahName.trim()
                : item.nasabahName;
            item.notes = item.notes != null ? item.notes.trim() : item.notes;
            item.ownershipStatus =
              item.ownershipStatus != null
                ? item.ownershipStatus.trim()
                : item.ownershipStatus;
            item.pelakuPencurian =
              item.pelakuPencurian != null
                ? item.pelakuPencurian.trim()
                : item.pelakuPencurian;
            item.prsCollection =
              item.prsCollection != null
                ? item.prsCollection.trim()
                : item.prsCollection;
            item.recordFlag =
              item.recordFlag != null
                ? item.recordFlag.trim()
                : item.recordFlag;
            item.sentraName =
              item.sentraName != null
                ? item.sentraName.trim()
                : item.sentraName;
            item.tabunganNo =
              item.tabunganNo != null
                ? item.tabunganNo.trim()
                : item.tabunganNo;
            item.transaksiType =
              item.transaksiType != null
                ? item.transaksiType.trim()
                : item.transaksiType;
            item.updateBy =
              item.updateBy != null ? item.updateBy.trim() : item.updateBy;
            item.version =
              item.version != null ? item.version.trim() : item.version;
          });

          this.submitInvestigasi({
            jenisPelanggaranList: this.jenisPelanggaranListToSave,
            detailLossList: detailLossList,
            fraudAttList: this.fraudAttAFMList,
            fraudCommitedDate: this.fraudCommitedDate
              ? moment(this.fraudCommitedDate, "DD-MMM-YYYY").format(
                "yyyy-MM-DD"
              )
              : "", //OK tanggal fraud dilakukan
            fraudControl:
              this.fraudControl != null
                ? this.fraudControl.trim()
                : this.fraudControl, //OK kontrol yang tidak berjalan
            fraudDetail:
              this.fraudDetail != null
                ? this.fraudDetail.trim()
                : this.fraudDetail, // Detail / Cara Fraud Dilakukan (lokasi kerja terjadinya fraud ??)
            fraudFactor:
              this.fraudFactor != null
                ? this.fraudFactor.trim()
                : this.fraudFactor, //OK Tujuan Fraud
            fraudFactorDesc:
              this.fraudFactorDesc != null
                ? this.fraudFactorDesc.trim()
                : this.fraudFactorDesc, //OK Jika Tujuan Fraud Lainnya
            fraudId: this.fraudId,
            fraudKnownDate: this.fraudKnownDate
              ? moment(this.fraudKnownDate, "DD-MMM-YYYY").format("yyyy-MM-DD")
              : "", //OK tanggal fraud diketahui
            fraudMthdList: this.modusOperandiList,
            fraudPurpose:
              this.fraudPurpose != null
                ? this.fraudPurpose.trim()
                : this.fraudPurpose, //OK Hari Kecurangan digunakan digunakan untuk apa
            fraudReason:
              this.fraudReason != null
                ? this.fraudReason.trim()
                : this.fraudReason, //OK Alasan Pelaku melakukan fraud
            investigationDelegatorPhone:
              this.investigationDelegatorPhone != null
                ? this.investigationDelegatorPhone.trim()
                : this.investigationDelegatorPhone,
            investigationStatus:
              this.investigationStatus != null
                ? this.investigationStatus.trim()
                : this.investigationStatus, //OK Investigation Status / Status Investigasi
            picInvestigatorEmail: this.emailInvestigator, //??
            picInvestigatorPhone:
              this.picInvestigatorPhone != null
                ? this.picInvestigatorPhone.trim()
                : this.picInvestigatorPhone, //??
            note: this.note != null ? this.note.trim() : this.note, //OK
            fraudCommitedDateTo: this.fraudCommitedDateTo
              ? moment(this.fraudCommitedDateTo, "DD-MMM-YYYY").format(
                "yyyy-MM-DD"
              )
              : "",
            rootCauseList: arraysv, //??
            // violationType: this.violationType, //OK jenis pelanggaran
            // violationTypeDesc: this.violationTypeDesc, //OK jika jenis pelanggaran lainnya
          }).then((response) => {
            this.isFromSave = true;
            console.log("response", response);
            this.$router.push("/investigasi-fraud");
            this.$helpers.handleSuccess(response);
          }).catch((err) => {
            console.log("response", err.response);
            this.$helpers.handleError(err);
          });
        }
      }
    },

    // Memunculkan popup Permintaan Approval
    loadPicAppr() {
      if (this.investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        this.position = 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER'
        console.log("Cek New Position: ", this.position)

        this.getInquiryPic({
          pageNumber: 0,
          pageSize: 1000,
          searchValue: this.searchValuePicAppr,
          role: this.position,
        }).then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        }).catch(() => {
          this.$store.state.inquiryApprover = [];
        });
      } else if (this.investigationDelegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER') {
        this.position = 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
        console.log("Cek New Position: ", this.position)

        this.getInquiryPic({
          pageNumber: 0,
          pageSize: 1000,
          searchValue: this.searchValuePicAppr,
          role: this.position,
        }).then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        }).catch(() => {
          this.$store.state.inquiryApprover = [];
        });
      }
    }, // Ok

    pilihPicAppr(item) {
      this.dialogPicApprBC = false;
      this.picApproverName = item.name;
      this.picApproverNik = item.nik;

      console.log("Cek Data Manager Name: ", this.picApproverName)
      console.log("Cek Data Manager NIK: ", this.picApproverNik)
      console.log("Cek Data Manager Position: ", this.position)
    }, // Ok

    handleSubmitInvestigasiFraud() {
      this.dialogPicAppr = false;
      this.reportStatusCode = 'REPORT_STATUS_VERIFIED'

      this.submitVerifikasiData({
        fraudId: this.fraudId,
        reportStatus: this.reportStatusCode,
        verifStatus: this.verifStatus,
        investigationDelegatorNik: this.picApproverNik,
        investigationDelegatorPhone: this.investigationDelegatorPhone,
        investigationDelegatorPosition: this.position,
        spPelaku: this.spPelaku,
        spNasabah: this.spNasabah,
        reportDocTypeList: this.reportDocTypeList,
        extFraudMthdDesc: this.returnKeterangan != null ? this.returnKeterangan.trim() : this.returnKeterangan,
      }).then((response) => {
        this.isFromSave = true;
        console.log("response", response);
        this.$router.push("/investigasi-fraud");
        this.$helpers.handleSuccess(response);
      }).catch((err) => {
        console.log("response", err.response);
        this.$helpers.handleError(err);
      });
    },
  },

  computed: {
    ...mapState(["username"]),
    fraudId: function () {
      return this.$route.params.fraudId;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
    allInquiryPicApprover() {
      return Object.values(this.$store.getters.allInquiryPicApprover);
    },
    valueSBM() {
      return 25000000 // Variabel untuk menyimpan nilai ambang batas (25 juta)
    },
    valueQAM() {
      return 100000000
    },
  },
};
</script>

<style></style>