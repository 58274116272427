<template>
  <v-app-bar class="main-header" height="64" fixed color="primary">
    <!-- <v-btn icon class="mx-1" @click.stop="drawer">
      <template v-if="drawer">
        <v-icon style="font-size: 28px">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
    </v-btn> -->
    <!-- <v-toolbar-title>Ad1sales</v-toolbar-title> -->
    <v-toolbar-title>
      <span class="white--text">
        <!-- {{ this.$store.state.titleApp }} -->
        <v-img max-width="80" max-height="130" src="@/assets/btpn-logo.png"></v-img>
      </span>
      <!-- <v-col class="ma-0 pa-0">
        <v-row no-gutters class="title-header white--text">
          JAGA
        </v-row>
        <v-row no-gutters class="title-header-bottom white--text">
          Online
        </v-row>
      </v-col> -->
      </v-toolbar-title>
      <span class="white--text">
        <v-img max-width="80" max-height="130" src="@/assets/btpn-logo-tepat.png"></v-img>
      </span>
    <v-spacer></v-spacer>
      <span class="white--text">Selamat datang, {{ this.$store.state.fullName }}</span>
    <!-- <Search /> -->

    <!-- <v-menu
      offset-y
      bottom
      nudge-bottom="10"
      left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="notificationsBadge ? notificationsBadge = !notificationsBadge : ''"
            v-bind="attrs"
            v-on="on"
            style="font-size: 28px"
            icon
            class="mr-2">
              <v-badge
                :value="notificationsBadge"
                color="error"
                content="4"
                overlap>
                  <v-icon
                    style="font-size: 28px"
                    color="rgba(255, 255, 255, 0.35)">mdi-bell-outline</v-icon>
              </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in notifications"
              :key="i">
                <v-list-item-icon class="mr-4 mb-1">
                  <v-icon
                    :color="item.color"
                    v-text="item.icon">
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
    </v-menu> -->
    <!-- <v-menu
      max-width="280"
      offset-y
      bottom
      nudge-bottom="10"
      left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="messageBadge ? messageBadge = !messageBadge : ''"
          v-bind="attrs"
          v-on="on"
          style="font-size: 28px"
          icon
          class="mr-2">
          <v-badge
              :value="messageBadge"
              color="warning"
              content="3"
              overlap>
              <v-icon
                  :color="config.light.iconColor"
                  style="font-size: 28px">
                  mdi-email-outline</v-icon>
            </v-badge>
        </v-btn>
      </template>
      <v-list>
        <div class="text-h5 grey--text text--darken-3 px-4 pt-4">New Messages</div>
        <div class="subtitle-2 error--text font-weight-regular px-4 pb-2 link-item">4 new Messages</div>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in messages"
            :key="i">
            <v-list-item-icon class="mr-4 mb-1 d-flex flex-column">
              <v-btn
                fab
                x-small
                depressed
                :color="item.color">{{ item.text }}
              </v-btn>
              <div style="font-size: 11px">{{ item.time }}</div>
            </v-list-item-icon>
            <v-list-item two-line >
              <v-list-item-content style="width: 190px">
                <v-list-item-title v-text="item.name" class="pb-2"></v-list-item-title>
                <v-list-item-subtitle v-text="item.message" class="text-truncate"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
          </v-list-item-group>
        <div class="d-flex justify-center my-3">
            <v-btn
              large
              color="primary"
              rounded
              elevation="5"
              class="text-capitalize">
              Send New Message
            <v-icon right dark>mdi-send</v-icon>
          </v-btn>
          </div>
      </v-list>
    </v-menu> -->
    <!-- <v-menu
      min-width="180"
      offset-y
      bottom
      left
      nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-0"
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon 
              style="font-size: 28px"
              color="black">mdi-account</v-icon>
            </v-btn>
        </template>
        <v-list >
          <div class="text-h5 grey--text text--darken-3 px-4 pt-4">{{ this.$store.state.username }}</div> -->
    <!-- <div class="subtitle-2 primary--text font-weight-regular px-4">{{ this.$store.state.username }}</div> -->
    <!-- <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in account"
              :key="i"
              :to="item.link === '#' ? null : item.link">
                <v-list-item-icon class="mr-4">
                  <v-icon
                    :color="item.color"
                    v-text="item.icon">
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :color='config.light.textColor' v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <div class="d-flex justify-center my-3">
            <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
<v-btn
              width="80%"
              large
              outlined
              color="primary"
              class="text-capitalize"
v-bind="attrs"
          v-on="on"
              >Sign Out
            </v-btn>        
      </template>
      <v-card>
        <v-card-title class="headline">
          Yakin untuk Logout?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Tidak
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="logOut"
          >
            Ya
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          </div>
        </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "../../config";
//import Search from "@/components/Search/Search";

export default {
  name: "Header",
  //components: { Search },
  data: () => ({
    dialog: false,
    config,
    searchCollapse: true,
    notifications: [
      {
        text: "Check out this awesome ticket",
        icon: "mdi-tag",
        color: "warning",
      },
      {
        text: "What is the best way to get ...",
        icon: "mdi-thumb-up",
        color: "success",
      },
      {
        text: "This is just a simple notification",
        icon: "mdi-flag",
        color: "error",
      },
      {
        text: "12 new orders has arrived today",
        icon: "mdi-cart",
        color: "primary",
      },
    ],
    messages: [
      {
        text: "JH",
        name: "Jane Hew",
        message: "Hey! How is it going?",
        time: "09:32",
        color: "warning",
      },
      {
        text: "LB",
        name: "Lloyd Brown",
        message: "Check out my new Dashboard",
        time: "10:02",
        color: "success",
      },
      {
        text: "MW",
        name: "Mark Winstein",
        message: "I want rearrange the appointment",
        time: "12:16",
        color: "error",
      },
      {
        text: "LD",
        name: "Liana Dutti",
        message: "Good news from sale department",
        time: "14:56",
        color: "primary",
      },
    ],
    account: [
      // { text: 'Aktivasi VA', icon: 'mdi-account', color: 'textColor' , link: '/registrasiVA' },
      // { text: 'Ubah PIN', icon: 'mdi-key-variant', color: 'textColor', link: '/changePIN'  },
      // { text: 'Reset PIN', icon: 'mdi-key-variant', color: 'textColor', link: '/resetPIN'  },
      // { text: 'Ubah Kata Sandi', icon: 'mdi-key', color: 'textColor', link: '/changePass' },
      // { text: 'Profil', icon: 'mdi-account', color: 'textColor', link: '/profile' },
    ],
    notificationsBadge: true,
    messageBadge: true,
  }),
  computed: {
    ...mapState(["drawer"]),
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
    },
  },
  methods: {
    ...mapActions(["TOGGLE_DRAWER"]),
    logOut: function() {
      // window.localStorage.setItem('authenticated', false);
      // this.$router.push('/login');
      this.$store
        .dispatch("logout")
        .then((response) => {
          if (response) {
            let data = response.data;
            if (data.status == 0) {
              const snackbarMsg = data.message;
              const snackbarColor = "red";
              this.$store.commit("setShowSnackbar", {
                snackbarMsg,
                snackbarColor,
              });
            } else {
              const snackbarMsg = data.message;
              const snackbarColor = "green";
              this.$store.commit("setShowSnackbar", {
                snackbarMsg,
                snackbarColor,
              });
              this.$router.push(`/`);
            }
          }
          //this.$router.push('/')
        })
        .catch((error) => {
          if (error.response) {
            let response = error.response;
            // let data = response.data;
            let hasToken = !!response.config.headers.Authorization;
            // console.log(response)
            switch (response.status) {
              case 403:
                if (hasToken) {
                  this.$store.commit("logout");
                  this.$router.push(
                    `${process.env.VUE_APP_PREFIX_ROUTE_PATH}/`
                  );
                }
            }
          }
          // console.log(err.response);
          // let hasToken = !!err.response.config.headers.Authorization;
          // switch(err.response.status){
          //   case 403 : if (hasToken){
          //     this.$store.commit('logout')
          //     return this.$router.push('/')
          //   }
          // }
        });
    },
  },
};
</script>

<style src="./Header.scss" lang="scss"></style>
