<template>
  <v-app>
    <v-form ref="formHolidays" v-model="validFormHolidays" lazy-validation>
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Hari Libur</p>
          <span class="titlet"></span>
          <p v-if="isEdit" class="subTitle">Edit</p>
          <p v-else class="subTitle">Tambah</p>
        </v-row>
        <v-col cols="12" md="18">
          <div class="dp">
            <v-card cols="12">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Nama Hari Libur</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="holidayName"
                      class="rp mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan nama hari libur"
                      @keypress="noChar"
                      @paste="pasteChar"
                      :rules="[
                        (v) =>
                          !!v || 'nama hari libur harus diisi',
                        (v) => (v && v.length <= 32) || 'nama hari libur must be less than 32 characters'
                      ]"

                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Tanggal hari libur</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="holidayStartDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="rp mb-0"
                          placeholder=""
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="holidayStartDateVal"
                        no-title
                        scrollable
                        @input="formatStartDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- <v-icon aria-hidden="false" color="black"> mdi-minus </v-icon> -->
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="holidayEndDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          class="rp mb-0 mt-7"
                          placeholder=""
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="holidayEndDateVal"
                        no-title
                        scrollable
                        @input="formatEndDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="checkRepeat"
                      label="Hari Libur Berulang"
                      class="dp mt-2"
                      @change="checkAllData"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Status</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <!-- <v-switch
                        v-model="status"
                        :label="status == 'Y' ? 'Aktif' : 'Tidak Aktif'"
                        true-value="Y"
                        false-value="N"
                        inset
                        color="orange darken-3"
                        hide-details
                      /> -->
                    <div class="jf mt-2">
                      <v-btn-toggle
                        v-model="status"
                        id="btnGroup"
                        active-class="active"
                      >
                        <v-btn
                          x-large
                          depressed
                          outlined
                          color="grey"
                          cols="12"
                          value="Y"
                          style="
                            width: 112px;
                            height: 48px;
                            border: 1px solid #f5821f;
                            box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                            border-radius: 8px;
                          "
                        >
                          Aktif</v-btn
                        >

                        <v-btn
                          x-large
                          depressed
                          outlined
                          color="grey"
                          cols="12"
                          value="N"
                          style="
                            width: 112px;
                            height: 48px;
                            border: 1px solid #f5821f;
                            box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                            border-radius: 8px;
                          "
                        >
                          Tidak Aktif</v-btn
                        >
                      </v-btn-toggle>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <v-row>
            <v-col class="text-right">
              <v-btn
                v-if="isEdit"
                x-large
                depressed
                class="delegasi ml-8"
                @click="editHolidays"
                ><span>Save</span></v-btn
              >

              <v-btn
                v-else
                x-large
                depressed
                class="delegasi ml-8"
                @click="addHolidays"
                ><span>Save</span></v-btn
              >

              <v-btn
                :to="`/master-hari-libur`"
                x-large
                depressed
                outlined
                class="back"
                ><span class="primary--text">Cancel</span></v-btn
              >

              <!-- <v-btn
                class="elevation-0 text-capitalize mr-3 primary--text"
                depressed
                outlined
                color="primary"
                :to="`/master-hari-libur`"
              >
                <span class="orange--text">Batal</span></v-btn
              >
              <v-btn
                v-if="isEdit"
                class="elevation-0 text-capitalize"
                color="primary"
                @click="editHolidays"
                ><span class="white--text">Simpan</span></v-btn
              >
              <v-btn
                v-else
                class="elevation-0 text-capitalize"
                color="primary"
                @click="addHolidays"
                ><span class="white--text">Simpan</span></v-btn
              > -->
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
// var dateFormat = require("dateformat");

export default {
  data() {
    return {
      formHolidays: false,
      holidayName: "",
      holidayStartDate: "",
      holidayStartDateVal: null,
      holidayEndDate: "",
      holidayEndDateVal: null,
      menu1: false,
      menu2: false,
      // currentDate: dateFormat(
      //   new Date().toISOString().substr(0, 10),
      //   "yyyy-mm-dd"
      // ),
      // currentDateMinusOneDay: new Date(Date.now() - 86400000)
      //   .toISOString()
      //   .substring(0, 10),
      checkRepeat: false,
      status: "N",
      repeatFlag: "N",
    };
  },

  async mounted() {
    await this.loadData();
    // console.log("status", this.status);
  },

  created() {
    this.requiredFeature('HOLIDAY')
  },

  methods: {
    ...mapActions(["changeTitleApp", "updateHolidays", "createHolidays"]),

    formatStartDate() {
      this.menu1 = false;
      if (this.checkRepeat == true) {
        this.holidayStartDate = moment(this.holidayStartDateVal).format(
          "YYYY-MM-DD"
        );
      } else {
        this.holidayStartDate = moment(this.holidayStartDateVal).format(
          "YYYY-MM-DD"
        );
      }
    },

    formatEndDate() {
      this.menu2 = false;
      if (this.checkRepeat == true) {
        this.holidayEndDate = moment(this.holidayEndDateVal).format("YYYY-MM-DD");
      } else {
        this.holidayEndDate = moment(this.holidayEndDateVal).format(
          "YYYY-MM-DD"
        );
      }
    },

    async loadData() {
      if (this.isEdit) {
        await this.$store
          .dispatch("getHolidayById", { holidayId: this.idHoliday })
          .then((response) => {
            this.formData = response.data.data;
            console.log("this.formData", this.formData);
          });
        this.holidayName = this.formData.holidayName;
        this.holidayStartDate = this.formData.holidayStartDate;
        this.holidayEndDate = this.formData.holidayEndDate;
        this.formData.holidayRepeatFlag == "Y"
          ? (this.checkRepeat = true)
          : (this.checkRepeat = false);
        this.status = this.formData.holidayActiveFlag;

        // const holidayStartDate = new Date(this.formData.holidayStartDate);
        // holidayStartDate.setTime(holidayStartDate.getTime() + 86400000);
        // console.log("holidayStartDate", holidayStartDate);
        // this.holidayStartDate = moment(holidayStartDate).format("DD-MMM-YYYY");
        if (this.checkRepeat == true) {
          this.holidayStartDate = moment(this.holidayStartDate).format("YYYY-MM-DD");
        } else {
          this.holidayStartDate =
            moment(this.holidayStartDate).format("YYYY-MM-DD");
        }

        // const holidayEndDate = new Date(this.formData.holidayEndDate);
        // holidayEndDate.setTime(holidayEndDate.getTime() + 86400000);
        // console.log("holidayEndDate", holidayEndDate);
        //this.holidayEndDate = moment(holidayEndDate).format("DD-MMM-YYYY");
        if (this.checkRepeat == true) {
          this.holidayEndDate = moment(this.holidayEndDate).format("YYYY-MM-DD");
        } else {
          this.holidayEndDate = moment(this.holidayEndDate).format("YYYY-MM-DD");
        }
      }
    },

    checkAllData() {
      this.checkRepeat == true
        ? (this.repeatFlag = "Y")
        : (this.repeatFlag = "N");
    },

    addHolidays() {
      let holidayStartDateWithoutFormat = moment(this.holidayStartDateVal);
      let holidayEndDateWithoutFormat = moment(this.holidayEndDateVal);

      if (
        moment(holidayStartDateWithoutFormat).isAfter(
          holidayEndDateWithoutFormat
        )
      ) {
        this.loading = false;
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Tanggal akhir hari libur tidak boleh lebih kecil dari tanggal mulai hari libur",
          snackbarColor: "red",
        });
      } else {
        this.createHolidays({
          holidayName: this.holidayName.trim(),
          holidayStartDate:
            this.holidayStartDateVal == null
              ? ""
              : moment(this.holidayStartDateVal).format("YYYY-MM-DD"),
          holidayEndDate:
            this.holidayEndDateVal == null
              ? ""
              : moment(this.holidayEndDateVal).format("YYYY-MM-DD"),
          holidayStartDateStr:
            this.holidayStartDateVal == null
              ? ""
              : moment(this.holidayStartDateVal).format("YYYY-MM-DD"),
          holidayEndDateStr:
            this.holidayEndDateVal == null
              ? ""
              : moment(this.holidayEndDateVal).format("YYYY-MM-DD"),
          holidayRepeatFlag: this.repeatFlag,
          holidayActiveFlag: this.status,
        })
          .then((response) => {
            this.$router.push(`/master-hari-libur`);
            this.$helpers.handleSuccess(response);
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },

    editHolidays() {
      let holidayStartDateWithoutFormat = moment(this.holidayStartDate);
      let holidayEndDateWithoutFormat = moment(this.holidayEndDate);

      if (
        moment(holidayStartDateWithoutFormat).isAfter(
          holidayEndDateWithoutFormat
        )
      ) {
        this.loading = false;
        this.$store.commit("setShowSnackbar", {
          snackbarMsg:
            "Tanggal akhir hari libur tidak boleh lebih kecil dari tanggal mulai hari libur",
          snackbarColor: "red",
        });
      } else {
        this.updateHolidays({
          holidayId: this.idHoliday,
          holidayName: this.holidayName.trim(),
          // holidayStartDate:
          //   this.holidayStartDate != null
          //     ? moment(this.holidayStartDate).format("YYYY-MM-DD")
          //     : moment(this.holidayStartDateVal).format("YYYY-MM-DD"),
          // holidayEndDate:
          //   this.holidayEndDate != null
          //     ? moment(this.holidayEndDate).format("YYYY-MM-DD")
          //     : moment(this.holidayEndDateVal).format("YYYY-MM-DD"),
          holidayStartDate:
            this.holidayStartDateVal == null
              ? ""
              : moment(this.holidayStartDateVal).format("YYYY-MM-DD"),
          holidayEndDate:
            this.holidayEndDateVal == null
              ? ""
              : moment(this.holidayEndDateVal).format("YYYY-MM-DD"),
          holidayRepeatFlag:
            this.holidayRepeatFlag != null
              ? this.holidayRepeatFlag
              : this.repeatFlag,
          holidayActiveFlag:
            this.holidayActiveFlag != null
              ? this.holidayActiveFlag
              : this.status,
        })
          .then((response) => {
            this.$router.push(`/master-hari-libur`);
            this.$helpers.handleSuccess(response);
            this.loading = false;
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },
  },

  computed: {
    ...mapState(["holidayById"]),
    idHoliday: function () {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
  },
};
</script>

<style src="./Holidays.scss" lang="scss" />
