<template>
  <v-container fluid>
    <v-row>
      <v-col md="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              class="mb-0"
              placeholder=" "
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDateVal"
            no-title
            scrollable
            @input="formatStartDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="6">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              hide-details="auto"
              class="mb-0"
              placeholder=" "
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDateVal"
            no-title
            scrollable
            @input="formatEndDate()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-text-field
          label="User Name"
          class="mb-0"
          hide-details="auto"
          v-model="userName"
          outlined
          placeholder=" "
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-select
          item-text="userTypeName"
          :items="allUserType"
          label="Agent Type"
          class="mb-0"
          hide-details="auto"
          v-model="agentType"
          item-value="userTypeCd"
          outlined
          clearable
          placeholder=" "
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="NIK/ID"
          outlined
          class="mb-0"
          placeholder=" "
          v-model="nik"
        ></v-text-field>
        <v-select
          item-text="branchName"
          item-value="branchCode"
          :items="items"
          v-model="branchCode"
          class="mb-0"
          outlined
          clearable
          hide-details
          label="Branch"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="Nama"
          outlined
          class="mb-0"
          placeholder=" "
          v-model="name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          @click="search"
          class="elevation-0 text-capitalize"
          color="primary"
          >Search</v-btn
        >
        <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-1"
          color="primary"
          @click="downloadStopSellingData"
        >
          Download
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col class="text-right">        
        <v-btn
          class="elevation-0 text-capitalize"
          color="primary"
          :to="`/stop-selling/form/add`"
          >Add</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="allInquiryStopSelling"
      :items-per-page="5"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:item="allStopSellingUsers">
        <tr>
          <td>{{ allStopSellingUsers.item.userName }}</td>
          <td>{{ allStopSellingUsers.item.nik }}</td>
          <td>{{ allStopSellingUsers.item.name }}</td>
          <td>
            {{ allStopSellingUsers.item.agentType }}
          </td>
          <td>
            {{ allStopSellingUsers.item.effStartDate }}
          </td>
          <td>{{ allStopSellingUsers.item.effEndDate }}</td>
          <td>
            {{ allStopSellingUsers.item.endNowBy }}
          </td>
          <td>
            {{ allStopSellingUsers.item.endNowDate }}
          </td>
          <td>{{ allStopSellingUsers.item.createdBy }}</td>
          <td>{{ allStopSellingUsers.item.createdDate }}</td>
          <td>{{ allStopSellingUsers.item.updateBy }}</td>
          <td>{{ allStopSellingUsers.item.updateDate }}</td>
          <td align="center">
            <v-btn
              v-show="beforeToday(allStopSellingUsers.item.effEndDate)"
              small
              text
              class="text-capitalize black--text px-0"
              :to="`/stop-selling/${allStopSellingUsers.item.internalId}/form/edit`"
            >
              Edit
            </v-btn>  

            <v-dialog
              v-model="dialogDel[allStopSellingUsers.item.internalId]"
              persistent
              :key="allStopSellingUsers.item.internalId"
              width="unset"
              v-if="isHidden(allStopSellingUsers.item.effStartDate)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  class="text-capitalize black--text px-0"
                  v-on="on"
                  v-bind="attrs"
                  >Delete
                </v-btn>
              </template>
              <div class="text-center">
                <v-sheet
                  class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
                  color="blue-grey darken-3"
                >
                  <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Yakin untuk menghapus
                    {{ allStopSellingUsers.item.name }} ?
                  </div>

                  <v-btn
                    class="ma-1"
                    color="primary"
                    text
                    @click="
                      $set(
                        dialogDel,
                        allStopSellingUsers.item.internalId,
                        false
                      )
                    "
                  >
                    Tidak
                  </v-btn>

                  <v-btn
                    class="ma-1"
                    color="primary"
                    text
                    @click="
                      removeStopSellingUser(
                        allStopSellingUsers.item.internalId
                      ),
                        $set(
                          dialogDel,
                          allStopSellingUsers.item.internalId,
                          false
                        )
                    "
                  >
                    Ya
                  </v-btn>
                </v-sheet>
              </div>
            </v-dialog>

            <v-dialog
              v-model="dialogEndNow[allStopSellingUsers.item.internalId]"
              persistent
              width="unset"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  text
                  class="text-capitalize black--text px-0"
                  v-on="on"
                  v-show="
                    isEndNow(
                      allStopSellingUsers.item.effStartDate,
                      allStopSellingUsers.item.effEndDate,
                      allStopSellingUsers.item.endNowDate
                    )
                  "
                  >End Now
                </v-btn>
              </template>
              <div class="text-center">
                <v-sheet
                  class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
                  color="blue-grey darken-3"
                >
                  <div class="grey--text text--lighten-1 text-body-2 mb-4">
                    Stop Penjualan Untuk Seller
                    {{ allStopSellingUsers.item.name }} ?
                  </div>

                  <v-btn
                    class="ma-1"
                    color="primary"
                    text
                    @click="
                      $set(
                        dialogEndNow,
                        allStopSellingUsers.item.internalId,
                        false
                      )
                    "
                  >
                    Tidak
                  </v-btn>

                  <v-btn
                    class="ma-1"
                    color="primary"
                    text
                    @click="
                      endNow(allStopSellingUsers.item),
                        $set(
                          dialogEndNow,
                          allStopSellingUsers.item.internalId,
                          false
                        )
                    "
                  >
                    Ya
                  </v-btn>
                </v-sheet>
              </div>
            </v-dialog>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      :total-visible="7"
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      v-model="page"
      :length="Math.ceil(totalData / perPage)"
      @input="handlePageChange"
    >
    </v-pagination>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
//var dateFormat = require("dateformat");

export default {
  data() {
    return {
      // date: new Date().toISOString().substr(0, 10),
      // date2: new Date().toISOString().substr(0, 10),
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      link: "",
      deletedFlag: false,
      menu: false,
      menu2: false,
      dialog: false,
      userName: "",
      agentType: "",
      name: "",
      nik: "",

      startDate: null,
      endDate: null,
      startDateVal: null,
      endDateVal: null,
      currentDate: new Date(),
      branchCode: "",
      selected: "",
      items: [],
      headers: [
        {
          text: "Username",
          align: "start",
          value: "name",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "NIK/ID",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Name",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Agent Type",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Start Date",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "End Date",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "End Now By",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "End Now Date",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Created By",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Created Date",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Update By",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Update Date",
          width: "150px",
          class: "primary black--text",
        },
        {
          text: "Action",
          align: "center",
          width: "250px",
          class: "primary black--text",
        },
      ],
      userStopSelling: {},
      today: new Date(),
      statusSearch: false,

      startDateSearch: '',
      endDateSearch: '',
      usernameSearch: '',
      nameSearch: '',
      agentTypeSearch: '',
      nikSearch: '',
      namaSearch: '',
      branchSearch: '',
    };
  },
  async mounted() {
    // console.log("ASDAS", this.branchCode);
    await this.loadUserProfile();
    this.load();
  },
  created() {
    this.requiredFeature('MNT_STOP_SELL')
    this.changeTitleApp("Register Stop Selling");
  },
  methods: {
    ...mapActions(["changeTitleApp", "downloadStopSelling"]),
    async loadUserProfile() {
      await this.$store.dispatch("getUserProfile").then((res) => {
        // this.setBranch(res.data.data.branchList)
        this.branchCode = res.data.data.branchList[0].branchCode;
        this.items = res.data.data.branchList;
        // console.log("KANTOR CABANG", res.data.data.branchList);
      });
    },
    // setBranch(data) {
    //   for (let i = 0; i < data.length; i++) {
    //     this.branchName = data[i].branchName;
    //     this.branchCode = data[i].branchCode;
    //   }
    //   this.length = data.length;
    // },
    formatStartDate() {
      this.menu = false;
      this.startDate = moment(this.startDateVal).format("DD-MMM-YYYY");
    },
    formatEndDate() {
      this.menu2 = false;
      this.endDate = moment(this.endDateVal).format("DD-MMM-YYYY");
    },
    isEndNow(start, end, endNowDate) {
      // console.log("END NOW", start, end, endNowDate)
      let startDate = moment(start, "DD-MMM-YYYY");
      let endDate = moment(end, "DD-MMM-YYYY");
      // console.log("start: ", startDate)
      // console.log("end: ", endDate)
      // console.log("sama dengan startDate atau setelah ?", moment(this.today).isSameOrAfter(startDate))
      // console.log("sama dengan endDate atau sebelum ?", moment(this.today.setHours(0, 0, 0, 0)).isSameOrBefore(endDate))
      return (
        endNowDate == null &&
        (moment(this.today.setHours(0, 0, 0, 0)).isSameOrAfter(startDate) &&
        moment(this.today.setHours(0, 0, 0, 0)).isSameOrBefore(endDate))
      );
    },
    compareDate(e, f) {
      let endDate = moment(e, "DD-MMM-YYYY");
      // console.log(endDate, "vs", this.currentDate ,"=", moment(endDate).isSameOrAfter(this.currentDate));
      return (
        f == null &&
        moment(endDate).isSameOrAfter(this.currentDate.setHours(0, 0, 0, 0))
      );
    },
    isHidden(b) {
      let startDate = moment(b, "DD-MMM-YYYY");
      return moment(startDate).isAfter(this.currentDate);
    },
    beforeToday(data) {
      let endDate = moment(data, "DD-MMM-YYYY");
      return moment(endDate).isSameOrAfter(this.currentDate.setHours(0, 0, 0, 0));
    },
    downloadStopSellingData(){
      this.downloadStopSelling({
        agentType : this.agentType,
        endDate : this.endDate !== null
          ? moment(this.endDateVal).format("DD-MM-YYYY")
          : "",
        startDate : this.startDate !== null
          ? moment(this.startDateVal).format("DD-MM-YYYY")
          : "",
        userName : this.userName,
        branchCode : this.branchCode,
        name : this.name,
        nik : this.nik,
        pageNumber : 0,
        pageSize : this.countData,
      })
    },
    load() {
      let pageNumber = 0;
      let pageSize = this.perPage;
      let branchCode = this.branchCode;
      this.$store
        .dispatch("getInquiryStopSelling", { pageNumber, pageSize, branchCode })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryStopSelling = [];
        });

      this.$store.dispatch("getUserType");
    },
    handlePageChange(value) {
      // if (
      //   !this.statusSearch && (this.startDate !== null || this.endDate !== null || this.agentType !== null || this.userName !== null || this.nik !== null || this.name !== null )
      // ) {
      //   // console.log("kesini")
      //   this.$store.dispatch("getInquiryStopSelling", {
      //     pageNumber: value - 1,
      //     pageSize: this.perPage,
      //     startDate:"",
      //     endDate: "",
      //     agentType: '',
      //     branchCode: this.branchCode,
      //     userName: '',
      //     nik: '',
      //     name: '',
      //   });
      // } else {
        // console.log("kesini gak ?")
        this.$store.dispatch("getInquiryStopSelling", {
          pageNumber: value - 1,
          pageSize: this.perPage,
          startDate: this.startDateSearch,
          endDate: this.endDateSearch,
          agentType: this.agentTypeSearch,
          branchCode: this.branchSearch ? this.branchSearch : this.branchCode,
          userName: this.usernameSearch,
          nik: this.nikSearch,
          name: this.nameSearch,
        })
      // }
    },
    search() {
      let branchCode = this.branchCode;
      this.branchSearch = this.branchCode;
      let agentType = this.agentType;
      this.agentTypeSearch = this.agentType;
      let endDate =
        this.endDate !== null
          ? moment(this.endDateVal).format("DD-MM-YYYY")
          : "";
      this.endDateSearch = this.endDate !== null
          ? moment(this.endDateVal).format("DD-MM-YYYY")
          : "";
      let startDate =
        this.startDate !== null
          ? moment(this.startDateVal).format("DD-MM-Y YYY")
          : "";
      this.startDateSearch = this.startDate !== null
          ? moment(this.startDateVal).format("DD-MM-YYYY")
          : "";
      let userName = this.userName;
      this.usernameSearch = this.userName;
      let name = this.name;
      this.nameSearch = this.name;
      let nik = this.nik;
      this.nikSearch = this.nik;
      let pageNumber = 0;
      let pageSize = this.countData;
      this.$store
        .dispatch("getInquiryStopSelling", {
          agentType,
          endDate,
          startDate,
          userName,
          branchCode,
          name,
          nik,
          pageNumber,
          pageSize,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (this.agentType == '' && this.endDate == null && this.startDate == null && this.userName == '' && this.name == '' && this.nik == ''
          ) {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryStopSelling = [];
        });
      this.page = 1;
    },
    removeStopSellingUser(id) {
      let internalId = id;
      this.$store
        .dispatch("deleteStopSellingUser", { internalId })
        .then((response) => {
          this.load();
          this.page = 1
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    endNow(user) {
    // let deletedFlag = user.deletedFlag
      // console.log("flag", deletedFlag);
      let internalId = user.internalId;
      this.$store
        .dispatch("endNowStopSellingUser", { internalId })
        .then(() => this.load());
    },
   },
  computed: {
    ...mapGetters(["allUserType", "allEndNowUser"]),
    ...mapState(["listBranch"]),
    allInquiryStopSelling() {
      return Object.values(this.$store.getters.allInquiryStopSelling);
    },
    // branch() {
    //   if (this.listBranch.length === 1) {
    //     return { branchName: this.branchName, branchCode: this.branchCode };
    //   } else {
    //     return null;
    //   }
    // },
  },
};
</script>

<style src="./StopSelling.scss" lang="scss" />
