// https://vuex.vuejs.org/en/actions.html 
import axios from 'axios'
// import { reject } from 'core-js/fn/promise'
//import router from '@/router'


const prefixPath = process.env.VUE_APP_ROOT_URL //process.env.VUE_APP_PREFIX_ROUTE_PATH
const adiraKey = process.env.VUE_APP_ADIRA_KEY

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/login`, {
        login: userData.username,
        password: userData.password,
        captcha: userData.captcha,
        uuid: userData.uuid
      }, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          // 'client-source-type': 'CST_WEB_ADMIN',
          // 'user-type': userData.userType,//'INTERN_SLS'
          // 'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          const token = response.data.token
          const username = userData.username
          const userType = userData.userType
          const refreshTokenAdmin = response.data.refreshToken
          const tokenExpDate = response.data.tokenExpDate
          const refreshTokenExpDate = response.data.refreshTokenExpDate
          const dataUser = response.data.data
          const fullName = response.data.data.fullName
          const userMenu = response.data.data.userAccessList
          commit('auth_success', { fullName, token, username, userType, refreshTokenAdmin, tokenExpDate, refreshTokenExpDate, dataUser, userMenu })
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          commit('auth_error')
          // localStorage.removeItem('token')

          reject(err)
        })
    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/logout`, {}, {
        headers: {
          'Authorization': state.token
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then((response) => {
          commit('logout')
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getInquiryParameterType({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      // console.log("search data ", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchParameterType`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setInquiryParameterType', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getParameterTypeCd({ commit, state }, paramtypeData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getParameterType`, { parameterTypeCode: paramtypeData.parameterTypeCode }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setParamtypeCd', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  updateParamtype({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateParameterType`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setParamTypeData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteParameterType({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteParameterType`, {}, {
        headers: {
          'Authorization': state.token,
          'paramtypeCd': paramData
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addParameterType({ commit, state }, paramTypeData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("param type data", paramTypeData);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addParameterType`, paramTypeData, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setParamTypeData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getInquiryParameter({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchParameter`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryParameter', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addParameter({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addParameter`, {
        paramCd: paramData.parameterCode,
        paramName: paramData.parameterName,
        paramValue: paramData.parameterValue,
        paramType: paramData.parameterTypeCode
      }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setParameterData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  updateParameter({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateParameter`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setParameterData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getParameterByCd({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getParameter`, { paramCd: paramData.paramCd }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setParameterByCd', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteParameter({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteParameter`, {}, {
        headers: {
          'Authorization': state.token,
          'paramCd': paramData
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getInquiryUser({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchUser`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryUser', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  createUser({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/createUser`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setUserData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  updateUser({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateUser`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setUserData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getUserById({ commit, state }, userData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getUser`, { nik: userData.nik }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setUserById', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteUser({ commit, state }, userData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteUser`, {}, {
        headers: {
          'Authorization': state.token,
          'nik': userData.nik
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getInquiryHolidays({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchHolidays`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryHolidays', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  createHolidays({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addHolidays`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setHolidayData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  updateHolidays({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateHolidays`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setUserData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getHolidayById({ commit, state }, holidayData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getHolidays`, { holidayId: holidayData.holidayId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setHolidayById', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteHolidays({ commit, state }, holidayData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteHolidays`, {}, {
        headers: {
          'Authorization': state.token,
          'holidayId': holidayData.holidayId
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getInquiryRole({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchRole`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_inquiryRole', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteRole({ commit, state }, roleData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteRole`, {}, {
        headers: {

          'Authorization': state.token,
          'roleId': roleData.roleId

        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {

          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addRole({ commit, state }, roleData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addRole`
        , roleData, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setRoleData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  updateRole({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateRole`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setRoleData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getRoleId({ commit, state }, roleData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getRole`, { roleId: roleData.roleId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setRoleId', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  updateRoleMenuMapping({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateRoleMenuMapping`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('setRoleMenuMappingData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addRoleMenuMapping({ commit, state }, roleMenuMappingData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addRoleMenuMapping`, roleMenuMappingData, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setRoleMenuMappingData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getRoleMenuMappingId({ commit, state }, roleMenuMappingData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getRoleMenuMapping`, roleMenuMappingData, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setRoleMenuMappingId', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addMenu({ commit, state }, menuData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/addMenu`, {

        menuId: menuData.menuId,
        menuName: menuData.menuName,
        menuDesc: menuData.menuDesc,


      }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setMenuData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryMenu({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchMenu`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_inquiryMenu', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getMenuId({ commit, state }, menuData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getMenu`, menuData, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setMenuId', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getParameterByParamType({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      // commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getParamDataByParamType`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_paramByType', response.data.data)
          // commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          // commit('stopLoading')
          reject(err)
        })
    })
  },

  getKodeModusPengaduanFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      // commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getKodeModusPengaduanFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_paramByType', response.data.data)
          // commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          // commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryEmployee({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getKaryawanAllData`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          //commit('set_inquiryEmployee', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getEmployeeByNik({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      // commit('startLoading')
      console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getKaryawanByNik`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setEmployeeNik', response.data.data)
          // commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          // commit('stopLoading')
          reject(err)
        })
    })
  },

  getEmployeeBySearchValue({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchKaryawanDataBySearchValue`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryEmployee', response.data.data)
          commit('set_inquiryPicInvestigator', response.data.data)
          commit('set_inquiryPicApprover', response.data.data)
          commit('set_inquiryPicBh', response.data.data)
          commit('set_inquiryPicDh', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },



  getInquiryBranch({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getBranchAllData`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // commit('set_inquiryBranch', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getBranchByCd({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      // console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getBranchByBranchCode`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing getBranchByCd", response)
          commit('setBranchCode', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getBranchBySearchValue({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      // console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchBranchDataBySearchValue`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing getBranchByCd", response)
          // commit('set_inquiryBranch', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryFraud({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataPengaduanFraud`, args, {
        headers: {
          'Authorization': state.token,
          'Origin-Name': encodedUserName,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryFraud', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  createFraud({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/createPengaduanFraud`, args, {
        headers: {
          'Authorization': state.token,
          'Origin-Name': encodedUserName,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setFraudData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  // Untuk cancel API Calling [Start]
  // uploadPengaduan({ commit, state }, args) {
  //   let encodedUserName = Buffer.from(state.username).toString('base64')
  //   return new Promise((resolve, reject) => {
  //     commit('startLoading')
  //     axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadPengaduanFraud`, args.formData, {
  //       headers: {
  //         'Authorization': state.token,
  //         'Content-Type': 'multipart/form-data',
  //         'Origin-Name': encodedUserName,
  //       },
  //       validateStatus: function (status) {
  //         return status == 200
  //       },
  //       cancelToken: args.cancelToken,
  //     })
  //       .then(response => {
  //         commit('stopLoading')
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         commit('stopLoading')
  //         reject(err)
  //       })
  //   })
  // },
  // Untuk cancel API Calling [End]

  uploadPengaduan({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadPengaduanFraud`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data',
          'Origin-Name': encodedUserName,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataPelapor({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getKaryawanByNik`, { nik: paramData.nik }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setKaryawanByNik', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  uploadReviewFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadReviewFraud`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryStopSelling({ commit, state }, stopSellingUser) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/inquiryStopSellings`, {
        agentType: stopSellingUser.agentType,
        endDate: stopSellingUser.endDate,
        startDate: stopSellingUser.startDate,
        branchCode: stopSellingUser.branchCode,
        userName: stopSellingUser.userName,
        name: stopSellingUser.name,
        nik: stopSellingUser.nik,
        pageNumber: stopSellingUser.pageNumber,
        pageSize: stopSellingUser.pageSize
      }, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          'user-type': state.userType,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("inquiry", response.data)
          commit('set_inquiryStopSelling', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getStopSellingById({ commit, state }, stopSellingUser) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/getStopSellingById`, { internalId: stopSellingUser.internalId }, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          'user-type': state.userType,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setStopSellingById', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  deleteStopSellingUser({ commit, state }, stopSellingUser) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/deleteStopSelling`, { internalId: stopSellingUser.internalId }, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          'user-type': state.userType,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // commit('delete_user', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  endNowStopSellingUser({ commit, state }, stopSellingUser) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/endNowStopSelling`, { internalId: stopSellingUser.internalId }, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          'user-type': state.userType,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('endNowUser', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  addStopSellingUser({ commit, state }, userData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/addStopSelling`, {
        effStartDate: userData.effStartDate,
        effEndDate: userData.effEndDate,
        endNowBy: userData.endNowBy,
        endNowDate: userData.endNowDate,
        userId: userData.userId
      }, {
        headers: {
          'client-source-type': 'CST_WEB_ADMIN',
          'user-type': state.userType,
          'Authorization': state.token,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setStopSellingData', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  // addStopSelling({ commit }, userData) {

  // },
  updateUserStopSelling({ commit, state }, userStopSelling) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/editStopSelling`, {
        effStartDate: userStopSelling.effStartDate,
        effEndDate: userStopSelling.effEndDate,
        endNowBy: userStopSelling.endNowBy,
        endNowDate: userStopSelling.endNowDate,
        internalId: userStopSelling.internalId,
        userId: userStopSelling.userId
      }, {
        headers: {
          'client-source-type': 'CST_WEB_ADMIN',
          'user-type': state.userType,
          'Authorization': state.token,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setStopSellingData', response.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  changeTitleApp({ commit }, titleApp) {
    commit('setTitleApp', titleApp)
  },

  inquiryFeature({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/ad1sales/web-admin/v1/inquiryFeature`, args, {
        headers: {
          'Authorization': state.token,
          'client-source-type': 'CST_WEB_ADMIN',
          'user-type': state.userType,
          'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log('WKWKWKWKWK', response.data.data)
          commit('setFeature', response.data.data);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryVerifikasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataVerifikasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryVerifikasiData', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  createVerifikasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setFraudData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataFraudId({ commit, state }, verifikasiData) {
    console.log();
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataVerifikasiFraudById`, { fraudId: verifikasiData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setVerifikasiId', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitVerifikasiData({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setSubmitVerifikasi', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveVerifikasiData({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setSaveVerifikasi', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataInvestigasi({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getDataInvestigasiByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataInvestigasiByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveInvestigasi({ commit, state }, args) {
    console.log('Request Save Investigasi', args)
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitInvestigasi({ commit, state }, args) {
    console.log('Request Submit Investigasi', args)
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveRincianDaftarKerugianAuto({ commit, state }, args) {
    console.log('Request Auto Investigasi', args)
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveRincianDaftarKerugianAuto`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getRincianDafKerugianPelakuByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getRincianDafKerugianPelakuById`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getDataKerugianDocumentByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataKerugianDocumentByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  searchDataRinDafKerugianByFraudId({ state }, args) {
    return new Promise((resolve, reject) => {
      // commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataRinDafKerugianByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          // commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataInvestigasiProcess({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataInvestigasiProcess`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryController', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  uploadInvestigasi({ commit, state }, args) { //this is uploadInvestigasi
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getFraudInvProcessDataByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getFraudInvProcessDataByFraudId`, { fraudId: args.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('setProcessId', response.data.data)
          console.log('dataproces', response.data.data);

          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })

    })
  },

  getInquiryPic({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchKaryDataByRoleAndSearchValue`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // commit('set_inquiryPicInvestigator', response.data.data)
          commit('set_inquiryPicApprover', response.data.data)
          // commit('set_inquiryPicBh', response.data.data)
          // commit('set_inquiryPicDh', response.data.data)
          // commit('set_inquiryEmployeeAfm', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  delegateInvestigator({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/delegasiInvProcess`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setDelegateData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  requestPICApproval({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/requestPICApproval`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setApprovalData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitInvestigation({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitInvestigation`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setApprovalData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  requestPICBC({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/requestPICBC`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setBcData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  requestPICDH({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/requestPICDH`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setDhData', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadAttachment({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadAttach`, args,
        {
          headers: {
            'Authorization': state.token,
          },
          // params: {
          //   'base64Data': args.base64Data,
          //   'fileName': args.fileName,
          //   'filePathName': args.filePathName,
          // },
          responseType: 'blob',
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadTemplateDaftarKerugian({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadTemplateDaftarKerugian`, {}, {
        headers: {
          'Authorization': state.token,
          'lossType': args,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadTemplateBranch({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadTemplateBranch`, args, {}, {
        headers: {
          'Authorization': state.token
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadTemplateKaryawan({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadTemplateKaryawan`, args, {}, {
        headers: {
          'Authorization': state.token
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveUploadRincianDaftarKerugian({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveUploadRincianDaftarKerugian`, args.formData, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data',
          'typeTemplate': args.typeTemplate,
          'lossType': args.lossType,
          'fraudId': args.fraudId
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveUploadBranch({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveUploadBranch`, args.formData, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data',

        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveUploadKaryawan({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveUploadKaryawan`, args.formData, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data',

        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataReviewProcess({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataReviewProcess`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchBranch({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      // console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataBranch`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_inquiryBranch', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  searchKaryawanValue({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      // console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataKaryawan`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_inquiryKaryawanValue', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataReviewProcessByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataReviewProcessByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitReviewProcess({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitReviewProcess`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchInvestigasiCheck({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataInvestigasiChecker`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_inquiryInvestigasiCheck', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataInvestigasiChekcId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataInvestigasiCheckerByFraudId`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {

          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },



  reviseChecker({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/revisedInvestigasiChecker`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setRevise', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitInvestigasiCheck({ commit, state }, args) {
    console.log('Test');
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitInvestigasiChecker`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setSubmitCheck', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchReviewFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("ini req", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataReviewFraud`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          // console.log("testing inquiryParameter", response)
          commit('set_reviewFraud', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataReviewFraudId({ commit, state }, args) {
    console.log("ini request review status id : ", args);
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataReviewFraudByFraudId`, args, {
        headers: {
          // 'Authorization': `Bearer ${localStorage.getItem('token')}`
          //  'client-source-type': 'CST_WEB_ADMIN',
          'Authorization': state.token,
          //  'user-type': state.userType,
          //  'X-ADIRA-Key': adiraKey
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {

          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitReviewFraud({ commit, state }, args) {
    console.log("submt review fraud", args);
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitReviewFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          commit('setSubmitReview', response.data)
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getUnsurPelanggaran({ commit, state }, args) {
    console.log("ini request review status id : ", args);
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataUnsurPelanggaran`, args, {
        headers: {

          'Authorization': state.token,

        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {

          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },


  TOGGLE_DRAWER({ commit }) {
    commit('toggleDrawer');
  },

  searchDataRecoveryPayment({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataRecoveryPayment`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryRecoveryPayment', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getRecoveryPaymentByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataRecoveryPaymentByFraudId`, { fraudId: args.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('recoveryPaymentData', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })

    })
  },

  uploadRecoveryPayment({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadRecoveryPayment`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitRecoveryPayment({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitRecoveryPayment`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataKomiteFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataKomiteFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryKomiteFraud', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  uploadKomiteFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadKomiteFraud`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getKomiteFraudByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataKomiteFraudByFraudId`, { fraudId: args.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('recoveryPaymentData', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })

    })
  },

  downloadKomiteFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadKomiteFraud`, args,
        {
          headers: {
            'status': args.status,
            'Authorization': state.token,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitKomiteFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitKomiteFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadDataRekapKerugian({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadDataRekapKerugian`, {}, {
        headers: {
          'Authorization': state.token,
          'fraudId': args,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadDataRincianKerugian({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadDataRincianKerugian`, {}, {
        headers: {
          'Authorization': state.token,
          'fraudId': args,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataTambahModus({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataTambahModus`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadTemplateTambahanModus({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadTemplateTambahanModus`, {}, {
        headers: {
          'Authorization': state.token,
          'lossType': args,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveUploadTambahModusKerugian({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveUploadTambahModusKerugian`, args.formData, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data',
          // 'typeTemplate': args.typeTemplate,
          'lossType': args.lossType,
          'fraudId': args.fraudId
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataTambahModusByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataTambahModusByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  submitTambahModus({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/submitTambahModus`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveTambahanModusAuto({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveTambahanModusAuto`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchDataTambahanModusKerugianByFraudId({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchDataTambahanModusKerugianByFraudId`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  uploadTambahanModus({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadTambahanModus`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchMenu({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchMenu`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getMenu({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getMenu`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  updateMenu({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/updateMenu`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  deleteMenu({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteMenu`, {}, {
        headers: {
          'Authorization': state.token,
          'menuId': args

        },
        // params: {
        //   menuId: args
        // },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  downloadDataLhi({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadDataLhi`, {}, {
        headers: {
          'Authorization': state.token,
          'fraudId': args,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getManagerInvestigatorSearchValue({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchManagerInvestigatorBySearchValue`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryManagerInvestigator', response.data.data)
          // commit('set_inquiryPicInvestigator', response.data.data)
          // commit('set_inquiryPicApprover', response.data.data)
          // commit('set_inquiryPicBh', response.data.data)
          // commit('set_inquiryPicDh', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getAllCase({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getAllCase`, {},
        {
          headers: {
            'Authorization': state.token,
            'Origin-Name': encodedUserName,
          },
          params: {
            'reportDateFrom': args.reportDateFrom,
            'reportDateTo': args.reportDateTo,
            'chartCase': args.chartCase,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDugaanFraud({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDugaanFraud`, {},
        {
          headers: {
            'Authorization': state.token,
            'Origin-Name': encodedUserName,
          },
          params: {
            'reportDateFrom': args.reportDateFrom,
            'reportDateTo': args.reportDateTo,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInvestigasiFraud({ commit, state }, args) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getInvestigasiFraud`, {},
        {
          headers: {
            'Authorization': state.token,
            'Origin-Name': encodedUserName,
          },
          params: {
            'reportDateFrom': args.reportDateFrom,
            'reportDateTo': args.reportDateTo,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getProsesCase({ commit, state }) {
    let encodedUserName = Buffer.from(state.username).toString('base64')
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getProsesCase`, {},
        {
          headers: {
            'Authorization': state.token,
            'Origin-Name': encodedUserName,
          },
          validateStatus: function (status) {
            return status == 200
          },
        })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovAfm({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovAfmReviewer`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryEmployeeAfm', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovInvestigator({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovInvestigator`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryPicInvestigator', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovPicBc({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovPicBc`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryPicBh', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovPicDh({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovPicDh`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('set_inquiryPicDh', response.data.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  deleteKaryawan({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteKaryawan`, {}, {
        headers: {
          'Authorization': state.token,
          'nik': paramData
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  deleteBranch({ commit, state }, paramData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/deleteBranch`, {}, {
        headers: {
          'Authorization': state.token,
          'branchCd': paramData
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovPengguna({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovPengguna`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInquiryLovTerlibat({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/lovTerlibat`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  generateCaptcha({ commit }) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/generateCaptcha`, {}, {
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getDataPelaporByLogin({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataPelaporByLogin`, {}, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  validateToken({ commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/validate`, args, {
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  returnInvestigasi({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/returnInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  reDelegateInvestigasi({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/reDelegateInvestigasi`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  saveInvestigasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/saveInvestigasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  searchFraudPelaku({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchFraudPelaku`, {}, {
        headers: {
          'Authorization': state.token,
          'fraudId': args
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  uploadFilePelaku({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/uploadFilePelaku`, args, {
        headers: {
          'Authorization': state.token,
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },
  downloadFilePelaku({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadFilePelaku?pelakuDtlId=${args.pelakuDtlId}&type=${args.type}`, null, {
        headers: {
          'Authorization': state.token,
        },
        responseType: 'arraybuffer',
        validateStatus: function (status) {
          return status == 200
        },
      }).then(response => {
        commit('stopLoading')
        // const defaultFilename = `file_${args.type}_${args.pelakuDtlId}`;
        // const filename = response.headers['content-disposition'] 
        // ? response.headers['content-disposition'].split('"')[1] : defaultFilename; 
        // console.log(response.headers['content-disposition'], "namaFile");
        // console.log(response.headers, "headers");
        // console.log(response, "response");
        // const blob = new Blob([response.data], { type: response.headers['content-type'] });
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', filename);
        // document.body.appendChild(link);
        // link.click();
        // resolve(response);
        if (response.headers['content-disposition']) {
          const filename = response.headers['content-disposition'].split('"')[1];
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          resolve(response);
        } else {
          throw new Error("Nama File tidak valid, File tidak bisa didownload");
        }
      }).catch(err => {
        commit('stopLoading')
        reject(err);
      })
    })
  },
  async downloadVerifikasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/downloadVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      }).then(response => {
        commit('stopLoading')
        console.log(response, "responseDownload")
        let file = response.data.data
        let base64Data = file.base64Data
        if (base64Data) {
          let xlsxSource = `data:application/octet-stream;base64,${base64Data}`
          const link = document.createElement('a');
          link.href = xlsxSource;
          link.setAttribute('download', `${file.fileName}`);
          document.body.appendChild(link);
          link.click();
          link.remove()
        }
        resolve(response)
      }).catch(err => {
        commit('stopLoading')
        reject(err)
      })
    })
  },
  
  killVerifikasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/killVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getManagerInvestigator({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchManagerInvestigatorBySearchValue`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let filteredData = response.data.data.filter(item => item.roleCd === args.roleCd);
          commit('set_inquiryManagerInvestigator', filteredData)
          commit('set_inquiryPicInvestigator', filteredData)
          commit('set_inquiryPicApprover', filteredData)
          commit('stopLoading')
          resolve(response)
          console.log(response, "cek Manager")
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getPicInvestigator({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      console.log("args", args);
      axios.post(`${prefixPath}/api/jagaonline/main/v1/searchPicInvestigator`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let filteredData = response.data.data.filter(item => item.roleCd === args.roleCd);
          commit('set_inquiryPicInvestigator', filteredData)
          commit('stopLoading')
          resolve(response)
          console.log(response, "cek Manager")
        })
        .catch(err => {
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },

  reDelegateVerifikasiFraud({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/reDelegateVerifikasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  exportExcelMonitoringReport({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/exportExcel`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          let file = response.data.data
          let base64Data = file.base64Data
          if (base64Data) {
            let fileSource = `data:application/octet-stream;base64,${base64Data}`
            const link = document.createElement('a');
            link.href = fileSource;
            link.setAttribute('download', `${file.fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
          }
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          commit('stopLoading')
          reject(err)
        })
    })
  },

  createInvHistory({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/createInvestigatorHistory`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          console.log(response);
          commit('setCreateInvHistory', response.data)
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          commit('auth_error')
          commit('stopLoading')
          reject(err)
        })
    })
  },
  getLoginRole({ commit, state }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getLoginRole`,
      {
        "username": args
      }, 
      {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
        .then(response => {
          commit('stopLoading')
          resolve(response)
        })
        .catch(err => {
          console.log('getLoginRole ERR', err);
          commit('stopLoading')
          reject(err)
        })
    })
  },

  getInitialData({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getInitialData`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getInitialData ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDataPelaporByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataPelapor`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDataPelaporByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getFraudHistoryByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getFraudHistory`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getFraudHistoryByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDetailLaporanByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDetailLaporan`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDetailLaporanByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getYangTerlibatByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getYangTerlibat`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getYangTerlibatByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getModusOperandiByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getModusOperandi`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getModusOperandiByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getAttachmentByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getAttachment`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getAttachmentByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getVerifikasiFraudByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getVerifikasiFraud`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getVerifikasiFraudByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDokumenAwalByFraudId({ commit, state }, fraudIdData) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDokumenAwal`, { fraudId: fraudIdData.fraudId }, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDokumenAwalByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getManagerInvestigatorList({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getManagerInvestigatorList`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getManagerInvestigatorList ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDataInvestigatorByFraudId({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataInvestigator`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDataInvestigatorByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDataInvestigasiFraudByFraudId({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDataInvestigasiFraud`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDataInvestigasiFraudByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getRootCauseByFraudId({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getRootCause`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getRootCauseByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getAttachmentAFMByFraudId({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getAttachmentAFM`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getAttachmentAFMByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

  getDelegasiInvestigatorByFraudId({ state, commit }, args) {
    return new Promise((resolve, reject) => {
      commit('startLoading')
      axios.post(`${prefixPath}/api/jagaonline/main/v1/getDelegasiInvestigator`, args, {
        headers: {
          'Authorization': state.token,
        },
        validateStatus: function (status) {
          return status == 200
        },
      })
      .then(response => {
        commit('stopLoading')
        resolve(response)
      })
      .catch(err => {
        console.log('getDelegasiInvestigatorByFraudId ERR', err);
        commit('stopLoading')
        reject(err)
      })
    })
  },

}
