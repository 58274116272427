<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Review Controller</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field"> No. LHI </h7>
        <v-text-field
          v-model="refNo"
          class="mb-0 white mr-1"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari no. referensi"
          label=""
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Lokasi Kejadian </h7>
        <v-text-field
          v-model="fraudLoc"
          class="mb-0 mr-1 white"
          hide-details="auto"
          outlined
          dense
          placeholder="Cari lokasi kejadian"
          label=""
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary">
          Tanggal Selesai Investigasi
        </h7>
        <v-row no-gutters>
          <v-col cols="12">
            <v-menu
              ref="menu1"
              v-model="reportDateFromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reportDateFrom"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-0 mr-1 white"
                  placeholder=""
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reportDateFromVal"
                no-title
                scrollable
                @input="pickReportDateFrom"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Jenis Fraud </h7>
        <v-select
          v-model="fraudType"
          class="mb-0 mr-1 white"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          :items="jenisFraudList"
          item-text="paramName"
          item-value="paramCd"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <h7 class="label-text-field" color="primary"> Status </h7>
        <v-select
          v-model="reportStatus"
          class="mb-0 mr-1 white"
          hide-details="auto"
          outlined
          dense
          placeholder="Semua"
          label=""
          :items="infoStatus"
          item-text="paramName"
          item-value="paramCd"
        ></v-select>
      </v-col>
      <v-col md="4">
        <h7 class="label-text-field white--text" color="primary"> "" </h7>
        <v-row cols="12" md="4" class="text--right">
          <v-btn
            @click="handleGetInquiryReview"
            class="elevation-0 text-capitalize ml-3"
            cols="12"
            color="primary"
          >
            <span class="white--text">Cari</span></v-btn
          >
          <v-btn
            class="elevation-0 text-capitalize ml-2 primary--text"
            depressed
            outlined
            color="primary"
            cols="12"
            @click="reset"
          >
            <span class="orange--text">Reset</span></v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersReview"
                  :items="inquiryReview"
                  :items-per-page="5"
                  :header-props="{ sortIcon: null }"
                  disable-sort
                  mobile-breakpoint="0"
                  ><template v-slot:item="data">
                    <tr>
                      <td class="orange--text">{{ data.item.jagaNo }}</td>
                      <td>{{ data.item.investigationEndDateStr }}</td>
                      <td>{{ data.item.fraudLocName }}</td>
                      <td>{{ data.item.fraudTypeName }}</td>
                      <td>
                        <v-chip
                          class="ma-2"
                          color="orange lighten-4"
                          text-color="orange"
                        >
                          {{ data.item.reportStatusName }}
                        </v-chip>
                      </td>
                      <td>
                        <span
                          :class="`${data.item.slaColor !== undefined ? data.item.slaColor.toLowerCase() : ''}--text`"
                        >
                          {{ data.item.sla }}
                        </span>
                      </td>
                      <td align="center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs"
                              @click="handleDownloadDataLhi(data.item.fraudId)">
                              <v-icon aria-hidden="false" style="font-size: 20px"
                                color="#F5821F">mdi-printer-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Print</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs" :to="`/review-controller/${data.item.fraudId}/form`">
                              <v-icon aria-hidden="false" style="font-size: 20px" color="#F5821F">
                                mdi-share-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Proses</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      fraudType: "",
      jenisFraudList: [],
      reportDateFromMenu: false,
      reportDateToMenu: false,
      infoStatus: [],
      fraudLoc: "",
      refNo: "",
      reportDate: "",
      reportDateFrom: "",
      reportDateTo: "",
      reportStatus: "",
      reportDateFromVal: null,
      reportDateToVal: null,
      headersReview: [
        {
          text: "No. LHI",
          align: "center",
          width: "150px",
        },
        {
          text: "Tanggal Selesai Investigasi",
          align: "center",
          width: "180px",
        },
        {
          text: "Lokasi Kejadian",
          align: "center",
          width: "158px",
        },
        {
          text: "Jenis Fraud",
          width: "134px",
          align: "center",
        },
        {
          text: "Status",
          width: "170px",
          align: "center",
        },
        {
          text: "Running SLA",
          width: "200px",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "200px",
          align: "center",
        },
      ],
      inquiryReview: [
        {
          jaga: "a",
        },
      ],
    };
  },
  created() {
    this.requiredFeature('REVIEW_CONTROLLER')
    this.changeTitleApp("Review Controller");
  },
  async mounted() {
    this.handleGetInquiryReview();
    this.loadReportStatus();
    this.loadJenisFraud();
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "searchDataReviewProcess",
      "getParameterByParamType",
      "downloadDataLhi",
    ]),

    handleDownloadDataLhi(fraudId) {
      this.downloadDataLhi(fraudId)
        .then((resp) => {
          console.log("susccess", resp);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("error", err.response);
        });
    },

    reset() {
      this.refNo = "";
      this.fraudLoc = "";
      this.reportDateFrom = "";
      this.fraudType = "";
      this.reportStatus = "";

      this.handleGetInquiryReview();
    },

    handleGetInquiryReview() {
      this.searchDataReviewProcess({
        fraudId: null,
        fraudLoc: this.fraudLoc,
        pageNumber: 0,
        pageSize: 10000,
        jagaNo: this.refNo,
        investigationEndDt: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
        // reportDate: this.reportDateFrom ? moment(this.reportDateFrom, 'DD-MMM-YYYY').format("yyyy-MM-DD") : "",
        reportDateFrom: "",
        reportDateTo: this.reportDateTo,
        reportStatus: this.reportStatus,
        fraudType: this.fraudType,
      })
        .then((response) => {
          this.inquiryReview = response.data.data;
        })
        .catch(() => {
          this.inquiryReview = [];
        });
    },

    pickReportDateFrom() {
      this.reportDateFromMenu = false;
      this.reportDateFrom = moment(this.reportDateFromVal).format(
        "DD-MMM-YYYY"
      );
    },

    pickReportDateTo() {
      this.reportDateToMenu = false;
      this.reportDateTo = moment(this.reportDateToVal).format("DD-MMM-YYYY");
    },

    loadReportStatus() {
      this.getParameterByParamType({
        paramType: "REPORT_STATUS",
      })
        .then((resp) => {
          this.infoStatus = resp.data.data.filter(
            (item) => item.paramCd === "REPORT_STATUS_INVESTIGATION_SUBMIT"
          );
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    loadJenisFraud() {
      this.getParameterByParamType({
        paramType: "FRAUD_TYPE",
      })
        .then((resp) => {
          this.jenisFraudList = resp.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
};
</script>

<style src="./ReviewController.scss" lang="scss" />