<template>
  <v-app>
    <v-form ref="formMenu" v-model="validFormMenu" lazy-validation>
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Menu</p>
          <span class="titlet"></span>
          <p class="subTitle">Edit</p>
        </v-row>
        <v-col cols="12" md="18">
          <!-- <div class="top"> -->
          <v-card cols="12">
            <v-card-text>
              <v-row class="mx-1">
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Code</p>
                  </v-row>
                  <v-text-field
                    v-model="menuCode"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Name</p>
                    <span class="ml-2 mt-1"> Wajib </span>
                  </v-row>
                  <v-text-field
                    v-model="menuName"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    :rules="[
                        (v) =>
                          !!v || 'nama menu harus diisi',
                        (v) => (v && v.length <= 64) || 'nama menu must be less than 64 characters'
                      ]"
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Description</p>
                    <span class="ml-2 mt-1"> Wajib </span>
                  </v-row>
                  <v-textarea
                    v-model="menuDesc"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    :rules="[
                        (v) =>
                          !!v || 'deskripsi menu harus diisi',
                        (v) => (v && v.length <= 256) || 'deskripsi menu must be less than 256 characters'
                      ]"
                  ></v-textarea>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Type</p>
                  </v-row>
                  <v-select
                    :items="listMenuType"
                    item-text="paramName"
                    item-value="paramCd"
                    label=""
                    class="rp mb-0"
                    hide-details="auto"
                    v-model="menuType"
                    outlined
                    dense
                    placeholder="Semua"
                    disabled
                  ></v-select>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Level</p>
                  </v-row>
                  <v-text-field
                    v-model="menuLevel"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Menu Parent</p>
                  </v-row>
                  <v-text-field
                    v-model="parentId"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">Sequence</p>
                    <span class="ml-2 mt-1"> Wajib </span>
                  </v-row>
                  <v-text-field
                    v-model="menuSeq"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    @keypress="numOnly"
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-row class="verifStat ml-0">
                    <p class="label-text-field ml-0">URL</p>
                  </v-row>
                  <v-text-field
                    v-model="menuUrl"
                    class="mb-0"
                    hide-details="auto"
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- </div> -->
          <v-row>
            <v-col class="text-right">
              <v-btn
                :to="`/menu`"
                x-large
                depressed
                outlined
                class="elevation-0 text-capitalize mr-3 primary--text"
                ><span class="primary--text">Cancel</span></v-btn
              >

              <v-btn x-large depressed color="primary" @click="handleUpdateMenu"
                ><span class="white--text">Save</span></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      formMenu: false,
      parentId: null,
      menuCode: "",
      menuName: "",
      menuDesc: "",
      menuType: "",
      menuLevel: null,
      menuSeq: null,
      menuUrl: null,
      listMenuType: [],
    };
  },

  mounted() {
    this.loadData();
    this.loadMenuType();
  },

  created() {
    this.requiredFeature('MENU')
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "updateMenu",
      "getMenu",
      "getParameterByParamType",
    ]),

    loadData() {
      this.getMenu({
        menuDesc: "",
        menuId: this.menuId,
        menuLevel: 0,
        menuName: "",
        menuSeq: 0,
        menuType: "",
        menuUrl: "",
        pageNumber: 0,
        pageSize: 0,
        parentId: 0,
        searchValue: "",
      }).then((response) => {
        let formData = response.data.data;

        console.log("fairy", formData);

        this.parentId = formData.parentId;
        this.menuCode = formData.menuCode;
        this.menuName = formData.menuName;
        this.menuDesc = formData.menuDesc;
        this.menuType = formData.menuType.paramCd;
        this.menuLevel = formData.menuLevel;
        this.menuSeq = formData.menuSeq;
        this.menuUrl = formData.menuUrl;
      });
    },

    loadMenuType() {
      this.getParameterByParamType({
        paramType: "MENU_TYPE",
      })
        .then((res) => {
          this.listMenuType = res.data.data;
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    handleUpdateMenu() {
      this.updateMenu({
        menuId: this.menuId,
        menuName: this.menuName.trim(),
        menuDesc: this.menuDesc.trim(),
        menuSeq: this.menuSeq,
      })
        .then((response) => {
          this.$router.push(`/menu`);
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    editUser() {
      this.updateUser({
        userId: this.idUser,
        nik: this.nik,
        fullName: this.fullName,
        emailAddress: this.email,
        roleId: this.roleId,
        department: this.department,
        status: this.status == true ? "USER_ACTIVE" : "USER_NOT_ACTIVE",
      })
        .then((response) => {
          this.$router.push(`/pengguna`);
          this.$helpers.handleSuccess(response);
          this.loading = false;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },
  },

  computed: {
    ...mapState(["userById"]),
    allInquiryRole() {
      return Object.values(this.$store.getters.allInquiryRole);
    },
    menuId: function () {
      return this.$route.params.menuId;
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
  },
};
</script>
