// https://vuex.vuejs.org/en/mutations.html

// import axios from "axios"


export default {
  setProfile(state, { profileData }) {
    state.profile = profileData
  },
  resetProfile(state) {
    state.profile = {}
  },
  setListPromoCountBaseOn(state, listPromoCountBaseOn) {
    state.listPromoCountBaseOn = listPromoCountBaseOn
  },
  setListPaymentType(state, listPaymentType) {
    state.listPaymentType = listPaymentType
  },
  setEditFaq(state, dataEdited) {
    state.dataEdited = dataEdited
  },
  setAddFaq(state, faqData) {
    state.faqData = faqData
  },
  setFaqById(state, faqById) {
    state.faqById = faqById
  },
  setListFaqCategory(state, listFaqCategory) {
    state.listFaqCategory = listFaqCategory
  },
  setInquiryFaq(state, inquiryFaq) {
    inquiryFaq.forEach(function (data) { delete data.answer })
    state.inquiryFaq = inquiryFaq
  },
  setPopupNotiById(state, initPopupNotiData) {
    state.initPopupNotiData = initPopupNotiData
  },
  setAddPopupNoti(state, popupNotiData) {
    state.popupNotiData = popupNotiData
  },
  setInquiryPopupNoti(state, inquiryPopupNoti) {
    state.inquiryPopupNoti = inquiryPopupNoti
  },
  setListPopupNotiType(state, listPopupNotiType) {
    state.listPopupNotiType = listPopupNotiType
  },
  setProgramById(state, initProgramData) {
    state.initProgramData = initProgramData
  },
  setAddProgram(state, programData) {
    state.programData = programData
  },
  setInquiryProgram(state, inquiryProgram) {
    state.inquiryProgram = inquiryProgram
  },
  setSalesKitById(state, salesKitById) {
    state.salesKitById = salesKitById
  },
  setEditSalesKit(state, salesKitEdited) {
    state.salesKitEdited = salesKitEdited
  },
  setAddSalesKit(state, salesKitData) {
    state.salesKitData = salesKitData
  },
  setInquirySalesKit(state, inquirySalesKit) {
    inquirySalesKit.forEach(function (data) {
      delete data.iconSmall
      delete data.iconMedium
    })
    state.inquirySalesKit = inquirySalesKit
  },
  setInquirySchedLog(state, inquirySchedLog) {
    state.inquirySchedLog = inquirySchedLog
  },
  setListSchedLogStatus(state, listSchedLogStatus) {
    state.listSchedLogStatus = listSchedLogStatus
  },
  setListSchedLog(state, listSchedLog) {
    state.listSchedLog = listSchedLog
  },
  setSchedLogById(state, schedLogById) {
    state.schedLogById = schedLogById
  },
  setActivityById(state, activityById) {
    state.activityById = activityById
  },
  setListActivityType(state, listActivityType) {
    state.listActivityType = listActivityType
  },
  setInquiryActivity(state, inquiryActivity) {
    state.inquiryActivity = inquiryActivity
  },
  setRegVAFormModeById(state, initRegVAFormModeData) {
    state.initRegVAFormModeData = initRegVAFormModeData
  },
  setAddRegVAFormMode(state, regVAFormModeData) {
    state.regVAFormModeData = regVAFormModeData
  },
  setInquiryRegVAFormMode(state, inquiryRegVAFormMode) {
    state.inquiryRegVAFormMode = inquiryRegVAFormMode
  },
  setListFieldMode(state, listFieldMode) {
    state.listFieldMode = listFieldMode
  },
  setListSystemType(state, listSystemType) {
    state.listSystemType = listSystemType
  },
  setListDatabase(state, listDatabase) {
    state.listDatabase = listDatabase
  },
  setSystemById(state, systemById) {
    state.systemById = systemById
  },
  setEditSystem(state, systemEdited) {
    state.systemEdited = systemEdited
  },
  setAddSystem(state, systemData) {
    state.systemData = systemData
  },
  setInquirySystem(state, inquirySystem) {
    state.inquirySystem = inquirySystem
  },
  setBankById(state, bankById) {
    state.bankById = bankById
  },
  setEditBank(state, bankEdited) {
    state.bankEdited = bankEdited
  },
  setAddBank(state, bankData) {
    state.bankData = bankData
  },
  setInquiryBank(state, inquiryBank) {
    state.inquiryBank = inquiryBank
  },
  setProductPartnerById(state, productPartnerById) {
    state.productPartnerById = productPartnerById
  },
  setEditProductPartner(state, productPartnerEdited) {
    state.productPartnerEdited = productPartnerEdited
  },
  setAddProductPartner(state, productPartnerData) {
    state.productPartnerData = productPartnerData
  },
  setInquiryProductPartner(state, inquiryProductPartner) {
    state.inquiryProductPartner = inquiryProductPartner
  },
  setInterfaceDataMapBy(state, initInterfaceDataMapData) {
    state.initInterfaceDataMapData = initInterfaceDataMapData
  },
  setInquiryDataMap(state, inquiryInterfaceDataMapData) {
    state.inquiryInterfaceDataMapData = inquiryInterfaceDataMapData
  },
  setAddInterfaceDataMap(state, interfaceDataMapData) {
    state.interfaceDataMapData = interfaceDataMapData
  },
  setListDataType(state, listDataType) {
    state.listDataType = listDataType
  },
  setListExternSystem(state, listExternSystem) {
    state.listExternSystem = listExternSystem
  },
  setInquiryFeatureGroup(state, inquiryFeatureGroup) {
    state.inquiryFeatureGroup = inquiryFeatureGroup
  },
  setAddFeature(state, featureData) {
    state.featureData = featureData
  },
  setFeatureByFeatureCode(state, initFeatureData) {
    state.initFeatureData = initFeatureData
  },
  setInquiryProductSetting(state, inquiryProductSetting) {
    state.inquiryProductSetting = inquiryProductSetting
  },
  setProductSettingById(state, productSettingById) {
    state.productSettingById = productSettingById
  },
  setAddProductSetting(state, productSettingNewData) {
    state.productSettingNewData = productSettingNewData
  },
  setEditProductSetting(state, productSettingUpdated) {
    state.productSettingUpdated = productSettingUpdated
  },
  setInquiryCommission(state, inquiryCommission) {
    state.inquiryCommission = inquiryCommission
  },
  setCommissionById(state, commissionById) {
    state.commissionById = commissionById
  },
  setAddComission(state, commissionAdded) {
    state.commissionAdded = commissionAdded
  },
  setEditCommission(state, commissionEdited) {
    state.commissionEdited = commissionEdited
  },
  setListPartyType(state, listPartyType) {
    state.listPartyType = listPartyType
  },
  setUserTypePrefix(state, listUserTypePrefix) {
    state.listUserTypePrefix = listUserTypePrefix
  },
  setListCustomerType(state, listCustomerType) {
    state.listCustomerType = listCustomerType
  },
  setListPaymentPeriodType(state, listPaymentPeriodType) {
    state.listPaymentPeriodType = listPaymentPeriodType
  },
  setInquiryProductAccess(state, inquiryProductAccess) {
    state.inquiryProductAccess = inquiryProductAccess
  },
  setAddProductAccess(state, productAccessData) {
    state.productAccessData = productAccessData
  },
  setProductAccessById(state, initProductAccessData) {
    state.initProductAccessData = initProductAccessData
  },
  setInquiryAdminFee(state, inquiryAdminFee) {
    state.inquiryAdminFee = inquiryAdminFee
  },
  setAddAdminFee(state, adminFeeData) {
    state.adminFeeData = adminFeeData
  },
  setAdminFeeById(state, initAdminFeeData) {
    state.initAdminFeeData = initAdminFeeData
  },
  setAddProduct(state, productAdded) {
    state.productAdded = productAdded
  },
  setEditProduct(state, productEdited) {
    state.productEdited = productEdited
  },
  setDisableProduct(state, productDisabled) {
    state.productDisabled = productDisabled
  },
  setEnableProduct(state, productEnabled) {
    state.productEnabled = productEnabled
  },
  setProductById(state, productById) {
    state.productById = productById
  },
  setInquiryProduct(state, inquiryProduct) {
    state.inquiryProduct = inquiryProduct
  },
  setListProductPartner(state, listProductPartner) {
    state.listProductPartner = listProductPartner
  },
  setListPremiumPartner(state, listPremiumPartner) {
    state.listPremiumPartner = listPremiumPartner
  },
  setListProductType(state, listProductType) {
    state.listProductType = listProductType
  },
  setListAppovalStatus(state, listApprovalStatus) {
    state.listApprovalStatus = listApprovalStatus
  },
  setListSyariahType(state, listSyariahType) {
    state.listSyariahType = listSyariahType
  },
  setListVehicleType(state, listVehicleType) {
    state.listVehicleType = listVehicleType
  },
  setInquiryCashoutAccAppr(state, { inquiryApproval }) {
    state.listInquiryApproval = inquiryApproval
  },
  setCashoutAccApprById(state, cashoutAccApprById) {
    state.cashoutAccApprById = cashoutAccApprById
  },
  setApprovalCashoutAccAppr(state, approvalCashoutAccAppr) {
    state.approvalCashoutAccAppr = approvalCashoutAccAppr
  },
  setBankAccInfo(state, { bankAccInfoData }) {
    state.bankAccInfo = bankAccInfoData
  },
  setSubmitPIN(state, submitPIN) {
    state.submitPIN = submitPIN
  },
  setSubmitOTP(state, submitOTP) {
    state.submitOTP = submitOTP
  },
  endNowUser(state, endNowUser) {
    state.endNowUser = endNowUser
  },
  // setUserType(state, userType) {
  //   state.userType = userType
  // },
  set_inquiryStopSelling(state, inquiryStopSelling) {
    state.inquiryStopSelling = inquiryStopSelling
  },
  set_registeredUser(state, registeredUser) {
    state.registeredUser = registeredUser
  },
  // delete_user(state, stopSellingUser) {
  //   state.stopSellingUserList = state.stopSellingUserList.filter(b => stopSellingUser.id !== b.id)
  // },

  setParamTypeData(state, user) {
    state.paramTypeDataList.push(user)
  },

  setInquiryParameterType(state, inquiryParameterType) {
    state.inquiryParameterType = inquiryParameterType
  },

  setParamtypeCd(state, paramTypeCd) {
    state.paramTypeCd = paramTypeCd
  },



  setParameterData(state, user) {
    state.parameterDataList.push(user)
  },

  set_inquiryParameter(state, inquiryParameter) {
    state.inquiryParameter = inquiryParameter
  },

  setParameterByCd(state, parameterByCd) {
    state.parameterByCd = parameterByCd
  },

  set_inquiryUser(state, inquiryUser) {
    state.inquiryUser = inquiryUser
  },

  setUserData(state, user) {
    state.userDataList.push(user)
  },

  setUserById(state, userById) {
    state.userById = userById
  },

  set_inquiryHolidays(state, inquiryHolidays) {
    state.inquiryHolidays = inquiryHolidays
  },

  setHolidayById(state, holidayById) {
    state.holidayById = holidayById
  },

  setHolidayData(state, user) {
    state.holidayDataList.push(user)
  },

  setRoleData(state, user) {
    state.roleDataList.push(user)
  },

  set_inquiryRole(state, inquiryRole) {
    state.inquiryRole = inquiryRole
  },

  setRoleId(state, roleId) {
    state.roleId = roleId
  },

  setRoleMenuMappingData(state, user) {
    state.roleMenuMappDataList.push(user)
  },

  setRoleMenuMappingId(state, roleMenuId) {
    state.roleMenuId = roleMenuId
  },

  setMenuData(state, user) {
    state.menuDataList.push(user);
  },

  set_inquiryMenu(state, menuId) {
    state.menuId = menuId
  },
  setMenuId(state, menuId) {
    state.menuId = menuId
  },


  set_paramByType(state, parameterByType) {
    state.parameterByType = parameterByType
  },
  set_inquiryEmployee(state, inquiryEmployee) {
    state.inquiryEmployee = inquiryEmployee
  },

  set_inquiryManagerInvestigator(state, inquiryManagerInvestigator) {
    state.inquiryManagerInvestigator = inquiryManagerInvestigator
  },
  setEmployeeNik(state, employeeNik) {
    state.employeeNik = employeeNik
  },
  set_inquiryBranch(state, inquiryBranch) {
    state.inquiryBranch = inquiryBranch
  },
  set_inquiryKaryawanValue(state, inquiryKaryawanValue){
    state.inquiryKaryawanValue = inquiryKaryawanValue
  },
  setBranchCode(state, branchCode) {
    state.branchCode = branchCode
  },
  set_inquiryFraud(state, inquiryFraud) {
    state.inquiryFraud = inquiryFraud
  },
  setFraudData(state, user) {
    state.fraudDataList.push(user)
  },
  setKaryawanByNik(state, karyawanByNik) {
    state.karyawanByNik = karyawanByNik
  },

  setSubmitVerifikasi(state, user){
    state.fraudDataList.push(user)
  },

  setSaveVerifikasi(state, user){
    state.fraudDataList.push(user)
  },

  set_inquiryVerifikasiData(state, inquiryVerifikasi){
    state.inquiryVerifikasi = inquiryVerifikasi
  },

  setVerifikasiId(state, verifikasiId) {
    state.verifikasiId = verifikasiId
  },
  set_inquiryController(state, inquiryInvestigasiProcess) {
    state.inquiryInvestigasiProcess = inquiryInvestigasiProcess
  },
  setProcessId(state, processId) {
    state.processId = processId
  },
  set_inquiryEmployeeAfm(state, inquiryAfm) {
    state.inquiryAfm = inquiryAfm
  },
  set_inquiryPicInvestigator(state, inquiryInvestigator) {
    state.inquiryInvestigator = inquiryInvestigator
  },
  set_inquiryPicApprover(state, inquiryApprover) {
    state.inquiryApprover = inquiryApprover
  },
  set_inquiryPicBh(state, inquiryBh) {
    state.inquiryBh = inquiryBh
  },
  set_inquiryPicDh(state, inquiryDh) {
    state.inquiryDh = inquiryDh
  },
  setApprovalData(state, user) {
    state.approvalDataList.push(user)
  },
  setBcData(state, user) {
    state.bcDataList.push(user)
  },
  setDhData(state, user) {
    state.dhDataList.push(user)
  },
  setDelegateData(state, user) {
    state.delegateDataList.push(user)
  },
  set_inquiryRecoveryPayment(state, inquiryRecoveryPayment) {
    state.inquiryRecoveryPayment = inquiryRecoveryPayment
  },
  recoveryPaymentData(state, recoveryPaymentData) {
    state.recoveryPaymentData = recoveryPaymentData
  },
  set_inquiryKomiteFraud(state, inquiryKomiteFraud) {
    state.inquiryKomiteFraud = inquiryKomiteFraud
  },
  komiteFraudData(state, komiteFraudData) {
    state.komiteFraudData = komiteFraudData
  },
  

  setSubmitCheck(state, user){
    state.investigasiCheckList.push(user)
  },

  setSubmitReview(state,user){
    state.reviewFraudList.push(user)
  },

  setRevise(state, user){
    state.investigasiCheckList.push(user)
  },

  set_inquiryInvestigasiCheck(state, inquiryInvestigasiCheck){
    state.inquiryInvestigasiCheck = inquiryInvestigasiCheck
  },

 

  set_reviewFraud(state, inquiryReviewFraud){
    state.inquiryReviewFraud = inquiryReviewFraud
  },


  // setUserById(state, userById) {
  //   state.userById = userById
  // },

  setStopSellingData(state, user) {
    state.stopSellingUserList.push(user)
  },
  // set_users(state, stopSellingUserList) {
  //   state.stopSellingUserList = stopSellingUserList
  // },
  setStopSellingById(state, stopSellingById) {
    state.stopSellingById = stopSellingById
    // const index = state.stopSellingUserList.findIndex(t => t.id === updateUserStopSelling.id)
    // if (index !== -1) {
    //   state.stopSellingUserList.splice(index, 1, updateUserStopSelling)
    // }
  },
  set_agents(state, agentTypes) {
    state.agentTypes = agentTypes
  },
  setListJob(state, listJobList) {
    state.listJobList = listJobList
  },
  setInquiryFeatureAccess(state, inquiryFeatureAccess) {
    state.inquiryFeatureAccess = inquiryFeatureAccess
  },
  setFeatureAccessById(state, inquiryFeatureAccessList) {
    state.inquiryFeatureAccessList = inquiryFeatureAccessList
  },
  setRegisteredUser(state, registeredUserList) {
    state.registeredUserList = registeredUserList
  },
  // setInquiryStopSelling (state, inquiryStopSellingList) {
  //   state.inquiryStopSellingList = inquiryStopSellingList
  // },
  setUserTypeLogin(state, userTypeListLogin) {
    state.userTypeListLogin = userTypeListLogin
  },
  setUserType(state, userTypeList) {
    state.userTypeList = userTypeList
  },
  setFeatureAccess(state, featureAccessList) {
    state.featureAccessList = featureAccessList
  },
  setFeature(state, featureList) {
    state.featureList = featureList
  },
  setFeatures(state, { features }) {
    state.featuresWebAdmin = features
  },
  setUserProfile(state, userProfileList) {
    state.userProfileList = userProfileList
  },
  setBranchList(state, { branchList }) {
    state.listBranch = branchList
    if (branchList.length <= 1) {
      state.kodeCabang = branchList[0].branchCode
    }
  },
  setReportSalesByBranch(state, reportSalesByBranchList) {
    state.reportSalesByBranchList = reportSalesByBranchList
  },
  setJob(state, jobList) {
    state.jobList = jobList
  },

  
  // downloadSalesReportSuccess(state) {
  //   state.downloadSalesReportSuccess = 'success'
  // },
  // downloadCommissionSuccess(state) {
  //   state.downloadCommissionSuccess = 'success'
  // },
  newFeatureAccess(state, featureAccess) {
    // state.featureAccessList.unshift(featureAccess)
    state.featureAccess = featureAccess
  },
  // deleteFeatureAccess(state, featureAccess) {
  //   state.featureAccessList = state.featureAccessList.filter(t => featureAccess.id !== t.id)
  // },
  updFeatureAccess: (state, updatedFeatureAccess) => {
    const index = state.featureAccessList.findIndex(t => t.id === updatedFeatureAccess.id);
    if (index !== -1) {
      state.featureAccessList.splice(index, 1, updatedFeatureAccess);
    }
  },
  auth_request(state) {
    state.authStatus = 'loading'
  },
  auth_success(state, { fullName, token, username, userType, refreshTokenAdmin, tokenExpDate, refreshTokenExpDate, dataUser, userMenu }) {
    state.userMenu = userMenu
    state.dataUser = []
    state.authStatus = 'success'
    state.token = token
    state.fullName = fullName
    state.username = username
    state.userType = userType
    state.refreshTokenAdmin = refreshTokenAdmin
    state.tokenExpDate = tokenExpDate
    state.refreshTokenExpDate = refreshTokenExpDate

    if(dataUser.userAccessList) {
      dataUser.userAccessList.map(menu => {
        if(menu.menuCode != null) {
          state.dataUser.push({
            "menuName" : menu.menuCode
          })
        }
        if(menu.userAccessList) {
          menu.userAccessList.map(menuSub => {
            if(menuSub.menuCode != null) {
              state.dataUser.push({
                "menuName" : menuSub.menuCode
              })
            }
          })
        }
      })
    }

    const allowedFeature = state.dataUser.filter(feature => {
      return feature.menuName == 'DASHBOARD'
    })
    allowedFeature.length > 0 ? state.haveDashboard = true : state.haveDashboard = false
    
    // state.dataUser = dataUser.userAccessList

    //enhance
    state.roleCodeFromLogin = dataUser.roleCode
    // state.roleCodeFromLogin = []
  },
  auth_error(state) {
    state.authStatus = 'error'
  },
  logoutError(state) {
    state.logoutStatus = 'error'
  },
  // logout(state) {
  //   state.authStatus = ''
  //   state.token = ''
  //   state.refreshTokenAdmin = ''
  //   state.tokenExpDate = ''
  //   state.username = ''
  //   state.userType = ''
  //   state.refreshTokenExpDate = ''

  //   state.inquirySalesKit = [],
  //     state.salesKitById = {},

  //     state.inquiryProgram = [],
  //     state.programData = {},
  //     state.initProgramData = {},
  //     delete axios.defaults.headers.common['Authorization']
  // },
  logout(state){
    state.token = ''
    state.jabatan = []
    state.jabatanSelected = []
    state.cabang = []
    state.cabangSelected = []
    state.nextPath = ''
    state.featureGroup = []
    state.username = ''
  },
  // setTableList(state, tableList) {
  //   state.tableList = tableList
  // },
  // setUserList(state, userList) {
  //   state.userList = userList
  // },
  // setUser(state, username) {
  //   state.username = username
  // },
  // setUserListCount(state, userListCount) {
  //   state.userListCount = userListCount
  // },
  // userList_error(state) {
  //   state.userListStatus = 'error'
  // },
  // userListCount_error(state) {
  //   state.userListCountStatus = 'error'
  // },
  // useradd_error(state) {
  //   state.userAddStatus = 'error'
  // },
  // useredit_error(state) {
  //   state.userEditStatus = 'error'
  // },
  // setHeaderLinks(state, headerLinks) {
  //   state.headerLinks = headerLinks
  // },
  // user_error(state) {
  //   state.userStatus = 'error'
  // },
  toggleDrawer(state) {
    state.drawer = !state.drawer;
  },
  setShowSnackbar(state, { snackbarMsg, snackbarColor }) {
    state.showSnackbar = true;
    state.snackbarMsg = snackbarMsg;
    state.snackbarColor = snackbarColor;
  },
  hideSnackBar(state) {
    state.showSnackbar = false;
  },
  resetSnackBarMsg(state) {
    state.snackbarMsg = '';
  },
  snackBarColor(state, { color }) {
    state.snackbarColor = color;
  },
  setJustLoggedIn(state, justLoggedIn) {
    state.justLoggedIn = justLoggedIn
  },
  setTitleApp(state, titleApp) {
    state.titleApp = titleApp
  },
  startLoading(state) {
    // console.log('im loading now')
    state.isLoading = true
  },
  stopLoading(state) {
    // console.log('im stop loading now')
    state.isLoading = false
  },
  changeStatusInvestigastiController(state, status) {
    state.statusInvestigasiController = status
  }
}
