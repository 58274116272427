<template>
  <v-container>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Parameter Type</h1>
    </v-row>
    <v-row class="d-flex mt-4 mb-1">
      <v-col cols="12" lg="6">
        <p class="label-text-field" color="primary">Parameter Type Name</p>
        <v-text-field label="" class="rp mb-0" outlined dense hide-details="auto" placeholder="Cari parameter type name"
          prepend-inner-icon="mdi-magnify" v-model="searchValueModel"></v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-btn @click="search" class="elevation-0 text-capitalize ml-5 mt-6" color="primary">
            <span style="color: white">Cari</span>
          </v-btn>
          <v-btn @click="reset" class="elevation-0 text-capitalize ml-3 mt-6" color="primary" outlined>
            Reset
          </v-btn>
        </v-row>
      </v-col>

      <v-col class="text-right">
        <!-- <v-btn
          width="100"
          class="elevation-0 text-capitalize ml-15 mt-6"
          color="primary"
          :to="`/parameter-type/form/add`"
        >
          <v-icon aria-hidden="false" color="white"> mdi-plus </v-icon>
          <span style="color: white">Tambah</span>
        </v-btn> -->
        <v-btn class="elevation-0 mt-6" color="primary" :to="`/parameter-type/form/add`">
          <v-icon left>
            mdi-plus
          </v-icon>
          Tambah
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pf">
          <v-card cols="12">
            <v-card-text>
              <v-row style="width: 100%">
                <v-col cols="12">
                  <v-data-table :headers="headers" :items="allInquiryParameterType" :items-per-page="5"
                    :header-props="{ sortIcon: null }" style="width: 100%" disable-sort mobile-breakpoint="0">
                    <template v-slot:item="allParamType">
                      <tr>
                        <td>{{ allParamType.item.parameterTypeCode }}</td>
                        <td>{{ allParamType.item.parameterTypeName }}</td>
                        <td align="center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small text v-on="on" v-bind="attrs" class="text-capitalize black--text px-0"
                                :to="`/parameter-type/${allParamType.item.parameterTypeCode}/form/edit`">
                                <v-icon outline color="primary">
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-dialog v-model="
                            dialogDel[allParamType.item.parameterTypeCode]
                          " persistent :key="allParamType.item.parameterTypeCode" width="unset">
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn small text v-on="{ ...dialog, ...tooltip }" v-bind="attrs">
                                    <v-icon outline color="primary">
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon class="icon" @click="
                                  dialogDel[
                                  allParamType.item.parameterTypeCode
                                  ] = false
                                " style="float: right">mdi-close</v-icon>
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Parameter?
                                  <p>
                                    {{ allParamType.item.parameterTypeName }}
                                  </p>
                                </div>
                                <v-btn class="paramDialogBtn ma-1" color="primary" text @click="
                                  $set(
                                    dialogDel,
                                    allParamType.item.parameterTypeCode,
                                    false
                                  )
                                ">Batalkan</v-btn>
                                <v-btn class="paramDialogDelBtn ma-1" color="primary" text @click="
                                  removeParameterType(
                                    allParamType.item.parameterTypeCode
                                  ),
                                  $set(
                                    dialogDel,
                                    allParamType.item.parameterTypeCode,
                                    false
                                  )
                                ">Hapus</v-btn>
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      headers: [
        {
          text: "Parameter Type Code",
          align: "center",
          width: "150px",
          class: "black-text",
        },
        {
          text: "Parameter Type Name",
          align: "center",
          width: "150px",
          class: "black-text",
        },
        {
          text: "Tindakan ",
          align: "center",
          width: "150px",
          class: "black-text",
        },
      ],

      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      searchValueModel: "",
      searchInquiry: "",
      dialogDel: {},
      dialog: false,
    };
  },

  created() {
    this.requiredFeature('PARAMETER_TYPE')
    this.changeTitleApp("Master Parameter Type");
  },

  async mounted() {
    this.load();
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getInquiryParameterType",
      "deleteParameterType",
    ]),

    reset() {
      this.searchValueModel = "";
      this.load();
    },

    load() {
      this.getInquiryParameterType({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValueModel,
      })
        .then((resp) => {
          this.totalData = resp.data.countData;
          this.countData = resp.data.countData;
          // console.log("ini memunculkan data", resp);
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryParameterType = [];
        });
    },

    handlePageChange(value) {
      this.getInquiryParameterType({
        pageNumber: value - 1,
        pageSize: this.perPage,
        searchValu: this.searchInquiry
          ? this.searchValueModel
          : this.searchValueModel,
      });
    },

    removeParameterType(id) {
      console.log("id yang mau di hapus", id);
      this.deleteParameterType(id)
        .then((resp) => {
          console.log(resp);
          this.load();
          this.$helpers.handleSuccess(resp);
        })
        .catch((err) => {
          console.log(err);
          this.$helpers.handleError(err);
        });

      // let paramtypeCd = id;
      // this.deleteParameterType({
      //   paramtypeCd,
      // })
      //   .then((resp) => {
      //     console.log(resp);
      //     this.load();
      //     this.$helpers.handleSuccess(resp);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.$helpers.handleError(err);
      //   });
    },

    search() {
      this.getInquiryParameterType({
        pageNumber: 0,
        pageSize: this.countData,
        searchValue: this.searchValueModel.trim(),
      })
        .then((res) => {
          this.totalData = res.data.data.length;
        })
        .catch(() => {
          this.$store.state.inquiryParameterType = [];
        });
    },
  },

  computed: {
    ...mapState(["paramTypeCd"]),
    allInquiryParameterType() {
      return Object.values(this.$store.getters.allInquiryParameterType);
    },
  },
};
</script>



<style src="./ParameterType.scss" lang="scss"/>