<template>
  <v-app>
    <v-form ref="formPengaduan" v-model="validFormPengaduan" lazy-validation>
      <v-container fluid>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Pengaduan Fraud</p>
          <span class="titlet"></span>
          <p class="subTitle">Buat Pengaduan</p>
        </v-row>
        <v-col cols="12">
          <div class="dp">
            <v-card>
              <v-card-text>
                <v-row class="titleCardTop">
                  <p>Data Pelapor</p>
                  <span v-if="checkAnonym">
                    Anda akan melaporkan sebagai Anonim
                  </span>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <p class="label-text-field">Nama Pelapor</p>
                    <v-text-field
                      v-model="reportBy"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder="Masukan nama Anda"
                      readonly
                    ></v-text-field>
                    <v-checkbox
                      v-model="checkAnonym"
                      label="Cek jika Anda ingin lapor sebagai Anonim"
                      class="dp"
                      style="margin-top: -18px"
                    ></v-checkbox
                  ></v-col>
                  <v-col cols="12" md="6">
                    <p class="label-text-field">NIK Pelapor</p>
                    <v-text-field
                      v-model="nik"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder="Masukan NIK"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p class="label-text-field">Jabatan Pelapor</p>
                    <v-text-field
                      v-model="position"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder="Masukan Jabatan Anda"
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field> </v-col
                  ><v-col cols="12" md="6">
                    <p class="label-text-field">Email Pelapor</p>
                    <v-text-field
                      v-model="email"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder=""
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p class="label-text-field">Office Pelapor</p>
                    <v-text-field
                      v-model="office"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder=""
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field> </v-col
                  ><v-col cols="12" md="6">
                    <p class="label-text-field">Departement Pelapor</p>
                    <v-text-field
                      v-model="department"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder=""
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p class="label-text-field">Nama Atasan 1</p>
                    <v-text-field
                      v-model="superior1"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder=""
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field> </v-col
                  ><v-col cols="12" md="6">
                    <p class="label-text-field">Nama Atasan 2</p>
                    <v-text-field
                      v-model="superior2"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder=""
                      readonly
                      style="background-color: #f8f8f8"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">No. Telepon Pelapor</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-text-field
                      v-model="phoneNumber"
                      :rules="phoneRules"
                      type="number"
                      min="0"
                      class="dp mb-0"
                      hide-details="auto"
                      outlined
                      color="#A8B5CB"
                      dense
                      placeholder="Contoh: 08123456789"
                      style="background: #ffffff"
                      @keypress="numOnly"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="dl">
            <v-card>
              <v-card-text>
                <p class="titleCardMiddle">Detail Laporan</p>
                <v-row>
                  <v-col cols="12">
                    <p class="label-text-field">Tanggal Pelaporan</p>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="inputStartDate"
                          label=""
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          class="dl mb-0"
                          hide-details="auto"
                          outlined
                          color="#f8f8f8"
                          dense
                          placeholder=""
                          readonly
                          :rules="tanggalPelaporanRules"
                          disabled
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="currentDate"
                        v-model="inputStartDateVal"
                        no-title
                        scrollable
                        @input="formatStartDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Jenis Fraud</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <div class="jf mt-2">
                      <v-btn-toggle id="btnGroup" active-class="active">
                        <v-btn
                          v-for="item in tipeFraud"
                          :key="item.title"
                          x-large
                          depressed
                          outlined
                          color="grey"
                          @click="clickTipe(item.title)"
                          style="
                            width: 112px;
                            height: 48px;
                            border: 1px solid #f5821f;
                            box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                            border-radius: 8px;
                          "
                          >{{ item.title }}</v-btn
                        >
                      </v-btn-toggle>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field">
                        Sumber Informasi Awal Temuan Fraud
                      </p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-select
                      :items="infoFraud"
                      item-text="paramName"
                      item-value="paramCd"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Sumber Infomasi Awal Temuan Fraud harus diisi',
                      ]"
                      label=""
                      class="dl mb-0"
                      hide-details="auto"
                      v-model="fraudInfo"
                      outlined
                      dense
                      clearable
                      placeholder="Pilih sumber informasi awal temuan fraud"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <p
                      v-if="fraudInfo == 'FRAUD_INF_OTHER'"
                      class="label-text-field"
                    >
                      Lainnya
                    </p>
                    <v-text-field
                      v-if="fraudInfo == 'FRAUD_INF_OTHER'"
                      v-model="fraudInfoOther"
                      outlined
                      dense
                      placeholder="Masukan sumber informasi awal temuan fraud"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <p class="label-text-field">Yang Terlibat</p>
                    <v-row style="width: 100%">
                      <v-col cols="12">
                        <v-data-table
                          hide-default-footer
                          :headers="headersSuspect"
                          :items="fraudSuspect"
                          :items-per-page="10"
                          :header-props="{ sortIcon: null }"
                          disable-sort
                          mobile-breakpoint="0"
                          ><template v-slot:item="itemFraud">
                            <tr>
                              <!-- <td>{{ itemFraud.item.seqNo + 1 }}</td> -->
                              <td align="center">{{ itemFraud.index + 1 }}</td>
                              <td align="center">
                                <v-dialog
                                  v-model="dialogEmployee[itemFraud.index]"
                                  :key="itemFraud.Index"
                                  max-width="780"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="itemFraud.item.pelakuNik"
                                      v-bind="attrs"
                                      v-on="on"
                                      hide-details="auto"
                                      outlined
                                      dense
                                      class="ma-0"
                                      style="width: 100px; border-radius: 8px"
                                    >
                                    </v-text-field>
                                  </template>
                                  <v-card>
                                    <v-card-text>
                                      <v-icon
                                        class="icon"
                                        @click="
                                          dialogEmployee[
                                            itemFraud.index
                                          ] = false
                                        "
                                        style="float: right; margin-top: 12px"
                                        >mdi-close</v-icon
                                      >
                                    </v-card-text>
                                    <v-card-title class="black--text">
                                      Yang Terlibat
                                    </v-card-title>
                                    <v-card-text class="mt-5">
                                      <span class="black--text"
                                        >NIK atau Nama</span
                                      >
                                      <v-row>
                                        <v-text-field
                                          label=""
                                          class="mb-0 ml-3"
                                          hide-details="auto"
                                          v-model="searchValueEmployee"
                                          dense
                                          outlined
                                          placeholder="12345678"
                                          prepend-inner-icon="mdi-magnify"
                                          @click="loadEmployee"
                                        ></v-text-field>
                                        <v-btn
                                          @click="searchEmployee"
                                          class="
                                            elevation-0
                                            text-capitalize
                                            ml-5
                                          "
                                          color="primary"
                                        >
                                          <span class="white--text">Cari</span>
                                        </v-btn>
                                      </v-row>
                                      <v-data-table
                                        class="mt-5 mb-5"
                                        :headers="headersEmployee"
                                        :items="allEmployee"
                                        :items-per-page="5"
                                        :header-props="{ sortIcon: null }"
                                        disable-sort
                                        ><template v-slot:item="data">
                                          <tr>
                                            <td align="center">
                                              {{ data.item.nik }}
                                            </td>
                                            <td align="center">
                                              {{ data.item.name }}
                                            </td>
                                            <td align="center">
                                              {{ data.item.position }}
                                            </td>
                                            <td align="center">
                                              {{ data.item.status }}
                                            </td>
                                            <!-- <td align="center">
                                          {{ data.item.location }}
                                        </td> -->
                                            <td align="center">
                                              <v-btn
                                                small
                                                text
                                                class="
                                                  text-capitalize
                                                  black--text
                                                  px-0
                                                "
                                                @click="
                                                  pilihFraudPelaku(
                                                    data.item,
                                                    itemFraud.item,
                                                    itemFraud.index
                                                  )
                                                "
                                              >
                                                <span class="orange--text"
                                                  >Pilih</span
                                                >
                                              </v-btn>
                                            </td>
                                          </tr>
                                        </template>
                                      </v-data-table>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                  </v-card>
                                </v-dialog>
                              </td>
                              <!-- v-model="itemFraud.item.pelakuUtama" -->
                              <td align="center">
                                <v-switch
                                  v-model="itemFraud.item.pelakuUtama"
                                  :label="
                                    itemFraud.item.pelakuUtama == 'Y'
                                      ? 'Ya'
                                      : 'Tidak'
                                  "
                                  true-value="Y"
                                  false-value="N"
                                  inset
                                  color="orange darken-3"
                                  hide-details
                                >
                                </v-switch>
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuName }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuPosition }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuStatus }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuLocName }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuAssignCategory }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuJointDate }}
                              </td>
                              <td align="center">
                                {{ itemFraud.item.pelakuLastEdu }}
                              </td>
                              <td align="center">
                                <v-tooltip bottom slot="activator">
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                      small
                                      text
                                      class="text-capitalize black--text px-0"
                                      v-on="{ ...dialog, ...tooltip }"
                                      v-bind="attrs"
                                      @click="
                                        removeFraudPelaku(itemFraud.index)
                                      "
                                      :disabled="fraudSuspect.length == 1"
                                    >
                                      <v-icon
                                        aria-hidden="false"
                                        style="font-size: 18px"
                                        color="#F5821F"
                                      >
                                        mdi-delete-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="tambah">
                      <v-btn
                        v-model="employee"
                        class="mb-0"
                        hide-details="auto"
                        dense
                        placeholder="Tambah"
                        prepend-inner-icon="mdi-plus-box"
                        @click="addFraudSuspect"
                      >
                        <v-icon aria-hidden="false" color="orange">
                          mdi-plus-box-outline </v-icon
                        ><span class="orange--text ml-2">Tambah</span></v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field">
                        Lokasi Kerja Terjadinya Fraud
                      </p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-dialog v-model="dialogBranch" max-width="650px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="locationCd"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Lokasi Kerja Terjadinya Fraud harus diisi',
                          ]"
                          class="dl mb-0"
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder="Cari lokasi kerja terjadinya fraud"
                          prepend-inner-icon="mdi-magnify"
                          @click="loadBranch"
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-icon
                            class="icon"
                            @click="dialogBranch = false"
                            style="float: right; margin-top: 12px"
                            >mdi-close</v-icon
                          >
                        </v-card-text>
                        <v-card-title class="black--text">
                          Kode Lokasi Terjadinya Fraud
                        </v-card-title>
                        <v-card-text class="mt-5">
                          <span class="black--text"
                            >Kode Cabang atau Nama Cabang</span
                          >
                          <v-row>
                            <v-text-field
                              label=""
                              class="mb-0 ml-3"
                              hide-details="auto"
                              v-model="searchValueBranch"
                              dense
                              outlined
                              placeholder="Cari kode cabang atau nama cabang"
                              prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                            <v-btn
                              @click="searchBranch"
                              class="elevation-0 text-capitalize ml-5"
                              color="primary"
                            >
                              <span class="white--text">Cari</span>
                            </v-btn>
                          </v-row>
                          <v-data-table
                            class="mt-5 mb-5"
                            :headers="headersLocation"
                            :items="allBranch"
                            :items-per-page="5"
                            :header-props="{ sortIcon: null }"
                            disable-sort
                            ><template v-slot:item="data">
                              <tr>
                                <td align="center">
                                  {{ data.item.branchType }}
                                </td>
                                <td align="center">
                                  {{ data.item.branchCode }}
                                </td>
                                <td align="center">
                                  {{ data.item.branchName }}
                                </td>
                                <td align="center">
                                  <v-btn
                                    small
                                    text
                                    class="text-capitalize black--text px-0"
                                    @click="pilihBranch(data.item)"
                                  >
                                    <span class="orange--text">Pilih</span>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-divider></v-divider>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field">Kerugian Awal</p>
                      <span v-if="!checkAnonym" class="ml-2 mt-1">Wajib</span>
                    </v-row>
                    <vuetify-money
                      v-if="checkAnonym"
                      v-model="initialLoss"
                      prefix="Rp"
                      class="mb-0"
                      label=""
                      placeholder="contoh 15000"
                      outlined
                      dense
                      v-bind:options="options"
                    />
                    <vuetify-money
                      v-else
                      v-model="initialLoss"
                      :rules="[(v) => !!v || ' Kerugian Awal harus diisi']"
                      prefix="Rp"
                      class="mb-0"
                      label=""
                      placeholder="contoh 15000"
                      outlined
                      dense
                      v-bind:options="options"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0" style="margin-top: -24px">
                      <p class="label-text-field ml-0">Modus Operandi</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-row style="width: 100%">
                      <v-col cols="12">
                        <v-data-table
                          hide-default-footer
                          :headers="headersModus"
                          :items="fraudMethod"
                          :items-per-page="10"
                          :header-props="{ sortIcon: null }"
                          disable-sort
                          mobile-breakpoint="0"
                          ><template v-slot:item="allModus">
                            <tr>
                              <td align="center">{{ allModus.index + 1 }}</td>
                              <td align="center">
                                <v-select
                                  :items="methodFraud"
                                  item-text="paramName"
                                  item-value="paramCd"
                                  v-model="allModus.item.fraudMthd"
                                  label=""
                                  class="mt-3 mb-3"
                                  hide-details="auto"
                                  outlined
                                  dense
                                  clearable
                                  placeholder=""
                                  @input="
                                    fraudMethodInput(
                                      allModus.item.fraudMthd,
                                      allModus.index
                                    )
                                  "
                                  style="border-radius: 8px"
                                ></v-select>
                              </td>
                              <td align="center">
                                <v-select
                                  :items="kronologis[allModus.index]"
                                  item-text="paramName"
                                  item-value="paramCd"
                                  v-model="allModus.item.incident"
                                  label=""
                                  class="mt-3 mb-3"
                                  hide-details="auto"
                                  outlined
                                  dense
                                  clearable
                                  placeholder=""
                                  style="border-radius: 8px"
                                ></v-select>
                              </td>
                              <td align="center">
                                <v-text-field
                                  v-if="
                                    allModus.item.fraudMthd ==
                                      'FRAUD_MTHD_OTHER' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_ANGSURAN_2' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_BRW_2' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_FIKTIF_8' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_FPB_4' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_KAS_SOL_2' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_LEMARI_BESI_5' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_PELUNASAN_2' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_PENCURIAN_EXT_1' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_PENCURIAN_INT_1' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_SEBAGIAN_PENCAIRAN_4' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_SELURUH_PENCAIRAN_5' ||
                                    allModus.item.incident ==
                                      'FRAUD_MTHD_TABUNGAN_4'
                                  "
                                  class="mb-0"
                                  v-model="allModus.item.incidentDesc"
                                  label=""
                                  outlined
                                  dense
                                  hide-details="auto"
                                  clearable
                                  placeholder="Silahkan isi deskripsi"
                                  style="border-radius: 8px"
                                  @keypress="noChar"
                      @paste="pasteChar"
                                >
                                </v-text-field>
                                <v-text-field
                                  v-else
                                  class="mt-0 mb-0"
                                  hide-details="auto"
                                  outlined
                                  dense
                                  readonly
                                  style="border-radius: 8px"
                                ></v-text-field>
                              </td>
                              <td align="center">
                                <v-tooltip bottom slot="activator">
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                      small
                                      text
                                      class="text-capitalize black--text px-0"
                                      v-on="{ ...dialog, ...tooltip }"
                                      v-bind="attrs"
                                      @click="removeFraudMethod(allModus.index)"
                                      :disabled="fraudMethod.length == 1"
                                    >
                                      <v-icon
                                        aria-hidden="false"
                                        style="font-size: 18px"
                                        color="#F5821F"
                                      >
                                        mdi-delete-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <div class="tambah">
                      <v-btn
                        v-model="modusOperandi"
                        class="mb-0"
                        hide-details="auto"
                        dense
                        placeholder="Tambah"
                        prepend-inner-icon="mdi-plus-box"
                        @click="addFraudMethod"
                      >
                        <v-icon aria-hidden="false" color="orange">
                          mdi-plus-box-outline </v-icon
                        ><span class="orange--text ml-2">Tambah</span>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Attachment</p>
                      <span class="ml-2 mt-1"> Wajib </span>
                    </v-row>
                    <v-row class="verifStat ml-0">
                      <v-col>
                        <p class="label-text-field ml-0" style="color: #a8b5cb">
                          Format file JPG/PNG/PDF/DOCX (Bukti minimal 2 file
                          dengan masing-masing maksimum 2MB per file)
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-div
                        v-for="(item, index) in fraudAttachment"
                        :key="index"
                      >
                        <div class="dropbox ml-1">
                          <input
                            :rules="uploadRules"
                            type="file"
                            :name="uploadFieldName"
                            @change="uploadFile($event, index)"
                            accept="image/*, application/pdf, application/msword"
                            class="input-file"
                            ref="file"
                          />
                          <v-div class="d-flex">
                            <v-icon
                              v-if="!item.attachFilePath"
                              aria-hidden="false"
                              color="grey lighten-3"
                              class="mt-8 ml-13"
                              size="65px"
                            >
                              mdi-image-plus
                            </v-icon>
                            <v-btn
                              v-if="!item.attachFilePath"
                              x-small
                              outlined
                              color="white"
                              @click="removeFile(index)"
                              class="mt-1"
                              style="margin-left: 20px"
                            >
                              <v-icon aria-hidden="false" color="orange">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-div>

                          <v-div class="jpg d-flex">
                            <v-row>
                              <img
                                v-if="
                                  ekstension[index] == '.jpg' ||
                                  ekstension[index] == '.JPG'
                                "
                                :src="item.attachFileImage"
                                alt=""
                                class="preview mt-8 ml-4"
                              />

                              <v-btn
                                v-if="
                                  ekstension[index] == '.jpg' ||
                                  ekstension[index] == '.JPG'
                                "
                                x-small
                                outlined
                                color="white"
                                @click="removeFile(index)"
                                class="mt-1 mr-1"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-div>

                          <v-div class="png d-flex">
                            <v-row>
                              <img
                                v-if="
                                  ekstension[index] == '.png' ||
                                  ekstension[index] == '.PNG'
                                "
                                :src="item.attachFileImage"
                                alt=""
                                class="preview mt-8 ml-4"
                              />
                              <v-btn
                                v-if="
                                  ekstension[index] == '.png' ||
                                  ekstension[index] == '.PNG'
                                "
                                x-small
                                outlined
                                color="white"
                                @click="removeFile(index)"
                                class="mt-1 mr-1"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-div>

                          <v-div class="pdf d-flex">
                            <v-row>
                              <img
                                v-if="
                                  ekstension[index] == '.pdf' ||
                                  ekstension[index] == '.PDF'
                                "
                                src="@/assets/Pdf.svg"
                                alt=""
                                class="preview mt-8 ml-4"
                              />

                              <v-btn
                                v-if="
                                  ekstension[index] == '.pdf' ||
                                  ekstension[index] == '.PDF'
                                "
                                x-small
                                outlined
                                color="white"
                                @click="removeFile(index)"
                                class="mt-1 mr-1"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-div>

                          <v-div class="docx d-flex">
                            <v-row>
                              <img
                                v-if="
                                  ekstension[index] == '.docx' ||
                                  ekstension[index] == '.DOCX'
                                "
                                src="@/assets/docx.svg"
                                alt=""
                                class="preview mt-8 ml-4"
                              />

                              <v-btn
                                v-if="
                                  ekstension[index] == '.docx' ||
                                  ekstension[index] == '.DOCX'
                                "
                                x-small
                                outlined
                                color="white"
                                @click="removeFile(index)"
                                class="mt-1 mr-1"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-row>
                          </v-div>

                          <p
                            v-if="!item.attachFileName"
                            class="grey--text mt-4 ml-8"
                          >
                            Upload File {{ index + 1 }}
                            <v-progress-linear
                              v-if="isLoadingPhoto[index]"
                              indeterminate
                            ></v-progress-linear>
                          </p>
                          <p
                            v-if="item.attachFileName"
                            class="ml-6"
                            style="text-align: initial"
                          >
                            {{ item.attachFileName }}
                          </p>
                          <p v-if="item.attachFileName" class="size ml-6">
                            {{ attachFileSize[index] }} mb
                          </p>
                        </div>
                      </v-div>

                      <div class="dropbox">
                        <v-btn
                          x-large
                          outlined
                          color="white"
                          @click="addFraudAttachment"
                          class="mt-16"
                        >
                          <v-col>
                            <v-icon
                              aria-hidden="false"
                              color="orange"
                              class="mt-5 mb-5 ml-4"
                              size="35px"
                            >
                              mdi-plus
                            </v-icon>
                            <v-row>
                              <span class="orange--text ml-4">Tambah File</span>
                            </v-row>
                          </v-col>
                        </v-btn>
                      </div>
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="12" v-if="!checkAnonym">
                    <v-row class="verifStat ml-0">
                      <p class="label-text-field ml-0">Link Sharepoint</p>
                      <span class="ml-2 mt-1">(Optional)</span>
                    </v-row>
                    <v-text-field
                      v-model="sharePoint"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Masukan link share point"
                      @keypress="noCharLink"
                      @paste="pasteCharLink"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-dialog v-model="dialogCancel" width="unset">
                      <template v-slot:activator="{ on: on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          depressed
                          outlined
                          color="primary"
                          x-large
                          class="elevation-0 text-capitalize mr-3 primary--text"
                        >
                          <span>Cancel</span></v-btn
                        >
                      </template>
                      <div class="text-center">
                        <v-sheet
                          style="
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 32px;
                            position: relative;
                            width: 349px;
                            height: 354px;
                            background: #ffffff;
                            box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                            border-radius: 12px;
                          "
                        >
                          <!-- <v-icon
                            class="icon"
                            @click="dialogCancel = false"
                            style="float: right"
                            >mdi-close</v-icon
                          > -->
                          <img
                            src="@/assets/Warning.svg"
                            alt=""
                            style="
                              position: static;
                              left: 0%;
                              right: 0%;
                              top: 0%;
                              bottom: 0%;
                            "
                          />
                          <div
                            style="
                              position: static;
                              width: 285px;
                              height: 78px;
                              left: calc(50% - 285px / 2);
                              top: 164px;
                              @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                              font-style: normal;
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 26px;
                              text-align: center;
                              color: #2e3a59;
                              flex: none;
                              order: 1;
                              align-self: stretch;
                              flex-grow: 1;
                              margin: 32px 0px;
                            "
                          >
                            Apakah Anda yakin akan membatalkan pengaduan ?
                          </div>
                          <v-row></v-row>
                          <v-btn
                            class="ma-1"
                            color="primary"
                            text
                            @click="$set / (dialogCancel = false)"
                            style="
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                              padding: 0px;
                              width: 134px;
                              height: 48px;
                              background: #ffffff;
                              border: 1px solid #f5821f;
                              border-radius: 8px;
                              flex: none;
                              order: 1;
                              flex-grow: 0;
                              margin: 0px 16px;
                            "
                          >
                            <span
                              style="
                                position: static;
                                width: 38px;
                                height: 24px;
                                left: 48px;
                                top: 12px;
                                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 24px;
                                color: #f5821f;
                                flex: none;
                                order: 0;
                                flex-grow: 0;
                                margin: 10px 0px;
                              "
                            >
                              Tidak
                            </span>
                          </v-btn>
                          <v-btn
                            class="ma-1"
                            color="primary"
                            text
                            @click="$set((dialogCancel = true))"
                            :to="`/pengaduan-fraud`"
                            style="
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                              padding: 0px;
                              width: 134px;
                              height: 48px;
                              background: #f5821f;
                              border-radius: 8px;
                              flex: none;
                              order: 1;
                              flex-grow: 0;
                              margin: 0px 16px;
                            "
                          >
                            <span
                              style="
                                position: static;
                                width: 17px;
                                height: 22px;
                                left: 58.5px;
                                top: 13px;
                                @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 22px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: #ffffff;
                                flex: none;
                                order: 0;
                                flex-grow: 0;
                                margin: 10px 0px;
                              "
                            >
                              Ya
                            </span>
                          </v-btn>
                        </v-sheet>
                      </div>
                    </v-dialog>
                    <v-btn
                      v-if="isEdit"
                      class="elevation-0 text-capitalize"
                      color="primary"
                      @click="editFraud"
                      ><span class="white--text">Submit</span></v-btn
                    >
                    <v-btn
                      v-else
                      class="elevation-0 text-capitalize mr-3"
                      color="primary"
                      @click="addFraud"
                      x-large
                      ><span class="white--text">Submit</span></v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-dialog v-model="successDialog" persistent width="unset">
          <v-card>
            <div class="text-center">
              <v-sheet
                style="
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 32px;
                  position: relative;
                  width: 349px;
                  height: 354px;
                  background: #ffffff;
                  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                  border-radius: 12px;
                "
              >
                <img
                  src="@/assets/Checklist.svg"
                  alt=""
                  style="
                    position: static;
                    left: 0%;
                    right: 0%;
                    top: 0%;
                    bottom: 0%;
                  "
                />
                <div
                  style="
                    position: static;
                    width: 285px;
                    height: 78px;
                    left: calc(50% - 285px / 2);
                    top: 164px;
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: #2e3a59;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 1;
                    margin: 32px 0px;
                  "
                >
                  Terimakasih telah menggunakan Aplikasi JAGA Online data Anda
                  akan segera kami proses.
                </div>
                <v-row></v-row>
                <v-btn
                  class="ma-1"
                  text
                  @click="$set / (successDialog = false)"
                  to="/pengaduan-fraud"
                  style="
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    width: 285px;
                    height: 48px;
                    background: #f5821f;
                    border-radius: 8px;
                    flex: none;
                    order: 2;
                    flex-grow: 0;
                    margin: 32px 0px;
                  "
                >
                  <span
                    style="
                      position: static;
                      width: 21px;
                      height: 22px;
                      left: 132px;
                      top: 13px;
                      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 22px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      color: #ffffff;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                      margin: 10px 0px;
                    "
                  >
                    OK
                  </span>
                </v-btn>
              </v-sheet>
            </div>
          </v-card>
        </v-dialog>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
// import axios from "axios"; // Untuk cancel API Calling

var dateFormat = require("dateformat");

export default {
  data() {
    return {
      isLoadingPhoto: [false, false],
      successDialog: false,
      validFormPengaduan: false,
      reportBy: "",
      checkAnonym: false,
      nik: "",
      position: "",
      email: "",
      office: "",
      department: "",
      superior1: "",
      superior2: "",
      phoneNumber: "",

      menu1: false,
      inputStartDate: moment().format("DD-MMM-YYYY"),
      inputStartDateVal: null,
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      fraudType: "",
      fraudInfo: "",
      fraudInfoOther: "",
      locationCd: "",
      branchCd: "",
      initialLoss: "",
      sharePoint: "",

      employee: "",

      statusSearch: false,
      searchValueBranch: "",
      searchValueEmployee: "",

      incidentOther: [],

      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,

      dialog: false,
      dialogBranch: false,
      dialogEmployee: {},
      dialogCancel: false,

      base64: "",
      imageData: "",

      fraudSuspect: [
        {
          kfPasal: "",
          kfSanksi: "",
          pelakuJointDate: "",
          pelakuLastEdu: "",
          pelakuLocName: "",
          //pelakuLocCode: "",
          pelakuName: "",
          pelakuNik: "",
          pelakuPosition: "",
          pelakuStatus: "",
          pelakuStatusName: "",
          pelakuUtama: "N",
          realisasiSanksiDate: "",
          rekomendasiPasal: "",
          rekomendasiSanksi: "",
        },
      ],
      fraudMethod: [
        {
          fraudMthd: "",
          fraudMthdName: "",
          incident: "",
          incidentDesc: "",
          incidentName: "",
        },
      ],
      fraudAttachment: [
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Pengaduan Fraud",
          attachFileImage: "",
        },
        {
          attachFileName: "",
          attachFilePath: "",
          flowName: "Pengaduan Fraud",
          attachFileImage: "",
        },
      ],

      kronologis: [],
      infoFraud: [],
      methodFraud: [],
      allBranch: [],
      allEmployee: [],
      tipeFraud: [
        { id: "1", title: "Internal" },
        { id: "2", title: "Eksternal" },
      ],
      ekstension: [],
      attachFileSize: [],
      uploadFraud: [],

      file: "",
      uploadPercentage: 0,

      phoneRules: [
        (v) => !!v || "No Telepon Pelapor harus diisi",
        (v) =>
          (v && v.length <= 24) || "No Telepon tidak boleh lebih dari 24 angka",
        // v => Number.isInteger(Number(v)) || "The value must be an integer number",
      ],

      tanggalPelaporanRules: [(v) => !!v || "Tanggal Pelaporan harus diisi"],

      // sharePointRules: [
      //   (v) =>
      //     (v && v.length <= 128) ||
      //     "Link Sharepoint tidak boleh lebih dari 128 karakter",
      // ],

      uploadRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],

      items: [],
      headersSuspect: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "130px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          width: "118px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          width: "180px",
        },
        {
          text: "Status Kepagawaian Pelaku",
          align: "center",
          width: "170px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          width: "170px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          width: "170px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          width: "126px",
        },
        {
          text: "Pendidikan",
          align: "center",
          width: "126px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "116px",
        },
      ],
      headersLocation: [
        {
          text: "Tipe Cabang",
          align: "center",
          width: "86px",
        },
        {
          text: "Kode Cabang",
          align: "center",
          width: "90px",
        },
        {
          text: "Nama Cabang",
          align: "center",
          width: "95px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "62px",
        },
      ],
      headersEmployee: [
        {
          text: "NIK",
          align: "center",
          width: "91px",
        },
        {
          text: "Nama",
          align: "center",
          width: "150px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "150px",
        },
        {
          text: "Status",
          align: "center",
          width: "86px",
        },
        // {
        //   text: "Lokasi Kerja",
        //   align: "center",
        //   width: "150px",
        //   class: "black--text",
        // },
        {
          text: "Tindakan",
          align: "center",
          width: "86px",
        },
      ],
      headersModus: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "280px",
        },
        {
          text: "Kronologis Kejadian",
          align: "center",
          width: "620px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "620px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "108px",
        },
      ],
      options: {
        locale: "pt-BR",
        prefix: "Rp",
        suffix: "",
        length: 14,
        precision: 0,
      },
      timeoutUpload: null,
      cancelTokenSource: null,
    };
  },

  async mounted() {
    this.loadFraudMethod();
    this.loadFraudInfo();
    this.loadDataPelapor();
    this.loadEmployee();
    this.loadBranch();
  },

  // created() {
  //   if (this.isEdit) {
  //     this.changeTitleApp("Ubah Pengaduan Fraud");
  //   } else {
  //     this.changeTitleApp("Tambah Pengaduan Fraud");
  //   }
  // },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "createFraud",
      "getParameterByParamType",
      "uploadPengaduan",
      "getDataPelapor",
      "getInquiryBranch",
      "getInquiryEmployee",
      "getInquiryLovTerlibat",
      "getDataPelaporByLogin",
      "getKodeModusPengaduanFraud"
    ]),

    async loadDataPelapor() {
      this.getDataPelaporByLogin().then((response) => {
        this.formData = response.data.data;
        this.reportBy = this.formData.name == null ? "-" : this.formData.name;
        this.nik = this.formData.nik == null ? "-" : this.formData.nik;
        this.position =
          this.formData.position == null ? "-" : this.formData.position;
        this.email = this.formData.email == null ? "-" : this.formData.email;
        this.office =
          this.formData.organization == null ? "-" : this.formData.organization;
        this.department =
          this.formData.department == null ? "-" : this.formData.department;
        this.superior1 =
          this.formData.superior1Name == null
            ? "-"
            : this.formData.superior1Name;
        this.superior2 =
          this.formData.superior2Name == null
            ? "-"
            : this.formData.superior2Name;
      });
    },

    // checkAllData() {
    //   console.log("anonym", this.checkAnonym);
    // },

    formatStartDate() {
      this.menu1 = false;
      this.inputStartDate = moment(this.inputStartDateVal).format(
        "DD-MMM-YYYY"
      );
    },

    clickTipe(item) {
      console.log("item tipe", item);

      item == "Internal"
        ? (this.fraudType = "FRAUD_TYPE_INTERNAL")
        : (this.fraudType = "FRAUD_TYPE_EKSTERNAL");
    },

    internal() {
      this.fraudType = "FRAUD_TYPE_INTERNAL";
    },
    eksternal() {
      this.fraudType = "FRAUD_TYPE_EKSTERNAL";
    },

    loadFraudInfo() {
      this.getParameterByParamType({
        paramType: "FRAUD_INF",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.infoFraud = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    loadEmployee() {
      this.getInquiryLovTerlibat({
        pageNumber: 0,
        pageSize: 200,
        searchValue: "",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.allEmployee = res.data.data
        })
        .catch((err) => {
          console.log(err);
          //this.$store.state.inquiryEmployee = [];
        });
    },

    searchEmployee() {
      this.getInquiryLovTerlibat({
        pageNumber: 0,
        pageSize: 200,
        searchValue: this.searchValueEmployee,
      })
        .then((res) => {
          this.totalData = res.data.data.length;
          this.allEmployee = res.data.data;
          if (this.searchValueEmployee == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch((err) => {
          console.log(err);
          //this.$store.state.inquiryEmployee = [];
        });
      this.page = 1;
    },

    addFraudSuspect() {
      this.fraudSuspect.push({
        kfPasal: "",
        kfSanksi: "",
        pelakuJointDate: "",
        pelakuLastEdu: "",
        pelakuLocName: "",
        pelakuName: "",
        pelakuNik: "",
        pelakuPosition: "",
        pelakuStatus: "",
        pelakuStatusName: "",
        pelakuUtama: "N",
        realisasiSanksiDate: "",
        rekomendasiPasal: "",
        rekomendasiSanksi: "",
      });
    },

    pilihFraudPelaku(item, indexTable, index) {
      console.log("dialogEmployee", this.dialogEmployee[index]);
      this.dialogEmployee[index] = false;

      indexTable.pelakuNik = item.nik;
      indexTable.pelakuName = item.name;
      indexTable.pelakuPosition = item.position;
      indexTable.pelakuStatus = item.status;
      indexTable.pelakuLocName = item.organization;
      indexTable.pelakuAssignCategory = item.assignmentCategory;
      indexTable.pelakuJointDate = item.jointDate;
      indexTable.pelakuLastEdu = item.education;
    },

    removeFraudPelaku(index) {
      this.fraudSuspect.splice(index, 1);
    },

    loadBranch() {
      this.getInquiryBranch({})
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.allBranch = res.data.data
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryBranch = [];
        });
    },

    searchBranch() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = this.searchValueBranch;
      this.$store
        .dispatch("getBranchBySearchValue", {
          pageNumber,
          pageSize,
          searchValue,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          this.allBranch = res.data.data;
          if (this.searchValueBranch == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryBranch = [];
        });
      this.page = 1;
    },

    pilihBranch(item) {
      this.dialogBranch = false;

      this.locationCd = `${item.branchCode} - ${item.branchName}`;
      console.log("itemBranch", item);

      this.branchCd = item.branchCode;
    },

    addFraudMethod() {
      this.fraudMethod.push({
        fraudMthd: "",
        fraudMthdName: "",
        incident: "",
        incidentDesc: "",
        incidentName: "",
      });
    },

    loadFraudMethod() {
      this.getKodeModusPengaduanFraud({
        paramType: "FRAUD_MTHD",
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.methodFraud = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    fraudMethodInput(item, index) {
      this.getParameterByParamType({
        paramType: item,
      })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;

          this.kronologis.splice(index, 1, res.data.data);

          this.incidentOther = res.data.data;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    removeFraudMethod(index) {
      this.fraudMethod.splice(index, 1);
      this.kronologis.splice(index, 1);
    },

    addFraudAttachment() {
      this.fraudAttachment.push({
        attachFileName: "",
        attachFilePath: "",
        flowName: "Pengaduan Fraud",
        attachFileImage: "",
      });
      console.log("fraudAtt", this.fraudAttachment);
      this.isLoadingPhoto.push(false);
    },

    uploadFile(event, index) {
      let size = event.target.files[0].size / (1024 * 1024);
      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      if (size < 2) {
        // Untuk cancel API Calling [Start]
        // this.cancelTokenSource = axios.CancelToken.source();
        // let cancelToken = this.cancelTokenSource.token;

        // this.timeoutUpload = setTimeout(() => {
        //   this.$store.commit("setShowSnackbar", {
        //     snackbarMsg: "Upload Timeout",
        //     snackbarColor: "red",
        //   });

        //   if (this.cancelTokenSource) {
        //     this.cancelTokenSource.cancel();
        //     this.cancelTokenSource = null;
        //   }
        // }, 300000);
        // // }, 10000);
        // Untuk cancel API Calling [End]

        this.isLoadingPhoto.splice(index, 1, true);
        // this.uploadPengaduan({ formData, cancelToken }) // Untuk cancel API Calling
        this.uploadPengaduan( formData )
          .then((response) => {
            this.uploadFraud = response.data.data;
            this.fraudAttachment[index].attachFilePath = this.uploadFraud.filePathName;
            this.fraudAttachment[index].attachFileName = this.uploadFraud.fileName;

            var ext = this.fraudAttachment[index].attachFileName.substring(
              this.fraudAttachment[index].attachFileName.lastIndexOf("."),
              this.fraudAttachment[index].length
            );

            this.ekstension[index] = ext;
            this.attachFileSize[index] = (
              event.target.files[0].size /
              (1024 * 1024)
            ).toFixed(2);

            var reader = new FileReader();
            reader.onload = (e) => {
              console.log("event reader", e);
              this.fraudAttachment[index].attachFileImage = e.target.result;
            };
            reader.readAsDataURL(event.target.files[0]);
          })
          .catch((err) => {
            this.isLoadingPhoto.splice(index, 1, false);
            console.log("error", err.response);
            this.$helpers.handleError(err);
          });
          // Untuk cancel API Calling [Start]
          // .finally(() => {
          //   this.cancelToken = null;
          //   clearTimeout(this.timeoutUpload);
          // });
          // Untuk cancel API Calling [End]
      } else {
        this.$store.commit("setShowSnackbar", {
          snackbarMsg: "Maksimum 2MB per file",
          snackbarColor: "red",
        });
      }
    },

    removeFile(index) {
      this.fraudAttachment.splice(index, 1);
      this.ekstension.splice(index, 1);
      this.attachFileSize.splice(index, 1);
      this.isLoadingPhoto.splice(index, 1);
      //this.attachFileImage.splice(index, 1)
    },

    addFraud() {
      console.log("isi reportDate", this.inputStartDateVal);
      if (this.$refs.formPengaduan.validate()) {
        this.fraudMethod.map((item) => {
          item.incidentDesc =
            item.incidentDesc != null
              ? item.incidentDesc.trim()
              : item.incidentDesc;
        });
        this.fraudAttachment.map((item) => {
          item.attachFileImage = "";
        });
        this.createFraud({
          reporterName:
            this.reportBy != null ? this.reportBy.trim() : this.reportBy,
          anonimFlag: this.checkAnonym,
          reporterNik: this.nik != null ? this.nik.trim() : this.nik,
          reporterPosition:
            this.position != null ? this.position.trim() : this.position,
          reporterEmail: this.email != null ? this.email.trim() : this.email,
          reporterOffice:
            this.office != null ? this.office.trim() : this.office,
          reporterDepartment:
            this.department != null ? this.department.trim() : this.department,
          reporterAtasan1Name:
            this.superior1 != null ? this.superior1.trim() : this.superior1,
          reporterAtasan2Name:
            this.superior2 != null ? this.superior2.trim() : this.superior2,
          reporterPhone:
            this.phoneNumber != null
              ? this.phoneNumber.trim()
              : this.phoneNumber,

          reportDate: this.inputStartDate
            ? moment(this.inputStartDate, "DD-MMM-YYYY").format("YYYY-MM-DD")
            : "",
          fraudType:
            this.fraudType != null ? this.fraudType.trim() : this.fraudType,
          fraudInf:
            this.fraudInfo != null ? this.fraudInfo.trim() : this.fraudInfo,
          fraudInfReason:
            this.fraudInfoOther != null
              ? this.fraudInfoOther.trim()
              : this.fraudInfoOther,
          fraudPelakuList: this.fraudSuspect,
          fraudLoc:
            this.branchCd != null ? this.branchCd.trim() : this.branchCd,
          initialLoss: this.initialLoss,
          fraudMthdList: this.fraudMethod,
          fraudAttList: this.fraudAttachment,
          sharepointLink:
            this.sharePoint != null ? this.sharePoint.trim() : this.sharePoint,
        })
          .then((response) => {
            this.successDialog = true;
            console.log("response", response);
            // this.$router.push(`/pengaduan-fraud`);
            // this.$helpers.handleSuccess(response);
          })
          .catch((err) => {
            if (!err.response) {
              this.loading = false;
            } else {
              switch (err.response.status) {
                default:
                  this.$store.commit("setShowSnackbar", {
                    snackbarMsg: err.response.data.message,
                    snackbarColor: "red",
                  });
                  this.errorMessages = err.response.data.message;
                  break;
              }
            }
          });
      }
    },
  },
  computed: {
    ...mapState(["reportById", "username", "token"]),
    allInquiryBranch() {
      return Object.values(this.$store.getters.allInquiryBranch);
    },
    allInquiryEmployee() {
      return Object.values(this.$store.getters.allInquiryEmployee);
    },
    mode() {
      return this.$route.params.mode;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    // isView: function () {
    //   return this.mode === "view";
    // },
  },
};
</script>

<style src="./PengaduanFraud.scss" lang="scss" />
