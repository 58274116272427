<template>
  <v-app class="pa-6">
    <Header />
    <Sidebar v-if="renderComponent" />
    <v-main class="content">
      <router-view />
      <!-- <Footer /> -->
    </v-main>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor">
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn text small v-bind="attrs" @click="showSnackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :absolute="absolute" :value="isLoading">
      <v-progress-circular indeterminate color="primary"> </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header2";
import Sidebar from "@/components/Sidebar/Sidebar2";
// import Footer from "@/components/Footer/Footer";
import "./Layout2.scss";
import { mapActions } from "vuex";

export default {
  name: "Layout",
  data() {
    return {
      absolute: true,
      renderComponent: true,
    };
  },
  components: { Header, Sidebar },
  mounted() {
    if (Object.keys(this.$store.state.featuresWebAdmin).length > 0) {
     // this.getFeatures();

      //load profile
     /* this.$store
        .dispatch("getUserProfile")
        .then((response) => {
          return this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        }); */
    } else {
      // console.log("have not features");
    }
  },
  methods: {
    ...mapActions(["getFeatures"]),
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
  },
  computed: {
    showSnackbar: {
      get: function() {
        return this.$store.getters.showSnackbar;
      },
      set: function() {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function() {
        return this.$store.getters.snackbarMsg;
      },
      set: function() {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function() {
        return this.$store.getters.snackbarColor;
      },
      set: function() {
        this.$store.commit("resetSnackBarColor");
      },
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style src="./Layout2.scss" lang="scss" />
