<template>
  <v-app>
    <v-form ref="formVerifikasi" v-model="validFormVerifikasi" lazy-validation>
      <v-container fluid>
        <v-dialog v-model="successDialog" persistent width="unset">
          <v-card>
            <div class="text-center">
              <v-sheet
                style="
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  padding: 32px;
                  position: relative;
                  width: 349px;
                  background: #ffffff;
                  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
                  border-radius: 12px;
                "
              >
                <img
                  src="@/assets/Checklist.svg"
                  alt=""
                  style="
                    position: static;
                    left: 0%;
                    right: 0%;
                    top: 0%;
                    bottom: 0%;
                  "
                />
                <div
                  style="
                    position: static;
                    left: calc(50% - 285px / 2);
                    top: 164px;
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: #2e3a59;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 1;
                    margin: 32px 0px;
                  "
                >
                  Data Berhasil Disubmit
                </div>
                <v-btn
                  class="ma-1"
                  text
                  @click="toBack()"
                  style="
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    width: 285px;
                    height: 48px;
                    background: #f5821f;
                    border-radius: 8px;
                    flex: none;
                    order: 2;
                    flex-grow: 0;
                  "
                >
                  <span
                    style="
                      position: static;
                      width: 21px;
                      height: 22px;
                      left: 132px;
                      top: 13px;
                      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;600&display=swap');
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 22px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      color: #ffffff;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                      margin: 10px 0px;
                    "
                  >
                    OK
                  </span>
                </v-btn>
              </v-sheet>
            </div>
          </v-card>
        </v-dialog>
        <v-row no-gutters class="header d-flex mt-4 mb-1">
          <p class="titlet">Verifikasi Fraud</p>
          <span class="titlet"></span>
          <p class="subTitle">Proses</p>
        </v-row>
        <v-col>
          <v-card rounded="lg">
            <template>
              <v-tabs>
                <v-tab v-if="reportType != 'REPORT_TYPE_ANONIM'" href="#tab-dataPelapor">Data Pelapor</v-tab>
                <v-tab href="#tab-detailLaporan" @click="handleDetailLaporan">Detail Laporan</v-tab>
                <!-- <v-tab href="#tab-verifikasiFraud" v-if="isEdit">Verifikasi fraud</v-tab> -->
                <v-tab href="#tab-verifikasiFraud" @click="handleVerifikasiFraud">Verifikasi fraud</v-tab>

                <v-tab-item v-if="reportType != 'REPORT_TYPE_ANONIM'" value="tab-dataPelapor">
                  <v-card>
                    <v-card-text>
                      <v-row class="mx-1">
                        <v-col cols="12" md="6">
                          <p class="label-text-field">Nama Pelapor</p>
                          <v-text-field
                            v-model="reportBy"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Masukkan nama Anda"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="label-text-field">NIK Pelapor</p>
                          <v-text-field
                            v-model="reportNik"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Masukan NIK"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="label-text-field">Jabatan Pelapor</p>
                          <v-text-field
                            v-model="reportDepartment"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Masukkan Jabatan Anda"
                          ></v-text-field> </v-col
                        ><v-col cols="12" md="6">
                          <p class="label-text-field">Email Pelapor</p>
                          <v-text-field
                            v-model="reportEmail"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Masukan email Anda"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="label-text-field">Office Pelapor</p>
                          <v-text-field
                            v-model="reportOffice"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder=""
                          ></v-text-field> </v-col
                        ><v-col cols="12" md="6">
                          <p class="label-text-field">Departement Pelapor</p>
                          <v-text-field
                            v-model="reportBcArea"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="label-text-field">Nama Atasan 1</p>
                          <v-text-field
                            v-model="reportBcPelapor"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder=""
                          ></v-text-field> </v-col
                        ><v-col cols="12" md="6">
                          <p class="label-text-field">Nama Atasan 2</p>
                          <v-text-field
                            v-model="reportDhPelapor"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="label-text-field">No. Telepon Pelapor</p>
                          <v-text-field
                            v-model="reportNoTelp"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Contoh: 08123456789"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-col>
                        <v-row>
                          <v-col class="text-right">
                            <v-btn
                              class="
                                elevation-0
                                text-capitalize
                                mr-3
                                primary--text
                              "
                              depressed
                              outlined
                              color="primary"
                              :to="`/verifikasi-data`"
                              x-large
                            >
                              <span class="orange--text">Back</span></v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="tab-detailLaporan">
                  <v-card>
                    <v-card-text>
                      <v-row class="mx-1">
                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Tanggal Pelaporan
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="inputStartDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                outlined
                                filled
                                dense
                                hide-details="auto"
                                class="mb-0"
                                placeholder=""
                                disabled
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="currentDate"
                              v-model="inputStartDateVal"
                              no-title
                              scrollable
                              @input="formatStartDate()"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <h7 class="label-text-field" color="primary">
                            Jenis Fraud
                            <!-- <span class="red--text"> *</span> -->
                          </h7>

                          <v-col align="left" style="margin-left: -12px">
                            <v-btn
                              x-large
                              class="elevation-0 text-capitalize ml-5 primary--text"
                              outlined
                              disabled
                              color="primary"
                              cols="12"
                              style="
                                width: 112px;
                                height: 48px;
                                border: 1px solid #f5821f;
                                box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                                border-radius: 8px;
                              "
                            >
                              <span class="primary--text">{{
                                reportJenisFraud
                              }}</span>
                            </v-btn>
                          </v-col>
                        </v-col>
                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Sumber Informasi Awal Temuan Fraud
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-text-field
                            v-model="reportInfo"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="18">
                          <h7 class="label-text-field" color="primary">
                            Yang Terlibat
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-row>
                            <v-col cols="12">
                              <v-data-table
                                hide-default-footer
                                :headers="headers"
                                :items="tablesPelakuList"
                                :items-per-page="5"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                                mobile-breakpoint="0">
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Lokasi Terjadinya Fraud
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-text-field
                            v-model="reportFraudLoc"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            filled
                            readonly
                            dense
                            placeholder="Masukan kode lokasi kerja terjadinya fraud"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Kerugian Awal
                          </h7>
                          <v-text-field
                            v-model="loss"
                            prefix="Rp"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            dense
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Modus Operandi
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-row>
                            <v-col cols="12">
                              <v-data-table
                                hide-default-footer
                                :headers="headersModus"
                                :items="tablesModus"
                                :items-per-page="5"
                                :header-props="{ sortIcon: null }"
                                disable-sort
                                mobile-breakpoint="0"
                              >
                                <template v-slot:item="data">
                                  <tr>
                                    <td>
                                      {{ data.index + 1 }}
                                    </td>
                                    <td>
                                      {{ data.item.fraudMthdName }}
                                    </td>
                                    <td>
                                      {{ data.item.incidentName }}
                                    </td>
                                    <td>
                                      {{ data.item.incidentDesc }}
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <h7 class="label-text-field" color="primary">
                            Attachment
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-col
                            cols="12"
                            v-for="item in attachment"
                            :key="item"
                          >
                            <a
                              v-if="item.flowName == 'Pengaduan Fraud'"
                              @click="handleDownloadAttch(item)"
                            >
                              {{ item.attachFileName }}
                            </a>
                          </v-col>
                        </v-col>
                      </v-row>
                      <v-col>
                        <v-row>
                          <v-col class="text-right">
                            <v-btn
                              class="
                                elevation-0
                                text-capitalize
                                mr-3
                                primary--text
                              "
                              depressed
                              outlined
                              color="primary"
                              :to="`/verifikasi-data`"
                              x-large
                            >
                              <span class="orange--text">Back</span></v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="tab-verifikasiFraud">
                  <v-card cols="12" md="18">
                    <v-card-text>
                      <v-row class="mx-1">
                        <v-col>
                          <h7 class="label-text-field" color="primary">
                            Verification Status
                            <span class="grey--text ml-2">Wajib</span>
                          </h7>
                          <v-col align="left" style="margin-left: -12px">
                            <v-btn-toggle
                              v-model="verifikasiType"
                              id="btnGroup"
                              active-class="active"
                            >
                              <v-btn
                                x-large
                                :class="
                                  verifikasiType ==
                                  'VERIFICATION_STATUS_CANCELLED'
                                    ? 'elevation-0 text-capitalize ml-5 primary--text'
                                    : 'elevation-0 text-capitalize ml-5 grey--text'
                                "
                                depressed
                                outlined
                                color="grey"
                                cols="12"
                                value="VERIFICATION_STATUS_CANCELLED"
                                style="border-radius: 8px"
                              >
                                Cancelled
                              </v-btn>
                              <v-btn
                                x-large
                                :class="
                                  verifikasiType ==
                                  'VERIFICATION_STATUS_NOT_FRAUD'
                                    ? 'elevation-0 text-capitalize ml-5 primary--text'
                                    : 'elevation-0 text-capitalize ml-5 grey--text'
                                "
                                outlined
                                :color="
                                  verifikasiType ==
                                  'VERIFICATION_STATUS_NOT_FRAUD'
                                    ? 'primary'
                                    : 'grey'
                                "
                                cols="12"
                                value="VERIFICATION_STATUS_NOT_FRAUD"
                                style="
                                  width: 112px;
                                  height: 48px;
                                  border: 1px solid #f5821f;
                                  box-shadow: 0px 10px 20px
                                    rgba(235, 127, 0, 0.1);
                                  border-radius: 8px;
                                  border-radius: 8px;
                                "
                              >
                                Non-Fraud
                              </v-btn>
                              <v-btn
                                x-large
                                :class="
                                  verifikasiType == 'VERIFICATION_STATUS_FRAUD'
                                    ? 'elevation-0 text-capitalize ml-5 primary--text'
                                    : 'elevation-0 text-capitalize ml-5 grey--text border: 1px solid #f5821f'
                                "
                                depressed
                                outlined
                                :color="
                                  verifikasiType == 'VERIFICATION_STATUS_FRAUD'
                                    ? 'primary'
                                    : 'grey'
                                "
                                cols="12"
                                value="VERIFICATION_STATUS_FRAUD"
                                style="
                                  width: 112px;
                                  height: 48px;
                                  border: 1px solid #f5821f;
                                  box-shadow: 0px 10px 20px
                                    rgba(235, 127, 0, 0.1);
                                  border-radius: 8px;
                                "
                              >
                                Fraud
                              </v-btn>
                            </v-btn-toggle>
                          </v-col>
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="
                              verifikasiType ==
                                'VERIFICATION_STATUS_CANCELLED' ||
                              verifikasiType == 'VERIFICATION_STATUS_NOT_FRAUD'
                            "
                            class="label-text-field"
                            color="primary"
                          >
                            Return
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-textarea
                            v-if="
                              verifikasiType ==
                                'VERIFICATION_STATUS_CANCELLED' ||
                              verifikasiType == 'VERIFICATION_STATUS_NOT_FRAUD'
                            "
                            @keypress="noChar"
                            @paste="pasteChar"
                            v-model="returnNote"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            dense
                            placeholder=""
                            :rules="[
                              (v) => !!v || 'return harus di isi',
                              (v) =>
                                (v && v.length <= 512) ||
                                'no hp manager investigation harus  512 ',
                            ]"
                          ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="
                              verifikasiType ==
                                'VERIFICATION_STATUS_CANCELLED' ||
                              verifikasiType == 'VERIFICATION_STATUS_NOT_FRAUD'
                            "
                            class="label-text-field"
                            color="primary"
                          >
                            Tanggal Return
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-menu
                            v-if="
                              verifikasiType ==
                                'VERIFICATION_STATUS_CANCELLED' ||
                              verifikasiType == 'VERIFICATION_STATUS_NOT_FRAUD'
                            "
                            ref="menuReturn"
                            v-model="menuReturn"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-if="
                                  verifikasiType ==
                                    'VERIFICATION_STATUS_CANCELLED' ||
                                  verifikasiType ==
                                    'VERIFICATION_STATUS_NOT_FRAUD'
                                "
                                v-model="inputReturnDate"
                                label=""
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                outlined
                                dense
                                hide-details="auto"
                                class="mb-0"
                                placeholder=""
                                disabled
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="currentDate"
                              v-model="inputReturnDateVal"
                              no-title
                              scrollable
                              @input="formatReturnDate()"
                              disabled
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col>
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            Dokumen Awal Pelaporan
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <h7 v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD' && countCheckbox == 0" class="red--text">Dokumen Awal Pelaporan harus diisi</h7>
                          <v-row align="left">
                            <div
                              v-for="(document, index) in dataDokumen"
                              :key="index"
                            >
                              <v-checkbox
                                v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                                :value="document"
                                v-model="checkData"
                                :label="document.reportDocTypeName"
                                class="checkboxes"
                                @change="handleCheckbox"
                              ></v-checkbox>
                            </div>
                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            Nominal SP Pelaku
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <span
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="grey--text ml-1"
                            >Wajib</span
                          >
                          <vuetify-money
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            v-model="spNominal"
                            prefix="Rp"
                            class="mb-0"
                            label=""
                            placeholder=""
                            hide-details
                            outlined
                            dense
                            v-bind:options="options"
                            :rules="[
                              (v) => !!v || 'nominal sp pelaku harus di isi',
                            ]"
                          />
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            SP Nasabah / Pihak Ketiga
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <span
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="grey--text ml-1"
                            >Wajib</span
                          >
                          <vuetify-money
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            v-model="thirdParty"
                            prefix="Rp"
                            class="mb-0"
                            label=""
                            placeholder=""
                            hide-details
                            outlined
                            dense
                            v-bind:options="options"
                            :rules="[
                              (v) => !!v || 'nominal sp nasabah harus di isi',
                            ]"
                          />
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            Manager Investigator
                          </h7>
                          <span
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="grey--text ml-1"
                            >Wajib</span
                          >
                          <v-dialog
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            v-model="dialogActor"
                            max-width="700"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-if="
                                  verifikasiType == 'VERIFICATION_STATUS_FRAUD'
                                "
                                v-model="investigatorManager"
                                class="mb-0"
                                outlined
                                dense
                                v-on="on"
                                v-bind="attrs"
                                placeholder="Masukan Investigator Manager"
                                @click="handleManagerInvestigator"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'manager investigator harus harus di isi',
                                ]"
                              >
                              </v-text-field>
                            </template>
                            <v-card>
                              <v-card-title class="black--text">
                                Manager Investigator
                              </v-card-title>
                              <v-card-text class="mt-5">
                                <span class="black--text">NIK Atau nama</span>
                                <v-row>
                                  <v-text-field
                                    label=""
                                    class="mb-0 ml-3"
                                    hide-details="auto"
                                    v-model="searchValueActor"
                                    dense
                                    outlined
                                    placeholder="Contoh NIK: 123415 atau Contoh Nama: Test "
                                    prepend-inner-icon="mdi-magnify"
                                  >
                                  </v-text-field>
                                  <v-btn
                                    @click="loadManagerInvestigator"
                                    class="elevation-0 text-capitalize ml-5"
                                    color="primary"
                                  >
                                    <span class="white--text">Cari</span>
                                  </v-btn>
                                </v-row>
                                <v-data-table
                                  :headers="headersPelaku"
                                  :items="managerInvestigatorList"
                                  :items-per-page="5"
                                  disable-sort
                                  class="mt-5"
                                  :loading="managerInvestigatorLoad"
                                >
                                  <template v-slot:[`item.pelakuId`]="{ item }">
                                    <v-btn
                                      text
                                      class="primary--text"
                                      :disabled="btnPilihDisabled"
                                      @click="pilihPelakuFraud(item)"
                                      icon
                                    >
                                      Pilih</v-btn
                                    >
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            No Hp Manager Investigator
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <span
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="grey--text ml-1"
                            >Wajib</span
                          >
                          <v-text-field
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            v-model="investigatorPhone"
                            type="number"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            dense
                            placeholder="contoh: 0812xxxxxx"
                            @keypress="numOnly"
                            :rules="[
                              (v) =>
                                !!v ||
                                'nomor hp manager investigation harus di isi',
                              (v) =>
                                (v && v.length <= 24) ||
                                'no hp manager investigation harus  24 ',
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            Didelegasi Oleh
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-row align="left" style="margin-left: -12px">
                            <v-btn-toggle
                              v-model="delegasiModel"
                              id="btnDelegasi"
                              active-class="activeDelegasi"
                            >
                              <v-row
                                v-for="(delegasi, index) in delegasiType"
                                :key="index"
                              >
                                <v-btn
                                  v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                                  x-large
                                  class="ml-7"
                                  depressed
                                  outlined
                                  color="grey"
                                  cols="12"
                                  :value="delegasi.paramCd"
                                  style="
                                    width: 170px;
                                    height: 56px;
                                    border: 1px solid #f5821f;
                                    box-shadow: 0px 10px 20px
                                      rgba(235, 127, 0, 0.1);
                                    border-radius: 8px;
                                  "
                                >
                                  {{ delegasi.paramName }}</v-btn
                                >
                              </v-row>
                            </v-btn-toggle>
                          </v-row>
                        </v-col>

                        <v-col cols="12">
                          <h7
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            class="label-text-field"
                            color="primary"
                          >
                            Keterangan
                            <!-- <span class="red--text"> *</span> -->
                          </h7>
                          <v-textarea
                            v-if="verifikasiType == 'VERIFICATION_STATUS_FRAUD'"
                            @keypress="noChar"
                            @paste="pasteChar"
                            v-model="returnKeterangan"
                            class="mb-0"
                            hide-details="auto"
                            outlined
                            dense
                            placeholder=""
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-col>
                        <v-row>
                          <v-col class="text-right">
                            <v-btn
                              class="
                                elevation-0
                                text-capitalize
                                mr-3
                                primary--text
                              "
                              depressed
                              outlined
                              color="primary"
                              :to="`/verifikasi-data`"
                              x-large
                            >
                              <span class="orange--text">Back</span></v-btn
                            >
                            <v-btn
                              class="
                                elevation-0
                                text-capitalize
                                mr-3
                                primary--text
                              "
                              depressed
                              outlined
                              color="primary"
                              @click="saveAsDraft"
                              x-large
                            >
                              <span class="orange--text"
                                >Save as Draft</span
                              ></v-btn
                            >
                            <v-btn
                              class="
                                elevation-0
                                text-capitalize
                                mr-3
                                primary--text
                              "
                              depressed
                              color="primary"
                              @click="submitVerifikasi"
                              x-large
                            >
                              <span class="white--text">Submit</span></v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </template>
          </v-card>
        </v-col>
        <v-col>
          <v-card cols="12" rounded="lg">
            <v-card-text>
              <v-row class="mx-1">
                <v-col>
                  <h7 class="label-text-field" color="primary"> History </h7>
                  <v-data-table
                    :headers="headersLog"
                    :items="tablesLog"
                    :items-per-page="5"
                    :header-props="{ sortIcon: null }"
                    disable-sort
                    mobile-breakpoint="0"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      attachment: "",
      tablesModus: [],
      tablesLog: [],
      successDialog: false,
      tablesPelakuList: [],
      investigatorNik: "",
      reportBy: "",
      checkAnonym: "",
      reportNik: "",
      reportDepartment: "",
      reportEmail: "",
      reportOffice: "",
      reportBcArea: "",
      reportBcPelapor: "",
      reportDhPelapor: "",
      reportNoTelp: "",
      reportJenisFraud: "",
      fraudTypeCd: "",
      reportInfo: "",
      reportFraudLoc: "",
      delegasiType: "",
      reporterType: "",
      paramCd: "",
      dokumenType: "",
      returnKeterangan: "",
      delegasiModel: "",

      reportType: "",
      managerInvestigatorList: [],

      searchValueActor: "",

      office: "",
      branchArea: "",
      bcName: "",
      dhName: "",
      phoneNumber: "",
      dialogActor: false,
      dialogActor2: {},
      dataDokumen: [],
      checkData: [],
      checkDataPelaporan: false,
      checkDataSurat: false,

      readMoney: true,

      menu1: false,
      inputStartDate: "",
      inputStartDateVal: null,
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      fraudType: "",
      info: "",
      locationCd: "",
      locationName: "",
      loss: "",
      modusCd: "",
      modusOps: "",
      sharePoint: "",
      verifikasiType: "",

      chronology: "",
      returnNote: "",

      menu2: false,
      inputEndDate: "",
      inputEndDateVal: null,

      menuReturn: false,
      inputReturnDate: moment().format("DD-MMM-YYYY"),
      inputReturnDateVal: null,

      spNominal: "",
      thirdParty: "",
      investigatorManager: "",
      investigatorPhone: "",

      dataVerif: null,

      picInvestigator: "",

      items: [],
      headersPelaku: [
        {
          text: "NIK",
          width: "10px",
          value: "nik",
          align: "center",
        },

        {
          text: "Nama",
          width: "76px",
          value: "name",
          align: "center",
        },
        {
          text: "Jabatan",
          width: "96px",
          value: "position",
          align: "center",
        },
        {
          text: "Organisasi",
          width: "96px",
          value: "organization",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "96px",
          value: "pelakuId",
          align: "center",
        },
      ],
      headers: [
        {
          text: "No.",
          align: "center",
          width: "10px",
          value: "seqNo",
        },
        {
          text: "NIK Pelaku",
          width: "10px",
          value: "pelakuNik",
          align: "center",
        },
        {
          text: "Pelaku Utama",
          width: "86px",
          value: "pelakuUtama",
          align: "center",
        },
        {
          text: "Nama Pelaku",
          width: "76px",
          value: "pelakuName",
          align: "center",
        },
        {
          text: "Jabatan Pelaku",
          width: "96px",
          value: "pelakuPosition",
          align: "center",
        },
        {
          text: "Status Kepagawaian Pelaku",
          width: "126px",
          value: "pelakuStatus",
          align: "center",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          width: "116px",
          value: "lokasiKerja",
          align: "center",
        },
        {
          text: "Status Penugasan",
          width: "106px",
          value: "assignmentCategory",
          align: "center",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "84px",
          value: "",
        },
      ],
      headersModus: [
        {
          text: "No.",
          align: "center",
          width: "19px",
          value: "seqNo",
        },
        {
          text: "Kode Modus",
          width: "84px",
          value: "fraudMthdName",
          align: "center",
        },
        {
          text: "Kronologis Kejadian",
          width: "207px",
          value: "incidentName",
          align: "center",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "62px",
        },
      ],
      headersLog: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
        },
        {
          text: "Process Date",
          value: "processDate",
          align: "center",
        },
        {
          text: "Process By",
          value: "processBy",
          align: "center",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
        },
        {
          text: "Process Comment",
          align: "left",
          value: "processComment",
        },
      ],
      options: {
        locale: "pt-BR",
        prefix: "Rp",
        suffix: "",
        length: 14,
        precision: 0,
      },
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      fraudData: [],
      tab: null,
      text: "test",
      activeTab: "",
      managerInvestigatorLoad: false,
      btnPilihDisabled: false,
      flagLoadVerifikasi: false,
      flagLoadLaporan: false,
      countCheckbox: "1",
    };
  },

  async mounted() {
    this.handleInitialData();
  },

  created() {
    this.requiredFeature('VERIFIKASI_FRAUD')
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "createVerifikasiFraud",
      "getDataFraudId",
      "submitVerifikasiData",
      "saveVerifikasiData",
      "getInquiryParameter",
      "downloadAttachment",
      "getManagerInvestigatorSearchValue",
      "createInvHistory",
      "getInitialData",
      "getDataPelaporByFraudId",
      "getFraudHistoryByFraudId",
      "getDetailLaporanByFraudId",
      "getYangTerlibatByFraudId",
      "getModusOperandiByFraudId",
      "getAttachmentByFraudId",
      "getVerifikasiFraudByFraudId",
      "getDokumenAwalByFraudId",
      "getParameterByParamType",
      "getManagerInvestigatorList",
    ]),

    handleCheckbox() {
      this.countCheckbox = this.checkData.length;
    },

    handleInitialData() {
      this.getInitialData({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.reportType = resp.data.data.reportType;

        if (this.reportType !== 'REPORT_TYPE_ANONIM') {
          this.handleDataPelapor();
        }
        else {
          this.handleDetailLaporan();
        }

        this.handleFraudHistory();
      })
      .catch((err) => {
        console.log("handleInitialData ERR", err);
      });
    },

    handleDataPelapor() {
      this.getDataPelaporByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.reportBy = resp.data.data.namaPelapor == null ? "-" : resp.data.data.namaPelapor;
        this.reportNik = resp.data.data.nikPelapor == null ? "-" : resp.data.data.nikPelapor;
        this.reportDepartment = resp.data.data.jabatanPelapor == null ? "-" : resp.data.data.jabatanPelapor;
        this.reportEmail = resp.data.data.emailPelapor == null ? "-" : resp.data.data.emailPelapor;
        this.reportOffice = resp.data.data.officePelapor == null ? "-" : resp.data.data.officePelapor;
        this.reportBcArea = resp.data.data.departmentPelapor == null ? "-" : resp.data.data.departmentPelapor;
        this.reportBcPelapor = resp.data.data.namaAtasan1 == null ? "-" : resp.data.data.namaAtasan1;
        this.reportDhPelapor = resp.data.data.namaAtasan2 == null ? "-" : resp.data.data.namaAtasan2;
        this.reportNoTelp = resp.data.data.teleponPelapor == null ? "-" : resp.data.data.teleponPelapor;
      })
      .catch((err) => {
        console.log("handleDataPelapor ERR", err);
      });
    },

    handleFraudHistory() {
      this.getFraudHistoryByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.tablesLog = resp.data.data;
      })
      .catch((err) => {
        console.log("handleFraudHistory ERR", err);
      });
    },

    handleDetailLaporan() {
      if (!this.flagLoadLaporan) {
        this.flagLoadLaporan = true;

        this.getDetailLaporan();
        this.getYangTerlibat();
        this.getModusOperandi();
        this.getAttachment();
      }
    },

    getDetailLaporan() {
      this.getDetailLaporanByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.inputStartDate = moment(resp.data.data.tanggalLaporan).format("DD-MMM-YYYY");
        this.reportJenisFraud = resp.data.data.jenisFraudStr;
        this.reportInfo = resp.data.data.sumberInformasiStr;
        this.reportFraudLoc = resp.data.data.lokasiFraudNm;
        this.loss = this.humanFormat(resp.data.data.kerugianAwal);
      })
      .catch((err) => {
        console.log("getDetailLaporan ERR", err);
      });
    },

    getYangTerlibat() {
      this.getYangTerlibatByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.tablesPelakuList = resp.data.data;
      })
      .catch((err) => {
        console.log("getYangTerlibat ERR", err);
      });
    },

    getModusOperandi() {
      this.getModusOperandiByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.tablesModus = resp.data.data;
      })
      .catch((err) => {
        console.log("getModusOperandi ERR", err);
      });
    },

    getAttachment() {
      this.getAttachmentByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.attachment = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachment ERR", err);
      });
    },

    handleVerifikasiFraud() {
      if (!this.flagLoadVerifikasi) {
        this.flagLoadVerifikasi = true;
        
        this.loadDokumenAwal();
        this.loadDelegatorPosition();
        this.getVerifikasiFraud();
        this.getDokumenAwal();
      }
    },

    loadDokumenAwal() {
      this.getParameterByParamType({
        paramType: "DOKUMEN_AWAL_PELAPORAN",
      })
      .then((resp) => {
        this.dataDokumen = resp.data.data
        this.dataDokumen.map((item) => {
            item.reportDocType = item.paramCd;
            item.reportDocTypeName = item.paramName;

            delete item.pageNumber;
            delete item.pageSize;
            delete item.paramCd;
            delete item.paramName;
            delete item.paramType;
            delete item.paramTypeName;
            delete item.paramValue;
            delete item.searchValue;
          });
      })
      .catch((err) => {
        console.log("loadDokumenAwal ERR", err);
      });
    },

    loadDelegatorPosition() {
      this.getParameterByParamType({
        paramType: "INVESTIGATOR_DELEGATOR_POS",
      })
      .then((resp) => {
        this.delegasiType = resp.data.data;
      })
      .catch((err) => {
        console.log("loadDelegatorPosition ERR", err);
      });
    },

    loadManagerInvestigator() {
      this.managerInvestigatorLoad = true;
      this.btnPilihDisabled = true;

      this.getManagerInvestigatorList({
        searchValue: this.searchValueActor != null ? this.searchValueActor.trim() : this.searchValueActor,
      })
      .then((resp) => {
        this.managerInvestigatorList = resp.data.data;
      })
      .catch((err) => {
        console.log("loadManagerInvestigator ERR", err);
      })
      .finally(() => {
        this.managerInvestigatorLoad = false;
        this.btnPilihDisabled = false;
      });

      this.page = 1;
    },

    getVerifikasiFraud() {
      this.getVerifikasiFraudByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.verifikasiType = resp.data.data.verifikasiStatus; 
        this.spNominal = resp.data.data.spPelaku;
        this.thirdParty = resp.data.data.spNasabah;
        this.investigatorManager = resp.data.data.delegatorName;
        this.investigatorNik = resp.data.data.delegatorNik;
        this.investigatorPhone = resp.data.data.delegatorPhone;
        this.delegasiModel = resp.data.data.delegatorPosition;
        this.returnKeterangan = resp.data.data.keterangan;
        this.returnNote = resp.data.data.returnReason;
        this.menuReturn = moment(resp.data.data.returnDateStr).format("DD-MMM-YYYY");
      })
      .catch((err) => {
        console.log("getVerifikasiFraud ERR", err);
      });
    },

    getDokumenAwal() {
      this.getDokumenAwalByFraudId({
        fraudId: this.idFraud,
      })
      .then((resp) => {
        this.checkData = resp.data.data;
        this.checkData.map((item) => {
              delete item.reportDocId;
              delete item.fraudId;
            })
      })
      .catch((err) => {
        console.log("getDokumenAwal ERR", err);
      });
    },

    handleManagerInvestigator() {
      this.resetSearchManagerInvestigatorField();
      this.loadManagerInvestigator();
    },

    resetSearchManagerInvestigatorField() {
      this.searchValueActor = '';
    },

    toBack() {
      this.successDialog = false;
      this.$router.push(`/verifikasi-data`);
    },

    increment() {
      this.investigatorPhone = parseInt(this.investigatorPhone, 16) + 1;
    },

    decrement() {
      this.investigatorPhone = parseInt(this.investigatorPhone, 10) - 1;
    },

    formatStartDate() {
      this.menu1 = false;
      this.inputStartDate = moment(this.inputStartDateVal).format("DD/MM/YYYY");
    },

    formatEndDate() {
      this.menu2 = false;
      this.inputEndDate = moment(this.inputEndDateVal).format("DD-MMM-YYYY");
    },

    formatReturnDate() {
      this.menuReturn = false;
      this.inputReturnDate = moment(this.inputReturnDateVal).format(
        "DD-MMM-YYYY"
      );
    },

    pilihPelakuFraud(item) {
      this.dialogActor = false;
      this.investigatorManager = item.name;
      this.investigatorNik = item.nik;
    },

    submitVerifikasi() {
      // Verifikasi Fraud Jika DiDelegasi Oleh SBM Maka Report Status Menjadi 'Delegate to Investigator' dan Update & Insert History. 
      // Selain SBM Report Status 'Verified'

      if (this.checkData.length === 0) {
        this.countCheckbox = 0;
        return;
      }
      
      let delegasiModel = this.delegasiModel
      console.log("Cek delegasiModel: ", this.delegasiModel)

      if (delegasiModel == 'INVESTIGATOR_DELEGATOR_POS_SBM') {
        this.reportStatusCode = 'REPORT_STATUS_DELEGATE_INVESTIGATOR'
        console.log("Report Status New: ", this.reportStatusCode)
        console.log("PIC Investigator: ", this.investigatorNik)

        this.submitVerifikasiData({
          extFraudMthdDesc: this.returnKeterangan != null ? this.returnKeterangan.trim() : this.returnKeterangan,
          fraudId: this.idFraud,
          investigationDelegatorNik: this.investigatorNik,
          investigationDelegatorPhone: this.investigatorPhone,
          investigationDelegatorPosition: this.delegasiModel,
          reportDocTypeList: this.checkData,
          returnDate: this.inputReturnDate != "" ? moment(this.inputReturnDate, "DD-MMM-YYYY").format("YYYY-MM-DD") : null,
          returnReason: this.returnNote != null ? this.returnNote.trim() : this.returnNote,
          spNasabah: this.thirdParty,
          spPelaku: this.spNominal,
          verifStatus: this.verifikasiType,
          picInvestigator: this.investigatorNik,
          reportStatus: this.reportStatusCode
        }).then(() => {
          if (this.verifikasiType === 'VERIFICATION_STATUS_FRAUD') {
            return this.createInvHistory({
              fraudId: this.idFraud,
              investigationDelegatorPosition: this.delegasiModel,
              investigationDelegatorNik: this.investigatorNik,
              picInvestigator: this.investigatorNik
            });
          }
        }).then((res) => {
          if (res && res.data.data != null) {
            this.successDialog = true;
          }
        }).catch((err) => {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: err.response.data.message,
            snackbarColor: "red",
          });
          console.log("error di submit", err.response);
        });
      } else {
        this.submitVerifikasiData({
            extFraudMthdDesc: this.returnKeterangan != null ? this.returnKeterangan.trim() : this.returnKeterangan,
            fraudId: this.idFraud,
            investigationDelegatorNik: this.investigatorNik,
            investigationDelegatorPhone: this.investigatorPhone,
            investigationDelegatorPosition: this.delegasiModel,
            reportDocTypeList: this.checkData,
            returnDate: this.inputReturnDate != "" ? moment(this.inputReturnDate, "DD-MMM-YYYY").format("YYYY-MM-DD") : null,
            returnReason: this.returnNote != null ? this.returnNote.trim() : this.returnNote,
            spNasabah: this.thirdParty,
            spPelaku: this.spNominal,
            verifStatus: this.verifikasiType,
        }).then(() => {
            this.successDialog = true;
        }).catch((err) => {
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: err.response.data.message,
            snackbarColor: "red",
          });
          console.log("error di submit", err.response);
        });
      }
    },

    delegasiKlik(delegasi) {
      this.delegasiModel = delegasi.paramCd;
      console.log("ini delegasi ", delegasi);
      console.log("ini delegasi yang di klik", this.delegasiModel);
    },

    saveAsDraft() {
      this.saveVerifikasiData({
        extFraudMthdDesc:
          this.returnKeterangan != null
            ? this.returnKeterangan.trim()
            : this.returnKeterangan,
        fraudId: this.idFraud,
        investigationDelegatorNik: this.investigatorNik,
        investigationDelegatorPhone: this.investigatorPhone,
        investigationDelegatorPosition: this.delegasiModel,
        reportDocTypeList: this.checkData,
        returnDate:
          this.inputReturnDate != ""
            ? moment(this.inputReturnDate, "DD-MMM-YYYY").format("YYYY-MM-DD")
            : null,
        returnReason:
          this.returnNote != null ? this.returnNote.trim() : this.returnNote,
        spNasabah: this.thirdParty,
        spPelaku: this.spNominal,
        verifStatus: this.verifikasiType,
      })
        .then(() => {
          this.$router.push(`/verifikasi-data`);
          // this.$helpers.handleSuccess(resp);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
          console.log("ini error save as draft", err);
        });
    },

    handleDownloadAttch(item) {
      this.downloadAttachment({
        base64Data: "",
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },
  },
  computed: {
    ...mapState(["reportById", "inquiryManagerInvestigator"]),
    allInquirySuspect() {
      return Object.values(this.$store.getters.allInquirySuspect);
    },
    allInquiryModus() {
      return Object.values(this.$store.getters.allInquiryModus);
    },
    allInquiryEmployeeVerifikasiFraud() {
      if (Object.values(this.$store.getters.allInquiryEmployeeVerifikasiFraud).length != 0) {
        return Object.values(this.$store.getters.allInquiryEmployeeVerifikasiFraud).filter((x) =>
              x.roleCd == "AFM_MANAGER" ||
              x.roleCd == "QA_MANAGER" ||
              x.roleCd == "QA_OPERATION_HEAD" ||
              x.roleCd == "SBM")
      } else {
        return [];
      }
    },
    mode() {
      return this.$route.params.mode;
    },
    idFraud: function () {
      return this.$route.params.id;
    },
    isEdit: function () {
      return this.mode === "edit";
    },
    rules() {
      return this.checkData.filter((s) => s === true).length > 0;
    },
  },
};
</script>

<style src="./VerifikasiData.scss" lang="scss" />