<template>
  <v-container fluid>
    <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
      <v-card>
        <v-col>
          <v-card-text class="text-right">
            <v-icon class="icon" @click="expandPembiayaanFiktif = false">mdi-close</v-icon>
          </v-card-text>
          <v-card-title>{{ lossChosen.paramName }} </v-card-title>

          <v-row no-gutters justify="center">
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF' ||
              lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
            " :headers="headersPembayaranFiktif1Expand" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.pencairanDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.plafond" prefix="Rp" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingPokok" prefix="Rp" class="mb-0 mt-3" hide-details
                      label="" outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingMargin" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsPctg" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>

                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
              :headers="headersPembayaranFiktif1ExpandSebagian" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.pencairanDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.plafond" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.pembiayaanNasabah" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.pembiayaanNasabahPercentage" class="mb-0 mt-3" hide-details
                      label="" outlined dense v-bind:options="optionsPctg" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.pembiayaanKaryawan" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.pembiayaanKaryawanPercentage" class="mb-0 mt-3" hide-details
                      label="" outlined dense v-bind:options="optionsPctg" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingTotal" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingNasabah" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingKaryawan" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
              :headers="headersPembayaranFiktif2ExpandSetoran" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.fraudRepayDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
              :headers="headersPembayaranFiktif2ExpandPelunasan" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.outstandingPelunasan" class="mb-0 mt-3" hide-details label=""
                      outlined dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.tabunganBalance" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.fraudRepayDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
              :headers="headersPembayaranFiktif2ExpandTabungan" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.tabunganNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.transaksiType" :items="transaksiTypeList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.prsCollection" :items="prsCollectionList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.fraudRepayDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'" :headers="headersPembayaranFiktif3ExpandBrw"
              :items="pembiayaanFiktifList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.mthdDetailDesc" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.brwCategory" :items="brwCategoryList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'" :headers="headersPembayaranFiktif3ExpandKas"
              :items="pembiayaanFiktifList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.kasSolCategory" :items="kasSolCategoryList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.fraudRepayDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
              :headers="headersPembayaranFiktif3ExpandLemari" :items="pembiayaanFiktifList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.mthdDetailDesc" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'" :headers="headersPembayaranFiktif3ExpandFpb"
              :items="pembiayaanFiktifList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.itemName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.fpbTransactionDate" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'" :headers="headersPembayaranFiktif4Expand"
              :items="pembiayaanFiktifList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.itemName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.pelakuPencurian" :items="pelakuPencurianList" item-text="paramName"
                      item-value="paramCd" readonly>
                    </v-autocomplete>
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.potentialLoss" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryNasabah" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.recoveryBranch" class="mb-0 mt-3" hide-details label="" outlined
                      dense v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <vuetify-money v-model="data.item.netFraud" class="mb-0 mt-3" hide-details label="" outlined dense
                      v-bind:options="optionsMoney" readonly />
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialog[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                  <!-- <td style="text-align: center">
                    <v-icon
                      small
                      color="red"
                      @click="deleteRow(data.item, indexExpand)"
                    >
                      mdi-delete
                    </v-icon>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="pelakuFraudDialog">
      <v-card>
        <v-card-text>
          <v-icon class="icon" @click="pelakuFraudDialog = false"
            style="float: right; margin-top: 12px">mdi-close</v-icon>
        </v-card-text>
        <v-card-title>Pelaku Fraud</v-card-title>
        <v-card-text>Total Fraud: Rp. {{ humanFormat(netFraudText) }}</v-card-text>
        <v-card-text>
          <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList" :items-per-page="5"
            :header-props="{ sortIcon: null }" disable-sort>
            <template v-slot:[`item.netFraudDist`]="{ item }">
              <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                v-model="item.netFraudDist" readonly>
              </v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- <v-card-actions>
          <v-col class="text-right">
            <v-btn
              class="elevation-0 text-capitalize mr-3 primary--text"
              depressed
              outlined
              color="primary"
              @click="pelakuFraudDialog = false"
            >
              <span class="orange--text">Cancel</span></v-btn
            >
          </v-col>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="documentDialog">
      <v-card>
        <v-card-text>
          <v-icon class="icon" @click="documentDialog = false" style="float: right; margin-top: 12px">mdi-close</v-icon>
        </v-card-text>
        <v-card-title>Download Images</v-card-title>
        <v-card-text>Format file</v-card-text>
        <v-card-text>
          <v-row no-gutters v-for="(document, index) in isiDocumentList" :key="index">
            <v-col> {{ index + 1 }}. {{ document.documentTypeName }} </v-col>
            <v-col class="text-right pr-3">
              <v-btn color="primary" class="text-none" rounded depressed :loading="isSelecting"
                @click="handleDownloadAttch(document)">
                <v-icon left> mdi-download </v-icon>
                {{
                  document.attachFileName ? document.attachFileName : "Download"
                }}
              </v-btn>
              <!-- <input
                :ref="`uploader` + index"
                class="d-none"
                type="file"
                accept="image/*"
                @change="onFileChanged($event, index)"
              /> -->
            </v-col>
            <v-col>
              <v-text-field hide-details="auto" dense outlined placeholder="Keterangan" v-model="document.notes"
                readonly></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-overlay :absolute="true" :value="isLoading">
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog width="800" v-model="keteranganDialog">
      <v-card>
        <v-card-title>Keterangan</v-card-title>
        <v-card-text>
          <v-textarea v-model="catatan"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
              @click="keteranganDialog = false">
              <span class="orange--text">Cancel</span></v-btn>
            <!-- <v-btn
              class="elevation-0 text-capitalize mr-3"
              depressed
              color="primary"
              @click="keteranganDialog = false"
            >
              <span class="white--text">Save</span></v-btn
            > -->
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="header d-flex mt-4 mb-1">
      <p class="titlet">Review Controller</p>
      <span class="titlet"></span>
      <p class="subTitle">Proses</p>
    </v-row>
    <v-col>
      <v-card>
        <v-tabs v-model="tab" center-active>
          <v-tab href="#data-investigator">Data Investigator</v-tab>
          <v-tab href="#detail-laporan">Detail Laporan</v-tab>
          <v-tab href="#verifikasi-fraud">Verifikasi Fraud</v-tab>
          <v-tab href="#tab-laporanPelengkap" v-if="investigationDelegatorPosition ==
            'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
          ">Laporan Pelengkap</v-tab>
          <v-tab href="#investigasi-fraud">Investigasi Fraud</v-tab>
          <v-tab href="#delegasi">Delegasi</v-tab>

          <v-tab-item value="data-investigator">
            <v-card flat>
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="6">
                    <h7 class="label-text-field">Nama Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="picInvestigator" disabled></v-text-field>
                    <h7 class="label-text-field">Jabatan/Posisi Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="picInvestigatorPosition"
                      disabled></v-text-field>
                    <h7 class="label-text-field">No. Telepon Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="investigationDelegatorPhone"
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <h7 class="label-text-field">NIK Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="picInvestigatorNik"
                      disabled></v-text-field>
                    <h7 class="label-text-field">Email Investigator</h7>
                    <v-text-field hide-details="auto" dense outlined v-model="picInvestigatorEmail"
                      disabled></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/review-controller`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="detail-laporan">
            <v-card cols="12">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="12">
                    <h7 class="label-text-field">
                      No. Tiket JAGA
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="jagaNo" class="mb-0" hide-details="auto" outlined dense
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Pelaporan
                    </h7>
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="reportDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                          v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                          disabled></v-text-field>
                      </template>
                      <v-date-picker :min="currentDate" v-model="inputStartDateVal" no-title scrollable
                        @input="formatStartDate()">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Jenis Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12">{{ fraudTypeName }}</v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Sumber Informasi Awal Temuan Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-select item-text="paramName" :items="sumberInformasiList" label="" class="mb-0"
                      hide-details="auto" v-model="fraudInf" item-value="paramCd" outlined dense clearable
                      placeholder="Pilih sumber informasi awal temuan fraud" disabled></v-select>
                  </v-col>
                  <v-col cols="12" v-if="fraudInf == 'FRAUD_INF_OTHER'">
                    <h7 class="label-text-field" color="primary">
                      Lainnya
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="fraudInfReason" class="mb-0" hide-details="auto" outlined dense
                      placeholder="Masukan kode lokasi kerja terjadinya fraud" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="18">
                    <h7 class="label-text-field" color="primary">
                      Yang Terlibat
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table hide-default-footer :headers="headersYangTerlibat" :items="fraudPelakuList"
                          :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Lokasi Kerja Terjadinya Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-text-field v-model="fraudLocName" class="mb-0" hide-details="auto" outlined dense
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Kerugian Awal
                      <!-- <span class="grey--text ml-2">Wajib</span> -->
                    </h7>
                    <v-text-field v-model="initialLoss" class="mb-0" hide-details="auto" outlined dense disabled
                      prefix="Rp"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Modus Operandi
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table hide-default-footer :headers="headersModus" :items="modusOperandiList"
                          :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                          <template v-slot:item="data">
                            <tr>
                              <td>
                                {{ data.index + 1 }}
                              </td>
                              <td>
                                {{ data.item.fraudMthdName }}
                              </td>
                              <td>
                                {{ data.item.incidentName }}
                              </td>
                              <td>
                                {{ data.item.incidentDesc }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Attachment
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col cols="12" v-for="item in attachment" :key="item">
                      <a @click="handleDownloadAttch(item)">
                        {{ item.attachFileName }}
                      </a>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/review-controller`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="verifikasi-fraud">
            <v-card cols="12" md="18">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Verification Status
                      <span class="grey--text ml-2">Wajib</span>
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12">
                        <span class="primary--text">{{
                          verifStatusName
                        }}</span></v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Dokumen Awal Pelaporan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col>
                      <v-row>
                        <div v-for="(dokumen, index) in reportDocTypeList" :key="index">
                          <v-checkbox v-model="dokumen.reportDocType" :label="dokumen.reportDocTypeName" class="px-2"
                            readonly></v-checkbox>
                        </div>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Nominal SP Pelaku
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="spPelaku" class="mb-0" hide-details="auto" outlined dense placeholder="0"
                      disabled prefix="Rp"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      SP Nasabah / Pihak Ketiga
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="spNasabah" class="mb-0" hide-details="auto" outlined dense placeholder=""
                      disabled prefix="Rp"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Manager Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="investigationDelegatorName" class="mb-0" hide-details="auto" outlined dense
                      placeholder="Masukan Investigator Manager" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      No Hp Investigator
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <span class="grey--text ml-1">Wajib</span>
                    <v-text-field v-model="investigationDelegatorPhone" class="mb-0" hide-details="auto" outlined dense
                      placeholder="contoh: 0812xxxxxx" disabled></v-text-field>
                  </v-col>
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Didelegasi Oleh
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12" style="
                                    width: 170px;
                                    height: 56px;
                                    border: 1px solid #f5821f;
                                    box-shadow: 0px 10px 20px
                                      rgba(235, 127, 0, 0.1);
                                    border-radius: 8px;
                                  ">{{ investigationDelegatorPositionName }}</v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <h7 class="label-text-field" color="primary">
                      Keterangan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-textarea v-model="extFraudMthdDesc" class="mb-0" hide-details="auto" outlined dense
                      placeholder="" disabled></v-textarea>
                  </v-col>
                  <!-- <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Return
                  </h7>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="returnDate"
                        label=""
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        class="mb-0"
                        placeholder=""
                        disabled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="currentDate"
                      v-model="inputEndDateVal"
                      no-title
                      scrollable
                      @input="formatEndDate()"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col> -->
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/review-controller`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-laporanPelengkap">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12" v-if="investigationDelegatorPosition ==
                  'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'
                ">
                  <h7 class="label-text-field" color="primary">
                    Attachment
                    <!-- <span class="red--text"> *</span> -->
                  </h7>
                  <v-col style="margin-left: -10px">
                    <!-- <span class="elevation-0 grey--text"
                      >Format file JPG/PNG/PDF/DOCX (Bukti minimal 2 file dengan
                      masing-masing maksimum 2MB per file)</span
                    > -->
                  </v-col>
                  <v-row>
                    <v-card-text>
                      <v-row class="mx-1">
                        <v-col>
                          <h7 class="label-text-field" color="primary">
                            Attachment</h7>
                          <v-col cols="12" v-for="item in fraudAttAFMList" :key="item">
                            <a @click="handleDownloadAttch(item)">{{
                              item.attachFileName
                            }}</a>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="investigasi-fraud">
            <v-card cols="12">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col>
                    <h7 class="label-text-field" color="primary">
                      Investigation Status

                    </h7>
                    <v-col align="left" style="margin-left: -12px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" depressed outlined
                        color="primary" cols="12">
                        {{ investigationStatusName }}</v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12" v-if="investigationStatus ==
                    'INVESTIGATION_STATUS_NOT_PROVEN' ||
                    investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                    <h7 v-if="investigationStatus == 'INVESTIGATION_STATUS_NOT_PROVEN'
                    " class="label-text-field" color="primary">
                      Notes

                    </h7>
                    <v-textarea v-model="note" class="mb-0" hide-details="auto" outlined dense placeholder="" disabled
                      v-if="investigationStatus == 'INVESTIGATION_STATUS_NOT_PROVEN'
                      "></v-textarea>

                  </v-col>
                  <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <h7 class="label-text-field" color="primary">
                      Tujuan Fraud

                    </h7>
                    <v-select item-text="paramName" :items="tujuanFraudList" label="" class="mb-0" hide-details="auto"
                      v-model="fraudFactor" item-value="paramCd" outlined dense clearable disabled></v-select>
                  </v-col>
                  <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN' &&
                    fraudFactor == 'FRAUD_FACTOR_OTHER'
                  ">
                    <h7 class="label-text-field" color="primary">
                      Lainnya

                    </h7>
                    <v-text-field v-model="fraudFactorDesc" class="mb-0" hide-details="auto" outlined dense
                      placeholder="Jelaskan secara rinci" disabled></v-text-field>
                  </v-col>

                  <!-- <v-col
                    cols="12"
                    v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'"
                  >
                    <h7 class="label-text-field" color="primary">
                      Jenis Pelanggaran
                      
                    </h7>
                    <v-select
                      item-text="paramName"
                      :items="jenisPelanggaranList"
                      label=""
                      class="mb-0"
                      hide-details="auto"
                      v-model="violationType"
                      item-value="paramCd"
                      outlined
                      dense
                      clearable
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      investigationStatus == 'INVESTIGATION_STATUS_PROVEN' &&
                      violationType == 'VIOLATION_TYPE_OTHER'
                    "
                  >
                    <h7 class="label-text-field" color="primary">
                      Lainnya
                    </h7>
                    <v-text-field
                      v-model="violationTypeDesc"
                      class="mb-0"
                      hide-details="auto"
                      outlined
                      dense
                      placeholder="Jelaskan secara rinci"
                      disabled
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                    <!-- <h7 class="label-text-field" color="primary">
                    Jenis Pelanggaran
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        hide-default-footer
                        :headers="headersJenisPelanggaran"
                        :items="jenisPelanggaranListToSave"
                        :items-per-page="5"
                        :header-props="{ sortIcon: null }"
                        disable-sort
                      >
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">{{ data.item.pelakuNik }}</td>
                            <td align="center">{{ data.item.pelakuName }}</td>
                            <td align="center">{{ data.item.assignmentCategory }}</td>
                            <td align="center">{{ data.item.violationTypeName }}</td>
                            <td align="center">{{ data.item.violationDesc }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row> -->
                  </v-col>
                  <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <h7 class="label-text-field" color="primary">
                      Root Cause
                      <span class="ml-2 grey--text">(Optional)</span>
                    </h7>
                    <v-row style="width: 100%">
                      <v-col cols="12">
                        <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse" :items-per-page="10"
                          :header-props="{ sortIcon: null }" disable-sort>
                          <template v-slot:item="data">
                            <tr>
                              <td align="center">{{ data.index + 1 }}</td>
                              <td align="center">
                                {{ data.item.rootCauseName }}
                              </td>
                              <td>
                                <v-select ref="keteranganRootCause" :items="rootCauseStatus[data.index]"
                                  item-text="rootCauseName" item-value="rootCauseDesc" v-model="data.item.rootCauseList"
                                  return-object label="" class="mt-3 mb-3" hide-details="auto" outlined readonly dense
                                  placeholder="" multiple chips>
                                </v-select>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Fraud Dilakukan
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-menu v-model="fraudCommitedDateMenu" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fraudCommitedDate" label="" prepend-inner-icon="mdi-calendar"
                          v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                          placeholder="" disabled></v-text-field>
                      </template>
                      <v-date-picker v-model="fraudCommitedDateVal" no-title scrollable
                        @input="fraudCommitedDatePicker()">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Fraud Diketahui
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-menu v-model="fraudKnownDateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fraudKnownDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                          v-on="on" readonly outlined dense hide-details="auto" class="mb-0" placeholder=""
                          disabled></v-text-field>
                      </template>
                      <v-date-picker v-model="fraudKnownDateVal" no-title scrollable @input="fraudKnownDatePicker()">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <h7 class="label-text-field" color="primary">
                      Tanggal Terakhir Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-menu v-model="recoveryDateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="fraudCommitedDateTo" label="" prepend-inner-icon="mdi-calendar"
                          v-bind="attrs" v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                          placeholder="" disabled></v-text-field>
                      </template>
                      <v-date-picker v-model="recoveryDateVal" no-title scrollable @input="recoveryDatePicker()">
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col v-if="investigationStatus == 'INVESTIGATION_STATUS_PROVEN'">
                    <!-- <v-row>
                      <v-expansion-panels accordion>
                        <v-expansion-panel
                          v-for="(loss, index) in lossList"
                          :key="index"
                        >
                          <v-expansion-panel-header
                            ><v-row no-gutters
                              ><v-col>{{ loss.paramName }} </v-col
                              ><v-col class="text-right"
                                ><v-btn
                                  text
                                  @click.native.stop="openExpand(index, loss)"
                                >
                                  <span class="orange--text"
                                    >Expand</span
                                  ></v-btn
                                ></v-col
                              ></v-row
                            ></v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col class="text-left">
                                <v-btn
                                class="elevation-0 text-capitalize text-center"
                                cols="12"
                                color="white"
                                @click="handleDownloadTemplate(loss.paramValue)"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-download </v-icon
                                ><span class="orange--text ml-2"
                                  >Download Template</span
                                >
                              </v-btn>
                                <v-btn
                                class="elevation-0 text-capitalize text-center"
                                cols="12"
                                color="white"
                                @click="handleAddDetailLoss(index, loss)"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-plus-box </v-icon
                                ><span class="orange--text ml-2">Tambah</span>
                              </v-btn>
                                <v-btn
                                class="elevation-0 text-capitalize text-center"
                                cols="12"
                                color="white"
                                @click="onButtonClickTemplate(index)"
                              >
                                <v-icon aria-hidden="false" color="orange">
                                  mdi-upload </v-icon
                                ><span class="orange--text ml-2">Upload</span>
                              </v-btn>
                                <input
                                :ref="`uploaderTemplate` + index"
                                class="d-none"
                                type="file"
                                @change="
                                  onFileChangedTemplate(
                                    $event,
                                    loss.paramValue,
                                    loss.paramCd
                                  )
                                "
                              />
                              </v-col>
                            </v-row>
                            <v-data-table
                              v-if="
                                loss.paramCd == 'LOSS_TYPE_FIKTIF' ||
                                loss.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
                              "
                              :headers="headersPembayaranFiktif1"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.nasabahName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.appIdNo"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum4">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.pencairanDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.plafond"
                                      prefix="Rp"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingPokok"
                                      prefix="Rp"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingMargin"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsPctg"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.tabunganBalance"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="
                                loss.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'
                              "
                              :headers="headersPembayaranFiktif1Sebagian"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.nasabahName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.appIdNo"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum4">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.pencairanDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.plafond"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.pembiayaanNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="
                                        data.item.pembiayaanNasabahPercentage
                                      "
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsPctg"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.pembiayaanKaryawan"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="
                                        data.item.pembiayaanKaryawanPercentage
                                      "
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsPctg"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingTotal"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingKaryawan"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.tabunganBalance"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_ANGSURAN'"
                              :headers="headersPembayaranFiktif2Setoran"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.nasabahName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.appIdNo"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum4">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.angsuranStatus"
                                      :items="statusAngsuranList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.fraudRepayDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_PELUNASAN'"
                              :headers="headersPembayaranFiktif2Pelunasan"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.nasabahName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.appIdNo"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum4">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.outstandingPelunasan"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.tabunganBalance"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.fraudRepayDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_TABUNGAN'"
                              :headers="headersPembayaranFiktif2Tabungan"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.nasabahName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.tabunganNo"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum4">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.transaksiType"
                                      :items="transaksiTypeList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.prsCollection"
                                      :items="prsCollectionList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.fraudRepayDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_BRW'"
                              :headers="headersPembayaranFiktif3Brw"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.mthdDetailDesc"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.brwCategory"
                                      :items="brwCategoryList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_KAS_SOL'"
                              :headers="headersPembayaranFiktif3Kas"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.sentraName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.kasSolCategory"
                                      :items="kasSolCategoryList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.fraudRepayDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
                              :headers="headersPembayaranFiktif3Lemari"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.mthdDetailDesc"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_FPB'"
                              :headers="headersPembayaranFiktif3Fpb"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.itemName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.fpbTransactionDate"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                            <v-data-table
                              v-if="loss.paramCd == 'LOSS_TYPE_PENCURIAN'"
                              :headers="headersPembayaranFiktif4"
                              :items="pembiayaanFiktifList[index]"
                              :header-props="{ sortIcon: null }"
                              disable-sort
                            >
                              <template v-slot:item="data">
                                <tr class="text-center">
                                  <td class="fixed_table_colum">
                                    {{ data.index + 1 }}
                                  </td>
                                  <td class="fixed_table_colum2">
                                    <v-text-field
                                      class="mt-3"
                                      dense
                                      outlined
                                      hide-details="auto"
                                      persistent-placeholder
                                      v-model="data.item.itemName"
                                      readonly
                                    >
                                    </v-text-field>
                                  </td>
                                  <td class="fixed_table_colum3">
                                    <v-autocomplete
                                      class="mt-3"
                                      dense
                                      outlined
                                      persistent-placeholder
                                      hide-details="auto"
                                      v-model="data.item.pelakuPencurian"
                                      :items="pelakuPencurianList"
                                      item-text="paramName"
                                      item-value="paramCd"
                                      readonly
                                    >
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.potentialLoss"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryNasabah"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.recoveryBranch"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <vuetify-money
                                      v-model="data.item.netFraud"
                                      class="mb-0 mt-3"
                                      hide-details
                                      label=""
                                      outlined
                                      dense
                                      v-bind:options="optionsMoney"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openPelakuDialog(
                                          data.item.detailLossDistList,
                                          data.item.netFraud
                                        )
                                      "
                                      >Pelaku</v-btn
                                    >
                                  </td>
                                  <td>
                                    <v-dialog
                                      v-model="dialog[data.item.netFraud]"
                                      :key="data.index"
                                      persistent
                                      width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          small
                                          text
                                          class="primary--text"
                                          v-bind="attrs"
                                          v-on="on"
                                          >Keterangan
                                        </v-btn>
                                      </template>
                                      <div class="text-center">
                                        <v-card>
                                          <v-card-text>
                            <v-icon
                              class="icon"
                              @click="$set(dialog, data.item.netFraud, false)"
                              style="float: right; margin-top: 12px"
                              >mdi-close</v-icon
                            >
                          </v-card-text>
                                          <v-card-title
                                            >Keterangan</v-card-title
                                          >
                                          <v-card-text>
                                            <v-textarea
                                              class="mb-0"
                                              hide-details="auto"
                                              outlined
                                              dense
                                              placeholder=""
                                              v-model="data.item.notes"
                                              readonly
                                            ></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </div>
                                    </v-dialog>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      class="primary--text"
                                      @click="
                                        openDocumentDialog(
                                          data.item.detailLossDocList
                                        )
                                      "
                                      >Document Image</v-btn
                                    >
                                  </td>
                                  <td style="text-align: center">
                                    <v-icon
                                      small
                                      color="red"
                                      @click="deleteRow(data.item, index)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row> -->

                    <v-col>
                      <h7 class="label-text-field" color="primary">Rincian Daftar Kerugian</h7>
                    </v-col>
                    <!-- <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                      hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                      <template v-slot:item="data">
                        <tr>
                          <td>{{ data.index + 1 }}</td>
                          <td>{{ data.item.pelakuNik }}</td>
                          <td>{{ data.item.pelakuName }}</td>
                          <td>{{ data.item.modusName }}</td>
                          <td>{{ data.item.noa === 0 ? "" : data.item.noa }}</td>
                          <td>{{ data.item.plafond === 0 ? "" : data.item.plafond }}</td>
                          <td>{{ data.item.tabungan === 0 ? "" : data.item.tabungan }}</td>
                          <td>{{ data.item.potentialLoss === 0 ? "" : data.item.potentialLoss }}</td>
                          <td>{{ data.item.recovery }}</td>
                          <td>{{ data.item.netFraud }}</td>
                          <td>
                            <v-btn v-if="data.item.pelakuUtama == 'N'" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                              @click="handleDownloadFilePelaku(data.item, 'bukti')">
                              <span class="orange--text ml-2">Download Surat Pernyataan</span>
                            </v-btn> 
                            <div v-else>
                              <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                <span class="orange--text ml-2">Download List Nasabah</span>
                              </v-btn> 
                              <v-btn class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                <span class="orange--text ml-2">Download Bukti</span> 
                              </v-btn> 
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template slot="body.append">
                        <tr>
                          <td colspan="4" align="center">Total</td>
                          <td>{{sumField('noa') === 0 ? "" : sumField('noa')}}</td>
                          <td>{{sumField('plafond') === 0 ? "" : sumField('plafond')}}</td>
                          <td>{{sumField('tabungan') === 0 ? "" : sumField('tabungan')}}</td>
                          <td>{{sumField('potentialLoss') === 0 ? "" : sumField('potentialLoss')}}</td>
                          <td>{{sumField('recovery') === 0 ? "" : sumField('recovery')}}</td>
                          <td>{{sumField('netFraud') === 0 ? "" : sumField('netFraud')}}</td>
                        </tr>
                      </template>
                    </v-data-table> -->
                    <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                      hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                      <template v-slot:item="data" item-key="pelakuId">
                        <tr v-if="!isMerged(data.item, data.index)">
                          <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                            data.index) }}</td>
                          <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                          <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                          <td>{{ data.item.modusName }}</td>
                          <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                          <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                          <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                          <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                          <td>{{ formatNumber(data.item.recovery) }}</td>
                          <td>{{ formatNumber(data.item.netFraud) }}</td>
                          <td>
                            <div v-if="data.item.pelakuUtama == 'N'">
                              <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                <span class="orange--text ml-2">Download Surat Pernyataan</span>
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                <span class="orange--text ml-2">Download List Nasabah</span>
                              </v-btn>
                              <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                <span class="orange--text ml-2">Download Bukti</span>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                        <tr v-else>
                          <td>{{ data.item.modusName }}</td>
                          <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                          <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                          <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                          <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                          <td>{{ formatNumber(data.item.recovery) }}</td>
                          <td>{{ formatNumber(data.item.netFraud) }}</td>
                          <td>
                            <div v-if="data.item.pelakuUtama == 'N'">
                              <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                <span class="orange--text ml-2">Download Surat Pernyataan</span>
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                <span class="orange--text ml-2">Download List Nasabah</span>
                              </v-btn>
                              <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''"
                                class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                <span class="orange--text ml-2">Download Bukti</span>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template slot="body.append">
                        <tr>
                          <td colspan="4" align="center">Total</td>
                          <td>{{ sumField('noa') === 0 ? "" : formatNumber(sumField('noa')) }}</td>
                          <td>{{ sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond')) }}</td>
                          <td>{{ sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan')) }}</td>
                          <td>{{ sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss')) }}</td>
                          <td>{{ sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery')) }}</td>
                          <td>{{ sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud')) }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/review-controller`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="delegasi">
            <v-card>
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="12">
                    <h7 class="label-text-field">
                      Tanggal Plan Komite Fraud
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-menu v-model="kfPlantDateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="kfPlantDate" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                          v-on="on" readonly outlined dense hide-details="auto" class="mb-0"
                          placeholder=""></v-text-field>
                      </template>
                      <v-date-picker v-model="kfPlantDateVal" no-title scrollable @input="kfPlantDatePicker()"
                        type="month">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-2">
              <v-card-text>
                <v-row class="mx-1">
                  <v-col cols="12">
                    <h7 class="label-text-field">
                      AFM Reviewer
                      <!-- <span class="red--text"> *</span> -->
                    </h7>
                    <v-dialog v-model="dialogActor" max-width="700">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="afmReviewerName" class="mb-0" hide-details="auto" outlined dense
                          v-on="on" v-bind="attrs"></v-text-field>
                      </template>
                      <v-card>
                        <v-card-title class="black--text">
                          AFM Reviewer
                        </v-card-title>
                        <v-card-text class="mt-5">
                          <span class="black--text">NIK atau Nama</span>
                          <v-row>
                            <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValueActor"
                              dense outlined placeholder="Contoh NIK: 123415 atau Contoh Nama: Test "
                              prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                            <v-btn @click="loadDataKary" class="elevation-0 text-capitalize ml-5" color="primary">
                              <span class="white--text">Cari</span>
                            </v-btn>
                          </v-row>
                          <v-data-table :headers="headersPelaku" :items="allInquiryEmployeeAfm" :items-per-page="5"
                            disable-sort class="mt-5">
                            <template v-slot:[`item.pelakuId`]="{ item }">
                              <v-btn text class="primary--text" @click="
                                (afmReviewer = item.nik),
                                (afmReviewerName = item.name),
                                (dialogActor = false)
                                " icon>
                                Pilih</v-btn>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/review-controller`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                    <v-btn class="elevation-0 text-capitalize mr-3" depressed color="primary"
                      @click="handleSubmitReview" x-large>
                      <span class="white--text">Delegasi</span></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-col>
      <v-card rounded="lg">
        <v-card-text>
          <v-row class="mx-1">
            <v-col>
              <h7 class="label-text-field"> History </h7>
              <v-data-table :headers="headersHistory" :items="fraudLogList" :header-props="{ sortIcon: null }"
                disable-sort>
                <template v-slot:[`item.processDate`]="{ item }">
                  {{ item.processDate }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      picInvestigatorNik: "",
      nikManager: '',
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      optionsMoney: {
        locale: "id-ID",
        prefix: "Rp",
        suffix: "",
        length: 12,
        precision: 0,
      },
      optionsPctg: {
        locale: "id-ID",
        prefix: "",
        suffix: "%",
        length: 12,
        precision: 0,
      },
      fraudAttAFMList: [],
      fraudCommitedDateTo: null,
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      rootCouse: [],
      rootCauseStatus: [],
      rootCauseApi: [],
      dialogActor: false,
      headersPelaku: [
        {
          text: "NIK",
          width: "10px",
          value: "nik",
          align: "center",
        },

        {
          text: "Nama",
          width: "76px",
          value: "name",
          align: "center",
        },
        {
          text: "Jabatan",
          width: "96px",
          value: "position",
          align: "center",
        },
        {
          text: "Organisasi",
          width: "96px",
          value: "organization",
          align: "center",
        },
        {
          text: "Tindakan",
          width: "96px",
          value: "pelakuId",
          align: "center",
        },
      ],
      attachment: [],
      afmReviewer: "",
      afmReviewerName: "",
      kfPlantDate: null,
      fraudAttList: [],
      fraudCommitedDateMenu: false,
      fraudCommitedDateVal: null,
      kfPlantDateMenu: false,
      kfPlantDateVal: null,
      fraudKnownDateMenu: false,
      fraudKnownDateVal: null,
      dialog: {},
      pelakuFraudDialog: false,
      documentDialog: false,
      picInvestigator: "",
      picInvestigatorPosition: "",
      picInvestigatorEmail: "",
      picInvestagorPhone: "",
      fraudLogList: [],
      jagaNo: "",
      reportDate: "",
      fraudTypeName: "",
      fraudInf: "",
      fraudInfReason: "",
      fraudPelakuList: [],
      fraudPelakuListPelakuFraud: [],
      fraudLocName: "",
      initialLoss: "",
      sharepointLink: "",
      verifStatusName: "",
      reportDocTypeList: [],
      spPelaku: "",
      spNasabah: "",
      investigationDelegatorName: "",
      investigationDelegatorPhone: "",
      investigationDelegatorPositionName: "",
      extFraudMthdDesc: "",
      returnDate: "",
      fraudCommitedDate: null,
      fraudControl: "",
      fraudDetail: "",
      fraudFactor: "",
      fraudFactorDesc: "",
      fraudKnownDate: null,
      fraudPurpose: "",
      fraudReason: "",
      investigationStatus: "",
      investigationDelegatorPosition: "",
      violationType: "",
      violationTypeDesc: "",
      note: "",
      expandPembiayaanFiktif: false,
      pembiayaanFiktifList: [],
      headersModus: [
        {
          text: "No",
          align: "center",
          width: "30px",
        },
        {
          text: "Kode Modus",
          align: "center",
          width: "250px",
        },
        {
          text: "Kronologi Kejadian",
          align: "center",
          width: "400px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "150px",
        },
      ],
      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "150px",
        },
      ],
      headersYangTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "",
          width: "150px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
        },
      ],
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Janis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        // {
        //   text: "Tindakan",
        //   align: "center",
        //   width: "150px",
        //   class: "tableHeader ",
        // },
      ],
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafon (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
        }
      ],
      headersHistory: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Date",
          align: "center",
          value: "processDate",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Processed By",
          align: "center",
          value: "processBy",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
          //   width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "250px",
          value: "processComment",
          class: "tableHeader ",
        },
      ],
      dataReviewById: null,
      jenisPelanggaranList: [],
      modusOperandiList: [],
      kodeModusList: [],
      kronologiList: [],
      deleteIndex: null,
      defaultListModus: {
        fraudId: "",
        fraudMthd: "",
        fraundMthdName: "",
        incident: "",
        incidentDesc: "",
        incidentName: "",
        seqNo: 0,
      },
      defaultDetailLossList: {
        appIdNo: "",
        createBy: "",
        createDate: "",
        detailLossDistList: [],
        detailLossDocList: [],
        detailLossTempId: 0,
        fraudId: 0,
        itemName: "",
        lossType: "",
        lossTypeName: "",
        mthdDetailDesc: "",
        nasabahName: "",
        netFraud: 0,
        notes: "",
        outstandingMargin: 0,
        outstandingPokok: 0,
        ownershipStatus: "",
        ownershipStatusName: "",
        pencairanDate: "",
        plafond: 0,
        potentialLoss: 0,
        recordFlag: "",
        recoveryBranch: 0,
        recoveryNasabah: 0,
        sentraName: "",
        tabunganBalance: 0,
        tabunganNo: "",
        updateBy: "",
        updateDate: "",
        version: "",
      },
      lossList: [],
      indexExpand: 0,
      indexTable: 0,
      pelakuFraudList: [],
      isiPelakuList: [],
      catatan: "",
      keteranganDialog: false,
      isiDocumentList: [],
      sumberInformasiList: [],
      tujuanFraudList: [],
      lossChosen: {
        paramValue: 5,
      },
      investigationStatusName: "",
    };
  },
  async created() {
    this.requiredFeature('REVIEW_CONTROLLER')
    this.changeTitleApp("Review Controller");
    await this.loadData();
    this.handleGetJenisPelanggaran();
    this.handleGetKodeModus();
    this.handleGetSumberInformasi();
    this.handleGetTujuanFraud();
    this.loadDataKary();

    this.hanldeGetStatusAngsuran();
    this.hanldeGetTransaksiType();
    this.hanldeGetPrsCollection();
    this.hanldeGetBrwCategory();
    this.hanldeGetKasSolCategory();
    this.hanldeGetPelakuPencurian();
  },
  filters: {
    momentDateHistory: function (date) {
      return moment(date).format("DD-MMM-YYYY HH:mm:ss");
    },
  },
  mounted() {
    this.handleSearchFraudPelaku();
  },
  methods: {
    ...mapActions([
      "changeTitleApp",
      "getDataReviewProcessByFraudId",
      "submitReviewProcess",
      "saveReview",
      "getParameterByParamType",
      "uploadReview",
      "saveRincianDaftarKerugianAuto",
      "searchDataRinDafKerugianByFraudId",
      "downloadTemplateDaftarKerugian",
      "saveUploadRincianDaftarKerugian",
      "downloadAttachment",
      // "getManagerInvestigatorSearchValue",
      "getInquiryLovAfm",
      "searchFraudPelaku",
      "downloadFilePelaku"
    ]),

    handleSearchFraudPelaku() {
      this.searchFraudPelaku(this.fraudId)
        .then(response => {
          console.log('response', response)
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    handleDownloadFilePelaku(item, type) {
      this.downloadFilePelaku({
        pelakuDtlId: item.pelakuDetailId,
        type: type
      })
        .then((response) => {
          console.log(response, "response")
        })
        .catch((err) => {
          console.log("gagal download", err.response);
          this.$helpers.handleError(err);
          this.$store.commit("setShowSnackbar", {
            snackbarMsg: "Gagal mengunduh. File tidak ditemukan",
            snackbarColor: "red",
          })
          return
        });
    },
    isMerged(item, index) {
      if (index === 0) return false;
      return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    sumField(key) {
      return this.daftarKerugianList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    hanldeGetPrsCollection() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PRS_COLLECTION",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.prsCollectionList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetBrwCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "BRW_CATEGORY",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.brwCategoryList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetKasSolCategory() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "KAS_SOL_CATEGORY",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.kasSolCategoryList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    hanldeGetPelakuPencurian() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "PELAKU_PENCURIAN",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.pelakuPencurianList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    hanldeGetTransaksiType() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "TRANSAKSI_TYPE",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.transaksiTypeList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    hanldeGetStatusAngsuran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "ANGSURAN_STATUS",
        paramValue: "",
        searchValue: "",
      })
        .then((response) => {
          this.statusAngsuranList = response.data.data;
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    loadStatusRoot() {
      this.rootCouse = [];
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
        .then((resp) => {
          this.mandatoryList = [];
          let rootCauseStatusa = resp.data.data;
          for (let index = 0; index < rootCauseStatusa.length; index++) {
            this.rootCouse.push({
              createBy: "",
              createDate: "",
              fraudId: this.fraudId,
              lossDocId: 0,
              recordFlag: "",
              rootCause: rootCauseStatusa[index].paramCd,
              rootCauseDesc: "",
              rootCauseList: [],
              rootCauseName: rootCauseStatusa[index].paramName,
              seqNo: 0,
              updateBy: "",
              updateDate: "",
            });

            this.mandatoryList.push(rootCauseStatusa[index].paramValue);

            this.rootCauseStatus.push([]);

            this.getParameterByParamType({
              pageNumber: 0,
              pageSize: 100,
              paramCd: "",
              paramName: "",
              paramType: rootCauseStatusa[index].paramCd,
              paramValue: "",
              searchValue: "",
            }).then((response) => {
              response.data.data.map((item) => {
                item.rootCause = item.paramType;
                delete item.paramType;
                item.rootCauseDesc = item.paramCd;
                delete item.paramCd;
                item.rootCauseName = item.paramName;
                delete item.paramName;
                item.rootCauseDescName = "";
                delete item.paramTypeName;
                delete item.paramValue;

                // item.lossDocId = null; //dihapus pas load
                // item.fraudId = this.fraudId; //dihapus pas load
                //         "rootCauseId": 272, // dihapus aja pas load
                //         "seqNo": 1, //dihapus pas load

                //         "rootCause": "ROOT_CAUSE_1", //munculkan
                //         "rootCauseName": null, //munculkan
                //         "rootCauseDesc": "ROOT_CAUSE_PRESSURE_1", //munculkan
                //         "rootCauseDescName": null, //munculkan

                //         "rootCauseList": [] //hapus pas load
              });
              this.rootCauseStatus.splice(index, 1, response.data.data);
            });
          }

          if (this.rootCauseApi.length != 0) {
            this.rootCauseApi.map((item) => {
              let indexs = this.rootCouse.findIndex(
                (x) => x.rootCause === item.rootCause
              );
              this.rootCouse.splice(indexs, 1, item);
              // console.log('apa isisisis', this.rootCouse[indexs])
              // .push(temp[index]);
            });
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    loadDataKary() {
      this.getInquiryLovAfm({
        pageNumber: 0,
        pageSize: 1000,
        searchValue: this.searchValueActor,
        nikManagerInvestigator: this.nikManager,
        // role: 5
      })
        .then(() => {
        })
        .catch(() => {
          this.$store.state.inquiryEmployee = [];
        });
      this.page = 1;
    },
    handleDownloadAttch(item) {
      this.downloadAttachment({
        base64Data: "",
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleDownloadTemplate(tipe) {
      this.downloadTemplateDaftarKerugian(tipe)
        .then(() => {
        })
        .catch(() => {
        });
    },
    onButtonClickTemplate(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      //   console.log("tanpa index", this.$refs.uploaderTemplate);
      // console.log('index', this.$refs.uploaderTemplate[index])

      // const button = this.$refs['uploaderTemplate'].find( el => el.$attrs['key'] === index);

      // button.click();
      this.$refs["uploaderTemplate" + index][0].click();
    },
    onFileChangedTemplate(e, tipe, lossType) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);

      this.saveUploadRincianDaftarKerugian({
        formData: formData,
        fraudId: this.fraudId,
        typeTemplate: tipe,
        lossType: lossType,
      })
        .then(() => {
        })
        .catch(() => {
        });
    },
    handleSaveAuto(index) {
      let detailLossList = this.pembiayaanFiktifList[index];
      this.saveRincianDaftarKerugianAuto({
        detailLossList: detailLossList,
        fraudId: this.fraudId,
      }).then(() => {
      });
    },
    onButtonClick(index) {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // this.$refs.uploader[index].click();
      this.$refs["uploader" + index][0].click();
    },
    onFileChanged(e, index) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);

      this.uploadReview(formData).then((response) => {
        this.isiDocumentList[index].attachFileName =
          response.data.data.fileName;
        this.isiDocumentList[index].attachFilePath =
          response.data.data.filePathName;
      });
    },
    uploadFile(event, index) {

      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      this.uploadReview(formData).then((response) => {
        this.fraudAttList[index].attachFileName = response.data.data.fileName;
        this.fraudAttList[index].attachFilePath =
          response.data.data.filePathName;
      });
    },
    addFraudAttachment() {
      this.fraudAttList.push({
        attachFileName: "",
        attachFilePath: "",
        flowName: "Review Fraud",
        fraudId: this.fraudId,
        seqNo: 0,
      });
    },
    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },
    fraudCommitedDatePicker() {
      this.fraudCommitedDateMenu = false;
      this.fraudCommitedDate = moment(this.fraudCommitedDateVal).format(
        "yyyy-MM-DD"
      );
    },
    kfPlantDatePicker() {
      this.kfPlantDateMenu = false;
      this.kfPlantDate = moment(this.kfPlantDateVal).format("MMMM YYYY");
    },
    fraudKnownDatePicker() {
      this.fraudKnownDateMenu = false;
      this.fraudKnownDate = moment(this.fraudKnownDateVal).format("yyyy-MM-DD");
    },
    openPelakuDialog(isi, fraud) {
      this.isiPelakuList = isi;
      this.netFraudText = fraud;
      this.pelakuFraudDialog = true;
    },
    openDocumentDialog(document) {
      this.isiDocumentList = document;
      this.documentDialog = true;
    },
    openKeteranganDialog(catatan) {
      this.catatan = catatan;
      this.keteranganDialog = true;
    },
    deleteRowOperandi(data) {
      this.deleteIndex = this.modusOperandiList.indexOf(data);
      if (this.deleteIndex !== -1) {
        this.modusOperandiList.splice(this.deleteIndex, 1);
        this.kronologiList.splice(this.deleteIndex, 1);
      }
    },
    deleteRow(data, index) {
      this.deleteIndex = this.pembiayaanFiktifList[index].indexOf(data);
      if (this.deleteIndex !== -1) {
        this.pembiayaanFiktifList[index].splice(this.deleteIndex, 1);
      }
    },
    handleAdd() {
      const addObj = Object.assign(
        {},
        {
          fraudId: this.fraudId,
          fraudMthd: "",
          fraundMthdName: "",
          incident: "",
          incidentDesc: "",
          incidentName: "",
          seqNo: 0,
        }
      );
      this.modusOperandiList.push(addObj);
      this.kronologiList.push([]);
    },
    handleAddDetailLoss(index, loss) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: loss.paramCd,
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        let respData = response.data.data;
        let docArray = [];
        for (let index = 0; index < respData.length; index++) {
          docArray.push({
            attachFileName: null,
            attachFilePath: null,
            documentType: respData[index].paramCd,
            documentTypeName: respData[index].paramName,
            fileUpload: null,
            lossDocTempId: null,
            notes: null,
            detailLossId: null,
            lossDocId: null,
          });
        }
        var cloneArray = JSON.parse(JSON.stringify(this.fraudPelakuList));
        cloneArray.map((item) => {
          item.netFraudDist = "";
          item.fraudPelakuId = item.pelakuId;
          item.detailLossId = 0;
          item.lossDistId = 0;
          item.lossDistTempId = 0;
        });
        this.defaultDetailLossList.detailLossDistList = cloneArray;
        this.defaultDetailLossList.detailLossDocList = docArray;
        this.defaultDetailLossList.lossType = loss.paramCd;
        this.defaultDetailLossList.lossTypeName = loss.paramName;
        this.defaultDetailLossList.fraudId = this.fraudId;
        const addObj = Object.assign({}, this.defaultDetailLossList);
        this.pembiayaanFiktifList[index].push(addObj);
        this.pelakuFraudList[index].push([]);
      });
    },
    handleGetJenisPelanggaran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "VIOLATION_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.jenisPelanggaranList = response.data.data;
      });
    },
    handleGetTujuanFraud() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_FACTOR",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.tujuanFraudList = response.data.data;
      });
    },
    handleGetSumberInformasi() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_INF",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.sumberInformasiList = response.data.data;
      });
    },
    handleGetLossList(temp) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.lossList = response.data.data;
        for (var i = 0; i < this.lossList.length; i++) {
          this.pembiayaanFiktifList.push([]);
          this.pelakuFraudList.push([]);
        }
        temp.map((item) => {
          let index = this.lossList.findIndex(
            (x) => x.paramCd === item.lossType
          );
          this.pembiayaanFiktifList[index].push(item);
        });
      });
    },
    handleGetKodeModus() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_MTHD",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.kodeModusList = response.data.data;
      });
    },
    handleGetKronologiList(item, index) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: item,
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.kronologiList.splice(index, 1, response.data.data);
      });
    },
    async loadData() {
      await this.getDataReviewProcessByFraudId({
        fraudId: this.fraudId,
      }).then((response) => {
        this.dataReviewById = response.data.data;

        this.jenisPelanggaranListToSave = this.dataReviewById.jenisPelanggaranList;
        //HISTORY
        this.fraudLogList = this.dataReviewById.fraudLogList;

        this.nikManager = this.dataReviewById.investigationDelegatorNik;

        //DATA INVESTIGATOR
        this.picInvestigator = this.dataReviewById.picInvestigatorName;
        this.picInvestigatorNik = this.dataReviewById.picInvestigator;
        this.picInvestigatorPosition =
          this.dataReviewById.picInvestigatorPosition;
        this.picInvestigatorEmail = this.dataReviewById.picInvestigatorEmail;
        this.investigationDelegatorPhone =
          this.dataReviewById.investigationDelegatorPhone;

        //DETAIL LAPORAN
        this.jagaNo = this.dataReviewById.jagaNo;
        this.reportDate = moment(this.dataReviewById.reportDate).format(
          "DD-MMM-YYYY"
        );
        this.fraudTypeName = this.dataReviewById.fraudTypeName;
        this.fraudInf = this.dataReviewById.fraudInf;
        this.fraudInfReason = this.dataReviewById.fraudInfReason;
        this.fraudPelakuList = this.dataReviewById.fraudPelakuList;
        this.fraudLocName = this.dataReviewById.fraudLocName;
        this.initialLoss = this.humanFormat(this.dataReviewById.initialLoss);
        this.sharepointLink = this.dataReviewById.sharepointLink;
        this.modusOperandiList = this.dataReviewById.fraudMthdList;
        for (var i = 0; i < this.modusOperandiList.length; i++) {
          this.kronologiList.push([]);
          if (this.modusOperandiList[i].fraudMthd != null) {
            this.handleGetKronologiList(this.modusOperandiList[i].fraudMthd, i);
          }
        }
        this.fraudPelakuListPelakuFraud = this.dataReviewById.fraudPelakuList;
        this.attachment = this.dataReviewById.fraudAttList;

        this.fraudAttAFMList = this.dataReviewById.fraudAttAFMList;

        //VERIFIKASI FRAUD
        this.verifStatusName = this.dataReviewById.verifStatusName;
        this.reportDocTypeList = this.dataReviewById.reportDocTypeList;
        this.spPelaku = this.humanFormat(this.dataReviewById.spPelaku);
        this.spNasabah = this.humanFormat(this.dataReviewById.spNasabah);
        this.investigationDelegatorName =
          this.dataReviewById.investigationDelegatorName;
        this.investigationDelegatorPhone =
          this.dataReviewById.investigationDelegatorPhone;
        this.investigationDelegatorPositionName =
          this.dataReviewById.investigationDelegatorPositionName;
        this.extFraudMthdDesc = this.dataReviewById.extFraudMthdDesc;
        this.returnDate = moment(this.dataReviewById.returnDate).format(
          "yyyy-MM-DD"
        );

        //INVESTIGASI FRAUD
        this.investigationStatus = this.dataReviewById.investigationStatus;
        this.investigationStatusName =
          this.dataReviewById.investigationStatusName;
        this.investigationDelegatorPosition =
          this.dataReviewById.investigationDelegatorPosition;
        this.note = this.dataReviewById.note;
        this.fraudFactor = this.dataReviewById.fraudFactor;
        this.fraudFactorDesc = this.dataReviewById.fraudFactorDesc;
        this.fraudReason = this.dataReviewById.fraudReason;
        this.fraudPurpose = this.dataReviewById.fraudPurpose;
        this.fraudControl = this.dataReviewById.fraudControl;
        this.fraudDetail = this.dataReviewById.fraudDetail;
        this.violationType = this.dataReviewById.violationType;
        this.violationTypeDesc = this.dataReviewById.violationTypeDesc;
        this.fraudCommitedDate = this.dataReviewById.fraudCommitedDate
          ? moment(this.dataReviewById.fraudCommitedDate).format("DD-MMM-YYYY")
          : null;
        this.fraudKnownDate = this.dataReviewById.fraudKnownDate
          ? moment(this.dataReviewById.fraudKnownDate).format("DD-MMM-YYYY")
          : null;
        this.fraudAttList = this.dataReviewById.fraudAttList;

        this.rootCauseApi = this.dataReviewById.rootCauseList;
        this.loadStatusRoot();

        this.fraudCommitedDateTo = this.dataReviewById.fraudCommitedDateTo
          ? moment(this.dataReviewById.fraudCommitedDateTo).format("DD-MMM-YYYY")
          : null;

        this.handleGetLossList(this.dataReviewById.detailLossList);
      });
    },

    handleSaveAsDraft() {
      let detailLossList = [].concat(...this.pembiayaanFiktifList);
      this.saveReview({
        detailLossList: detailLossList,
        fraudAttList: this.fraudAttList,
        fraudCommitedDate: this.fraudCommitedDate, //OK tanggal fraud dilakukan
        fraudControl: this.fraudControl, //OK kontrol yang tidak berjalan
        fraudDetail: this.fraudDetail, // Detail / Cara Fraud Dilakukan (lokasi kerja terjadinya fraud ??)
        fraudFactor: this.fraudFactor, //OK Tujuan Fraud
        fraudFactorDesc: this.fraudFactorDesc, //OK Jika Tujuan Fraud Lainnya
        fraudId: this.fraudId,
        fraudKnownDate: this.fraudKnownDate, //OK tanggal fraud diketahui
        fraudMthdList: this.modusOperandiList,
        fraudPurpose: this.fraudPurpose, //OK Hari Kecurangan digunakan digunakan untuk apa
        fraudReason: this.fraudReason, //OK Alasan Pelaku melakukan fraud
        investigationDelegatorPhone: this.investigationDelegatorPhone,
        investigationStatus: this.investigationStatus, //OK Investigation Status / Status Review
        note: this.note, //OK
        violationType: this.violationType, //OK jenis pelanggaran
        violationTypeDesc: this.violationTypeDesc, //OK jika jenis pelanggaran lainnya
      })
        .then((response) => {
          this.$router.push("/review-controller");
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleSubmitReview() {
      this.submitReviewProcess({
        afmReviewer: this.afmReviewer,
        fraudId: this.fraudId,
        kfPlantDate: moment(this.kfPlantDate, "MMMM YYYY").format("yyyy-MM-DD"),
      })
        .then((response) => {
          this.$router.push("/review-controller");
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },

  computed: {
    fraudId: function () {
      return this.$route.params.fraudId;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    allInquiryEmployee() {
      if (Object.values(this.$store.getters.allInquiryEmployee).length != 0) {
        return Object.values(this.$store.getters.allInquiryEmployee).filter(
          (x) => x.roleCd == "AFM_REVIEWER"
        );
      } else {
        return [];
      }
    },
    allInquiryEmployeeAfm() {
      return Object.values(this.$store.getters.allInquiryEmployeeAfm);
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
  },
};
</script>

<style></style>