<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-5 mb-1">
      <h1 class="page-title">Pengguna</h1>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <p class="label-text-field" color="primary">Cari</p>
        <v-text-field label="" class="rp mb-0" hide-details="auto" v-model="searchValue" outlined placeholder="Cari..."
          dense prepend-inner-icon="mdi-magnify"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="label-text-field" color="primary">Status</p>
        <v-select :items="parameterByType" item-text="paramName" item-value="paramCd" label="" class="rp mb-0"
          hide-details="auto" v-model="status" outlined dense clearable placeholder="Semua"
          @click="loadUserStatus"></v-select>
      </v-col>

      <v-col>
        <p class="transparent--text" color="primary"></p>
        <v-row>
          <v-btn @click="search" class="elevation-0 text-capitalize ml-3 mt-2" cols="12" color="primary">
            <span class="white--text">Cari</span></v-btn>
          <v-btn class="elevation-0 text-capitalize ml-3 mt-2 primary--text" depressed outlined color="primary"
            cols="12" @click="reset">
            <span class="orange--text">Reset</span></v-btn>
        </v-row>
      </v-col>
      <v-col class="text-right">
        <p class="transparent--text" color="primary"></p>
        <v-btn class="elevation-0 mt-2" color="primary" :to="`/pengguna/form/add`">
          <v-icon left>
            mdi-plus
          </v-icon>
          Tambah
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="18">
        <div class="pf">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-data-table mobile-breakpoint="0" :headers="headers" :items="allInquiryUser" :items-per-page="5"
                    :header-props="{ sortIcon: null }" disable-sort><template v-slot:item="allUser">
                      <tr>
                        <td>{{ allUser.item.nik }}</td>
                        <td>{{ allUser.item.fullName }}</td>
                        <td>{{ allUser.item.position }}</td>
                        <td>{{ allUser.item.organization }}</td>
                        <td>{{ allUser.item.roleName }}</td>
                        <td>
                          {{
                              allUser.item.status
                          }}
                        </td>
                        <td align="center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn small text class="text-capitalize black--text px-0" v-on="on" v-bind="attrs"
                                :to="`/pengguna/${allUser.item.nik}/form/edit`">
                                <v-icon aria-hidden="false" style="font-size: 20px" color="#F5821F">
                                  mdi-square-edit-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-dialog v-model="dialogDel[allUser.item.nik]" persistent :key="allUser.item.nik"
                            width="unset">
                            <template v-slot:activator="{ on: dialog, attrs }">
                              <v-tooltip bottom slot="activator">
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn small text class="text-capitalize black--text px-0"
                                    v-on="{ ...dialog, ...tooltip }" v-bind="attrs">
                                    <v-icon aria-hidden="false" style="font-size: 18px" color="#F5821F">
                                      mdi-delete-outline
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </template>
                            <div class="dialogDelete text-center">
                              <v-sheet>
                                <v-icon class="icon" @click="
                                  dialogDel[
                                  allUser.item.nik
                                  ] = false
                                " style="float: right">mdi-close</v-icon>
                                <img src="@/assets/Warning.svg" alt="" />
                                <div class="pDialog">
                                  Hapus Pengguna?
                                  <p>
                                    {{ allUser.item.fullName }}
                                  </p>
                                </div>
                                <v-btn class="paramDialogBtn ma-1" color="primary" text @click="
                                  $set(dialogDel, allUser.item.nik, false)
                                ">Batalkan</v-btn>
                                <v-btn class="paramDialogDelBtn ma-1" color="primary" text @click="
                                  removeUser(allUser.item.nik),
                                  $set(dialogDel, allUser.item.nik, false)
                                ">Hapus</v-btn>
                              </v-sheet>
                            </div>
                          </v-dialog>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      page: 1,
      perPage: 5,
      totalData: null,
      countData: null,
      dialogDel: {},
      dialogEndNow: {},
      dialog: false,
      status: "",
      items: [],
      headers: [
        {
          text: "NIK",
          align: "center",
          width: "140px",
        },
        {
          text: "Nama",
          align: "center",
          width: "150px",
        },
        {
          text: "Posisi",
          align: "center",
          width: "150px",
        },
        {
          text: "Organisasi",
          align: "center",
          width: "120px",
        },
        {
          text: "Role",
          align: "center",
          width: "200px",
        },
        {
          text: "Status",
          align: "center",
          width: "100px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
        },
      ],
      statusSearch: false,
      searchValueSearch: "",
      searchValue: "",
    };
  },
  async mounted() {
    this.load();
    this.loadUserStatus();
  },
  created() {
    this.requiredFeature('PENGGUNA')
    this.changeTitleApp("Pengguna");
  },
  methods: {
    ...mapActions(["changeTitleApp", "getInquiryUser"]),

    reset: function () {
      this.searchValue = "";
      this.status = "";
      this.load();
    },

    load() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = this.searchValue;
      this.$store
        .dispatch("getInquiryUser", { pageNumber, pageSize, searchValue })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          console.log(err);
          this.$store.state.inquiryUser = [];
        });
    },

    loadUserStatus() {
      let paramType = "USER_STATUS";
      this.$store
        .dispatch("getParameterByParamType", { paramType })
        .then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        })
        .catch((err) => {
          if (!err.response) {
            this.loading = false;
          } else {
            switch (err.response.status) {
              default:
                this.$store.commit("setShowSnackbar", {
                  snackbarMsg: err.response.data.message,
                  snackbarColor: "red",
                });
                this.errorMessages = err.response.data.message;
                break;
            }
          }
        });
    },

    search() {
      let pageNumber = 0;
      let pageSize = 1000;
      let searchValue = this.searchValue;
      let status = this.status;
      this.$store
        .dispatch("getInquiryUser", {
          pageNumber,
          pageSize,
          searchValue,
          status,
        })
        .then((res) => {
          this.totalData = res.data.data.length;
          if (this.searchValue == "") {
            this.statusSearch = false;
          } else {
            this.statusSearch = true;
          }
        })
        .catch(() => {
          this.$store.state.inquiryUser = [];
        });
      this.page = 1;
    },

    removeUser(id) {
      let nik = id;
      this.$store
        .dispatch("deleteUser", { nik })
        .then((response) => {
          this.load();
          this.page = 1;
          this.$helpers.handleSuccess(response);
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
  },
  computed: {
    allInquiryUser() {
      return Object.values(this.$store.getters.allInquiryUser);
    },
    allInquiryParameter() {
      return Object.values(this.$store.getters.allInquiryParameter);
    },
    parameterByType() {
      return Object.values(this.$store.getters.parameterByType);
    },
  },
};
</script>







<style src="./User.scss" lang="scss" />
