<template> 
  <v-container fluid>
    <v-dialog fullscreen v-model="expandPembiayaanFiktif" persistent>
      <v-card>
        <v-col>
          <v-card-text>
            <v-icon class="icon" @click="expandPembiayaanFiktif = false">mdi-close</v-icon>
          </v-card-text>
          <v-card-title>{{ lossChosen.paramName }}</v-card-title>
          <v-row no-gutters justify="center">
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FIKTIF' ||
              lossChosen.paramCd == 'LOSS_TYPE_SELURUH_PENCAIRAN'
              " :headers="headersPembayaranFiktif1Expand" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr>
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.plafond"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.outstandingPokok" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                      v-model="data.item.outstandingMargin" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.tabunganBalance" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_SEBAGIAN_PENCAIRAN'"
              :headers="headersPembayaranFiktif1ExpandSebagian" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.pencairanDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.plafond"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.pembiayaanNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                      v-model="data.item.pembiayaanNasabahPercentage" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.pembiayaanKaryawan" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" suffix="%" dense outlined hide-details="auto"
                      v-model="data.item.pembiayaanKaryawanPercentage" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.outstandingTotal" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.outstandingNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.outstandingKaryawan" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.tabunganBalance" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PELUNASAN'"
              :headers="headersPembayaranFiktif2ExpandPelunasan" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td class="fixed_table_colum">
                    {{ data.index + 1 }}
                  </td>
                  <td class="fixed_table_colum2">
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td class="fixed_table_colum3">
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td class="fixed_table_colum4">
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.outstandingPelunasan" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.tabunganBalance" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_ANGSURAN'"
              :headers="headersPembayaranFiktif2ExpandSetoran" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.appIdNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.angsuranStatus" :items="statusAngsuranList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_TABUNGAN'"
              :headers="headersPembayaranFiktif2ExpandTabungan" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.nasabahName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.tabunganNo" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.transaksiType" :items="transaksiTypeList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.prsCollection" :items="prsCollectionList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_BRW'" :headers="headersPembayaranFiktif3ExpandBrw"
              :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.mthdDetailDesc" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.brwCategory" :items="brwCategoryList" item-text="paramName" item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_KAS_SOL'" :headers="headersPembayaranFiktif3ExpandKas"
              :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.sentraName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.kasSolCategory" :items="kasSolCategoryList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.fraudRepayDate" label="" prepend-inner-icon="mdi-calendar" readonly
                      outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_LEMARI_BESI'"
              :headers="headersPembayaranFiktif3ExpandLemari" :items="lossList[indexExpand]"
              :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.mthdDetailDesc" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_FPB'" :headers="headersPembayaranFiktif3ExpandFpb"
              :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.itemName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field v-model="data.item.fpbTransactionDate" label="" prepend-inner-icon="mdi-calendar"
                      readonly outlined dense hide-details="auto" class="mt-3" placeholder=""></v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table v-if="lossChosen.paramCd == 'LOSS_TYPE_PENCURIAN'" :headers="headersPembayaranFiktif4Expand"
              :items="lossList[indexExpand]" :header-props="{ sortIcon: null }" disable-sort>
              <template v-slot:item="data">
                <tr class="text-center">
                  <td>
                    {{ data.index + 1 }}
                  </td>
                  <td>
                    <v-text-field class="mt-3" dense outlined hide-details="auto" persistent-placeholder
                      v-model="data.item.itemName" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete class="mt-3" dense outlined persistent-placeholder hide-details="auto"
                      v-model="data.item.pelakuPencurian" :items="pelakuPencurianList" item-text="paramName"
                      item-value="paramCd">
                    </v-autocomplete>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.potentialLoss" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryNasabah" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto"
                      v-model="data.item.recoveryBranch" readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field class="mt-3" prefix="Rp" dense outlined hide-details="auto" v-model="data.item.netFraud"
                      readonly>
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="
                      openPelakuDialog(
                        data.item.detailLossDistList,
                        data.item.netFraud
                      )
                      ">Pelaku</v-btn>
                  </td>
                  <td>
                    <v-dialog v-model="dialogKeterangan[data.item.netFraud]" :key="data.index" persistent width="600">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="primary--text" v-bind="attrs" v-on="on">Keterangan
                        </v-btn>
                      </template>
                      <div class="text-center">
                        <v-card>
                          <v-card-text>
                            <v-icon class="icon" @click="
                              $set(
                                dialogKeterangan,
                                data.item.netFraud,
                                false
                              )
                              " style="float: right; margin-top: 12px">mdi-close</v-icon>
                          </v-card-text>
                          <v-card-title>Keterangan</v-card-title>
                          <v-card-text>
                            <v-textarea class="mb-0" hide-details="auto" outlined dense placeholder=""
                              v-model="data.item.notes" readonly></v-textarea>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </td>
                  <td>
                    <v-btn text class="primary--text" @click="openDocumentDialog(data.item.detailLossDocList)">Document
                      Image</v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-row no-gutters class="header d-flex mt-4 mb-1">
      <p class="titlet">Investigasi Checker</p>
      <span class="titlet"></span>
      <p class="subTitle">Proses</p>
    </v-row>
    <v-col>
      <v-card rounded="lg">
        <v-tabs v-model="tab" center-active>
          <v-tab href="#data-investigator">Data Investigator</v-tab>
          <v-tab href="#detail-laporan" @click="handleDetailLaporan">Detail Laporan</v-tab>
          <v-tab href="#verifikasi-fraud" @click="handleVerifikasiFraud">Verifikasi Fraud</v-tab>
          <v-tab href="#investigasi-fraud" @click="handleInvestigasiFraud">Investigasi fraud</v-tab>
          <v-tab href="#tab-laporanPelengkap" v-if="delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER'" @click="handleLaporanPelengkap">
            Laporan Pelengkap
          </v-tab>

          <v-tab-item value="data-investigator">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="6">
                  <h7 class="label-text-field"> Nama Investigator </h7>
                  <v-text-field hide-details="auto" dense outlined v-model="picNamaInvestigator" disabled></v-text-field>
                  <h7 class="label-text-field"> Jabatan/Posisi Investigator</h7>
                  <v-text-field hide-details="auto" dense outlined v-model="picJabatanInvestigator"
                    disabled></v-text-field>
                  <h7 class="label-text-field"> No. Telepon Investigator </h7>
                  <v-text-field hide-details="auto" dense outlined v-model="picPhoneInvestigator" disabled></v-text-field>
                </v-col>
                <v-col cols="6">
                  <h7 class="label-text-field"> NIK Investigator </h7>
                  <v-text-field hide-details="auto" dense outlined v-model="picNikInvestigator" disabled></v-text-field>
                  <h7 class="label-text-field"> Email Investigator </h7>
                  <v-text-field hide-details="auto" dense outlined v-model="picEmailInvestigator" disabled></v-text-field>
                </v-col>
              </v-row>
              <v-col>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-checker`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
            <!-- <v-card flat>
          </v-card> -->
          </v-tab-item>
          <v-tab-item value="detail-laporan">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <h7 class="label-text-field"> No. Tiket JAGA </h7>
                  <v-text-field v-model="jagaNo" class="mb-0" hide-details="auto" outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Laporan</h7>
                  <v-text-field v-model="reportDateForm" label="" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                    v-on="on" readonly outlined filled dense hide-details="auto" class="mb-0"
                    placeholder=""></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary"> Jenis Fraud</h7>
                  <v-col align="left" style="margin-left: -12px">
                    <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" outlined disabled
                      color="primary" cols="12" style="
                        width: 112px;
                        height: 48px;
                        border: 1px solid #f5821f;
                        box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                        border-radius: 8px;
                      ">
                      <span class="primary--text">{{
                        reportJenisFraud
                      }}</span></v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Sumber Informasi Awal Temuan Fraud</h7>
                  <v-text-field hide-details="auto" dense outlined v-model="reportFraudInf" disabled></v-text-field>
                </v-col>
                <v-col>
                  <h7 class="label-text-field" color="primary">
                    Yang Terlibat
                  </h7>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table :headers="headersTerlibat" :items="fraudPelakuList" :items-per-page="5"
                        :header-props="{ sortIcon: null }" disable-sort></v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Lokasi Kerja Terjadinya Fraud</h7>
                  <v-text-field hide-details="auto" dense outlined v-model="fraudLocNameM" disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Kerugian Awal</h7>
                  <v-text-field v-model="lossKejadian" prefix="Rp" class="mb-0" hide-details="auto" outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Modus Operandi</h7>
                  <v-data-table :headers="headersModus" :items="modusOperandiList" :items-per-page="5"
                    :header-props="{ sortIcon: null }" disable-sort>
                    <template v-slot:item="data">
                      <tr>
                        <td>{{ data.index + 1 }}</td>
                        <td>
                          {{ data.item.fraudMthdName }}
                        </td>
                        <td>
                          {{ data.item.incidentName }}
                        </td>
                        <td>
                          {{ data.item.incidentDesc }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col>
                  <h7 class="label-text-field" color="primary"> Attachment</h7>
                  <v-col cols="12" v-for="item in attach" :key="item">
                    <a v-if="item.flowName == 'Pengaduan Fraud'" @click="handleDownloadAttachment(item)">{{
                      item.attachFileName }}</a>
                  </v-col>
                </v-col>
              </v-row>
              <v-col>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-checker`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="verifikasi-fraud">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <h7 class="label-text-field"> Verification Status </h7>
                  <v-col align="left" style="margin-left: -12px">
                    <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" outlined disabled
                      color="primary" cols="12" style="
                        width: 112px;
                        height: 48px;
                        border: 1px solid #f5821f;
                        box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                        border-radius: 8px;
                      ">
                      <span class="primary--text">{{
                        verifikasiStatus
                      }}</span></v-btn>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Dokumen Awal Pelaporan </h7>
                  <v-col>
                    <v-row>
                      <div v-for="(dokumen, index) in reportDocTypeList" :key="index">
                        <v-checkbox v-model="dokumen.reportDocType" :label="dokumen.reportDocTypeName" readonly value
                          class="px-2"></v-checkbox>
                      </div>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Nominal SP Pelaku</h7>
                  <v-text-field v-model="spPelaku" class="mb-0" hide-details="auto" outlined dense disabled
                    placeholder="0" prefix="Rp"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> SP Nasabah / Pihak Ketiga</h7>
                  <v-text-field v-model="spNasabah" class="mb-0" hide-details="auto" outlined dense disabled
                    placeholder="0" prefix="Rp"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> Manager Investigator</h7>
                  <v-text-field v-model="managerInvestigator" class="mb-0" hide-details="auto" outlined dense disabled
                    placeholder="0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field"> No HP Investigator</h7>
                  <v-text-field v-model="hanphoneInvestigator" class="mb-0" hide-details="auto" outlined dense disabled
                    placeholder="0"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <h7 class="label-text-field"> Didelegasi Oleh</h7>
                  <v-col>
                    <v-row class="ml-2px">
                      <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" outlined disabled
                        color="primary" cols="12" style="
                                    width: 170px;
                                    height: 56px;
                                    border: 1px solid #f5821f;
                                    box-shadow: 0px 10px 20px
                                      rgba(235, 127, 0, 0.1);
                                    border-radius: 8px;
                                  ">
                        <span class="primary--text">{{ delegasi }}</span></v-btn>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col cols="12">
                  <h7 class="label-text-field" color="primary">
                    Keterangan
                    <!-- <span class="red--text"> *</span> -->
                  </h7>
                  <v-textarea v-model="extFraudMthdDesc" class="mb-0" hide-details="auto" outlined disabled dense
                    placeholder=""></v-textarea>
                </v-col>
              </v-row>
              <v-col>
                <v-row>
                  <v-col class="text-right">
                    <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                      :to="`/investigasi-checker`" x-large>
                      <span class="orange--text">Back</span></v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="investigasi-fraud">
            <v-card-text>
              <v-row class="mx-1">

                <v-col align="left" cols="12">
                  <p class="label-text-field">Investigation Status</p>
                  <v-btn x-large class="elevation-0 text-capitalize ml-5 primary--text" outlined disabled color="primary"
                    cols="12" style="
                      width: 112px;
                      height: 48px;
                      border: 1px solid #f5821f;
                      box-shadow: 0px 10px 20px rgba(235, 127, 0, 0.1);
                      border-radius: 8px;
                    ">
                    <span class="primary--text">{{
                      investigationStatus
                    }}</span></v-btn>
                </v-col>
                <v-col cols="12" v-if="investigationStatusCode ===
                    'INVESTIGATION_STATUS_NOT_PROVEN'
                    ">
                  <h7 class="label-text-field"> Notes </h7>
                  <v-textarea v-model="note" class="mb-0" hide-details="auto" outlined disabled readonly dense
                    placeholder=""></v-textarea>
                </v-col>
                <v-col v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <h7 class="label-text-field"> Tujuan Fraud</h7>
                  <v-text-field hide-details="auto" dense outlined v-model="investigasiFraudFactor" disabled></v-text-field>
                </v-col>
                <v-col cols="12" v-if="investigasiFraudFactor == 'FRAUD_FACTOR_OTHER'
                  ">
                  <h7 class="label-text-field" color="primary">
                    Lainnya

                  </h7>
                  <v-text-field v-model="investigasiFraudFactorDesc" class="mb-0" hide-details="auto" outlined dense
                    placeholder="Jelaskan secara rinci" disabled></v-text-field>
                </v-col>

                <!-- ROOT CAUSE -->
                <v-col cols="12" v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <h7 class="label-text-field" color="primary"> Root Cause </h7>
                  <v-row style="width: 100%">
                    <v-col cols="12">
                      <v-data-table hide-default-footer :headers="headersRoot" :items="rootCouse" :items-per-page="10"
                        :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data">
                          <tr>
                            <td align="center">{{ data.index + 1 }}</td>
                            <td align="center">
                              {{ data.item.rootCauseName }}
                            </td>
                            <td>
                              <v-select :items="rootCauseStatus[data.index]" item-text="rootCauseName"
                                item-value="rootCauseDesc" v-model="data.item.rootCauseList" label="" class="mt-3 mb-3"
                                hide-details="auto" outlined dense placeholder="" multiple chips :rules="mandatoryList[data.index] == 'Mandatory' &&
                                    investigationStatus ==
                                    'INVESTIGATION_STATUS_PROVEN'
                                    ? rootCauseRules
                                    : noRootCauseRules
                                  " readonly>
                              </v-select>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Fraud Dilakukan</h7>
                  <v-text-field v-model="fraudCommitDate" label="" prepend-inner-icon="mdi-calendar" readonly outlined
                    filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
                <v-col cols="6" v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <h7 class="label-text-field" color="primary">
                    Tanggal Fraud Dikeahui</h7>
                  <v-text-field v-model="fraudKnowDate" label="" prepend-inner-icon="mdi-calendar" readonly outlined
                    filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
                <v-col cols="6" v-if="investigationStatusCode === 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <h7> Tanggal Terakhir Fraud </h7>
                  <v-text-field v-model="fraudRecovery" label="" prepend-inner-icon="mdi-calendar" readonly outlined
                    filled dense hide-details="auto" class="mb-0" placeholder=""></v-text-field>
                </v-col>
                <v-col cols="12" v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN'
                  ">
                  <p class="label-text-field">Summary Status</p>
                  <v-row style="width: 100%">
                    <v-col cols="12">
                      <v-data-table :headers="headersDaftarKerugian" :items="daftarKerugianList" disable-pagination
                        hide-default-footer :header-props="{ sortIcon: null }" disable-sort>
                        <template v-slot:item="data" item-key="pelakuId">
                          <tr v-if="!isMerged(data.item, data.index)">
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ getUniqueIndex(data.item,
                              data.index) }}</td>
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuNik }}</td>
                            <td :rowspan="getMergedRowspan(data.item, data.index)">{{ data.item.pelakuName }}</td>
                            <td>{{ data.item.modusName }}</td>
                            <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                            <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                            <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                            <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                            <td>{{ formatNumber(data.item.recovery) }}</td>
                            <td>{{ formatNumber(data.item.netFraud) }}</td>
                            <td>
                              <div v-if="data.item.pelakuUtama == 'N'">
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                  cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                  <span class="orange--text ml-2">Download List Nasabah</span>
                                </v-btn>
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Bukti</span>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                          <tr v-else>
                            <td>{{ data.item.modusName }}</td>
                            <td>{{ data.item.noa === 0 ? "" : formatNumber(data.item.noa) }}</td>
                            <td>{{ data.item.plafond === 0 ? "" : formatNumber(data.item.plafond) }}</td>
                            <td>{{ data.item.tabungan === 0 ? "" : formatNumber(data.item.tabungan) }}</td>
                            <td>{{ data.item.potentialLoss === 0 ? "" : formatNumber(data.item.potentialLoss) }}</td>
                            <td>{{ formatNumber(data.item.recovery) }}</td>
                            <td>{{ formatNumber(data.item.netFraud) }}</td>
                            <td>
                              <div v-if="data.item.pelakuUtama == 'N'">
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center"
                                  cols="12" color="white" @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Surat Pernyataan</span>
                                </v-btn>
                              </div>
                              <div v-else>
                                <v-btn v-if="data.item.nasabahAttName != null && data.item.nasabahAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'nasabah')">
                                  <span class="orange--text ml-2">Download List Nasabah</span>
                                </v-btn>
                                <v-btn v-if="data.item.evidenceAttName != null && data.item.evidenceAttName !== ''" class="elevation-0 text-capitalize text-center" cols="12" color="white"
                                  @click="handleDownloadFilePelaku(data.item, 'bukti')">
                                  <span class="orange--text ml-2">Download Bukti</span>
                                </v-btn>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <template slot="body.append">
                          <tr>
                            <td colspan="4" align="center">Total</td>
                            <td>{{sumField('noa') === 0 ? "" : formatNumber(sumField('noa'))}}</td>
                            <td>{{sumField('plafond') === 0 ? "" : formatNumber(sumField('plafond'))}}</td>
                            <td>{{sumField('tabungan') === 0 ? "" : formatNumber(sumField('tabungan'))}}</td>
                            <td>{{sumField('potentialLoss') === 0 ? "" : formatNumber(sumField('potentialLoss'))}}</td>
                            <td>{{sumField('recovery') === 0 ? "" : formatNumber(sumField('recovery'))}}</td>
                            <td>{{sumField('netFraud') === 0 ? "" : formatNumber(sumField('netFraud'))}}</td>
                        </tr>
                        </template>
                      </v-data-table>
                      <v-snackbar v-model="showSnackbar" :color="snackbarColor">
                        {{ snackbarMsg }}
                        <template v-slot:action="{ attrs }">
                          <v-btn text small v-bind="attrs" @click="showSnackbar = false">
                            X
                          </v-btn>
                        </template>
                      </v-snackbar>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN' &&
              reportStatus == 'REPORT_STATUS_REQUEST_AFM_MANAGER' &&
              delegasi != 'AFM Manager'
              ">
              Laporan Pelengkap
            </v-card-title>
            <v-card-text v-if="investigationStatusCode == 'INVESTIGATION_STATUS_PROVEN' &&
              reportStatus == 'REPORT_STATUS_REQUEST_AFM_MANAGER' &&
              delegasi != 'AFM Manager'
              ">
              <h7 class="label-text-field" color="primary"> Attachment </h7>
              <v-col cols="12" v-for="item in attachment" :key="item">
                <a v-if="item.flowName == 'Investigasi AFM'" @click="handleDownloadAttachment(item)">
                  {{ item.attachFileName }}
                </a>
              </v-col>
            </v-card-text>
            
            <v-row>
              <!-- Berdasarkan User Login -->
              <v-col v-if="
                (picApprUser === userLogin ||
                picBc === userLogin ||
                picDh === userLogin) &&
                reportStatus !== 'REPORT_STATUS_INVESTIGATED' &&
                reportStatus !== 'REPORT_STATUS_INVESTIGATION_OUTSTANDING' &&
                reportStatus !== 'REPORT_STATUS_DELEGATE_INVESTIGATOR' &&
                reportStatus !== 'REPORT_STATUS_INVESTIGATION_REVISED' &&
                reportStatus !== 'REPORT_STATUS_RE_INVESTIGATE' &&
                reportStatus !== 'REPORT_STATUS_APPROVE_QA_MANAGER' &&
                reportStatus !== 'REPORT_STATUS_APPROVE_QA_OPERATION' &&
                reportStatus !== 'REPORT_STATUS_APPROVE_AFM_MANAGER' &&
                reportStatus !== 'REPORT_STATUS_APPROVE_BC' &&
                reportStatus !== 'REPORT_STATUS_APPROVE_DH' &&
                reportStatus !== 'REPORT_STATUS_RETURN_QA_MANAGER' &&
                reportStatus !== 'REPORT_STATUS_RETURN_QA_OPERATION' &&
                reportStatus !== 'REPORT_STATUS_RETURN_AFM_MANAGER' &&
                reportStatus !== 'REPORT_STATUS_RETURN_BC' &&
                reportStatus !== 'REPORT_STATUS_RETURN_DH' &&
                reportStatus !== 'REPORT_STATUS_DELEGATE_REVIEWER' &&
                reportStatus !== 'REPORT_STATUS_REVIEWED' &&
                reportStatus !== 'REPORT_STATUS_KF_DONE' &&
                reportStatus !== 'REPORT_STATUS_INVESTIGATION_SUBMIT' &&
                ((reportStatus === 'REPORT_STATUS_REQUEST_QA_MANAGER' && userRole.includes('QA_MANAGER')) ||
                (reportStatus === 'REPORT_STATUS_REQUEST_AFM_MANAGER' && userRole.includes('AFM_MANAGER')) ||
                (reportStatus === 'REPORT_STATUS_REQUEST_BC' && userRole.includes('PIC_BC')) ||
                (reportStatus === 'REPORT_STATUS_REQUEST_DH' && userRole.includes('PIC_DH')))" class="text-right">
                <v-btn
                  class="elevation-0 text-capitalize mr-3 primary--text"
                  depressed
                  outlined
                  color="primary"
                  :to="`/investigasi-checker`"
                  x-large
                >
                  <span class="orange--text">Back</span>
                </v-btn>
                
                <v-btn
                  class="elevation-0 text-capitalize mr-3 primary--text"
                  depressed
                  outlined
                  color="primary"
                  @click="openHandleRevice"
                  x-large
                >
                  <span class="orange--text">Revise</span>
                </v-btn>

                <!-- Menampilkan Popup 'Permintaan Approval' di Investigasi Checker Pejabat 1 -->
                <v-dialog v-if="
                  (this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER') || 
                  (this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER')" v-model="dialogPicAppr" max-width="900px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" 
                      v-on="on"
                      class="elevation-0 text-capitalize mr-3"
                      depressed
                      color="primary"
                      x-large>
                      <span class="white--text">Approve</span>
                    </v-btn>
                  </template>
                  
                  <v-card>
                    <v-card-title class="black--text">
                      Permintaan Approval
                    </v-card-title>

                    <v-row class="mx-1" justify="end">
                      <!-- No Jaga -->
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">No JAGA</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="jagaNo" 
                              v-bind="attrs" 
                              v-on="on" 
                              class="mb-0"
                              hide-details="auto" 
                              outlined dense placeholder="Masukan No JAGA"
                              :disabled="reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER'">
                            </v-text-field>
                          </template>
                        </v-dialog>
                      </v-col>

                      <!-- Pejabat 1 -->
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Pejabat 1</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog v-model="dialogPicApprBC" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="picApprName" 
                                v-bind="attrs" 
                                v-on="on" 
                                class="mb-0" 
                                hide-details="auto"
                                outlined 
                                dense 
                                placeholder="Masukan Pejabat 1" 
                                @click="loadPicAppr">
                            </v-text-field>
                          </template>
                          
                          <v-card>
                            <v-card-text>
                              <v-icon 
                                class="icon"
                                style="float: right; margin-top: 12px"
                                @click="dialogPicApprBC = false">mdi-close
                              </v-icon>
                            </v-card-text>

                            <v-card-title class="black--text">
                              Pejabat 1
                            </v-card-title>

                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicAppr"
                                  dense outlined placeholder="Masukan nama Pejabat 1"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicAppr" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>

                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicBh"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicAppr">
                                  <tr>
                                    <td align="center">
                                      {{ allPicAppr.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicAppr(allPicAppr.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>

                      <!-- approval for Pejabat 1 -->
                      <v-btn 
                        v-if="reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER'"
                        x-large 
                        depressed 
                        class="elevation-0 text-capitalize mr-3 mb-3 primary--text" 
                        color="primary"
                        @click="handleApprove">
                        <span class="white--text">Request for Approval</span>
                      </v-btn>

                      <!-- Cancel -->
                      <v-btn 
                        class="mr-3 elevation-0" 
                        height="50px" 
                        @click="dialogPicAppr = false">
                        <span>Cancel</span>
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-dialog>

                <!-- Menampilkan Popup 'Permintaan Approval' di Investigasi Checker Pejabat 2 -->
                <v-dialog v-if="
                  this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && reportStatus == 'REPORT_STATUS_REQUEST_BC' || 
                  this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && reportStatus == 'REPORT_STATUS_REQUEST_BC'" v-model="dialogPicAppr" max-width="900px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" 
                      v-on="on"
                      class="elevation-0 text-capitalize mr-3"
                      depressed
                      color="primary"
                      x-large>
                      <span class="white--text">Approve</span>
                    </v-btn>
                  </template>
                  
                  <v-card>
                    <v-card-title class="black--text">
                      Permintaan Approval
                    </v-card-title>

                    <v-row class="mx-1" justify="end">
                      <!-- No Jaga -->
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">No JAGA</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="jagaNo" 
                              v-bind="attrs" 
                              v-on="on" 
                              class="mb-0"
                              hide-details="auto" 
                              outlined dense placeholder="Masukan No JAGA"
                              :disabled="reportStatus == 'REPORT_STATUS_REQUEST_BC'">
                            </v-text-field>
                          </template>
                        </v-dialog>
                      </v-col>

                      <!-- Pejabat 2 -->
                      <v-col cols="12">
                        <v-row class="verifStat ml-0">
                          <p class="label-text-field">Pejabat 2</p>
                          <span class="ml-2 mt-1">Wajib</span>
                        </v-row>
                        
                        <v-dialog v-model="dialogPicApprBC" max-width="900px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-model="picApprName" 
                                v-bind="attrs" 
                                v-on="on" 
                                class="mb-0" 
                                hide-details="auto"
                                outlined 
                                dense 
                                placeholder="Masukan Pejabat 2" 
                                @click="loadPicAppr">
                            </v-text-field>
                          </template>
                          
                          <v-card>
                            <v-card-text>
                              <v-icon 
                                class="icon"
                                style="float: right; margin-top: 12px"
                                @click="dialogPicApprBC = false">mdi-close
                              </v-icon>
                            </v-card-text>

                            <v-card-title class="black--text">
                              Pejabat 2
                            </v-card-title>

                            <v-card-text class="mt-5">
                              <span class="black--text">NIK Atau Nama</span>
                              
                              <v-row>
                                <v-text-field label="" class="mb-0 ml-3" hide-details="auto" v-model="searchValuePicAppr"
                                  dense outlined placeholder="Masukan nama Pejabat 2"
                                  prepend-inner-icon="mdi-magnify"></v-text-field>
                                <v-btn @click="loadPicAppr" class="elevation-0 text-capitalize ml-5" color="primary">
                                  <span class="white--text">Cari</span>
                                </v-btn>
                              </v-row>

                              <v-data-table class="mt-5 mb-5" :headers="headersPic" :items="allInquiryPicDh"
                                :items-per-page="5" :header-props="{ sortIcon: null }" disable-sort>
                                <template v-slot:item="allPicAppr">
                                  <tr>
                                    <td align="center">
                                      {{ allPicAppr.item.nik }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.name }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.position }}
                                    </td>
                                    <td align="center">
                                      {{ allPicAppr.item.organization }}
                                    </td>
                                    <td align="center">
                                      <v-btn small text class="text-capitalize black--text px-0"
                                        @click="pilihPicAppr(allPicAppr.item)">
                                        <span class="orange--text">Pilih</span>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>

                      <!-- approval for Pejabat 2 -->
                      <v-btn 
                        v-if="reportStatus == 'REPORT_STATUS_REQUEST_BC'"
                        x-large 
                        depressed 
                        class="elevation-0 text-capitalize mr-3 mb-3 primary--text" 
                        color="primary"
                        @click="handleApprove">
                        <span class="white--text">Request for Approval</span>
                      </v-btn>

                      <!-- Cancel -->
                      <v-btn 
                        class="mr-3 elevation-0" 
                        height="50px" 
                        @click="dialogPicAppr = false">
                        <span>Cancel</span>
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-dialog>

                <!-- Approve Pejabat 2 di Investigasi Checker -->
                <v-btn v-if="
                    this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && reportStatus == 'REPORT_STATUS_REQUEST_DH' || 
                    this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && reportStatus == 'REPORT_STATUS_REQUEST_DH' || 
                    this.delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER' && reportStatus == 'REPORT_STATUS_REQUEST_AFM_MANAGER'"
                  class="elevation-0 text-capitalize mr-3"
                  depressed
                  color="primary"
                  @click="handleApprove"
                  x-large>
                  <span class="white--text">Approve</span>
                </v-btn>
              </v-col>

              <!-- Tidak Berdasarkan User Login -->
              <v-col v-else class="text-right">
                <v-btn
                  class="elevation-0 text-capitalize mr-3 primary--text"
                  depressed
                  outlined
                  color="primary"
                  :to="`/investigasi-checker`"
                  x-large
                >
                  <span class="orange--text">Back</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="tab-laporanPelengkap">
            <v-card-text>
              <v-row class="mx-1">
                <v-col cols="12">
                  <p class="label-text-field">Attachment</p>
                  <v-col cols="12" v-for="item in attachmentAfm" :key="item">
                    <a v-if="item.flowName == 'Investigasi AFM'" @click="handleDownloadAttachment(item)">{{
                      item.attachFileName }}</a>
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-dialog width="800" v-model="noteDialog">
      <v-card>
        <v-card-title>Keterangan</v-card-title>
        <v-card-text>
          <v-textarea class="mb-0" hide-details="auto" outlined dense readonly placeholder=""
            v-model="notesRevice"></v-textarea>
        </v-card-text>
        <v-col>
          <v-row>
            <v-col> </v-col>
            <v-btn right class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="$set(false)">
              <span class="white--text">Cancel</span></v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="keteranganRevice">
      <v-form ref="keteranganRevise">
        <v-card>
          <v-card-title>Keterangan</v-card-title>
          <v-card-text>
            <v-textarea :rules="keteranganReviseRules" class="mb-0" hide-details="auto" outlined dense placeholder=""
              v-model="notesRevice"></v-textarea>
          </v-card-text>
          <v-col>
            <v-row>
              <v-col> </v-col>
              <v-btn right class="elevation-0 text-capitalize mr-3" depressed color="primary" @click="handleRevise">
                <span class="white--text">Save</span></v-btn>
              <v-btn class="elevation-0 text-capitalize mr-3 primary--text" depressed outlined color="primary"
                @click="keteranganRevice = false">
                <span class="orange--text">Back</span></v-btn>
            </v-row>
          </v-col>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="documentDialog">
      <v-card>
        <v-card-title>Download Image</v-card-title>
        <p class="text--white ml-3">Format File JPG atau PNG maksimum 2MB</p>
        <v-card-text>
          <v-row v-for="(document, index) in isiDocumentList" :key="index">
            <v-col> {{ index + 1 }} - {{ document.documentTypeName }} </v-col>

            <v-btn v-if="document.attachFilePath" class="elevation-0 text-capitalize text-center" cols="12" color="white"
              @click="handleDownloadAttachment(document)">
              <v-icon aria-hidden="false" color="orange">mdi-download</v-icon>
              <span class="orange--text ml-2">Download</span>
            </v-btn>
            <v-text-field class="mt-1" dense outlined hide-details="auto" persistent-placeholder readonly
              v-model="document.notes">
            </v-text-field>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="800" v-model="pelakuFraudDialog">
      <v-card>
        <v-card-title>Pelaku Fraud</v-card-title>
        <v-card-text>Total Fraud:

          <span class="red--text">{{ netFraudText }}</span>
        </v-card-text>
        <v-card-text>
          <v-data-table hide-default-footer :headers="headersPelakuFraud" :items="isiPelakuList" :items-per-page="5"
            :header-props="{ sortIcon: null }" disable-sort>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col>
      <v-card rounded="lg">
        <v-card-text>
          <v-row class="mx-1">
            <v-col>
              <h7 class="label-text-field"> History </h7>
              <v-data-table :headers="headersHistory" :items="fraudLogList" :header-props="{ sortIcon: null }"
                disable-sort></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
var dateFormat = require("dateformat");

export default {
  data() {
    return {
      jenisPelanggaranListToSave: [],
      headersJenisPelanggaran: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          width: "200px",
        },
        {
          text: "Jenis Pelanggaran",
          align: "center",
          width: "250px",
        },
        {
          text: "Deskripsi",
          align: "center",
          width: "220px",
        },
      ],
      rootCauseList: [],
      headersRoot: [
        {
          text: "No. ",
          align: "center",
          width: "50px",
        },
        {
          text: "Root Cause",
          align: "center",
          width: "200px",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "620px",
        },
      ],
      headersHistory: [
        {
          text: "No.",
          align: "center",
          value: "seqNo",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Date",
          align: "center",
          value: "processDate",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Processed By",
          align: "center",
          value: "processBy",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Status",
          align: "center",
          value: "processStatusName",
          //   width: "150px",
          class: "tableHeader",
        },
        {
          text: "Process Comment",
          align: "center",
          width: "250px",
          value: "processComment",
          class: "tableHeader",
        },
      ],

      headersTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "assignmentCategory",
          width: "150px",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
        },
      ],
      headersModus: [
        {
          text: "No",
          align: "center",
          //   width: "50px",
        },
        {
          text: "Kode Modus",
          align: "center",
          //   width: "350",
        },
        {
          text: "Kronologi Kejadian",
          align: "center",
          //   width: "400px",
        },
        {
          text: "Deskripsi",
          align: "center",
          //   width: "100px",
        },
      ],

      headersPelakuFraud: [
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "",
          width: "150px",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
        },
        {
          text: "Net Fraud",
          align: "center",
          value: "netFraudDist",
          width: "150px",
        },
      ],

      headersSummaryStatus: [
        {
          text: "No.",
          align: "center",
          width: "50px",
        },
        {
          text: "Nama Karyawan",
          align: "center",
          width: "200px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "120px",
        },
        {
          text: "Status",
          align: "center",
          width: "120px",
        },
        {
          text: "NIK",
          align: "center",
          width: "126px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "300px",
        },
        {
          text: "Potensi Kerugian Awal",
          align: "center",
          width: "126px",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "126px",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "126px",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "126px",
        },
      ],

      // Accordion
      headersPembayaranFiktif1: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1Sebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Setoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Pelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2Tabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header4 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Janis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Brw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Kas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Lemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3Fpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif4: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "fixed_table_header tableHeader ",
        },
        {
          text: "Jenis Barang/Asset",
          align: "center",
          width: "150px",
          class: "fixed_table_header2 tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "fixed_table_header3 tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersYangTerlibat: [
        {
          text: "No",
          align: "center",
          value: "seqNo",

          width: "150px",
          class: "black--text",
        },
        {
          text: "NIK Pelaku",
          align: "center",
          value: "pelakuNik",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Pelaku Utama",
          align: "center",
          value: "pelakuUtama",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Nama Pelaku",
          align: "center",
          value: "pelakuName",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Jabatan Pelaku",
          align: "center",
          value: "",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Status Kepegawaian Pelaku",
          align: "center",
          value: "pelakuStatus",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Nama Lokasi Kerja Existing",
          align: "center",
          value: "pelakuPosition",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Status Penugasan",
          align: "center",
          value: "",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Tanggal Bergabung",
          align: "center",
          value: "pelakuJointDate",
          width: "150px",
          class: "black--text",
        },
        {
          text: "Pendidikan",
          align: "center",
          value: "pelakuEducation",
          width: "150px",
          class: "black--text",
        },
      ],
      headersPembayaranFiktif1Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Pokok",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Margin",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaran2Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Upload File",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif1ExpandSebagian: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pencairan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Plafond",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pembiayaan Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "%",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Total",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding Karyawan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandSetoran: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Angsuran",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif2ExpandPelunasan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. APPID",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Outstanding",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Saldo Tabungan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Pelunasan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],

      headersPembayaranFiktif2ExpandTabungan: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "No. Rekening",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "PRS/Collection",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandBrw: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandKas: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Nama Sentra",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Kategori",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Dilakukan Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandLemari: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Uraian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPembayaranFiktif3ExpandFpb: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Jenis Transaksi(Item)",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tanggal Transaksi",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Potensi Kerugian",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      daftarKerugianList: [],
      headersDaftarKerugian: [
        {
          text: "No",
          align: "center",
          width: "50px",
        },
        {
          text: "NIK",
          align: "center",
          width: "150px",
        },
        {
          text: "Nama",
          align: "center",
          width: "200px",
        },
        {
          text: "Modus Operandi",
          align: "center",
          width: "250px",
        },
        {
          text: "NOA",
          align: "center",
          width: "100px",
        },
        {
          text: "Plafond (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Tabungan (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Potensi Kerugian (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Recovery (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Actual Loss (Rp)",
          align: "center",
          width: "150px",
        },
        {
          text: "Rincian Daftar Kerugian",
          align: "center",
          width: "400px",
          colspan: "2",
        }
      ],
      headersPembayaranFiktif4Expand: [
        {
          text: "No",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Item Barang/Asset/Uang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Status Kepemilikan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Nasabah",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Recovery Cabang",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Net Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Pelaku Fraud",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Keterangan",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
        {
          text: "Document Image",
          align: "center",
          width: "150px",
          class: "tableHeader ",
        },
      ],
      headersPic: [
        {
          text: "NIK",
          align: "center",
          width: "130px",
        },
        {
          text: "Nama",
          align: "center",
          width: "180px",
        },
        {
          text: "Jabatan",
          align: "center",
          width: "180px",
        },
        {
          text: "Organisasi",
          align: "center",
          width: "170px",
        },
        {
          text: "Tindakan",
          align: "center",
          width: "116px",
        },
      ],
      options: {
        locale: "pt-BR",
        prefix: "Rp",
        suffix: "",
        length: 14,
        precision: 0,
      },
      dataInvestigasiCheckId: null,

      //DATA INVESTIGATOR
      picNamaInvestigator: "",
      picJabatanInvestigator: "",
      picPhoneInvestigator: "",
      picNikInvestigator: "",
      picEmailInvestigator: "",

      // DETAIL LAPORAN
      currentDate: dateFormat(
        new Date().toISOString().substr(0, 10),
        "yyyy-mm-dd"
      ),
      menu1: false,
      reportDateForm: "",
      inputDateFormValPicker: false,
      reportJenisFraud: "",
      reportFraudInf: "",
      fraudPelakuList: [],
      fraudLocNameM: "",
      readMoney: true,
      kronoLogiList: [],
      modusOperandiList: [],
      sharepointLink: "",
      fraudLogList: [],
      attach: "",

      //VERIFIKASI STATUS
      verifikasiStatus: "",
      reportDocTypeList: [],
      spPelaku: "",
      spNasabah: "",
      managerInvestigator: "",
      hanphoneInvestigator: "",
      delegasi: "",
      extFraudMthdDesc: "",

      expandPembiayaanFiktif: false,
      lossList: [],
      pembiayaanFiktifList: [],
      detailPembiayaan: [],
      lossChosen: {
        paramValue: 5,
      },

      documentDialog: false,
      keteranganRevice: false,

      isiDocumentList: [],
      isiPelakuList: [],
      detailLoss: [],

      reportinvestigationDelegatorPosition: "",
      // dialog: {},
      isiKeteranganRevice: "",

      reportStatus: "",
      notesRevice: "",

      note: "",
      noteDialog: false,
      noteList: [],
      keteranganReviseRules: [(v) => !!v || "Catatan harus diisi"],

      rootCouse: [],
      rootCauseStatus: [],
      rootCauseApi: [],
      pelakuFraudList: [],
      detailLossToSaveAuto: [],
      detailLossToDeleteAuto: [],
      detailLossDistList: [],
      detailLossDocList: [],
      fraudIdCopy: "",
      fraudId: 0,
      panelIndex: [],
      dialogKeterangan: {},
      investigationStatusCode: "",
      tujuanFraudList: [],
      attachmentAfm: "",
      investigasiFraudFactor: "",
      investigasiFraudFactorDesc: "",
      jenisPelanggaranList: [],
      investigasiViolationType: "",
      searchValuePicAppr: "",
      approveDHBoolean: false,
      dialogPicAppr: false,
      dialogPicApprBC: false,
      flagLoadLaporan: false,
      flagLoadVerifikasi: false,
      flagLoadInvestigasi: false,
      flagLoadPelengkap: false,
      reportType: "",
      investigationDelegatorPosition: "",
      delegatorPosition: "",
    };
  },

  async created() {
    this.requiredFeature('INVESTIGASI_CHECKER')
    this.changeTitleApp("Investigasi Checker");

    this.handleInitialData();
  },

  mounted() { 
    
  },

  methods: {
    ...mapActions([
      "changeTitleApp",
      "getDataInvestigasiChekcId",
      "searchDataRinDafKerugianByFraudId",
      "getParameterByParamType",
      "submitInvestigasiCheck",
      "reviseChecker",
      "downloadAttachment",
      "downloadAttachment",
      "saveRincianDaftarKerugianAuto",
      "searchFraudPelaku",
      "downloadFilePelaku",
      "getInquiryLovPicBc",
      "getInquiryLovPicDh",
      "requestPICBC",
      "requestPICDH",
      "submitInvestigation",
      "getInitialData",
      "getDataInvestigatorByFraudId",
      "getFraudHistoryByFraudId",
      "getDetailLaporanByFraudId",
      "getYangTerlibatByFraudId",
      "getModusOperandiByFraudId",
      "getAttachmentByFraudId",
      "getVerifikasiFraudByFraudId",
      "getDokumenAwalByFraudId",
      "getDataInvestigasiFraudByFraudId",
      "getRootCauseByFraudId",
    ]),

    handleInitialData() {
      this.getInitialData({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.reportType = resp.data.data.reportType;
        this.delegatorPosition = resp.data.data.delegatorPosition;
        this.reportStatus = resp.data.data.reportStatus;
        this.userRole = this.$store.state.roleCodeFromLogin;

        this.handleDataInvestigator();
        this.handleFraudHistory();
      })
      .catch((err) => {
        console.log("handleInitialData ERR", err);
      });
    },

    handleDataInvestigator() {
      this.getDataInvestigatorByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.picNamaInvestigator = resp.data.data.investigatorName;
        this.picNikInvestigator = resp.data.data.investigatorNik;
        this.picJabatanInvestigator = resp.data.data.investigatorPosition;
        this.picEmailInvestigator = resp.data.data.investigatorEmail;
        this.picPhoneInvestigator = resp.data.data.investigatorPhone;
      })
      .catch((err) => {
        console.log("handleDataInvestigator ERR", err);
      });
    },

    handleFraudHistory() {
      this.getFraudHistoryByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.fraudLogList = resp.data.data;
      })
      .catch((err) => {
        console.log("handleFraudHistory ERR", err);
      });
    },

    handleDetailLaporan() {
      if (!this.flagLoadLaporan) {
        this.flagLoadLaporan = true;

        this.getDetailLaporan();
        this.getYangTerlibat();
        this.getModusOperandi();
        this.getAttachment();
      }
    },

    getDetailLaporan() {
      this.getDetailLaporanByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.jagaNo = resp.data.data.jagaNo;
        this.reportDateForm = moment(resp.data.data.tanggalLaporan).format("DD-MMM-YYYY");
        this.reportJenisFraud = resp.data.data.jenisFraudStr;
        this.reportFraudInf = resp.data.data.sumberInformasiStr;
        this.fraudLocNameM = resp.data.data.lokasiFraudNm;
        this.lossKejadian = this.humanFormat(resp.data.data.kerugianAwal);
      })
      .catch((err) => {
        console.log("getDetailLaporan ERR", err);
      });
    },

    getYangTerlibat() {
      this.getYangTerlibatByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.fraudPelakuList = resp.data.data;
      })
      .catch((err) => {
        console.log("getYangTerlibat ERR", err);
      });
    },

    getModusOperandi() {
      this.getModusOperandiByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.modusOperandiList = resp.data.data;
        for (var i = 0; i < this.modusOperandiList.length; i++) {
            this.kronologiList?.push([]);
            
            if (this.modusOperandiList[i].fraudMthd != null) {
              this.handleGetKronologiList(
                this.modusOperandiList[i].fraudMthd,
                i
              );
            }
          }
      })
      .catch((err) => {
        console.log("getModusOperandi ERR", err);
      });
    },

    getAttachment() {
      this.getAttachmentByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.attach = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachment ERR", err);
      });
    },

    handleVerifikasiFraud() {
      if (!this.flagLoadVerifikasi) {
        this.flagLoadVerifikasi = true;

        this.getVerifikasiFraud();
        this.getDokumenAwal();
      }
    },

    getVerifikasiFraud() {
      this.getVerifikasiFraudByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.verifikasiStatus = resp.data.data.verifikasiStatusNm; 
        this.spPelaku = resp.data.data.spPelaku;
        this.spNasabah = resp.data.data.spNasabah;
        this.managerInvestigator = resp.data.data.delegatorName;
        this.hanphoneInvestigator = resp.data.data.delegatorPhone;
        this.delegasi = resp.data.data.delegatorPositionNm;
        this.extFraudMthdDesc = resp.data.data.keterangan;
      })
      .catch((err) => {
        console.log("getVerifikasiFraud ERR", err);
      });
    },

    getDokumenAwal() {
      this.getDokumenAwalByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.reportDocTypeList = resp.data.data;
      })
      .catch((err) => {
        console.log("getDokumenAwal ERR", err);
      });
    },

    handleLaporanPelengkap() {
      if (!this.flagLoadPelengkap) {
        this.flagLoadPelengkap = true;

        this.getAttachmentAFM();
      }
    },

    getAttachmentAFM() {
      this.getAttachmentAFMByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.attachment = resp.data.data;
      })
      .catch((err) => {
        console.log("getAttachmentAFM ERR", err);
      });
    },

    handleInvestigasiFraud() {
      if (!this.flagLoadLaporan) {
        this.getDetailLaporan();
      }

      if (!this.flagLoadInvestigasi) {
        this.flagLoadInvestigasi = true;

        this.loadStatusRoot();
        this.getInvestigasiFraud();
        this.getRootCause();
        this.getSummaryStatus();
      }
    },

    getInvestigasiFraud() {
      this.getDataInvestigasiFraudByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        this.investigationStatusCode = resp.data.data.investigationStatus;
        this.investigationStatus = resp.data.data.investigationStatusNm;
        this.investigasiFraudFactor = resp.data.data.fraudFactorNm;
        this.fraudCommitDate = resp.data.data.fraudCommitedDt !== null ? moment(resp.data.data.fraudCommitedDt).format("DD-MMM-YYYY") : null;
        this.fraudKnowDate = resp.data.data.fraudKnownDt !== null ? moment(resp.data.data.fraudKnownDt).format("DD-MMM-YYYY") : null;
        this.fraudRecovery = resp.data.data.fraudCommitedDtTo !== null ? moment(resp.data.data.fraudCommitedDtTo).format("DD-MMM-YYYY") : null;

        this.picApprUser = resp.data.data.picAppr;
        this.picBc = resp.data.data.picBc;
        this.picDh = resp.data.data.picDh;
        this.userLogin = this.$store.state.username;
      })
      .catch((err) => {
        console.log("getInvestigasiFraud ERR", err);
      });
    },

    getRootCause() {
      this.getRootCauseByFraudId({
        fraudId: this.getId,
      })
      .then((resp) => {
        // console.log("getRootCause", resp.data.data);
        this.rootCauseApi = resp.data.data;
        
        if (this.rootCauseApi.length != 0) {
          this.rootCauseApi.map((item) => {
            let indexs = this.rootCouse.findIndex(
              (x) => x.rootCause === item.rootCause
            );
            this.rootCouse.splice(indexs, 1, item);
          });
        }
      })
      .catch((err) => {
        console.log("getRootCause ERR", err);
      });
    },

    getSummaryStatus() {
      this.searchFraudPelaku(this.getId)
        .then(response => {
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('getSummaryStatus ERR', error)
        })
    },

    handleDownloadFilePelaku(item, type) {
    this.downloadFilePelaku({
      pelakuDtlId: item.pelakuDetailId,
      type: type
    })
      .then((response) => {
        console.log(response, "response")
      })
      .catch((err) => {
        console.log("gagal download", err.response);
        this.$helpers.handleError(err);
        this.$store.commit("setShowSnackbar", {
                snackbarMsg: "Gagal mengunduh. File tidak ditemukan",
                snackbarColor: "red",
        })      
        return
      });  
    },
    sumField(key) {
        return this.daftarKerugianList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    isMerged(item, index) {
    if (index === 0) return false;
    return item.pelakuNik === this.daftarKerugianList[index - 1].pelakuNik && item.pelakuName === this.daftarKerugianList[index - 1].pelakuName;
    },
    getMergedRowspan(item, index) {
      let count = 0;
      for (let i = index; i < this.daftarKerugianList.length; i++) {
        if (item.pelakuNik === this.daftarKerugianList[i].pelakuNik && item.pelakuName === this.daftarKerugianList[i].pelakuName) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getUniqueIndex(item, index) {
      let uniqueIndex = 0;
      let lastPelakuNik = '';
      for (let i = 0; i <= index; i++) {
        if (i === 0 || this.daftarKerugianList[i].pelakuNik !== lastPelakuNik) {
          uniqueIndex++;
          lastPelakuNik = this.daftarKerugianList[i].pelakuNik;
        }
      }
      return uniqueIndex;
    },
    loadData() {
      this.getDataInvestigasiChekcId({
        fraudId: this.getId,
      }).then((resp) => {
        console.log("cek resp", resp)

        this.dataInvestigasiCheckId = resp.data.data;

        this.jenisPelanggaranListToSave = this.dataInvestigasiCheckId.jenisPelanggaranList;
        this.reportStatus = this.dataInvestigasiCheckId.reportStatus;

        // DATA INVESTIGATOR
        this.picNamaInvestigator =
          this.dataInvestigasiCheckId.picInvestigatorName != null
            ? this.dataInvestigasiCheckId.picInvestigatorName
            : "-";
        this.picJabatanInvestigator =
          this.dataInvestigasiCheckId.picInvestigatorPosition != null
            ? this.dataInvestigasiCheckId.picInvestigatorPosition
            : "-";
        this.picEmailInvestigator =
          this.dataInvestigasiCheckId.picInvestigatorEmail != null
            ? this.dataInvestigasiCheckId.picInvestigatorEmail
            : "-";
        this.picPhoneInvestigator =
          this.dataInvestigasiCheckId.picInvestigatorPhone != null
            ? this.dataInvestigasiCheckId.picInvestigatorPhone
            : "-";
        this.picNikInvestigator =
          this.dataInvestigasiCheckId.picInvestigator != null
            ? this.dataInvestigasiCheckId.picInvestigator
            : "-";
        console.log("Cek NIK: ", this.picNikInvestigator)

        // DETAIL PELAPORAN
        this.jagaNo = this.dataInvestigasiCheckId.jagaNo;
        this.reportDateForm =
          this.dataInvestigasiCheckId.reportDate != null
            ? moment(this.dataInvestigasiCheckId.reportDate).format(
              "DD/MM/YYYY"
            )
            : "-";
        this.reportJenisFraud = this.dataInvestigasiCheckId.fraudTypeName;
        this.reportFraudInf = this.dataInvestigasiCheckId.fraudInfName;
        this.fraudPelakuList = this.dataInvestigasiCheckId.fraudPelakuList;
        this.fraudLocNameM = this.dataInvestigasiCheckId.fraudLocName;
        this.lossKejadian = this.humanFormat(this.dataInvestigasiCheckId.initialLoss);
        this.attach = this.dataInvestigasiCheckId.fraudAttList;
        this.modusOperandiList = this.dataInvestigasiCheckId.fraudMthdList;
        // for(var i = 0; i <this.modusOperandiList.length; i++){
        //     this.handleGetKronologiList(this.modusOperandiList[i].fraudMthd, i);
        // }
        this.sharepointLink = this.dataInvestigasiCheckId.sharepointLink;

        //VERIFIKASI FRAUD
        this.verifikasiStatus = this.dataInvestigasiCheckId.verifStatusName;
        this.reportDocTypeList = this.dataInvestigasiCheckId.reportDocTypeList;
        this.spPelaku = this.humanFormat(this.dataInvestigasiCheckId.spPelaku);
        this.spNasabah = this.humanFormat(this.dataInvestigasiCheckId.spNasabah);
        this.nikInvestigator =
          this.dataInvestigasiCheckId.investigationDelegatorNik;
        this.managerInvestigator =
          this.dataInvestigasiCheckId.investigationDelegatorName;
        this.hanphoneInvestigator =
          this.dataInvestigasiCheckId.investigationDelegatorPhone;
        this.delegasi =
          this.dataInvestigasiCheckId.investigationDelegatorPositionName;
        this.delegatorPosition =
          this.dataInvestigasiCheckId.investigationDelegatorPosition;
        this.extFraudMthdDesc = this.dataInvestigasiCheckId.extFraudMthdDesc;
        this.reportDateReturn = this.dataInvestigasiCheckId.returnDate;

        console.log("Cek Delegate Nik: ", this.nikInvestigator)
        console.log("Cek Delegate Name: ", this.delegasi)
        console.log("Cek Delegate Position: ", this.delegatorPosition)
        console.log("Cek Report Status: ", this.reportStatus)
        

        //INVESTIGASI FRAUD
        this.investigationStatus =
          this.dataInvestigasiCheckId.investigationStatusName != null
            ? this.dataInvestigasiCheckId.investigationStatusName
            : "-";
        this.investigationStatusCode =
          this.dataInvestigasiCheckId.investigationStatus;
        this.investigasiFraudFactor =
          this.dataInvestigasiCheckId.fraudFactor != null
            ? this.dataInvestigasiCheckId.fraudFactor
            : "-";
        this.investigasiFraudFactorDesc = this.dataInvestigasiCheckId.fraudFactorDesc != null ? this.dataInvestigasiCheckId.fraudFactorDesc : "-";
        this.fraudReasonM = this.dataInvestigasiCheckId.fraudReason;
        this.fraudControlM = this.dataInvestigasiCheckId.fraudControl;
        this.investigasiViolationType =
          this.dataInvestigasiCheckId.violationType != null
            ? this.dataInvestigasiCheckId.violationType
            : "-";
        this.investigasiViolationTypeDesc = this.dataInvestigasiCheckId.violationTypeDesc != null ? this.dataInvestigasiCheckId.violationTypeDesc : "-";
        this.fraudCommitDate =
          this.dataInvestigasiCheckId.fraudCommitedDate != null
            ? moment(this.dataInvestigasiCheckId.fraudCommitedDate).format(
              "DD/MM/YYYY"
            )
            : null;
        this.fraudKnowDate =
          this.dataInvestigasiCheckId.fraudKnownDate != null
            ? moment(this.dataInvestigasiCheckId.fraudKnownDate).format(
              "DD/MM/YYYY"
            )
            : null;
        this.fraudRecovery =
          this.dataInvestigasiCheckId.fraudCommitedDateTo!= null
            ? moment(this.dataInvestigasiCheckId.fraudCommitedDateTo).format(
              "DD/MM/YYYY"
            )
            : "-";
        this.attachment = this.dataInvestigasiCheckId.fraudAttAFMList;
        this.rootCauseApi = this.dataInvestigasiCheckId.rootCauseList;
        this.loadStatusRoot();

        this.picApprUser = this.dataInvestigasiCheckId.picAppr
        this.picBc = this.dataInvestigasiCheckId.picBc
        this.picDh = this.dataInvestigasiCheckId.picDh

        console.log("Cek picAppr: ", this.picApprUser)
        console.log("Cek picBc: ", this.picBc)
        console.log("Cek picDh: ", this.picDh)

        this.userRole = this.$store.state.roleCodeFromLogin
        console.log("Cek userRole: ", this.userRole)

        this.userLogin = this.$store.state.username
        console.log("Cek username: ", this.userLogin)

        let temp = this.dataInvestigasiCheckId.detailLossList;

        for (var i = 0; i < this.detailLoss.length; i++) {
          this.lossList.push([]);
        }

        temp.map((item) => {
          console.log("ini item loss type", item.lossType);
          let index = this.detailLoss.findIndex(
            (x) => x.paramCd === item.lossType
          );
          this.lossList[index].push(item);
        });

        console.log(
          "isi detail loss list pembayaan fiktif",
          this.pembiayaanFiktifList
        );

        this.notesRevice = this.dataInvestigasiCheckId.notes;

        // console.log(
        //   "isi pembiayaan fiktif list di get id",
        //   this.pembiayaanFiktifList
        // );

        console.log(" ini adalah investigasi status", this.investigationStatus);

        this.lossSummary = this.dataInvestigasiCheckId.lossSummaryList;
        console.log("ini data Summary Status", this.lossSummary);

        //lAPORAN PELENGKAP
        this.attachmentAfm = this.dataInvestigasiCheckId.fraudAttAFMList;
        this.delegateBy =
          this.dataInvestigasiCheckId.investigationDelegatorPositionName;

        //HISTORY
        this.fraudLogList = this.dataInvestigasiCheckId.fraudLogList;
        this.fraudDetailM = this.dataInvestigasiCheckId.fraudDetail;
        this.fraudPurposeM = this.dataInvestigasiCheckId.fraudPurpose;

        console.log("ini check id ", this.dataInvestigasiCheckId);
        console.log("ini root cause", this.rootCauseList);
      });

      this.searchFraudPelaku(this.getId)
        .then(response => {
          console.log('response', response)
          this.daftarKerugianList = response.data.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    handleDownloadAttachment(item) {
      this.downloadAttachment({
        base64Data: "",
        fileName: item.attachFileName,
        filePathName: item.attachFilePath,
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.download = item.attachFileName;
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
        });
    },

    handleDownloadTemplate(tipe) {
      this.downloadTemplateDaftarKerugian(tipe)
        .then((response) => {
          console.log("response oke", response);
        })
        .catch((err) => {
          console.log("gagal download", err.response);
          this.$helpers.handleError(err);
        });
    },

    handleGetLossList() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "LOSS_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.detailLoss = response.data.data;
        console.log("isi detailLoss", this.detailLoss);
      });
    },

    loadStatusRoot() {
      this.getParameterByParamType({
        paramType: "ROOT_CAUSE",
      })
        .then((resp) => {
          this.mandatoryList = [];
          let rootCauseStatusa = resp.data.data;
          for (let index = 0; index < rootCauseStatusa.length; index++) {
            this.rootCouse.push({
              createBy: "",
              createDate: "",
              fraudId: this.fraudId,
              lossDocId: 0,
              recordFlag: "",
              rootCause: rootCauseStatusa[index].paramCd,
              rootCauseDesc: "",
              rootCauseList: [],
              rootCauseName: rootCauseStatusa[index].paramName,
              seqNo: 0,
              updateBy: "",
              updateDate: "",
            });

            this.mandatoryList.push(rootCauseStatusa[index].paramValue);

            this.rootCauseStatus.push([]);

            this.getParameterByParamType({
              pageNumber: 0,
              pageSize: 100,
              paramCd: "",
              paramName: "",
              paramType: rootCauseStatusa[index].paramCd,
              paramValue: "",
              searchValue: "",
            }).then((response) => {
              response.data.data.map((item) => {
                item.rootCause = item.paramType;
                delete item.paramType;
                item.rootCauseDesc = item.paramCd;
                delete item.paramCd;
                item.rootCauseName = item.paramName;
                delete item.paramName;
                item.rootCauseDescName = "";
                delete item.paramTypeName;
                delete item.paramValue;
              });
              this.rootCauseStatus.splice(index, 1, response.data.data);
            });
          }

          if (this.rootCauseApi.length != 0) {
            this.rootCauseApi.map((item) => {
              let indexs = this.rootCouse.findIndex(
                (x) => x.rootCause === item.rootCause
              );
              this.rootCouse.splice(indexs, 1, item);
            });
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },

    handleGetKronologiList(item, index) {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: item,
        paramValue: "",
        searchValue: "",
      }).then((resp) => {
        this.kronoLogiList.splice(index, 1, resp.data.data);
      });
    },

    formatDateForm() {
      this.menu1 = false;
      this.reportDateForm = moment(this.inputDateFormValPicker).format(
        "DD/MM/YYYY"
      );
    },

    openNoteDialog(note) {
      this.noteList = note;
      this.noteDialog = true;
    },

    openDocumentDialog(document) {
      this.isiDocumentList = document;
      this.documentDialog = true;
    },
    
    openPelakuDialog(isi, fraud) {
      this.isiPelakuList = isi;
      this.netFraudText = fraud;
      this.pelakuFraudDialog = true;
    },

    openExpand(index, loss) {
      this.expandPembiayaanFiktif = true;
      this.indexExpand = index;
      this.lossChosen = loss;
    },

    openHandleRevice() {
      this.keteranganRevice = true;
    },

    onExpansionPanelClick(index, event) {
      console.log("apa isi panelIndex", this.panelIndex);
      console.log("index", index);
      if (
        event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        console.log("Panel is closing/now closed.");
        //this.handleSaveAuto(index);
      } else {
        console.log("Panel is opening/now open.");
      }
    },

    handleGetJenisPelanggaran() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "VIOLATION_TYPE",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.jenisPelanggaranList = response.data.data;
      });
    },

    // Memunculkan popup Permintaan Approval
    loadPicAppr() {
      if (this.reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER') {
        this.getInquiryLovPicBc({
          pageNumber: 0,
          pageSize: 1000,
          searchValue: this.searchValuePicAppr
        }).then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        }).catch(() => {
          this.$store.state.inquiryApprover = [];
        });
      } else if (this.reportStatus == 'REPORT_STATUS_REQUEST_BC') {
        this.getInquiryLovPicDh({
          pageNumber: 0,
          pageSize: 1000,
          searchValue: this.searchValuePicAppr
        }).then((res) => {
          this.totalData = res.data.countData;
          this.countData = res.data.countData;
        }).catch(() => {
          this.$store.state.inquiryApprover = [];
        });
      }
      
    }, // Ok

    pilihPicAppr(item) {
      this.dialogPicApprBC = false;

      this.picApprover = item.name;
      this.picApproverNik = item.nik;

      this.picApprName = item.name;
      this.picAppr = item.nik;
    }, // Ok

    handleApprove() {
      let delegatorPosition = this.delegatorPosition
      console.log("Cek delegatorPosition: ", delegatorPosition)

      if (delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && this.reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER' ||
          delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && this.reportStatus == 'REPORT_STATUS_REQUEST_QA_MANAGER') {
        
        this.dialogPicAppr = false;
        this.reportStatusCode = 'REPORT_STATUS_REQUEST_BC'
        console.log("Report Status New: ", this.reportStatusCode)

        this.requestPICBC({
          fraudId: this.getId,
          picBc: this.picAppr,
          reportStatus: this.reportStatusCode,
        }).then((resp) => {
          this.$router.push(`/investigasi-checker`);
          this.$helpers.handleSuccess(resp);
        });
      } else if (delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && this.reportStatus == 'REPORT_STATUS_REQUEST_BC' ||
                 delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && this.reportStatus == 'REPORT_STATUS_REQUEST_BC') {
        
        this.dialogPicAppr = false;
        this.reportStatusCode = 'REPORT_STATUS_REQUEST_DH'
        console.log("Report Status New: ", this.reportStatusCode)

        this.requestPICDH({
          fraudId: this.getId,
          picDh: this.picAppr,
          reportStatus: this.reportStatusCode,
        }).then((resp) => {
          this.$router.push(`/investigasi-checker`);
          this.$helpers.handleSuccess(resp);
        });
      } else if (delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_SBM' && this.reportStatus == 'REPORT_STATUS_REQUEST_DH' ||
                 delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_QA_MANAGER' && this.reportStatus == 'REPORT_STATUS_REQUEST_DH' || 
                 delegatorPosition == 'INVESTIGATOR_DELEGATOR_POS_AFM_MANAGER' && this.reportStatus == 'REPORT_STATUS_REQUEST_AFM_MANAGER') {
        
        this.dialogPicAppr = false;
        this.reportStatusCode = 'REPORT_STATUS_INVESTIGATION_SUBMIT'
        console.log("Report Status New: ", this.reportStatusCode)

        this.submitInvestigation({
          fraudId: this.getId,
          reportStatus: this.reportStatusCode,
        }).then((resp) => {
          this.$router.push(`/investigasi-checker`);
          this.$helpers.handleSuccess(resp);
        });
      } else {
        console.log("ini approce menggunakan id", this.fraudId);
        this.submitInvestigasiCheck({
          fraudId: this.getId,
          notes: this.note,
        }).then((resp) => {
          this.$router.push(`/investigasi-checker`);

          this.$helpers.handleSuccess(resp);
        });
      }
    },

    handleGetTujuanFraud() {
      this.getParameterByParamType({
        pageNumber: 0,
        pageSize: 100,
        paramCd: "",
        paramName: "",
        paramType: "FRAUD_FACTOR",
        paramValue: "",
        searchValue: "",
      }).then((response) => {
        this.tujuanFraudList = response.data.data;
      });
    },

    handleRevise() {
      if (this.$refs.keteranganRevise.validate()) {
        this.reviseChecker({
          fraudId: this.getId,
          notes: this.notesRevice.trim(),
        })
          .then(async (resp) => {
            this.$router.push("/investigasi-checker");
            this.$helpers.handleSuccess(resp);

            //  this.logChoosed = resp.data.data;
            // console.log("ini logChoosed", this.logChoosed);
            console.log("data berhasil di revised", resp.data.data);
            // await this.addFraudLog(this.logChoosed);
          })
          .catch((err) => {
            this.$helpers.handleError(err);
          });
      }
    },

    // handleDownload() {
    //   this.downloadAttachment({});
    // },

    // async handleMapRevise() {
    //   await this.handleRevise();
    // },
  },

  computed: {
    ...mapState([]),
    getId: function () {
      return this.$route.params.fraudId;
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.showSnackbar;
      },
      set: function () {
        this.$store.commit("hideSnackBar");
      },
    },
    snackbarMsg: {
      get: function () {
        return this.$store.getters.snackbarMsg;
      },
      set: function () {
        this.$store.commit("resetSnackBarMsg");
      },
    },
    snackbarColor: {
      get: function () {
        return this.$store.getters.snackbarColor;
      },
      set: function () {
        this.$store.commit("resetSnackBarColor");
      },
    },
    allInquiryPicBh() {
      return Object.values(this.$store.getters.allInquiryPicBh);
    },
    allInquiryPicDh() {
      return Object.values(this.$store.getters.allInquiryPicDh);
    },
  },
};
</script>