import axios from 'axios';
import store from './store/index'
import router from './router/index'
import helpers from './helpers'
// import moment from 'moment'
// import { Promise } from "es6-promise";
// import { TokenStorage } from "./token-storage";
// import { router } from '../app';

export default () => {

  // let originalRequest = true

  axios.interceptors.request.use(
    config => {
        const username = store.state.username;
        if (username) {
            let encodedUserName = Buffer.from(username).toString('base64')
            config.headers['Origin-Name'] = encodedUserName;
        }
        return config;
    },
    error => {
        Promise.reject(error)
});

  axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    // const today = moment(new Date()).format('DD-MMM-YYYY hh:mm:ss')
    //     const expDate = store.state.tokenExpDateWeb

    //     if (moment(expDate).isBefore(today) && originalRequest === true) {
    //         originalRequest = false

    //         return axios.post(`/api/ad1sales/identity/v1/refreshToken`, {
    //             refreshToken: store.state.refreshTokenWeb,
    //             username: store.state.usernameWeb
    //         }, {
    //             validateStatus: function (status) {
    //                 return status == 200
    //             },
    //         })
    //             .then(res => {
    //                 const tokenWeb = res.data.token
    //                 const refreshTokenWeb = res.data.refreshToken
    //                 const tokenExpDateWeb = res.data.tokenExpDate

    //                 console.log('REFRESH TOKENNYA JALAN TIDAK YAH?')

    //                 store.state.tokenWeb = tokenWeb
    //                 store.state.refreshTokenWeb = refreshTokenWeb
    //                 store.state.tokenExpDateWeb = tokenExpDateWeb

    //                 axios.defaults.headers.common['Authorization'] = tokenWeb

    //                 originalRequest = true
    //             })
    //             .catch(error => {
    //                 console.log('ERRORNYA KENAPA YAH?', error)
    //             })
    //     } else {
            return response;
        // }
  }, (error) => {
    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url == `${process.env.VUE_APP_ROOT_URL}/api/jagaonline/main/v1/refreshToken`) {

      store.commit('logout')
      router.push('/login')

      return new Promise((reject) => {
        reject(error);
      });
    }

    if (error.response.status == 400) {
      if(error.response.data.message == "Token sudah tidak bisa digunakan") {
        store.commit('logout')
        router.push('/login')
  
        return new Promise((reject) => {
          reject(error);
        });
      }
    }

    if (error.response.status == 401) {
        router.push({ name: "forbidden" });
  
        return new Promise((reject) => {
          reject(error);
        });
    }

    if (error.response.status == 403 && error.response.data.message == "Autorisasi ditolak!") {
      store.commit('logout')
      router.push('/login')

      return new Promise((reject) => {
        reject(error);
      });
  }
    
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 403) {
        // store.commit("setShowSnackbar", {
        //     snackbarMsg: error.response.data.message,
        //     snackbarColor: "red",
        // });
      helpers.handleError(error);
      return Promise.reject(error);
    }
    // console.clear();

    // Try request again with new token
    return axios.post(`${process.env.VUE_APP_ROOT_URL}/api/jagaonline/main/v1/refreshToken`, {
        refreshToken: store.state.refreshTokenAdmin,
        username: store.state.fullName
    }, {
    //   headers: {
    //     'X-ADIRA-Key': process.env.VUE_APP_ADIRA_KEY
    // },
    }
    // {
    //     // headers: {},
    //     validateStatus: function (status) {
    //         return status == 200
    //     },
    // }
    )
      .then((res) => {

        // New request with new token

        const token = res.data.token
        const refreshTokenAdmin = res.data.refreshToken
        const tokenExpDate = res.data.tokenExpDate

        store.state.token = token
        store.state.refreshTokenAdmin = refreshTokenAdmin
        store.state.tokenExpDate = tokenExpDate

        const config = error.config;
        config.headers['Authorization'] = `${token}`;
        // axios.defaults.headers.common['Authorization'] = token

        return new Promise((resolve, reject) => { 
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });

      })
      .catch((error) => {
        Promise.reject(error);
      });
  });
}